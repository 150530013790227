import { DateTime } from 'luxon';
import { SectionSubset } from './Event';
import {
  SetOverrideType, OverrideType, ShiftOverrideType, ScaleOverrideType,
} from './Override';

type RollupGroupVar =
// date-time
| 'hour'
| 'day'
| 'week'
| 'month'
| 'quarter'
| 'year'
| 'time'
| 'log_hours_to_event'
// categorical
| 'stakeholder'
| 'deal'
| 'deal_term'
| 'event_type'
| 'event'
| 'autobroker_event'
| 'venue'
| 'marketplace'
| 'state'
| 'inventory_source_type'
| 'map_config_id'
| 'section_group'
| 'section'
| 'row'
| 'is_unmanifested'
// numeric
| 'quantity'
| 'price'

interface DateFilter {
  fromDate?: string;
  toDate?: string;
  fromDatetime?: string;
  toDatetime?: string;
  fromEventDate?: string;
  toEventDate?: string;
}

enum Perspective {
  SGIQ = 'sim',
  STAKEHOLDER = 'stakeholder',
}

enum InventorySourceType {
  PRIMARY = 'primary',
  BROKER = 'broker',
  CONSUMER = 'consumer',
}

interface RollupFilters extends DateFilter {
  stakeholder?: string;
  seatgeekEventId?: number[];
  autobrokerEventId?: number[];
  autobrokerEventGroup?: string;
  groups?: RollupGroupVar[];
  state?: string;
  deal?: string;
  dealTerm?: string;
  hasDealTerm?: boolean;
  isUnmanifested?: boolean;
  perspective?: Perspective;
  inventorySourceType?: InventorySourceType;
  // timeInterval accepts postgres interval types (e.g.: '6 hours', '3 days', '1 week')
  timeInterval?: string;
  logInterval?: number;
  // timeZone accepts IANA time zone names (e.g.: 'America/New_York', 'UTC') and
  // will default to 'UTC' and all times returned will use that time zone.
  timeZone?: string;
  bypassCache?: boolean;
}

interface RollupAggregate {
  ingestedCt?: number;
  ingestedTicketCt?: number;
  ingestedCost?: number;
  ingestedFvTicketCt?: number;
  ingestedFaceValue?: number;
  saleCt?: number;
  saleTicketCt?: number;
  saleRevenue?: number;
  saleCost?: number;
  saleFvTicketCt?: number;
  saleFaceValue?: number;
  saleNetRevenueAttr?: number;
  saleNetRevenueIncr?: number;
  saleAccountingProfitAttr?: number;
  saleAccountProfitIncr?: number;
  overallGrossProfitAttr?: number;
  overallGrossProfitIncr?: number;
}

type BinMeasure = keyof RollupAggregate;

interface RollupEventPartition {
  sectionSubset: SectionSubset;
  sources: string[];
}

interface RollupGroups {
  isUnmanifested?: boolean;
  time?: string;
  logHoursToEvent?: number;
  stakeholder?: string;
  deal?: string;
  dealTerm?: string;
  marketplace?: string;
  eventType?: string;
  seatgeekEventId?: number;
  autobrokerEventId?: number;
  eventTitle?: string;
  eventVenue?: string;
  eventStartsAtLocal?: DateTime;
  eventStartsAt?: DateTime;
  eventPartition?: RollupEventPartition;
  inventorySourceType?: InventorySourceType;
  mapConfigId?: number;
  sectionGroup?: string;
  section?: string;
  row?: string;
  mapkey?: string;
  quantity?: number;
  priceLower?: number;
  priceUpper?: number;
  state?: string;
}

interface Rollup {
  group: RollupGroups;
  aggregate: RollupAggregate;
}

interface GetRollupResponse {
  request: RollupFilters;
  rollup: Rollup[];
}

interface RollupPreviewMeasurement {
  projected: number;
  adjusted: number;
  target: number;
}

interface RollupPreviewAggregate {
  saleCost?: RollupPreviewMeasurement;
  saleFaceValue?: RollupPreviewMeasurement;
  saleTicketCt?: RollupPreviewMeasurement;
  saleRevenue?: RollupPreviewMeasurement;
}

interface RollupPreview {
  group: RollupGroups;
  aggregate: RollupPreviewAggregate;
}

interface RollupPreviewResponse {
  request: RollupFilters;
  rollup: RollupPreview[];
}

interface SalesSummaryMeasurement {
  transactionDate?: Date;
  logHoursToEvent?: number;
  cost?: number;
  revenue?: number;
  ticketsSold: number;
  managedInventory?: number;
  totalSales?: number;
  totalFaceValue?: number;
  soldFaceValue?: number;
  take?: number;
  totalManagedInventory?: number;
  takeAttr?: number;
  liftOfSold?: number;
  cumulativeTicketsSold?: number;
  cumulativeManagedInventory?: number;
  cumulativeIngestedFaceValue?: number;
  cumulativeSoldFaceValue?: number;
  cumulativeRevenue?: number;
  cumulativeCost?: number;
  cumulativeTake?: number;
  cumulativeTakeAttr?: number;
  cumulativeLiftOfSold?: number;
}

type CumulativeMmt = |
'cumulativeCost'
| 'cumulativeTicketsSold'
| 'cumulativeManagedInventory'
| 'cumulativeRevenue'
| 'cumulativeIngestedFaceValue'
| 'cumulativeSoldFaceValue'
| 'cumulativeTake'
| 'cumulativeTakeAttr'
| 'cumulativeLiftOfSold';

type PreviewTrendMetric = |
'ticketsSold'
| 'soldFaceValue'
| 'revenue'
| 'liftOfSold';

type TrendMetric = PreviewTrendMetric
| 'managedInventory'
| 'revenue'
| 'cost'
| 'take'
| 'takeAttr'
| 'liftOfSold'
| 'totalFaceValue'

enum OverrideOperation {
  CLEAR = 'clear',
  SET = 'set',
  SHIFT = 'shift',
  SCALE = 'scale',
}

interface RollupOverrideParams {
  type: SetOverrideType | ShiftOverrideType | ScaleOverrideType | OverrideType
  operation: OverrideOperation
  value?: number;
}

interface RequestedOverride extends RollupOverrideParams {
  label?: string;
}

interface RollupOverride {
  autobrokerEventIds: number[]
  parameters: RollupOverrideParams[]
  notes?: string
}

interface PostRollupBody {
  overrides: RollupOverride[]
  group?: string
}

export {
  RollupGroupVar,
  RollupEventPartition,
  RollupFilters,
  DateFilter,
  GetRollupResponse,
  Rollup,
  RollupAggregate,
  RollupGroups,
  BinMeasure,
  Perspective,
  InventorySourceType,
  RollupPreview,
  RollupPreviewResponse,
  PostRollupBody,
  OverrideOperation,
  RollupOverride,
  RollupOverrideParams,
  RequestedOverride,
  SalesSummaryMeasurement,
  CumulativeMmt,
  TrendMetric,
  PreviewTrendMetric,
};
