import {
  useEffect, useState, useCallback, useContext, useMemo,
} from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { ActiveEventContext } from '../../../contexts/ActiveEvent';
import {
  ListingsGraphInfo,
  PriceRatioGraphInfo,
  SelldownGraphInfo,
  SnapshotData,
} from '#/types/Snapshot';
import getSnapshots from '#/api/getSnapshots';
import formatApiError from '#/api/utils/formatApiError';

type Hook = () => {
  data: SnapshotData;
  error?: string;
  fetchSnapshots: () => Promise<void>;
  loading: boolean;
};

const useFetchSnapshots: Hook = () => {
  const isVisible = usePageVisibility();
  const { activeEvent } = useContext(ActiveEventContext);
  const eventId = useMemo(() => activeEvent?.id, [activeEvent]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [listingsGraphInfo, setListingsGraphInfo] = useState<ListingsGraphInfo>(
    null,
  );
  const [selldownGraphInfo, setSelldownGraphData] = useState<SelldownGraphInfo>(
    null,
  );
  const [priceRatioGraphInfo, setPriceRatioGraphData] = useState<
  PriceRatioGraphInfo
  >(null);

  const data = useMemo(() => {
    return {
      listingsGraphInfo,
      priceRatioGraphInfo,
      selldownGraphInfo,
    };
  }, [listingsGraphInfo, selldownGraphInfo, priceRatioGraphInfo]);

  const fetchSnapshots = useCallback(async (): Promise<void> => {
    setError('');
    try {
      const fetched = await getSnapshots(eventId);

      setSelldownGraphData(fetched.selldownGraphInfo);
      setPriceRatioGraphData(fetched.priceRatioGraphInfo);
      setListingsGraphInfo(fetched.listingsGraphInfo);
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }

    setLoading(false);
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      setLoading(true);
      fetchSnapshots();
    }
  }, [eventId, fetchSnapshots]);

  // refresh the snapshots data
  useEffect(() => {
    const interval = setInterval(() => {
      if (eventId && isVisible)
        fetchSnapshots();
    }, 80000);

    return (): void => clearInterval(interval);
  }, [eventId, fetchSnapshots, isVisible]);

  return {
    data,
    error,
    fetchSnapshots,
    loading,
  };
};

export default useFetchSnapshots;
