/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { StrategyType } from '#/types/Event';
import Select from 'react-select';
import { modalMenuProps } from '#/shared/modalComponents';
import { strategyOptions } from './constants';
import { Label } from '../sharedBulkEditModals/BulkEditModal';

interface Props {
  onChange: ({ value }: { value: StrategyType, label: string }) => void
  selectedValue?: { value: StrategyType, label: string }
}

const STRATEGY = 'Strategy';
const PLACEHOLDER = 'Select exploration strategy';

const StrategyDropdown: React.FC<Props> = ({ onChange, selectedValue }) => (
  <div>
    <Label htmlFor='price-exploration-strategy-menu'>{STRATEGY}</Label>
    <Select
      aria-label='price-exploration-strategy-menu'
      id='price-exploration-strategy-menu'
      isClearable
      isMulti={false}
      onChange={onChange}
      options={strategyOptions}
      placeholder={PLACEHOLDER}
      value={selectedValue?.value ? selectedValue : null}
      {...modalMenuProps}
    />
  </div>
);

export default StrategyDropdown;
