import React from 'react';
import styled from 'styled-components';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Listing from '#/types/Listing';
import { formatCurrency } from '#/shared/formatNumberForDisplay';

const LISTING = 'Ingestion Listing';
const EVENT = 'SeatGeek Event';
const COST = 'Ingested Cost';
const FACE_VALUE = 'Face Value';

interface CardProps {
  listing: Listing;
}

const IngestionCard: React.FC<CardProps> = ({ listing }) => {
  const {
    ingestion_listing_id,
    ingestion_cost_per_ticket,
    ingestion_face_value_per_ticket,
    seatgeek_event_id,
    quantity,
  } = listing;

  return (
    <NarrowWrapper>
      <Element>
        <Name>
          {LISTING}
          <Link
            href={`/ingestions?id=${ingestion_listing_id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {ingestion_listing_id}
          </Link>
        </Name>
      </Element>
      <Element>
        <Name>
          {EVENT}
          <Link
            href={`https://seatgeek.com/e/events/${seatgeek_event_id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {seatgeek_event_id}
          </Link>
        </Name>
      </Element>
      <Element>
        <Name>{COST}</Name>
        <Value>{`${quantity}x • ${formatCurrency(ingestion_cost_per_ticket)}`}</Value>
      </Element>
      {!!ingestion_face_value_per_ticket && (
        <Element>
          <Name>{FACE_VALUE}</Name>
          <Value>{`${quantity}x • ${formatCurrency(ingestion_face_value_per_ticket)}`}</Value>
        </Element>
      )}
    </NarrowWrapper>
  );
};

const Link = styled.a`
  padding-left: 0.35rem
`;

export default IngestionCard;
