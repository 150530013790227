import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 4rem;
`;
const GraphWrapper = styled.div`
  width: 100%;
  max-width: calc(55% - 2rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
  margin-right: 4.5rem;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 2.25rem;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GraphiqueWrapper = styled.div`
  width: calc(100% - 40px);
  line-height: 1.2;
  letter-spacing: 0;
`;

const YAxisLabel = styled.div`
  padding-bottom: 8px;
`;

export {
  Container, GraphWrapper, Header, Wrapper, GraphiqueWrapper, YAxisLabel,
};
