import { ErrorLevelType } from '#/types/Errors';
import styled from 'styled-components';

const CardsWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.secondary};
  ${({ theme }: { theme: Theme }): string => theme.shadows.down};
  border-radius: 12px;
  text-align: center;
`;

const Wrapper = styled(Card as any)`
  margin: 14px;
  width: 24rem;
  height: 11.5rem;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
`;

const NarrowWrapper = styled(Card as any)`
  margin: 7px 7px;
  padding: .5rem;
  width: 11rem;
  height: 10.5rem;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
`;

const ErrorOutput = styled.div`
  text-align: left;
  font-size: 14px;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.highlight};
  border-radius: 4px;
  padding: 4px 8px;
  overflow-x: auto;
  overflow-y: auto;
  max-width: 280px;
  width: calc(100% - 16px);
  font-family: monospace;
  line-height: 1.2;
`;

const NarrowErrorWrapper = styled(NarrowWrapper as any)`
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.error};
`;

const errorLevelColor = ({ theme, level }: { theme: Theme; level?: ErrorLevelType }): string => {
  switch (level) {
    case ErrorLevelType.CRITICAL:
      return theme.color.background.critical;
    case ErrorLevelType.ERROR:
      return theme.color.background.error;
    case ErrorLevelType.WARNING:
      return theme.color.background.warning;
    default:
      return theme.color.background.warning;
  }
};

const ErrorWrapper = styled(Card as any)`
  padding: 0.25rem;
  margin: 7px 0;
  width: 60rem;
  height: 5.5rem;
  background-color: ${errorLevelColor};
`;

const OkWrapper = styled(Card as any)`
  padding: 0.25rem;
  margin: 7px 0;
  width: 60rem;
  height: 5.5rem;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
`;

const RelatedWrapper = styled(Card as any)`
  padding: 0.5rem;
  margin: 12px 1.75rem;
  width: 56rem;
  height: 3rem;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.teritary};
  display: flex;
  flex-direction: column;

  &:after {
    content: "";
    position: absolute;
    top: 0%;
    left: 30px;
    height: 20px;
    width: 20px;
    background: inherit;
    box-sizing:border-box;
    transform: rotate(225deg) translate(50%);
    border-bottom: inherit;
    border-right: inherit;
  }
`;

const Tag = styled.div`
  margin-top: 0.25rem;
  margin-bottom: 0.0rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 6px;
  padding-left: .4rem;
  padding-right: .4rem;
  padding-bottom: .1rem;
  padding-top: .2rem;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.secondary};
  min-width: 8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SmallTag = styled(Tag as any)`
  min-width: auto;
`;

const HorizonalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4.25rem;
  width: 100%;
`;

const ScrollBox = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  margin: 0.5rem;
`;

const Name = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }: { theme: Theme }): string => theme.text3};
  margin-bottom: 0.375rem;
`;

const NameValue = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.text3};
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 1.25rem;
`;

const Value = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.text1};
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 1.25rem;
`;

const WhiteSpaceValue = styled(Value as any)`
  white-space: pre;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export {
  RelatedWrapper,
  ErrorWrapper,
  NarrowWrapper,
  Wrapper,
  Element,
  ScrollBox,
  Name,
  Value,
  NameValue,
  WhiteSpaceValue,
  CardsWrapper,
  SmallTag,
  Tag,
  HorizonalWrapper,
  OkWrapper,
  Header,
  NarrowErrorWrapper,
  ErrorOutput,
};
