import React, { useCallback } from 'react';

import PriceLevel from '#/types/PriceLevel';
import {
  Wrapper, Section, Header, Title, Right, Button, EditImg, CancelledImg,
} from '#/shared/multiSelectDetail';

interface MultiPriceLevelsDetailProps {
  canEdit: boolean;
  priceLevels: PriceLevel[];
  setOverridePriceLevelModal: (listings: PriceLevel[]) => void;
  closeDetail: () => void;
}

const MultiPriceLevelsDetail: React.FC<MultiPriceLevelsDetailProps> = ({
  canEdit,
  priceLevels,
  setOverridePriceLevelModal,
  closeDetail,
}) => {
  const openEditModal = useCallback(() => {
    setOverridePriceLevelModal(priceLevels);
  }, [setOverridePriceLevelModal, priceLevels]);

  return (
    <Wrapper>
      <Section>
        <Header>
          <Title>{`${priceLevels.length} Price Levels: ${priceLevels.map((i) => `${i.id}`).join(', ')}`}</Title>
          {canEdit && (
            <Right>
              <Button onClick={openEditModal} title="Edit Price Levels">
                <EditImg />
              </Button>
            </Right>
          )}
          <Right>
            <Button onClick={closeDetail} title="Close Listing Detail">
              <CancelledImg />
            </Button>
          </Right>
        </Header>
      </Section>
    </Wrapper>
  );
};

export default MultiPriceLevelsDetail;
