import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import {
  RollupFilters,
  Rollup,
  GetRollupResponse,
} from '#/types/ReportingRollup';
import redirectUnauthorizedToAuth from './utils/redirectUnauthorizedToAuth';

const FULFILLED = 'fulfilled';

const getReportingRollup = async (
  filters: RollupFilters = {},
): Promise<Rollup[]> => {
  const isStateGrouped = filters.groups.includes('state');
  const defaultState = isStateGrouped ? undefined : FULFILLED;

  try {
    const response: AxiosResponse<GetRollupResponse> = await client.get(
      '/proxy/reporting_rollup',
      {
        params: {
          ...filters,
          // default to include only fulfilled transactions unless
          // state filter explicitly set or grouped by state
          state: filters?.state ?? defaultState,
        },
      },
    );

    const rawRollup = response?.data?.rollup || [];

    return rawRollup;
  } catch (err) {
    redirectUnauthorizedToAuth(err);
    return null;
  }
};

export default getReportingRollup;
