import React from 'react';
import { NarrowWrapper, Element, Name } from '#/shared/Card';
import ButtonSmall from '#/shared/Button';

const PEAKPASS_DOMAINS = 'Peakpass Domains';
const UPDATE = 'Update';

interface PeakpassDomainCardProps {
  openUpdateModal: () => void;
}

const PeakpassDomainCard: React.FC<PeakpassDomainCardProps> = ({ openUpdateModal }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{PEAKPASS_DOMAINS}</Name>
      </Element>
      <Element>
        <ButtonSmall onClick={openUpdateModal}>{UPDATE}</ButtonSmall>
      </Element>
    </NarrowWrapper>
  );
};

export default PeakpassDomainCard;
