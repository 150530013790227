import React from 'react';
import styled from 'styled-components';
import Error from '#images/Error.svg';

interface WarningIconProps extends React.PropsWithChildren {
  warning?: string;
}

const WarningIcon: React.FC<WarningIconProps> = ({
  warning,
  children,
}) => {
  return (
    <>
      <Center>
        {children}
      </Center>
      {warning
        ? <WarningImg title={warning} />
        : <RightEmptyImg />}
    </>
  );
};

const WarningImg = styled.img`
  content: url(${Error});
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
`;

const Center = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const EmptyImg = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
`;

const RightEmptyImg = styled(EmptyImg as any)`
  right: 0;
`;

export default WarningIcon;
