import React, {
  useContext, useMemo, useState, useCallback,
} from 'react';
import { min } from 'd3-array';
import {
  GG, ScaleX, ScaleY, ScaleStroke, Labels, Tooltip,
} from '@graphique/graphique';
import { GeomLine } from '@graphique/geom-line';
import { GeomVLine } from '@graphique/geom-vline';
import { SELLDOWN_LEGEND_KEYS } from '../constants';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import { Wrapper, GraphiqueWrapper } from '../sharedGraphComponents/styledComponents';
import { Theme } from '#/shared/graphique';
import Legend from '../sharedGraphComponents/Legend';
import determineGraphTimeframe from '../utils/determineGraphTimeframe';
import {
  SelldownGraphInfo, Graphlabels, LongData,
} from '#/types/Snapshot';
import adjustDomainForOverlay from '../utils/adjustDomainForOverlay';
import {
  elongate, formatXTicks, formatTooltipX, formatYTicks,
  isDefined, TIME_TO_EVENT_HRS_CUTOFF, invSymLog,
} from '../utils/dataFormatting';
import {
  generateConsistentX,
  palette,
  X_AXIS_LABEL,
  Y_AXIS_LABEL,
  TOOLTIP_POSITION,
} from './utils';

interface GraphProps {
  handleClick: () => void;
  historical: boolean;
  invertAxis?: boolean;
  overlayError: string;
  selldownGraphInfo: SelldownGraphInfo;
  selldownOverlay: SelldownGraphInfo;
  showOverlay: boolean;
  yLabel: string;
}

const groups: string[] = SELLDOWN_LEGEND_KEYS.map((t) => Graphlabels[t]);

const Selldown: React.FC<GraphProps> = ({
  selldownGraphInfo,
  selldownOverlay,
  historical,
}) => {
  const {
    graphData,
    graphDataDaysX,
    graphDomain,
    graphDomainHistorical,
  } = selldownGraphInfo;

  const adjustedDomain = adjustDomainForOverlay(
    selldownGraphInfo.graphData,
    graphDomain,
    selldownOverlay?.graphDomain,
  );
  const adjustedDomainHistorical = adjustDomainForOverlay(
    selldownGraphInfo.graphDataDaysX,
    graphDomainHistorical,
    selldownOverlay?.graphDomainHistorical,
  );

  const { activeEvent } = useContext(ActiveEventContext);
  const {
    data,
    overlay,
  } = determineGraphTimeframe(
    historical,
    graphData,
    graphDataDaysX,
    selldownOverlay,
    adjustedDomain,
    adjustedDomainHistorical,
    activeEvent,
  );

  const formattedData = useMemo(() => {
    const longData = elongate(data);

    // merges / replaces (long) data with values from (long) overlay data
    if (overlay) {
      const longOverlayData = elongate(overlay);

      const overlayGroups = Array.from(new Set(longOverlayData.map((d) => d.group)));

      const existingData = longData.filter((d) => !overlayGroups.includes(d.group));
      const mergedData: LongData[] = [...existingData, ...longOverlayData];

      return generateConsistentX(mergedData);
    }

    return generateConsistentX(longData);
  }, [data, overlay]);

  const existingGroups = useMemo(() => (
    Array.from(new Set(
      formattedData.filter((d) => isDefined(d.y) && isDefined(d.x))
        .map((d) => d.group),
    ))
  ), [formattedData]);
  const [includedGroups, setIncludedGroups] = useState(existingGroups);

  const handleLegendSelection = useCallback((v: Graphlabels) => {
    if (!existingGroups.includes(v))
      return;
    setIncludedGroups((prev) => {
      if (prev.includes(v) && prev.length === 1)
        return existingGroups;
      return prev.includes(v) ? prev.filter((p) => p !== v) : [...prev, v];
    });
  }, [existingGroups]);

  const now = useMemo(() => {
    const remaining = Graphlabels.ticketsRemaining;
    const lastRemainingX = min(
      formattedData.filter((d) => d.y && d.group === remaining), (d) => d.x,
    );

    const datum = formattedData.find(
      (d) => d.group === remaining && d.x === lastRemainingX,
    );

    return datum ? [datum] : undefined;
  }, [formattedData]);

  const includedData = useMemo(() => {
    return formattedData.filter((d) => (
      includedGroups.includes(d.group)
        && invSymLog(d.x) >= TIME_TO_EVENT_HRS_CUTOFF
    ));
  }, [formattedData, includedGroups]);

  return (
    <Wrapper>
      <GraphiqueWrapper>
        <GG
          aes={{
            x: (d): number => d.x,
            y: (d): number => d.y,
            stroke: (d): string => d.group,
          }}
          data={includedData}
          isContainerWidth
          margin={{ left: 50 }}
        >
          { now && (
            <GeomVLine data={now} showTooltip={false} stroke='#aaa' strokeDasharray='3,3' />
          )}
          <GeomLine
            brushAction='zoom'
            entrance='data'
            strokeOpacity={0.67}
            strokeWidth={3}
          />
          <ScaleX
            format={formatXTicks}
            numTicks={4}
            reverse
          />
          <ScaleY format={formatYTicks} numTicks={5} />
          <ScaleStroke domain={groups} values={palette} />
          <Labels
            header={<Legend onSelection={handleLegendSelection} />}
            x={X_AXIS_LABEL}
            y={Y_AXIS_LABEL}
          />
          <Tooltip
            position={TOOLTIP_POSITION}
            xFormat={formatTooltipX}
          />
          <Theme />
        </GG>
      </GraphiqueWrapper>
    </Wrapper>
  );
};

export default Selldown;
