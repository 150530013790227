import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import Modal from 'react-modal';
import { SinkConfig, ConfigType } from '../types';
import postSinkConfig from '#/api/postSinkConfig';
import deleteSinkConfig from '#/api/deleteSinkConfig';
import useSink from './useSink';
import checkListingsConstraints from '../utils/checkListingsConstraints';
import TABLE_HEADERS from '../../constants';
import {
  P_VISIBLE_MAX,
  P_VISIBLE_MIN,
  LISTING_MIN,
  CHURN_VISIBILITY_RATE_MAX,
  CHURN_VISIBILITY_RATE_MIN,
  PRICE_INCREMENT_MIN,
  PRICE_INCREMENT_MAX,
} from '../constants';
import InputForm, { InputType } from '../Forms/InputForm';
import CheckboxForm from '../Forms/CheckboxForm';
import ModalButtons from '../Buttons';
import {
  Content, Error, SectionWrapper, Title,
} from '#/shared/modalComponents';
import formatApiError from '#/api/utils/formatApiError';
import SelectMarketplaces from '../SelectMarketplaces';
import useFetchSinks from '#/pages/useFetchSinks';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';
import Sink from '#/types/Sink';

interface SinkModalProps {
  retry: () => void;
  closeModal: () => void;
  sink: SinkConfig;
}

const NOTES = 'Notes';

const SinkModal: React.FC<SinkModalProps> = ({ retry, closeModal, sink }) => {
  const [listingCountError, setListingCountError] = useState('');
  const [currentSink, setCurrentSink] = useState<Sink>(undefined);
  const [apiError, setApiError] = useState('');
  const { sinkConfig, updateSinkConfig } = useSink(sink);
  const handleSave = useCallback(async () => {
    try {
      await postSinkConfig(sinkConfig);
      retry();
      closeModal();
    } catch (err) {
      const errorString = formatApiError(err);

      setApiError(errorString);
    }
  }, [closeModal, retry, sinkConfig]);

  useEffect(() => Modal.setAppElement('body'), []);

  const { id } = sink;

  const handleDelete = useCallback(async () => {
    try {
      await deleteSinkConfig(id, sinkConfig.notes);
      retry();
      closeModal();
    } catch (err) {
      const errorString = formatApiError(err);

      setApiError(errorString);
    }
  }, [closeModal, retry, id, sinkConfig]);

  const {
    sinkExternalEventId,
    listingMaxCount,
    listingMinCount,
    marketplaces,
    pVisible,
    qaMode,
    resetVisibility,
    churnVisibilityRate,
    priceIncrement,
    sinkName,
    notes,
  } = sinkConfig;
  const { SINK } = TABLE_HEADERS;

  const { sinks, sinksError } = useFetchSinks();

  useEffect(() => {
    const selectedSink = sinks?.find((s) => s.name === sinkName);

    setCurrentSink(selectedSink);
  }, [sinks, sinkName]);

  const currentMarketplaces = useMemo(() => currentSink?.marketplaces || [], [currentSink]);

  useEffect(() => {
    const error = checkListingsConstraints(listingMinCount, listingMaxCount);

    setListingCountError(error);
  }, [listingMaxCount, listingMinCount]);

  useSetupModal(closeModal);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <Title className="sink_modal">{`Edit ${sinkName} / ${sinkExternalEventId}`}</Title>
      {listingCountError && <Error className="listingCountError">{listingCountError}</Error>}
      {apiError && <Error>{apiError}</Error>}
      <SectionWrapper>
        <Content>
          <InputForm
            handleInputChange={updateSinkConfig}
            initialVal={sinkExternalEventId}
            inputType={InputType.text}
            label={SINK.sinkExternalEventId}
            type={ConfigType.SINK_EXTERNAL_EVENT_ID}
          />
          <InputForm
            handleInputChange={updateSinkConfig}
            initialVal={listingMaxCount}
            inputType={InputType.number}
            label={SINK.listingMaxCount}
            min={LISTING_MIN}
            type={ConfigType.LISTING_MAX_COUNT}
          />
          <InputForm
            handleInputChange={updateSinkConfig}
            initialVal={listingMinCount}
            inputType={InputType.number}
            label={SINK.listingMinCount}
            min={LISTING_MIN}
            type={ConfigType.LISTING_MIN_COUNT}
          />
          <InputForm
            handleInputChange={updateSinkConfig}
            initialVal={pVisible}
            inputType={InputType.number}
            label={SINK.pVisible}
            max={P_VISIBLE_MAX}
            min={P_VISIBLE_MIN}
            type={ConfigType.P_VISIBLE}
          />
          <InputForm
            handleInputChange={updateSinkConfig}
            initialVal={churnVisibilityRate}
            inputType={InputType.number}
            label={SINK.churnVisibilityRate}
            max={CHURN_VISIBILITY_RATE_MAX}
            min={CHURN_VISIBILITY_RATE_MIN}
            type={ConfigType.CHURN_VISIBILITY_RATE}
          />
          <InputForm
            handleInputChange={updateSinkConfig}
            initialVal={priceIncrement}
            inputType={InputType.number}
            label={SINK.priceIncrement}
            max={PRICE_INCREMENT_MAX}
            min={currentSink?.priceIncrementMin || PRICE_INCREMENT_MIN}
            step={String(currentSink?.priceIncrementMin || PRICE_INCREMENT_MIN)}
            type={ConfigType.PRICE_INCREMENT}
          />
          <SelectMarketplaces
            error={sinksError}
            initialVal={marketplaces}
            label={SINK.marketplaces}
            onChange={updateSinkConfig}
            possibleMarketplaces={currentMarketplaces}
            type={ConfigType.MARKETPLACES}
          />
          <CheckboxForm
            handleInputChange={updateSinkConfig}
            label={SINK.qaMode}
            type={ConfigType.QA_MODE}
            value={qaMode}
          />
          <CheckboxForm
            handleInputChange={updateSinkConfig}
            label={SINK.resetVisibility}
            type={ConfigType.RESET_VISIBILITY}
            value={resetVisibility}
          />
          <InputForm
            handleInputChange={updateSinkConfig}
            initialVal={notes}
            inputType={InputType.text}
            label={NOTES}
            type={ConfigType.NOTES}
          />
        </Content>
        <ModalButtons
          disabled={!!listingCountError}
          onCancel={closeModal}
          onDelete={handleDelete}
          onSave={handleSave}
        />
      </SectionWrapper>
    </Modal>
  );
};

export default SinkModal;
