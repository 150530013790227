import { TableStyles } from 'react-data-table-component';
import palette from '#/theme/palettes/main';

const tableStyles: TableStyles = {
  rows: {
    style: {
      minHeight: '0',
    },
    highlightOnHoverStyle: {
      backgroundColor: palette.brand.light08,
      outlineColor: palette.brand.light24,
      borderBottomColor: palette.brand.light24,
    },
    selectedHighlightStyle: {
      backgroundColor: palette.brand.light24,
      outlineColor: palette.brand.light24,
      borderTopColor: palette.brand.light24,
      borderBottomColor: palette.brand.light24,
      userSelect: 'none',
    },
  },
  headCells: {
    style: {
      fontSize: '11.5px',
      fontWeight: 600,
      color: '#000',
      background: '#ddd',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      borderBottom: '1px solid #d3d3d3',
      paddingTop: '3px',
    },
  },
  cells: {
    style: {
      fontSize: '15px',
      letterSpacing: 0,
      paddingTop: '12px',
      paddingBottom: '12px',
    },
  },
};

export default tableStyles;
