import client from './_client';
import {
  Overrides, PostParameterOverride, OverrideType, Settings,
} from '#/types/Override';
import formatOverrides from './utils/formatOverrides';

type APICall = (
  eventId: number,
  canonicalSettings: Settings,
  settings: Settings,
  notes?: string,
) => Promise<Settings>;

/* eslint-disable camelcase */
const formatPostBody = (
  eventId: number,
  canonicalSettings: Settings,
  settings: Settings,
  notes?: string,
): Overrides => {
  const body: Overrides = {
    autobroker_event_id: eventId,
    parameters: [] as PostParameterOverride[],
    notes: notes ?? '',
  };

  Object.keys(settings).forEach((key) => {
    const type = key as OverrideType;
    const updatedSetting = settings[type] !== canonicalSettings[type];

    if (updatedSetting) {
      body.parameters.push({
        type: type as OverrideType,
        value: settings[type] ?? null,
        notes,
      });
    }
  });
  return body;
};
/* eslint-enable camelcase */

const postOverrides: APICall = async (
  eventId,
  canonicalSettings,
  settings,
  notes,
) => {
  const postBody = formatPostBody(
    eventId,
    canonicalSettings,
    settings,
    notes,
  );
  const response = await client.post('/proxy/overrides', postBody);

  const rawOverrides = response?.data;

  return formatOverrides(rawOverrides);
};

export default postOverrides;
