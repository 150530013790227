import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { User } from '#/types/User';
import Header from '#/shared/Header';
import EventTitle from './EventTitle';
import TabBar, { TabLabel, getTabLabel } from './TabBar';
import { FetchGroupsHook } from '#/pages/useFetchGroups';

interface NavigationProps {
  user: User;
  activeTab: TabLabel;
  setActiveTab: (input: TabLabel) => void;
  toggleOpen: MouseEventHandler<HTMLButtonElement>;
  groupsHook: FetchGroupsHook;
}

const Navigation: React.FC<NavigationProps> = ({
  user,
  activeTab,
  setActiveTab,
  toggleOpen,
  groupsHook,
}) => (
  <Container>
    <Header
      hasSlider
      toggleOpen={toggleOpen}
      user={user}
    />
    <EventTitle groupsHook={groupsHook} />
    <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
  </Container>
);

const Container = styled.div`
  position: fixed;
  z-index: 4;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.info};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: padding 0.75s ease;
  border-bottom: 1px solid ${({ theme }: { theme: Theme }): string => theme.color.border.primary};
`;

export { TabLabel, getTabLabel };
export default Navigation;
