import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { PriceType } from '#/types/PriceTypes';
import getPriceTypes from '#/api/getPriceTypes';
import formatApiError from '#/api/utils/formatApiError';

interface Option<Type> {
  id: number;
  value: Type;
  label: string;
}

interface PriceTypesHook {
  priceTypes: PriceType[];
  priceTypesError: string;
  fetchPriceTypes: () => void;
  priceTypesOptions: Option<number>[];
  alreadyMappedPriceTypesOptions: Option<number>[];
}

const useFetchPriceTypes = (stakeholder?: string, deal_term_id?: number): PriceTypesHook => {
  const [priceTypes, setPriceTypes] = useState<PriceType[]>([]);
  const [priceTypesError, setPriceTypesError] = useState<string>('');

  const fetchPriceTypes = useCallback(async () => {
    try {
      const d = await getPriceTypes(stakeholder);

      setPriceTypes(d);
    } catch (err) {
      const errorString = formatApiError(err);

      setPriceTypesError(errorString);
    }
  }, [stakeholder, setPriceTypes, setPriceTypesError]);

  useEffect(() => {
    fetchPriceTypes();
  }, [fetchPriceTypes]);

  const priceTypesOptions = useMemo(() => {
    return priceTypes?.map((priceType) => ({
      id: priceType.id,
      value: priceType.id,
      label: priceType.name,
    }));
  }, [priceTypes]);

  const alreadyMappedPriceTypesOptions = useMemo(() => {
    return (priceTypes && stakeholder)
      ? priceTypes
        .filter((priceType) => priceType.dealTermId === deal_term_id)
        .map((priceType) => ({
          id: priceType.id,
          value: priceType.id,
          label: priceType.name,
        }))
      : [];
  }, [priceTypes, deal_term_id, stakeholder]);

  return {
    priceTypes,
    priceTypesError,
    fetchPriceTypes,
    priceTypesOptions,
    alreadyMappedPriceTypesOptions,
  };
};

export default useFetchPriceTypes;
export { PriceTypesHook, Option };
