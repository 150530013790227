import React, { useCallback } from 'react';
import styled from 'styled-components';
import palette from '#/theme/palettes/main';

interface Props {
  label: string;
  onChange: () => void;
  checked: boolean;
}

const YScaleCheckbox: React.FC<Props> = ({ label, onChange, checked }) => {
  const handleChecked = useCallback(() => {
    onChange();
  }, [onChange]);

  return (
    <Container>
      <Checkbox
        checked={checked}
        onChange={handleChecked}
        title={label}
        type="checkbox"
      />
      <Label onClick={handleChecked}>{label}</Label>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 8px;
  padding: 2px;
`;

const Label = styled.label`
  font-size: 14px;
  letter-spacing: 0;
  cursor: pointer;
`;

const Checkbox = styled.input`
  cursor: pointer;
  height: 1rem;
  width: 1.2rem;
  margin-right: 0.25rem;

  &:checked {
    background-color: ${palette.brand.base};
  }
  &:after {
    background-color: ${palette.brand.base};
  }
`;

export default YScaleCheckbox;
