import React from 'react';

import { User } from '#/types/User';
import Ingestion from '#/types/Ingestion';
import {
  Wrapper, Section, Header, Title, Right, Button, EditImg, CancelledImg,
} from '#/shared/multiSelectDetail';

interface MultiIngestionsDetailProps {
  user: User;
  ingestions: Ingestion[];
  openEditModal: () => void;
  closeDetail: () => void;
}

const MultiIngestionsDetail: React.FC<MultiIngestionsDetailProps> = ({
  user,
  ingestions,
  openEditModal,
  closeDetail,
}) => {
  const disabled = user.isReadOnly;

  return (
    <Wrapper>
      <Section>
        <Header>
          <Title>{`${ingestions.length} Ingestion Listings: ${ingestions.map((i) => `${i.id}`).join(', ')}`}</Title>
          {!disabled && (
            <Right>
              <Button onClick={openEditModal} title="edit listing">
                <EditImg />
              </Button>
            </Right>
          )}
          <Right>
            <Button onClick={closeDetail} title="close listing detail">
              <CancelledImg />
            </Button>
          </Right>
        </Header>
      </Section>
    </Wrapper>
  );
};

export default MultiIngestionsDetail;
