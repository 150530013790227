import React from 'react';
import styled from 'styled-components';
import ModalForms from './ModalForms';
import Button from '#/shared/Button';
import { SalesPeriod, SinkEvent } from '#/types/Event';
import useModals from './useModals';
import createDefaultIntegration from './utils/createDefaultIntegration';

interface ModalsProps {
  eventId: number;
  retry: () => void;
  sink: SinkEvent;
  sinkModalIsOpen: boolean;
  setSinkModalIsOpen: (input: boolean) => void;
  salesPeriods: SalesPeriod[];
}

const ADD_INTEGRATION = 'Add New Integration';
const UPDATE_SALES_PERIODS = 'Update Sales Periods';
const REBUILD_EVENT = 'Rebuild Event';
const DELETE_EVENT = 'Delete Event';

const Modals: React.FC<ModalsProps> = ({
  eventId,
  retry,
  sink,
  sinkModalIsOpen,
  setSinkModalIsOpen,
  salesPeriods,
}) => {
  const newIntegration = createDefaultIntegration(eventId);

  const {
    addNewIntegration,
    updateSalesPeriods,
    rebuildEvent,
    deleteEvent,
    closeModal,
    integrationModalIsOpen,
    rebuildEventModalIsOpen,
    deleteEventModalIsOpen,
    salesPeriodModalIsOpen,
    openModal,
  } = useModals(setSinkModalIsOpen, sinkModalIsOpen);

  return (
    <>
      <Wrapper>
        <Button onClick={addNewIntegration}>{ADD_INTEGRATION}</Button>
        <Button onClick={updateSalesPeriods}>{UPDATE_SALES_PERIODS}</Button>
        <Button onClick={rebuildEvent}>{REBUILD_EVENT}</Button>
        <Button onClick={deleteEvent}>{DELETE_EVENT}</Button>
      </Wrapper>
      {openModal && (
        <>
          <ModalForms
            closeModal={closeModal}
            deleteEventModalIsOpen={deleteEventModalIsOpen}
            eventId={eventId}
            integrationModalIsOpen={integrationModalIsOpen}
            newIntegration={newIntegration}
            rebuildEventModalIsOpen={rebuildEventModalIsOpen}
            retry={retry}
            salesPeriodModalIsOpen={salesPeriodModalIsOpen}
            salesPeriods={salesPeriods}
            sink={sink}
            sinkModalIsOpen={sinkModalIsOpen}
          />
        </>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  padding: 1rem 2rem;
`;

export default Modals;
