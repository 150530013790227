import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { zIndices } from '#/constants/css';

const LEFT_OF_SCREEN_TO_EDGE_OF_SUMMARY_LINK = '20rem';

interface SliderProps extends React.PropsWithChildren {
  sliderIsOpen: boolean;
  toggleOpen: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
}

interface SliderBoxProps {
  sliderIsOpen: boolean;
}

const Slider: React.FC<SliderProps> = ({
  children,
  sliderIsOpen,
}) => {
  return (
    <SliderBox sliderIsOpen={sliderIsOpen}>
      <SliderContents>{children}</SliderContents>
    </SliderBox>
  );
};

const SliderBox = styled.div<SliderBoxProps>`
  height: calc(100vh);
  z-index: ${zIndices.slider};
  position: fixed;
  top: 0;
  border-top: 1px solid
    ${({ theme }: { theme: Theme }): string => theme.color.border.light};
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
  width: ${LEFT_OF_SCREEN_TO_EDGE_OF_SUMMARY_LINK};
  transition: transform 0.75s ease;
  ${({ sliderIsOpen }): string => {
    if (!sliderIsOpen)
      return 'transform: translateX(-100%)';
    return '';
  }};
`;

const SliderContents = styled.div`
  position: relative;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

export default Slider;
