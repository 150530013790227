
import React from 'react';
import { createRoot } from 'react-dom/client';
import Layout from '../src/layout/Layout';
import './index.html'; // eslint-disable-line import/no-unassigned-import
import './index.scss'; // eslint-disable-line import/no-unassigned-import
import './images/favicon-light.svg'; // eslint-disable-line import/no-unassigned-import

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Layout />,
);
