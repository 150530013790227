import React, { useMemo } from 'react';
import styled from 'styled-components';
import TableWrapper, {
  Cell,
  Header,
  RowWrapper,
} from '#/shared/TableComponents';
import { SectionRowPriceConstraint } from '#/types/PriceConstraints';

type PriceConstraint = SectionRowPriceConstraint

interface TableProps {
  data: PriceConstraint[];
}

const NULL_MARK = '-';

const formatText = (text: string | number | null, capitalize = true): (string | number | null) => {
  if (text === null)
    return '';
  if (typeof text === 'number')
    return text.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return text.toString().split('_')
    .map((s) => {
      const returnText = capitalize
        ? `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`
        : s;

      return returnText;
    })
    .join(' ');
};

const PriceConstraintsTable: React.FC<TableProps> = ({ data }) => {
  const headers = useMemo(() => {
    const keys = data.flatMap((d) => Object.keys(d));

    return Array.from(new Set(keys));
  }, [data]);

  return (
    <TableWrapper>
      <thead>
        <Header>
          {headers.map((header) => (
            <Cell key={header}>{formatText(header)}</Cell>
          ))}
        </Header>
      </thead>
      <tbody>
        {data.map((row, i) => {
          const rowKey = `row-${i}`;

          return (
            <RowWrapper key={rowKey}>
              {Object.values(row).map((v, j) => {
                const isNumber = typeof v === 'number';

                const isPrice = isNumber && Object.keys(data[i])[j].includes('price');

                const cellKey = `cell-${j}`;

                return (
                  <Cell
                    key={cellKey}
                    style={{ paddingLeft: isNumber ? 30 : 14 }}
                  >
                    {
                      v === null ? <NullWrapper>{NULL_MARK}</NullWrapper>
                        : `${isPrice ? '$' : ''}${formatText(v, false)}`
                    }
                  </Cell>
                );
              })}
            </RowWrapper>
          );
        })}
      </tbody>
    </TableWrapper>
  );
};

const NullWrapper = styled.div`
  color: ${({ theme }: { theme: Theme }): string => theme.palette.gray.light24};
`;

export default PriceConstraintsTable;
