/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { AriaSelectProps } from '@react-types/select';
import {
  useSelect,
  HiddenSelect,
  useButton,
  mergeProps,
  useFocusRing,
  useOverlayTrigger,
  useOverlayPosition,
} from 'react-aria';
import { useSelectState } from 'react-stately';
import { useWindowWidth } from '@react-hook/window-size';
import Popover from '../Popover';
import ListBox, { Label } from '../ListBox';
import SmallButton from '../web-platform-components/Buttons/SmallButton';
import Selector from '../Icons/Selector';

const DEFAULT_PLACEHOLDER = 'Select an option';
const EDGE_BUFFER = 10;

interface Props<T> extends AriaSelectProps<T> {
  isLoading: boolean;
}

function Dropdown<T extends Record<string, any>>(props: Props<T>): JSX.Element {
  const state = useSelectState(props);

  const triggerRef = React.useRef<HTMLButtonElement>();
  const overlayRef = React.useRef<HTMLDivElement>();

  // Get props for the trigger and overlay. This also handles
  // hiding the overlay when a parent element of the trigger scrolls
  // (which invalidates the popover positioning).
  const { overlayProps } = useOverlayTrigger(
    { type: 'dialog' },
    state,
    triggerRef,
  );

  useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    isOpen: state.isOpen,
  });

  const {
    label,
    name,
    placeholder,
    isLoading,
    isDisabled,
  } = props;

  const {
    triggerProps,
    menuProps,
  } = useSelect(
    props,
    state,
    triggerRef,
  );

  const { buttonProps } = useButton(triggerProps, triggerRef);
  const { focusProps } = useFocusRing();

  const triggerX = triggerRef?.current?.getBoundingClientRect()?.x ?? 0;
  const windowWidth = useWindowWidth() ?? 0;
  const popoverWidth = overlayRef?.current?.clientWidth ?? 0;
  const popoverLeftAnchor = triggerX < (EDGE_BUFFER / 2)
    ? (EDGE_BUFFER / 2)
    : triggerX;
  const popoverX = (triggerX + popoverWidth + EDGE_BUFFER) >= windowWidth
    ? (windowWidth - popoverWidth - EDGE_BUFFER)
    : popoverLeftAnchor;

  return (
    <Wrapper>
      <HiddenSelect
        label={label}
        name={name}
        state={state}
        triggerRef={triggerRef}
      />
      <SmallButton
        active={!!state.selectedItem || state.isOpen}
        forwardedRef={triggerRef}
        iconPosition='after'
        isDisabled={isDisabled}
        isLoading={isLoading}
        kind='secondary'
        label={(
          <Label maxWidth={160}>
            <span title={state?.selectedItem?.textValue}>
              {state.selectedItem?.rendered ?? placeholder ?? DEFAULT_PLACEHOLDER}
            </span>
          </Label>
        )}
        style={{ maxWidth: 200, minWidth: 120 }}
        summary={<SelectorContainer><Selector /></SelectorContainer>}
        summaryKind='secondary'
        {...mergeProps(buttonProps, focusProps)}
      />
      {
        state.isOpen && (
          <Popover
            {...overlayProps}
            isOpen={state.isOpen}
            // eslint-disable-next-line react/jsx-handler-names
            onClose={state.close}
            ref={overlayRef}
            style={{
              left: popoverX,
            }}
          >
            <ListBox {...menuProps} state={state} />
          </Popover>
        )
      }
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const SelectorContainer = styled.div`
  height: 22px;
  width: 18px;
  padding: 2px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { Item } from 'react-stately';
export { Dropdown };
