import {
  ErrorLevelType, ErrorStatusType, EventErrorType, FormattedEventError,
} from '#/types/Errors';
import { formatErrorType } from '#/pages/ErrorsPage/ErrorsTable/utils/helpers';

interface Filters {
  search: string;
  level?: ErrorLevelType;
  status?: ErrorStatusType;
  type?: EventErrorType;
  stakeholder?: string;
}

const filterErrorsBySearch = (eventErrors: FormattedEventError[], filters: Filters):
FormattedEventError[] => {
  const { search } = filters;

  let errors = eventErrors;

  if (filters.level)
    errors = errors.filter((error) => error.level === filters.level);

  if (filters.status)
    errors = errors.filter((error) => error.status === filters.status);

  if (filters.type)
    errors = errors.filter((error) => error.type === filters.type);

  if (filters.stakeholder)
    errors = errors.filter((error) => error.stakeholders.includes(filters.stakeholder));

  if (!errors || !search || search === '')
    return errors;

  return errors.filter((error) => {
    const title = error.event.title.toLowerCase();
    const venue = error.event.venue.toLowerCase();
    const status = error.status.toLowerCase();
    const type = formatErrorType(error.type);
    const level = error.level.toLowerCase();
    const stakeholders = error.stakeholders.map((s) => s.toLowerCase());

    // negative search to exclude matches
    if (search[0] === '!' && search.length > 1) {
      const negativeSearch = search.slice(1);

      return (
        !title.includes(negativeSearch)
        && !venue.includes(negativeSearch)
        && !status.includes(negativeSearch)
        && !type.includes(negativeSearch)
        && !level.includes(negativeSearch)
        && stakeholders.some((s) => !s.includes(negativeSearch))
      );
    }

    return (
      title.includes(search)
      || venue.includes(search)
      || status.includes(search)
      || type.includes(search)
      || level.includes(search)
      || stakeholders.some((s) => s.includes(search))
    );
  });
};

export default filterErrorsBySearch;
export { Filters };
