import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { SegmentedControl, SegmentedGroup } from '#/shared/clientReporting/web-platform-components/Controls';
import { Perspective } from '#/types/ReportingRollup';
import ReportingContext from '#/pages/clientReporting/ReportingContext';
import { createQueryParams } from '../../utils';

const VIEW_AS = 'View as';
const PERSPECTIVE_OPTIONS = [
  {
    value: Perspective.SGIQ,
    label: 'SGIQ',
  },
  {
    value: Perspective.STAKEHOLDER,
    label: 'Client',
  },
];

const PerspectiveToggle: React.FC = () => {
  const { queryParams, hasPendingData, user } = useContext(ReportingContext);
  const [perspective, setPerspective] = useState(
    PERSPECTIVE_OPTIONS.find((p) => p.value === queryParams.perspective) || PERSPECTIVE_OPTIONS[0],
  );
  const navigate = useNavigate();

  const handlePerspectiveChange = useCallback((v: Perspective) => {
    setPerspective(PERSPECTIVE_OPTIONS.find((o) => o.value === v));

    const searchParams = createQueryParams(
      queryParams,
      {
        perspective: v,
      },
    );

    navigate({ search: searchParams });
  }, [navigate, queryParams]);

  return user?.isReporting
    ? null
    : (
      <ToggleWrapper hasPendingData={hasPendingData}>
        <ToggleLabel htmlFor='perspectiveType'>{VIEW_AS}</ToggleLabel>
        <SegmentedGroup
          name='perspectiveType'
          onChange={handlePerspectiveChange}
          value={perspective.value}
        >
          {PERSPECTIVE_OPTIONS.map((p) => (
            <SegmentedControl
              disabled={hasPendingData}
              isSmall
              key={p.value}
              value={p.value}
            >
              {p.label}
            </SegmentedControl>
          ))}
        </SegmentedGroup>
      </ToggleWrapper>
    );
};

const ToggleLabel = styled.label`
  font-size: 0.8rem;
  letter-spacing: 0;
  font-weight: 600;
`;

interface ToggleProps {
  hasPendingData?: boolean;
}

const ToggleWrapper = styled.div<ToggleProps>`
  width: 8rem;
  display: flex;
  align-self: center;
  flex-direction: column;
  margin: 1rem 0.2rem;
  letter-spacing: 0;
  opacity: ${(props: ToggleProps): number => props.hasPendingData ? 0.4 : 1};
  transition: all 0.2s;
`;

export default PerspectiveToggle;
export { PERSPECTIVE_OPTIONS };
