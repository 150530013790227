import ApiSnapshot, {
  ListingsGraphData,
  ListingsGraphInfo,
  GraphPlot,
} from '../../types/Snapshot';
import calcPriceRatioDomain from './calcTruncatedDomain';

/* eslint-disable camelcase */

interface ListingData {
  actualABListingCount: GraphPlot;
  actualABNormalizedListingCount: GraphPlot;
  actualCompetitorListingCount: GraphPlot;
  actualCompetitorNormalizedListingCount: GraphPlot;
}

const formatListingSnapshot = (rawSnapshot: ApiSnapshot): ListingData => {
  const { logHoursToEvent } = rawSnapshot;

  const response: ListingData = {
    actualABListingCount: { x: logHoursToEvent, y: null },
    actualABNormalizedListingCount: { x: logHoursToEvent, y: null },
    actualCompetitorListingCount: { x: logHoursToEvent, y: null },
    actualCompetitorNormalizedListingCount: { x: logHoursToEvent, y: null },
  };

  const { actual } = rawSnapshot;

  if (!actual)
    return response;

  const { autobroker, competitor } = actual;

  if (autobroker) {
    response.actualABListingCount.y = autobroker.listingCt;
    response.actualABNormalizedListingCount.y = autobroker.normalizedListingCt;
  }

  if (competitor) {
    response.actualCompetitorListingCount.y = competitor.listingCt;
    response.actualCompetitorNormalizedListingCount.y = competitor.normalizedListingCt;
  }
  return response;
};

const formatListingSnapshots = (
  rawSnapshots: ApiSnapshot[],
): ListingsGraphInfo => {
  const listingsGraphData: ListingsGraphData = {
    actualABListingCount: [],
    actualABNormalizedListingCount: [],
    actualCompetitorListingCount: [],
    actualCompetitorNormalizedListingCount: [],
  };

  rawSnapshots.reduce(
    (
      accumulator: ListingsGraphData,
      rawSnapshot: ApiSnapshot,
    ): ListingsGraphData => {
      const snapshot = formatListingSnapshot(rawSnapshot);
      const lineTypes = Object.keys(snapshot) as Array<keyof ListingData>;

      lineTypes.forEach((line) => {
        const dataArr: GraphPlot[] = accumulator[line];

        dataArr.push(snapshot[line]);
      });
      return accumulator;
    },
    listingsGraphData,
  );

  const listingsGraphDomain = calcPriceRatioDomain(listingsGraphData);

  return {
    listingsGraphData,
    listingsGraphDomain,
  };
};

export default formatListingSnapshots;
