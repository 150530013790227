import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Clear from '#images/Clear.svg';
import palette from '#/theme/palettes/main';
import { isDefined } from './getSalesTrendsByEvent';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClearIcon = (
  <IconContainer>
    <SVG height={16} src={Clear} stroke={palette.red.dark} width={16} />
  </IconContainer>
);

const formatOverrideValue = (value?: number, isCleared?: boolean): React.ReactNode => {
  const emptyValue = isCleared ? ClearIcon : '-';

  return (
    isDefined(value) ? value.toLocaleString(undefined, { maximumFractionDigits: 2 }) : emptyValue
  );
};

export default formatOverrideValue;
