import { TableColumn } from 'react-data-table-component';
import {
  formatColumn,
  OverrideSummary,
  OverrideSummaryColumns,
  customGranularitySort,
} from './helpers';

const generateSummaryColumns = (): TableColumn<OverrideSummary>[] => {
  return Object.values(OverrideSummaryColumns)
    .map((key: keyof OverrideSummary) => ({
      id: key,
      name: formatColumn(key),
      selector: (row: OverrideSummary): any => row[key],
      sortable: true,
      sortFunction: key === OverrideSummaryColumns.GRANULARITY ? customGranularitySort : undefined,
    }));
};

export default generateSummaryColumns;
