import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Document, Page, pdfjs } from 'react-pdf';
import { Content } from '#/shared/modalComponents';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '../useSetupModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfFile {
  numPages: number;
}

interface DisplayPdfProps {
  pdfUrl: string;
  closeModal: () => void;
}

const pdfModalStyles = {
  ...modalStyles,
  content: {
    ...modalStyles.content,
    maxWidth: 700,
    maxHeight: '90%',
  },
};

const DisplayPdf: React.FC<DisplayPdfProps> = ({ pdfUrl, closeModal }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = useCallback((file: PdfFile) => {
    setNumPages(file.numPages);
  }, [setNumPages]);

  useSetupModal(closeModal);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={pdfModalStyles}
    >
      <PdfContent>
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </PdfContent>
    </Modal>
  );
};

const PdfContent = styled(Content as any)`
  display: flex;
  justify-content: center;
  max-height: 84vh;
`;

export default DisplayPdf;
