import {
  SelldownGraphData,
  GraphDomain,
  GraphPlot,
} from '../../types/Snapshot';

// This function takes an x-axis value and finds the minimum y
// value for plot points with a matching x
const calcGraphDomainHistoricalY = (
  graphData: SelldownGraphData,
  xMin: number,
): number => {
  let yMin: number;
  const datapoints = Object.keys(graphData);
  const Y_EXTENSION_FACTOR = 0.9;

  datapoints.forEach((el: keyof SelldownGraphData): void => {
    const plotArray = graphData[el];
    const xMinPlotpoint = plotArray.filter(
      (xySet: GraphPlot) => xySet.x === xMin,
    )[0];

    yMin = Math.min(yMin || xMinPlotpoint.y, xMinPlotpoint.y);
  });

  yMin *= Y_EXTENSION_FACTOR;
  return yMin;
};

// This function iterates backwards through the actual ticket data to find the
// most recent (last) index where data is provided.
const calcGraphDomainHistorical = (
  graphData: SelldownGraphData,
  graphDomain: GraphDomain,
): GraphDomain => {
  let xMin: number;
  const xPoints = [...graphDomain.x];
  const yPoints = [...graphDomain.y];
  const { actualUnsoldTickets } = graphData;
  const X_EXTENSION_FACTOR = 0.7;
  const STEP = 1;
  const END = 0;

  for (let i = actualUnsoldTickets.length - STEP; i >= END; i -= STEP) {
    const plotPoint: GraphPlot = actualUnsoldTickets[i];

    if (plotPoint.x) {
      xMin = plotPoint.x;
      break;
    }
  }

  // If we find an xMin, an adjusted y min is calculated, and xMin extended a bit
  // to see beyond that endpoint.  If there's no xMin found (no actual sales
  // data) we return the same data as the original graph domain.
  if (xMin) {
    const yMin = calcGraphDomainHistoricalY(graphData, xMin);

    yPoints[0] = yMin;
    xPoints[0] = xMin * X_EXTENSION_FACTOR;
  }

  return {
    x: xPoints,
    y: yPoints,
  };
};

export default calcGraphDomainHistorical;
