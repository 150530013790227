import React, { useCallback } from 'react';
import useFetchGroups, { type GroupOption } from '#/pages/useFetchGroups';
import Action, { EventType } from '../types';
import SelectForm from '../Forms/SelectForm';

interface SelectProps {
  handleInputChange: React.Dispatch<Action>;
  label: string;
}

interface DropdownOption {
  id: number;
  label: string;
  value: string;
}

const SelectGroup: React.FC<SelectProps> = ({ handleInputChange, label }) => {
  const { groupLabels, groupError } = useFetchGroups();

  const handleChange = useCallback(
    (option: GroupOption[]) => {
      const groups = option?.map((el: DropdownOption) => el.value);

      handleInputChange({ type: EventType.GROUPS, value: groups });
    },
    [handleInputChange],
  );

  return (
    <SelectForm
      error={groupError}
      isMulti
      label={label}
      onChange={handleChange}
      options={groupLabels}
    />
  );
};

export default SelectGroup;
