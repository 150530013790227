import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ButtonSmall } from '#/shared/Button';
import Event from '#/types/Event';
import postInventoryUpdater from '#/api/postInventoryUpdater';
import toast, { Toaster } from 'react-hot-toast';
import InfoTooltip from '#/shared/InfoTooltip';

interface Props {
  activeEvent: Event;
}

const TEXT = 'Run Inventory Updater';
const TOAST_SUCCESS_MESSAGE = 'Running the Inventory Updater';
const TOAST_FAIL_MESSAGE = 'Unable to run the Inventory Updater due to an error';
const TOOLTIP_TEXT = 'This job identifies any ingestion listings that are removed from the SGIQ allocations for this event and deletes them.';

const RunInventoryUpdaterButton: React.FC<Props> = ({ activeEvent }) => {
  const isActive = activeEvent.config.isActive;
  const handleClick = useCallback(async () => {
    await toast.promise(postInventoryUpdater(activeEvent.id), {
      loading: '',
      success: TOAST_SUCCESS_MESSAGE,
      error: TOAST_FAIL_MESSAGE,
    });
  }, [activeEvent]);

  return (
    <div>

      <RunButton disabled={!isActive} onClick={handleClick}>
        <WrapperStyle>
          {TEXT}
          <ToolTipStyle>
            <InfoTooltip
              ariaLabel='take-tooltip'
              closeDelay={80}
              delay={80}
            >
              {TOOLTIP_TEXT}
            </InfoTooltip>
          </ToolTipStyle>
        </WrapperStyle>
      </RunButton>

      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 5000,
          success: {
            style: {
              background: '#ECF8F3',
              color: '#0B6F46',
            },
          },
          error: {
            style: {
              background: '#FDF0F0',
              color: '#BA2626',
            },
          },
        }}
      />
    </div>
  );
};

const RunButton = styled(ButtonSmall as any)`
  padding: 0 1rem;
  padding-top: 5px;
`;

const WrapperStyle = styled.div`
  z-index: 10;
  display: flex;
  top: 2px;
`;

const ToolTipStyle = styled.div`
  padding-left: 4px;
`;

export default RunInventoryUpdaterButton;
