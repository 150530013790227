import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { formatCurrency } from '#/shared/formatNumberForDisplay';
import Event from '#/types/Event';

interface CardProps {
  event: Event;
}

const GTV = 'Sale GTV';
const AVG_PRICE = 'Avg. Price';
const NA = 'N/A';

const GtvCard: React.FC<CardProps> = ({ event }) => {
  const { saleGtv, saleTicketCt } = event.summary;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{GTV}</Name>
        <Value>{formatCurrency(saleGtv)}</Value>
      </Element>
      <Element>
        <Name>{AVG_PRICE}</Name>
        <Value>
          {
            saleTicketCt === 0
              ? NA
              : formatCurrency(saleGtv / saleTicketCt)
          }
        </Value>
      </Element>
    </NarrowWrapper>
  );
};

export default GtvCard;
