/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import calendar from '#images/calendar.svg';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import { CollectionSelectionsContext } from '../../contexts';
import { useOverlayTriggerState } from 'react-stately';
import {
  OverlayContainer, useButton, useOverlayPosition, useOverlayTrigger,
} from 'react-aria';
import Popover from '#/shared/clientReporting/Popover';
import DateFilterMenu from './DateFilterMenu';

const Calendar: React.FC = () => (
  <SVG id='calendar' src={calendar} title='Calendar' />
);

interface DateExtent {
  eventStartFullExtent?: [number, number];
  transactionAtFullExtent?: [number, number];
}

interface CollectionDateFilters extends DateExtent {
  eventStartMin?: string;
  eventStartMax?: string;
  transactionAtMin?: string;
  transactionAtMax?: string;
}

interface Props {
  isLoading?: boolean;
}

const DateFilters: React.FC<Props> = ({ isLoading }) => {
  const state = useOverlayTriggerState({ });
  const triggerRef = useRef();
  const overlayRef = useRef();

  const { overlayProps, triggerProps } = useOverlayTrigger(
    { type: 'dialog' },
    state,
    triggerRef,
  );

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement: 'bottom',
    offset: 4,
    isOpen: state.isOpen,
  });

  const { dateFilters } = useContext(CollectionSelectionsContext);
  const {
    eventStartMin, eventStartMax, transactionAtMin, transactionAtMax,
  } = dateFilters;

  const hasDateFilter = eventStartMin || eventStartMax || transactionAtMin || transactionAtMax;
  const handleCloseDateFilters = state.close;

  useEffect(() => {
    window.addEventListener('resize', handleCloseDateFilters);

    return (): void => {
      window.removeEventListener('resize', handleCloseDateFilters);
    };
  }, [handleCloseDateFilters]);

  const { buttonProps } = useButton(triggerProps, triggerRef);

  return (
    <>
      <Container isLoading={isLoading}>
        <SmallButton
          Icon={<Calendar />}
          forwardedRef={triggerRef}
          isDisabled={isLoading}
          kind={hasDateFilter ? 'primary' : 'secondary'}
          label=''
          {...buttonProps}
          padding='0 1px'
          style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
        />
      </Container>
      {state.isOpen && (
        <OverlayContainer>
          <Popover
            {...overlayProps}
            {...positionProps}
            isOpen={state.isOpen}
            onClose={handleCloseDateFilters}
            ref={overlayRef}
            style={{ left: 700 }}
          >
            <DateFilterMenu close={handleCloseDateFilters} />
          </Popover>
        </OverlayContainer>
      )}
    </>
  );
};

interface LoadingProps {
  isLoading?: boolean;
}

const Container = styled.div<LoadingProps>`
  border-right: 1px solid #ddd;
  opacity: ${({ isLoading }): number => isLoading ? 0.4 : 1};
  padding-left: 8px;
  padding-right: 8px;
`;

export {
  type DateExtent,
  type CollectionDateFilters,
  DateFilters,
};
