import React, { useCallback } from 'react';
import styled from 'styled-components';

import { SmallTag } from '#/shared/Card';
import palette from '#/theme/palettes/main';
import {
  Header, Title, Right, Button, CancelledImg, EditImg,
} from '#/shared/Detail';
import { OverrideChangeLogEntry } from '#/types/Override';

const UPDATE = 'Update';

interface OverrideLogsDetailHeaderProps {
  log: OverrideChangeLogEntry;
  closeDetail: () => void;
  closeable: boolean;
  setOverrideNotesModal: (log: OverrideChangeLogEntry) => void;
  disabled: boolean;
}

const OverrideLogsDetailHeader: React.FC<OverrideLogsDetailHeaderProps> = ({
  log,
  closeDetail,
  closeable,
  setOverrideNotesModal,
  disabled,
}) => {
  const {
    createdAt,
  } = log;

  const openNotesModal = useCallback(() => {
    setOverrideNotesModal(log);
  }, [setOverrideNotesModal, log]);

  return (
    <Header>
      <Title>
        {UPDATE}
      </Title>
      <Right>
        <BlueTag title={`created ${createdAt.toRelative()}`}>
          {'Created ' + createdAt.toRelative()}
        </BlueTag>
      </Right>
      {!disabled && (
        <Right>
          <Button onClick={openNotesModal} title="edit notes">
            <EditImg />
          </Button>
        </Right>
      )}
      {closeable && (
        <Right>
          <Button onClick={closeDetail} title="close override log detail">
            <CancelledImg />
          </Button>
        </Right>
      )}
    </Header>
  );
};

const BlueTag = styled(SmallTag as any)`
  background-color: ${palette.blue.light24};
`;

export default OverrideLogsDetailHeader;
