import { AxiosResponse } from 'axios';
import PriceLevel, { GetPriceListResponse } from '#/types/PriceLevel';
import client from './_clientFormatted';

const getPriceList = async (autobrokerEventId: number): Promise<PriceLevel[]> => {
  const response: AxiosResponse<GetPriceListResponse> = await client.get(
    `/proxy/price_list/${autobrokerEventId}`,
  );

  return response.data.priceList;
};

export default getPriceList;
