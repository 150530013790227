import { Dropdown, Item } from '#/shared/clientReporting/Dropdown';
import ReportingContext from '#/pages/clientReporting/ReportingContext';
import React, {
  useCallback, useContext, useMemo, useEffect,
} from 'react';
import styled from 'styled-components';
import { createQueryParams } from '../utils';
import { InventorySourceType } from '#/types/ReportingRollup';
import { useNavigate } from 'react-router-dom';
import { Label } from '#/shared/clientReporting/ListBox';

const SELECT_INVENTORY_TYPE = 'Select inventory type';
const ALL_TYPES = 'All types';
const inventoryTypeMap: Record<InventorySourceType, string> = {
  [InventorySourceType.PRIMARY]: 'Primary',
  [InventorySourceType.BROKER]: 'Secondary',
  [InventorySourceType.CONSUMER]: 'Consumer',
};

const SelectInventoryType: React.FC<{ types: InventorySourceType[] }> = ({ types }) => {
  const {
    hasPendingData,
    queryParams,
    setReportingSelection,
  } = useContext(ReportingContext);
  const inventoryOptions = useMemo(() => {
    const options = [
      {
        name: '',
        id: -1,
        displayName: ALL_TYPES,
      },
      ...types.map((type: InventorySourceType, index: number) => ({
        name: type,
        id: index,
        displayName: inventoryTypeMap[type],
      })),
    ];

    return options;
  }, [types]);

  const { inventorySourceType: queriedType } = queryParams;
  const navigate = useNavigate();

  useEffect(() => {
    setReportingSelection((prev) => ({
      ...prev,
      inventorySourceType: queriedType,
    }));
  }, [
    queriedType,
    setReportingSelection,
  ]);

  const handleTypeChange = useCallback((type?: InventorySourceType) => {
    if (type) {
      setReportingSelection((prev) => ({
        ...prev,
        inventorySourceType: type,
      }));
      navigate({
        search: createQueryParams(
          queryParams,
          {
            inventorySourceType: type,
            eventId: undefined,
          },
        ),
      });
    } else {
      navigate({
        search: createQueryParams(
          queryParams,
          {
            eventId: undefined,
            inventorySourceType: undefined,
          },
        ),
      });
      setReportingSelection((prev) => ({
        ...prev,
        inventorySourceType: undefined,
      }));
    }
  }, [navigate, queryParams, setReportingSelection]);

  return (
    <Wrapper>
      <Dropdown
        aria-label={SELECT_INVENTORY_TYPE}
        isLoading={hasPendingData}
        items={inventoryOptions}
        onSelectionChange={handleTypeChange}
        placeholder={SELECT_INVENTORY_TYPE}
        selectedKey={queriedType ?? null}
      >
        {(item: { name: string, displayName: string }): JSX.Element => (
          <Item
            key={item.name}
            textValue={item.displayName}
          >
            <Label maxWidth={180}>{item.displayName}</Label>
          </Item>
        )}

      </Dropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-left: 0.3rem;
  border-left: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}`};
  padding: 0 0.3rem 0 0.6rem;
`;

export default SelectInventoryType;
