import React, { useCallback } from 'react';
import styled from 'styled-components';
import InputForm from './InputForm';
import type Action from './types';
import SelectForm from './SelectForm';
import { Label, NotesInput } from './sharedComponents';
import { type PriceExploration, PriceExplorationType } from '#/types/Event';

interface Props {
  disabled: boolean;
  priceExplorationConfig: PriceExploration;
  updatePriceExplorationConfig: React.Dispatch<Action>;
}

const NOTES = 'Notes';

const ExplorationInputs: React.FC<Props> = ({
  disabled,
  priceExplorationConfig,
  updatePriceExplorationConfig,
}) => {
  const onChangeNotes = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newNotes = event?.target?.value;

      updatePriceExplorationConfig({ type: 'notes', value: newNotes });
    },
    [updatePriceExplorationConfig],
  );

  return (
    <>
      <InputsWrapper>
        <SelectForm
          disabled={disabled}
          handleDropdownChange={updatePriceExplorationConfig}
          initialVal={priceExplorationConfig.strategyType}
          label="strategy"
          type={PriceExplorationType.STRATEGY_TYPE}
        />
        <InputForm
          disabled={disabled}
          handleInputChange={updatePriceExplorationConfig}
          initialVal={priceExplorationConfig.frequency}
          label="frequency"
          type={PriceExplorationType.FREQUENCY}
        />
        <InputForm
          disabled={disabled}
          handleInputChange={updatePriceExplorationConfig}
          initialVal={priceExplorationConfig.lowerPercentile}
          label="lower percentile"
          type={PriceExplorationType.LOWER_PERCENTILE}
        />
        <InputForm
          disabled={disabled}
          handleInputChange={updatePriceExplorationConfig}
          initialVal={priceExplorationConfig.upperPercentile}
          label="upper percentile"
          type={PriceExplorationType.UPPER_PERCENTILE}
        />
      </InputsWrapper>
      <Label htmlFor="notes">{NOTES}</Label>
      <NotesInput
        disabled={disabled}
        onChange={onChangeNotes}
        type='text'
      />
    </>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin: 0 1rem;
`;

export default ExplorationInputs;
