
interface Interval {
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
}

interface TimeRange {
  min: Interval;
  max: Interval;
  [key: string]: any;
}

interface SettingsPostBody {
  schedulerInterval: TimeRange;
  profitsUpdaterInterval: TimeRange;
  ingestorInterval: TimeRange;
  supervisorInterval: TimeRange;
  inventoryUpdaterInterval: TimeRange;
  targetSupervisorIntervalTicketsSold: number;
  thresholdSalesSurgeRate: number;
  minAllInPrice: number;
  minDisplayPrice: number;
  minBroadcastPrice: number;
  [key: string]: any;
}

interface Settings extends SettingsPostBody {
  id: number;
  created_at: string;
}

enum TimeUnit {
  SECS = 'seconds',
  MINS = 'minutes',
  HRS = 'hours',
  DAYS = 'days',
}

export default SettingsPostBody;
export { Settings, TimeRange, TimeUnit };
