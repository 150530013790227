import styled from 'styled-components';
import palette from '#/theme/palettes/main';

const Ellipse = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
`;

const GreenEllipse = styled(Ellipse as any)`
  background-color: ${palette.mint.light56};
`;
const RedEllipse = styled(Ellipse as any)`
  background-color: ${palette.salmon.light56};
`;
const YellowEllipse = styled(Ellipse as any)`
  background-color: ${palette.yellow.light56};
`;

export { GreenEllipse, RedEllipse, YellowEllipse };
export default Ellipse;
