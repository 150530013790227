import React from 'react';
import {
  Wrapper, Element, Name, Value,
} from '#/shared/Card';
import { EventConfigTableData } from '../../utils/formatEventInfo';

interface CardProps {
  tableData: EventConfigTableData;
}

const EVENT_START = 'Event Start';
const ONSALE_START = 'Onsale Start';
const PRICING_MODE = 'Pricing Mode';

const EventDateCard: React.FC<CardProps> = ({ tableData }) => {
  return (
    <Wrapper>
      <Element>
        <Name>{ONSALE_START}</Name>
        <Value>{`${tableData.onsaleStartsAt} UTC`}</Value>
      </Element>
      <Element>
        <Name>{EVENT_START}</Name>
        <Value>{tableData.eventStartsAt}</Value>
      </Element>
      <Element>
        <Name>{PRICING_MODE}</Name>
        <Value>{tableData.pricingMode}</Value>
      </Element>
    </Wrapper>
  );
};

export default EventDateCard;
