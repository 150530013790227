import React from 'react';
import styled from 'styled-components';
import { EventTxSummary } from '#/pages/clientReporting/DashboardPage/types';
import { formatColName, formatEventLabel } from '#/pages/clientReporting/DashboardPage/utils';
import { EXTRA_NARROW_BREAKPOINT } from '#/shared/clientReporting/utils';

interface Props {
  measure: string;
  event?: EventTxSummary;
}

const MeasureTitle: React.FC<Props> = ({ measure, event }) => (
  <Container>
    <Measure>{formatColName(measure)}</Measure>
    {
      event && <Event>{`${formatEventLabel(event)}`}</Event>
    }
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Measure = styled.div`
  font-size: 15px;
  font-weight: 650;
  margin-right: 8px;
  white-space: nowrap;
`;

const Event = styled.div`
  font-size: 15px;
  font-weight: 400;
  padding-left: 8px;
  border-left: 1px solid #ccc;
  @media (max-width: ${EXTRA_NARROW_BREAKPOINT}px) {
    font-size: 14px;
    line-height: 1.3;
  };
`;

export default MeasureTitle;
