import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Pages from '#/pages';
import useFetchStakeholders from '#/pages/useFetchStakeholders';
import Providers from './Providers';
import getCurrentUser from '#/api/getUser';
import { User } from '#/types/User';

const Background = styled.div`
  height: 100%;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.secondary};
`;

const Layout: React.FC = () => {
  const [user, setUser] = useState<User>(null);

  useEffect(() => {
    async function populateUser(): Promise<void> {
      const resp = await getCurrentUser();

      setUser(resp);
    }
    populateUser();
  }, []);

  const stakeholderData = useFetchStakeholders();

  return (
    <Providers>
      <Background>
        <Pages stakeholderData={stakeholderData} user={user} />
      </Background>
    </Providers>
  );
};

export default Layout;
