/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  useTab, useTabList, useTabPanel,
  type AriaTabListProps, type AriaTabProps, type AriaTabPanelProps, TabAria,
} from 'react-aria';
import { useTabListState, type TabListState } from 'react-stately';
import type {
  Node, Orientation, DOMAttributes, FocusableElement,
} from '@react-types/shared';

interface TabProps {
  item: Node<AriaTabProps>;
  state: TabListState<AriaTabProps>;
  orientation?: Orientation;
}

const Tab: React.FC<TabProps> = ({ item, state }) => {
  const { key, rendered } = item;
  const ref = React.useRef(null);
  const tabAttributes = useTab({ key }, state, ref);
  const { tabProps, ...rest } = tabAttributes;

  return (
    <StyledTab
      tabProps={tabProps}
      {...tabProps}
      {...rest}
      key={key}
      ref={ref}
    >
      {rendered}
    </StyledTab>
  );
};

type TabPanelProps = AriaTabPanelProps & { state: TabListState<AriaTabProps>}

const TabPanel: React.FC<TabPanelProps> = ({ state, ...props }) => {
  const ref = React.useRef(null);
  const { tabPanelProps } = useTabPanel(props, state, ref);

  return (
    <StyledTabPanel {...tabPanelProps} ref={ref}>
      {state.selectedItem?.props.children}
    </StyledTabPanel>
  );
};

const Tabs: React.FC<AriaTabListProps<AriaTabProps>> = (props) => {
  const state = useTabListState(props);
  const ref = React.useRef(null);
  const { tabListProps } = useTabList(props, state, ref);

  return (
    <TabsWrapper>
      <StyledTabs {...tabListProps} ref={ref}>
        {[...state.collection].map((item) => (
          <Tab item={item} key={item.key} state={state} />
        ))}
      </StyledTabs>
      <TabPanel key={state.selectedItem?.key} state={state} />
    </TabsWrapper>
  );
};

const TabsWrapper = styled.div`
  margin: 0 1rem;
  padding-top: 0.5rem;
  background: #fff;
  border: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.color.border.light}`};
  border-radius: 4px;
`;

const StyledTabs = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
  margin: 0 1rem;
`;

const StyledTabPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  min-height: 700px;
  margin-top: 0.75rem;
`;

type StyledTabProps = DOMAttributes<FocusableElement> & TabAria;

const StyledTab = styled.div<StyledTabProps>`
  padding: 0.35rem 0.75rem 0.65rem 0.75rem;
  cursor: ${({ isDisabled }): string => isDisabled ? 'default' : 'pointer' };
  opacity: ${({ isDisabled, isSelected }): number => {
    if (isDisabled)
      return 0.2;
    return isSelected ? 1 : 0.6;
  }};
  border-bottom: ${({ isSelected, theme }: (TabAria & { theme: Theme })): string => isSelected ? `2px solid ${theme.color.brand}` : undefined};
  :focus {
    outline: none;
  }
  font-weight: 550;
`;

export default Tabs;
