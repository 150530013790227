import { PreviewSnapshot } from '#/types/Preview';
import { SelldownGraphInfo } from '#/types/Snapshot';
import calcGraphDomain from './calcGraphDomain';

// This function handles data formatting for the saved, modeled tickets_target
// data from the /preview GET request, as well as temporary modeled tickets_target
// data from the /preview POST request. Only one or the other is shown in the FE
// at a time, but for better data source visibility we differentiate the dataset name by
// adding "Overlay" when making the POST request.

const ONE = 1;
const ZERO = 0;

const formatPreviewSnapshots = (
  data: PreviewSnapshot[],
  isPost = true,
): SelldownGraphInfo => {
  const dataName = isPost ? 'ticketsTargetOverlay' : 'ticketsTarget';
  const defaultData: SelldownGraphInfo = {
    graphData: { [dataName]: [] },
    graphDataDaysX: { [dataName]: [] },
    graphDomain: { x: [ZERO, ONE], y: [ZERO, ONE] },
  };

  if (!data)
    return defaultData;

  const plotPoints = data.map((el) => ({
    x: el.logHoursToEvent,
    y: el.ticketsTarget,
  }));

  const plotPointsDaysX = data.map((el) => ({
    x: el.daysToEvent,
    y: el.ticketsTarget,
  }));

  const graphData = { [dataName]: plotPoints };
  const graphDataDaysX = { [dataName]: plotPointsDaysX };

  // Standard selldown graph domain represents log-hours-to-event for x-axis
  const graphDomain = calcGraphDomain(graphData);

  return {
    graphData,
    graphDataDaysX,
    graphDomain,
  };
};

export default formatPreviewSnapshots;
