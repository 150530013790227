import React from 'react';
import ShowImageButton from './ShowImageButton';
import clockicon from '#images/clockicon.svg';

interface ShowButtonProps {
  show: boolean;
  toggleShow: () => void;
}

const ShowHelperButton: React.FC<ShowButtonProps> = ({ show, toggleShow }) => {
  return (
    <ShowImageButton
      image={clockicon}
      imageText="clock"
      show={show}
      toggleShow={toggleShow}
    />
  );
};

export default ShowHelperButton;
