import React, { useContext } from 'react';
import styled from 'styled-components';
import palette from '#/theme/palettes/main';
import Loader from '#/shared/Loader';
import useCsvUpload from './useCsvUpload';
import Input from './Input';
import SubmitButton from './SubmitButton';
import DeleteButton from './DeleteButton';
import renderResponse from './renderResponse';
import { Wrapper } from '#/shared/Card';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';

interface PricingConstraintsProps {
  eventId: number;
  hasPriceConstraints: boolean;
  setModalSuccess?: (input: boolean) => void;
}
const PRICING_CONSTRAINTS = 'Price Constraints';
const NOTES = 'Notes';

const PricingConstraintsButton: React.FC<PricingConstraintsProps> = ({
  eventId,
  hasPriceConstraints,
  setModalSuccess,
}) => {
  const context = useContext(ActiveEventContext);
  // eslint-disable-next-line react/destructuring-assignment
  const retrieveActiveEvent = context?.retrieveActiveEvent;
  const {
    disabled,
    error,
    fileName,
    handleSubmit,
    handleDelete,
    loading,
    onFileChange,
    onNotesChange,
    success,
  } = useCsvUpload(eventId, retrieveActiveEvent, setModalSuccess);

  return (
    <Wrapper>
      <div>{PRICING_CONSTRAINTS}</div>
      {!loading && (
        <>
          <Form>
            <Input fileName={fileName} onFileChange={onFileChange} />
            <Label>{NOTES}</Label>
            <NotesInput
              onChange={onNotesChange}
              type='text'
            />
            <ButtonsWrapper>
              <SubmitButton disabled={disabled} onClick={handleSubmit} />
              { hasPriceConstraints && <DeleteButton onClick={handleDelete} /> }
            </ButtonsWrapper>
          </Form>
        </>
      )}
      {loading && <Loader hexColor={palette.brand.base} size={2} />}
      {renderResponse(error, success)}
    </Wrapper>
  );
};

const Form = styled.form`
  position: relative;
  width: 100%;
  height: 50%;
  top: 0;

  input[type='file'] {
    ${({ theme }: { theme: Theme }): string => theme.text2};
    position: absolute;
    top: 0rem;
    left: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .uploadLabel {
    position: absolute;
    width: 100%;
    display: block;
    font-size: 1rem;
    color: ${palette.brand.base};
    text-decoration: underline;
    text-align: center;
  }
`;

const Label = styled.label`
  ${({ theme }: { theme: Theme }): string => theme.text3};
  position: absolute;
  top: 1.65rem;
  left: 1.0rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem;
`;

const NotesInput = styled.input`
position: absolute;
top: 1.5rem;
left: 4.5rem;
display: flex;
justify-content: center;
width: 15rem;
height: 1rem;
border: 1px solid ${palette.silver.dark};
font-size: 12px;
text-align: center;
border: 1px solid ${palette.silver.dark};
border-radius: 8px;
padding: 4px;
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 3rem;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`;

export default PricingConstraintsButton;
