import {
  useEffect, useState, useCallback, useContext, useMemo,
} from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { ActiveEventContext } from '../../../contexts/ActiveEvent';
import getPreview from '#/api/getPreview';
import { GetPreviewParams, PreviewData } from '#/types/Preview';
import formatApiError from '#/api/utils/formatApiError';

type Hook = () => {
  fetchPreview: () => Promise<void>;
  listingsLoading: boolean;
  previewData: PreviewData;
  previewError?: string;
  previewLoading: boolean;
};

// look for these external marketplaces when fetching listings
const externalMarketplace = ['stubhub', 'vivid seats'];
const PARAMS: GetPreviewParams = {
  externalMarketplace,
  includeListings: true,
};

const useFetchPreview: Hook = () => {
  const isVisible = usePageVisibility();
  const { activeEvent } = useContext(ActiveEventContext);
  const eventId = useMemo(() => activeEvent?.id, [activeEvent]);
  const [previewLoading, setPreviewLoading] = useState<boolean>(true);
  const [previewError, setPreviewError] = useState<string | undefined>();
  const [listingsLoading, setListingsLoading] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<PreviewData>({
    priceDistributionListings: {
      autobrokerListings: [], competitorListings: [],
    },
    priceDistributionGraphInfo: null,
    selldownTargetInfo: null,
  });

  const fetchPreview = useCallback(async (): Promise<void> => {
    setPreviewError('');
    try {
      const fetched = await getPreview(eventId, PARAMS);

      setPreviewData(fetched);
    } catch (err) {
      const errorString = formatApiError(err);

      setPreviewError(errorString);
    }
    setPreviewLoading(false);
    setListingsLoading(false);
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      setPreviewLoading(true);
      setListingsLoading(true);
      fetchPreview();
    }
  }, [eventId, fetchPreview]);

  // refresh the preview data
  useEffect(() => {
    const interval = setInterval(() => {
      if (eventId && isVisible)
        fetchPreview();
    }, 40000);

    return (): void => clearInterval(interval);
  }, [eventId, fetchPreview, isVisible]);

  return {
    fetchPreview,
    listingsLoading,
    previewData,
    previewError,
    previewLoading,
  };
};

export default useFetchPreview;
