import React from 'react';
import styled from 'styled-components';
import Marketplace from '#images/Marketplace.svg';
import { Option } from '#/pages/useFetchStakeholders';

const formatOptionLabel = (stakeholder: Option<string>): JSX.Element => {
  return (
    <LogoContainer>
      <Logo src={stakeholder.logoUrl ?? Marketplace} />
      <span>{stakeholder.label}</span>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  object-fit: cover;
  object-position: center;
`;

export default formatOptionLabel;
