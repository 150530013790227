import { useState, useEffect, useCallback } from 'react';
import { User, UserFilters } from '#/types/User';
import getUsersList from '#/api/getUsersList';

interface UseUsersListHook {
  users: User[];
  timestamp: Date;
  isLoading: boolean;
  hasError: boolean;
  filters: UserFilters,
  setFilters: (newFilters: UserFilters) => void,
  retry: () => void;
}

const useFetchUsersList = (): UseUsersListHook => {
  const [users, setUsers] = useState<User[]>([]);
  const [timestamp, setTimestamp] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [filters, setFilters] = useState<UserFilters>({});

  const getUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      setHasError(false);
      const results = await getUsersList(filters);

      setUsers(results);
      setTimestamp(new Date());
    } catch (err) {
      console.error(err);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [filters]);

  const retry = getUsers;

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return {
    users,
    timestamp,
    isLoading,
    hasError,
    filters,
    setFilters,
    retry,
  };
};

export default useFetchUsersList;
