import React from 'react';
import styled from 'styled-components';

const ERROR_MSG = 'Sorry, something went wrong.';
const RETRY_MSG = 'Please try again';
const PERIOD = '.';

interface Props {
  retry?: () => void;
}

const RollupErrorComponent: React.FC<Props> = ({ retry }) => (
  <Container>
    {ERROR_MSG}
    {retry ? (
      <>
        <Retry onClick={retry}>{RETRY_MSG}</Retry>
      </>
    ) : (
      <>
        &nbsp;
        {RETRY_MSG}
      </>
    )}
    {PERIOD}
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 3rem);
  min-height: 90px;
`;

const Retry = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    opacity: 0.6;
  }
  transition: all 0.2s ease-in-out;
  text-decoration: underline;
  padding-right: 0;
`;

export { ERROR_MSG };
export default RollupErrorComponent;
