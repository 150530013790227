import { DateTime } from 'luxon';
import {
  DynamicPricingModel, PricingBasis, PricingMode, TrainingSetType, SectionSubsetType,
} from '#/types/Event';
import { UpdateEventConfig, UpdateEventConfigType } from '#/pages/ViewPage/components/Configuration/EventConfig/types';

interface Action {
  type: ConfigType | EventType | UpdateEventConfigType | 'reset';
  value:
  | number
  | EventConfig
  | IntegrationConfig
  | SinkConfig
  | UpdateEventConfig
  | ''
  | boolean
  | DateTime
  | string
  | string[];
}

enum ConfigType {
  ACTIVATED_AT = 'activatedAt',
  AUTOBROKER_EVENT_ID = 'autobrokerEventId',
  EXTERNAL_EVENT_ID = 'externalEventId',
  CREATED_AT = 'createdAt',
  ID = 'id',
  INTEGRATION_TYPE = 'integrationType',
  LISTING_MAX_COUNT = 'listingMaxCount',
  LISTING_MIN_COUNT = 'listingMinCount',
  MARKETPLACES = 'marketplaces',
  P_VISIBLE = 'pVisible',
  QA_MODE = 'qaMode',
  RESET_VISIBILITY = 'resetVisibility',
  CHURN_VISIBILITY_RATE = 'churnVisibilityRate',
  PRICE_INCREMENT = 'priceIncrement',
  SINK_EXTERNAL_EVENT_ID = 'sinkExternalEventId',
  SINK_ID = 'sinkId',
  SINK_NAME = 'sinkName',
  UPDATED_AT = 'updatedAt',
  NOTES = 'notes',
}

interface SinkConfig {
  [ConfigType.ID]: number;
  [ConfigType.SINK_ID]: number;
  [ConfigType.AUTOBROKER_EVENT_ID]: number;
  [ConfigType.SINK_NAME]: string;
  [ConfigType.SINK_EXTERNAL_EVENT_ID]: string;
  [ConfigType.P_VISIBLE]: number;
  [ConfigType.LISTING_MAX_COUNT]: number;
  [ConfigType.LISTING_MIN_COUNT]: number;
  [ConfigType.ACTIVATED_AT]?: DateTime;
  [ConfigType.MARKETPLACES]?: string[];
  [ConfigType.QA_MODE]: boolean;
  [ConfigType.RESET_VISIBILITY]: boolean;
  [ConfigType.CHURN_VISIBILITY_RATE]: number;
  [ConfigType.PRICE_INCREMENT]: number;
  [ConfigType.NOTES]?: string;
}

interface IntegrationConfig {
  [ConfigType.ACTIVATED_AT]?: string;
  [ConfigType.AUTOBROKER_EVENT_ID]: number;
  [ConfigType.EXTERNAL_EVENT_ID]?: string;
  [ConfigType.INTEGRATION_TYPE]: string;
  [ConfigType.LISTING_MAX_COUNT]: number;
  [ConfigType.LISTING_MIN_COUNT]: number;
  [ConfigType.P_VISIBLE]: number;
  [ConfigType.CHURN_VISIBILITY_RATE]: number;
  [ConfigType.PRICE_INCREMENT]?: number;
  [ConfigType.MARKETPLACES]: string[];
  [ConfigType.QA_MODE]: boolean;
  [ConfigType.NOTES]?: string;
}

enum EventType {
  GROUPS = 'groups',
  ONSALE_STARTS_AT = 'onsaleStartsAt',
  PRICING_MODE = 'pricingMode',
  SEATGEEK_EVENT_ID = 'seatgeekEventId',
  SECTION_SUBSET_SECTIONS = 'sectionSubsetSections',
  SECTION_SUBSET_TYPE = 'sectionSubsetType',
  SUPERVISOR_STARTS_AT = 'supervisorStartsAt',
  SUPERVISOR_STOPS_AT = 'supervisorStopsAt',
  KEEP_SUPERVISOR_ACTIVE = 'keepSupervisorActive',
  USE_SEATGEEK_TRANSACTIONS = 'useSeatgeekTransactions',
  DEMAND_CHANGEPOINT_CT = 'demandChangepointCt',
  PRICING_BASIS = 'pricingBasis',
  ONSALE_DEMAND_FRACTION = 'onsaleDemandFraction',
  SELLDOWN_PROJECTION_MODEL = 'selldownProjectionModel',
  ONSALE_PRICING_MODEL = 'onsalePricingModel',
  DYNAMIC_PRICING_MODEL = 'dynamicPricingModel',
  NOTES = 'notes',
}

interface EventConfig {
  [EventType.GROUPS]?: string[];
  [EventType.ONSALE_STARTS_AT]: DateTime;
  [EventType.PRICING_MODE]: PricingMode;
  [EventType.SEATGEEK_EVENT_ID]: number;
  [EventType.SECTION_SUBSET_TYPE]?: SectionSubsetType;
  [EventType.SECTION_SUBSET_SECTIONS]?: string;
  [EventType.SUPERVISOR_STARTS_AT]?: DateTime;
  [EventType.SUPERVISOR_STOPS_AT]?: DateTime;
  [EventType.KEEP_SUPERVISOR_ACTIVE]?: boolean;
  [EventType.USE_SEATGEEK_TRANSACTIONS]?: boolean;
  [EventType.DEMAND_CHANGEPOINT_CT]?: number;
  [EventType.PRICING_BASIS]?: PricingBasis;
  [EventType.ONSALE_DEMAND_FRACTION]?: number;
  [EventType.SELLDOWN_PROJECTION_MODEL]?: TrainingSetType;
  [EventType.DYNAMIC_PRICING_MODEL]?: DynamicPricingModel;
  [EventType.DYNAMIC_PRICING_MODEL]?: DynamicPricingModel;
  [EventType.NOTES]?: string;
}

export {
  ConfigType, EventConfig, EventType, IntegrationConfig, SinkConfig,
};

export default Action;
