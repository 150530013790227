import { GraphPlot, Model } from '#/types/Snapshot';

interface Response {
  modelLogPriceRatio: GraphPlot;
  targetLogPriceRatio: GraphPlot;
}

const formatModelData = (model: Model, logHoursToEvent: number): Response => {
  return {
    modelLogPriceRatio: {
      x: logHoursToEvent,
      y: model ? model.logPriceRatio : null,
    },
    targetLogPriceRatio: {
      x: logHoursToEvent,
      y: model ? model.logPriceRatioTarget : null,
    },
  };
};

export default formatModelData;
