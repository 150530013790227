import client from './_client';
import Ingestion, { IngestionFilters } from '#/types/Ingestion';
import mapIngestion from './utils/mapIngestion';

const getIngestions = async (
  filters: IngestionFilters,
): Promise<[Ingestion[], number]> => {
  const response = await client.get('/proxy/ingestion_listings', { params: filters });
  const rawIngestions = response?.data?.ingestion_listings || [];
  const perPage = response?.data?.request?.per_page;

  return [rawIngestions.map(mapIngestion), perPage];
};

export default getIngestions;
