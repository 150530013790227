import { GraphDomain, GraphPlot, SelldownGraphInfo } from './Snapshot';

interface PreviewApiResponse {
  autobrokerEventId: number;
  distributions: Distributions;
  selldown: PreviewSnapshot[];
  listings: Listing[];
}

interface Distributions {
  autobroker?: Data;
  competitor?: Data;
  model?: Data;
}

interface Data {
  boundaries: Boundary[];
  listingCt: number;
  ticketCt: number;
}

interface Boundary {
  logExpectedValue: number;
  logPriceRatio: PriceRatioData;
  probability: number;
}

interface PriceRatioData {
  pct5: number;
  pct10: number;
  pct25: number;
  pct50: number;
  pct75: number;
  pct90: number;
  pct95: number;
  target: number;
}

interface PreviewSnapshot {
  snapshotAt: string;
  daysToEvent: number;
  logHoursToEvent: number;
  ticketsTarget: number;
}

enum ListingSubset {
  COMPETITOR = 'competitor',
  AUTOBROKER = 'autobroker',
}

interface Listing {
  listingSubset: ListingSubset;
  inventorySourceType: string;
  sellerId?: number;
  section: string;
  row: string;
  quantity: number;
  allInPrice: number;
  displayPrice: number;
  logPriceRatio: number;
  logPriceRatioAdj?: number;
  logExpectedValue: number;
  id?: string;
  market?: string;
  marketplace?: string;
  isInstant?: boolean;
}

interface PriceDistributionGraphInfo {
  priceDistributionGraphData: PriceDistributionGraphData;
  priceDistributionGraphDomain: GraphDomain;
}

interface PriceGraphPlot extends GraphPlot {
  yPrice?: number;
  y0Price?: number;
  y1Price?: number;
}

interface PriceDistributionGraphPlot {
  graphPlot: PriceGraphPlot[];
  probability: number;
}

interface PriceDistributionGraphData {
  autobrokerWide?: PriceDistributionGraphPlot[];
  autobrokerMedium?: PriceDistributionGraphPlot[];
  autobrokerNarrow?: PriceDistributionGraphPlot[];
  autobrokerTarget?: PriceDistributionGraphPlot[];
  competitorWide?: PriceDistributionGraphPlot[];
  competitorMedium?: PriceDistributionGraphPlot[];
  competitorNarrow?: PriceDistributionGraphPlot[];
  competitorTarget?: PriceDistributionGraphPlot[];
  modelWide?: PriceDistributionGraphPlot[];
  modelMedium?: PriceDistributionGraphPlot[];
  modelNarrow?: PriceDistributionGraphPlot[];
  modelTarget?: PriceDistributionGraphPlot[];
  modelWideOverlay?: PriceDistributionGraphPlot[];
  modelMediumOverlay?: PriceDistributionGraphPlot[];
  modelNarrowOverlay?: PriceDistributionGraphPlot[];
  modelTargetOverlay?: PriceDistributionGraphPlot[];
}

interface PriceDistributionListings {
  autobrokerListings: GraphPlot[];
  competitorListings: GraphPlot[];
}

interface FormattedBoundary {
  wide: PriceDistributionGraphPlot;
  medium: PriceDistributionGraphPlot;
  narrow: PriceDistributionGraphPlot;
  target: PriceDistributionGraphPlot;
}

interface PreviewData {
  priceDistributionListings: PriceDistributionListings;
  priceDistributionGraphInfo: PriceDistributionGraphInfo;
  selldownTargetInfo: SelldownGraphInfo;
  listingMarketplaces?: string[];
}

interface GetPreviewParams {
  externalMarketplace?: string | string[];
  includeListings?: boolean;
}

export default PriceDistributionGraphInfo;
export {
  Boundary,
  Data,
  Distributions,
  Listing,
  ListingSubset,
  FormattedBoundary,
  PreviewApiResponse,
  PreviewData,
  PreviewSnapshot,
  PriceDistributionGraphData,
  PriceDistributionGraphPlot,
  PriceDistributionListings,
  GetPreviewParams,
};
