import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Content } from '#/shared/modalComponents';
import { ButtonSmall } from '#/shared/Button';
import modalStyles from './modalStyles';
import useSetupModal from '#/pages/useSetupModal';

interface Props {
  clearError: () => void;
  error: string;
}

const HEADLINE = 'This action was unsuccessful';
const CLOSE = 'Close';

const ErrorModal: React.FC<Props> = ({ clearError, error }) => {
  useSetupModal(clearError);

  return error ? (
    <Modal
      isOpen
      style={modalStyles}
    >
      <HeaderText>{HEADLINE}</HeaderText>
      <Content className='errorModal modalContent'>
        <ErrorMessage>{error}</ErrorMessage>
      </Content>
      <ButtonSmall onClick={clearError}>{CLOSE}</ButtonSmall>
    </Modal>
  ) : null;
};

const ErrorMessage = styled.p`
  margin: 0 0 1rem 0;
`;

const HeaderText = styled.h4`
  ${({ theme }: { theme: Theme }): string => theme.header4};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.error};
  margin: 1rem 0;
`;

export default ErrorModal;
