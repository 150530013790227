import styled from 'styled-components';

const DropdownButton = styled.button`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.highlight};
  text-decoration: none;
  font-weight: 600;
  padding: 15px;
  background: rgba(0,0,0,0.0);
`;

const UploadLabel = styled.label`
  background-color: transparent;
  text-decoration: none;
  display: block;
  cursor: pointer;
`;

const UploadWrapper = styled.div`
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  &:hover {
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.highlight};
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  cursor: pointer;  
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${DropdownButton} {
    background-color: #fff2f0;
  }
  &:hover ${DropdownContent} {
    display: block;
  }
`;

export {
  Dropdown,
  DropdownContent,
  UploadWrapper,
  UploadLabel,
  DropdownButton,
};
