import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import LeftArrow from '#images/LeftArrow.svg';

interface HeaderProps {
  toggleOpen: MouseEventHandler<HTMLButtonElement>;
}

const EVENTS = 'Events';

const BackButton: React.FC<HeaderProps> = ({ toggleOpen }) => {
  return (
    <ButtonWrapper onClick={toggleOpen}>
      <img alt='back' src={LeftArrow} />
    </ButtonWrapper>
  );
};

const Header: React.FC<HeaderProps> = ({ toggleOpen }) => {
  return (
    <Wrapper>
      <Title>{EVENTS}</Title>
      <BackButton toggleOpen={toggleOpen} />
    </Wrapper>
  );
};

const ButtonWrapper = styled.button`
  border: none;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
  height: 0.875rem;
  width: 1rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
`;

const Wrapper = styled.div`
  position: fixed;
  width: 17.5rem;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};

  img {
    cursor: pointer;
  }
`;

export default Header;
