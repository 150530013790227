import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';
import getIngestions from '#/api/getIngestions';
import queryToIngestionFilters from '#/api/utils/queryToIngestionFilters';
import Ingestion, { IngestionFilters } from '#/types/Ingestion';

const PER_PAGE = 15;
const SORT_BY = 'id';
const SORT_ASC = 0;

interface UseIngestionsHook {
  ingestions: Ingestion[];
  perPage: number,
  pages: number[];
  nextPage: () => void;
  prevPage: () => void;
  filters: IngestionFilters;
  setFilters: (newFilters: IngestionFilters) => void;
  retry: () => void;
  showError: boolean;
  showLoader: boolean;
  showResults: boolean;
  setPinnedIngestions: (ingestions: Ingestion[]) => void;
}

const useFetchIngestions = (initial?: IngestionFilters): UseIngestionsHook => {
  const initialFiltersString = JSON.stringify(initial);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const isVisible = usePageVisibility();

  const [filters, setFiltersRaw] = useState<IngestionFilters>(
    {
      sort_by: SORT_BY,
      sort_asc: SORT_ASC,
      per_page: PER_PAGE,
      is_pinned: false,
      ...initial,
      ...queryToIngestionFilters(search),
    },
  );
  const [pages, setPages] = useState<number[]>([]);
  const [ingestions, setIngestions] = useState<Ingestion[]>([]);
  const [perPage, setPerPage] = useState<number>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [pinnedIngestions, setPinnedIngestions] = useState<Ingestion[]>([]);

  const retry = useCallback(async () => {
    setHasError(false);
    try {
      if (filters.is_pinned) {
        setIngestions(pinnedIngestions);
      } else if (filters.autobroker_event_id || !initialFiltersString) {
        const [returnedIngestions, returnedPerPage] = await getIngestions(filters) || [];

        setIngestions(returnedIngestions);
        setPerPage(returnedPerPage);
      }
    } catch {
      setHasError(true);
    }
    setLoading(false);
  }, [filters, initialFiltersString, pinnedIngestions]);

  const updateQuery = useCallback(() => {
    if (pathname === '/ingestions') {
      const filterStrings = Object.entries(filters)
        .filter((key_value) => typeof key_value[1] !== 'undefined' && key_value[1] !== null)
        .map(([key, value]) => [key, String(value)]);

      navigate({
        pathname: pathname,
        search: '?' + new URLSearchParams(filterStrings).toString(),
      });
    }
  }, [filters, pathname, navigate]);

  const showResults = useMemo(() => !isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showLoader = useMemo(() => isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showError = useMemo(() => !isLoading && hasError, [
    hasError,
    isLoading,
  ]);

  // refresh the event data
  useEffect(() => {
    setLoading(true);
    retry();
    const interval = setInterval(() => {
      if (pages.length === 0 && isVisible)
        retry();
    }, 30000);

    return (): void => clearInterval(interval);
  }, [pages.length, retry, isVisible]);

  useEffect(() => {
    updateQuery();
  }, [updateQuery]);

  const nextPage = useCallback(() => {
    setPages((p) => [...p, filters.from_id]);
    const fromId = ingestions[ingestions.length - 1].id;

    setFiltersRaw({ ...filters, from_id: fromId });
  },
  [ingestions, filters]);

  const prevPage = useCallback(() => {
    setFiltersRaw({ ...filters, from_id: pages[pages.length - 1] });
    setPages((p) => p.slice(0, p.length - 1));
  },
  [filters, pages]);

  const setFilters = useCallback((newFilters: IngestionFilters) => {
    setFiltersRaw({ ...newFilters, from_id: undefined });
    setPages([]);
  }, []);

  return {
    ingestions,
    perPage,
    pages,
    nextPage,
    prevPage,
    filters,
    setFilters,
    retry,
    showError,
    showLoader,
    showResults,
    setPinnedIngestions,
  };
};

export { PER_PAGE, UseIngestionsHook };
export default useFetchIngestions;
