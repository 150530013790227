const HEADING = 'Date filters';
const EVENT_START = 'Event start';
const TRANSACTION_AT = 'Transaction at';
const DASH = '–';

const CANCEL = 'Cancel';
const APPLY = 'Apply';

export {
  HEADING,
  EVENT_START,
  TRANSACTION_AT,
  DASH,
  CANCEL,
  APPLY,
};
