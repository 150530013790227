import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { GG, ScaleFill, Labels } from '@graphique/graphique';
import { Theme } from '#/shared/graphique';
import { Legend as FillLegend } from '@graphique/geom-point';
import type { MapRollupDatum } from '#/types/MapRollup';
import { MetricOption } from '../utils/menuOptions';
import { interpolateRdYlBu } from 'd3-scale-chromatic';
import styled from 'styled-components';

interface Props {
  data?: MapRollupDatum[];
  metricSelection: MetricOption;
}

const Legend: React.FC<Props> = ({ data, metricSelection }) => {
  const formatLegendTicks = useCallback((v: number) => (
    metricSelection.format({ value: v, showDecimal: false })), [metricSelection]);

  // render this in a portal that references a parent node
  // to place this in a container that is
  // - outside of the visualization drawing area, but
  // - dependendent on related data.
  //
  // this allows the visualization to fill the rest of the tab content container
  // and maintain some logical/organizational separation between visualizations and
  // things that are not visualizations.
  return ReactDOM.createPortal(
    <Wrapper>
      <GG
        aes={{
          x: (): number => 0,
          fill: (d): number => d.metrics?.[metricSelection.key],
        }}
        data={data}
        height={0}
        width={320}
      >
        <Labels
          header={(
            <FillLegend
              format={formatLegendTicks}
              style={{ fontSize: '0.725rem' }}
              title={<Title>{metricSelection.label}</Title>}
            />
          )}
        />
        <ScaleFill reverse values={interpolateRdYlBu} />
        <Theme />
      </GG>
    </Wrapper>,
    document.getElementById('tab-controls'),
  );
};

const Wrapper = styled.div`
  padding: 0 1rem;
`;

const Title = styled.span`
  font-size: 0.85rem;
  font-weight: 600;
`;

export default Legend;
