import React from 'react';
import styled from 'styled-components';
import Loader from '#/shared/Loader';

const Loading: React.FC = () => (
  <CenterContainer>
    <Loader />
  </CenterContainer>
);

const CenterContainer = styled.div`
  height: 100%;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default Loading;
