import { AxiosResponse } from 'axios';
import client from './_client';
import { SectionRowPriceConstraint, APIPriceConstraint } from '#/types/PriceConstraints';

const getSectionRowPriceConstraints = async (eventId: number):
Promise<SectionRowPriceConstraint[]> => {
  const response: AxiosResponse<APIPriceConstraint> = await client.get(`/proxy/price_constraints/${eventId}?type=raw`);

  const sectionRowPriceConstraints = response?.data?.raw_price_constraints;

  return sectionRowPriceConstraints;
};

export default getSectionRowPriceConstraints;
