import React, {
  useMemo, useState, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import ReportingContext from '#/pages/clientReporting/ReportingContext';
import { MarketplaceMixMeasure } from '#/pages/clientReporting/DashboardPage/types';
import { SectionTitleContainer, SectionTitle } from '#/shared/clientReporting/typography';
import SummaryBar from './SummaryBar/Bar';
import { createMarketplaceSummary } from './utils';
import { EXTRA_NARROW_BREAKPOINT } from '#/shared/clientReporting/utils';
import useFetchReportingRollup from '#/pages/useFetchReportingRollup';

const TITLE = 'Marketplace Mix';
const DEFAULT_MEASURE: MarketplaceMixMeasure = 'revenue';

const MarketplaceMix: React.FC = () => {
  const [measure] = useState<MarketplaceMixMeasure>(DEFAULT_MEASURE);

  const {
    stakeholder,
    setHasPendingData,
    queryParams,
    reportingSelection,
  } = useContext(ReportingContext);

  const {
    rollup, isLoading, hasError, fetchRollup,
  } = useFetchReportingRollup({
    stakeholder: stakeholder?.value,
    group: ['marketplace'],
    ...queryParams,
  });

  const marketplaceMix = useMemo(() => (
    createMarketplaceSummary(rollup, measure)
  ), [rollup, measure]);

  useEffect(() => {
    setHasPendingData(isLoading);
  }, [isLoading, setHasPendingData]);

  const shouldLoad = useMemo(() => (
    isLoading && (rollup?.length === 0 || !reportingSelection?.selectedEvent)
  ), [isLoading, rollup, reportingSelection]);

  return (
    <Container>
      <SectionTitleContainer>
        <SectionTitle>{TITLE}</SectionTitle>
      </SectionTitleContainer>
      <ChartContainer>
        <SummaryBar
          data={marketplaceMix}
          hasError={hasError}
          loading={shouldLoad}
          measure={measure}
          retry={fetchRollup}
          selectedEvent={reportingSelection?.selectedEvent}
        />
      </ChartContainer>
    </Container>
  );
};

const ChartContainer = styled.div`
  display: flex;
  padding-top: 1rem;
  justify-content: center;
  align-items: center;
  min-height: 140px;
`;

const Container = styled.div`
  margin: 1rem 1rem 0 1rem;
  min-height: 282px;
  @media (max-width: ${EXTRA_NARROW_BREAKPOINT}px) {
    margin: 1rem 0 0 0;
  };
`;

export { DEFAULT_MEASURE };
export default MarketplaceMix;
