import { AxiosResponse } from 'axios';
import { Deal, PostDealRequest } from '#/types/Deal';
import client from './_clientFormatted';

const patchDeal = async (dealId: number, request: PostDealRequest): Promise<Deal> => {
  const response: AxiosResponse<Deal> = await client.patch(`/proxy/deals/${dealId}`, request);

  return response?.data;
};

export default patchDeal;
