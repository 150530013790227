import React from 'react';
import styled from 'styled-components';
import { EventErrorName } from '#/types/Errors';

const EventLabel: React.FC<EventErrorName> = ({ title, venue }) => (
  <Container data-tag='allowRowEvents'>
    <Title data-tag='allowRowEvents'>{title}</Title>
    <Venue data-tag='allowRowEvents'>{venue}</Venue>
  </Container>
);

const Container = styled.div``;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
`;

const Venue = styled.div`
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.38;
`;

export default EventLabel;
