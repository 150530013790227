import React, { useState } from 'react';
import { SourceHeaders } from '../constants';
import TableWrapper, {
  Cell,
  Header,
  RowWrapper,
} from '#/shared/TableComponents';
import LinkButton from './LinkButton';
import SourceLinkModal, { useLinkModal } from './SourceLinkModal';
import { SourceEvent } from '#/types/Event';

interface SourceTableProps {
  disabled: boolean;
  columns: SourceHeaders;
  retry: () => void;
  tableData: SourceEvent[];
}

interface BodyProps {
  disabled: boolean;
  columnNames: Array<keyof SourceHeaders>;
  openModal: (source: SourceEvent) => void;
  tableData: SourceEvent[];
}

interface HeaderProps {
  columns: SourceHeaders;
  columnNames: Array<keyof SourceHeaders>;
}

const SourceBody: React.FC<BodyProps> = ({
  disabled,
  columnNames,
  openModal,
  tableData,
}) => {
  return (
    <tbody>
      {tableData.map((source) => (
        <RowWrapper key={source.sourceId}>
          {columnNames.map((el) => (
            <Cell key={`${el}_${source[el]}`}>{String(source[el])}</Cell>
          ))}
          <LinkButton disabled={disabled} openModal={openModal} sourceData={source} />
        </RowWrapper>
      ))}
    </tbody>
  );
};

const SourceHeader: React.FC<HeaderProps> = ({ columns, columnNames }) => {
  const LINK = 'Link / Unlink';

  return (
    <thead>
      <Header>
        {columnNames.map((el) => (
          <Cell key={`${columns[el]}_${el}`}>{columns[el]}</Cell>
        ))}
        <Cell>{LINK}</Cell>
      </Header>
    </thead>
  );
};

const SourceTable: React.FC<SourceTableProps> = ({
  disabled,
  columns,
  retry,
  tableData,
}) => {
  const [activeSource, setActiveSource] = useState<SourceEvent>(null);
  const {
    cancelButtonText,
    closeModal,
    handleClickLink,
    linkButtonText,
    linkError,
    linkLoading,
    linkModalIsOpen,
    linkModalText,
    openModal,
  } = useLinkModal(activeSource, retry, setActiveSource);

  const columnNames = Object.keys(columns) as Array<keyof typeof columns>;

  return (
    <>
      <TableWrapper>
        <SourceHeader columnNames={columnNames} columns={columns} />
        <SourceBody
          columnNames={columnNames}
          disabled={disabled}
          openModal={openModal}
          tableData={tableData}
        />
      </TableWrapper>
      <SourceLinkModal
        cancelButtonText={cancelButtonText}
        closeModal={closeModal}
        handleClickLink={handleClickLink}
        linkButtonText={linkButtonText}
        linkError={linkError}
        linkLoading={linkLoading}
        linkModalIsOpen={linkModalIsOpen}
        linkModalText={linkModalText}
      />
    </>
  );
};

export default SourceTable;
