import { Listing, PriceDistributionListings } from '#/types/Preview';

const formatPriceDistributionListings = (
  rawData: Listing[] | null,
): PriceDistributionListings => {
  const startingVal: PriceDistributionListings = {
    autobrokerListings: [],
    competitorListings: [],
  };

  if (!rawData)
    return startingVal;

  return rawData.reduce((acc: PriceDistributionListings, cur: Listing) => {
    const {
      listingSubset,
      logExpectedValue,
      logPriceRatio,
      ...rest
    } = cur;

    const subset = `${listingSubset}Listings` as keyof PriceDistributionListings;
    const plotPoint = {
      ...rest,
      x: logExpectedValue,
      y: logPriceRatio,
    };

    acc[subset].push(plotPoint);
    return acc;
  }, startingVal);
};

const getUniqueMarketplaces = (listingsData: Listing[]): string[] => (
  Array.from(new Set(listingsData?.map((d) => d.marketplace)))
);

export {
  formatPriceDistributionListings,
  getUniqueMarketplaces,
};
