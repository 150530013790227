import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipContainer, TooltipContent } from '@graphique/graphique';
import { formatCurrency } from '#/shared/formatNumberForDisplay';
import { Rollup } from '#/types/ReportingRollup';

const ENDASH = '–';

const renderTooltip = (
  value: TooltipContent<Rollup>[], isTicketCount = true,
): React.ReactNode => {
  if (!value[0])
    return null;

  const { datum, formattedY } = value[0];
  const { group } = (datum?.[0]);
  const { priceLower, priceUpper } = group;
  const TICKETS = ` ticket${formattedY === '1' ? '' : 's'}`;

  return (
    <TooltipContainer>
      <TooltipWrapper>
        <BinLabel>
          {`${formatCurrency(priceLower)} ${ENDASH} ${formatCurrency(priceUpper)}`}
        </BinLabel>
        <ValueLabel>
          {`${formattedY}${isTicketCount ? TICKETS : ''}`}
        </ValueLabel>
      </TooltipWrapper>
    </TooltipContainer>
  );
};

interface Props {
  isTicketCount?: boolean;
}

const BinTooltip: React.FC<Props> = ({ isTicketCount }) => {
  const drawTooltip = useCallback((value: TooltipContent<Rollup>[]) => (
    renderTooltip(value, isTicketCount)
  ), [isTicketCount]);

  return <Tooltip content={drawTooltip} />;
};

const TooltipWrapper = styled.div`
  font-size: 1.15em;
  margin-top: 3px;
  margin-bottom: 4px;
`;

const BinLabel = styled.div`
  font-weight: 500;
  font-size: 1.075em;
`;

const ValueLabel = styled.div`
  padding-top: 5px;
  font-weight: 550;
  font-size: 1.1em;
`;

export default BinTooltip;
