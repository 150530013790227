import { AxiosResponse } from 'axios';
import { PriceType, PatchPriceTypeRequest } from '#/types/PriceTypes';
import client from './_clientFormatted';

const patchPriceType = async (
  priceTypeId: number,
  request: PatchPriceTypeRequest,
): Promise<PriceType> => {
  const response: AxiosResponse<PriceType> = await client.patch(`/proxy/peakpass_price_types/${priceTypeId}`, request);

  return response.data;
};

const updatePriceTypes = async (
  dealTermId: number,
  currPeakpassPriceTypeIds: number[],
  prevPeakpassPriceTypeIds: number[],
): Promise<PriceType[]> => {
  const addPeakpassPriceTypes = currPeakpassPriceTypeIds
    .filter((id) => !prevPeakpassPriceTypeIds.includes(id))
    .map((id) => patchPriceType(id, { dealTermId }));
  const rmvPeakpassPriceTypes = prevPeakpassPriceTypeIds
    .filter((id) => !currPeakpassPriceTypeIds.includes(id))
    .map((id) => patchPriceType(id, { clearDealTerm: true }));

  return Promise.all(addPeakpassPriceTypes.concat(rmvPeakpassPriceTypes));
};

export default patchPriceType;
export { updatePriceTypes };

