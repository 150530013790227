import { useCallback, useReducer, useEffect } from 'react';

import Action, { RESET } from './types';
import { type PriceExploration } from '#/types/Event';

type Reducer = (
  previousState: PriceExploration,
  action: Action
) => PriceExploration;

const reducer: Reducer = (previousState, action) => {
  const { type, value } = action;

  if (type === RESET)
    return value as PriceExploration;

  const state = { ...previousState, [type]: value } as PriceExploration;

  return state;
};

type Hook = (
  initalConfig: PriceExploration
) => {
  priceExplorationConfig: PriceExploration;
  resetPriceExplorationConfig: () => void;
  updatePriceExplorationConfig: React.Dispatch<Action>;
};

const usePriceExploration: Hook = (initialConfig) => {
  const [priceExplorationConfig, updatePriceExplorationConfig] = useReducer<
  Reducer
  >(reducer, initialConfig);

  const resetPriceExplorationConfig = useCallback(() => {
    updatePriceExplorationConfig({ type: RESET, value: initialConfig });
  }, [initialConfig]);

  useEffect(() => {
    if (initialConfig.id !== priceExplorationConfig.id)
      resetPriceExplorationConfig();
  }, [resetPriceExplorationConfig, initialConfig.id, priceExplorationConfig.id]);

  return {
    priceExplorationConfig,
    resetPriceExplorationConfig,
    updatePriceExplorationConfig,
  };
};

export { Hook };
export default usePriceExploration;
