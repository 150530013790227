import { AxiosResponse } from 'axios';
import { DealTerm } from '#/types/Deal';
import client from './_clientFormatted';

const deleteDealTerm = async (dealTermId: number): Promise<DealTerm> => {
  const response: AxiosResponse<DealTerm> = await client.delete(`/proxy/deal_term/${dealTermId}`);

  return response.data;
};

export default deleteDealTerm;
