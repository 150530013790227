import React from 'react';
import { NarrowWrapper, Element, Name } from '#/shared/Card';
import ButtonSmall from '#/shared/Button';

const STAKEHOLDERS = 'Stakeholders';
const CREATE = 'Create';
const UPDATE = 'Update';

interface StakeholderCardProps {
  openCreateModal: () => void;
  openUpdateModal: () => void;
}

const StakeholderCard: React.FC<StakeholderCardProps> = ({
  openCreateModal,
  openUpdateModal,
}) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{STAKEHOLDERS}</Name>
      </Element>
      <Element>
        <ButtonSmall onClick={openCreateModal}>{CREATE}</ButtonSmall>
      </Element>
      <Element>
        <ButtonSmall onClick={openUpdateModal}>{UPDATE}</ButtonSmall>
      </Element>
    </NarrowWrapper>
  );
};

export default StakeholderCard;
