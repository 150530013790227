import { AxiosResponse } from 'axios';
import SettingsPostBody, { Settings } from '#/types/Settings';
import client from './_clientFormatted';

const postSettings = async (
  request: SettingsPostBody,
): Promise<Settings> => {
  const response: AxiosResponse<Settings> = await client.post('/proxy/settings', request);

  return response?.data;
};

export default postSettings;
