import { useEffect, useState, useCallback } from 'react';
import getEventErrors from '#/api/getEventErrors';
import { FormattedEventError } from '#/types/Errors';

type Hook = () => {
  hasError: boolean;
  eventErrors: FormattedEventError[];
  retry: () => void;
};

const useFetchEventErrors: Hook = () => {
  const [hasError, setHasError] = useState(false);
  const [eventErrors, setEventErrors] = useState<FormattedEventError[]>(null);

  const retry = useCallback(async () => {
    setHasError(false);
    try {
      const errors = await getEventErrors();

      setEventErrors(errors);
    } catch {
      setHasError(true);
    }
  }, []);

  useEffect(() => {
    retry();
  }, [retry]);

  return {
    hasError,
    eventErrors,
    retry,
  };
};

export default useFetchEventErrors;
