/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useState, ChangeEvent, useMemo,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {
  SectionWrapper,
  Title,
  Error,
  ButtonsWrapper,
  modalMenuProps,
  Content,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import type { GenericOption } from '#/types/GenericOption';
import patchIngestions from '#/api/patchIngestions';

import Ingestion, {
  EditIngestion, EditTicket, IngestionState, SplittingStrategy, Uploader,
  INGESTION_STATES, TICKET_ACCOUNT_EMAILS, TicketAccountPlatformType, StockType, SplitsRule,
  PatchIngestionsBody,
} from '#/types/Ingestion';
import { Deal } from '#/types/Deal';
import formatApiError from '#/api/utils/formatApiError';
import patchIngestion from '#/api/patchIngestion';
import useFetchStakeholders, { type Option as StakeholderOption } from '#/pages/useFetchStakeholders';
import EditTicketForm from './EditTicketForm';
import useFetchDeals, { type Option as DealOption } from '../useFetchDeals';
import useFetchUploaders from '../useFetchUploaders';
import useSetupModal from '../useSetupModal';
import modalStyles from '#/shared/modalStyles';
import SeatDeletionForm from './SeatDeletionForm';

const TITLE = 'Edit Ingestion Listing';
const ATTRIBUTE = 'Field to Edit';

const NONE = 'None';
const STATE = 'State';
const SEATGEEK_EVENT_ID = 'SeatGeek Event ID';
const UPLOADER_EVENT_ID = 'Uploader Event ID';
const STAKEHOLDER = 'Stakeholder';
const DEAL_TERM = 'Deal Term';
const DEAL = 'Deal';
const SPLITS_RULE = 'Splits Rule';
const SPLITTING_STRATEGY = 'Splitting Strategy';
const COST_PER_TICKET = 'Cost Per Ticket';
const FACE_VALUE_PER_TICKET = 'Face Value Per Ticket';
const COMMISSION_PER_TICKET = 'Commission Per Ticket';
const TICKET_ACCOUNT_EMAIL = 'Ticket Account Email';
const TICKET_ACCOUNT_PLATFORM = 'Ticket Account Platform';
const IS_RESERVED = 'Is Reserved';
const IN_HAND = 'In Hand';
const TICKETS = 'Tickets';
const IS_UNMANIFESTED = 'Is Unmanifested';
const EXTERNAL_NOTES = 'External Notes';

const SUBMIT = 'Submit';
const CANCEL = 'Cancel';

const BOOLEAN_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const DELETION_STATES = [
  IngestionState.DELETED,
  IngestionState.DELETION_NEEDED,
  IngestionState.DELETION_PENDING,
  IngestionState.RELISTING_NEEDED,
  IngestionState.DELETION_FAILED,
];

interface EditListingProps {
  ingestions: Ingestion[];
  closeModal: () => void;
  isBulk: boolean;
}

const EditListingModal: React.FC<EditListingProps> = ({
  ingestions,
  closeModal,
  isBulk,
}) => {
  const {
    state, seatgeek_event_id, uploader_event_id, uploader, splits_rule, splitting_strategy,
    stakeholder_id, deal_id, deal_term_id, cost_per_ticket, face_value_per_ticket,
    commission_per_ticket, ticket_account_email, tickets, ticket_account_platform,
    stock_type, external_notes,
  } = ingestions[0];
  const [error, setError] = useState<string>(null);
  const [attribute, setAttribute] = useState(NONE);
  const [edit, setEdit] = useState<EditIngestion>({});
  const [files, setFiles] = useState<File[]>([]);
  const [deal, setDeal] = useState<Deal>(null);

  const { stakeholderLabels } = useFetchStakeholders();
  const { uploaders } = useFetchUploaders();
  const { deals } = useFetchDeals();

  const DESCRIPTION = isBulk ? `Edit the details of ${ingestions.length} ingestion listings` : 'Edit the details of this ingestion listing.';

  const stateOptions = INGESTION_STATES
    .filter((s) => ingestions.every(((i) => i.allowed_state_transitions.includes(s))))
    .map((s) => ({ value: s, label: s }));

  const splitsRuleOptions = useMemo(() => {
    const selectedUploader = uploaders?.filter((u) => u.name === uploader)[0];

    return selectedUploader?.splitsRules.map((s) => ({ value: s, label: s }));
  }, [uploader, uploaders]);

  const splittingStrategyOptions = Object.values(SplittingStrategy)
    .map((s) => ({ value: s, label: s }));

  const dealOptions = useMemo(() => (
    deals
      .filter((d) => d.stakeholderId === (edit.stakeholder_id || stakeholder_id))
      .map((d) => ({ value: d.id, label: `(${d.id}) ${d.displayName}` }))
    || []
  ), [edit.stakeholder_id, stakeholder_id, deals]);

  const dealTermOptions = useMemo(() => {
    const selectedDeal: Deal = (
      edit.stakeholder_id ? deal : (deal ?? deals.find((d) => d.id === deal_id))
    );

    return selectedDeal?.dealTerms
      ?.map((dt) => ({ value: dt.id, label: `(${dt.id}) ${dt.name}` }))
    || [];
  }, [deal, deals, deal_id, edit.stakeholder_id]);

  const ticketAccountEmailOptions = TICKET_ACCOUNT_EMAILS.map((t) => ({ value: t, label: t }));
  const ticketAccountPlatformOptions = Object.values(TicketAccountPlatformType)
    .map((platform) => {
      return ({ value: platform, label: platform });
    });
  const noneInHand = !ingestions.some((i) => i.tickets.some((t) => t.in_hand_at !== null));
  const allInHand = ingestions.every((i) => i.tickets.every((t) => t.in_hand_at !== null));
  let defaultInHand: boolean = null;

  if (noneInHand)
    defaultInHand = false;
  else if (allInHand)
    defaultInHand = true;

  const canSplit = ingestions
    .every((i) => (
      i.uploader === Uploader.PEAKPASS
      || (
        !i.is_sold
        && i.tickets.length > 3
        && (
          i.allowed_state_transitions.includes(IngestionState.SPLITTING_NEEDED)
          || DELETION_STATES.includes(i.state)
        )
      )
    ));
  const canUpload = ingestions
    .every((i) => (
      i.allowed_state_transitions.includes(IngestionState.UPLOAD_NEEDED)
      || DELETION_STATES.includes(i.state)
    ));
  const canFinalize = ingestions
    .every((i) => (
      i.allowed_state_transitions.includes(IngestionState.FINALIZE_NEEDED)
      || DELETION_STATES.includes(i.state)
    ));
  const allHavePdfs = ingestions.every((i) => i.tickets.every((t) => t.s3_path !== null));
  const ticketCount = ingestions.map((i) => i.tickets.length).reduce((a, b) => a + b, 0);

  const attributeOptions = [
    stateOptions.length > 0 ? STATE : null,
    ingestions.every((i) => i.state === IngestionState.EVENT_FAILED) ? UPLOADER_EVENT_ID : null,
    SEATGEEK_EVENT_ID,
    canFinalize ? SPLITS_RULE : null,
    canSplit ? SPLITTING_STRATEGY : null,
    canFinalize ? COST_PER_TICKET : null,
    canFinalize ? FACE_VALUE_PER_TICKET : null,
    canFinalize ? COMMISSION_PER_TICKET : null,
    canFinalize ? TICKET_ACCOUNT_EMAIL : null,
    canFinalize ? TICKET_ACCOUNT_PLATFORM : null,
    canFinalize ? STAKEHOLDER : null,
    canFinalize ? IS_RESERVED : null,
    canFinalize ? EXTERNAL_NOTES : null,
    !allHavePdfs && ticketCount > 0 && canFinalize ? IN_HAND : null,
    ingestions.length === 1 && tickets.length > 0 && canUpload ? TICKETS : null,
    IS_UNMANIFESTED,
  ]
    .filter((a) => a !== null)
    .map((a) => ({ value: a, label: a }));

  const allSeats = useMemo(() => tickets.map((t) => t.seat), [tickets]);

  const onChangeAttribute = useCallback((event: GenericOption<string, string>) => {
    setAttribute(event?.value);
    setEdit({});
  }, []);

  const onChangeState = useCallback((event: GenericOption<IngestionState, IngestionState>) => {
    const newState = event?.value || null;

    setEdit((e) => ({ ...e, state: newState }));
  }, [setEdit]);

  const onChangeSeatgeekEventId = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const new_seatgeek_event_id = Number(event?.target?.value) || null;

    setEdit((e) => ({ ...e, seatgeek_event_id: new_seatgeek_event_id }));
  }, [setEdit]);

  const onChangeSplitsRule = useCallback((event: GenericOption<SplitsRule, SplitsRule>) => {
    const new_splits_rule = event?.value || null;

    setEdit((e) => ({ ...e, splits_rule: new_splits_rule }));
  }, [setEdit]);

  const onChangeSplittingStrategy = useCallback(
    (event: GenericOption<SplittingStrategy, SplittingStrategy>) => {
      const new_splitting_strategy = event?.value || null;

      setEdit((e) => ({ ...e, splitting_strategy: new_splitting_strategy }));
    }, [setEdit],
  );

  const onChangeCostPerTicket = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const new_cost_per_ticket = value ? Number(value) : null;

    setEdit((e) => ({ ...e, cost_per_ticket: new_cost_per_ticket }));
  }, [setEdit]);

  const onChangeFaceValuePerTicket = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const new_face_value_per_ticket = value ? Number(value) : null;

    setEdit((e) => ({ ...e, face_value_per_ticket: new_face_value_per_ticket }));
  }, [setEdit]);

  const onChangeCommissionPerTicket = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const new_commission_per_ticket = value ? Number(value) : null;

    setEdit((e) => ({ ...e, commission_per_ticket: new_commission_per_ticket }));
  }, [setEdit]);

  const onChangeStakeholder = useCallback((event: StakeholderOption<string>) => {
    const new_stakeholder_id = event?.id || null;

    setDeal(null);
    setEdit((e) => ({
      ...e,
      stakeholder_id: new_stakeholder_id,
      deal_term_id: null,
    }));
  }, [setDeal, setEdit]);

  const onChangeDeal = useCallback((event: DealOption<number>) => {
    const newDealId = event?.value || null;
    const selectedDeal = deals.find((d) => d.id === Number(newDealId));

    setDeal(selectedDeal);
    setEdit((e) => ({
      ...e,
      deal_term_id: null,
    }));
  }, [deals, setDeal, setEdit]);

  const onChangeDealTerm = useCallback((event: DealOption<number>) => {
    const new_deal_term_id = event?.value ? Number(event.value) : null;

    setEdit((e) => ({
      ...e,
      stakeholder_id: e.stakeholder_id || stakeholder_id,
      deal_term_id: new_deal_term_id,
    }));
  }, [setEdit, stakeholder_id]);

  const onChangeInHand = useCallback((event: GenericOption<string, boolean>) => {
    const value = event?.value;
    const in_hand = value === null || typeof (value) === 'undefined' ? null : value;

    setEdit((e) => ({ ...e, in_hand }));
  }, [setEdit]);

  const onChangeIsReserved = useCallback((event: GenericOption<string, boolean>) => {
    const value = event?.value;
    const is_reserved = value === null || typeof (value) === 'undefined' ? null : value;

    setEdit((e) => ({ ...e, is_reserved }));
  }, [setEdit]);

  const onChangeUploaderEventId = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const new_uploader_event_id = event?.target?.value || null;

    setEdit((e) => ({ ...e, uploader_event_id: new_uploader_event_id }));
  }, [setEdit]);

  const onChangeTicketAccountEmail = useCallback((event: GenericOption<string, string>) => {
    const new_ticket_account_email = event?.value || null;

    setEdit((e) => ({ ...e, ticket_account_email: new_ticket_account_email }));
  }, [setEdit]);

  const onChangeTicketAccountPlatform = useCallback(
    (event: GenericOption<TicketAccountPlatformType, TicketAccountPlatformType>) => {
      const new_ticket_account_platform = event?.value as TicketAccountPlatformType;

      setEdit((e) => ({ ...e, ticket_account_platform: new_ticket_account_platform }));
    }, [setEdit],
  );

  const onChangeIsUnmanifested = useCallback((event: GenericOption<string, boolean>) => {
    const value = event?.value;
    const is_unmanifested = value === null || typeof (value) === 'undefined' ? null : value;

    setEdit((e) => ({ ...e, is_unmanifested }));
  }, [setEdit]);

  const onChangeExternalNotes = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const new_external_notes = event?.target?.value ?? null;

    setEdit((e) => ({ ...e, external_notes: new_external_notes }));
  }, [setEdit]);

  const setTicket = useCallback((ticket: EditTicket) => {
    setEdit((e) => {
      const existingTickets = (e.tickets || [])
        .filter((t) => t.seat !== ticket.seat);
      const newTickets = [...existingTickets, ticket];

      return { ...e, tickets: newTickets };
    });
  }, [setEdit]);

  const setTicketRelistingRequests = useCallback((
    seatsToRelist: Set<number>,
  ) => {
    setEdit((e) => {
      const existingTickets: { [seat: number]: EditTicket } = (e.tickets || [])
        .reduce((accumulator, ticket) => ({ ...accumulator, [ticket.seat]: ticket }), {});

      const newTickets: EditTicket[] = allSeats.map((seat) => {
        // We make sure not to overwrite other fields in each ticket request so that,
        // in the future, we are able to support updating multiple ticket attributes
        // across multiple tickets at once.
        const isExistingTicket = Object.hasOwn(existingTickets, seat);
        const ticket = isExistingTicket ? { ...existingTickets[seat] } : { seat: seat };
        const isRelistingRequested = seatsToRelist.has(seat);

        return { ...ticket, is_relisting_requested: isRelistingRequested };
      });

      return { ...e, tickets: newTickets };
    });
  }, [setEdit, allSeats]);

  const addFile = useCallback((file: File) => {
    setFiles((f) => [...f, file]);
  }, [setFiles]);

  const editListing = useCallback(async () => {
    setError(null);
    const errors: string[] = [];
    const handleIngestion = async (ingestion: Ingestion): Promise<void> => {
      try {
        await patchIngestion(ingestion.id, edit, files);
      } catch (e) {
        errors.push(`${ingestion.id}: ${formatApiError(e)}`);
      }
    };

    const handleBulkIngestions = async (bulkIngestions: Ingestion[]): Promise<void> => {
      try {
        const request : PatchIngestionsBody = {
          constant: edit,
          variable: bulkIngestions.map((i) => ({ id: i.id })),
        };

        await patchIngestions(request);
      } catch (e) {
        errors.push(`${formatApiError(e)}`);
      }
    };

    if (isBulk)
      await handleBulkIngestions(ingestions);
    else
      await Promise.all(ingestions.map(handleIngestion));

    if (errors.length)
      setError(errors.join('\n'));
    else
      closeModal();
  }, [ingestions, edit, files, closeModal, isBulk]);

  useSetupModal(closeModal);

  // For simplicity of the UI, we currently don't allow seat-granular deletion when
  // multiple ingestion listings are selected to edit.
  const shouldShowSeatDeletionForm = (
    edit?.state === IngestionState.DELETION_NEEDED && ingestions.length === 1
  );

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <Title>{TITLE}</Title>
      <SectionWrapper>
        <InnerWrapper>
          <Description>{DESCRIPTION}</Description>
        </InnerWrapper>
        <Content>
          <InnerWrapper>
            <Label>{ATTRIBUTE}</Label>
            <Select
              defaultValue={
                attributeOptions.find((s) => s.value === attribute)
              }
              onChange={onChangeAttribute}
              options={attributeOptions}
              {...modalMenuProps}
            />
          </InnerWrapper>
          {attribute === STATE && (
            <InnerWrapper>
              <Label>{STATE}</Label>
              <Select
                defaultValue={
                  stateOptions.find((s) => s.value === state)
                }
                onChange={onChangeState}
                options={stateOptions}
                {...modalMenuProps}
              />
              {shouldShowSeatDeletionForm && (
                <SeatDeletionForm
                  row={ingestions[0].row}
                  seats={allSeats}
                  section={ingestions[0].section}
                  setTicketRelistingRequests={setTicketRelistingRequests}
                />
              )}
            </InnerWrapper>
          )}
          {attribute === SEATGEEK_EVENT_ID && (
            <InnerWrapper>
              <Label>{SEATGEEK_EVENT_ID}</Label>
              <Input
                defaultValue={seatgeek_event_id}
                onChange={onChangeSeatgeekEventId}
                type='number'
              />
            </InnerWrapper>
          )}
          {attribute === UPLOADER_EVENT_ID && (
            <InnerWrapper>
              <Label>{UPLOADER_EVENT_ID}</Label>
              <Input
                defaultValue={uploader_event_id}
                onChange={onChangeUploaderEventId}
                type='text'
              />
            </InnerWrapper>
          )}
          {attribute === SPLITS_RULE && (
            <InnerWrapper>
              <Label>{SPLITS_RULE}</Label>
              <Select
                defaultValue={
                  splitsRuleOptions.find((s) => s.value === splits_rule)
                }
                onChange={onChangeSplitsRule}
                options={splitsRuleOptions}
                {...modalMenuProps}
              />
            </InnerWrapper>
          )}
          {attribute === SPLITTING_STRATEGY && (
            <InnerWrapper>
              <Label>{SPLITTING_STRATEGY}</Label>
              <Select
                defaultValue={
                  splittingStrategyOptions.find((s) => s.value === splitting_strategy)
                }
                onChange={onChangeSplittingStrategy}
                options={splittingStrategyOptions}
                {...modalMenuProps}
              />
            </InnerWrapper>
          )}
          {attribute === COST_PER_TICKET && (
            <InnerWrapper>
              <Label>{COST_PER_TICKET}</Label>
              <Input
                defaultValue={cost_per_ticket}
                onChange={onChangeCostPerTicket}
                type='number'
              />
            </InnerWrapper>
          )}
          {attribute === FACE_VALUE_PER_TICKET && (
            <InnerWrapper>
              <Label>{FACE_VALUE_PER_TICKET}</Label>
              <Input
                defaultValue={face_value_per_ticket}
                onChange={onChangeFaceValuePerTicket}
                type='number'
              />
            </InnerWrapper>
          )}
          {attribute === COMMISSION_PER_TICKET && (
            <InnerWrapper>
              <Label>{COMMISSION_PER_TICKET}</Label>
              <Input
                defaultValue={commission_per_ticket}
                onChange={onChangeCommissionPerTicket}
                type='number'
              />
            </InnerWrapper>
          )}
          {attribute === TICKET_ACCOUNT_EMAIL && (
            <InnerWrapper>
              <Label>{TICKET_ACCOUNT_EMAIL}</Label>
              <Creatable
                defaultValue={
                  ticketAccountEmailOptions.find((t) => t.value === ticket_account_email)
                }
                isClearable
                isMulti={false}
                onChange={onChangeTicketAccountEmail}
                options={ticketAccountEmailOptions}
                placeholder="Select or type..."
              />
            </InnerWrapper>
          )}
          {attribute === TICKET_ACCOUNT_PLATFORM && (
            <InnerWrapper>
              <Label>{TICKET_ACCOUNT_PLATFORM}</Label>
              <Select
                defaultValue={
                  ticketAccountPlatformOptions.find((t) => t.value === ticket_account_platform)
                }
                isClearable
                onChange={onChangeTicketAccountPlatform}
                options={ticketAccountPlatformOptions}
                {...modalMenuProps}
              />
            </InnerWrapper>
          )}
          {attribute === STAKEHOLDER && (
            <>
              <InnerWrapper>
                <Label>{STAKEHOLDER}</Label>
                <Select
                  onChange={onChangeStakeholder}
                  options={stakeholderLabels}
                  value={
                    stakeholderLabels.find(
                      (s) => s.id === (edit.stakeholder_id ?? stakeholder_id),
                    ) || null
                  }
                  {...modalMenuProps}
                />
              </InnerWrapper>
              <InnerWrapper>
                <Label>{DEAL}</Label>
                <Select
                  isClearable
                  onChange={onChangeDeal}
                  options={dealOptions}
                  value={
                    dealOptions.find(
                      (d) => d.value === (edit.stakeholder_id ? deal?.id : (deal?.id ?? deal_id)),
                    ) || null
                  }
                  {...modalMenuProps}
                />
              </InnerWrapper>
              <InnerWrapper>
                <Label>{DEAL_TERM}</Label>
                <Select
                  isClearable
                  onChange={onChangeDealTerm}
                  options={dealTermOptions}
                  value={
                    dealTermOptions.find(
                      (s) => s.value === ('deal_term_id' in edit ? edit.deal_term_id : deal_term_id),
                    ) || null
                  }
                  {...modalMenuProps}
                />
              </InnerWrapper>
            </>
          )}
          {attribute === IS_RESERVED && (
            <InnerWrapper>
              <Label>{IS_RESERVED}</Label>
              <Select
                defaultValue={BOOLEAN_OPTIONS.find((s) => s.value === edit.is_reserved)}
                onChange={onChangeIsReserved}
                options={BOOLEAN_OPTIONS}
                {...modalMenuProps}
              />
            </InnerWrapper>
          )}
          {attribute === IN_HAND && (
            <InnerWrapper>
              <Label>{IN_HAND}</Label>
              <Select
                defaultValue={BOOLEAN_OPTIONS.find((s) => s.value === defaultInHand)}
                onChange={onChangeInHand}
                options={BOOLEAN_OPTIONS}
                {...modalMenuProps}
              />
            </InnerWrapper>
          )}
          {attribute === IS_UNMANIFESTED && (
            <InnerWrapper>
              <Label>{IS_UNMANIFESTED}</Label>
              <Select
                defaultValue={BOOLEAN_OPTIONS.find((s) => s.value === false)}
                onChange={onChangeIsUnmanifested}
                options={BOOLEAN_OPTIONS}
                {...modalMenuProps}
              />
            </InnerWrapper>
          )}
          {attribute === TICKETS && (
            <InnerWrapper>
              <Label>{TICKETS}</Label>
              {tickets.map((t) => (
                <EditTicketForm
                  addFile={addFile}
                  key={`ticket_${t.seat}`}
                  setTicket={setTicket}
                  stock_type={stock_type as StockType}
                  ticket={t}
                />
              ))}
            </InnerWrapper>
          )}
          {attribute === EXTERNAL_NOTES && (
            <InnerWrapper>
              <Label>{EXTERNAL_NOTES}</Label>
              <Input
                defaultValue={external_notes}
                onChange={onChangeExternalNotes}
                type='text'
              />
            </InnerWrapper>
          )}
        </Content>
        {error && (
          <InnerWrapper>
            <Error>{error}</Error>
          </InnerWrapper>
        )}
        <ButtonsWrapper>
          <Button
            disabled={Object.keys(edit).length === 0}
            onClick={editListing}
          >
            {SUBMIT}
          </Button>
          <Button onClick={closeModal}>{CANCEL}</Button>
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

const Description = styled.p``;

const InnerWrapper = styled.div`
  text-align: center;
  font-size: 15px;
  margin: 1rem;
`;

const Label = styled.label`
  text-align: left;
  width: 10rem;
  padding: 4px;
`;

const Input = styled.input`
  width: 100%;
  ${({ theme }: { theme: Theme }): string => theme.text4};
  padding: 4px;
  border: 1px solid
    ${({ theme }: { theme: Theme }): string => theme.color.border.primary};
  border-radius: 6px;
  transition: all 0.2s ease;
  &:focus {
    outline: none;
    outline-offset: none;
    border-color: ${({ theme }: { theme: Theme }): string => theme.color.border.selected};
  }
`;

export default EditListingModal;
export { EditListingProps };
