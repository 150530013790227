import { AxiosResponse } from 'axios';
import client from './_clientFormatted';

import { SelldownProjections } from '#/types/Event';

const DAYS_FROM_NOW = [0, 1, 7];

interface SelldownProjectionResponse {
  daysFromNow: number;
  selldownProjected: number;
}

interface SelldownProjectionsResponse {
  snapshots: SelldownProjectionResponse[];
}

const getSelldownProjections = async (autobrokerEventId: number,
): Promise<SelldownProjections> => {
  const response: AxiosResponse<SelldownProjectionsResponse> = await client.get(
    `/proxy/preview/${autobrokerEventId}/selldown`,
    { params: { daysFromNow: DAYS_FROM_NOW } },
  );

  const raw = response?.data?.snapshots || [];

  const result: SelldownProjections = {};

  raw.forEach((item: SelldownProjectionResponse) => {
    if (item.daysFromNow === 0)
      result.now = item.selldownProjected;

    if (item.daysFromNow === 1)
      result.tomorrow = item.selldownProjected;

    if (item.daysFromNow === 7)
      result.nextWeek = item.selldownProjected;
  });

  return Object.keys(result).length === 3 ? result : undefined;
};

export default getSelldownProjections;
