import { DateTime } from 'luxon';
import { PriceGranularity, PricingBasis } from './Event';
import { APIErrorStatus, ErrorStatus } from './Errors';
import { FallbackPricingPolicy } from './Stakeholder';
import { PriceConstraint } from './PriceConstraints';

interface APIIngestions {
  ingestion_listings: APIIngestion[];
}

interface SeatGeekUserListingId {
  id: number;
  display_id: string;
  bulk_id?: string;
}
interface Ingestion {
  id: number;
  ingestion_csv_file: IngestionCsvFile | null;
  seatgeek_user_purchase_id: number | null;
  seatgeek_user_purchase_return_id: number | null;
  stakeholder: string;
  stakeholder_id: number;
  deal_id: number | null;
  deal: string | null;
  deal_term_id: number | null;
  deal_term: string | null;
  uploader: string;
  seatgeek_event_id: number;
  uploader_event_id: string | null;
  section: string;
  row: string;
  start_seat: number;
  end_seat: number;
  quantity: number;
  stock_type: string;
  splits_rule: SplitsRule | null;
  external_notes: string | null;
  in_hand_date: string | null;
  ticket_account_email: string | null;
  ticket_account_platform: string | null;
  ticket_account_external_id?: string | null;
  cost_per_ticket: number;
  face_value_per_ticket: number | null;
  commission_per_ticket: number;
  tickets: IngestionTicket[];
  candidate_event: CandidateEvent | null;
  input_listing_errors: InputListingError[];
  output_listings: OutputListing[];
  transactions: Transaction[];
  error_status: ErrorStatus | null;
  splitting_strategy: SplittingStrategy;
  state: IngestionState;
  last_state: IngestionState | null;
  allowed_state_transitions: IngestionState[];
  error_reason: string | null;
  to_setup_event: boolean;
  in_hand: boolean;
  is_priced: boolean;
  listed_ct: number;
  is_sold: boolean;
  sold_ct: number;
  cost: number;
  face_value: number | null;
  gtv: number;
  has_pdf: boolean;
  recoupment_rate: number | null;
  sellthrough_rate: number | null;
  is_reserved: boolean;
  seatgeek_user_listing: SeatGeekUserListingId | null
  peakpass_price_type_id: number | null;
  peakpass_price_type_name: string | null;
  price_level_name: string | null;
  peakpass_package_type: string | null;
  is_unmanifested: boolean | null;
  previous_ingestion_listing_id: number | null;
  previous_ingestion_listing: IngestionListingMinimalInfo | null;
  relisted_ingestion_listings: IngestionListingMinimalInfo[]
  reserved_at: DateTime | null;
  created_at: DateTime;
  updated_at: DateTime;
}

interface APITicketAccount {
  id: number;
  email: string;
  platform: string;
  external_id: string | null;
}
interface APIIngestion extends Omit<
Ingestion,
'ingestion_csv_file' | 'tickets' | 'candidate_event' | 'input_listing_errors'
| 'output_listings' | 'transactions' | 'error_status' | 'created_at' | 'updated_at'
| 'ticket_account_email' | 'ticket_account_platform'
> {
  ticket_account: APITicketAccount | null;
  ingestion_csv_file: APIIngestionCsvFile | null;
  tickets: APIIngestionTicket[];
  candidate_event: APICandidateEvent | null;
  input_listing_errors: APIInputListingError[];
  output_listings: APIOutputListing[];
  transactions: APITransaction[];
  error_status: APIErrorStatus | null;
  created_at: string;
  updated_at: string;
}

interface IngestionCsvFile {
  id: number;
  user_id: number;
  user_name: number;
  created_at: DateTime;
}

interface APIIngestionCsvFile extends Omit<IngestionCsvFile, 'created_at'> {
  created_at: string;
}

interface IngestionTicket {
  id: number;
  split_index: number;
  seat: number;
  uploader_ticket_id: string | null;
  paulbunyan_ticket_id: number | null;
  barcode: string | null;
  wallet_link: string | null;
  s3_path: string | null;
  in_hand_at: DateTime | null;
  created_at: DateTime;
  updated_at: DateTime;
  expired_at: DateTime | null;
}

interface APIIngestionTicket extends Omit<IngestionTicket, 'in_hand_at' | 'created_at' | 'updated_at' | 'expired_at'> {
  in_hand_at: string | null;
  created_at: string;
  updated_at: string;
  expired_at: string | null;
}

interface CandidateEvent {
  seatgeek_event_id: number;
  title: string;
  venue: string;
  event_starts_at: DateTime;
  event_starts_at_local: string;
  event_start_time_status: string;
  autobroker_event_ids: number[];
}

interface APICandidateEvent extends Omit<CandidateEvent, 'event_starts_at' | 'event_starts_at_local'> {
  event_starts_at: string;
  event_starts_at_local: string | null;
}

interface InputListingError {
  id: number;
  autobroker_event_id: number;
  source_name: string;
  source_external_event_id: string;
  source_inventory_id: string;
  section: string;
  row: string;
  quantity: number;
  error_type: string;
  error_reason: string;
  error_status: ErrorStatus | null;
  created_at: DateTime;
  updated_at: DateTime;
}

interface APIInputListingError extends Omit<InputListingError, 'error_status' | 'created_at' | 'updated_at'> {
  error_status: APIErrorStatus | null;
  created_at: string;
  updated_at: string;
}

interface InputListingOverride {
  id: number;
  granularity: PriceGranularity;
  section?: string;
  row?: string;
  mapkey?: string;
  expected_value?: number;
  price_constraint?: PriceConstraint;
  is_visible?: boolean;
  all_in_price?: number;
  display_price?: number;
  broadcast_price?: number;
  user_name: string;
  created_at: DateTime;
  is_cascading: boolean;
  is_active: boolean;
  is_empty: boolean;
}
interface APIInputListingOverride extends Omit<InputListingOverride, 'created_at'> {
  created_at: string;
}

interface OutputPrice {
  amount: number;
  basis: PricingBasis;
  expected_value?: number;
  fallback_pricing_policy?: FallbackPricingPolicy;
}

interface OutputListing {
  id: number;
  autobroker_event_id: number;
  source_name: string;
  source_external_event_id: string;
  source_inventory_id: string;
  source_price_level_id: string | null;
  section: string;
  row: string | null;
  quantity: number;
  mapkey: string | null;
  price: OutputPrice;
  override: InputListingOverride | null;
  ingestion_listing_id?: number;
  created_at: DateTime;
}

interface APIOutputListing extends Omit<OutputListing, 'override' | 'created_at'> {
  override: APIInputListingOverride | null;
  created_at: string;
}

interface Transaction {
  id: number;
  autobroker_event_id: number;
  sink_name: string;
  sink_external_event_id: string;
  sink_transaction_id: string;
  quantity: number;
  mapkey: string | null;
  expected_value: number | null;
  marketplace: string | null;
  submitted_price_per_ticket: number | null;
  reported_cost_per_ticket: number | null;
  state: string;
  created_at: DateTime;
}
interface APITransaction extends Omit<Transaction, 'created_at'> {
  created_at: string;
}

enum IngestionState {
  EVENT_NEEDED = 'event_needed',
  EVENT_FAILED = 'event_failed',
  SPLITTING_NEEDED = 'splitting_needed',
  PO_NEEDED = 'po_needed',
  UPLOAD_NEEDED = 'upload_needed',
  UPLOAD_FAILED = 'upload_failed',
  FINALIZE_NEEDED = 'finalize_needed',
  FINALIZE_PENDING = 'finalize_pending',
  EVENT_SETUP_NEEDED = 'event_setup_needed',
  FAILED = 'failed',
  DONE = 'done',
  DELETION_NEEDED = 'deletion_needed',
  DELETION_PENDING = 'deletion_pending',
  RELISTING_NEEDED = 'relisting_needed',
  DELETION_FAILED = 'deletion_failed',
  DELETED = 'deleted',
}

const INGESTION_STATES = [
  IngestionState.EVENT_NEEDED,
  IngestionState.EVENT_FAILED,
  IngestionState.SPLITTING_NEEDED,
  IngestionState.PO_NEEDED,
  IngestionState.UPLOAD_NEEDED,
  IngestionState.UPLOAD_FAILED,
  IngestionState.FINALIZE_NEEDED,
  IngestionState.FINALIZE_PENDING,
  IngestionState.EVENT_SETUP_NEEDED,
  IngestionState.FAILED,
  IngestionState.DONE,
  IngestionState.DELETION_NEEDED,
  IngestionState.DELETION_PENDING,
  IngestionState.RELISTING_NEEDED,
  IngestionState.DELETION_FAILED,
  IngestionState.DELETED,
];

enum SplitsRule {
  DONT_LEAVE_ONE = 'dont_leave_one',
  NONE = 'none',
  ANY = 'any',
  LEAVE_EVEN = 'leave_even',
  MULTIPLES_OF_2 = 'multiples_of_2',
  MULTIPLES_OF_3 = 'multiples_of_3',
}

enum SplittingStrategy {
  NONE = 'none',
  SIMPLE = 'simple',
  MAXIMAL = 'maximal',
}

enum InputListingErrorSubset {
  PRICING = 'pricing',
  BROADCAST = 'broadcast',
}

interface IngestionFilters {
  id?: number;
  ingestion_csv_file_id?: number;
  seatgeek_event_id?: number;
  autobroker_event_id?: number;
  is_upcoming?: number;
  from_date?: string;
  to_date?: string;
  stakeholder?: string;
  state?: string;
  is_priced?: number;
  has_error?: number;
  error_subset?: InputListingErrorSubset;
  in_hand?: number;
  seatgeek_user_purchase_id?: number;
  from_id?: number;
  section?: string;
  row?: string;
  per_page?: number;
  uses_fallback?: number;
  is_listed?: number;
  is_reserved?: number;
  sort_by?: string;
  sort_asc?: number;
  deal?: string;
  deal_term?: string;
  is_pinned?: boolean;
  uploader?: string;
  seatgeek_user_listing_id?: number;
  seatgeek_bulk_user_listing_id?: string;
}

interface EditIngestion {
  state?: IngestionState;
  seatgeek_event_id?: number;
  uploader_event_id?: string;
  splits_rule?: SplitsRule;
  cost_per_ticket?: number;
  face_value_per_ticket?: number;
  commission_per_ticket?: number;
  stakeholder_id?: number;
  deal_term_id?: number;
  in_hand?: boolean;
  is_reserved?: boolean;
  is_unmanifested?: boolean;
  tickets?: EditTicket[];
  external_notes?: string;
}

interface EditTicket {
  seat: number;
  in_hand?: boolean;
  barcode?: string;
  wallet_link?: string;
  pdf_path?: string;
  paulbunyan_ticket_id?: number;
  updated_seat?: number;
  is_relisting_requested?: boolean;
}

enum StockType {
  MOBILE_TRANSFER = 'mobile_transfer',
  PDF = 'pdf',
  SCREENSHOT = 'screenshot',
  AXS_MOBILE_ID = 'axs_mobile_id',
  HARD_STOCK = 'hard_stock',
  VENUE_WALK_IN = 'venue_walk_in',
  WILL_CALL = 'will_call',
  WALLET_LINKS = 'wallet_links',
}

enum TicketAccountPlatformType {
  TICKETMASTER = 'ticketmaster',
  TICKETMASTER_ACCOUNT_MANAGER = 'ticketmaster account manager',
  AXS = 'axs',
  SEATGEEK = 'seatgeek',
  MLB_BALLPARK = 'mlb ballpark',
  PACIOLAN = 'paciolan',
}

enum Uploader {
  ONETICKET = 'oneticket',
  TICKETVAULT = 'ticketvault',
  CONSUMER = 'consumer',
  PEAKPASS = 'peakpass',
}

interface PostIngestion {
  seatgeek_user_purchase_id?: number;
  seatgeek_user_purchase_return_id?: number;
  stakeholder_id?: number;
  seatgeek_event_id?: number;
  uploader_event_id?: string;
  section?: string;
  row?: string;
  start_seat?: number;
  end_seat?: number;
  quantity?: number;
  stock_type?: StockType;
  cost_per_ticket?: number;
  face_value_per_ticket?: number;
  commission_per_ticket?: number;
  external_notes?: string;
  in_hand?: boolean;
  in_hand_date?: string;
  ticket_account_email?: string;
  ticket_account_platform?: TicketAccountPlatformType;
  uploader?: Uploader;
  tickets?: PostIngestionTicket[];
  splits_rule?: SplitsRule;
  splitting_strategy?: SplittingStrategy;
  deal_term_id?: number;
  is_reserved?: boolean;
  is_unmanifested?: boolean | null;
  seatgeek_user_listing_id?: number;
  seatgeek_bulk_user_listing_id?: string;
}

interface PostIngestionTicket {
  seat?: number;
  pdf_path?: string;
  barcode?: string;
  wallet_link?: string;
  in_hand?: boolean;
  paulbunyan_ticket_id?: number;
}
interface PatchIngestion {
  id?: number
}

interface PatchIngestionsBody {
  constant: EditIngestion;
  variable: PatchIngestion[];
}

// common account emails that we use
const TICKET_ACCOUNT_EMAILS = [
  'eftickets@gmail.com',
  'returns@seatgeek.com',
  'rufus.sesman@gmail.com',
  'jimmydeanbfs@gmail.com',
  'jimothysimons@gmail.com',
  'rufus.sesman1@gmail.com',
  'transfer@sgticketing.com',
  'jteamtickets@gmail.com',
  'tmreturns1@seatgeek.com',
  'tmreturns2@seatgeek.com',
  'tmreturns3@seatgeek.com',
  'eftickets2022@gmail.com',
  'irene.p.price84@gmail.com',
];

interface IngestionListingMinimalInfo {
  id: number;
  start_seat: number;
  end_seat: number;
}

export default Ingestion;
export {
  APIIngestions,
  APIIngestion,
  APIIngestionCsvFile,
  APIIngestionTicket,
  APICandidateEvent,
  APIInputListingError,
  APIInputListingOverride,
  APIOutputListing,
  APITransaction,
  EditIngestion,
  EditTicket,
  IngestionCsvFile,
  IngestionFilters,
  IngestionListingMinimalInfo,
  IngestionState,
  IngestionTicket,
  CandidateEvent,
  InputListingError,
  InputListingErrorSubset,
  InputListingOverride,
  OutputPrice,
  OutputListing,
  Transaction,
  PostIngestion,
  PostIngestionTicket,
  PatchIngestionsBody,
  PatchIngestion,
  StockType,
  Uploader,
  SplitsRule,
  SplittingStrategy,
  INGESTION_STATES,
  TICKET_ACCOUNT_EMAILS,
  TicketAccountPlatformType,
};
