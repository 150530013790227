import { AxiosResponse } from 'axios';
import { OverrideChangeLog } from '#/types/Override';
import client from './_clientFormatted';

const getOverrideLogs = async (eventId: number): Promise<OverrideChangeLog> => {
  const response: AxiosResponse<OverrideChangeLog> = await client.get(`/proxy/overrides/history/${eventId}`);

  const overrideLogs = response?.data;

  return overrideLogs;
};

export default getOverrideLogs;
