import React, {
  useState, useCallback, useRef,
} from 'react';
import styled from 'styled-components';
import { debounce } from '@graphique/graphique';
import {
  FilterSelect, Form,
} from '#/shared/Filters';
import TableWrapper, { Cell, RowWrapper, SmallHeader } from '#/shared/TableComponents';
import Button from '#/shared/Button';
import { UserType, UserFilters, User } from '#/types/User';
import type { GenericOption } from '#/types/GenericOption';

const SEARCH = 'Search';
const COLUMNS = [
  'Search',
  'Role',
  '',
];
const SEARCH_PLACEHOLDER = 'Search (default active)';

interface FilterProps {
  filters: UserFilters;
  setFilters: (filters: UserFilters) => void;
  setSearch: (search: string) => void;
  setSelectedUser: (user: User) => void;
}

const Filters: React.FC<FilterProps> = ({
  filters,
  setFilters,
  setSearch,
  setSelectedUser,
}) => {
  const [input, setInput] = useState<UserFilters>(filters);
  const userTypeOptions = Object.values(UserType).map((type) => ({ label: type, value: type }));

  const setUserType = useCallback((event: GenericOption<UserType, UserType>[]) => {
    const userTypes = event?.map((item) => item.value);

    setInput((i) => ({ ...i, userTypes }));
  }, [setInput]);

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    setFilters(input);
    setSelectedUser(null);
  }, [input, setFilters, setSelectedUser]);

  const searchRef = useRef<HTMLInputElement>(null);
  const handleSearchChange = debounce(300, () => {
    let searchString = searchRef?.current?.value?.trim().toLowerCase();

    if (searchString === '')
      searchString = 'active';
    setSearch(searchString);
  });

  return (
    <Form onSubmit={handleSubmit}>
      <TableWrapper>
        <thead>
          <SmallHeader>
            {COLUMNS.map((column) => (
              <Cell key={column}>{column}</Cell>
            ))}
          </SmallHeader>
        </thead>
        <tbody>
          <RowWrapper>
            <Cell>
              <UserSearch
                onChange={handleSearchChange}
                placeholder={SEARCH_PLACEHOLDER}
                ref={searchRef}
                spellCheck='false'
                type='search'
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={filters.userTypes}
                isMulti
                onChange={setUserType}
                options={userTypeOptions}
                width='12rem'
              />
            </Cell>
            <Cell>
              <Button type='submit'>{SEARCH}</Button>
            </Cell>
          </RowWrapper>
        </tbody>
      </TableWrapper>
    </Form>
  );
};

const UserSearch = styled.input`
  font-family: Roobert;
  height: 40px;
  width: 270px;
  border: 1px solid;
  border-color: ${({ theme }: { theme: Theme }): string => theme.palette.silver.dark};
  border-radius: .3rem;
  padding: 4px 6px 4px 12px;
  font-size: 14px;
  letter-spacing: 0;
`;

export default Filters;
