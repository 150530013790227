/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import DeckGL from '@deck.gl/react';
import { OrthographicView, PickingInfo } from '@deck.gl/core';
import { PolygonLayer } from '@deck.gl/layers';
import Loader from '#/shared/Loader';
import { fillScale } from './utils';
import type { MapRollupDatum, Coord } from '#/types/MapRollup';
import RollupErrorComponent from '#/pages/clientReporting/DashboardPage/components/RollupErrorComponent';
import Tooltip from './Tooltip';
import useFetchMapRollup from './hooks/useFetchMapRollup';
import { CollectionEventsContext, CollectionSelectionsContext } from '../contexts';

interface Props {
  mapConfigId?: number;
}

const TRANSITION_MS = 500;

const VenueMap: React.FC<Props> = ({ mapConfigId }) => {
  const { selectedEventIds } = useContext(CollectionEventsContext);
  const {
    metricSelection,
    hasDealTerm,
    mapGranularity,
    isUnmanifestedFilter,
    bypassCache,
  } = useContext(CollectionSelectionsContext);
  const { key: selectedMetric } = metricSelection;

  const { hasError, isLoading, mapData } = useFetchMapRollup({
    bypassCache,
    hasDealTerm,
    isUnmanifested: isUnmanifestedFilter,
    selectedEventIds,
    granularity: mapGranularity,
    mapConfigId,
  });

  const isAvailable = !isLoading && !hasError && mapData;

  // delay hover interactions until transitions are over
  const [hoverInfo, setHoverInfo] = useState<PickingInfo<MapRollupDatum>>();
  const [canHover, setCanHover] = useState(false);

  useEffect(() => {
    setCanHover(false);
    const timeoutId = setTimeout(() => {
      if (isAvailable)
        setCanHover(true);
    }, TRANSITION_MS);

    return (): void => clearTimeout(timeoutId);
  }, [isAvailable, metricSelection]);

  return (
    <>
      <Wrapper>
        {isLoading && (
          <CenteredWrapper>
            <Loader
              data-testid='event-collection-rollup-loader'
              size={3.5}
              thickness={0.3}
            />
          </CenteredWrapper>
        )}
        {!isLoading && hasError && (
          <CenteredWrapper>
            <RollupErrorComponent />
          </CenteredWrapper>
        )}
        {isAvailable && (
          <MapWrapper>
            <DeckGL
              controller
              initialViewState={{
                target: [...mapData.center, 0], zoom: -0.4, minZoom: -0.5, maxZoom: mapData.maxZoom,
              }}
              layers={[new PolygonLayer<MapRollupDatum>({
                data: mapData.mapRollup,
                positionFormat: 'XY',
                pickable: true,
                getPolygon: (d): Coord[] => d.polygon,
                getLineColor: [200, 200, 200],
                getFillColor: (d): [number, number, number] => (
                  Number.isFinite(d?.metrics?.[selectedMetric])
                    ? fillScale({ mapData, selectedMetric, datum: d })
                    : [255, 255, 255]
                ),
                updateTriggers: {
                  getFillColor: {
                    selectedMetric,
                  },
                  onHover: {
                    canHover,
                  },
                },
                transitions: {
                  getFillColor: TRANSITION_MS,
                },
                onHover: canHover ? (info): void => setHoverInfo(info) : undefined,
                onDragStart: (): void => setHoverInfo(undefined),
              })]}
              views={new OrthographicView()}
            >
              {canHover && hoverInfo?.object && (
                <Tooltip hoverInfo={hoverInfo} selectedMetric={selectedMetric} />
              )}
            </DeckGL>
          </MapWrapper>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 550px;
`;

const CenteredWrapper = styled.div`
  display: flex;
  height: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
`;

const MapWrapper = styled.div`
  height: 650px;
  width: 100%;
  position: relative;
`;

export default VenueMap;
