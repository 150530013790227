import { AxiosResponse } from 'axios';
import { PeakpassDomain, PatchPeakpassDomain } from '#/types/PeakpassDomain';
import client from './_clientFormatted';

const patchPeakpassDomain = async (
  peakpassDomainId: string,
  request: PatchPeakpassDomain,
): Promise<PeakpassDomain> => {
  const response: AxiosResponse<PeakpassDomain> = await client.patch(
    `/proxy/stakeholders/peakpass_domain/${peakpassDomainId}`, request,
  );

  return response?.data;
};

export default patchPeakpassDomain;
