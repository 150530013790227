import React, { useMemo } from 'react';
import styled from 'styled-components';
import Loader from '#/shared/Loader';
import palette from '#/theme/palettes/main';
import useFetchPriceConstraints from '#/pages/useFetchPriceConstraints';
import PriceConstraintsTable from './PriceConstraintsTable';

const PRICE_CONSTRAINTS = 'Price Constraints';

const PriceConstraints: React.FC = () => {
  const { error, priceConstraints } = useFetchPriceConstraints();

  const showPriceConstraintsResults = useMemo(() => {
    return priceConstraints && !error;
  }, [error, priceConstraints]);

  return (
    <Wrapper>
      <div id="price-constraints">
        {showPriceConstraintsResults
          && priceConstraints?.sectionRowPriceConstraints
          && priceConstraints.sectionRowPriceConstraints.length > 0
          && (
            <TableWrapper>
              <Title>{`Section-Row ${PRICE_CONSTRAINTS}`}</Title>
              <PriceConstraintsTable data={priceConstraints.sectionRowPriceConstraints} />
            </TableWrapper>
          )}
        {!showPriceConstraintsResults && (
          <CenterContainer>
            <Loader hexColor={palette.brand.base} />
          </CenterContainer>
        )}
      </div>
    </Wrapper>
  );
};

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const TableWrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 1rem;
`;

export default PriceConstraints;
