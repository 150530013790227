import { AxiosResponse } from 'axios';
import { Deal, GetDealsResponse } from '#/types/Deal';
import client from './_clientFormatted';

const getDeals = async (stakeholder?: string, autobrokerEventId?: number): Promise<Deal[]> => {
  const response: AxiosResponse<GetDealsResponse> = await client.get(
    '/proxy/deals',
    {
      params: {
        stakeholder,
        autobroker_event_id: autobrokerEventId,
      },
    },
  );

  return response.data.deals;
};

export default getDeals;
