import React from 'react';
import {
  Wrapper, Element, Name, Value,
} from '#/shared/Card';
import { EventConfigTableData } from '../../utils/formatEventInfo';

interface CardProps {
  tableData: EventConfigTableData;
}

const OPTIONS = 'Options';

const ModelOptionsCard: React.FC<CardProps> = ({ tableData }) => {
  return (
    <Wrapper>
      <Element>
        <Name>{OPTIONS}</Name>
        <Value>{tableData.keepSupervisorActive}</Value>
        <Value>{tableData.resetPrices}</Value>
        <Value>{tableData.useSeatgeekTransactions}</Value>
        <Value>{tableData.highDemandOnsale}</Value>
        <Value>{tableData.demandChangepointCt}</Value>
      </Element>
    </Wrapper>
  );
};

export default ModelOptionsCard;
