import React, {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import styled from 'styled-components';
import {
  GG, Labels, ScaleFill, ScaleX, ScaleY,
} from '@graphique/graphique';
import { GeomTile } from '@graphique/geom-tile';
import { GeomLabel } from '@graphique/geom-label';
import { Theme } from '#/shared/graphique';
import { CollectionEventsContext, CollectionSelectionsContext } from '../contexts';
import { extent } from 'd3-array';
import {
  timeDays,
  timeHours,
  timeMinutes,
  timeMondays,
} from 'd3-time';
import { interpolateRdYlBu } from 'd3-scale-chromatic';
import { DateTime } from 'luxon';
import palette from '#/theme/palettes/main';
import type { EventTimeSeriesSalesSummary } from '../utils/getSalesTrendsByEvent';
import camelCase from 'lodash/camelCase';
import {
  ATP_KEY, LIFT_KEY, METRIC_OPTIONS, TimeMeasurement,
} from '../utils/menuOptions';
import Tooltip from './Tooltip';
import { scaleSequentialSqrt } from 'd3-scale';
import { GeomHLine } from '@graphique/geom-hline';
import {
  Control, ControlBar, TimeIntervalMenu, RelatedControls,
} from '../Controls';
import Legend from '../MapRollup/Legend';
import { LoadingWrapper } from '../PageComponents';
import Loader from '#/shared/Loader';
import MissingData from '../utils/MissingData';
import { stripTimeZone, TimeUnit } from '#/shared/reporting/accumulationUtils';
import useFetchReportingRollup, { RollupProps } from '#/pages/useFetchReportingRollup';
import getSalesTrendsByEvent, { isDefined } from '../utils/getSalesTrendsByEvent';

interface Props {
  isLoading?: boolean;
  onChartUnfocus?: () => void;
  onDatumSelection: (d: EventTimeSeriesSalesSummary[], id: number) => void;
}

const createKey = (d: EventTimeSeriesSalesSummary): string => `${d.timeValue}-${d.autobrokerEvent.autobrokerEventId}`;
const formatYTicks = ({ value }: { value: string }): string => value.replace(/-.*/, '');
const MAX_TIME_BINS = 50;

const TimeHeatmap: React.FC<Props> = ({
  isLoading,
  onChartUnfocus,
  onDatumSelection,
}) => {
  const { selectedEventIds, listFocusedEventId } = useContext(CollectionEventsContext);
  const {
    metricSelection, handleMetricSelection, timeIntervalSelection,
    selectedGroup, hasDealTerm, autobrokerEventIds, isUnmanifestedFilter, bypassCache,
  } = useContext(CollectionSelectionsContext);

  useEffect(() => {
    if (metricSelection.key === 'cumulativeTicketsUnsoldPercent')
      handleMetricSelection(METRIC_OPTIONS[1].key);
  }, [metricSelection, handleMetricSelection]);

  const focusedYTicks = useMemo(() => (
    listFocusedEventId ? [String(listFocusedEventId)] : undefined
  ), [listFocusedEventId]);

  const fromDatetime = useMemo(() => {
    // include a default starting point for narrow time intervals
    const { value } = timeIntervalSelection;

    if (value === TimeUnit.HOUR || value === TimeUnit.MINUTE) {
      // use the current time window as a reference point
      const now = DateTime.now().endOf(timeIntervalSelection.value);

      return now.minus({ [timeIntervalSelection.value]: timeIntervalSelection.max });
    }
    return undefined;
  }, [timeIntervalSelection]);

  const rollupParams: RollupProps = {
    bypassCache,
    group: ['autobroker_event', 'map_config_id', TimeMeasurement.CALENDAR],
    autobrokerEventId: autobrokerEventIds,
    autobrokerEventGroup: selectedGroup?.name,
    fromDatetime: fromDatetime ? stripTimeZone(fromDatetime.toISO()) : undefined,
    isUnmanifested: isUnmanifestedFilter ?? undefined,
    hasDealTerm: hasDealTerm || undefined,
    logInterval: undefined,
    timeInterval: `${timeIntervalSelection.key}`,
    includePortfolioValue: true,
  };

  const { rollup, isLoading: isRollupLoading } = useFetchReportingRollup(rollupParams);
  const timeBins = Array.from(
    new Set(rollup?.map(({ group }) => group.time)),
  ).slice(-MAX_TIME_BINS);

  const data = useMemo(() => (
    rollup
      ? getSalesTrendsByEvent({
        eventLevelRollup: rollup
          .filter(({ group }) => timeBins.includes(group.time)),
        eventLevelRollupPreview: [],
        timeInterval: timeIntervalSelection.interval,
        timeUnit: timeIntervalSelection.value as TimeUnit,
        shouldPrependZero: false,
      }).salesTrendsByEvent
      : undefined
  ), [rollup, timeIntervalSelection, timeBins]);

  const isPending = isLoading || isRollupLoading;

  const selectedData = useMemo(() => {
    return (
      data?.length > 0
        ? data
          .filter((d) => selectedEventIds.includes(d?.autobrokerEvent?.autobrokerEventId))
          ?.sort((a, b) => {
            const diff = (
              Number(a.autobrokerEvent.eventStartsAt) - Number(b.autobrokerEvent.eventStartsAt)
            );

            if (diff !== 0)
              return diff;

            return (
              a.autobrokerEvent?.eventPartition?.sources?.[0]
                < b.autobrokerEvent?.eventPartition?.sources?.[0]
                ? 1 : -1
            );
          })
        : undefined
    );
  }, [data, selectedEventIds]);

  const completeTimeDomain = useMemo(() => {
    if (selectedData?.length > 0) {
      const timeExtent = extent(selectedData?.map((d) => d?.timeValue as Date));

      // we need to extend the max values by the time granularity,
      // since the stop value for creating a time range corresponds to [t0, t1)
      const extendedMax = DateTime.fromJSDate(timeExtent[1])
        .plus({ [timeIntervalSelection.value]: timeIntervalSelection.interval })
        .toJSDate();

      switch (timeIntervalSelection.value) {
        case TimeUnit.WEEK:
          return timeMondays(timeExtent[0], extendedMax, timeIntervalSelection.interval);

        case TimeUnit.DAY:
          return timeDays(timeExtent[0], extendedMax, timeIntervalSelection.interval);

        case TimeUnit.HOUR:
          return timeHours(timeExtent[0], extendedMax, timeIntervalSelection.interval);

        case TimeUnit.MINUTE:
          return timeMinutes(timeExtent[0], extendedMax, timeIntervalSelection.interval);

        default:
          return undefined;
      }
    }
    return undefined;
  }, [selectedData, timeIntervalSelection]);

  // fill in implicit 0s in the past for missing data
  const allowMissing = [ATP_KEY, LIFT_KEY].includes(metricSelection.key);
  const completeSelectedData = useMemo(() => {
    const thisKey = camelCase(metricSelection.key.replace('cumulative', '')) as keyof EventTimeSeriesSalesSummary;

    const selectedDataIds = Array.from(
      new Set((selectedData?.map((d) => d.autobrokerEvent.autobrokerEventId))),
    );

    return (
      completeTimeDomain?.flatMap((time) => (
        selectedDataIds?.map((eventId) => {
          const thisEventData = selectedData
            .find((d) => d.autobrokerEvent.autobrokerEventId === eventId);
          const thisData = selectedData.find(
            (d) => (
              Number(d.timeValue) === Number(time)
                && d.autobrokerEvent.autobrokerEventId === eventId
            ),
          );
          const isInPast = (
            Number(thisEventData?.timeValue as Date) < Number(time)
          );

          if (!thisData) {
            return {
              autobrokerEvent: thisEventData.autobrokerEvent,
              timeValue: time,
              [thisKey]: 0,
            };
          }

          const thisMetricValue = thisData[thisKey];

          return {
            ...thisData,
            [thisKey]: isInPast && !thisMetricValue ? 0 : thisMetricValue,
          };
        })
      ))
        // don't fill future values or metrics that should remain undefined if missing
        ?.map((d) => {
          const isInFuture = (
            Number(d.timeValue) > Number(d.autobrokerEvent.eventStartsAt.toJSDate())
          );

          const shouldExclude = allowMissing && d[thisKey] === 0;

          return {
            ...d,
            [thisKey]: (isInFuture || shouldExclude) ? undefined : d[thisKey],
          };
        })
    );
  }, [selectedData, completeTimeDomain, metricSelection, allowMissing]);

  const focusedKeys = useMemo(() => (
    completeSelectedData?.length > 0 && listFocusedEventId
      ? completeSelectedData
        .filter((d) => d?.autobrokerEvent?.autobrokerEventId === listFocusedEventId)
        .map(createKey)
      : undefined
  ), [completeSelectedData, listFocusedEventId]);

  // scroll to event in collection list when clicking focused trend line
  const handleSelection = useCallback((d: EventTimeSeriesSalesSummary[]) => {
    const id = d?.[0]?.autobrokerEvent?.autobrokerEventId;

    if (id)
      onDatumSelection(d, id);
  }, [onDatumSelection]);

  const firstFutureEvent = useMemo(() => {
    const now = DateTime.now();

    const futureEvents = selectedData?.filter((d) => d.autobrokerEvent?.eventStartsAt > now);

    if (futureEvents?.length > 0) {
      const sortedEvents = futureEvents.sort((a, b) => (
        Number(a.autobrokerEvent.eventStartsAt) - Number(b.autobrokerEvent.eventStartsAt)
      ));

      return [sortedEvents[0]];
    }

    return undefined;
  }, [selectedData]);

  const fill = useCallback((d: Partial<EventTimeSeriesSalesSummary>) => (
    d?.[camelCase(metricSelection.key.replace('cumulative', '')) as keyof EventTimeSeriesSalesSummary] as number
  ), [metricSelection]);

  const xFormat = useCallback((d: Date) => {
    switch (timeIntervalSelection.value) {
      case TimeUnit.WEEK:
        return `Week of ${d.toLocaleDateString(undefined, { dateStyle: 'medium' })}`;
      case TimeUnit.HOUR:
        return d.toLocaleString(
          undefined,
          {
            month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric',
          },
        );
      case TimeUnit.MINUTE:
        return d.toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short' });
      default:
        return d.toLocaleDateString(undefined, { dateStyle: 'medium' });
    }
  }, [timeIntervalSelection]);

  const allZeros = useMemo(() => (
    completeSelectedData?.every(
      (d) => d[metricSelection.key] === 0 || !isDefined(d[metricSelection.key]),
    )
  ), [completeSelectedData, metricSelection]);

  // data for directly labeling min/max values on chart
  const minMaxData = useMemo(() => {
    if (completeSelectedData) {
      const fillExtent = extent(completeSelectedData, fill);

      const minData = completeSelectedData.find((d) => fill(d) === fillExtent[0]);
      const maxData = completeSelectedData.find((d) => fill(d) === fillExtent[1]);

      return [minData, maxData];
    }

    return undefined;
  }, [completeSelectedData, fill]);

  return (
    <>
      <ControlBar isLoading={isPending}>
        <RelatedControls align='top' fillSpace>
          <Control>
            <TimeIntervalMenu isLoading={isPending} />
          </Control>
          {!allZeros && (
            <Control>
              <Legend data={completeSelectedData} fill={fill} isLoading={isPending} />
            </Control>
          )}
        </RelatedControls>
      </ControlBar>
      {isPending && (
        <LoadingWrapper>
          <Loader
            data-testid='event-collection-rollup-loader'
            size={3.5}
            thickness={0.3}
          />
        </LoadingWrapper>
      )}

      {!isPending && (
        <Wrapper>
          {completeSelectedData?.length > 0 && !allZeros ? (
            <>
              <GG
                aes={{
                  x: (d): Date => d.timeValue as Date,
                  y: (d): string => (
                    `${d.autobrokerEvent.eventStartsAtLocal.toFormat('D')}-${d.autobrokerEvent.autobrokerEventId}`
                  ),
                  fill,
                  key: createKey,
                }}
                data={completeSelectedData}
                height={610}
                isContainerWidth
                margin={{ left: 84 }}
              >
                <GeomTile
                  focusedKeys={focusedKeys}
                  focusedStyle={{ stroke: palette.black.base }}
                  onDatumSelection={handleSelection}
                  onExit={onChartUnfocus}
                  stroke={palette.white.base}
                  style={{ stroke: palette.white.base }}
                  unfocusedStyle={{ fillOpacity: 0.25, stroke: palette.white.base }}
                  xDomain={completeTimeDomain}
                  xPadding={0.02}
                  yPadding={0.15}
                />
                {firstFutureEvent && (
                  <GeomHLine
                    data={firstFutureEvent}
                    dy={10}
                    showTooltip={false}
                    stroke={palette.brand.base}
                    strokeDasharray='3,2'
                  />
                )}
                {minMaxData?.length > 0 && (
                  <GeomLabel
                    aes={{
                      label: (d): string => metricSelection
                        .format({ value: fill(d), showDecimal: false, abbreviate: true }),
                    }}
                    alignmentBaseline='middle'
                    data={minMaxData}
                    dx={0}
                    dy={1}
                    entrance='data'
                    fillOpacity={0.85}
                    fontSize={14.5}
                    isClipped={false}
                    showTooltip={false}
                    strokeWidth={4.5}
                    textAnchor='middle'
                  />
                )}
                <ScaleX numTicks={5} />
                <ScaleY
                  focusedTicks={focusedYTicks}
                  format={formatYTicks}
                  highlightOnFocus
                />
                <ScaleFill reverse type={scaleSequentialSqrt} values={interpolateRdYlBu} />
                <Tooltip
                  dx={20}
                  xFormat={xFormat}
                />
                <Theme
                  axis={{ showAxisLines: false }}
                  axisY={{ tickLabel: { fontSize: '12px' } }}
                />
                <Labels
                  x='Transaction time &rarr;'
                  y='Event start &darr;'
                />
              </GG>
            </>
          ) : (
            <MissingData />
          )}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export { Props };
export default TimeHeatmap;
