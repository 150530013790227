import React from 'react';
import styled from 'styled-components';

const ChartControlsContainer: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ChartControls>
    <Spacer />
    {children}
  </ChartControls>
);

const Spacer = styled.div`
  width: 12em;
`;

const ChartControls = styled.div`
  margin-top: 0.4em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default ChartControlsContainer;
