import React, {
  useMemo, useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ReportingContext from '#/pages/clientReporting/ReportingContext';
import { formatSlugToTitle, createQueryParams } from '#/pages/clientReporting/DashboardPage/utils';
import useFetchDeals from '#/pages/useFetchDeals';
import { Dropdown, Item } from '#/shared/clientReporting/Dropdown';
import { Label } from '#/shared/clientReporting/ListBox';
import { Deal, DealTerm } from '#/types/Deal';

interface Props {
  stakeholder: string;
}

interface DisplayDealTerm extends DealTerm {
  displayName?: string;
}

const SELECT_DEAL = 'Select deal';
const SELECT_DEAL_TERMS = 'Select terms';
const checkDealTermHasIngestions = (dt: DealTerm): boolean => dt.profits.ingestedTicketCt > 0;

const DealFilters: React.FC<Props> = ({ stakeholder }) => {
  const { deals, dealsError } = useFetchDeals({ stakeholder: stakeholder });
  const {
    hasPendingData,
    queryParams,
    setReportingSelection,
  } = useContext(ReportingContext);
  const [selectedDeal, setSelectedDeal] = useState<Deal | undefined>();
  const [selectedDealTerm, setSelectedDealTerm] = useState<DisplayDealTerm | undefined>();

  const navigate = useNavigate();

  const dealsWithIngestions = useMemo(() => (
    deals?.filter(({ dealTerms }) => {
      const hasTermsWithIngestions = dealTerms.some(checkDealTermHasIngestions);

      return hasTermsWithIngestions;
    })
  ), [deals]);

  const dealOptions = useMemo(() => ([
    {
      name: '',
      id: -1,
      displayName: 'All deals',
    },
    ...dealsWithIngestions,
  ]), [dealsWithIngestions]);

  const dealTermOptions = useMemo(() => {
    const selectedDealTermsWithIngestions = selectedDeal?.dealTerms.filter(
      checkDealTermHasIngestions,
    );

    return (
      selectedDealTermsWithIngestions?.length > 0
        ? [
          {
            name: '',
            displayName: 'All terms',
            id: -1,
          },
          ...selectedDealTermsWithIngestions.map((dt) => ({
            ...dt,
            displayName: formatSlugToTitle(dt.name),
          })),
        ] as DealTerm[]
        : []
    );
  }, [selectedDeal]);

  const { deal: queriedDeal, dealTerm: queriedDealTerm } = queryParams;

  useEffect(() => {
    const dealSelection = dealsWithIngestions?.find((d) => d.name === queriedDeal);
    const dealTermSelection = dealTermOptions?.find((dt) => dt.name === queriedDealTerm);

    setSelectedDeal(dealSelection);
    setSelectedDealTerm(dealTermSelection);
    setReportingSelection((prev) => ({
      ...prev,
      selectedDeal: dealSelection,
      selectedDealTerm: dealTermSelection,
    }));
  }, [
    queriedDeal,
    queriedDealTerm,
    dealsWithIngestions,
    dealTermOptions,
    setReportingSelection,
  ]);

  const handleDealOrTermChange = useCallback((kind?: 'deal' | 'term') => (
    (name?: string): void => {
      if (name) {
        if (kind === 'deal') {
          const thisDeal = dealsWithIngestions.find((d) => d.name === name);

          setReportingSelection((prev) => ({
            ...prev,
            selectedDeal: thisDeal,
            selectedDealTerm: undefined,
          }));

          navigate({
            search: createQueryParams(
              queryParams,
              {
                eventId: undefined,
                deal: thisDeal?.name,
                dealTerm: undefined,
              },
            ),
          });
        }
        if (kind === 'term') {
          const thisTerm = dealTermOptions.find((d) => d.name === name);

          setReportingSelection((prev) => ({
            ...prev,
            selectedDealTerm: thisTerm,
          }));
          navigate({
            search: createQueryParams(
              queryParams,
              {
                eventId: undefined,
                deal: selectedDeal?.name,
                dealTerm: thisTerm?.name,
              },
            ),
          });
        }
      } else {
        if (kind === 'deal') {
          navigate({
            search: createQueryParams(
              queryParams,
              {
                eventId: undefined,
                deal: undefined,
                dealTerm: undefined,
              },
            ),
          });

          setReportingSelection((prev) => ({
            ...prev,
            selectedDeal: undefined,
            selectedDealTerm: undefined,
          }));
        }

        if (kind === 'term') {
          setReportingSelection((prev) => ({
            ...prev,
            selectedDealTerm: undefined,
          }));

          navigate({
            search: createQueryParams(
              queryParams,
              {
                eventId: undefined,
                deal: kind === 'term' ? selectedDeal?.name : undefined,
                dealTerm: undefined,
              },
            ),
          });
        }
      }
    }
  ), [
    dealsWithIngestions,
    navigate,
    queryParams,
    dealTermOptions,
    selectedDeal,
    setReportingSelection,
  ]);

  const shouldHideFilters = useMemo(() => (
    dealsError || dealsWithIngestions?.length === 0
  ), [dealsError, dealsWithIngestions]);

  return (
    shouldHideFilters
      ? null
      : (
        <Wrapper>
          <Dropdown
            aria-label={SELECT_DEAL}
            isLoading={hasPendingData}
            items={dealOptions}
            onSelectionChange={handleDealOrTermChange('deal')}
            placeholder={SELECT_DEAL}
            selectedKey={selectedDeal?.name ?? null}
          >
            {(item: Deal): JSX.Element => (
              <Item
                key={item.name}
                textValue={item.displayName}
              >
                <Label maxWidth={180}>{item.displayName}</Label>
              </Item>
            )}
          </Dropdown>
          {
            selectedDeal && dealTermOptions?.length > 2 && (
              <Dropdown
                aria-label={SELECT_DEAL_TERMS}
                isLoading={hasPendingData}
                items={dealTermOptions}
                onSelectionChange={handleDealOrTermChange('term')}
                placeholder={SELECT_DEAL_TERMS}
                selectedKey={selectedDealTerm?.name ?? null}
              >
                {(item: DisplayDealTerm): JSX.Element => (
                  <Item
                    key={item.name}
                    textValue={item.displayName}
                  >
                    <Label maxWidth={180}>{item.displayName}</Label>
                  </Item>
                )}
              </Dropdown>
            )
          }
        </Wrapper>
      )
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-left: 0.3rem;
  border-left: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}`};
  padding: 0 0.3rem 0 0.6rem;
`;

export default DealFilters;
