import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import styled from 'styled-components';
import {
  GG,
  Labels,
  ScaleX,
  ScaleY,
  ScaleFill,
  ScaleStroke,
} from '@graphique/graphique';
import { scaleLog, scaleSymlog } from 'd3-scale';
import { GraphiqueWrapper, Wrapper } from '../sharedGraphComponents/styledComponents';
import { Theme } from '#/shared/graphique';
import { formatCurrency, formatGtv } from '#/shared/formatNumberForDisplay';
import Legend from '../sharedGraphComponents/Legend';
import Tooltip from './Tooltip';
import { renderAreas, renderLines, renderPoints } from './renderGeoms';
import PriceDistributionGraphInfo, {
  PriceDistributionListings,
} from '#/types/Preview';
import Event, { PRICING_BASIS_OPTIONS } from '#/types/Event';
import { exponentiate, formatYTicks, isDefined } from '../utils/dataFormatting';
import {
  formatListings,
  formatDistributionData,
  formatDollarYTicks,
  isTarget,
  palette,
  groups,
  getMaxDisplayY,
  getSharedXExtent,
  getSharedYExtent,
  sampleListings,
  priceAccessor,
} from './utils';
import { FormattedPricingChartDatum } from '../types';
import ChartControlsContainer from '../sharedGraphComponents/ChartControlsContainer';
import { Dropdown, Item } from '#/shared/clientReporting/Dropdown';
import { Label } from '#/shared/clientReporting/ListBox';
import { PriceDistroViewMode, PRICE_DISTRO_VIEW_OPTIONS } from './hooks/useViewSelection';
import { SG_SALE } from '#/api/utils/formatMarketplaceTransaction';

interface GraphProps {
  distributionOverlay: PriceDistributionGraphInfo;
  overlayError: string;
  priceDistributionGraphInfo: PriceDistributionGraphInfo;
  priceDistributionListings: PriceDistributionListings;
  marketplaceTransactions?: FormattedPricingChartDatum[];
  activeEvent?: Event;
  loading?: boolean;
  isPrice: boolean;
  handleViewSelection: (name?: PriceDistroViewMode) => void;
  viewSelection: PriceDistroViewMode;
  listingMarketplaces: string[];
}

const X_AXIS_LABEL = 'Expected value';
const Y_AXIS_LABEL = 'Log price ratio';
const SMALL_X_RANGE_THRESHOLD = 5;

const Graph: React.FC<GraphProps> = ({
  distributionOverlay,
  overlayError,
  priceDistributionGraphInfo,
  priceDistributionListings,
  marketplaceTransactions,
  activeEvent,
  loading,
  isPrice,
  viewSelection,
  handleViewSelection,
  listingMarketplaces,
}) => {
  let id;

  if (activeEvent)
    ({ id } = activeEvent);
  const {
    priceDistributionGraphData,
  } = priceDistributionGraphInfo;

  const basis = useMemo(() => (
    activeEvent?.config?.pricingBasis
  ), [activeEvent]);

  const showOverlay = useMemo(() => (
    !overlayError && distributionOverlay
  ), [overlayError, distributionOverlay]);

  const distributionData = useMemo(() => {
    let formattedData = formatDistributionData(priceDistributionGraphData);

    // merges / replaces data with values from overlay data
    if (showOverlay) {
      const formattedOverlay = formatDistributionData(
        distributionOverlay.priceDistributionGraphData,
      );
      const existingData = formattedData.filter((d) => d.group !== 'Model');

      formattedData = [...existingData, ...formattedOverlay];
    }

    return formattedData;
  }, [priceDistributionGraphData, showOverlay, distributionOverlay]);

  const maxDisplayY = useMemo(() => {
    const hasDistributionData = distributionData?.length > 0;

    return (
      getMaxDisplayY(
        hasDistributionData
          ? distributionData
          : (marketplaceTransactions ?? []),
        isPrice,
        basis,
        hasDistributionData,
      )
    );
  }, [distributionData, marketplaceTransactions, isPrice, basis]);

  const pointData = useMemo(() => {
    const filterY = (d: FormattedPricingChartDatum): boolean => (
      isPrice ? (d.allInPrice <= maxDisplayY) : (d.logPriceRatio <= maxDisplayY)
    );

    const listingsInRange = formatListings(priceDistributionListings).filter(filterY);
    const sampledListings = sampleListings(listingsInRange);

    return marketplaceTransactions && viewSelection === SG_SALE ? [
      ...sampledListings,
      ...marketplaceTransactions?.filter(filterY),
    ] : sampledListings;
  }, [priceDistributionListings, maxDisplayY, isPrice, marketplaceTransactions, viewSelection]);

  const formattedData = useMemo(() => (
    [...pointData, ...distributionData]
  ), [pointData, distributionData]);

  const existingGroups = useMemo(() => {
    const pointGroups = Array.from(new Set(
      pointData.filter((d) => isDefined(d.logPriceRatio) && isDefined(d.logExpectedValue))
        .map((d) => d.group),
    ));

    const distributionGroups = Array.from(new Set(
      distributionData.filter((d) => (
        isDefined(d.logPriceRatio) && d.priceBand.every((g) => isDefined(g.logExpectedValue))
      ))
        .map((d) => d.group),
    ));

    return Array.from(new Set([...pointGroups, ...distributionGroups]));
  }, [pointData, distributionData]);
  const [includedGroups, setIncludedGroups] = useState<string[]>(groups);

  const handleLegendSelection = useCallback((v: string) => {
    if (!existingGroups.includes(v))
      return;
    setIncludedGroups((prev) => {
      const possibleGroups = prev.filter((p) => existingGroups.includes(p));

      if (possibleGroups.includes(v) && possibleGroups.length === 1)
        return existingGroups;
      return possibleGroups.includes(v)
        ? possibleGroups.filter((p) => p !== v)
        : [...possibleGroups, v];
    });
  }, [existingGroups]);

  const includedData = useMemo(() => (
    formattedData.filter((d) => includedGroups.includes(d.group))
  ), [formattedData, includedGroups]);

  const includedPointData = useMemo(() => (
    pointData.filter((d) => includedGroups.includes(d.group) && d.measure === viewSelection)
  ), [pointData, includedGroups, viewSelection]);

  const includedDistributionData = useMemo(() => (
    distributionData.filter((d) => includedGroups.includes(d.group))
  ), [distributionData, includedGroups]);

  const sharedXExtent = useMemo(() => (
    getSharedXExtent(includedPointData, includedDistributionData)
  ), [includedPointData, includedDistributionData]);

  const sharedYExtent = useMemo(() => (
    getSharedYExtent(includedPointData, includedDistributionData, isPrice)
  ), [includedPointData, includedDistributionData, isPrice]);

  const formatXDollars = useCallback(({ value }: { value: number }) => {
    const xRange = sharedXExtent[1] - sharedXExtent[0];

    return (
      xRange < SMALL_X_RANGE_THRESHOLD ? formatCurrency(value) : formatGtv(value, false)
    );
  }, [sharedXExtent]);

  const formatYDollars = useCallback(({ value }: { value: number }) => {
    const yRange = sharedYExtent[1] - sharedYExtent[0];

    return formatDollarYTicks(value, yRange);
  }, [sharedYExtent]);

  const yAxisLabel = useMemo(() => {
    if (!isPrice)
      return Y_AXIS_LABEL;
    const label = PRICING_BASIS_OPTIONS.find((option) => option.value === basis)?.label;

    return label || PRICING_BASIS_OPTIONS[0].label;
  }, [isPrice, basis]);
  const areaData = useMemo(() => {
    const filteredAreaData = includedDistributionData.filter(
      (d) => !isTarget(d.measure),
    );

    return filteredAreaData;
  }, [includedDistributionData]);
  const lineData = useMemo(() => includedDistributionData.filter(
    (d) => isTarget(d.measure),
  ), [includedDistributionData]);

  useEffect(() => {
    if (includedData?.length === 0)
      setIncludedGroups(groups);
  }, [includedData]);

  const availableViewOptions = useMemo(() => {
    const hasSales = marketplaceTransactions?.length > 0;
    const availableOptions = PRICE_DISTRO_VIEW_OPTIONS.filter((o) => {
      return (
        o.label === PriceDistroViewMode.SG_LISTINGS
        || listingMarketplaces?.includes(o.key)
        || (o.label === PriceDistroViewMode.SG_SALES && hasSales)
      );
    });

    return availableOptions;
  }, [listingMarketplaces, marketplaceTransactions]);

  return (
    <Wrapper>
      <ChartWrapper>
        <GG
          aes={{
            x: (d): number => exponentiate(d.logExpectedValue) ?? 0,
            y: (d): number => (
              isPrice ? priceAccessor(d, basis) ?? d.price : d.logPriceRatio
            ),
            fill: (d): string => d.group,
            stroke: (d): string => d.group,
          }}
          data={includedData}
          height={440}
          isContainerWidth
          margin={{ left: isPrice ? 60 : 52 }}
        >
          {renderAreas(areaData, id, isPrice)}
          {renderLines(lineData, id, isPrice)}
          {!loading && (
            renderPoints(includedPointData)
          )}
          <ScaleX
            domain={sharedXExtent}
            format={formatXDollars}
            numTicks={4}
            type={scaleSymlog}
          />
          <ScaleY
            domain={sharedYExtent}
            format={isPrice ? formatYDollars : formatYTicks}
            type={isPrice ? scaleLog : undefined}
          />
          <ScaleFill domain={groups} values={palette} />
          <ScaleStroke domain={groups} values={palette} />
          <Labels
            header={(
              <ChartControlsContainer>
                <Legend kind='point' onSelection={handleLegendSelection} />
                <DropdownWrapper>
                  <Dropdown
                    aria-label='price-distribution-view-selection'
                    id='price-distribution-view-menu'
                    isLoading={loading}
                    items={availableViewOptions}
                    onSelectionChange={handleViewSelection}
                    selectedKey={viewSelection}
                  >
                    {({ label, imgSrc }): JSX.Element => (
                      <Item
                        key={label}
                        textValue={label}
                      >
                        <Label maxWidth={180}>
                          <Option>
                            {imgSrc ? <img alt={label} height={25} src={imgSrc} /> : undefined}
                            {label}
                          </Option>
                        </Label>
                      </Item>
                    )}
                  </Dropdown>
                </DropdownWrapper>
              </ChartControlsContainer>
            )}
            x={X_AXIS_LABEL}
            y={yAxisLabel}
          />
          <Tooltip isPrice={isPrice} />
          <Theme />
        </GG>
      </ChartWrapper>
    </Wrapper>
  );
};

const ChartWrapper = styled(GraphiqueWrapper as any)`
  padding-top: 2px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 12em;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export default Graph;
export { X_AXIS_LABEL, Y_AXIS_LABEL };
