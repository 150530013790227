import {
  ListingsGraphData,
  PriceRatioGraphData,
  GraphDomain,
  GraphArray,
} from '#/types/Snapshot';
import calcMin from './calcMin';

type GraphData = ListingsGraphData | PriceRatioGraphData;

const calcDomainTruncated = (graphData: GraphData): GraphDomain => {
  const ZERO = 0;
  const ONE = 1;
  const graphDomain = { x: [ZERO, ONE], y: [ZERO, ONE] };
  const X_PADDING = 2;

  let minX: number;

  const datapoints = Object.keys(graphData);

  datapoints.forEach((el: keyof GraphData): void => {
    const plotArray = graphData[el] as GraphArray;
    const lastIdx = plotArray.length - ONE;

    for (let i = lastIdx; i >= ZERO; i -= ONE) {
      const xySet = plotArray[i];

      if (xySet.y)
        minX = calcMin(minX, xySet.x);
      const y0 = xySet.y0 || xySet.y;

      graphDomain.y[0] = Math.min(graphDomain.y[0], xySet.y, y0);
      graphDomain.y[1] = Math.max(graphDomain.y[1], xySet.y);
      graphDomain.x[1] = Math.max(graphDomain.x[1], xySet.x);
    }
  });

  graphDomain.x[0] = minX - X_PADDING || ZERO;
  return graphDomain;
};

export default calcDomainTruncated;
