import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import {
  ApiExpectedValueBinSnapshotResponse,
  ExpectedValueBinSnapshotResponse,
  ExpectedValueBinMeasure,
} from '#/types/SelldownByExpectedValue';

const formatExpectedValueSnapshots = (
  response: ApiExpectedValueBinSnapshotResponse,
): ExpectedValueBinSnapshotResponse => {
  const { expectedValueBins, snapshots } = response;

  const formattedData = (
    snapshots.flatMap((s) => (
      s.bins
        .map((sb) => ({
          logHoursToEvent: s.logHoursToEvent,
          ticketsRemaining: sb.ticketsRemaining,
          selldownActual: sb.selldownActual,
          group: expectedValueBins.find((b) => b.id === sb.expectedValueBinId),
          measure: ExpectedValueBinMeasure.ACTUAL,
        }))
    ))
  );

  return { formattedData, expectedValueBins };
};

const getSelldownByExpectedValue = (
  async (eventId: number): Promise<ExpectedValueBinSnapshotResponse> => {
    const response: AxiosResponse<ApiExpectedValueBinSnapshotResponse> = await client.get(
      `/proxy/history/${eventId}/selldown_by_expected_value`,
    );
    const raw = response?.data;

    return formatExpectedValueSnapshots(raw);
  }
);

export default getSelldownByExpectedValue;
