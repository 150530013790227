import React from 'react';
import styled from 'styled-components';
import { CurrentStateData } from '#/types/Snapshot';
import currentStateLabels, { CurrentState as CS } from './constants';
import CompetitiveView from './CompetitiveView';
import Bullet from './Bullet';

interface Props {
  data: CurrentStateData;
  loading: boolean;
  showData: boolean;
}

interface DataPointProps {
  dataPoint: CS;
  value: number | boolean | string;
}

const DataPoint: React.FC<DataPointProps> = ({ dataPoint, value }) => {
  const dataKey = currentStateLabels[dataPoint];

  return (
    <Datum>
      <Bullet />
      <p>{`${dataKey}: ${value}`}</p>
    </Datum>
  );
};

const MODEL = 'Model data';
const EVENT_TIME = 'Remaining time to event';
const SALES = 'Sales';

const CurrentStateDataTable: React.FC<Props> = ({
  data,
  loading,
  showData,
}) => {
  const showTheThing = !loading && showData;

  return (
    showTheThing && (
      <Outer>
        <Wrapper>
          <CompetitiveView data={data} />
        </Wrapper>
        <Wrapper>
          <SubHead>{MODEL}</SubHead>
          <DataPoint
            dataPoint={CS.modelLogPriceRatio}
            value={data.modelLogPriceRatio}
          />
          <DataPoint
            dataPoint={CS.modelLogPriceRatioTarget}
            value={data.modelLogPriceRatioTarget}
          />
          <DataPoint
            dataPoint={CS.isUnconstrained}
            value={data.isUnconstrained}
          />
          <DataPoint dataPoint={CS.futureDemand} value={data.futureDemand} />
          <DataPoint dataPoint={CS.localDemand} value={data.localDemand} />
          <DataPoint
            dataPoint={CS.localElasticity}
            value={data.localElasticity}
          />
          <DataPoint
            dataPoint={CS.localMeanTicketsPerTransaction}
            value={data.localMeanTicketsPerTransaction}
          />
        </Wrapper>
        <Wrapper>
          <SubHead>{EVENT_TIME}</SubHead>
          <DataPoint dataPoint={CS.daysToEvent} value={data.daysToEvent} />
          <DataPoint
            dataPoint={CS.projectedSelldown}
            value={data.projectedSelldown}
          />
        </Wrapper>
        <Wrapper>
          <SubHead>{SALES}</SubHead>
          <DataPoint
            dataPoint={CS.transactionCount}
            value={data.transactionCount}
          />
          <DataPoint
            dataPoint={CS.ticketsSoldCount}
            value={data.ticketsSoldCount}
          />
          <DataPoint
            dataPoint={CS.ticketsSoldGtv}
            value={`$${data?.ticketsSoldGtv.toFixed(2)}`}
          />
          <DataPoint
            dataPoint={CS.ticketsUnsoldCount}
            value={data.ticketsUnsoldCount}
          />
          <DataPoint
            dataPoint={CS.listingsVisible}
            value={data.listingsRemaining}
          />
        </Wrapper>
      </Outer>
    )
  );
};

const Datum = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0.25rem 0;
  }
  margin-left: 0.5rem;
`;
const SubHead = styled.p`
  ${({ theme }: { theme: Theme }): string => theme.text2}
  margin: 1rem 0 .5rem;
`;
const Outer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 80%;
  width: 85%;
  margin-top: 2rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 1rem 2rem;
  padding-left: 3rem;
  min-height: 11rem;
`;

export default CurrentStateDataTable;
