import React from 'react';
import {
  NarrowWrapper, Element, Name, Value, NameValue,
} from '#/shared/Card';
import PriceLevel from '#/types/PriceLevel';
import { PRICING_BASIS_OPTIONS } from '#/types/Event';
import { formatNumber } from '#/shared/formatNumberForDisplay';

interface CardProps {
  priceLevel: PriceLevel;
}

const PRICE_LEVEL_PRICE = 'Price Level Price';
const LOG_PRICE_RATIO = 'Log Price Ratio';

const PriceCard: React.FC<CardProps> = ({ priceLevel }) => {
  const { price, summary, updatedAt } = priceLevel;
  const priceLabel = PRICING_BASIS_OPTIONS
    .find((option) => option.value === price.basis)
    ?.label;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{PRICE_LEVEL_PRICE}</Name>
      </Element>
      {price.logPriceRatio && (
        <Element>
          <NameValue>{`${LOG_PRICE_RATIO}: ${formatNumber(price.logPriceRatio)}`}</NameValue>
        </Element>
      )}
      <Element>
        <Name>{priceLabel}</Name>
        <Value>{`${summary.ticketCt}x • $${price.amount.toFixed(2)}`}</Value>
      </Element>
      <Element>
        <Name>{`Updated ${updatedAt.toRelative()}`}</Name>
      </Element>
    </NarrowWrapper>
  );
};

export default PriceCard;
