import { DateTime } from 'luxon';
import Listing, { APIListing, ListingPolicyPrices } from '#/types/Listing';
import { InputListingOverride, APIInputListingOverride } from '#/types/Ingestion';
import { PricingBasis } from '#/types/Event';

const mapOverride = (override: APIInputListingOverride): InputListingOverride => {
  return {
    ...override,
    created_at: override.created_at ? DateTime.fromISO(override.created_at, { zone: 'utc' }) : null,
  };
};

const mapListing = (listing: APIListing): Listing => {
  const { price, policy_prices } = listing;

  return {
    ...listing,
    price: {
      ...price,
      expected_value: price.log_expected_value ? Math.exp(price.log_expected_value) : null,
      actual: getPrice(policy_prices, price.basis),
      is_constrained: policy_prices?.is_constrained,
    },
    price_level_price: listing.price_level_price && {
      ...listing.price_level_price,
      expected_value: (
        listing.price_level_price.log_expected_value
          ? Math.exp(listing.price_level_price.log_expected_value)
          : null
      ),
      actual: getPrice(policy_prices, price.basis),
      is_constrained: policy_prices?.is_constrained,
    },
    override: listing.override ? mapOverride(listing.override) : null,
    created_at: DateTime.fromISO(listing.created_at, { zone: 'utc' }),
    updated_at: DateTime.fromISO(listing.updated_at, { zone: 'utc' }),
  };
};

const getPrice = (policy_prices: ListingPolicyPrices, basis: PricingBasis): number => {
  switch (basis) {
    case PricingBasis.DISPLAY_PRICE:
      return policy_prices?.display_price;
    case PricingBasis.ALL_IN_PRICE:
      return policy_prices?.all_in_price;
    default:
      return undefined;
  }
};

export default mapListing;
