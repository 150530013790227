import styled from 'styled-components';

const FallbackWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 500px;
`;

const LoadingWrapper = styled(FallbackWrapper as any)`
  width: 100%;
`;

export { FallbackWrapper, LoadingWrapper };
