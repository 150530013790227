import { User } from '#/types/User';

const filterUsersBySearch = (users: User[], search: string):
User[] => {
  if (!users || !search || search === '')
    return users;

  return users.filter((user) => {
    const id = user.id.toString().toLowerCase();
    const name = user.fullName().toLowerCase();
    const email = user.email.toLowerCase();
    const type = user.userType.toLowerCase();

    const userText = id + name + email + type;

    if (search.startsWith('!')) {
      const filterSearch = search.substring(1);

      if (filterSearch.includes('active'))
        return (!user.isActive && filterSearch === 'active') || (user.isActive && filterSearch === 'inactive');

      return !userText.includes(filterSearch);
    }

    return userText.includes(search) || (user.isActive && search === 'active') || (!user.isActive && search === 'inactive');
  });
};

export default filterUsersBySearch;
