import {
  useEffect, useState, useContext,
} from 'react';
import { ActiveEventContext } from '../../contexts/ActiveEvent';
import getParameters from '#/api/getParameters';
import formatApiError from '#/api/utils/formatApiError';
import formatOverrides from '#/api/utils/formatOverrides';
import { Settings } from '#/types/Override';

type Hook = () => {
  error?: string;
  loading: boolean;
  initialSettings: Settings;
  parameters: Settings;
};

const useFetchData: Hook = () => {
  const { activeEvent } = useContext(ActiveEventContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [initialSettings, setInitialSettings] = useState<Settings>({});
  const [parameters, setParameters] = useState<Settings>({});
  const id = activeEvent?.id;

  useEffect(() => {
    const fetchParameters = async (): Promise<void> => {
      setLoading(true);
      try {
        setInitialSettings(formatOverrides(activeEvent?.overrides));

        const newParameters = await getParameters(id);

        setParameters(newParameters);
      } catch (err) {
        const errorString = formatApiError(err);

        setError(errorString);
      }

      setLoading(false);
    };

    if (id)
      fetchParameters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    error,
    initialSettings,
    loading,
    parameters,
  };
};

export { Hook };
export default useFetchData;
