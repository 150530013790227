import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Listing from '#/types/Listing';
import { formatCurrency, formatGtv, formatNumber } from '#/shared/formatNumberForDisplay';
import { PRICING_BASIS_OPTIONS } from '#/types/Event';

const PORTFOLIO_VALUE = 'Portfolio Value';
const VALUE = 'Value';

interface CardProps {
  listing: Listing;
}

const PortfolioValueCard: React.FC<CardProps> = ({ listing }) => {
  const { price, portfolio } = listing;
  const { value_per_ticket, sale_ticket_ct } = portfolio;
  const { basis } = price;

  const priceLabel = PRICING_BASIS_OPTIONS.find((option) => option.value === basis)?.label;
  const priceValue = `${formatNumber(sale_ticket_ct, 1)}x • ${formatCurrency(value_per_ticket)}`;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{PORTFOLIO_VALUE}</Name>
        <Value>{formatGtv(value_per_ticket * sale_ticket_ct)}</Value>
      </Element>
      <Element>
        <Name>{`${priceLabel} ${VALUE}`}</Name>
        <Value>{priceValue}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default PortfolioValueCard;
