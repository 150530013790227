import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Ingestion from '#/types/Ingestion';
import Pdf from '#images/Pdf.svg';

const STOCK_TYPE = 'Stock Type';
const ACCOUNT = 'Account Details';
const IN_HAND = 'In Hand';
const NOT_IN_HAND = 'Not In Hand';
const WILL_BE_IN_HAND = 'Will Be In Hand';
const SHOULD_BE_IN_HAND = 'Should Be In Hand';

interface CardProps {
  ingestion: Ingestion;
  setPdfUrl: (url: string) => void;
}

const FulfillmentCard: React.FC<CardProps> = ({ ingestion, setPdfUrl }) => {
  const {
    id, quantity, has_pdf, in_hand, in_hand_date, ticket_account_email,
    ticket_account_platform, ticket_account_external_id, stock_type,
  } = ingestion;
  const stockTypeFormatted = stock_type.split('_').join(' ');
  const pdfUrl = `/proxy/ingestion_listing/${id}/pdf`;
  const onClick = useCallback(() => setPdfUrl(pdfUrl), [pdfUrl, setPdfUrl]);
  let inHandLabel = in_hand ? IN_HAND : NOT_IN_HAND;

  if (in_hand_date && !in_hand) {
    if (DateTime.fromISO(in_hand_date).diffNow().toObject().days < 1)
      inHandLabel = SHOULD_BE_IN_HAND;
    else
      inHandLabel = WILL_BE_IN_HAND;
  }

  let accountLink = null;

  if (ticket_account_external_id) {
    if (ticket_account_platform === 'seatgeek')
      accountLink = `https://rufus.seatgeekadmin.com/users/${ticket_account_external_id}`;
    else if (ticket_account_platform === 'reach iq')
      accountLink = `https://sgoogle.seatgeekadmin.com/u/${ticket_account_external_id}`;
  }

  return (
    <NarrowWrapper>
      <Element>
        <Name>{STOCK_TYPE}</Name>
        {has_pdf && (
          <PdfButton onClick={onClick}>
            <img alt="pdf" height="20px" src={Pdf} />
          </PdfButton>
        )}
        {stockTypeFormatted}
      </Element>
      {(ticket_account_email || ticket_account_platform) && (
        <Element>
          <Name>{ACCOUNT}</Name>
          {ticket_account_email && (
            <Value>
              {accountLink ? (
                <a
                  href={accountLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {ticket_account_email}
                </a>
              ) : ticket_account_email}
            </Value>
          )}
          {ticket_account_platform && <Value>{ticket_account_platform}</Value>}

        </Element>
      )}
      {quantity > 0 && (
        <Element>
          <Name>{inHandLabel}</Name>
          {in_hand_date && !in_hand && (
            <Value>{in_hand_date}</Value>
          )}
        </Element>
      )}
    </NarrowWrapper>
  );
};

const PdfButton = styled.div`
  cursor: pointer;
`;

export default FulfillmentCard;
