import {
  useState, useCallback, useContext, useMemo,
} from 'react';
import postRerunOnSaleModel from '#/api/postRerunOnSaleModel';
import postRerunSelldownModel from '#/api/postRerunSelldownModel';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import { ModelType } from '#/types/Event';

type Hook = () => {
  showConfirmationModal: boolean;
  closeRerunModal: () => void;
  openRerunSelldownModal: () => void;
  openRerunOnsaleModal: () => void;
  rerunModel: () => void;
  message: string;
  error: string;
}

const useRerunModelConfirmationModal: Hook = () => {
  const [error, setError] = useState<string>('');
  const [modelType, setModelType] = useState<ModelType>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { activeEvent } = useContext(ActiveEventContext);
  const eventId = activeEvent?.id;

  const message = useMemo(() => {
    if (modelType === ModelType.SELLDOWN)
      return 'Are you sure you want to rerun the selldown model?';
    if (modelType === ModelType.ONSALE)
      return 'Are you sure you want to rerun the onsale model?';
    return '';
  }, [modelType]);

  const closeRerunModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const openRerunSelldownModal = useCallback(() => {
    setModelType(ModelType.SELLDOWN);
    setShowConfirmationModal(true);
  }, []);

  const openRerunOnsaleModal = useCallback(() => {
    setModelType(ModelType.ONSALE);
    setShowConfirmationModal(true);
  }, []);

  const rerunModel = useCallback(async () => {
    try {
      if (modelType === ModelType.SELLDOWN)
        await postRerunSelldownModel(eventId);
      else if (modelType === ModelType.ONSALE)
        await postRerunOnSaleModel(eventId);

      closeRerunModal();
    } catch (e) {
      setError(`${e}`);
    }
  }, [closeRerunModal, eventId, modelType]);

  return {
    openRerunSelldownModal,
    openRerunOnsaleModal,
    closeRerunModal,
    rerunModel,
    showConfirmationModal,
    message,
    error,
  };
};

export default useRerunModelConfirmationModal;
