import { TimeUnit } from '#/types/Settings';

const TITLE = 'Update Settings';

const SUBMIT = 'Submit';
const CANCEL = 'Cancel';

const MINIMUM = {
  key: 'min',
  label: 'Minimum',
};

const MAXIMUM = {
  key: 'max',
  label: 'Maximum',
};

const SCHEDULER_EXTENT = [1, 60];
const INGESTOR_EXTENT = [300, 21600];
const SUPERVISOR_MIN_EXTENT = [60, 3600];
const SUPERVISOR_MAX_EXTENT = [3600, 86400];
const PROFITS_UPDATER_EXTENT = [15, 300];
const INVENTORY_UPDATER_EXTENT = [43200, 432000];

const SCHEDULER_STEP = 1;
const INGESTOR_STEP = 100;
const SUPERVISOR_MIN_STEP = 20;
const SUPERVISOR_MAX_STEP = 300;
const PROFITS_UPDATER_STEP = 5;
const INVENTORY_UPDATER_STEP = 3600;

interface NumberSlider {
  title?: string;
  extent: [number, number];
  step: number;
  label: string;
  key: string;
}

const NUMBER_SLIDERS: NumberSlider[] = [
  {
    extent: [0.1, 2],
    step: 0.1,
    label: 'Target tickets sold',
    key: 'targetSupervisorIntervalTicketsSold',
  },
  {
    extent: [1.5, 5.0],
    step: 0.25,
    label: 'Sales surge rate threshold',
    key: 'thresholdSalesSurgeRate',
  },
  {
    title: 'Global Price Floors',
    extent: [0.0, 10.0],
    step: 0.5,
    label: 'Min All-In Price',
    key: 'minAllInPrice',
  },
  {
    extent: [0.0, 5.0],
    step: 0.25,
    label: 'Min Display Price',
    key: 'minDisplayPrice',
  },
  {
    extent: [0.0, 5.0],
    step: 0.25,
    label: 'Min Broadcast Price',
    key: 'minBroadcastPrice',
  },
];

const INTERVAL_SLIDERS = [
  {
    schedulerInterval: [
      { ...MINIMUM, extent: SCHEDULER_EXTENT, step: SCHEDULER_STEP },
      { ...MAXIMUM, extent: SCHEDULER_EXTENT, step: SCHEDULER_STEP },
    ],
  },
  {
    profitsUpdaterInterval: [
      { ...MINIMUM, extent: PROFITS_UPDATER_EXTENT, step: PROFITS_UPDATER_STEP },
      { ...MAXIMUM, extent: PROFITS_UPDATER_EXTENT, step: PROFITS_UPDATER_STEP },
    ],
  },
  {
    ingestorInterval: [
      { ...MINIMUM, extent: INGESTOR_EXTENT, step: INGESTOR_STEP },
      { ...MAXIMUM, extent: INGESTOR_EXTENT, step: INGESTOR_STEP },
    ],
  },
  {
    inventoryUpdaterInterval: [
      { ...MINIMUM, extent: INVENTORY_UPDATER_EXTENT, step: INVENTORY_UPDATER_STEP },
      { ...MAXIMUM, extent: INVENTORY_UPDATER_EXTENT, step: INVENTORY_UPDATER_STEP },
    ],
  },
  {
    supervisorInterval: [
      { ...MINIMUM, extent: SUPERVISOR_MIN_EXTENT, step: SUPERVISOR_MIN_STEP },
      { ...MAXIMUM, extent: SUPERVISOR_MAX_EXTENT, step: SUPERVISOR_MAX_STEP },
    ],
  },
];

const TIME_UNIT_OPTIONS = [
  { value: TimeUnit.SECS, label: TimeUnit.SECS },
  { value: TimeUnit.MINS, label: TimeUnit.MINS },
  { value: TimeUnit.HRS, label: TimeUnit.HRS },
  { value: TimeUnit.DAYS, label: TimeUnit.DAYS },
];

export {
  TITLE,
  SUBMIT,
  CANCEL,
  NUMBER_SLIDERS,
  INTERVAL_SLIDERS,
  TIME_UNIT_OPTIONS,
};
