import { DateTime } from 'luxon';
import Ingestion, {
  APIIngestion,
  APIIngestionCsvFile,
  APIIngestionTicket,
  APICandidateEvent,
  APIInputListingError,
  APIInputListingOverride,
  APIOutputListing,
  APITransaction,
  IngestionCsvFile,
  IngestionTicket,
  CandidateEvent,
  InputListingError,
  InputListingOverride,
  OutputListing,
  Transaction,
} from '#/types/Ingestion';
import mapErrorStatus from '#/api/utils/mapErrors';
import formatEventStartsAtString from './formatEventStartsAtString';

const mapIngestionCsvFile = (ingestion: APIIngestionCsvFile): IngestionCsvFile => {
  return {
    ...ingestion,
    created_at: DateTime.fromISO(ingestion.created_at, { zone: 'utc' }),
  };
};

const mapIngestionTicket = (ingestion: APIIngestionTicket): IngestionTicket => {
  return {
    ...ingestion,
    in_hand_at: ingestion.in_hand_at ? DateTime.fromISO(ingestion.in_hand_at, { zone: 'utc' }) : null,
    created_at: DateTime.fromISO(ingestion.created_at, { zone: 'utc' }),
    updated_at: DateTime.fromISO(ingestion.updated_at, { zone: 'utc' }),
    expired_at: ingestion.expired_at ? DateTime.fromISO(ingestion.expired_at, { zone: 'utc' }) : null,
  };
};

const mapCandidateEvent = (event: APICandidateEvent): CandidateEvent => {
  const event_starts_at = DateTime.fromISO(event.event_starts_at, { zone: 'utc' });
  const event_starts_at_lcl = event.event_starts_at_local
    ? DateTime.fromISO(event.event_starts_at_local, { zone: 'utc' })
    : event_starts_at;
  const [
    eventStartDate,
    eventStartTime,
  ] = formatEventStartsAtString(event_starts_at, event_starts_at_lcl);
  const event_starts_at_local = `${eventStartDate} • ${eventStartTime}`;

  return {
    ...event,
    event_starts_at,
    event_starts_at_local,
  };
};

const mapInputListingError = (error: APIInputListingError): InputListingError => {
  return {
    ...error,
    error_status: error.error_status ? mapErrorStatus(error.error_status) : null,
    created_at: DateTime.fromISO(error.created_at, { zone: 'utc' }),
    updated_at: DateTime.fromISO(error.updated_at, { zone: 'utc' }),
  };
};

const mapInputListingOverride = (override: APIInputListingOverride): InputListingOverride => {
  return {
    ...override,
    created_at: DateTime.fromISO(override.created_at, { zone: 'utc' }),
  };
};

const mapOutputListing = (
  listing: APIOutputListing, ingestion_listing_id: number,
): OutputListing => {
  return {
    ...listing,
    override: listing.override ? mapInputListingOverride(listing.override) : null,
    ingestion_listing_id,
    created_at: DateTime.fromISO(listing.created_at, { zone: 'utc' }),
  };
};

const mapTransaction = (transaction: APITransaction): Transaction => {
  return {
    ...transaction,
    created_at: DateTime.fromISO(transaction.created_at, { zone: 'utc' }),
  };
};

const mapIngestion = (ingestion: APIIngestion): Ingestion => {
  const listed_ct = (
    ingestion
      .output_listings
      .map((l) => l.quantity)
      .reduce((a, b) => a + b, 0)
  );
  const sales = (
    ingestion
      .transactions
      .filter((t) => t.state !== 'cancelled' && t.state !== 'failed')
  );
  const sold_ct = sales.map((t) => t.quantity).reduce((a, b) => a + b, 0);
  const sellthrough_rate = sold_ct / ingestion.quantity;

  const tickets = ingestion.tickets;
  const quantity = tickets.filter((t) => t.expired_at === null).length;
  const cost = quantity * ingestion.cost_per_ticket;
  const face_value = ingestion.face_value_per_ticket
    ? quantity * ingestion.face_value_per_ticket
    : null;
  const gtv = sales
    .filter((t) => t.state === 'fulfilled')
    .map((t) => t.quantity * (t.submitted_price_per_ticket || 0))
    .reduce((a, b) => a + b, 0);
  const recoupment_rate = cost > 0 ? (gtv / cost) : null;

  const is_priced = ingestion.output_listings.length > 0 || ingestion.transactions.length > 0;
  const is_sold = quantity > 0 && listed_ct === 0 && sold_ct >= quantity;

  const in_hand = tickets.every((t) => t.in_hand_at !== null);
  const has_pdf = ingestion.tickets.some((t) => t.s3_path);

  const ticket_account_email = ingestion.ticket_account?.email;
  const ticket_account_platform = ingestion.ticket_account?.platform;
  const ticket_account_external_id = ingestion.ticket_account?.external_id;

  return {
    ...ingestion,
    ticket_account_email: ticket_account_email,
    ticket_account_platform: ticket_account_platform,
    ticket_account_external_id: ticket_account_external_id,
    ingestion_csv_file: ingestion.ingestion_csv_file
      ? mapIngestionCsvFile(ingestion.ingestion_csv_file)
      : null,
    tickets: tickets.map(mapIngestionTicket),
    candidate_event: ingestion.candidate_event
      ? mapCandidateEvent(ingestion.candidate_event)
      : null,
    input_listing_errors: ingestion.input_listing_errors.map(mapInputListingError),
    output_listings: ingestion.output_listings.map((o) => mapOutputListing(o, ingestion.id)),
    transactions: ingestion.transactions.map(mapTransaction),
    error_status: ingestion.error_status ? mapErrorStatus(ingestion.error_status) : null,
    in_hand,
    is_priced,
    listed_ct,
    is_sold,
    sold_ct,
    cost,
    face_value,
    gtv,
    has_pdf,
    recoupment_rate,
    sellthrough_rate,
    created_at: DateTime.fromISO(ingestion.created_at, { zone: 'utc' }),
    updated_at: DateTime.fromISO(ingestion.updated_at, { zone: 'utc' }),
  };
};

export default mapIngestion;
