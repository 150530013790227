import {
  useState, useCallback, useContext, useEffect,
} from 'react';
import type { BulkOverrides, Settings } from '#/types/Override';
import type { RollupEvent } from '../utils/getSalesTrendsByEvent';
import { CollectionOverridesContext } from '../contexts';
import redirectUnauthorizedToAuth from '#/api/utils/redirectUnauthorizedToAuth';
import getBulkOverrides from '#/api/getBulkOverrides';

interface Props {
  events: RollupEvent[]
}

interface Hook {
  isLoading: boolean
  hasError: boolean
  existingOverrides: BulkOverrides[]
  existingParameters: Settings[]
}

const useGetExistingOverrides = ({ events }: Props): Hook => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [existingOverrides, setExistingOverrides] = useState<BulkOverrides[]>();
  const [existingParameters, setExistingParameters] = useState<Settings[]>();

  const {
    requestedOverrides, isVisibleRequestedOverridesModal,
  } = useContext(CollectionOverridesContext);

  const getExistingOverrides = useCallback(async () => {
    if (events?.length > 0 && requestedOverrides?.length > 0 && isVisibleRequestedOverridesModal) {
      const eventIds = events.map(({ autobrokerEventId: id }) => id);

      setIsLoading(true);
      try {
        const previousSettingsAndOverrides = await getBulkOverrides(eventIds);

        const previousParameters: Settings[] = [];
        const previousOverrides: BulkOverrides[] = [];

        previousSettingsAndOverrides.events
          .forEach(({ parameters, overrides }) => {
            previousParameters.push(parameters);
            previousOverrides.push({
              autobrokerEventId: overrides.autobrokerEventId,
              parameters: overrides.parameters,
            });
          });

        setExistingOverrides(previousOverrides);
        setExistingParameters(previousParameters);
        setHasError(false);
      } catch (err) {
        redirectUnauthorizedToAuth(err);
        setHasError(true);
      }
      setIsLoading(false);
    }
  }, [events, requestedOverrides, isVisibleRequestedOverridesModal]);

  useEffect(() => {
    getExistingOverrides();
  }, [getExistingOverrides]);

  return {
    isLoading,
    hasError,
    existingOverrides,
    existingParameters,
  };
};

export { Hook };
export default useGetExistingOverrides;
