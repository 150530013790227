import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Event from '#/types/Event';

interface CardProps {
  event: Event;
}

const LISTINGS = 'Constrained Listings';
const TICKETS = 'Constrained Listed Tickets';

const Constrained: React.FC<CardProps> = ({ event }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{LISTINGS}</Name>
        <Value>{event.summary.constrainedListingCt}</Value>
      </Element>
      <Element>
        <Name>{TICKETS}</Name>
        <Value>{event.summary.constrainedListingTicketCt}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default Constrained;
