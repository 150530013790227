import { Rollup, BinMeasure } from '#/types/ReportingRollup';
import { LOG_SCALE_TICKS } from '#/shared/graphique';
import { formatGtv } from '#/shared/formatNumberForDisplay';
import { EXTRA_NARROW_BREAKPOINT } from '#/shared/clientReporting/utils';

const calcBinsBetween = (bin1: Rollup, bin2: Rollup, percDiff = 0.2): number => (
  Math.round(
    (Math.log(bin2.group?.priceLower) - Math.log(bin1.group.priceUpper)) / percDiff,
  )
);

const calcBinEnd = (start: number): number => (
  Math.round(Math.E ** (Math.log(start) + 0.2) * 100) / 100
);

// this is useful for expanding the set of bins in the returned price-grouped rollup
// i.e. turning implicitly missing bins into explicitly missing (or 0 count) bins
const expandMissingBins = (bins: Rollup[], measure: BinMeasure, replacement: any): Rollup[] => {
  bins.forEach((bin, i) => {
    if (i < bins.length - 1) {
      const nextIndex = i + 1;
      const binsBetween = calcBinsBetween(bin, bins[nextIndex]);

      if (binsBetween) {
        const { priceUpper: nextBinPriceLower } = bin.group;
        const nextBin: Rollup = {
          group: {
            priceLower: nextBinPriceLower,
            priceUpper: calcBinEnd(nextBinPriceLower),
          },
          aggregate: {
            [measure]: replacement,
          },
        };

        bins.splice(nextIndex, 0, nextBin);
      }
      if (binsBetween > 0)
        expandMissingBins(bins, measure, replacement);
    }
  });
  return bins;
};

const trimBinData = (bins: Rollup[], measure: BinMeasure): Rollup[] => {
  const firstNonZeroBinIndex = bins.findIndex((bin) => bin?.aggregate?.[measure] !== 0);

  return bins?.slice(firstNonZeroBinIndex)
    .filter((bin) => bin?.group?.priceLower > 0);
};

const formatBinData = (bins: Rollup[], measure: BinMeasure, replacement: any = 0): Rollup[] => (
  expandMissingBins(trimBinData([...bins], measure), measure, replacement)
);

const FILL_COLOR = '#587A8D';
const STROKE_COLOR = '#fafafa';

const shouldShowTick = (
  value: number, range: number, threshold: number, tickValue: number,
): boolean => (
  range <= threshold && value % tickValue === 0
);

const formatXTicks = (value: number, range: number, width: number): string => {
  const isNarrow = width <= EXTRA_NARROW_BREAKPOINT;
  const narrowFactor = isNarrow ? 2.5 : 1;
  const shouldShow = shouldShowTick(value, range, 100, 5 * narrowFactor)
    || shouldShowTick(value, range, 300, 20 * narrowFactor)
    || shouldShowTick(value, range, 600, 100 * narrowFactor);

  if (shouldShow)
    return formatGtv(value, false);

  return (
    LOG_SCALE_TICKS.map((v) => v * narrowFactor).includes(Math.abs(value))
      ? formatGtv(value, false)
      : ''
  );
};

export {
  formatBinData,
  FILL_COLOR,
  STROKE_COLOR,
  formatXTicks,
};
