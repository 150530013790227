import React, { useMemo, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PriceDistributionGraphInfo, {
  PriceDistributionListings,
} from '#/types/Preview';
import useFilterListingsAndTransactions from './hooks/useFilterListingsAndTransactions';
import useYScaleChange from './hooks/useYScaleChange';
import { getSelectedOptions } from './utils';
import Graph from './Graph';
import SelectInput from './SelectInput';
import PricingChartCheckbox from '../sharedGraphComponents/PricingChartCheckbox';
import Loading from '../sharedGraphComponents/Loading';
import Error from '../sharedGraphComponents/Error';
import DropdownFormOption from './types';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import useFetchMarketplaceTransactions from '#/pages/useFetchMarketplaceTransactions';
import useViewSelection from './hooks/useViewSelection';

interface PriceDistributionGraphProps {
  distributionOverlay: PriceDistributionGraphInfo;
  listingsLoading: boolean;
  overlayError: string;
  previewError: string;
  previewLoading: boolean;
  priceDistributionGraphInfo: PriceDistributionGraphInfo;
  priceDistributionListings: PriceDistributionListings;
  listingMarketplaces: string[];
  showGraph: boolean;
}

const PriceDistributionGraph: React.FC<PriceDistributionGraphProps> = ({
  distributionOverlay,
  listingsLoading,
  overlayError,
  previewError,
  previewLoading,
  priceDistributionGraphInfo,
  priceDistributionListings,
  listingMarketplaces,
  showGraph,
}) => {
  const okToRender = showGraph && !previewError && !previewLoading;
  const { activeEvent } = useContext(ActiveEventContext);

  const {
    marketplaceTransactions,
    isLoading: marketplaceTransactionsLoading,
  } = useFetchMarketplaceTransactions(activeEvent?.id);

  const {
    inventorySourceTypeOptions,
    quantityOptions,
    sellerIdOptions,
    filteredListings,
    handleInventoryTypeFilter,
    handleQuantityFilter,
    handleSellerIdFilter,
    resetFilters,
    inventorySourceTypes,
    quantities,
    sellerIds,
  } = useFilterListingsAndTransactions(priceDistributionListings, marketplaceTransactions);

  const { isPrice, handleYScaleChange } = useYScaleChange();
  const { viewSelection, handleViewSelection } = useViewSelection();

  const selectedOptions = useMemo(() => {
    return ({
      inventorySourceTypes: getSelectedOptions(inventorySourceTypeOptions, inventorySourceTypes),
      quantities: getSelectedOptions(quantityOptions, quantities),
      sellerIds: getSelectedOptions(sellerIdOptions, sellerIds)
        .map((v) => ({ ...v, label: v.value })) as DropdownFormOption[],
    });
  }, [
    inventorySourceTypes,
    inventorySourceTypeOptions,
    quantities,
    quantityOptions,
    sellerIds,
    sellerIdOptions,
  ]);

  const filteredMarketplaceTransactions = useMemo(() => {
    let filtered = quantities?.length > 0
      ? marketplaceTransactions.filter((mt) => quantities.includes(mt.quantity))
      : marketplaceTransactions;

    filtered = inventorySourceTypes?.length > 0
      ? filtered.filter((mt) => inventorySourceTypes.includes(mt.inventorySourceType))
      : filtered;

    filtered = sellerIds?.length > 0
      ? filtered.filter((mt) => sellerIds.includes(mt.sellerId))
      : filtered;
    return filtered;
  }, [marketplaceTransactions, quantities, inventorySourceTypes, sellerIds]);

  useEffect(() => {
    if (previewLoading || listingsLoading)
      resetFilters();
  }, [previewLoading, listingsLoading, resetFilters]);

  return (
    <>
      {okToRender && (
        <>
          <FilterWrapper>
            <SelectInput
              isMulti
              label='Inventory type'
              onChange={handleInventoryTypeFilter}
              options={inventorySourceTypeOptions}
              placeholder='Filter inventory types'
              value={selectedOptions.inventorySourceTypes}
            />
            <SelectInput
              isMulti
              label='Quantity'
              onChange={handleQuantityFilter}
              options={quantityOptions}
              placeholder='Filter quantities'
              value={selectedOptions.quantities}
            />
            <SelectInput
              isMulti
              label='Sellers'
              onChange={handleSellerIdFilter}
              options={sellerIdOptions}
              placeholder='Filter seller IDs'
              value={selectedOptions.sellerIds}
            />
            <PricingChartCheckbox
              checked={isPrice}
              label='Show as price'
              onChange={handleYScaleChange}
            />
          </FilterWrapper>
          <Spacer />
          <Graph
            activeEvent={activeEvent}
            distributionOverlay={distributionOverlay}
            handleViewSelection={handleViewSelection}
            isPrice={isPrice}
            listingMarketplaces={listingMarketplaces}
            loading={listingsLoading || marketplaceTransactionsLoading}
            marketplaceTransactions={filteredMarketplaceTransactions}
            overlayError={overlayError}
            priceDistributionGraphInfo={priceDistributionGraphInfo}
            priceDistributionListings={filteredListings}
            viewSelection={viewSelection}
          />
        </>
      )}
      <Loading loading={previewLoading} showGraph={showGraph} />
      <Error
        error={previewError}
        overlayError={overlayError}
        showGraph={showGraph}
      />
    </>
  );
};

const FilterWrapper = styled.div`
  position: absolute;
  top: 7.5em;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const Spacer = styled.div`
  height: 4em;
`;

export default PriceDistributionGraph;
