import React from 'react';
import styled from 'styled-components';
import { FetchEventsHook } from '#/types/Event';
import PageWrapper from '#/shared/PageWrapper';
import Header from '#/shared/Header';
import EventTable from './EventTable';
import Filters from './Filters';
import { FetchGroupsHook } from '#/pages/useFetchGroups';

interface SearchPageProps {
  eventsHook: FetchEventsHook;
  groupsHook: FetchGroupsHook;
}

const SearchPage: React.FC<SearchPageProps> = ({ eventsHook, groupsHook }) => {
  return (
    <PageWrapper>
      <Container>
        <Header
          toggleOpen={null}
          user={eventsHook.user}
        />
        <FilterWrapper>
          <Filters
            filters={eventsHook.filters}
            groupsHook={groupsHook}
            setFilters={eventsHook.setFilters}
          />
        </FilterWrapper>
      </Container>
      <ScrollBody>
        <TableWrapper>
          <EventTable
            eventsHook={eventsHook}
            groupsHook={groupsHook}
          />
        </TableWrapper>
      </ScrollBody>
    </PageWrapper>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 2;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.info};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: padding 0.75s ease;
`;

const ScrollBody = styled.div`
  position: absolute;
  width: 100%;
  top: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FilterWrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

const TableWrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
`;

export default SearchPage;
