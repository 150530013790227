import React from 'react';
import CancelButton from './CancelButton';
import SaveButton from './SaveButton';
import DeleteButton from './DeleteButton';
import { ButtonsWrapper } from '#/shared/modalComponents';

interface ButtonsProps {
  disabled: boolean;
  onCancel?: () => void;
  onSave: () => Promise<void>;
  onDelete?: () => Promise<void>;
}

const ModalButtons: React.FC<ButtonsProps> = ({
  disabled,
  onCancel,
  onSave,
  onDelete,
}) => {
  return (
    <>
      <ButtonsWrapper>
        <SaveButton disabled={disabled} handleSave={onSave} />
        <CancelButton handleCancel={onCancel} />
        {onDelete && <DeleteButton handleDelete={onDelete} />}
      </ButtonsWrapper>
    </>
  );
};

export default ModalButtons;
