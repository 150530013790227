import { ToRelativeOptions } from 'luxon';
import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { EventErrorType } from '#/types/Errors';
import Event from '#/types/Event';
import { GreenEllipse, YellowEllipse, RedEllipse } from '#/shared/Ellipse';

interface CardProps {
  event: Event;
}

const SELLDOWN_MODEL_ERROR = 'Selldown Proj. Model';
const ONSALE_PRICING_MODEL = 'Onsale Pricing Model';
const SALES_PERIOD_MODEL = 'Sales Period Model';
const EVENT_MODEL_STATUS = 'Event Model Status';
const dt = { style: 'short' } as ToRelativeOptions;

const PendingModelCard: React.FC<CardProps> = ({ event }: CardProps) => {
  // Error checks
  /* eslint-disable max-len */
  const onsaleError = event.eventErrors.find((e) => e.errorType === EventErrorType.ONSALE_MODEL_ERROR);
  const selldownError = event.eventErrors.find((e) => e.errorType === EventErrorType.SELLDOWN_MODEL_ERROR);
  const salesPeriodError = event.eventErrors.find((e) => e.errorType === EventErrorType.SALES_PERIOD_MODEL_ERROR);
  /* eslint-enable max-len */

  // Status determination
  const { modelStatus } = event.config;
  const hasErrors = !!onsaleError || !!selldownError || !!salesPeriodError;

  let StatusComponent;
  let statusText;

  if (hasErrors) {
    StatusComponent = RedEllipse;
    statusText = 'Error detected';
  } else if (modelStatus === 'pending') {
    StatusComponent = YellowEllipse;
    statusText = 'One or more models updating';
  } else {
    StatusComponent = GreenEllipse;
    statusText = 'All models current';
  }
  return (
    <NarrowWrapper>
      {hasErrors ? (
        <>
          {selldownError && (
            <Element>
              <Name>
                <RedEllipse />
                {SELLDOWN_MODEL_ERROR}
              </Name>
              <Value>
                {`error: ${selldownError.createdAt.toRelative(dt)}`}
              </Value>
            </Element>
          )}
          {onsaleError && (
            <Element>
              <Name>
                <RedEllipse />
                {ONSALE_PRICING_MODEL}
              </Name>
              <Value>
                {`error: ${onsaleError.createdAt.toRelative(dt)}`}
              </Value>
            </Element>
          )}
          {salesPeriodError && (
            <Element>
              <Name>
                <RedEllipse />
                {SALES_PERIOD_MODEL}
              </Name>
              <Value>
                {`error: ${salesPeriodError.createdAt.toRelative(dt)}`}
              </Value>
            </Element>
          )}
        </>
      ) : (
        <Element>
          <Name>
            <StatusComponent />
            {EVENT_MODEL_STATUS}
          </Name>
          <Value>{statusText}</Value>
        </Element>
      )}
    </NarrowWrapper>
  );
};

export default PendingModelCard;
