import React from 'react';
import { User } from '#/types/User';
import { Option } from '#/pages/useFetchStakeholders';
import DashboardPage from './DashboardPage';
import ErrorPage from './ErrorPage';

type Stakeholder = Option<string>;

const renderDashboard = (
  user: User, stakeholder: Stakeholder, stakeholderError?: string,
): React.ReactNode => {
  return (
    stakeholder
      ? (
        <DashboardPage
          stakeholder={stakeholder}
          user={user}
        />
      )
      : (
        <ErrorPage
          errorStatus={stakeholderError}
          user={user}
        />
      )
  );
};

export default renderDashboard;
