import { useState, useCallback, useEffect } from 'react';
import client from '#/api/_clientFormatted';
import type { MapResponse, MapGeometry } from '#/types/MapRollup';
import { Environment, getClientEnvironment } from '#/shared/environmentUtils';

const getMap = async (
  { mapConfigId }: { mapConfigId: number },
): Promise<MapResponse> => {
  const mapsUrl = getClientEnvironment() === Environment.PRODUCTION
    ? 'https://maps.seatgeek.com'
    : 'https://maps.tixcast.com';

  const mapResponse = await client.get(`${mapsUrl}/maps_json/${mapConfigId}.json?points=1`);

  return mapResponse?.data;
};

interface MapHook {
  isLoading?: boolean;
  hasError?: boolean;
  mapData?: MapGeometry;
  fetchMap: (id: number) => Promise<void>;
}

const useFetchMap = ({ mapConfigId }: { mapConfigId: number }): MapHook => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [mapData, setMapData] = useState<MapGeometry>();

  const fetchMap = useCallback(async (id: number) => {
    try {
      const mapDataResponse = await getMap({ mapConfigId: id });

      if (mapDataResponse.meta.status === 200) {
        const {
          center, minzoom: minZoom, maxzoom: maxZoom, sections,
        } = mapDataResponse;
        const mapGeometryData = {
          center,
          minZoom,
          maxZoom,
          sections,
        };

        setMapData(mapGeometryData);
      } else {
        setMapData(undefined);
        setHasError(true);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setHasError(true);
    }
  }, []);

  useEffect(() => {
    if (mapConfigId)
      fetchMap(mapConfigId);

    setIsLoading(false);
  }, [fetchMap, mapConfigId]);

  return {
    isLoading,
    hasError,
    mapData,
    fetchMap,
  };
};

export default useFetchMap;
