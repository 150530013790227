import { extent, min, max } from 'd3-array';
import { PRICE_RATIO_GRAPH_KEYS } from '../../constants';
import { PriceRatioGraphData, Graphlabels } from '#/types/Snapshot';
import { FormattedPricingChartDatum } from '../../types';
import { SG_SALE } from '#/api/utils/formatMarketplaceTransaction';

const palette = ['#1C71EF', '#F3685E', '#11A669', '#444444', '#A20870'];
const identityFormat = (v: number): string => (
  v.toLocaleString(undefined, { maximumFractionDigits: 1 })
);

interface GroupLabels {
  id: string;
  label: Graphlabels;
}

const AB: GroupLabels = {
  id: 'actualAB',
  label: Graphlabels.actualABLogPriceRatio,
};
const CP: GroupLabels = {
  id: 'actualCompetitor',
  label: Graphlabels.actualCompetitorLogPriceRatio,
};
const MODEL: GroupLabels = {
  id: 'model',
  label: Graphlabels.modelLogPriceRatio,
};
const TARGET = {
  id: 'target',
  label: Graphlabels.targetLogPriceRatio,
};
const SG = {
  label: SG_SALE,
};

const priceRatioKinds = {
  AB,
  CP,
  MODEL,
  TARGET,
  SG,
};

const groups: string[] = Array.from(new Set(PRICE_RATIO_GRAPH_KEYS.map((t) => Graphlabels[t])));

const isPercentile = (k: string): boolean => k.toLowerCase().includes('percentile');

const formatData = (data: PriceRatioGraphData): FormattedPricingChartDatum[] => {
  const keys = Object.keys(data) as (keyof PriceRatioGraphData)[];

  const formatted = keys.flatMap((k) => {
    const isAB = k.includes(AB.id);
    const isComp = k.includes(CP.id);
    const isModel = k.includes(MODEL.id);
    const isTarget = k.includes(TARGET.id);

    let group: Graphlabels;

    if (isAB)
      group = AB.label;
    else if (isComp)
      group = CP.label;
    else if (isModel)
      group = MODEL.label;
    else if (isTarget)
      group = TARGET.label;

    return data[k].map((d) => {
      return ({
        logHoursToEvent: d.x,
        priceBand: [{ logPriceRatioMin: d.y0, logPriceRatioMax: d.y }],
        logPriceRatio: isPercentile(k) ? undefined : d.y,
        quantity: d.quantity,
        group,
        measure: k,
        key: `${k}-${group}-${d.x}-${d.y}`,
      } as FormattedPricingChartDatum);
    });
  });

  return formatted;
};

const getSharedYExtent = (data: FormattedPricingChartDatum[]): [number, number] => {
  const yExtent = extent(data, (d) => d.logPriceRatio);
  const y0Extent = extent(data, (d) => d.priceBand?.[0].logPriceRatioMin);
  const y1Extent = extent(data, (d) => d.priceBand?.[0].logPriceRatioMax);

  // calculate a y range, truncated at the upper bound
  // (for a friendly y scale range)
  const minVal = min([...yExtent, ...y0Extent, ...y1Extent]);
  const maxVal = min(
    [max(yExtent) + 1, max([...yExtent, ...y0Extent, ...y1Extent])],
  );

  return [minVal, maxVal];
};

export {
  palette,
  groups,
  formatData,
  getSharedYExtent,
  priceRatioKinds,
  identityFormat,
};
