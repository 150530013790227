import React from 'react';
import PageWrapper from '#/shared/PageWrapper';
import Page from '#/shared/Page';
import UnauthenticatedHeader from '#/shared/UnauthenticatedHeader';
import ScrollBody from '#/shared/ScrollBody';

interface ErrorMessagePageProps {
  message: string;
}

const ErrorMessagePage: React.FC<ErrorMessagePageProps> = ({ message }) => {
  return (
    <PageWrapper>
      <Page>
        <UnauthenticatedHeader />
        <ScrollBody>
          { message }
        </ScrollBody>
      </Page>
    </PageWrapper>
  );
};

export default ErrorMessagePage;
