import client from './_clientFormatted';
import Group from '#/types/Group';

const getGroup = async (name: string): Promise<Group> => {
  const response = await client.get(`/proxy/group/${name}`);

  return response?.data;
};

export default getGroup;
