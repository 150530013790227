import React from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { OVERRIDES_MENU_WIDTH_PX, VIEWPORT_SMALL_PX, VIEWPORT_MOBILE_PX } from '../constants';

interface LayoutContextValue {
  isMobile?: boolean;
  isWide?: boolean;
  isWideWithOverrides?: boolean;
}

const CollectionLayoutContext = React.createContext<LayoutContextValue>(null);

const CollectionLayoutProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < VIEWPORT_MOBILE_PX;
  const isWide = windowWidth > VIEWPORT_SMALL_PX;
  const isWideWithOverrides = windowWidth > (VIEWPORT_SMALL_PX + OVERRIDES_MENU_WIDTH_PX);

  const value: LayoutContextValue = {
    isMobile,
    isWide,
    isWideWithOverrides,
  };

  return (
    <CollectionLayoutContext.Provider value={value}>
      {children}
    </CollectionLayoutContext.Provider>
  );
};

export {
  CollectionLayoutContext,
  CollectionLayoutProvider,
  type LayoutContextValue,
};
