import React, {
  useCallback, useState, useEffect, useMemo, useContext,
} from 'react';
import Ingestion, {
  IngestionFilters, INGESTION_STATES, Uploader, IngestionState,
} from '#/types/Ingestion';
import { type GenericOption } from '#/types/GenericOption';
import TableWrapper, {
  Cell, RowWrapper, SmallHeader,
} from '#/shared/TableComponents';
import {
  CONDITIONS,
  getCondition,
  applyConditionFilters,
  Condition,
} from '#/pages/IngestionsPage/Filters';
import {
  Form,
  FilterSelect,
  getOption,
  SmallInput,
  Checkbox,
} from '#/shared/Filters';
import Button from '#/shared/Button';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import useFetchStakeholders from '#/pages/useFetchStakeholders';
import useFetchDeals, { type Option as DealOption } from '#/pages/useFetchDeals';

const SEARCH = 'Search';
const COLUMNS = [
  'Section',
  'Row',
  'Stakeholder',
  'Deal',
  'Deal Term',
  'Uploader',
  'State',
  'Condition',
  'Pin',
  'Per Page',
  '',
];

interface FilterProps {
  filters: IngestionFilters;
  setFilters: (filters: IngestionFilters) => void;
  pinnedIngestions: Ingestion[];
  setPinnedIngestions: (ingestions: Ingestion[]) => void;
}

const Filters: React.FC<FilterProps> = ({
  filters,
  setFilters,
  pinnedIngestions,
  setPinnedIngestions,
}) => {
  const [input, setInput] = useState<IngestionFilters>(filters);
  const { activeEvent } = useContext(ActiveEventContext);
  const autobrokerEventId = useMemo(() => activeEvent?.id, [activeEvent]);
  const {
    dealOptions, selectedDeal, setSelectedDeal, dealTermOptions,
  } = useFetchDeals({ stakeholder: input.stakeholder, autobrokerEventId: autobrokerEventId });
  const { stakeholderLabels } = useFetchStakeholders(autobrokerEventId);
  const stateOptions = INGESTION_STATES.map((state) => ({ value: state, label: state }));
  const conditionOptions = CONDITIONS.map((condition) => ({ value: condition, label: condition }));
  const uploaderOptions = Object.values(Uploader).map(
    (uploader) => ({ label: uploader, value: uploader }),
  );

  const setSection = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const section = event?.target?.value;

    setInput((i) => ({ ...i, section }));
  }, [setInput]);
  const setRow = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const row = event?.target?.value;

    setInput((i) => ({ ...i, row }));
  }, [setInput]);

  const setStakeholder = useCallback((event: GenericOption<string, string>) => {
    const stakeholder = event?.value || null;

    setSelectedDeal(null);
    setInput((i) => ({
      ...i, stakeholder, deal: undefined, deal_term: undefined,
    }));
  }, [setInput, setSelectedDeal]);

  const setState = useCallback((event: GenericOption<IngestionState, IngestionState>) => {
    const state = event?.value || null;

    setInput((i) => ({ ...i, state }));
  }, [setInput]);

  const setCondition = useCallback((event: GenericOption<Condition, Condition>) => {
    const condition = event?.value || null;

    setInput((i) => applyConditionFilters(i, condition));
  }, [setInput]);

  const setDeal = useCallback((event: DealOption<string>) => {
    const deal = event?.value || null;

    setSelectedDeal(deal);
    setInput((i) => ({ ...i, deal, deal_term: undefined }));
  }, [setSelectedDeal, setInput]);

  const setDealTerm = useCallback((event: DealOption<string>) => {
    const deal_term = event?.value || null;

    setInput((i) => ({ ...i, deal_term }));
  }, [setInput]);

  const setUploader = useCallback((event: GenericOption<Uploader, Uploader>) => {
    const uploader = event?.value || null;

    setInput((i) => ({ ...i, uploader }));
  },
  [setInput]);

  const setPinned = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const is_pinned = !!event?.target?.checked;

    setInput((i) => ({ ...i, is_pinned }));
  }, [setInput]);

  const setPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const per_page = Number(event?.target?.value) || null;

    setInput((i) => ({ ...i, per_page }));
  }, [setInput]);

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    setPinnedIngestions(pinnedIngestions);
    setFilters({
      ...input, sort_by: filters.sort_by, sort_asc: filters.sort_asc,
    });
  }, [filters.sort_asc, filters.sort_by, input, setFilters, setPinnedIngestions, pinnedIngestions]);

  useEffect(() => {
    if (filters.autobroker_event_id)
      setInput(filters);
  }, [filters, filters.autobroker_event_id]);

  const hideDealFilter = useMemo(() => {
    return !input.stakeholder || dealOptions?.length === 0;
  }, [input.stakeholder, dealOptions]);

  const hideDealTermFilter = useMemo(() => !selectedDeal, [selectedDeal]);

  const hideColumn = useCallback((column: string) => {
    switch (column) {
      case 'Deal':
        return hideDealFilter;
      case 'Deal Term':
        return hideDealTermFilter;
      default:
        return false;
    }
  }, [hideDealFilter, hideDealTermFilter]);

  return (
    <Form onSubmit={handleSubmit}>
      <TableWrapper>
        <thead>
          <SmallHeader>
            {COLUMNS.map((column) => (
              <Cell hidden={hideColumn(column)} key={column}>{column}</Cell>
            ))}
          </SmallHeader>
        </thead>
        <tbody>
          <RowWrapper>
            <Cell>
              <SmallInput
                defaultValue={filters.section}
                onChange={setSection}
                type='text'
              />
            </Cell>
            <Cell>
              <SmallInput
                defaultValue={filters.row}
                onChange={setRow}
                type='text'
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.stakeholder, stakeholderLabels)}
                onChange={setStakeholder}
                options={stakeholderLabels}
                width="9rem"
              />
            </Cell>
            {!hideDealFilter
              && (
                <Cell>
                  <FilterSelect
                    defaultValue={getOption(filters.deal, dealOptions)}
                    onChange={setDeal}
                    options={dealOptions}
                    width="9rem"
                  />
                </Cell>
              )}
            {!hideDealTermFilter
              && (
                <Cell>
                  <FilterSelect
                    defaultValue={getOption(filters.deal_term, dealTermOptions)}
                    onChange={setDealTerm}
                    options={dealTermOptions}
                    width="9rem"
                  />
                </Cell>
              )}
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.uploader, uploaderOptions)}
                onChange={setUploader}
                options={uploaderOptions}
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.state, stateOptions)}
                onChange={setState}
                options={stateOptions}
                width="9rem"
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(getCondition(filters), conditionOptions)}
                onChange={setCondition}
                options={conditionOptions}
                width="9.5rem"
              />
            </Cell>
            <Cell>
              <Checkbox
                defaultChecked={filters.is_pinned}
                onChange={setPinned}
                type='checkbox'
              />
            </Cell>
            <Cell>
              <SmallInput
                defaultValue={filters.per_page}
                onChange={setPerPage}
                type='number'
              />
            </Cell>
            <Cell>
              <Button type='submit'>{SEARCH}</Button>
            </Cell>
          </RowWrapper>
        </tbody>
      </TableWrapper>
    </Form>
  );
};

export default Filters;
