import { useState, useEffect } from 'react';
import getSinks from '#/api/getSinks';
import Sink from '#/types/Sink';
import formatApiError from '#/api/utils/formatApiError';

interface SinkLabel {
  id: number;
  label: string;
  value: string;
}

interface SinkData {
  sinkLabels: SinkLabel[];
  sinks: Sink[];
  sinksError: string;
}

const formatSinkLabels = (sinks: Sink[]): SinkLabel[] => {
  const SLICE_IDX = 1;
  const options = sinks.map((sink) => {
    const { name } = sink;
    const formatted = name[0].toUpperCase() + name.slice(SLICE_IDX);

    return {
      id: sink.id,
      label: formatted,
      value: name,
    };
  });

  return options;
};

const useFetchSinks = (): SinkData => {
  const [sinks, setSinks] = useState<Sink[]>([]);
  const [sinkLabels, setSinkLabels] = useState<SinkLabel[]>([]);
  const [sinksError, setSinksError] = useState<string>('');

  useEffect(() => {
    const fetchSinks = async (): Promise<void> => {
      try {
        const sinksData = await getSinks();

        setSinks(sinksData);

        const labels = formatSinkLabels(sinksData);
        // Sinks endpoint includes an entity that is being phased out
        const currentLabels = labels.filter((el) => el.value !== 'stagefront');

        setSinkLabels(currentLabels);
      } catch (err) {
        const errorString = formatApiError(err);

        setSinksError(errorString);
      }
    };

    fetchSinks();
  }, []);

  return {
    sinkLabels,
    sinks,
    sinksError,
  };
};

export { SinkLabel };
export default useFetchSinks;
