import { useCallback, useReducer } from 'react';

import Action, { SinkConfig } from '../types';
import RESET from '../constants';

type Reducer = (previousState: SinkConfig, action: Action) => SinkConfig;

const reducer: Reducer = (previousState, action) => {
  const { type, value } = action;

  if (type === RESET)
    return value as SinkConfig;

  const state = { ...previousState, [type]: value } as SinkConfig;

  return state;
};

type Hook = (
  initalConfig: SinkConfig
) => {
  resetSinkConfig: () => void;
  sinkConfig: SinkConfig;
  updateSinkConfig: React.Dispatch<Action>;
};

const useSink: Hook = (initialConfig) => {
  const [sinkConfig, updateSinkConfig] = useReducer<Reducer>(
    reducer,
    initialConfig,
  );

  const resetSinkConfig = useCallback(() => {
    updateSinkConfig({ type: RESET, value: initialConfig });
  }, [initialConfig]);

  return {
    resetSinkConfig,
    sinkConfig,
    updateSinkConfig,
  };
};

export default useSink;
