import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Cell } from '#/shared/TableComponents';
import { SourceEvent } from '#/types/Event';

interface LinkButtonProps {
  disabled: boolean;
  sourceData: SourceEvent;
  openModal: (source: SourceEvent) => void;
}

const LinkButton: React.FC<LinkButtonProps> = ({ disabled, openModal, sourceData }) => {
  const { expiredAt } = sourceData;
  const linkText = useMemo(() => (expiredAt ? 'Link' : 'Unlink'), [expiredAt]);
  const handleClick = useCallback(() => {
    if (disabled)
      return; openModal(sourceData);
  }, [disabled, openModal, sourceData]);

  return (
    <Cell>
      <CardButton onClick={handleClick}>{linkText}</CardButton>
    </Cell>
  );
};

const CardButton = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.text3};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.highlight};
  margin-bottom: 0.375rem;
  cursor: pointer;
`;

export default LinkButton;
