import React, {
  useMemo, useState, useCallback, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import useFetchReportingRollup, { type RollupProps } from '../useFetchReportingRollup';
import getSalesTrendsByEvent, { type EventTimeSeriesSalesSummary } from './utils/getSalesTrendsByEvent';
import RollupErrorComponent from '../clientReporting/DashboardPage/components/RollupErrorComponent';
import { PREVIEWABLE_METRICS, TimeMeasurement } from './utils/menuOptions';
import OverlaidSalesChart from './OverlaidSalesChart';
import EventCollectionList from './EventCollectionList';
import { MainTitle } from '#/shared/clientReporting/typography';
import {
  PAGE_TITLE, SIDEBAR_WIDTH_PX, TOP_OFFSET_PX, VIEWPORT_SMALL_PX,
  DEFAULT_TIME_INTERVAL, DEFAULT_LOG_HOURS_TO_EVENT_INTERVAL,
  OVERRIDES_MENU_WIDTH_PX, toastStyles, ToastId, ToastMsg, Tab,
} from './constants';
import { Tabs, ParameterOverridesMenu } from './Controls';
import {
  CollectionLayoutContext, CollectionEventsContext, CollectionOverridesContext,
  CollectionSalesDataContext, CollectionSelectionsContext,
} from './contexts';
import { FetchGroupsHook, GroupOption } from '#/pages/useFetchGroups';
import useCollectionSalesPreview from './hooks/useCollectionSalesPreview';
import type Group from '#/types/Group';
import type { RollupOverrideParams, RollupGroups } from '#/types/ReportingRollup';
import { DateTime } from 'luxon';
import RequestedOverridesModal from './RequestedOverridesModal';
import { Feature, User } from '#/types/User';
import toast, { Toaster } from 'react-hot-toast';
import useSubmitBulkOverrides from './hooks/useSubmitBulkOverrides';
import { Item } from 'react-stately';
import TimeHeatmap from './TimeHeatmap';
import MapRollup from './MapRollup';
import { FallbackWrapper } from './PageComponents';

interface Props {
  autobrokerEventIds: string[]
  group?: string
  bypassCache?: boolean
  groupsHook: FetchGroupsHook
  selectedGroup?: Group
  totalEventCt?: number
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  user: User
}

const EventCollectionRollup: React.FC<Props> = ({
  autobrokerEventIds, group, bypassCache, groupsHook, selectedGroup,
  totalEventCt, page, setPage, user,
}) => {
  const {
    selectedEventIds,
    setCollectionEventIds,
    setFutureEventIds,
    selectedFutureEventIds,
    setListFocusedEventId,
  } = useContext(CollectionEventsContext);
  const { isMobile, isWideWithOverrides } = useContext(CollectionLayoutContext);
  const {
    isLoadingOverridesSubmission, overridesSubmission,
  } = useContext(CollectionOverridesContext);
  const { hasOverlaidSalesTarget, setEventSalesTrends } = useContext(CollectionSalesDataContext);

  const {
    setSelectedGroup, activeTab, setActiveTab, metricSelection,
    timeGroup, hasDealTerm, setAutobrokerEventIds, isUnmanifestedFilter, setBypassCache,
  } = useContext(CollectionSelectionsContext);

  useEffect(() => {
    setSelectedGroup(selectedGroup);
  }, [selectedGroup, setSelectedGroup]);

  useEffect(() => {
    setAutobrokerEventIds(autobrokerEventIds);
  }, [autobrokerEventIds, setAutobrokerEventIds]);

  useEffect(() => {
    setBypassCache(bypassCache);
  }, [bypassCache, setBypassCache]);

  const isPreviewable = useMemo(() => (
    PREVIEWABLE_METRICS.includes(metricSelection.key)
  ), [metricSelection.key]);

  const isTrendsActive = activeTab === Tab.TRENDS;
  const overridesVisible = isPreviewable && hasOverlaidSalesTarget
    && !user.isReadOnly && isWideWithOverrides && isTrendsActive;

  const navigate = useNavigate();
  const { groupLabels, groupLoading } = groupsHook;

  const isTimeToEvent = timeGroup.key === TimeMeasurement.TIME_TO_EVENT;

  const relativeDateRollupParams: RollupProps = {
    group: ['autobroker_event', 'map_config_id', TimeMeasurement.TIME_TO_EVENT],
    autobrokerEventId: autobrokerEventIds,
    autobrokerEventGroup: group,
    logInterval: DEFAULT_LOG_HOURS_TO_EVENT_INTERVAL,
    hasDealTerm: hasDealTerm || undefined,
    isUnmanifested: isUnmanifestedFilter ?? undefined,
    bypassCache,
    includePortfolioValue: true,
  };

  const calendarRollupParams: RollupProps = {
    ...relativeDateRollupParams,
    group: ['autobroker_event', 'map_config_id', TimeMeasurement.CALENDAR],
    timeInterval: DEFAULT_TIME_INTERVAL,
    logInterval: undefined,
  };

  const {
    rollup: calendarRollup,
    hasError: hasAbsoluteDateRollupError,
    isLoading: isLoadingAbsoluteDateRollup,
    fetchRollup: refreshCalendarRollup,
  } = useFetchReportingRollup(calendarRollupParams);
  const {
    rollupPreview: calendarRollupPreview,
    hasError: hasAbsoluteDateRollupPreviewError,
    isLoading: isLoadingAbsoluteDatePreviewRollup,
    fetchRollup: refreshCalendarRollupPreview,
  } = useFetchReportingRollup({ ...calendarRollupParams, isPreview: true });
  const {
    rollup: timeToEventRollup,
    hasError: hasRelativeDateRollupError,
    isLoading: isLoadingRelativeDateRollup,
    fetchRollup: refreshTimeToEventRollup,
  } = useFetchReportingRollup(relativeDateRollupParams);
  const {
    rollupPreview: timeToEventRollupPreview,
    hasError: hasRelativeDateRollupPreviewError,
    isLoading: isLoadingRelativeDateRollupPreview,
    fetchRollup: refreshTimeToEventRollupPreview,
  } = useFetchReportingRollup({ ...relativeDateRollupParams, isPreview: true });

  const {
    handlePreviewOverrides,
    overridePreviews,
    isLoading: hasPreviewOverridesLoading,
    setOverridePreviews,
    hasError: hasPreviewOverridesError,
  } = useCollectionSalesPreview({ relativeDateRollupParams, calendarRollupParams });

  const isLoadingRollup = isLoadingAbsoluteDateRollup || isLoadingAbsoluteDatePreviewRollup
    || isLoadingRelativeDateRollup || isLoadingRelativeDateRollupPreview;

  const hasRollupError = hasAbsoluteDateRollupError || hasAbsoluteDateRollupPreviewError
    || hasRelativeDateRollupError || hasRelativeDateRollupPreviewError;

  const hasError = hasRollupError || hasPreviewOverridesError;
  const isLoading = isLoadingRollup || groupLoading || isLoadingOverridesSubmission;

  const resetOverridePreviews = useCallback(() => (
    setOverridePreviews(undefined)
  ), [setOverridePreviews]);

  const refreshRollups = useCallback(() => {
    refreshCalendarRollup();
    refreshCalendarRollupPreview();
    refreshTimeToEventRollup();
    refreshTimeToEventRollupPreview();
  }, [
    refreshCalendarRollup,
    refreshCalendarRollupPreview,
    refreshTimeToEventRollup,
    refreshTimeToEventRollupPreview,
  ]);

  const { clearOverrideInputs } = useSubmitBulkOverrides();

  useEffect(() => {
    resetOverridePreviews();
    clearOverrideInputs();
  }, [
    selectedGroup, resetOverridePreviews, overridesSubmission, refreshRollups, clearOverrideInputs,
  ]);

  useEffect(() => {
    if (isLoadingRollup && overridesSubmission) {
      toast.dismiss(ToastId.OVERRIDES_STATUS);
      toast.loading(ToastMsg.LOADING_COLLECTION, { id: ToastId.REFRESHING_COLLECTION });
    } else {
      toast.dismiss(ToastId.REFRESHING_COLLECTION);
    }
  }, [overridesSubmission, isLoadingRollup, isLoadingOverridesSubmission]);

  const eventSalesTrends = useMemo(() => {
    const relativeDatePreview = isPreviewable
      ? (overridePreviews?.relativeDateOverridePreviewData
        ?? timeToEventRollupPreview)
      : undefined;

    const calendarPreview = isPreviewable
      ? (overridePreviews?.calendarOverridePreviewData
        ?? calendarRollupPreview)
      : undefined;

    const timeToEventSalesTrendsByEvent = getSalesTrendsByEvent({
      eventLevelRollup: timeToEventRollup,
      eventLevelRollupPreview: relativeDatePreview,
      reversedX: true,
    });

    const calendarSalesTrendsByEvent = getSalesTrendsByEvent({
      eventLevelRollup: calendarRollup,
      eventLevelRollupPreview: calendarPreview,
    });

    return {
      timeToEventSalesTrendsByEvent,
      calendarSalesTrendsByEvent,
    };
  }, [
    calendarRollup, timeToEventRollup,
    timeToEventRollupPreview, calendarRollupPreview, overridePreviews, isPreviewable,
  ]);

  const trends = useMemo(() => {
    const { timeToEventSalesTrendsByEvent, calendarSalesTrendsByEvent } = eventSalesTrends;

    if (timeToEventSalesTrendsByEvent?.autobrokerEvents?.length > 0)
      return isTimeToEvent ? timeToEventSalesTrendsByEvent : calendarSalesTrendsByEvent;

    return undefined;
  }, [eventSalesTrends, isTimeToEvent]);

  useEffect(() => setEventSalesTrends(trends), [trends, setEventSalesTrends]);

  useEffect(() => {
    const futureEvents = (
      trends?.autobrokerEvents?.filter((event) => event.eventStartsAt > DateTime.utc())
    );

    setFutureEventIds(futureEvents?.map(({ autobrokerEventId }) => autobrokerEventId));
  }, [trends, setFutureEventIds]);

  const uniqueMaps = useMemo(() => (
    new Set(
      trends?.autobrokerEvents
        ?.filter((event) => selectedEventIds.includes(event?.autobrokerEventId))
        ?.map((event) => event?.mapConfigId),
    )
  ), [trends, selectedEventIds]);

  const hasSingleMap = uniqueMaps.size === 1;

  const selectedFutureEvents = useMemo(() => (
    trends?.autobrokerEvents
      ?.filter(({ autobrokerEventId: id }) => selectedFutureEventIds?.includes(id))
  ), [trends, selectedFutureEventIds]);

  const selectedData = useMemo(() => {
    const selectedEventSalesTrend = trends?.salesTrendsByEvent?.filter(({ autobrokerEvent }) => (
      selectedEventIds.includes(autobrokerEvent.autobrokerEventId)
    )) ?? [];
    const selectedEventSalesPreviewTrend = trends?.salesPreviewTrendsByEvent
      ?.filter(({ autobrokerEvent }) => (
        selectedEventIds.includes(autobrokerEvent.autobrokerEventId)
      )) ?? [];

    if (selectedEventSalesTrend?.length > 0 || selectedEventSalesPreviewTrend?.length > 0)
      return [...selectedEventSalesTrend, ...selectedEventSalesPreviewTrend];

    return undefined;
  }, [trends, selectedEventIds]);

  const soldOutSelectedEventIds = useMemo(() => (
    trends?.salesTrendsByEvent?.filter((d) => d.cumulativeTicketsUnsold === 0
      && selectedFutureEventIds?.includes(d.autobrokerEvent.autobrokerEventId))
      ?.map(({ autobrokerEvent }) => autobrokerEvent.autobrokerEventId)
  ), [trends, selectedFutureEventIds]);

  useEffect(() => {
    setCollectionEventIds(autobrokerEventIds.map((id) => Number(id)));
  }, [setCollectionEventIds, autobrokerEventIds]);

  const handleListFocus = useCallback((event: RollupGroups) => {
    if (!selectedEventIds.includes(event.autobrokerEventId))
      return;

    const focusedEventTrend = trends?.salesTrendsByEvent.filter((trend) => (
      trend.autobrokerEvent.autobrokerEventId === event.autobrokerEventId
    ));

    setListFocusedEventId(event.autobrokerEventId);

    if (focusedEventTrend?.length > 0) {
      const lastPoint = focusedEventTrend[focusedEventTrend.length - 1];
      const lastPointValue = lastPoint[metricSelection.key];

      if (typeof lastPointValue !== 'undefined' && !Number.isNaN(lastPointValue))
        setFocusedDatum([lastPoint]);
    }
  }, [trends, metricSelection, selectedEventIds, setListFocusedEventId]);

  const handleListUnfocus = useCallback(() => {
    setListFocusedEventId(undefined);
    setFocusedDatum(undefined);
  }, [setListFocusedEventId]);

  // reset query params when group changes
  const handleSelectGroup = useCallback((option: GroupOption) => {
    if (option) {
      setPage(0);
      navigate(`/view/collection?group=${option.value}`);
    }
  }, [navigate, setPage]);

  const [focusedDatum, setFocusedDatum] = (
    useState<EventTimeSeriesSalesSummary[]>()
  );

  const handleChartDatumFocus = useCallback((datum: EventTimeSeriesSalesSummary[]): void => {
    if (typeof datum[0][metricSelection.key] !== 'undefined' && !Number.isNaN(datum[0][metricSelection.key]))
      setFocusedDatum([datum[0]]);
  }, [metricSelection]);
  const handleChartDatumUnfocus = useCallback(() => {
    setFocusedDatum(undefined);
  }, []);

  useEffect(() => {
    handleChartDatumUnfocus();
  }, [group, handleChartDatumUnfocus]);

  const submitOverridesPreview = useCallback((overrideParams: RollupOverrideParams[]) => {
    handlePreviewOverrides(overrideParams, selectedGroup?.name);
  }, [handlePreviewOverrides, selectedGroup]);

  const [datumSelectedEventIndex, setDatumSelectedEventIndex] = useState<number>();

  const handleDatumSelection = useCallback((id: number) => {
    const eventIndex = trends?.autobrokerEvents?.findIndex(({ autobrokerEventId }) => (
      autobrokerEventId === id
    ));

    setDatumSelectedEventIndex(eventIndex);
  }, [trends]);

  const disabledKeys = useMemo(() => (
    hasSingleMap ? undefined : [Tab.MAP]
  ), [hasSingleMap]);

  const handleTabSelection = useCallback((tab: Tab) => {
    setActiveTab(tab);
  }, [setActiveTab]);

  // feature flags
  const [canViewChoropleth, canViewTimeHeatmap] = [
    user?.hasFeature(Feature.CHOROPLETH),
    user?.hasFeature(Feature.TIME_BINS),
  ];

  const hasIllegalMapSelection = activeTab === Tab.MAP && !hasSingleMap;

  useEffect(() => {
    if (hasIllegalMapSelection)
      setActiveTab(Tab.TRENDS);
  }, [hasIllegalMapSelection, setActiveTab]);

  const handleHeatmapSelection = useCallback((d: EventTimeSeriesSalesSummary[], i: number) => {
    handleDatumSelection(i);
    setFocusedDatum(d);
  }, [handleDatumSelection]);

  return (
    <Container>
      {overridePreviews && (
        <RequestedOverridesModal
          events={
            selectedFutureEvents?.filter(({ autobrokerEventId }) => (
              !soldOutSelectedEventIds.includes(autobrokerEventId)))
          }
          group={selectedGroup?.name}
        />
      )}
      {hasError && !isLoading && (
        <FallbackWrapper>
          <RollupErrorComponent retry={refreshRollups} />
        </FallbackWrapper>
      )}
      {!hasError && (
        <>
          {!isMobile && (
            <>
              <EventCollectionList
                autobrokerEvents={trends?.autobrokerEvents}
                datumSelectedEventIndex={datumSelectedEventIndex}
                focusedDatum={focusedDatum}
                hasPreviewOverridesLoading={hasPreviewOverridesLoading}
                isLoading={isLoading}
                onEventFocus={handleListFocus}
                onExit={handleListUnfocus}
                page={page}
                resetOverridePreviews={resetOverridePreviews}
                setPage={setPage}
                totalEventCt={totalEventCt}
              />
              {overridesVisible && (
                <ParameterOverridesMenu
                  hasPreviewOverridesLoading={hasPreviewOverridesLoading}
                  isCollectionLoading={isLoading}
                  onSubmitOverridesPreview={submitOverridesPreview}
                  overridePreviews={overridePreviews}
                  setOverridePreviews={setOverridePreviews}
                />
              )}
            </>
          )}
          <Wrapper
            isMobile={isMobile}
            overridesMenuWidth={
              overridesVisible ? `${OVERRIDES_MENU_WIDTH_PX}px` : '0px'
            }
          >
            <MainContent>
              <TitleContainer>
                <Title>{selectedGroup?.displayName || PAGE_TITLE}</Title>
                <SelectWrapper>
                  <Select
                    isDisabled={isLoading || hasPreviewOverridesLoading}
                    isLoading={isLoading || hasPreviewOverridesLoading}
                    onChange={handleSelectGroup}
                    options={groupLabels}
                    placeholder='Select a group'
                    value={groupLabels.find((g) => g.value === group)}
                  />
                </SelectWrapper>
              </TitleContainer>
              <Description>{selectedGroup?.description || ''}</Description>
              <ContentContainer>
                <Tabs
                  disabledKeys={disabledKeys}
                  onSelectionChange={handleTabSelection}
                  selectedKey={activeTab}
                >
                  <Item key={Tab.TRENDS} title={Tab.TRENDS}>
                    <OverlaidSalesChart
                      data={selectedData}
                      focusedDatum={focusedDatum}
                      hasPreviewOverridesLoading={hasPreviewOverridesLoading}
                      isLoading={isLoading}
                      onChartFocus={handleChartDatumFocus}
                      onChartUnfocus={handleChartDatumUnfocus}
                      onLineSelection={handleDatumSelection}
                    />
                  </Item>
                  {canViewTimeHeatmap && (
                    <Item key={Tab.TIME_BINS} title={Tab.TIME_BINS}>
                      <TimeHeatmap
                        isLoading={isLoading}
                        onChartUnfocus={handleChartDatumUnfocus}
                        onDatumSelection={handleHeatmapSelection}
                      />
                    </Item>
                  )}
                  {canViewChoropleth && !hasIllegalMapSelection && (
                    <Item key={Tab.MAP} title={Tab.MAP}>
                      <MapRollup
                        mapConfigId={hasSingleMap ? uniqueMaps.values().next().value : undefined}
                      />
                    </Item>
                  )}
                </Tabs>
              </ContentContainer>
            </MainContent>
          </Wrapper>
        </>
      )}
      <Toaster
        containerStyle={toastStyles}
        position='bottom-right'
      />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: ${TOP_OFFSET_PX}px;
  height: calc(100vh - ${TOP_OFFSET_PX}px);
`;

const Title = styled(MainTitle)`
  padding: 2.5rem 0.25rem 2rem 0;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${VIEWPORT_SMALL_PX + 200}px;
  @media screen and (max-width: ${VIEWPORT_SMALL_PX}px) {
    margin-left: 0;
    max-width: ${VIEWPORT_SMALL_PX}px;
  }
`;

interface WrapperProps {
  isMobile?: boolean
  overridesMenuWidth?: string;
}

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  left: ${({ isMobile, overridesMenuWidth }): string => isMobile ? '0' : `calc(${SIDEBAR_WIDTH_PX}px + ${overridesMenuWidth})`};
  width: ${({ isMobile, overridesMenuWidth }): string => isMobile ? '100vw' : `calc(100vw - ${SIDEBAR_WIDTH_PX}px - ${overridesMenuWidth})`};
  height: calc(100vh - ${TOP_OFFSET_PX}px);
  overflow-y: auto;
  letter-spacing: 0;
  transition: all 0.3s;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectWrapper = styled.div`
  min-width: 300px;
  position: relative;
  top: 0.55rem;
`;

const TitleContainer = styled.div`
  display: flex;
  width: calc(100% - 2rem);
  max-width: ${VIEWPORT_SMALL_PX + 200}px;
  justify-content: space-between;
  column-gap: 1rem;
  flex-wrap: wrap-reverse;
  align-items: center;
`;

const Description = styled.div`
  color: ${({ theme }): string => theme.color.text.secondary};
  margin: -1.5rem 0 1rem 0;
  width: calc(100% - 2rem);
  max-width: ${VIEWPORT_SMALL_PX + 200}px
`;

export { Props };
export default EventCollectionRollup;
