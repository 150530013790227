import client from './_clientFormatted';
import DisplayableUploader from '#/types/Uploader';

const getUploaders = async (): Promise<DisplayableUploader[]> => {
  const response = await client.get('/proxy/uploaders');

  return response.data.uploaders;
};

export default getUploaders;
