import React from 'react';
import styled from 'styled-components';
import PageWrapper from '#/shared/PageWrapper';
import Header from '#/shared/Header';
import TransactionsTable from './TransactionsTable';
import { User } from '#/types/User';
import Filters from './Filters';
import useFetchTransactions from '#/pages/useFetchTransactions';
import { FetchGroupsHook } from '../useFetchGroups';
import { StakeholderLogos } from '../useFetchStakeholders';

interface SalesPageProps {
  user: User;
  groupsHook: FetchGroupsHook;
  stakeholderLogos: StakeholderLogos;
}

const SalesPage: React.FC<SalesPageProps> = ({
  user, groupsHook, stakeholderLogos,
}) => {
  const transactionsHook = useFetchTransactions();
  const { filters, setFilters } = transactionsHook;

  return (
    <PageWrapper>
      <Container>
        <Header
          toggleOpen={null}
          user={user}
        />
        <FilterWrapper>
          <Filters
            filters={filters}
            groupsHook={groupsHook}
            setFilters={setFilters}
          />
        </FilterWrapper>
      </Container>
      <ScrollBody>
        <TableWrapper>
          <TransactionsTable
            stakeholderLogos={stakeholderLogos}
            transactionsHook={transactionsHook}
          />
        </TableWrapper>
      </ScrollBody>
    </PageWrapper>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 2;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.info};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: padding 0.75s ease;
`;

const ScrollBody = styled.div`
  position: absolute;
  width: 100%;
  top: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FilterWrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

const TableWrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
`;

export default SalesPage;
