import React from 'react';
import {
  OkWrapper, Element, Name,
} from '#/shared/Card';

const MESSAGE = 'All clear!';

const NoErrorsCard: React.FC = () => {
  return (
    <>
      <OkWrapper>
        <Element>
          <Name>{MESSAGE}</Name>
        </Element>
      </OkWrapper>
    </>
  );
};

export default NoErrorsCard;
