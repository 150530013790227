import { AxiosResponse } from 'axios';
import { PeakpassDomain, PeakpassDomains } from '#/types/PeakpassDomain';
import client from './_clientFormatted';

const getPeakpassDomains = async (
  stakeholder?: string,
  peakpassDomainId?: string,
): Promise<PeakpassDomain[]> => {
  const response: AxiosResponse<PeakpassDomains> = await client.get(
    '/proxy/stakeholders/peakpass_domains',
    {
      params: {
        stakeholder,
        peakpassDomainId,
      },
    },
  );

  return response?.data.peakpassDomains;
};

export default getPeakpassDomains;
