import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '#/shared/Header';
import { User } from '#/types/User';
import camelcaseKeys from 'camelcase-keys';
import { parse } from 'query-string';
import RollupErrorComponent from '../clientReporting/DashboardPage/components/RollupErrorComponent';
import EventCollectionRollup from './EventCollectionRollup';
import { NUM_EVENTS_LIMIT } from './EventCollectionList';
import { EventCollectionProviders } from './contexts';
import { type FetchGroupsHook } from '#/pages/useFetchGroups';
import Loader from '#/shared/Loader';
import Group from '#/types/Group';
import getGroup from '#/api/getGroup';

interface Props {
  user: User;
  groupsHook: FetchGroupsHook;
}

interface SearchType {
  autobrokerEventIds?: string[]
  group?: string
  bypassCache?: boolean
}

const EventCollectionPage: React.FC<Props> = ({ user, groupsHook }) => {
  const [_selectedGroup, setSelectedGroup] = useState<Group>(undefined);
  const [hasError, setHasError] = useState<boolean>(false);
  const { defaultGroup, setDefaultGroup } = groupsHook;
  const { search: locationSearch } = useLocation();
  const { autobrokerEventIds, group, bypassCache } = camelcaseKeys(
    parse(locationSearch, { arrayFormat: 'comma' }),
  ) as SearchType;
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    if (!group && !autobrokerEventIds && !!defaultGroup)
      navigate(`/view/collection?group=${defaultGroup}`, { replace: true });
    if (!!group && group !== defaultGroup && !!setDefaultGroup)
      setDefaultGroup(group);
  }, [group, autobrokerEventIds, defaultGroup, setDefaultGroup, navigate]);

  const fetchSelectedGroup = useCallback(async () => {
    try {
      const selected = await getGroup(group);

      if (selected.attributes?.autobrokerEventIds) {
        setSelectedGroup(selected);
        setHasError(false);
      } else {
        // If an event collection is returned with an undefined autobrokerEventIds,
        // that means that there are too many events to display in the UI, and
        // and hence the group is not valid for display.
        setHasError(true);
      }
    } catch (err) {
      console.error(err);
      setHasError(true);
    }
  }, [group, setSelectedGroup]);

  const selectedGroup = useMemo(() => {
    if (!!group && _selectedGroup && _selectedGroup.name === group)
      return _selectedGroup;
    return undefined;
  }, [group, _selectedGroup]);

  useEffect(() => {
    if (!!group && !selectedGroup)
      fetchSelectedGroup();
  }, [group, selectedGroup, fetchSelectedGroup]);

  const selectedGroupEventIds = selectedGroup
    ?.attributes
    ?.autobrokerEventIds
    ?.map((id) => String(id)) || [];
  // handle the specific case of a single-event collection
  const eventList = selectedGroupEventIds.concat(autobrokerEventIds ?? []);
  const eventListTruncated = useMemo(() => (
    eventList.slice(page * NUM_EVENTS_LIMIT, (page + 1) * NUM_EVENTS_LIMIT)
  ), [eventList, page]);

  const groupHasNoDefinedEvents = !!selectedGroup && selectedGroupEventIds.length === 0;

  const isAllowed = !groupHasNoDefinedEvents && eventList.every((id) => Number(id));
  const isLoading = (!!group && !selectedGroup)
    || (eventListTruncated.length === 0 && !groupHasNoDefinedEvents);

  return (
    <>
      <Container>
        <Header
          toggleOpen={null}
          user={user}
        />
      </Container>
      <EventCollectionProviders>
        <EventCollectionWrapper>
          {!isLoading && !hasError && isAllowed && (
            <EventCollectionRollup
              autobrokerEventIds={eventListTruncated}
              bypassCache={bypassCache}
              group={group}
              groupsHook={groupsHook}
              page={page}
              selectedGroup={selectedGroup}
              setPage={setPage}
              totalEventCt={eventList.length}
              user={user}
            />
          )}
          {!isAllowed && (
            <RollupErrorComponent />
          )}
          {hasError && (
            <RollupErrorComponent retry={fetchSelectedGroup} />
          )}
          {isLoading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
        </EventCollectionWrapper>
      </EventCollectionProviders>
    </>
  );
};

const EventCollectionWrapper = styled.div`
  height: 100%;
`;

const Container = styled.div`
  position: fixed;
  z-index: 2;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.info};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: padding 0.75s ease;
`;

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default EventCollectionPage;
