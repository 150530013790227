import axios, { AxiosInstance } from 'axios';

const createInstance = (): AxiosInstance => {
  const connection = axios.create({
    timeout: 300000,
  });

  return connection;
};

export default createInstance();
