import React from 'react';
import {
  ErrorStatusType, EventError, PostErrorStatus,
} from '#/types/Errors';
import Checklist, { ChecklistConfigItem } from './checklist';

interface EventConflictingErrorChecklistProps {
  error: EventError;
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const CONFIG: ChecklistConfigItem[] = [
  {
    id: 0,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Identify the conflicting SeatGeekIQ Events using the Error Message Above',
  },
  {
    id: 1,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Check the SG and DTI Event IDs associated with each of the conflicting SeatGeekIQ Events, and confirm the SeatGeekIQ Events are in fact for the same event. Ingestion details should be the same across both events.',
    parent_id: 0,
  },
  {
    id: 2,
    level: 3,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Unlink the Source Config and Delete the Sink Config from the incorrect event',
    parent_id: 1,
  },
  {
    id: 3,
    level: 4,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Make sure that the SeatGeekIQ event that had the incorrect source/sink associated with it is set-up with a correct source/sink',
    parent_id: 2,
  },
  {
    id: 4,
    level: 4,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Check that associated Ingestions have the correct SeatGeek Event Id and OneTicket Event Id',
    parent_id: 3,
  },
  {
    id: 5,
    level: 5,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Wrong OneTicket Event Id',
    parent_id: 4,
  },
  {
    id: 6,
    level: 6,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Delete the Ingestion Listing, Relist the Listing with the correct OneTicket Event Id',
    parent_id: 5,
  },
  {
    id: 7,
    level: 5,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Wrong SeatGeek Event Id',
    parent_id: 4,
  },
  {
    id: 8,
    level: 6,
    status: ErrorStatusType.RESOLVED,
    notes: 'Edit the Ingestion Listing to Update the SeatGeek Event Id',
    parent_id: 7,
  },
  {
    id: 9,
    level: 5,
    status: ErrorStatusType.RESOLVED,
    notes: 'Run the Supervisor on ALL Conflicting Events to Clear the Errors',
    parent_id: 4,
  },
];

const EventConflictingErrorChecklist: React.FC<EventConflictingErrorChecklistProps> = (
  { error, setErrorStatus },
) => {
  return (
    <Checklist config={CONFIG} error={error} setErrorStatus={setErrorStatus} />
  );
};

export default EventConflictingErrorChecklist;
