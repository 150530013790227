import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Listing from '#/types/Listing';

const STAKEHOLDER = 'Stakeholder';
const PRIORITY = 'Priority';
const DEAL_TERMS = 'Deal Terms';

interface CardProps {
  listing: Listing;
}

const StakeholderCard: React.FC<CardProps> = ({ listing }) => {
  const {
    stakeholder, deal, deal_term_name, priority,
  } = listing;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{STAKEHOLDER}</Name>
        <Value>{stakeholder}</Value>
      </Element>
      { deal && (
        <Element>
          <Name>{DEAL_TERMS}</Name>
          <Value>{deal}</Value>
          <Value>{deal_term_name}</Value>
        </Element>
      )}
      { priority !== null
        && (
          <Element>
            <Name>{PRIORITY}</Name>
            <Value>{priority.toFixed(2)}</Value>
          </Element>
        )}
    </NarrowWrapper>
  );
};

export default StakeholderCard;
