import React, { MouseEventHandler } from 'react';
import Contents from './Contents';
import Slider from './Slider';
import Event from '#/types/Event';

interface EventTrayProps {
  events: Event[];
  retry: () => void;
  showError: boolean;
  showLoader: boolean;
  showResults: boolean;
  sliderIsOpen: boolean;
  toggleOpen: MouseEventHandler<HTMLButtonElement>;
}

const EventTray: React.FC<EventTrayProps> = ({
  events,
  sliderIsOpen,
  retry,
  showError,
  showLoader,
  showResults,
  toggleOpen,
}) => (
  <Slider sliderIsOpen={sliderIsOpen} toggleOpen={toggleOpen}>
    <Contents
      events={events}
      retry={retry}
      showError={showError}
      showLoader={showLoader}
      showResults={showResults}
      toggleOpen={toggleOpen}
    />
  </Slider>
);

export default EventTray;
