/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import styled from 'styled-components';
import { useNumberFieldState } from 'react-stately';
import {
  type AriaNumberFieldProps, useLocale, useNumberField, AriaButtonProps, useButton,
} from 'react-aria';

const DECREMENT = '-';
const INCREMENT = '+';

const Button: React.FC<AriaButtonProps> = (props) => {
  const ref = useRef();
  const { buttonProps } = useButton(props, ref);
  const { children } = props;

  return (
    <button
      {...buttonProps}
      ref={ref}
      style={{
        background: '#fff',
        border: '1px solid #ddd',
        width: '1.75rem',
        height: '1.8rem',
      }}
      type='button'
    >
      {children}
    </button>
  );
};

const NumberField: React.FC<AriaNumberFieldProps> = ({ step = 0.01, ...props }) => {
  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, step, locale });
  const inputRef = React.useRef(null);
  const {
    labelProps,
    groupProps,
    inputProps,
    incrementButtonProps,
    decrementButtonProps,
  } = useNumberField(props, state, inputRef);

  return (
    <div>
      <Label
        {...labelProps}
        htmlFor={props.id}
        isDisabled={props.isDisabled}
      >
        {props.label}
      </Label>
      <FieldGroup {...groupProps}>
        <Button {...decrementButtonProps}>
          {DECREMENT}
        </Button>
        <Input
          id={props.id}
          {...inputProps}
          name={props.id}
          ref={inputRef}
        />
        <Button {...incrementButtonProps}>
          {INCREMENT}
        </Button>
      </FieldGroup>
    </div>
  );
};

interface LabelProps {
  isDisabled?: boolean;
}

const Label = styled.label<LabelProps>`
  opacity: ${({ isDisabled }): number => isDisabled ? 0.5 : 1};
  font-size: 0.85rem;
  font-weight: 550;
  transition: all 0.2s ease-in-out;
`;

const Input = styled.input`
  border: 1px solid #ddd;
  border-left: none;
  border-right: none;
  width: 6rem;
  padding: 0.35rem;
`;

const FieldGroup = styled.div`
  display: flex;
  margin-top: 0.3rem;
`;

export default NumberField;
