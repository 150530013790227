import React from 'react';
import Graph from './Graph';
import { PriceRatioGraphInfo } from '#/types/Snapshot';
import Loading from '../sharedGraphComponents/Loading';
import Error from '../sharedGraphComponents/Error';
import useShowSales from './hooks/useShowSales';

interface PriceRatioGraphProps {
  error: string;
  loading: boolean;
  priceRatioGraphInfo: PriceRatioGraphInfo;
  showGraph: boolean;
}

const PriceRatioGraph: React.FC<PriceRatioGraphProps> = ({
  error,
  loading,
  priceRatioGraphInfo,
  showGraph,
}) => {
  const okToRender = showGraph && !loading && !error;
  const { showSales, handleShowSales } = useShowSales();

  return (
    <>
      {okToRender && (
        <Graph
          handleShowSales={handleShowSales}
          priceRatioGraphInfo={priceRatioGraphInfo}
          showSales={showSales}
        />
      )}
      <Loading loading={loading} showGraph={showGraph} />
      <Error error={error} showGraph={showGraph} />
    </>
  );
};

export default PriceRatioGraph;
