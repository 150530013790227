import React, { useState, useCallback, useEffect } from 'react';
import EventModal from './EventModal';
import IntegrationModal from './IntegrationModal';
import PricingConstraintsModal from './PricingConstraintsModal';
import createDefaultEvent from './utils/createDefaultEvent';
import createDefaultIntegration, {
  DefaultIntegration,
} from './utils/createDefaultIntegration';
import useSetupModal from '#/pages/useSetupModal';

interface ManagerProps {
  closeModal: () => void;
}

enum ModalStep {
  EVENT = 'event',
  PRICING = 'pricing',
  INTEGRATION = 'integration',
}

const MultiStepManager: React.FC<ManagerProps> = ({
  closeModal,
}): React.ReactElement => {
  const newEvent = createDefaultEvent();

  const [currentStage, setCurrentStage] = useState(ModalStep.EVENT);
  const [activeIntegration, setActiveIntegration] = useState<
  DefaultIntegration
  >(null);
  const [eventId, setEventId] = useState<number>(null);

  useEffect(() => {
    if (eventId)
      setActiveIntegration(createDefaultIntegration(eventId));
  }, [eventId]);

  const onEventSuccess = useCallback((newEventId: number) => {
    setEventId(newEventId);
    setCurrentStage(ModalStep.PRICING);
  }, []);

  const onPricingSuccess = useCallback(
    () => setCurrentStage(ModalStep.INTEGRATION),
    [setCurrentStage],
  );

  useSetupModal(closeModal);

  switch (currentStage) {
    case ModalStep.EVENT:
      return (
        <EventModal
          closeModal={closeModal}
          newEvent={newEvent}
          onSuccess={onEventSuccess}
        />
      );
    case ModalStep.PRICING:
      return (
        <PricingConstraintsModal
          closeModal={closeModal}
          eventId={eventId}
          onSuccess={onPricingSuccess}
        />
      );
    case ModalStep.INTEGRATION:
      return (
        <IntegrationModal
          closeModal={closeModal}
          integration={activeIntegration}
          multistep
        />
      );
    default:
      return null;
  }
};

export default MultiStepManager;
