/* eslint-disable babel/no-invalid-this */
enum UserType {
  SUPERUSER = 'superuser',
  ADMIN = 'admin',
  READ_WRITE = 'read_write',
  READ_ONLY = 'read_only',
  REPORTING = 'reporting',
}

enum StakeholderAccessType {
  READ_ONLY = 'read_only',
  READ_WRITE = 'read_write',
  NONE = 'none',
}

enum Feature {
  CHOROPLETH = 'choropleth',
  TIME_BINS = 'time_bins',
}

interface StakeholderAuthorizations {
  stakeholderId: number;
  hasWriteAccess: boolean;
}

interface UserFilters {
  userTypes?: UserType[];
}

class User {
  id: number;

  seatgeekUserId: number;

  firstName: string | null;

  lastName: string | null;

  email: string;

  profileImageUrl: string | null;

  userType: UserType;

  isActive: boolean;

  authorizations: StakeholderAuthorizations[];

  features: Feature[] | null;

  constructor(public userProps: Partial<User>) {
    Object.assign(this, userProps);
  }

  isSuperUser: boolean = this.userProps.userType === UserType.SUPERUSER;

  isAdmin: boolean = [UserType.ADMIN, UserType.SUPERUSER].includes(this.userProps.userType);

  isReadWrite: boolean = [
    UserType.READ_WRITE,
    UserType.ADMIN,
    UserType.SUPERUSER,
  ].includes(this.userProps.userType);

  isReadOnly: boolean = [UserType.READ_ONLY, UserType.REPORTING].includes(this.userProps.userType);

  isReporting: boolean = this.userProps.userType === UserType.REPORTING;

  getNames(): string[] {
    const names = [];

    if (this.firstName)
      names.push(this.firstName);

    if (this.lastName)
      names.push(this.lastName);

    if (names.length === 0)
      names.push(this.email);

    return names;
  }

  fullName(): string { return this.getNames().join(' '); }

  initials(): string { return this.getNames().map((name) => name[0].toUpperCase()).join(''); }

  hasFeature(feature: Feature): boolean {
    return this.features?.includes(feature) ?? false;
  }
}

interface PatchUserUpdate {
  isActive?: boolean;
  userType?: UserType;
  stakeholders?: string[];
  stakeholderAccessType?: StakeholderAccessType;
}

interface UsersListResponse {
  users: User[];
}

export {
  Feature,
  UserFilters,
  User,
  UserType,
  StakeholderAccessType,
  PatchUserUpdate,
  StakeholderAuthorizations,
  UsersListResponse,
};
