import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import getTheme from '#/theme';
import mainPalette from '#/theme/palettes/main';

const Providers = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  const theme = getTheme(mainPalette);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {children}
      </Router>
    </ThemeProvider>
  );
};

export default Providers;
