/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react/jsx-no-bind */
import React, {
  ChangeEvent, useCallback, useContext, useState,
} from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Clear from '#images/Clear.svg';
import { CollectionEventsContext, CollectionSelectionsContext } from '../../contexts';
import type { CollectionDateFilters } from '..';
import { DateInput } from '#/shared/clientReporting/FilterContainer';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import Check from '#/shared/clientReporting/Icons/Check';
import {
  HEADING, APPLY, CANCEL, DASH, EVENT_START, TRANSACTION_AT,
} from './constants';
import { formatMillisecondsAsDateTimeString, generateMaxLessThanMin, validateDatesWithinExtents } from './utils';
import { METRIC_OPTIONS } from '../../utils/menuOptions';
import { validateTransactionDateRange, getDefaultFromDatetime, generateTransactionDateRangeOverflow } from '../../TimeHeatmap/utils';
import { Tab } from '../../constants';

interface Props {
  close: () => void;
}

const isDateFilterActive = (
  tempFilter: string | undefined,
  persistedFilter: string | undefined,
): boolean => {
  return tempFilter !== '' && (!!tempFilter || !!persistedFilter);
};

const getResolvedDateFilter = (
  tempFilter: string | undefined,
  persistedFilter: string | undefined,
): string | undefined => {
  return tempFilter === '' ? undefined : (tempFilter || persistedFilter);
};

const DateFilterMenu = ({ close }: Props): JSX.Element => {
  const { selectAllEvents, allEventsSelected } = useContext(CollectionEventsContext);
  const { dateFilters, setDateFilters, resetDateFilters } = useContext(CollectionSelectionsContext);
  const [_dateFilters, _setDateFilters] = useState<CollectionDateFilters>({});

  const {
    eventStartFullExtent, transactionAtFullExtent,
    eventStartMin, eventStartMax, transactionAtMin, transactionAtMax,
  } = dateFilters;

  const handleDateInputChange = (filterKind: (keyof typeof dateFilters)) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      _setDateFilters((prev) => ({
        ...prev,
        [filterKind]: e.target.value ?? undefined,
      }));
    };
  };

  const handleClear = useCallback(() => {
    resetDateFilters();
    if (!allEventsSelected)
      selectAllEvents();
    close();
  }, [resetDateFilters, close, allEventsSelected, selectAllEvents]);

  const resolvedEventStartMin = _dateFilters?.eventStartMin || dateFilters?.eventStartMin;
  const resolvedEventStartMax = _dateFilters?.eventStartMax || dateFilters?.eventStartMax;
  const resolvedTransactionAtMin = _dateFilters?.transactionAtMin || dateFilters?.transactionAtMin;
  const resolvedTransactionAtMax = _dateFilters?.transactionAtMax || dateFilters?.transactionAtMax;

  const eventRangeError = generateMaxLessThanMin({
    min: resolvedEventStartMin, max: resolvedEventStartMax, kind: 'event',
  });

  const transactionRangeError = generateMaxLessThanMin({
    min: resolvedTransactionAtMin, max: resolvedTransactionAtMax, kind: 'transaction',
  });

  const {
    metricSelection,
    handleMetricSelection,
    timeIntervalSelection,
    activeTab,
  } = useContext(CollectionSelectionsContext);

  const isTimeBins = activeTab === Tab.TIME_BINS;

  const { isValid: hasValidTransactionDateRange } = validateTransactionDateRange({
    transactionAtMin: getResolvedDateFilter(
      _dateFilters?.transactionAtMin, resolvedTransactionAtMin,
    ),
    transactionAtMax: getResolvedDateFilter(
      _dateFilters?.transactionAtMax, resolvedTransactionAtMax,
    ),
    timeIntervalSelection,
    defaultFromDate: getDefaultFromDatetime(timeIntervalSelection),
  });

  const transactionRangeOverflow = isTimeBins
    ? generateTransactionDateRangeOverflow({
      hasValidTransactionDateRange,
      timeIntervalSelection,
    })
    : undefined;

  const handleApplyDateFilters = useCallback(() => {
    // if selectedMetric is ticketsUnsoldPercent, re-select ticketsSold instead
    // since ticketsUnsoldPercent is not a valid metric when transaction date filters are applied
    if (metricSelection.key === 'cumulativeTicketsUnsoldPercent'
      && (resolvedTransactionAtMin || resolvedTransactionAtMax))
      handleMetricSelection(METRIC_OPTIONS[1].key);

    const thisEventStartMin = getResolvedDateFilter(
      _dateFilters?.eventStartMin, resolvedEventStartMin,
    );
    const thisEventStartMax = getResolvedDateFilter(
      _dateFilters?.eventStartMax, resolvedEventStartMax,
    );
    const thisTransactionAtMin = getResolvedDateFilter(
      _dateFilters?.transactionAtMin, resolvedTransactionAtMin,
    );
    const thisTransactionAtMax = getResolvedDateFilter(
      _dateFilters?.transactionAtMax, resolvedTransactionAtMax,
    );

    setDateFilters((prev) => ({
      ...prev,
      eventStartMin: thisEventStartMin,
      eventStartMax: thisEventStartMax,
      transactionAtMin: thisTransactionAtMin,
      transactionAtMax: thisTransactionAtMax,
    }));

    if (!thisEventStartMin && !thisEventStartMax && !allEventsSelected)
      selectAllEvents();

    close();
  }, [
    setDateFilters,
    resolvedEventStartMin,
    resolvedEventStartMax,
    resolvedTransactionAtMin,
    resolvedTransactionAtMax,
    close,
    handleMetricSelection,
    metricSelection,
    _dateFilters,
    selectAllEvents,
    allEventsSelected,
  ]);

  const datesWithinExtents = validateDatesWithinExtents({
    eventStartFullExtent,
    transactionAtFullExtent,
    dateFilters: _dateFilters,
  });

  const isValid = !eventRangeError
    && datesWithinExtents
    && !transactionRangeError
    && !transactionRangeOverflow
    && (
      Object.values(_dateFilters).some((v) => v)
    || eventStartMin || eventStartMax || transactionAtMin || transactionAtMax
    );

  return (
    <Wrapper>
      <Header>
        <Heading>{HEADING}</Heading>
        <SmallButton
          Icon={<SVG height={16} src={Clear} width={16} />}
          kind='secondary'
          label='Clear all'
          onClick={handleClear}
          padding='0.1rem 0.5rem 0.1rem 0.25rem'
        />
      </Header>
      <Hr />
      <DateKindLabel>{EVENT_START}</DateKindLabel>
      <DateRangeContainer>
        <Input
          $isActive={isDateFilterActive(_dateFilters?.eventStartMin, dateFilters?.eventStartMin)}
          data-testid="min-event-start-time"
          id="min-event-start-time"
          max={formatMillisecondsAsDateTimeString(eventStartFullExtent?.[1])}
          min={formatMillisecondsAsDateTimeString(eventStartFullExtent?.[0])}
          name="min-event-start-time"
          onChange={handleDateInputChange('eventStartMin')}
          type="datetime-local"
          value={_dateFilters?.eventStartMin ?? dateFilters?.eventStartMin ?? ''}
        />
        <DashContainer>{DASH}</DashContainer>
        <Input
          $isActive={isDateFilterActive(_dateFilters?.eventStartMax, dateFilters?.eventStartMax)}
          data-testid="max-event-start-time"
          id="max-event-start-time"
          max={formatMillisecondsAsDateTimeString(eventStartFullExtent?.[1])}
          min={formatMillisecondsAsDateTimeString(eventStartFullExtent?.[0])}
          name="max-event-start-time"
          onChange={handleDateInputChange('eventStartMax')}
          type="datetime-local"
          value={_dateFilters?.eventStartMax ?? dateFilters?.eventStartMax ?? ''}
        />
      </DateRangeContainer>
      {eventRangeError && (
        <ErrorMessage>{eventRangeError}</ErrorMessage>
      )}
      <Hr />
      <DateKindLabel>{TRANSACTION_AT}</DateKindLabel>
      <DateRangeContainer>
        <Input
          $isActive={
            isDateFilterActive(_dateFilters?.transactionAtMin, dateFilters?.transactionAtMin)
          }
          data-testid="min-transaction-time"
          id="min-transaction-time"
          max={formatMillisecondsAsDateTimeString(transactionAtFullExtent?.[1])}
          min={formatMillisecondsAsDateTimeString(transactionAtFullExtent?.[0])}
          name="min-transaction-time"
          onChange={handleDateInputChange('transactionAtMin')}
          type="datetime-local"
          value={_dateFilters?.transactionAtMin ?? dateFilters?.transactionAtMin ?? ''}
        />
        <DashContainer>{DASH}</DashContainer>
        <Input
          $isActive={
            isDateFilterActive(_dateFilters?.transactionAtMax, dateFilters?.transactionAtMax)
          }
          data-testid="max-transaction-time"
          id="max-transaction-time"
          max={formatMillisecondsAsDateTimeString(transactionAtFullExtent?.[1])}
          min={formatMillisecondsAsDateTimeString(transactionAtFullExtent?.[0])}
          name="max-transaction-time"
          onChange={handleDateInputChange('transactionAtMax')}
          type="datetime-local"
          value={_dateFilters?.transactionAtMax ?? dateFilters?.transactionAtMax ?? ''}
        />
      </DateRangeContainer>
      {transactionRangeError && (
        <ErrorMessage>{transactionRangeError}</ErrorMessage>
      )}
      {transactionRangeOverflow && (
        <ErrorMessage>{transactionRangeOverflow}</ErrorMessage>
      )}
      <Hr />
      <ActionsContainer>
        <SmallButton
          kind='secondary'
          label={CANCEL}
          onClick={close}
        />
        <SmallButton
          Icon={<IconWrapper><Check /></IconWrapper>}
          isDisabled={!isValid}
          label={APPLY}
          onClick={handleApplyDateFilters}
          padding='0.45rem 1.1rem 0.45rem 0.7rem'
        />
      </ActionsContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 1rem;
  letter-spacing: 0;
`;

const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.35rem;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const DateKindLabel = styled.p`
  font-weight: 600;
  font-size: 0.9rem;
  margin: 0 0 0.45rem 0;
`;

const Input = styled(DateInput)`
  width: 11.25rem;
`;

const DashContainer = styled.div`
  opacity: 0.35;
`;

const Heading = styled.h1`
  margin: 0;
  font-size: 1.3rem;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.error};
  font-size: 0.875rem;
  margin: 0.5rem 0;
`;

const Hr = styled.hr`
  margin: 1.1rem 0 1rem 0;
  border: ${({ theme }: {theme: Theme}): string => `1px solid ${theme.palette.gray.light08}`};
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
`;

const IconWrapper = styled.div`
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DateFilterMenu;
