import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Listing from '#/types/Listing';

const VISIBLE = 'Visible';
const NOT_VISIBLE = 'Not Visible';
const RESERVED = 'Reserved';
const SINKS = 'Sinks';

interface CardProps {
  listing: Listing;
}

const VisibilityCard: React.FC<CardProps> = ({ listing }) => {
  const { sinks, reserved_at } = listing;

  return (
    <NarrowWrapper>
      <Element>
        <Value>
          {sinks && sinks.length > 0 ? VISIBLE : NOT_VISIBLE}
        </Value>
      </Element>
      {reserved_at && (
        <Element>
          <Value>
            {RESERVED}
          </Value>
        </Element>
      )}
      {sinks && sinks.length > 0 && (
        <Element>
          <Name>{SINKS}</Name>
          <>
            {sinks.map((sink) => (
              <Value key={sink}>
                {sink}
              </Value>
            ))}
          </>
        </Element>
      )}
    </NarrowWrapper>
  );
};

export default VisibilityCard;
