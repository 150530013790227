import React, { useCallback } from 'react';
import Action, { EventType } from '../types';
import { PRICING_BASIS_OPTIONS, PricingBasis } from '#/types/Event';
import SelectForm from '../Forms/SelectForm';
import type { GenericOption } from '#/types/GenericOption';

interface SelectProps {
  handleInputChange: React.Dispatch<Action>;
  label: string;
}

const SelectPricingBasis: React.FC<SelectProps> = ({
  handleInputChange,
  label,
}) => {
  const handleChange = useCallback(
    (option: GenericOption<string, PricingBasis>) => {
      const { value } = option;

      handleInputChange({ type: EventType.PRICING_BASIS, value });
    },
    [handleInputChange],
  );
  const defaultOption = PRICING_BASIS_OPTIONS[0];

  return (
    <SelectForm
      defaultValue={defaultOption}
      label={label}
      onChange={handleChange}
      options={PRICING_BASIS_OPTIONS}
    />
  );
};

export default SelectPricingBasis;
