import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { OverrideChangeType, Settings } from '#/types/Override';
import GraphControls from './GraphControls';
import Graph from './Graph';
import useGraphControls from './useGraphControls';
import usePostPreview from './usePostPreview';
import useFetchSnapshots from './useFetchSnapshots';
import useFetchPreview from './useFetchPreview';
import useFetchOverrideLogs from '#/pages/useFetchOverrideLogs';
import { User } from '#/types/User';

interface MainGraphProps {
  user: User;
  initialSettings: Settings;
  parameters: Settings;
}

const MainGraph: React.FC<MainGraphProps> = ({
  user,
  initialSettings,
  parameters,
}) => {
  const {
    data, error, fetchSnapshots, loading,
  } = useFetchSnapshots();

  const {
    fetchPreview,
    listingsLoading,
    previewData,
    previewError,
    previewLoading,
  } = useFetchPreview();

  const {
    canonicalSettings,
    resetSettings,
    saveError,
    saveInProgress,
    saveSettings,
    settings,
    updateSetting,
  } = useGraphControls(initialSettings, fetchPreview, fetchSnapshots);

  const {
    clearOverlay,
    distributionOverlay,
    overlayError,
    getPreviewOverlay,
    selldownOverlay,
  } = usePostPreview(canonicalSettings);

  const { overrideError, overrideLogs, retry } = useFetchOverrideLogs();
  const filteredLogs = useMemo(() => (
    overrideLogs?.filter((o) => o.type === OverrideChangeType.EVENT_PARAMETER)
  ), [overrideLogs]);

  const saveHandler = useCallback((notes? : string) => {
    saveSettings(notes);
    clearOverlay();
  }, [saveSettings, clearOverlay]);

  const resetHandler = useCallback(() => {
    resetSettings();
    clearOverlay();
  }, [clearOverlay, resetSettings]);

  return (
    <Container>
      <GraphControls
        disabled={user.isReadOnly}
        getPreviewOverlay={getPreviewOverlay}
        parameters={parameters}
        resetSettings={resetHandler}
        saveError={saveError}
        saveInProgress={saveInProgress}
        saveSettings={saveHandler}
        settings={settings}
        updateSetting={updateSetting}
      />
      <Graph
        data={data}
        distributionOverlay={distributionOverlay}
        error={error}
        listingsLoading={listingsLoading}
        loading={loading}
        overlayError={overlayError}
        overrideError={overrideError}
        overrideLogs={filteredLogs}
        overrideRetry={retry}
        previewData={previewData}
        previewError={previewError}
        previewLoading={previewLoading}
        selldownOverlay={selldownOverlay}
        user={user}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 3.75rem;
  width: 100%;
  max-width: 83.75rem;
  padding-left: 6.25rem;
`;

export default MainGraph;
