import React, { useState, useCallback, useEffect } from 'react';

interface EventsContextValue {
  collectionEventIds: number[]
  setCollectionEventIds: React.Dispatch<React.SetStateAction<number[]>>
  selectedEventIds: number[]
  selectEvents: (autbrokerEventIds: number[], isSelection?: boolean) => void
  selectAllEvents: () => void
  allEventsSelected: boolean
  futureEventIds: number[]
  setFutureEventIds: React.Dispatch<React.SetStateAction<number[]>>
  selectedFutureEventIds: number[]
}

const CollectionEventsContext = React.createContext<EventsContextValue>(null);

const CollectionEventsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [collectionEventIds, setCollectionEventIds] = useState<number[]>([]);
  const [selectedEventIds, setSelectedEventIds] = useState<number[]>([]);
  const [allEventsSelected, setAllEventsSelected] = useState(true);
  const [futureEventIds, setFutureEventIds] = useState<number[]>([]);
  const [selectedFutureEventIds, setSelectedFutureEventIds] = useState<number[]>([]);

  useEffect(() => {
    if (collectionEventIds?.length > 0)
      setSelectedEventIds(collectionEventIds.map((id) => Number(id)));
  }, [collectionEventIds]);

  useEffect(() => {
    setAllEventsSelected(collectionEventIds?.length === selectedEventIds?.length);
  }, [collectionEventIds, selectedEventIds]);

  useEffect(() => {
    setSelectedFutureEventIds(
      selectedEventIds?.filter((id) => futureEventIds?.includes(id)),
    );
  }, [selectedEventIds, futureEventIds]);

  const selectEvents = useCallback((autobrokerEventIds: number[], isSelection = false) => {
    setSelectedEventIds((prev) => {
      if (isSelection)
        return autobrokerEventIds;

      if (autobrokerEventIds.every((id) => (
        prev.includes(id)
      )))
        return prev.filter((event) => !autobrokerEventIds.includes(event));

      return [...prev, ...autobrokerEventIds];
    });
  }, []);

  const selectAllEvents = useCallback(() => {
    if (collectionEventIds?.length === selectedEventIds?.length) {
      setSelectedEventIds([]);
      setAllEventsSelected(false);
    } else {
      setSelectedEventIds(collectionEventIds.map((id) => Number(id)));
      setAllEventsSelected(true);
    }
  }, [collectionEventIds, selectedEventIds]);

  const value: EventsContextValue = {
    collectionEventIds,
    setCollectionEventIds,
    selectedEventIds,
    selectEvents,
    selectAllEvents,
    allEventsSelected,
    futureEventIds,
    setFutureEventIds,
    selectedFutureEventIds,
  };

  return (
    <CollectionEventsContext.Provider value={value}>
      {children}
    </CollectionEventsContext.Provider>
  );
};

export {
  CollectionEventsContext,
  CollectionEventsProvider,
  type EventsContextValue,
};
