import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import {
  GG, ScaleX, ScaleY, ScaleStroke,
} from '@graphique/graphique';
import { GeomLine } from '@graphique/geom-line';
import { GeomPoint } from '@graphique/geom-point';
import { GeomHLine } from '@graphique/geom-hline';
import { curveStepAfter } from 'd3-shape';
import { Theme } from '#/shared/graphique';
import type { SparklineDatum } from '#/pages/clientReporting/DashboardPage/types';
import type { CumulativeMmt } from '#/types/ReportingRollup';
import LoadingComponent from './LoadingComponent';
import { SALES_METRICS } from '#/shared/reporting/accumulationUtils';
import {
  calcTakeYRange,
  TAKE_ZERO_LINE,
  STROKE_COLORS,
  DELIM,
  formatMonth,
  formatMonthAndDay,
  getNumXTicks,
  isNarrowTimeframe,
} from './utils';

interface SparklineProps extends React.PropsWithChildren {
  data: SparklineDatum[];
  measure: CumulativeMmt;
  loading?: boolean;
  onDatumFocus?: (d: SparklineDatum[], i: number[]) => void;
  onDatumUnfocus?: () => void;
  focusedDatum?: SparklineDatum[];
}

const MARKER_RADIUS = 2.8;

const Sparkline: React.FC<SparklineProps> = ({
  data,
  measure,
  loading,
  onDatumFocus,
  onDatumUnfocus,
  focusedDatum,
  children,
}) => {
  const isTake = useMemo(() => measure === SALES_METRICS.take, [measure]);
  const rangeString = useMemo(() => calcTakeYRange(data, isTake), [data, isTake]);
  const takeExtendedYRange = useMemo(() => (
    rangeString?.split(DELIM)?.map((v) => Number(v))
  ), [rangeString]);
  const numTraces = useMemo(() => new Set(data.map((d) => d.measure)).size, [data]);
  const hasMeasurements = useMemo(() => data?.length > 2 && data?.some((d) => d.value), [data]);

  const dateFormat = useCallback(({ value }: { value: Date }) => {
    if (hasMeasurements) {
      return isNarrowTimeframe(data)
        ? formatMonthAndDay(value)
        : formatMonth(value);
    }
    return '';
  }, [data, hasMeasurements]);

  const pointData = useMemo(() => (
    data ? [data[data.length - 1]] : undefined
  ), [data]);

  return (
    <Container>
      {hasMeasurements && !loading && (
        <GG
          aes={{
            x: (d): Date => d.transactionDate,
            y: (d): number => d.value,
            stroke: (d): string => d.measure,
            key: (d): string => d.measure ?? '',
          }}
          data={data}
          height={110}
          isContainerWidth
          margin={{
            top: 10,
            right: 20,
            bottom: 22,
            left: 20,
          }}
        >
          {isTake && takeExtendedYRange && (
            <>
              <GeomHLine
                data={TAKE_ZERO_LINE}
                showTooltip={false}
                stroke={STROKE_COLORS[0]}
                strokeDasharray='2,2'
              />
              <ScaleY domain={takeExtendedYRange} />
            </>
          )}
          <GeomLine
            curve={curveStepAfter}
            markerRadius={MARKER_RADIUS}
            onDatumFocus={onDatumFocus}
            onExit={onDatumUnfocus}
            strokeOpacity={0.8}
          />
          <ScaleX format={dateFormat} numTicks={getNumXTicks} />
          <ScaleStroke values={STROKE_COLORS.slice(-numTraces)} />
          {!focusedDatum && (
            <GeomPoint
              data={pointData}
              entrance="data"
              fill={STROKE_COLORS[STROKE_COLORS.length - 1]}
              isClipped={false}
              r={MARKER_RADIUS}
              showTooltip={false}
              stroke="#fff"
              strokeWidth={0.5}
            />
          )}
          {children}
          <Theme
            axis={{
              showAxisLines: false,
            }}
            axisY={null}
          />
        </GG>
      )}
      { loading && (
        <LoadingContainer>
          <LoadingComponent height='5px' width='100%' />
        </LoadingContainer>
      )}
    </Container>
  );
};

const LoadingContainer = styled.div`
  margin: 1.5rem 1rem 0 0;
`;

const Container = styled.div`
  width: calc(100% - 130px);
  margin-top: -20px;
  line-height: 1.2;
  letter-spacing: 0;
`;

export default Sparkline;
