import {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { extent, max } from 'd3-array';
import { ExpectedValueBinSnapshotFormatted as EvSnapshot } from '#/types/SelldownByExpectedValue';

interface ZoomDomain {
  x?: any[];
  y?: any[];
}

interface Hook {
  isZoomedOut?: boolean;
  onZoom: (domain: ZoomDomain) => void;
  resetZoom: () => void;
  xZoomDomain: [number, number];
  yZoomDomain: [number, number];
}

const useZoom = (selectedData: EvSnapshot[], showAsTotal: boolean): Hook => {
  const [isZoomedOut, setIsZoomedOut] = useState(true);
  const xDomain = useMemo(() => (
    [0, selectedData && max(selectedData, (d) => d?.logHoursToEvent)]
  ), [selectedData]) as [number, number];
  const yDomain = useMemo(() => (
    selectedData ? extent(
      selectedData, (d) => showAsTotal ? d?.ticketsRemaining : d?.selldownActual,
    ) : [0, 0] as [number, number]
  ), [selectedData, showAsTotal]);

  const [xZoomDomain, setXZoomDomain] = useState<[number, number]>(xDomain);
  const [yZoomDomain, setYZoomDomain] = useState<[number, number]>(yDomain);

  useEffect(() => {
    const selectedDataInXRange = selectedData
      ?.filter((d) => d?.logHoursToEvent >= xZoomDomain[0] && d?.logHoursToEvent <= xZoomDomain[1]);

    const yDomainOfZoomedX = (
      selectedDataInXRange ? extent(
        selectedDataInXRange, (d) => showAsTotal ? d?.ticketsRemaining : d?.selldownActual,
      ) : [0, 0] as [number, number]
    );

    setYZoomDomain(yDomainOfZoomedX);
  }, [selectedData, xZoomDomain, showAsTotal]);

  const onZoom = useCallback((domain: ZoomDomain) => {
    setIsZoomedOut(false);
    setXZoomDomain(domain?.x as [number, number]);
    setYZoomDomain(domain?.y as [number, number]);
  }, []);

  const resetZoom = useCallback(() => {
    setXZoomDomain([0, xDomain[1]]);
    setYZoomDomain(yDomain);
    setIsZoomedOut(true);
  }, [
    setXZoomDomain,
    setYZoomDomain,
    xDomain,
    yDomain,
  ]);

  return {
    isZoomedOut,
    xZoomDomain,
    yZoomDomain,
    onZoom,
    resetZoom,
  };
};

export default useZoom;
