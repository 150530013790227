import React, { useCallback } from 'react';
import {
  InnerWrapper,
  Label,
  HorizontalWrapper,
  Input,
} from '#/shared/modalComponents';
import Separator from './Separator';
import { PostIngestion } from '#/types/Ingestion';

const EXTERNAL_NOTES = 'External Notes';

interface NotesFormProps {
  ingestion: PostIngestion;
  setIngestion: React.Dispatch<React.SetStateAction<PostIngestion>>;
}

const NotesForm: React.FC<NotesFormProps> = ({ ingestion, setIngestion }) => {
  const onChangeNotes = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const external_notes = event.target?.value || null;

    setIngestion((i) => ({ ...i, external_notes }));
  }, [setIngestion]);

  return (
    <>
      <InnerWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{EXTERNAL_NOTES}</Label>
            <Input
              defaultValue={ingestion.external_notes || ''}
              onChange={onChangeNotes}
              type="string"
            />
          </Separator>
        </HorizontalWrapper>
      </InnerWrapper>
    </>
  );
};

export default NotesForm;
