/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useCallback, useMemo,
} from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import {
  SectionWrapper,
  ButtonsWrapper,
  Header,
  InnerWrapper,
  Label,
  Input,
  TextArea,
  Content,
  modalMenuProps,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import formatApiError from '#/api/utils/formatApiError';
import { Option } from '#/pages/useFetchStakeholders';
import Stakeholder, {
  StakeholderType, PostStakeholderBody, FallbackPricingPolicy, PriceConstraintPolicyType,
} from '#/types/Stakeholder';
import postStakeholder from '#/api/postStakeholder';
import Notification from '#/shared/Notification';
import deleteStakeholder from '#/api/deleteStakeholder';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '../useSetupModal';
import { Logo, MainTitle } from '#/shared/clientReporting/typography';
import type { GenericOption } from '#/types/GenericOption';

const CREATE = 'Create Stakeholder';
const UPDATE = 'Update Stakeholder';
const NAME = 'Name';
const DISPLAY_NAME = 'Display Name';
const UPDATED_NAME = 'Updated Name';
const TYPE = 'Type';
const DESCRIPTION = 'Description (Optional)';
const PRIORITY = 'Priority (Optional)';
const PEAKPASS_DOMAIN_IDS = 'PeakPass Domain IDs (Optional, enter IDs separated by commas)';
const PRICE_CONSTRAINT_POLICY = 'Price Constraint Policy';
const PRICE_CONSTRAINT_POLICY_DEFAULT_SCALE = 'Default Price Constraint Scale';
const FALLBACK_PRICING_POLICY = 'Fallback Pricing Policy';
const LOGO_URL = 'Logo URL (Optional)';
const PRICE_CONSTRAINT_POLICIES = [
  { value: PriceConstraintPolicyType.REQUIRE, label: 'Error On Missing Price Constraints' },
  { value: PriceConstraintPolicyType.USE, label: 'Use Price Constraints When Available' },
  { value: PriceConstraintPolicyType.IGNORE, label: 'Ignore All Price Constraints' },
  { value: PriceConstraintPolicyType.DEFAULT, label: 'Require Price Constraints Or Default' },
];
const FALLBACK_PRICING_POLICIES = [
  { value: FallbackPricingPolicy.USE_ON_MISSING_MODELS, label: 'Fallback On Missing Pricing Models' },
  { value: FallbackPricingPolicy.USE_ON_ALL_ERRORS, label: 'Fallback On All Pricing Errors' },
  { value: FallbackPricingPolicy.BLOCK, label: 'Block On All Pricing Errors' },

];
const STAKEHOLDER_TYPES = Object.values(StakeholderType).map((key) => ({
  value: key,
  label: key,
}));
const SUBMIT = 'Submit';
const DELETE = 'Delete';
const CANCEL = 'Cancel';

interface KeyboardInputEvent extends React.KeyboardEvent<HTMLInputElement> {
  target: HTMLInputElement;
}

interface KeyboardTextAreaEvent extends React.KeyboardEvent<HTMLTextAreaElement> {
  target: HTMLTextAreaElement;
}

interface StakeholderModalProps {
  isUpdate: boolean;
  closeModal: () => void;
  stakeholderLabels: Option<string>[];
  stakeholders: Stakeholder[];
}

const StakeholderModal: React.FC<StakeholderModalProps> = ({
  isUpdate,
  closeModal,
  stakeholderLabels,
  stakeholders,
}) => {
  const [initial, setInitial] = useState<Stakeholder | null>(null);
  const [stakeholderPostBody, setStakeholderPostBody] = useState<PostStakeholderBody>({});
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [
    priceConstraintPolicy,
    setPriceConstraintPolicy,
  ] = useState<PriceConstraintPolicyType>(null);

  const logoUrl = useMemo(() => {
    return (
      stakeholderPostBody.clearLogoUrl
        ? undefined
        : (stakeholderPostBody.logoUrl ?? initial?.logoUrl)
    );
  }, [stakeholderPostBody, initial]);

  const title = useMemo(() => {
    return (
      stakeholderPostBody.displayName
      ?? initial?.displayName
      ?? stakeholderPostBody.name
    );
  }, [stakeholderPostBody, initial]);

  const onChangeStakeholder = useCallback((event: Option<string>) => {
    const name = event.value;
    const stakeholder = stakeholders.find((s) => s.name === name);

    setInitial(stakeholder);
    setPriceConstraintPolicy(stakeholder?.priceConstraintPolicy?.type);
    setStakeholderPostBody({ name });
  }, [setStakeholderPostBody, setPriceConstraintPolicy, stakeholders]);

  const onChangeName = useCallback((event: KeyboardInputEvent) => {
    const name = event.target?.value || null;

    setStakeholderPostBody((s: PostStakeholderBody): PostStakeholderBody => (
      { ...s, name }
    ));
  }, [setStakeholderPostBody]);

  const onChangeUpdatedName = useCallback((event: KeyboardInputEvent) => {
    const updatedName = event.target?.value || null;

    setStakeholderPostBody((s: PostStakeholderBody): PostStakeholderBody => (
      { ...s, updatedName }
    ));
  }, [setStakeholderPostBody]);

  const onChangeDisplayName = useCallback((event: KeyboardInputEvent) => {
    const displayName = event.target?.value || null;

    setStakeholderPostBody((s: PostStakeholderBody): PostStakeholderBody => (
      { ...s, displayName }
    ));
  }, [setStakeholderPostBody]);

  const onChangeType = useCallback((event: GenericOption<StakeholderType, StakeholderType>) => {
    const stakeholderType = event.value;

    setStakeholderPostBody((s: PostStakeholderBody): PostStakeholderBody => (
      { ...s, type: stakeholderType }
    ));
  }, [setStakeholderPostBody]);

  const onChangeDescription = useCallback((event: KeyboardTextAreaEvent) => {
    const description = event.target?.value || null;

    setStakeholderPostBody((s: PostStakeholderBody): PostStakeholderBody => (
      { ...s, description }
    ));
  }, [setStakeholderPostBody]);

  const onChangePriority = useCallback((event: KeyboardInputEvent) => {
    const priority = Number(event.target?.value) || null;

    setStakeholderPostBody((s: PostStakeholderBody): PostStakeholderBody => (
      { ...s, priority }
    ));
  }, [setStakeholderPostBody]);

  const onChangePricingPolicy = useCallback(
    (event: GenericOption<string, PriceConstraintPolicyType>) => {
      const policyType = (event?.value ?? undefined);

      setPriceConstraintPolicy(policyType);
      setStakeholderPostBody((s: PostStakeholderBody): PostStakeholderBody => (
        {
          ...s,
          priceConstraintPolicy: policyType ? {
            type: policyType,
            defaultScale: initial?.priceConstraintPolicy?.defaultScale ?? 1.0,
          } : undefined,
        }
      ));
    }, [setStakeholderPostBody, setPriceConstraintPolicy, initial],
  );

  const onChangePricingPolicyDefaultScale = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value;

      if (typeof value === 'string') {
        const newDefaultScale = value === '' ? null : Number(value);

        setStakeholderPostBody((p) => ({
          ...p,
          priceConstraintPolicy: {
            ...initial?.priceConstraintPolicy,
            ...p.priceConstraintPolicy,
            defaultScale: newDefaultScale,
          },
        }));
      }
    }, [initial, setStakeholderPostBody],
  );

  const onChangeFallbackPolicy = useCallback(
    (event: GenericOption<string, FallbackPricingPolicy>) => {
      const fallbackPricingPolicy = event.value;

      setStakeholderPostBody((s: PostStakeholderBody): PostStakeholderBody => (
        { ...s, fallbackPricingPolicy }
      ));
    }, [setStakeholderPostBody],
  );

  const convertStringListToList = (myString: string): string[] => {
    return myString ? myString.split(',') : [];
  };

  const onChangeDomainIds = useCallback((event: KeyboardInputEvent) => {
    const peakpassDomainIds = convertStringListToList(event.target?.value || null);

    setStakeholderPostBody((s: PostStakeholderBody): PostStakeholderBody => (
      { ...s, peakpassDomainIds }
    ));
  }, [setStakeholderPostBody]);

  const onChangeLogoUrl = useCallback((event: KeyboardInputEvent) => {
    const newLogoUrl = event.target?.value || null;
    const clearLogoUrl = newLogoUrl ? undefined : true;

    setStakeholderPostBody((s: PostStakeholderBody): PostStakeholderBody => (
      { ...s, logoUrl: newLogoUrl, clearLogoUrl }
    ));
  }, [setStakeholderPostBody]);

  const cancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const submitPostStakeholder = useCallback(async () => {
    try {
      setError('');
      const newStakeholder = await postStakeholder(stakeholderPostBody);

      if (newStakeholder.id) {
        setSuccess(true);
        setTimeout(closeModal, 1000);
      }
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [closeModal, stakeholderPostBody]);

  const deleteCurrentStakeholder = useCallback(async () => {
    try {
      setError('');
      const newStakeholder = await deleteStakeholder(initial.id);

      if (newStakeholder.id) {
        setSuccess(true);
        setTimeout(closeModal, 1000);
      }
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [closeModal, initial]);

  const deleteButtonDisabled = useMemo(() => {
    return (initial === null) || (Object.keys(stakeholderPostBody).length > 1);
  }, [initial, stakeholderPostBody]);

  useSetupModal(cancel);

  return (
    <Modal
      isOpen
      onRequestClose={cancel}
      style={modalStyles}
    >
      <Header>{isUpdate ? UPDATE : CREATE}</Header>
      <Notification error={error} success={success} />
      <SectionWrapper>
        <Content>
          {
            isUpdate
              ? (
                <InnerWrapper>
                  <Label>{NAME}</Label>
                  <Select
                    onChange={onChangeStakeholder}
                    options={stakeholderLabels}
                    {...modalMenuProps}
                  />
                </InnerWrapper>
              )
              : (
                <InnerWrapper>
                  <Label>{NAME}</Label>
                  <Input onKeyUp={onChangeName} type="string" />
                </InnerWrapper>
              )
          }
          <InnerWrapper>
            <Label>{DISPLAY_NAME}</Label>
            <Input
              defaultValue={initial?.displayName}
              onKeyUp={onChangeDisplayName}
              type="string"
            />
          </InnerWrapper>
          {
            isUpdate && (
              <InnerWrapper>
                <Label>{UPDATED_NAME}</Label>
                <Input
                  defaultValue={initial?.name}
                  onKeyUp={onChangeUpdatedName}
                  type="string"
                />
              </InnerWrapper>
            )
          }
          <InnerWrapper>
            <Label>{TYPE}</Label>
            <Select
              onChange={onChangeType}
              options={STAKEHOLDER_TYPES}
              value={
                STAKEHOLDER_TYPES
                  .find((type) => type.value === (
                    stakeholderPostBody?.type
                    || initial?.type as string
                  ))
              }
              {...modalMenuProps}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{PRICE_CONSTRAINT_POLICY}</Label>
            <Select
              onChange={onChangePricingPolicy}
              options={PRICE_CONSTRAINT_POLICIES}
              value={
                PRICE_CONSTRAINT_POLICIES
                  .find((policy) => policy.value === priceConstraintPolicy)
              }
              {...modalMenuProps}
            />
          </InnerWrapper>
          {priceConstraintPolicy === PriceConstraintPolicyType.DEFAULT && (
            <InnerWrapper>
              <Label>{PRICE_CONSTRAINT_POLICY_DEFAULT_SCALE}</Label>
              <Input
                defaultValue={initial?.priceConstraintPolicy?.defaultScale ?? 1.0}
                onChange={onChangePricingPolicyDefaultScale}
                placeholder='Default Scale'
                type='number'
              />
            </InnerWrapper>
          )}
          <InnerWrapper>
            <Label>{FALLBACK_PRICING_POLICY}</Label>
            <Select
              onChange={onChangeFallbackPolicy}
              options={FALLBACK_PRICING_POLICIES}
              value={
                FALLBACK_PRICING_POLICIES
                  .find((policy) => policy.value === (
                    stakeholderPostBody?.fallbackPricingPolicy
                    || initial?.fallbackPricingPolicy as string
                  ))
              }
              {...modalMenuProps}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{DESCRIPTION}</Label>
            <TextArea
              defaultValue={initial?.description}
              onKeyUp={onChangeDescription}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{PRIORITY}</Label>
            <Input
              defaultValue={initial?.priority}
              onKeyUp={onChangePriority}
              type="number"
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{PEAKPASS_DOMAIN_IDS}</Label>
            <Input
              defaultValue={initial?.peakpassDomainIds?.join(',')}
              onKeyUp={onChangeDomainIds}
              type="string"
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{LOGO_URL}</Label>
            <Input
              defaultValue={initial?.logoUrl}
              onKeyUp={onChangeLogoUrl}
              type="string"
            />
            {logoUrl && (
              <MainTitle>
                <Logo src={logoUrl} />
                {`${title} Ticketing`}
              </MainTitle>
            )}
          </InnerWrapper>
        </Content>
        <ButtonsWrapper>
          <Button onClick={submitPostStakeholder}>{SUBMIT}</Button>
          <Button onClick={cancel}>{CANCEL}</Button>
          {
            isUpdate
              && (
                <Button
                  disabled={deleteButtonDisabled}
                  onClick={deleteCurrentStakeholder}
                >
                  {DELETE}
                </Button>
              )
          }
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

export default StakeholderModal;
export { PRICE_CONSTRAINT_POLICIES };
