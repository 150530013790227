import React, { useCallback, useContext } from 'react';
import { Tooltip as GGTooltip } from '@graphique/graphique';
import { generateContent } from '../OverlaidSalesChart/Tooltip';
import { CollectionSelectionsContext } from '../contexts';
import { VIEWPORT_SMALL_PX } from '../constants';
import { useWindowWidth } from '@react-hook/window-size';

interface Props {
  dx?: number;
  xFormat?: (d: unknown) => string;
}

const Tooltip: React.FC<Props> = ({ dx, xFormat }) => {
  const { metricSelection } = useContext(CollectionSelectionsContext);
  const windowWidth = useWindowWidth();
  const maxWidth = windowWidth < VIEWPORT_SMALL_PX ? 220 : 350;

  const measureFormat = useCallback((d: number) => {
    if (typeof d === 'undefined')
      return undefined;

    return metricSelection?.format ? metricSelection.format({ value: d }) : d?.toLocaleString();
  }, [metricSelection]);

  return (
    <GGTooltip
      content={generateContent(metricSelection, maxWidth, 'fill')}
      dx={dx}
      measureFormat={measureFormat}
      xFormat={xFormat}
    />
  );
};

export default Tooltip;
