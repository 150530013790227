import { useContext, useEffect, useState } from 'react';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import formatApiError from '#/api/utils/formatApiError';
import getSectionRowPriceConstraints from '#/api/getSectionRowPriceConstraints';
import { SectionRowPriceConstraint } from '#/types/PriceConstraints';

interface PriceConstraints {
  sectionRowPriceConstraints: SectionRowPriceConstraint[];
}

type Hook = () => {
  error?: string;
  priceConstraints: PriceConstraints;
};

const useFetchPriceConstraints: Hook = () => {
  const { activeEvent } = useContext(ActiveEventContext);
  const [error, setError] = useState<string | undefined>();
  const [priceConstraints, setPriceConstraints] = useState<PriceConstraints>(null);
  const id = activeEvent?.id;

  useEffect(() => {
    const fetchPriceConstraints = async (): Promise<void> => {
      try {
        const sectionRowPriceConstraints = await getSectionRowPriceConstraints(id);

        setPriceConstraints({
          sectionRowPriceConstraints,
        });
      } catch (err) {
        const errorString = formatApiError(err);

        setError(errorString);
      }
    };

    if (id)
      fetchPriceConstraints();
  }, [id, activeEvent]);

  return {
    error,
    priceConstraints,
  };
};

export default useFetchPriceConstraints;
