import palette from '#/theme/palettes/main';
import { MarketplaceInfo } from '../../types';

const marketplaces: MarketplaceInfo = {
  seatgeek: {
    displayName: 'SeatGeek',
    color: palette.brand.base,
  },
  stubhub: {
    displayName: 'StubHub',
    color: '#3F1D74',
  },
  'vivid seats': {
    displayName: 'Vivid Seats',
    color: '#04092C',
  },
  ticketmaster: {
    displayName: 'Ticketmaster',
    color: '#016CDF',
  },
  gametime: {
    displayName: 'Gametime',
    color: '#8B8B90',
  },
  tickpick: {
    displayName: 'TickPick',
    color: '#32A5FD',
  },
  ticketnetwork: {
    displayName: 'TicketNetwork',
    color: '#1c3f94',
  },
  viagogo: {
    displayName: 'Viagogo',
    color: '#719A3D',
  },
  'ticket evolution': {
    displayName: 'TEVO',
    color: '#FFC20E',
  },
  gotickets: {
    displayName: 'GoTickets',
    color: '#645188',
  },
  'box office': {
    displayName: 'Box Office',
    color: '#550000',
  },
};

export default marketplaces;
