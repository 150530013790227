import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import Download from '#/shared/clientReporting/Icons/Download';
import ExportModal from '#/pages/clientReporting/DashboardPage/components/ExportModal';

const EXPORT = 'Export';

const DataExport: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toggleModal = useCallback(() => setModalIsOpen((prev) => !prev), []);
  const toggleLoading = useCallback(() => setIsLoading((prev) => !prev), []);

  return (
    <Wrapper>
      <SmallButton
        Icon={<Download />}
        isLoading={isLoading}
        kind='secondary'
        label={EXPORT}
        onClick={toggleModal}
      />
      <ExportModal
        isOpen={modalIsOpen}
        onExportComplete={toggleLoading}
        onExportStart={toggleLoading}
        onRequestClose={toggleModal}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-left: 0.3rem;
  border-left: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}88`};
  padding: 0 0.3rem 0 0.6rem;
`;

export default DataExport;
