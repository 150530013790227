import {
  OverrideType, ScaleOverrideType, SetOverrideType, ShiftOverrideType,
} from '#/types/Override';
import { CSSProperties } from 'react';

const TOP_OFFSET_PX = 48;
const SIDEBAR_WIDTH_PX = 380;
const OVERRIDES_MENU_WIDTH_PX = 240;
const VIEWPORT_SMALL_PX = 1100;
const VIEWPORT_MOBILE_PX = 768;
const PAGE_TITLE = 'Event Collection';
const COLLECTION_SUMMARY_HEIGHT = '2.15rem';
const DEFAULT_TIME_INTERVAL = '1 days';
const DEFAULT_LOG_HOURS_TO_EVENT_INTERVAL = 0.2;

const toastStyles: CSSProperties = {
  letterSpacing: 0,
  padding: '0.6rem 1rem',
  fontWeight: 550,
};

enum ToastId {
  OVERRIDES_STATUS = 'overrides-status',
  REFRESHING_COLLECTION = 'refreshing-collection',
}

enum ToastMsg {
  LOADING_OVERRIDES = 'Applying overrides',
  SUCCESS_OVERRIDES = 'Overrides applied',
  ERROR_OVERRIDES = 'Error applying overrides',
  LOADING_COLLECTION = 'Refreshing collection',
}

const SelldownPreviewOverrides: (
  SetOverrideType | ShiftOverrideType | ScaleOverrideType | OverrideType
)[] = [
  OverrideType.BIAS,
  OverrideType.MIN_OFFSET,
  OverrideType.MAX_OFFSET,
  OverrideType.AGGRESSIVENESS,
  OverrideType.ADDITIONAL_TICKETS,
  OverrideType.SELLOUT_BY_SELLDOWN,
  OverrideType.MAX_ELASTICITY,
];

const MIN_SCALE_VALUE = 0;
const MAX_SCALE_VALUE = 3;

enum Tab {
  TRENDS = 'Trends',
  MAP = 'Map',
  TIME_BINS = 'Time Bins',
}

export {
  TOP_OFFSET_PX, SIDEBAR_WIDTH_PX, OVERRIDES_MENU_WIDTH_PX, VIEWPORT_SMALL_PX, VIEWPORT_MOBILE_PX,
  PAGE_TITLE, COLLECTION_SUMMARY_HEIGHT, DEFAULT_TIME_INTERVAL,
  DEFAULT_LOG_HOURS_TO_EVENT_INTERVAL, toastStyles,
  ToastId, ToastMsg, SelldownPreviewOverrides, MIN_SCALE_VALUE, MAX_SCALE_VALUE, Tab,
};
