import React from 'react';
import TableWrapper, { Cell, Header } from '#/shared/TableComponents';
import { EventConfig, SalesPeriod, SalesPeriodType } from '#/types/Event';
import { Overrides, OverrideType } from '#/types/Override';
import SalesPeriodRow, { COLUMNS } from './SalesPeriodRow';

interface SalesPeriodTableProps {
  config: EventConfig;
  periods: SalesPeriod[];
  overrides?: Overrides;
}

const SalesPeriodTable: React.FC<SalesPeriodTableProps> = ({ config, periods, overrides }) => {
  // create a sales period for the onsale period and combine it with the rest
  // of the periods for display
  const onsale = configToSalesPeriod(config, overrides);
  const sortedPeriods = [...periods, onsale]
    .sort((a, b) => a.startsAt.diff(b.startsAt).milliseconds);

  return (
    <TableWrapper>
      <thead>
        <Header>
          {COLUMNS.map((col) => (
            <Cell key={`column_${col}`}>{col}</Cell>
          ))}
        </Header>
      </thead>
      <tbody>
        {sortedPeriods.map((period) => (
          <SalesPeriodRow
            key={period.id}
            period={period}
            seatgeekEventId={config.seatgeekEventId}
            timezone={config.timezone}
          />
        ))}
      </tbody>
    </TableWrapper>
  );
};

function configToSalesPeriod(config: EventConfig, overrides?: Overrides): SalesPeriod {
  return {
    id: 0,
    type: SalesPeriodType.ONSALE,
    startsAt: config.onsaleStartsAt,
    endsAt: config.eventStartsAt,
    demandPrior: (
      overrides
        ?.parameters
        ?.filter((p) => p.type === OverrideType.DEMAND_PRIOR)[0]
        ?.value
      || null
    ),
    accessCodes: [],
    createdAt: config.createdAt,
    updatedAt: config.updatedAt,
  };
}

export default SalesPeriodTable;
