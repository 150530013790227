import styled from 'styled-components';

const MainTitle = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header2};
  padding: 3.5rem 0.25rem 2rem 0;
  line-height: 1.2;
  min-width: 300px;
`;

const ReportingTitle = styled(MainTitle)`
  display: flex;
  align-items: center;
  padding: 2rem 0 1rem 0;
`;

const SectionTitleContainer = styled.div`
  display: flex;
  height: 24px;
  margin-bottom: 10px;
  align-items: center;
  letter-spacing: 0;
`;

const SectionTitle = styled.h3`
  font-size: 22px;
  white-space: nowrap;
  font-weight: ${({ theme }: { theme: Theme }): string => theme.text1semibold};
  margin: 0 12px 0 0;
`;

const Logo = styled.img`
  height: 2em;
  min-width: 2em;
  padding: 0.1em;
  border: 1px solid rgb(0 0 0 / 8%);
  border-radius: 8px;
  margin-right: 1rem;
`;

export {
  MainTitle, ReportingTitle, SectionTitleContainer, SectionTitle, Logo,
};
