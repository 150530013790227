/* eslint-disable import/max-dependencies */
import React from 'react';
import styled from 'styled-components';

import { PeakpassPackageType } from '#/types/Listing';
import { Level, Override as PriceLevelOverride, Summary } from '#/types/PriceLevel';
import Peakpass from '#images/Peakpass.svg';
import PriceConstraint from '#images/PriceConstraint.svg';
import Override from '#images/Override.svg';
import PendingOverride from '#images/PendingOverride.svg';
import Vip from '#images/Vip.svg';
import Bundle from '#images/Bundle.svg';
import Aisle from '#images/Aisle.svg';
import Prime from '#images/Prime.svg';

const PACKAGE_TYPE_IMAGE = {
  [PeakpassPackageType.VIP]: Vip,
  [PeakpassPackageType.BUNDLE]: Bundle,
  [PeakpassPackageType.AISLE]: Aisle,
  [PeakpassPackageType.PRIME]: Prime,
};

interface PriceLevelIconsProps {
  level: Level;
  summary: Summary;
  stakeholderImages: string[];
  override?: PriceLevelOverride;
}

const PriceLevelIcons: React.FC<PriceLevelIconsProps> = ({
  level, summary, override, stakeholderImages,
}) => {
  const { peakpassPackageTypes } = summary;

  return (
    <>
      {
        stakeholderImages.map((img) => (
          <OffsetImage
            alt="stakeholder"
            height="25"
            key={img}
            src={img}
          />
        ))
      }
      {
        level.sourceName === 'peakpass' && (
          <OffsetImage
            alt="peakpass"
            height="25"
            src={Peakpass}
            title="Peakpass source"
          />
        )
      }
      {
        peakpassPackageTypes.map((pt) => (
          <OffsetImage
            alt="package-type"
            height="25"
            key={pt}
            src={PACKAGE_TYPE_IMAGE[pt]}
            title={`${pt} package type`}
          />
        ))
      }
      {
        override?.priceConstraint && (
          <OffsetImage
            alt="constraint"
            height="25"
            src={PriceConstraint}
            title="Has Price Constraint"
          />
        )
      }
      {
        override && override.isActive && !override.isEmpty && (
          <OffsetImage
            alt="override"
            height="25"
            src={Override}
            title="Has Override"
          />
        )
      }
      {
        override && !override.isActive && (
          <OffsetImage
            alt="pending-override"
            height="25"
            src={PendingOverride}
            title="Pending Override"
          />
        )
      }
    </>
  );
};

const OffsetImage = styled.img`
  padding-left: 5px;
  padding-bottom: 3px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  object-fit: cover;
  object-position: center;
`;

export default PriceLevelIcons;
