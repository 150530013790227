import { useState, useCallback } from 'react';
import SeatGeek from '#images/seatgeek.png';
import StubHub from '#images/stubhub.png';
import VividSeats from '#images/vividseats.png';

enum PriceDistroViewMode {
  SG_LISTINGS = 'SeatGeek listings',
  SH_LISTINGS = 'StubHub listings',
  VIVID_LISTINGS = 'Vivid Seats listings',
  SG_SALES = 'SeatGeek sales',
}

interface ViewMode {
  key: string;
  label: string;
  imgSrc?: string;
}

const PRICE_DISTRO_VIEW_OPTIONS: ViewMode[] = [
  { key: 'seatgeek', label: PriceDistroViewMode.SG_LISTINGS, imgSrc: SeatGeek },
  { key: 'stubhub', label: PriceDistroViewMode.SH_LISTINGS, imgSrc: StubHub },
  { key: 'vivid seats', label: PriceDistroViewMode.VIVID_LISTINGS, imgSrc: VividSeats },
  { key: 'seatgeekSales', label: PriceDistroViewMode.SG_SALES, imgSrc: SeatGeek },
];

interface Hook {
  viewSelection: PriceDistroViewMode;
  handleViewSelection: (name?: PriceDistroViewMode) => void;
}

const useViewSelection = (): Hook => {
  const [viewSelection, setViewSelection] = useState(PriceDistroViewMode.SG_LISTINGS);

  const handleViewSelection = useCallback((name?: PriceDistroViewMode) => {
    setViewSelection(name);
  }, []);

  return {
    viewSelection,
    handleViewSelection,
  };
};

export default useViewSelection;
export { PRICE_DISTRO_VIEW_OPTIONS, PriceDistroViewMode };
