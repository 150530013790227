import React from 'react';
import Button from '#/shared/Button';

const DELETE = 'Delete';

interface DeleteButtonProps {
  handleDelete: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ handleDelete }) => {
  return <Button onClick={handleDelete}>{DELETE}</Button>;
};

export default DeleteButton;
