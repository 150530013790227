import React, { useCallback } from 'react';
import Action, { ConfigType, EventType } from '../types';
import { FormWrapper, Label } from './formComponents';

interface CheckboxFormProps {
  label: string;
  type: ConfigType | EventType;
  handleInputChange: React.Dispatch<Action>;
  value: boolean;
}

const CheckboxForm: React.FC<CheckboxFormProps> = ({
  label,
  type,
  handleInputChange,
  value,
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = event.target.checked;

      handleInputChange({ type, value: newVal });
    },
    [type, handleInputChange],
  );

  return (
    <FormWrapper>
      <Label htmlFor={type}>{`${label}:`}</Label>
      <input defaultChecked={value} onChange={handleChange} type='checkbox' />
    </FormWrapper>
  );
};

export default CheckboxForm;
