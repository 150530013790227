import React, {
  MouseEventHandler, useCallback, useEffect, useState,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Configuration from '../Configuration';
import Pricing from '../Pricing';
import Summary from '../Summary';
import Ingestions from '../Ingestions';
import Sales from '../Sales';
import Listings from '../Listings';
import PriceConstraints from '../PriceConstraints';
import Navigation, { TabLabel, getTabLabel } from '../Navigation';
import { User } from '#/types/User';
import Troubleshooting from '../Troubleshooting';
import OverrideLogs from '../OverrideLogs';
import PriceLevels from '../PriceLevels';
import Overrides from '../Overrides';
import parseHashedUrlWithParams from '../Pricing/MainGraph/parseHashedUrlWithParams';
import { FetchGroupsHook } from '#/pages/useFetchGroups';
import { StakeholderLogos } from '#/pages/useFetchStakeholders';

const SLIDER_SPACE_MINUS_STATIC_ELEMENTS = '30rem';

interface TabsProps {
  user: User;
  showError: boolean;
  showLoader: boolean;
  sliderIsOpen: boolean;
  toggleOpen: MouseEventHandler<HTMLButtonElement>;
  groupsHook: FetchGroupsHook;
  stakeholderLogos: StakeholderLogos;
}

const Tabs: React.FC<TabsProps> = ({
  user,
  showError,
  showLoader,
  sliderIsOpen,
  toggleOpen,
  groupsHook,
  stakeholderLogos,
}) => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [parsedHash] = parseHashedUrlWithParams(hash);

  const isValidHash = Object.values(TabLabel).includes(parsedHash as TabLabel);
  const [activeTab, _setActiveTab] = useState(getTabLabel(parsedHash) || TabLabel.PRICING);

  const setActiveTab = useCallback((tab: TabLabel) => {
    // update the url hash when the active tab changes
    navigate({
      hash: `#${tab}`,
    });
    _setActiveTab(tab);
  }, [navigate, _setActiveTab]);

  useEffect(() => {
    // update the active tab if the url hash changes
    if (parsedHash && isValidHash) {
      const currentTab = getTabLabel(`#${parsedHash}`);

      if (activeTab !== currentTab)
        _setActiveTab(currentTab);
    }
  }, [parsedHash, _setActiveTab, activeTab, isValidHash]);

  return (
    <Page sliderIsOpen={sliderIsOpen}>
      <Navigation
        activeTab={activeTab}
        groupsHook={groupsHook}
        setActiveTab={setActiveTab}
        toggleOpen={toggleOpen}
        user={user}
      />
      <ScrollBody>
        {activeTab === TabLabel.PRICING && <Pricing user={user} />}
        {activeTab === TabLabel.CONFIGURATION && (
          <Configuration
            error={showError}
            groupsHook={groupsHook}
            loading={showLoader}
            user={user}
          />
        )}
        {activeTab === TabLabel.SUMMARY && (
          <Summary error={showError} loading={showLoader} user={user} />
        )}
        {activeTab === TabLabel.INGESTIONS && (
          <Ingestions stakeholderLogos={stakeholderLogos} user={user} />
        )}
        {activeTab === TabLabel.LISTINGS && (
          <Listings
            error={showError}
            loading={showLoader}
            stakeholderLogos={stakeholderLogos}
            user={user}
          />
        )}
        {activeTab === TabLabel.PRICE_LEVELS && (
          <PriceLevels
            error={showError}
            loading={showLoader}
            stakeholderLogos={stakeholderLogos}
            user={user}
          />
        )}
        {activeTab === TabLabel.SALES && (
          <Sales
            error={showError}
            loading={showLoader}
            stakeholderLogos={stakeholderLogos}
            user={user}
          />
        )}
        {activeTab === TabLabel.PRICE_CONSTRAINTS && (
          <PriceConstraints />
        )}
        {activeTab === TabLabel.TROUBLESHOOTING && (
          <Troubleshooting user={user} />
        )}
        {activeTab === TabLabel.UPDATES && (
          <OverrideLogs user={user} />
        )}
        {activeTab === TabLabel.OVERRIDES && (
          <Overrides />
        )}
      </ScrollBody>
    </Page>
  );
};

interface SlideProps {
  sliderIsOpen: boolean;
}

const Page = styled.div<SlideProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.75s ease;
  ${({ sliderIsOpen }): string => {
    if (sliderIsOpen)
      return `padding: 0 3.75rem 0 ${SLIDER_SPACE_MINUS_STATIC_ELEMENTS};`;

    return '';
  }};
`;

const ScrollBody = styled.div`
  position: absolute;
  width: 100%;
  top: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
  `;

export default Tabs;
