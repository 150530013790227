import React from 'react';
import styled from 'styled-components';
import Select, { type GroupBase, type OptionsOrGroups } from 'react-select';
import { FormWrapper, Label } from './formComponents';
import { modalMenuProps } from '#/shared/modalComponents';

interface Option {
  id?: number;
  label: string;
  value: string;
}

type OnChangeCallback = (input: Option | Option[]) => void | React.Dispatch<Option>;

interface SelectProps {
  error?: string;
  defaultValue?: unknown;
  isMulti?: boolean;
  label?: string;
  options: OptionsOrGroups<unknown, GroupBase<unknown>>;
  onChange: OnChangeCallback;
  isClearable?: boolean;
}

const ZERO = 0;

const SelectForm: React.FC<SelectProps> = ({
  error,
  defaultValue,
  label,
  options,
  onChange,
  isMulti,
  isClearable,
}) => {
  if (options.length > ZERO) {
    return (
      <FormWrapper>
        <Label htmlFor={label}>{`${label}:`}</Label>
        <Wrapper>
          <Select
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...modalMenuProps}
            defaultValue={defaultValue}
            isClearable={isClearable}
            isMulti={isMulti}
            onChange={onChange}
            options={options}
          />
        </Wrapper>
      </FormWrapper>
    );
  }
  if (error)
    return <div>{error}</div>;
  return <div />;
};

const Wrapper = styled.div`
  width: 15.5rem;
`;

export { Option };
export default SelectForm;
