import React, { useState } from 'react';
import { Tab } from '../constants';

interface SelectionsContextValue {
  activeTab: Tab;
  setActiveTab: React.Dispatch<React.SetStateAction<Tab>>;
}

const CollectionSelectionsContext = React.createContext<SelectionsContextValue>(null);

const CollectionSelectionsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(Tab.TRENDS);

  const value: SelectionsContextValue = {
    activeTab,
    setActiveTab,
  };

  return (
    <CollectionSelectionsContext.Provider value={value}>
      {children}
    </CollectionSelectionsContext.Provider>
  );
};

export {
  type SelectionsContextValue,
  CollectionSelectionsContext,
  CollectionSelectionsProvider,
};
