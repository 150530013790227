import React from 'react';
import styled from 'styled-components';
import Oneticket from '#images/Oneticket.svg';
import Ticketvault from '#images/Ticketvault.svg';
import Consumer from '#images/ConsumerUploader.svg';
import Peakpass from '#images/Peakpass.svg';
import type { RollupEventPartition, RollupGroups } from '#/types/ReportingRollup';

interface Props {
  event: RollupGroups
  isCondensed?: boolean
  className?: string
}

const EventPartition: React.FC<{ partition: RollupEventPartition, isCondensed?: boolean }> = (
  { partition, isCondensed },
) => {
  const { sectionSubset, sources } = partition;

  let subset = ' All Sections';
  const sections = sectionSubset.sections.join(', ');
  const plural = sectionSubset.sections.length > 1 ? 's' : '';

  if (sectionSubset.type === 'negative')
    subset = ` All Section${plural}, Except: ${sections}`;
  else if (sectionSubset.type === 'positive')
    subset = ` Only Section${plural}: ${sections}`;

  return (
    <Sources isCondensed={isCondensed}>
      {sources.map((source: string) => (
        <Image
          key={source}
          src={sourceImage(source)}
          title={source}
        />
      ))}
      {subset}
    </Sources>
  );
};

const sourceImage = (source: string): string => {
  if (source === 'oneticket')
    return Oneticket;
  if (source === 'consumer')
    return Consumer;
  if (source === 'peakpass')
    return Peakpass;

  return Ticketvault;
};

const EventDetails: React.FC<Props> = ({ event, isCondensed, className }) => (
  <Wrapper className={className}>
    <Title isCondensed={isCondensed}>
      {event.eventTitle}
    </Title>
    {event.eventVenue && (
      <Detail isCondensed={isCondensed}>
        {event.eventVenue}
      </Detail>
    )}
    {event.eventStartsAtLocal && (
      <StartTime isCondensed={isCondensed}>
        {event.eventStartsAtLocal.toLocaleString({
          year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit',
        })}
      </StartTime>
    )}
    {event.eventPartition && (
      <EventPartition isCondensed={isCondensed} partition={event.eventPartition} />
    )}
  </Wrapper>
);

interface CondensedProps {
  isCondensed?: boolean
}

const Wrapper = styled.div`
  text-align: left;
`;

const Title = styled.div<CondensedProps>`
  font-size: ${({ isCondensed }): string => isCondensed ? '0.9rem' : '0.95rem'};
  font-weight: 600;
  line-height: ${({ isCondensed }): number => isCondensed ? 1.1 : 1.3};
`;

const Detail = styled.div<CondensedProps>`
  margin-top: 0.25rem;
  font-size: ${({ isCondensed }): string => isCondensed ? '0.875rem' : '0.9rem'};
  line-height: ${({ isCondensed }): number => isCondensed ? 1.1 : 1.38};
  opacity: ${({ isCondensed }): number => isCondensed ? 0.7 : 1};
`;

const Sources = styled(Detail as any)`
  margin-left: -2px;
  color: #666;
  font-size: 0.8rem;
`;

const Image = styled.img`
  height: 16px;
  margin-bottom: -3px;
  margin-right: 3px;
`;

const StartTime = styled(Detail as any)`
  color: #666;
`;

export default EventDetails;
export { EventPartition };
