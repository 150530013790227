import { DateTime } from 'luxon';
import { EventTxSummary } from '../../types';
import { Rollup, RollupGroups, RollupAggregate } from '#/types/ReportingRollup';

const checkHasFaceValues = ({
  ingestedFvTicketCt,
  ingestedTicketCt,
}: RollupAggregate): boolean => (
  ingestedFvTicketCt > 0 && ingestedFvTicketCt === ingestedTicketCt
);

const checkEventInPast = ({ eventStartsAt }: RollupGroups): boolean => {
  return (
    eventStartsAt.toUTC() < DateTime.now().toUTC()
  );
};

const createEventTransactionSummary = (eventRollup: Rollup[]): EventTxSummary[] => (
  eventRollup.map(({ aggregate, group }) => {
    let liftWithSpoilage;

    if (!checkHasFaceValues(aggregate))
      liftWithSpoilage = undefined;
    else if (checkEventInPast(group))
      liftWithSpoilage = aggregate.saleRevenue - aggregate.ingestedFaceValue;
    else
      liftWithSpoilage = 0;

    return (
      {
        seatgeekEventId: group.seatgeekEventId,
        event: group.eventTitle ?? `SeatGeek Event ID: ${group.seatgeekEventId}`,
        venue: group.eventVenue,
        eventDate: group?.eventStartsAtLocal,
        ticketsSold: aggregate.saleTicketCt,
        managedInventory: aggregate.ingestedTicketCt,
        revenue: aggregate.saleRevenue,
        cost: aggregate.ingestedCost,
        liftOfSold: checkHasFaceValues(aggregate)
          ? (aggregate.saleRevenue - aggregate.saleFaceValue)
          : undefined,
        liftWithSpoilage,
        isEventInFuture: !checkEventInPast(group),
      }
    );
  })
);

const filterEventsBySearch = (eventSummary: EventTxSummary[], search: string):
EventTxSummary[] => {
  if (!eventSummary || !search || search === '')
    return eventSummary;

  return eventSummary?.filter(({ event }) => {
    const eventText = event?.toLowerCase();

    return eventText?.includes(search);
  });
};

export { filterEventsBySearch, createEventTransactionSummary, checkHasFaceValues };
