import { EventFilters } from '#/types/Event';

function queryToEventFilters(search: string): EventFilters {
  const query = new URLSearchParams(search);
  const filters: EventFilters = {};

  if (query.has('autobroker_event_id'))
    filters.autobroker_event_id = Number(query.get('autobroker_event_id'));

  if (query.has('seatgeek_event_id'))
    filters.seatgeek_event_id = Number(query.get('seatgeek_event_id'));

  if (query.has('query'))
    filters.query = String(query.get('query'));

  if (query.has('is_scheduled'))
    filters.is_scheduled = Number(query.get('is_scheduled'));

  if (query.has('is_active'))
    filters.is_active = Number(query.get('is_active'));

  if (query.has('has_error'))
    filters.has_error = Number(query.get('has_error'));

  if (query.has('source_name'))
    filters.source_name = String(query.get('source_name'));

  if (query.has('source_external_event_id'))
    filters.source_external_event_id = String(query.get('source_external_event_id'));

  if (query.has('sink_name'))
    filters.sink_name = String(query.get('sink_name'));

  if (query.has('sink_external_event_id'))
    filters.sink_external_event_id = String(query.get('sink_external_event_id'));

  if (query.has('event_type'))
    filters.event_type = String(query.get('event_type'));

  if (query.has('error_type'))
    filters.error_type = String(query.get('error_type'));

  if (query.has('group'))
    filters.group = String(query.get('group'));

  if (query.has('from_date'))
    filters.from_date = String(query.get('from_date'));

  if (query.has('to_date'))
    filters.to_date = String(query.get('to_date'));

  if (query.has('from_id'))
    filters.from_id = Number(query.get('from_id'));

  if (query.has('per_page'))
    filters.per_page = Number(query.get('per_page'));

  if (query.has('is_include_qa_only'))
    filters.is_include_qa_only = Number(query.get('is_include_qa_only'));

  return filters;
}

export default queryToEventFilters;
