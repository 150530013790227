import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { OverrideChange, OverrideChangeLogEntry, OverrideChangeType } from '#/types/Override';
import DeletedIcon from '../DeletedIcon';
import { WhiteSpaceValue } from '#/shared/Card';

enum LogColumns {
  TYPE = 'type',
  CHANGES = 'changes',
  NOTES = 'notes',
  USER = 'user',
  CREATED_AT = 'createdAt',
  IS_DELETED = 'isDeleted',
  GROUPS = 'groups',
}

type TableCell = ((row: OverrideChangeLogEntry) => React.ReactNode) | undefined;

const formatColName = (column: string): string => (
  column.split(/(?=[A-Z])/).map((c) => `${c[0].toUpperCase()}${c.slice(1)}`).join(' ')
);
const formatType = (type: OverrideChangeType): string => (
  type.split('_').map((c) => `${c[0].toUpperCase()}${c.slice(1)}`).join(' ')
);

const roundNumericChange = (val: string): string => {
  const coercedNumber = Number(val);

  if (Number.isNaN(coercedNumber))
    return val;

  return coercedNumber.toLocaleString();
};

const formatChanges = (changes: OverrideChange[]): string => (
  changes.map((c) => `${c.field} = ${roundNumericChange(c.value)}`).join('\n')
);
const formatCreatedAt = (createdAt: DateTime): string => (
  createdAt.toRelative() + '\n' + createdAt.toLocaleString(DateTime.DATETIME_SHORT)
);

const formatTableCell = (key: string): TableCell => {
  if (key === LogColumns.TYPE) {
    return (row: OverrideChangeLogEntry): string => (
      formatType(row.type)
    );
  }

  if (key === LogColumns.USER)
    return (row: OverrideChangeLogEntry): string => row.user?.name;

  if (key === LogColumns.CREATED_AT) {
    return (row: OverrideChangeLogEntry): string => (
      row.createdAt ? formatCreatedAt(row.createdAt)
        : ''
    );
  }

  return undefined;
};

const renderTableCell = (key: string): TableCell => {
  if (key === LogColumns.IS_DELETED) {
    return (row: OverrideChangeLogEntry): React.ReactNode => (
      <DeletedIcon isDeleted={row.isDeleted} />
    );
  }
  if (key === LogColumns.CHANGES) {
    return (row: OverrideChangeLogEntry): React.ReactNode => (
      <FormattedWhiteSpace data-tag='allowRowEvents'>{formatChanges(row.changes)}</FormattedWhiteSpace>
    );
  }
  if (key === LogColumns.CREATED_AT) {
    return (row: OverrideChangeLogEntry): React.ReactNode => (
      <FormattedWhiteSpace data-tag='allowRowEvents'>{formatCreatedAt(row.createdAt)}</FormattedWhiteSpace>
    );
  }
  return undefined;
};

const growTableCell = (key: string): number => {
  if (key === LogColumns.CHANGES)
    return 2.0;
  if (key === LogColumns.NOTES)
    return 1.5;
  return undefined;
};

const FormattedWhiteSpace = styled(WhiteSpaceValue as any)`
  ${({ theme }: { theme: Theme }): string => theme.text4};
`;

export {
  LogColumns,
  formatTableCell,
  formatColName,
  growTableCell,
  formatType,
  formatChanges,
  formatCreatedAt,
  renderTableCell,
};
