import { DateTime } from 'luxon';

const determineEventIsActive = (supervisorStopsAt: DateTime): boolean => {
  if (!supervisorStopsAt)
    return false;

  const NOW = 0;

  const untilSupervisorEnd = supervisorStopsAt.diffNow().milliseconds;
  const isActive = untilSupervisorEnd > NOW;

  return isActive;
};

export default determineEventIsActive;
