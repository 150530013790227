const palette = [
  '#002762',
  '#F26907',
  '#53810E',
  '#1C71EF',
  '#FDBF2D',
  '#84CAE3',
];

export default palette;
