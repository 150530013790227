import client from './_client';

import { Overrides, PostListingOverride } from '#/types/Override';

type APICall = (
  eventId: number,
  listings: PostListingOverride[],
  notes: string,
) => Promise<void>;

const postListingOverride: APICall = async (eventId, listings, notes) => {
  const postBody: Overrides = {
    autobroker_event_id: eventId,
    listings,
    notes,
  };

  await client.post('/proxy/overrides', postBody);
};

export default postListingOverride;
