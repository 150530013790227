import {
  ListingsGraphData,
  SelldownGraphData,
  GraphDomain,
  GraphArray,
  GraphPlot,
} from '../../types/Snapshot';

type GraphData = ListingsGraphData | SelldownGraphData;

const calcGraphDomain = (graphData: GraphData): GraphDomain => {
  const MIN = 0;
  const MAX = 1;
  const graphDomain = { x: [MIN, MAX], y: [MIN, MAX] };

  if (!graphData)
    return graphDomain;

  const datapoints = Object.keys(graphData);

  datapoints.forEach((el: keyof GraphData): void => {
    const plotArray = graphData[el] as GraphArray;

    plotArray.forEach((xySet: GraphPlot) => {
      graphDomain.y[0] = Math.min(graphDomain.y[0], xySet.y);
      graphDomain.y[1] = Math.max(graphDomain.y[1], xySet.y);
      graphDomain.x[0] = Math.min(graphDomain.x[0], xySet.x);
      graphDomain.x[1] = Math.max(graphDomain.x[1], xySet.x);
    });
  });

  return graphDomain;
};

export default calcGraphDomain;
