import React, { useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import { OverrideType, Settings } from '#/types/Override';
import Buttons from './Buttons';
import GraphControl from './GraphControl';
import Action from '../../types';
import { EventError, EventErrorType } from '#/types/Errors';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';

interface GraphControlsProps {
  disabled: boolean;
  parameters: Settings;
  saveInProgress: boolean;
  saveError: string;
  settings: Settings;
  updateSetting: React.Dispatch<Action>;
  resetSettings: () => void;
  saveSettings: (notes?: string) => void;
  getPreviewOverlay: (
    settings: Settings,
    type: OverrideType,
    value: number
  ) => Promise<void>;
}

const PARAMETER = 'Parameter';
const OVERRIDE = 'Override';
const CURRENT = 'Current';
const NOTES = 'Notes';

const GraphControls: React.FC<GraphControlsProps> = ({
  disabled,
  parameters,
  settings,
  updateSetting,
  resetSettings,
  saveSettings,
  saveInProgress,
  saveError,
  getPreviewOverlay,
}) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const notesRef = useRef<HTMLInputElement>(null);

  const saveHandler = useCallback(() => {
    const notesString = notesRef?.current?.value;

    saveSettings(notesString || undefined);
    notesRef.current.value = '';
  }, [saveSettings]);

  const resetHandler = useCallback(() => {
    notesRef.current.value = '';
    resetSettings();
  }, [resetSettings]);

  return (
    <BoundingBox>
      <Header>
        <Parameter>{PARAMETER}</Parameter>
        <Override>{OVERRIDE}</Override>
        <Current>{CURRENT}</Current>
      </Header>

      <Sliders>
        {Object.values(OverrideType).map((type) => {
          const value = settings[type];
          const parameterValue = parameters[type];

          return (
            <GraphControl
              disabled={disabled}
              error={getErrorForOverride(type, activeEvent?.errors?.event)}
              getPreviewOverlay={getPreviewOverlay}
              key={type}
              parameterValue={parameterValue}
              settings={settings}
              type={type}
              updateSetting={updateSetting}
              value={value}
            />
          );
        })}
      </Sliders>
      <NotesHeader>
        <Label>{NOTES}</Label>
        <Input disabled={disabled} ref={notesRef} type="text" />
      </NotesHeader>
      {!disabled && (
        <Buttons
          resetSettings={resetHandler}
          saveError={saveError}
          saveInProgress={saveInProgress}
          saveSettings={saveHandler}
        />
      )}
    </BoundingBox>
  );
};

const getErrorForOverride = (
  type: OverrideType, errors: EventError[] | undefined,
): string | undefined => {
  if (!errors)
    return undefined;

  const errorType = getErrorTypeForOverride(type);

  return errors.find((error) => error.errorType === errorType)?.errorReason;
};

const getErrorTypeForOverride = (type: OverrideType): EventErrorType | undefined => {
  switch (type) {
    case OverrideType.MIN_OFFSET:
      return EventErrorType.PRICES_AT_BOUNDARY_ERROR;
    case OverrideType.MAX_OFFSET:
      return EventErrorType.PRICES_AT_BOUNDARY_ERROR;
    case OverrideType.MAX_ELASTICITY:
      return EventErrorType.PRICES_AT_MAX_ELASTICITY_ERROR;
    default:
      return undefined;
  }
};

const Header = styled.div`
  width: 100%;
  display: flex;
  ${({ theme }: { theme: Theme }): string => theme.text3};
`;

const Parameter = styled.p`
  min-width: 11.25rem;
`;
const Override = styled.p`
  margin-right: 0.75rem;
`;
const Current = styled.p`
  width: 2.5rem;
`;

const Sliders = styled.div`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.secondary};
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const BoundingBox = styled.div`
  margin-right: 2rem;
  width: 20rem;
`;

const Label = styled.div`
  margin-right: 2rem;
  color: ${({ theme } : { theme: Theme }): string => theme.color.text.primary};
`;

const NotesHeader = styled.div`
  width: 100%;
  height: 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }: { theme: Theme }): string => theme.text3};
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.secondary};
`;

const Input = styled.input`
  ${({ theme }: { theme: Theme }): string => theme.text4};
  width: 25rem;
  height: 1.5rem;
  text-align: center;
  margin-right: 0.75rem;
  border: 1px solid
    ${({ theme }: { theme: Theme }): string => theme.color.border.primary};
  border-radius: 6px;
  transition: all 0.2s ease;
  &:focus {
    outline: none;
    outline-offset: none;
    border-color: ${({ theme }: { theme: Theme }): string => theme.color.border.selected};
  }
`;

export default GraphControls;
