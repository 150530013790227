import styled, { keyframes } from 'styled-components';
import palette from '#/theme/palettes/main';

const rotate = keyframes`
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`;

const Loader = styled.div<{size?: number; hexColor?: string; thickness?: number; margin?: string | number}>`
    &:after {
        border-radius: 50%;
        width: ${({ size }): number => size}em;
        height: ${({ size }): number => size}em;
    }
    border-radius: 50%;
    width: ${({ size }): number => size}em;
    height: ${({ size }): number => size}em;

    margin: ${({ margin }): string | number => margin};
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: ${({ thickness }): number => thickness}em solid ${({ hexColor }): string => hexColor}33;
    border-right: ${({ thickness }): number => thickness}em solid ${({ hexColor }): string => hexColor}33;
    border-bottom: ${({ thickness }): number => thickness}em solid ${({ hexColor }): string => hexColor}33;
    border-left: ${({ thickness }): number => thickness}em solid ${({ hexColor }): string => hexColor};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: ${rotate} 1.1s infinite linear;
    animation: ${rotate} 1.1s infinite linear;
`;

Loader.defaultProps = {
  hexColor: palette.brand.base,
  margin: 'auto',
  size: 10,
  thickness: 1,
};

export default Loader;
