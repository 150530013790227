import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import TableWrapper, {
  Cell,
  Header,
  RowWrapper,
} from '#/shared/TableComponents';
import Button from '#/shared/Button';
import { EventFilters } from '#/types/Event';
import { EventErrorType } from '#/types/Errors';
import { SOURCES } from '#/types/Transaction';
import palette from '#/theme/palettes/main';
import { Checkbox } from '#/shared/Filters';
import type { FetchGroupsHook, GroupOption } from '#/pages/useFetchGroups';
import type { GenericOption } from '#/types/GenericOption';

const SEARCH = 'Search';
const COLUMNS = [
  'SG ID',
  'Query',
  'From Date',
  'To Date',
  'Group',
  'Source',
  'Error Type',
  'Active',
  'In QA',
  'Per Page',
];
const ERROR_TYPE_OPTIONS = Object.values(EventErrorType).map((type) => ({ value: type, label: type.split('_').join(' ') }));

interface Option<Type> {
  value: Type;
  label: string;
}

function getOption<Type>(value: Type, options: Option<Type>[]): Option<Type> {
  const subset = options.filter((option) => option.value === value);

  if (subset.length > 0)
    return subset[0];

  if (value !== null && typeof value !== 'undefined')
    return { value, label: value.toString() };

  return null;
}

interface FilterProps {
  filters: EventFilters;
  setFilters: (filters: EventFilters) => void;
  groupsHook: FetchGroupsHook;
}

const Filters: React.FC<FilterProps> = ({
  filters,
  setFilters,
  groupsHook,
}) => {
  const [input, setInput] = useState(filters);
  const { groupLabels, setDefaultGroup } = groupsHook;

  const sourceOptions = Object.values(SOURCES).map((source) => ({ label: source, value: source }));

  const setSeatGeekEventId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, seatgeek_event_id: Number(event.target.value) || null });
  },
  [input]);
  const setQuery = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, query: event?.target.value || null });
  },
  [input]);
  const setFromDate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, from_date: event?.target.value || null });
  },
  [input]);
  const setToDate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, to_date: event?.target.value || null });
  },
  [input]);
  const setGroup = useCallback((event: GroupOption) => {
    const group = event?.value;

    if (group)
      setDefaultGroup(group);

    setInput({ ...input, group });
  },
  [input, setDefaultGroup]);
  const setSource = useCallback((event: GenericOption<SOURCES, SOURCES>) => {
    setInput({ ...input, source_name: event?.value });
  },
  [input]);
  const setErrorType = useCallback((event: GenericOption<string, string>) => {
    setInput({ ...input, error_type: event?.value });
  },
  [input]);
  const setIsActive = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, is_active: event?.target.checked ? 1 : 0 });
  },
  [input]);
  const setPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, per_page: Number(event.target.value) });
  },
  [input]);
  const setIsIncludeQaOnly = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, is_include_qa_only: event?.target.checked ? 1 : 0 });
  },
  [input]);
  const handleSubmit = useCallback(() => {
    setFilters(input);
  }, [input, setFilters]);
  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter')
      handleSubmit();
  }, [handleSubmit]);

  return (
    <Form onKeyDown={onKeyDown}>
      <TableWrapper>
        <thead>
          <Header>
            {COLUMNS.map((column) => (
              <Cell key={column}>{column}</Cell>
            ))}
          </Header>
        </thead>
        <tbody>
          <RowWrapper>
            <Cell>
              <SmallInput
                defaultValue={filters.seatgeek_event_id}
                onChange={setSeatGeekEventId}
                type='number'
              />
            </Cell>
            <Cell>
              <LargeInput
                defaultValue={filters.query}
                onChange={setQuery}
                type='string'
              />
            </Cell>
            <Cell>
              <LargeInput
                defaultValue={filters.from_date}
                onChange={setFromDate}
                type='date'
              />
            </Cell>
            <Cell>
              <LargeInput
                defaultValue={filters.to_date}
                onChange={setToDate}
                type='date'
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.group, groupLabels)}
                onChange={setGroup}
                options={groupLabels}
                width='8rem'
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.source_name, sourceOptions)}
                onChange={setSource}
                options={sourceOptions}
                width='8rem'
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.error_type, ERROR_TYPE_OPTIONS)}
                onChange={setErrorType}
                options={ERROR_TYPE_OPTIONS}
                width='8rem'
              />
            </Cell>
            <Cell>
              <Checkbox
                defaultChecked={filters.is_active === 1}
                onChange={setIsActive}
                type='checkbox'
              />
            </Cell>
            <Cell>
              <Checkbox
                defaultChecked={filters.is_include_qa_only === 1}
                onChange={setIsIncludeQaOnly}
                type='checkbox'
              />
            </Cell>
            <Cell>
              <SmallInput
                defaultValue={filters.per_page}
                onChange={setPerPage}
                type='number'
              />
            </Cell>
            <Cell>
              <Button onClick={handleSubmit}>{SEARCH}</Button>
            </Cell>
          </RowWrapper>
        </tbody>
      </TableWrapper>
    </Form>
  );
};

interface FilterSelectProps {
  defaultValue: any;
  onChange: (value: any) => void;
  options: Option<any>[];
  width: string;
}

const FilterSelect: React.FC<FilterSelectProps> = ({
  defaultValue,
  onChange,
  options,
  width = '9rem',
}) => {
  return (
    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    <div style={{ width }}>
      <Select
        defaultValue={defaultValue}
        isClearable
        isMulti={false}
        menuPlacement="auto"
        menuPosition="fixed"
        onChange={onChange}
        options={options}
      />
    </div>
  );
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SmallInput = styled.input`
  height: 1.8rem;
  width: 3rem;
  border: 1px solid ${palette.silver.dark};
  border-radius: .3rem;
  padding: 4px;
  font-size: 14px;
`;

const LargeInput = styled.input`
  height: 1.8rem;
  width: 6.5rem;
  border: 1px solid ${palette.silver.dark};
  border-radius: .3rem;
  padding: 4px;
  font-size: 14px;
`;

export default Filters;
