import React from 'react';

import PriceLevel from '#/types/PriceLevel';
import PriceLevelDetailHeader from './PriceLevelDetailHeader';
import SourceCard from './SourceCard';
import StakeholderCard from './StakeholderCard';
import OverrideCard from './OverrideCard';
import ListingsCard from './ListingsCard';
import SectionsCard from './SectionsCard';
import PriceCard from './PriceCard';
import ExpectedValueCard from './ExpectedValueCard';
import NotesCard from './NotesCard';
import { Wrapper, Section } from '#/shared/Detail';

const OVERRIDE_NOTES = 'Override Notes';

interface PriceLevelDetailProps {
  priceLevel: PriceLevel;
  setOverridePriceLevelModal: (priceLevels: PriceLevel[]) => void;
  closeDetail: () => void;
  closeable: boolean;
  canEdit: boolean;
  showPrices: boolean;
}

const PriceLevelDetail: React.FC<PriceLevelDetailProps> = ({
  priceLevel,
  setOverridePriceLevelModal,
  closeDetail,
  closeable,
  canEdit,
  showPrices,
}) => {
  return (
    <Wrapper>
      <Section>
        <PriceLevelDetailHeader
          canEdit={canEdit}
          closeDetail={closeDetail}
          closeable={closeable}
          priceLevel={priceLevel}
          setOverridePriceLevelModal={setOverridePriceLevelModal}
        />
      </Section>
      <Section>
        <SourceCard priceLevel={priceLevel} />
        <SectionsCard priceLevel={priceLevel} />
        <ListingsCard priceLevel={priceLevel} />
        <StakeholderCard priceLevel={priceLevel} />
        {showPrices && <ExpectedValueCard priceLevel={priceLevel} />}
        {showPrices && <PriceCard priceLevel={priceLevel} />}
        {priceLevel.override && (!priceLevel.override.isActive || !priceLevel.override.isEmpty) && (
          <>
            <OverrideCard override={priceLevel.override} />
            {priceLevel.override.notes && (
              <NotesCard header={OVERRIDE_NOTES} notes={priceLevel.override.notes} />
            )}
          </>
        )}
      </Section>
    </Wrapper>
  );
};

export default PriceLevelDetail;
