import React, { useEffect, useState } from 'react';
import PageWrapper from '#/shared/PageWrapper';
import Page from '#/shared/Page';
import { User } from '#/types/User';
import getLogout from '#/api/getLogout';
import ScrollBody from '#/shared/ScrollBody';
import UnauthenticatedHeader from '#/shared/UnauthenticatedHeader';

interface LoggedOutPageProps {
  user: User;
}

const LogoutPage: React.FC<LoggedOutPageProps> = ({ user }) => {
  const [isLoggedOut, setIsLoggedOut] = useState(user === null);
  const loggedOutMessage = 'You are logged out.';
  const loggingOutMessage = 'Logging out...';

  useEffect(() => {
    if (user === null) {
      setIsLoggedOut(true);
    } else {
      getLogout().then(() => {
        setIsLoggedOut(true);
      });
    }
  }, [user]);

  return (
    <PageWrapper>
      <Page>
        <UnauthenticatedHeader />
        <ScrollBody>
          { isLoggedOut ? <div>{ loggedOutMessage }</div> : <div>{ loggingOutMessage }</div> }
        </ScrollBody>
      </Page>
    </PageWrapper>
  );
};

export default LogoutPage;
