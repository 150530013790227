import React, { useCallback } from 'react';
import Action, { EventType } from '../types';
import { SELLDOWN_PROJECTION_MODEL_OPTIONS, SUPPORTS_ONSALE_DEMAND_FRACTION, TrainingSetType } from '#/types/Event';
import SelectForm from '../Forms/SelectForm';
import type { GenericOption } from '#/types/GenericOption';

interface SelectProps {
  handleInputChange: React.Dispatch<Action>;
  label: string;
}

const SelectSelldownProjectionModel: React.FC<SelectProps> = ({
  handleInputChange,
  label,
}) => {
  const handleChange = useCallback(
    (option: GenericOption<string, TrainingSetType>) => {
      const value = option?.value || null;

      handleInputChange({ type: EventType.SELLDOWN_PROJECTION_MODEL, value });
      if (!SUPPORTS_ONSALE_DEMAND_FRACTION.includes(value))
        handleInputChange({ type: EventType.ONSALE_DEMAND_FRACTION, value: null });
    },
    [handleInputChange],
  );

  return (
    <SelectForm
      isClearable
      label={label}
      onChange={handleChange}
      options={SELLDOWN_PROJECTION_MODEL_OPTIONS}
    />
  );
};

export default SelectSelldownProjectionModel;
