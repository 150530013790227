import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import TableWrapper, {
  Cell,
  Header,
  RowWrapper,
} from '#/shared/TableComponents';
import Button from '#/shared/Button';
import Ingestion, {
  IngestionFilters, InputListingErrorSubset, INGESTION_STATES, Uploader,
  IngestionState,
} from '#/types/Ingestion';
import type { GenericOption } from '#/types/GenericOption';
import useFetchStakeholders, { type Option as StakeholderOption } from '#/pages/useFetchStakeholders';
import {
  Form, SmallInput, LargeInput, getOption, FilterSelect, Checkbox,
} from '#/shared/Filters';

const SEARCH = 'Search';
const COLUMNS = [
  'ID',
  'SG ID',
  'From Date',
  'To Date',
  'Uploader',
  'Stakeholder',
  'State',
  'Condition',
  'Pin',
  'Per Page',
];

enum Condition {
  EVER_PRICED = 'Ever Priced',
  NEVER_PRICED = 'Never Priced',
  HAS_ERROR = 'Has Error',
  NO_ERROR = 'No Error',
  PRICING_ERROR = 'Pricing Error',
  BROADCAST_ERROR = 'Broadcast Error',
  IN_HAND = 'In Hand',
  NOT_IN_HAND = 'Not In Hand',
  FALLBACK = 'Uses Fallback Pricing',
  NOT_FALLBACK = 'Doesn\'t Use Fallback Pricing',
  IS_LISTED = 'Currently Listed',
  RESERVED = 'Reserved',
  NOT_RESERVED = 'Not Reserved',
}

const CONDITIONS = [
  Condition.NEVER_PRICED,
  Condition.EVER_PRICED,
  Condition.HAS_ERROR,
  Condition.NO_ERROR,
  Condition.PRICING_ERROR,
  Condition.BROADCAST_ERROR,
  Condition.IN_HAND,
  Condition.NOT_IN_HAND,
  Condition.FALLBACK,
  Condition.NOT_FALLBACK,
  Condition.IS_LISTED,
  Condition.RESERVED,
  Condition.NOT_RESERVED,
];

function getCondition(filters: IngestionFilters): Condition {
  if (filters.has_error !== null && typeof filters.has_error !== 'undefined') {
    if (filters.error_subset === InputListingErrorSubset.PRICING)
      return filters.has_error ? Condition.PRICING_ERROR : null;
    if (filters.error_subset === InputListingErrorSubset.BROADCAST)
      return filters.has_error ? Condition.BROADCAST_ERROR : null;

    return filters.has_error ? Condition.HAS_ERROR : Condition.NO_ERROR;
  }

  if (filters.is_priced !== null && typeof filters.is_priced !== 'undefined')
    return filters.is_priced ? Condition.EVER_PRICED : Condition.NEVER_PRICED;

  if (filters.in_hand !== null && typeof filters.in_hand !== 'undefined')
    return filters.in_hand ? Condition.IN_HAND : Condition.NOT_IN_HAND;

  if (filters.uses_fallback !== null && typeof filters.uses_fallback !== 'undefined')
    return filters.uses_fallback ? Condition.FALLBACK : Condition.NOT_FALLBACK;

  if (filters.is_listed !== null && typeof filters.is_listed !== 'undefined')
    return filters.is_listed ? Condition.IS_LISTED : null;

  if (filters.is_reserved !== null && typeof filters.is_reserved !== 'undefined')
    return filters.is_reserved ? Condition.RESERVED : Condition.NOT_RESERVED;

  return null;
}

function applyConditionFilters(i: IngestionFilters, condition: Condition): IngestionFilters {
  const j: IngestionFilters = {
    ...i,
    is_priced: undefined,
    has_error: undefined,
    error_subset: undefined,
    in_hand: undefined,
    uses_fallback: undefined,
    is_listed: undefined,
    is_reserved: undefined,
  };

  switch (condition) {
    case Condition.EVER_PRICED:
      return { ...j, is_priced: 1 };
    case Condition.NEVER_PRICED:
      return { ...j, is_priced: 0 };
    case Condition.HAS_ERROR:
      return { ...j, has_error: 1 };
    case Condition.PRICING_ERROR:
      return { ...j, has_error: 1, error_subset: InputListingErrorSubset.PRICING };
    case Condition.BROADCAST_ERROR:
      return { ...j, has_error: 1, error_subset: InputListingErrorSubset.BROADCAST };
    case Condition.NO_ERROR:
      return { ...j, has_error: 0 };
    case Condition.IN_HAND:
      return { ...j, in_hand: 1 };
    case Condition.NOT_IN_HAND:
      return { ...j, in_hand: 0 };
    case Condition.FALLBACK:
      return { ...j, uses_fallback: 1 };
    case Condition.NOT_FALLBACK:
      return { ...j, uses_fallback: 0 };
    case Condition.IS_LISTED:
      return { ...j, is_listed: 1 };
    case Condition.RESERVED:
      return { ...j, is_reserved: 1 };
    case Condition.NOT_RESERVED:
      return { ...j, is_reserved: 0 };
    default:
      return j;
  }
}

interface FilterProps {
  filters: IngestionFilters;
  setFilters: (filters: IngestionFilters) => void;
  pinnedIngestions: Ingestion[];
  setPinnedIngestions: (ingestions: Ingestion[]) => void;
}

const Filters: React.FC<FilterProps> = ({
  filters,
  setFilters,
  pinnedIngestions,
  setPinnedIngestions,
}) => {
  const [input, setInput] = useState(filters);
  const { stakeholderLabels } = useFetchStakeholders();
  const stateOptions = INGESTION_STATES.map((state) => ({ value: state, label: state }));
  const conditionOptions = CONDITIONS.map((condition) => ({ value: condition, label: condition }));
  const uploaderOptions = Object.values(Uploader).map(
    (uploader) => ({ label: uploader, value: uploader }),
  );

  const setId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(event?.target?.value) || null;

    setInput((i) => ({ ...i, id }));
  },
  [setInput]);

  const setSeatGeekEventId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const seatgeek_event_id = Number(event?.target?.value) || null;

    setInput((i) => ({ ...i, seatgeek_event_id }));
  },
  [setInput]);

  const setFromDate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const from_date = event?.target?.value || null;

    setInput((i) => ({ ...i, from_date }));
  },
  [setInput]);

  const setToDate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const to_date = event?.target?.value || null;

    setInput((i) => ({ ...i, to_date }));
  },
  [setInput]);

  const setUploader = useCallback((event: GenericOption<Uploader, Uploader>) => {
    const uploader = event?.value || null;

    setInput((i) => ({ ...i, uploader }));
  },
  [setInput]);
  const setStakeholder = useCallback((event: StakeholderOption<string>) => {
    const stakeholder = event?.value || null;

    setInput((i) => ({ ...i, stakeholder }));
  },
  [setInput]);

  const setState = useCallback((event: GenericOption<IngestionState, IngestionState>) => {
    const state = event?.value || null;

    setInput((i) => ({ ...i, state }));
  },
  [setInput]);

  const setCondition = useCallback((event: GenericOption<Condition, Condition>) => {
    const condition = event?.value || null;

    setInput((i) => applyConditionFilters(i, condition));
  },
  [setInput]);

  const setPinned = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const is_pinned = !!event?.target?.checked;

    setInput((i) => ({ ...i, is_pinned }));
  }, [setInput]);

  const setPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const per_page = Number(event?.target?.value) || null;

    setInput((i) => ({ ...i, per_page }));
  },
  [setInput]);

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    setPinnedIngestions(pinnedIngestions);
    setFilters({
      ...input, sort_by: filters.sort_by, sort_asc: filters.sort_asc,
    });
  }, [filters.sort_asc, filters.sort_by, input, setFilters, setPinnedIngestions, pinnedIngestions]);

  return (
    <Form onSubmit={handleSubmit}>
      <TableWrapper>
        <thead>
          <Header>
            {COLUMNS.map((column) => (
              <Cell key={column}>{column}</Cell>
            ))}
          </Header>
        </thead>
        <tbody>
          <RowWrapper>
            <Cell>
              <SmallInput
                defaultValue={filters.id}
                onChange={setId}
                type='number'
              />
            </Cell>
            <Cell>
              <SmallInput
                defaultValue={filters.seatgeek_event_id}
                onChange={setSeatGeekEventId}
                type='number'
              />
            </Cell>
            <Cell>
              <MediumInput
                defaultValue={filters.from_date}
                onChange={setFromDate}
                type='date'
              />
            </Cell>
            <Cell>
              <MediumInput
                defaultValue={filters.to_date}
                onChange={setToDate}
                type='date'
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.uploader, uploaderOptions)}
                onChange={setUploader}
                options={uploaderOptions}
                width="8rem"
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.stakeholder, stakeholderLabels)}
                onChange={setStakeholder}
                options={stakeholderLabels}
                width="8rem"
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.state, stateOptions)}
                onChange={setState}
                options={stateOptions}
                width="8rem"
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(getCondition(filters), conditionOptions)}
                onChange={setCondition}
                options={conditionOptions}
                width="8rem"
              />
            </Cell>
            <Cell>
              <Checkbox
                defaultChecked={filters.is_pinned}
                onChange={setPinned}
                type='checkbox'
              />
            </Cell>
            <Cell>
              <SmallInput
                defaultValue={filters.per_page}
                onChange={setPerPage}
                type='number'
              />
            </Cell>
            <Cell>
              <Button type="submit">{SEARCH}</Button>
            </Cell>
          </RowWrapper>
        </tbody>
      </TableWrapper>
    </Form>
  );
};

const MediumInput = styled(LargeInput as any)`
  width: 6.5rem;
`;

export default Filters;
export {
  Condition,
  CONDITIONS,
  getCondition,
  applyConditionFilters,
};
