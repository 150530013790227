import Modal from 'react-modal';
import type { CSSObjectWithLabel } from 'react-select';

const modalStyles = {
  content: {
    ...Modal.defaultStyles.content,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10000,
    border: 'none',
    padding: '2rem',
    width: '90vw',
    maxWidth: 500,
    maxHeight: '80%',
    borderRadius: '0.75rem',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 1rem 1.5rem',
    letterSpacing: 0,
  },
  overlay: {
    ...Modal.defaultStyles.overlay,
    zIndex: 9999,
    backgroundColor: 'rgba(25, 25, 25, 0.7)',
  },
};

const menuStyles = {
  menuPortal: (base: CSSObjectWithLabel): CSSObjectWithLabel => (
    { ...base, zIndex: 9999 }
  ),
};

export { menuStyles };
export default modalStyles;
