import { DateTime } from 'luxon';

const formatDateForForm = (date: DateTime): string => {
  const ONE = 1;
  const ZERO = 0;

  if (!date)
    return '';

  const dateString = date.toISO({
    suppressSeconds: true,
    suppressMilliseconds: true,
    includeOffset: false,
  });

  const length = dateString.length - ONE;

  // toISO() returns a string with 'Z' on the end that the
  // html5 standard datepicker does not understand
  const lastChar = dateString[length];

  return lastChar === 'Z' ? dateString.substr(ZERO, length) : dateString;
};

export default formatDateForForm;
