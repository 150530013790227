import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import type {
  PostRollupBody,
  RollupPreviewResponse,
} from '#/types/ReportingRollup';
import redirectUnauthorizedToAuth from './utils/redirectUnauthorizedToAuth';

interface APICall {
  status: number;
  message?: string;
}

const postBulkOverrides = async ({ overrides, group }: PostRollupBody): Promise<APICall> => {
  try {
    const response: AxiosResponse<RollupPreviewResponse> = (
      await client.post<PostRollupBody, AxiosResponse<RollupPreviewResponse>, PostRollupBody>(
        '/proxy/overrides/bulk',
        {
          overrides,
          group,
        },
      )
    );

    return { status: response.status };
  } catch (err) {
    redirectUnauthorizedToAuth(err);
    throw new Error(err);
  }
};

export type { APICall };
export default postBulkOverrides;
