import React from 'react';
import {
  NarrowWrapper, Element, Name, ScrollBox,
} from '#/shared/Card';
import { InputListingOverride } from '#/types/Ingestion';
import { PriceGranularity } from '#/types/Event';
import { formatCurrency } from './formatNumberForDisplay';

const OVERRIDE = 'Override';
const PENDING = 'Pending';
const DELETED = 'Deleted';
const GRANULARITY_LABEL = {
  [PriceGranularity.PRICE_LEVEL]: 'Price Level',
  [PriceGranularity.INGESTION]: 'Ingestion',
  [PriceGranularity.ROW]: 'Row',
  [PriceGranularity.SECTION]: 'Section',
  [PriceGranularity.LISTING]: 'Listing',
  [PriceGranularity.EVENT]: 'Event',
};

interface CardProps {
  override: InputListingOverride;
}

const InputListingOverrideCard: React.FC<CardProps> = ({ override }) => {
  const {
    section, row, mapkey, expected_value, all_in_price,
    display_price, broadcast_price, price_constraint,
    is_visible, user_name, created_at, is_active, is_empty,
    granularity,
  } = override;

  return (
    <NarrowWrapper>
      <Element>
        <Name>
          {
            (is_active ? '' : `${PENDING} `)
            + `${GRANULARITY_LABEL[granularity as PriceGranularity]} ${OVERRIDE}`
          }
        </Name>
      </Element>
      <ScrollBox>
        {is_empty && <Name>{DELETED}</Name>}
        {section && <Override title="Section" value={section} />}
        {row && <Override title="Row" value={row} />}
        {mapkey && <Override title="MapKey" value={mapkey} />}
        {expected_value && <Override title="Expected Value" value={`${formatCurrency(expected_value)}`} />}
        {price_constraint && (
          <Override
            value={
              (price_constraint.price_floor ? `${formatCurrency(price_constraint.price_floor)} ≤ ` : '')
              + price_constraint.price_constraint_type.split('_').join(' ')
              + (price_constraint.price_ceiling ? ` ≤ ${formatCurrency(price_constraint.price_ceiling)}` : '')
            }
          />
        )}
        {typeof (is_visible) === 'boolean' && <Override value={is_visible ? 'Visible' : 'Not Visible'} />}
        {all_in_price && <Override title="All In Price" value={`${formatCurrency(all_in_price)}`} />}
        {display_price && <Override title="Display Price" value={`${formatCurrency(display_price)}`} />}
        {broadcast_price && <Override title="Broadcast Price" value={`${formatCurrency(broadcast_price)}`} />}
      </ScrollBox>
      <Element>
        <Name>{`- ${user_name}`}</Name>
      </Element>
      <Element>
        <Name>{`Created ${created_at.toRelative()}`}</Name>
      </Element>
    </NarrowWrapper>
  );
};

interface OverrideProps {
  title?: string;
  value: string;
}

const Override: React.FC<OverrideProps> = ({ title, value }) => {
  return (
    <Name>{title ? `${title}: ${value}` : value}</Name>
  );
};

export default InputListingOverrideCard;
