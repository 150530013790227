import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { IngestionCsvFile } from '#/types/Ingestion';

const CSV = 'CSV';
const UPLOADED_BY = 'Uploaded by';
const UPLOADED_AT = 'Uploaded';

interface CardProps {
  csv: IngestionCsvFile;
}

const IngestionCsvCard: React.FC<CardProps> = ({ csv }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{CSV}</Name>
        <Value>
          <a
            href={`/ingestions?ingestion_csv_file_id=${csv.id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {csv.id}
          </a>
        </Value>
      </Element>
      <Element>
        <Name>{UPLOADED_BY}</Name>
        <Value>{csv.user_name}</Value>
      </Element>
      <Element>
        <Name>{UPLOADED_AT}</Name>
        <Value>{csv.created_at.toRelative()}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default IngestionCsvCard;
