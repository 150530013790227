import { OverrideOperation, type RollupOverrideParams } from '#/types/ReportingRollup';

const overridesReducer = (
  state: RollupOverrideParams[],
  override: RollupOverrideParams | null,
): RollupOverrideParams[] => {
  if (override) {
    const otherOverrides = state.filter((d) => d?.type !== override.type);

    return [...otherOverrides, override]
      ?.filter((d) => (
        (d?.operation === OverrideOperation.CLEAR)
        || (!Number.isNaN(d?.value) && d?.value !== null)
      ));
  }

  return [];
};

export default overridesReducer;
