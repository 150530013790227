import type { GroupBase, OptionsOrGroups } from 'react-select';

interface DropdownFormOption {
  label: string;
  value: string | number;
}

enum FilterField {
  INVENTORY_SOURCE_TYPE = 'inventorySourceType',
  QUANTITY = 'quantity',
  SELLER_ID = 'sellerId',
}

type Options = OptionsOrGroups<DropdownFormOption, GroupBase<DropdownFormOption>>;

export { FilterField, Options };

export default DropdownFormOption;
