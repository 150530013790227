import client from './_client';
import { PriceExploration } from '#/types/Event';

type APICall = (
  priceExploration: PriceExploration,
  autobrokerEventId: number
) => Promise<number>;

const postPriceExploration: APICall = async (
  priceExploration,
  autobrokerEventId,
) => {
  const postBody = {
    autobroker_event_id: autobrokerEventId,
    frequency: priceExploration.frequency,
    strategy_type: priceExploration.strategyType,
    lower_percentile: priceExploration.lowerPercentile,
    upper_percentile: priceExploration.upperPercentile,
    notes: priceExploration.notes,
  };
  const response = await client.post('/proxy/exploration', postBody);

  return response.status;
};

export default postPriceExploration;
