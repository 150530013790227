import styled from 'styled-components';
import palette from '#/theme/palettes/main';

const FormWrapper = styled.div`
  display: flex;
  margin: 0.25rem;
  width: 33%;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.label`
  ${({ theme }: { theme: Theme }): string => theme.text3};
  margin-bottom: 0.25rem;
`;

const Input = styled.input`
  width: 4rem;
  height: 1rem;
  border: 1px solid ${palette.silver.dark};
  border-radius: 8px;
  padding: 4px;
  font-size: 12px;
  text-align: center
`;

const NotesInput = styled.input`
width: 15rem;
margin-left: 1rem;
height: 1rem;
border: 1px solid ${palette.silver.dark};
border-radius: 8px;
padding: 4px;
font-size: 12px;
text-align: center
`;

export { FormWrapper, Label, NotesInput };
export default Input;
