import React, {
  ChangeEvent, useState, useCallback, useMemo,
} from 'react';
import styled from 'styled-components';
import InputAndToggleRange from './InputAndToggleRange';
import Range from './Range';
import RangeLabels from './RangeLabels';
import ShowHelperButton from './ShowHelperButton';

interface SliderProps {
  disabled: boolean;
  error?: string;
  min: number;
  max: number;
  step: number;
  label: string;
  value: number | '';
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  parameterValue: number;
  hasHelper: boolean;
  openHelper: () => void;
}

interface SliderShowRangeProps {
  showRange: boolean;
}

const SliderInput: React.FC<SliderProps> = ({
  disabled,
  error,
  min,
  max,
  step,
  label,
  value,
  onChange,
  parameterValue,
  hasHelper,
  openHelper,
}) => {
  const inputVal = useMemo(() => (typeof value === 'number') ? value : '', [value]);
  const [showRange, setShowRange] = useState<boolean>(false);

  const toggleShowRange = useCallback(() => setShowRange(!showRange), [
    showRange,
  ]);

  return (
    <Wrapper>
      <Header>
        <Label isOverride={typeof value !== 'undefined'}>
          {label}
          {
            hasHelper && (
              <ShowHelperButton
                show={false}
                toggleShow={openHelper}
              />
            )
          }
        </Label>
        <InputAndToggleRange
          disabled={disabled}
          error={error}
          inputVal={inputVal}
          onChange={onChange}
          parameterValue={parameterValue}
          showRange={showRange}
          toggleShowRange={toggleShowRange}
        />
      </Header>

      <RangeWrapper showRange={showRange && !disabled}>
        <Range
          max={max}
          min={min}
          onChange={onChange}
          step={step}
          value={inputVal}
        />
        <RangeLabels max={max} min={min} />
      </RangeWrapper>

    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }: { theme: Theme }): string => theme.text3};
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.secondary};
`;

const Label = styled.div<{ isOverride: boolean }>`
  min-width: 11.25rem;
  color: ${({
    theme,
    isOverride,
  }: {
    theme: Theme;
    isOverride: boolean;
  }): string => isOverride ? theme.color.text.highlight : theme.color.text.primary};
`;

const RangeWrapper = styled.div<SliderShowRangeProps>`
  width: 100%;
  margin-top: 0.5rem;
  height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
  ${({ showRange }): string => {
    if (showRange)
      return 'height: 3.125rem; margin-bottom: 0.5rem';
    return '';
  }};
`;

export default SliderInput;
