import type { AxiosResponse } from 'axios';
import type { BulkOverridesAndSettings } from '#/types/Override';
import client from './_clientFormatted';

const getBulkOverrides = async (eventIds: number[]): Promise<BulkOverridesAndSettings> => {
  const response: AxiosResponse<BulkOverridesAndSettings> = await client.get(
    '/proxy/overrides/bulk',
    {
      params: {
        autobrokerEventId: eventIds,
      },
    },
  );

  const rawBulkOverrides = response?.data;

  return rawBulkOverrides;
};

export default getBulkOverrides;
