import { Dropdown, Item } from '#/shared/clientReporting/Dropdown';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Label as InputLabel } from '#/shared/clientReporting/Inputs';
import { Label } from '#/shared/clientReporting/ListBox';
import { MANIFEST_OPTIONS, MANIFESTED_LABEL } from '../utils/menuOptions';
import { CollectionSelectionsContext } from '../contexts';

const SELECT_INVENTORY_TYPE = 'Select inventory type';

interface Props {
  isLoading?: boolean;
}

const SelectManifestedInventory: React.FC<Props> = (
  {
    isLoading,
  },
) => {
  const {
    manifestOptionsSubset,
    handleUnmanifestedFilter,
    isUnmanifested,
  } = useContext(CollectionSelectionsContext);

  return (
    <Wrapper>
      <>
        <InputLabel isLoading={isLoading}>{MANIFESTED_LABEL}</InputLabel>
        <Dropdown
          aria-label={SELECT_INVENTORY_TYPE}
          isLoading={isLoading}
          items={manifestOptionsSubset}
          onSelectionChange={handleUnmanifestedFilter}
          placeholder={SELECT_INVENTORY_TYPE}
          selectedKey={MANIFEST_OPTIONS.find((m) => m.value === isUnmanifested)?.name ?? null}
        >
          {(item: { name: string}): JSX.Element => (
            <Item
              key={item.name}
              textValue={item.name}
            >
              <Label maxWidth={180}>{item.name}</Label>
            </Item>
          )}

        </Dropdown>
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.3rem;
`;

export default SelectManifestedInventory;
