import client from './_clientFormatted';
import { PatchOverrideNotesRequest } from '#/types/Override';

const patchOverrideNotes = async (
  autobrokerEventId: number,
  request: PatchOverrideNotesRequest,
): Promise<void> => {
  const response = await client.patch(`/proxy/overrides/history/${autobrokerEventId}`, request);

  return response.data;
};

export default patchOverrideNotes;
