import React, {
  useState, useCallback, useEffect,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import PageWrapper from '#/shared/PageWrapper';
import Header from '#/shared/Header';
import { User } from '#/types/User';
import UpdateSettingsModal from './UpdateSettingsModal';
import ManageCards, { ModalType } from './ManageCards';
import StakeholderModal from './StakeholderModal';
import DealModal from './DealModal';
import DealTermModal from './DealTermModal';
import PeakpassDomainModal from './PeakpassDomainModal';
import useFetchStakeholders from '../useFetchStakeholders';
import useFetchSettings from '../useFetchSettings';
import useFetchDeals from '../useFetchDeals';
import useFetchPeakpassDomains from '../useFetchPeakpassDomains';
import { FetchGroupsHook } from '../useFetchGroups';
import GroupModal from '#/shared/GroupModal';

const MANAGE = 'Manage';

interface ManagePageProps {
  user: User;
  groupsHook: FetchGroupsHook;
}

const ManagePage: React.FC<ManagePageProps> = ({
  user, groupsHook,
}) => {
  const { deals, fetchDeals } = useFetchDeals();
  const { stakeholders, stakeholderLabels, fetchStakeholders } = useFetchStakeholders();
  const {
    peakpassDomains, fetchPeakpassDomains, peakpassDomainStakeholders,
  } = useFetchPeakpassDomains();
  const { groups, groupLabels, fetchGroups } = groupsHook;
  const { settings, fetchSettings } = useFetchSettings();

  const [modal, setModal] = useState<ModalType>(undefined);

  const closeUpdateSettingsModal = useCallback(() => {
    setModal(undefined);
    fetchSettings();
  }, [fetchSettings]);

  const closeStakeholderModal = useCallback(() => {
    setModal(undefined);
    fetchStakeholders();
    fetchPeakpassDomains();
  }, [fetchStakeholders, fetchPeakpassDomains]);

  const closeDealModal = useCallback(() => {
    setModal(undefined);
    fetchDeals();
  }, [fetchDeals]);

  const closeDealTermModal = useCallback(() => {
    setModal(undefined);
    fetchDeals();
  }, [fetchDeals]);

  const closePeakpassDomainModal = useCallback(() => {
    setModal(undefined);
    fetchPeakpassDomains();
  }, [fetchPeakpassDomains]);

  const closeGroupModal = useCallback(() => {
    setModal(undefined);
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => Modal.setAppElement('body'), []);

  return (
    <PageWrapper>
      <Container>
        <Header
          toggleOpen={null}
          user={user}
        />
      </Container>
      <Wrapper>
        <>
          <Section>
            <Title>{MANAGE}</Title>
            <ManageCards setModal={setModal} user={user} />
          </Section>
        </>
        {[ModalType.CREATE_STAKEHOLDER, ModalType.UPDATE_STAKEHOLDER].includes(modal) && (
          <StakeholderModal
            closeModal={closeStakeholderModal}
            isUpdate={modal === ModalType.UPDATE_STAKEHOLDER}
            stakeholderLabels={stakeholderLabels}
            stakeholders={stakeholders}
          />
        )}
        {[ModalType.CREATE_DEAL, ModalType.UPDATE_DEAL].includes(modal) && (
          <DealModal
            closeModal={closeDealModal}
            deals={deals}
            isUpdate={modal === ModalType.UPDATE_DEAL}
            stakeholders={stakeholders}
          />
        )}
        {[
          ModalType.CREATE_DEAL_TERM,
          ModalType.UPDATE_DEAL_TERM,
          ModalType.MAP_DEAL_TERM,
        ].includes(modal) && (
          <DealTermModal
            closeModal={closeDealTermModal}
            deals={deals}
            isMapDealTerm={modal === ModalType.MAP_DEAL_TERM}
            isUpdate={modal === ModalType.UPDATE_DEAL_TERM}
            stakeholderLabels={stakeholderLabels}
            stakeholders={stakeholders}
          />
        )}
        {modal === ModalType.UPDATE_PEAKPASS_DOMAIN && (
          <PeakpassDomainModal
            closeModal={closePeakpassDomainModal}
            peakpassDomains={peakpassDomains}
            stakeholderLabels={
              stakeholderLabels
                .filter(({ value }) => peakpassDomainStakeholders.has(value))
            }
          />
        )}
        {[ModalType.CREATE_GROUP, ModalType.UPDATE_GROUP].includes(modal) && (
          <GroupModal
            closeModal={closeGroupModal}
            groupLabels={groupLabels}
            groups={groups}
            isUpdate={modal === ModalType.UPDATE_GROUP}
          />
        )}
        {modal === ModalType.UPDATE_SETTINGS && (
          <UpdateSettingsModal
            closeModal={closeUpdateSettingsModal}
            settings={settings}
          />
        )}
      </Wrapper>
    </PageWrapper>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 2;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.info};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: padding 0.75s ease;
`;

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const Wrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 8rem;
  width: 70%;
  margin-top: 3.75rem;
`;

export default ManagePage;
