import { useMemo } from 'react';
import { checkHasFaceValues } from '#/pages/clientReporting/DashboardPage/components/EventSalesTable/utils';
import useFetchReportingRollup, { RollupProps } from '#/pages/useFetchReportingRollup';
import useFetchMap from '#/shared/reporting/useFetchMap';
import { MapGranularity, MappedRollup, Metrics } from '#/types/MapRollup';
import { Rollup } from '#/types/ReportingRollup';
import { calcFraction } from '../../utils/getSalesTrendsByEvent';
import { simplifyText } from '../utils';

interface Props {
  granularity?: MapGranularity;
  selectedEventIds?: number[];
  hasDealTerm?: boolean;
  isUnmanifested?: boolean;
  bypassCache?: boolean;
  mapConfigId?: number;
}

interface Hook {
  isLoading: boolean;
  hasError: boolean;
  mapData: MappedRollup;
}

const useFetchMapRollup = ({
  granularity = MapGranularity.SECTION,
  selectedEventIds,
  hasDealTerm,
  isUnmanifested,
  bypassCache,
  mapConfigId: suppliedMapConfigId,
}: Props): Hook => {
  const mapRollupParams: RollupProps = {
    group: [granularity],
    autobrokerEventId: selectedEventIds?.map((id) => id.toString()),
    hasDealTerm,
    isUnmanifested,
    bypassCache,
  };

  const {
    isLoading: isRollupLoading, hasError: hasRollupError, rollup: mapRollup,
  } = useFetchReportingRollup(mapRollupParams);

  const mapConfigId = suppliedMapConfigId ?? mapRollup?.[0]?.group?.mapConfigId;

  // create a hashmap to lookup map rollups from (e.g. section-level rollups, keyed by section)
  const mapRollupMap = useMemo(() => (new Map(
    mapRollup?.map((rollup) => [simplifyText(rollup?.group?.section), rollup]),
  )), [mapRollup]);

  const {
    isLoading: isMapLoading, hasError: hasMapError, mapData: mapGeometryData,
  } = useFetchMap({ mapConfigId });

  const mapData = useMemo(() => {
    if (mapGeometryData) {
      const mappedRollup = Object.entries(mapGeometryData.sections)
        .flatMap(([label, { points }]) => {
          const thisRollup: Rollup = mapRollupMap.get(simplifyText(label));

          const metrics: Metrics = {};

          if (thisRollup?.aggregate) {
            const {
              ingestedTicketCt, saleTicketCt, saleRevenue, saleFaceValue, overallGrossProfitAttr,
            } = thisRollup?.aggregate;
            const cumulativeTicketsUnsoldPercent = calcFraction(
              (ingestedTicketCt - saleTicketCt), ingestedTicketCt,
            );
            const liftOfSold = checkHasFaceValues(thisRollup.aggregate)
              ? (saleRevenue - saleFaceValue)
              : undefined;
            const cumulativeLiftOfSoldPercent = calcFraction(liftOfSold, saleFaceValue);

            metrics.cumulativeManagedInventory = ingestedTicketCt;
            metrics.cumulativeTicketsUnsoldPercent = cumulativeTicketsUnsoldPercent;
            metrics.cumulativeTicketsSold = saleTicketCt;
            metrics.cumulativeRevenue = saleRevenue;
            metrics.cumulativeLiftOfSoldPercent = cumulativeLiftOfSoldPercent;
            metrics.cumulativeAverageTicketPrice = saleTicketCt > 0
              ? (saleRevenue / saleTicketCt)
              : undefined;
            metrics.ingestedTicketCt = ingestedTicketCt;
            metrics.cumulativeTakeAttr = overallGrossProfitAttr;
          }

          return points?.map((polygon) => ({
            label,
            polygon,
            metrics,
          }));
        });

      return {
        ...mapGeometryData,
        mapRollup: mappedRollup,
      };
    }
    return undefined;
  }, [mapRollupMap, mapGeometryData]);

  const isLoading = isRollupLoading || isMapLoading;
  const hasError = hasRollupError || hasMapError;

  return {
    isLoading,
    hasError,
    mapData,
  };
};

export default useFetchMapRollup;
