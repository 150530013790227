import React, { useContext, useMemo } from 'react';
import {
  ErrorStatusType, EventError, PostErrorStatus,
} from '#/types/Errors';
import Checklist, { ChecklistConfigItem } from './checklist';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';

interface SGMarketDisabledErrorChecklistProps {
  error: EventError;
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const SGMarketDisabledErrorChecklist: React.FC<SGMarketDisabledErrorChecklistProps> = (
  { error, setErrorStatus },
) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const id = activeEvent?.config?.seatgeekEventId;
  const rufus_link = `https://rufus.seatgeekadmin.com/events/${id}#:~:text=Market%20Configuration`;

  const config: ChecklistConfigItem[] = useMemo(() => ([
    {
      id: 0,
      level: 1,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Check Sellerdirect Status on Rufus',
      link: rufus_link,
    },
    {
      id: 1,
      level: 2,
      status: ErrorStatusType.WONTFIX,
      notes: 'Cannot turn on Sellerdirect for EFT',
      parent_id: 0,
    },
    {
      id: 2,
      level: 2,
      status: ErrorStatusType.RESOLVED,
      notes: 'Turned on Sellerdirect for EFT',
      parent_id: 0,
    },
  ]), [rufus_link]);

  return (
    <Checklist config={config} error={error} setErrorStatus={setErrorStatus} />
  );
};

export default SGMarketDisabledErrorChecklist;
