import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import OverridesIcons from './OverridesIcons';
import { Button } from '#/shared/Detail';
import Edit from '#images/Edit.svg';
import tableStyles from '#/shared/tableStyles';
import { PriceGranularity } from '#/types/Event';
import {
  TableCell,
  OverrideColumns,
  OverrideSummary,
  formatColumn,
  formatMapkey,
  formatConstraint,
  FormattedOverrideSummary,
  growTableCell,
  getSortFunction,
} from './utils/helpers';
import generateSummaryColumns from './utils/generateSummaryColumns';
import palette from '#/theme/palettes/main';
import { getSourceInventoryId, InputListing } from '#/shared/ListingOverrideModal';

const customStyles = {
  ...tableStyles,
  rows: {
    ...tableStyles.rows,
    style: {
      ...tableStyles.rows.style,
      minHeight: '3rem',
    },
  },
};

const customSummaryStyles = {
  ...tableStyles,
  rows: {
    ...tableStyles.rows,
    style: {
      ...tableStyles.rows.style,
      '&:hover': {
        backgroundColor: palette.brand.light08,
      },
      minHeight: '3rem',
    },
  },
};

interface OverridesTableProps {
  listingOverrides: InputListing[];
  setOverrideModal: (overrides: InputListing[]) => void;
}

const OverridesTable: React.FC<OverridesTableProps> = ({
  listingOverrides,
  setOverrideModal,
}) => {
  const openOverrideModal = useCallback((listingOverride : InputListing) => {
    setOverrideModal([listingOverride]);
  }, [setOverrideModal]);

  const renderTableCell = useCallback((key: string): TableCell => {
    if (key === OverrideColumns.ID) {
      return (row: InputListing): React.ReactNode => (
        <FormattedWhiteSpace>
          {`${row.source_name} event ${row.source_external_event_id}`}
          <SourceInventoryIDWrapper>{`${row.override.granularity} ${getSourceInventoryId(row, row.override.granularity)}`}</SourceInventoryIDWrapper>
        </FormattedWhiteSpace>
      );
    }

    if (key === OverrideColumns.MAPKEY) {
      return (row: InputListing): React.ReactNode => (
        <FormattedWhiteSpace>
          {formatMapkey(row.override.section, row.override.row, row.override.mapkey)}
        </FormattedWhiteSpace>
      );
    }

    if (key === OverrideColumns.PRICE_CONSTRAINT) {
      return (row: InputListing): React.ReactNode => (
        <FormattedWhiteSpace>
          {formatConstraint(
            row.override.price_constraint,
            row.override.broadcast_price,
            row.override.all_in_price,
            row.override.display_price,
          )}
        </FormattedWhiteSpace>
      );
    }

    if (key === OverrideColumns.PROPERTIES) {
      return (row: InputListing): React.ReactNode => (
        <OverridesIcons listingOverride={row} />
      );
    }

    if (key === OverrideColumns.EDIT) {
      return (row: InputListing): React.ReactNode => (
        // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
        <Button onClick={(): void => { openOverrideModal(row); }} title="edit listing"><EditImg /></Button>
      );
    }

    return undefined;
  }, [openOverrideModal]);

  const getOverrideSummary = useMemo(() : FormattedOverrideSummary[] => {
    return ((Object.values(PriceGranularity)).map((g) => {
      const overridesByGranularity = listingOverrides?.filter(
        (o : InputListing) => o.override.granularity === g,
      );
      const overrideCount = overridesByGranularity?.length;
      const overrideSummary = {
        granularity: g,
        override_count: overrideCount,
        overrides: overridesByGranularity,
      };

      return {
        label: g,
        value: overrideSummary,
      };
    }));
  }, [listingOverrides]);

  const generateColumns = useCallback((): TableColumn<InputListing>[] => {
    return Object.values(OverrideColumns)
      .map((key) => ({
        id: key,
        name: formatColumn(key),
        selector: (row: InputListing): any => {
          switch (key) {
            case OverrideColumns.ID:
              return row.source_inventory_id;
            case OverrideColumns.EXPECTED_VALUE:
              return row.override.expected_value;
            default:
              return undefined;
          }
        },
        sortable: key !== OverrideColumns.PROPERTIES
          && key !== OverrideColumns.EDIT
          && key !== OverrideColumns.MAPKEY,
        sortFunction: getSortFunction(key),
        cell: renderTableCell(key),
        grow: growTableCell(key),
      }));
  }, [renderTableCell]);

  const columns = useMemo(() => generateColumns(), [generateColumns]);
  const summaryColumns = generateSummaryColumns();

  const expandableRowDisabled = useCallback(
    (row: OverrideSummary) => (row.override_count === 0), [],
  );

  const ExpandedComponent: React.FC<ExpanderComponentProps<OverrideSummary>> = useCallback(
    ({ data }) => (
      <DataTable
        columns={columns}
        customStyles={customStyles}
        data={data.overrides}
        defaultSortFieldId="id"
        responsive
        striped
      />
    ), [columns],
  );

  return (
    <TableContainer>
      <DataTable
        columns={summaryColumns}
        customStyles={customSummaryStyles}
        data={getOverrideSummary.map((s:FormattedOverrideSummary) => s.value)}
        defaultSortAsc={false}
        defaultSortFieldId="granularity"
        expandOnRowClicked
        expandableRowDisabled={expandableRowDisabled}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        keyField="granularity"
        responsive
      />
    </TableContainer>
  );
};

const EditImg = styled.img`
  content: url(${Edit});
  height: 20px;
`;

const SourceInventoryIDWrapper = styled.div`
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 11rem;
`;

const TableContainer = styled.div`
  margin: 12px 12px 0 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgb(36 63 97 / 16%), 0px 1px 4px rgb(36 63 97 / 10%);
`;

const FormattedWhiteSpace = styled.div`
  white-space: pre;
`;

export default OverridesTable;
