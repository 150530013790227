import React from 'react';
import styled from 'styled-components';
import Edit from '#images/Edit.svg';
import Notes from '#images/Notes.svg';

interface HeaderButtonProps extends React.PropsWithChildren {
  disabled: boolean;
  openEditModal: () => void | null;
  openNotesModal: () => void | null;
}

const HeaderButtons: React.FC<HeaderButtonProps> = ({
  disabled,
  openEditModal,
  openNotesModal,
  children,
}) => {
  return (
    <>
      {!disabled && openEditModal
        ? <EditImg onClick={openEditModal} />
        : <LeftEmptyImg />}
      <Center>
        {children}
      </Center>
      {!disabled && openNotesModal
        ? <NotesImg onClick={openNotesModal} />
        : <RightEmptyImg />}
    </>
  );
};

const EditImg = styled.img`
  cursor: pointer;
  content: url(${Edit});
  height: 16px;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.35rem;
`;

const NotesImg = styled.img`
  cursor: pointer;
  content: url(${Notes});
  height: 16px;
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
`;

const Center = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const EmptyImg = styled.div`
  height: 16px;
  width: 16px;
  position: absolute;
  top: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
`;

const LeftEmptyImg = styled(EmptyImg as any)`
  left: 0;
`;

const RightEmptyImg = styled(EmptyImg as any)`
  right: 0;
`;

export default HeaderButtons;
