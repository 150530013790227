import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { usePageVisibility } from 'react-page-visibility';
import getPriceLevels from '#/api/getPriceLevels';
import { PriceLevelFilters, PriceLevelEntry } from '#/types/PriceLevel';

interface UsePriceLevelsHook {
  priceLevels: PriceLevelEntry[];
  pages: number[];
  nextPage: () => void;
  prevPage: () => void;
  filters: PriceLevelFilters;
  setFilters: (newFilters: PriceLevelFilters) => void;
  retry: () => void;
  showError: boolean;
  showLoader: boolean;
  showResults: boolean;
}

const useFetchPriceLevels = (initial?: PriceLevelFilters): UsePriceLevelsHook => {
  const initialFiltersString = JSON.stringify(initial);
  const isVisible = usePageVisibility();
  const [filters, setFiltersRaw] = useState<PriceLevelFilters>(
    { ...initial },
  );
  const [pages, setPages] = useState<number[]>([]);
  const [priceLevels, setPriceLevels] = useState<PriceLevelEntry[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const retry = useCallback(async () => {
    setHasError(false);
    try {
      if (filters.autobrokerEventId || !initialFiltersString) {
        const result = await getPriceLevels(filters);

        setPriceLevels(result);
      }
    } catch {
      setHasError(true);
    }
    setLoading(false);
  }, [filters, initialFiltersString]);

  const showResults = useMemo(() => !isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showLoader = useMemo(() => isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showError = useMemo(() => !isLoading && hasError, [
    hasError,
    isLoading,
  ]);

  // refresh the event data
  useEffect(() => {
    setLoading(true);
    retry();
    const interval = setInterval(() => {
      if (pages.length === 0 && isVisible)
        retry();
    }, 30000);

    return (): void => clearInterval(interval);
  }, [pages.length, retry, isVisible]);

  const nextPage = useCallback(() => {
    setPages((p) => [...p, filters.fromId]);
    const fromId = Math.max(...priceLevels.map((t) => t.id)) + 1;

    setFiltersRaw({ ...filters, fromId: fromId });
  },
  [priceLevels, filters]);

  const prevPage = useCallback(() => {
    setFiltersRaw({ ...filters, fromId: pages[pages.length - 1] });
    setPages((p) => p.slice(0, p.length - 1));
  },
  [filters, pages]);

  const setFilters = useCallback((newFilters: PriceLevelFilters) => {
    setFiltersRaw({ ...newFilters, fromId: undefined });
    setPages([]);
  }, []);

  return {
    priceLevels,
    pages,
    nextPage,
    prevPage,
    filters,
    setFilters,
    retry,
    showError,
    showLoader,
    showResults,
  };
};

export { UsePriceLevelsHook };
export default useFetchPriceLevels;
