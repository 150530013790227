import React, {
  useMemo,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import DataTable, { ConditionalStyles } from 'react-data-table-component';
import tableStyles from '#/shared/tableStyles';
import {
  getTableColumns,
  customStyles,
  styleHeaders,
  synchronizeScroll,
} from './helpers';
import TableTotals from './TableTotals';
import TableSkeleton from './TableSkeleton';
import RollupErrorComponent from '../RollupErrorComponent';
import { EventTxSummary, EventTxSummaryCols } from '../../types';

interface TableProps {
  data: EventTxSummary[];
  isLoading?: boolean;
  progressComponent?: React.ReactNode;
  hasError?: boolean;
  isClickable?: boolean;
  onRowClick: (event: EventTxSummary) => void;
  isNarrow?: boolean;
  retry?: () => void;
}

const SCROLL_HEIGHT = 'min(calc(53vh - 54px), 590px)';

const EXCLUDED_COLUMNS = [EventTxSummaryCols.COST, EventTxSummaryCols.TAKE];

const TOTALS_EXCLUDED_COLUMNS: EventTxSummaryCols[] = [
  EventTxSummaryCols.EVENT_DATE,
  EventTxSummaryCols.SG_EVENT_ID,
];
const POSSIBLY_EXCLUDED_COLUMNS: EventTxSummaryCols[] = [
  EventTxSummaryCols.LIFT_OF_SOLD,
  EventTxSummaryCols.LIFT_WITH_SPOILAGE,
];

const conditionalStyles: ConditionalStyles<EventTxSummary>[] = [
  {
    when: (row): boolean => row.isSelected,
    style: tableStyles.rows.selectedHighlightStyle,
  },
];

const Table: React.FC<TableProps> = ({
  data,
  isLoading,
  isClickable,
  progressComponent = <TableSkeleton />,
  hasError,
  onRowClick,
  isNarrow,
  retry,
}) => {
  const eventTableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    synchronizeScroll('addListener', eventTableRef);
    return synchronizeScroll('removeListener', eventTableRef);
  });
  useEffect(() => styleHeaders(), [isLoading]);

  const styles = useMemo(() => (
    isClickable ? customStyles : ({
      ...customStyles,
      cells: {
        style: {
          ...customStyles.cells.style,
          cursor: 'progress',
        },
      },
    })
  ), [isClickable]);

  const excludedColumns = useMemo(() => {
    const allColumns = Object.values(EventTxSummaryCols);

    return allColumns.filter((col) => {
      const hasNoData = data?.every((d) => typeof d[col] === 'undefined');
      const isExcluded = EXCLUDED_COLUMNS.includes(col);
      const isPossiblyExcluded = POSSIBLY_EXCLUDED_COLUMNS.includes(col);

      return isExcluded || (hasNoData && isPossiblyExcluded);
    });
  }, [data]);

  const totalsExcludedColumns = useMemo(
    () => [...excludedColumns, ...TOTALS_EXCLUDED_COLUMNS], [excludedColumns],
  );

  return (
    hasError
      ? <RollupErrorComponent retry={retry} />
      : (
        <TableContainer ref={eventTableRef}>
          <DataTable
            columns={getTableColumns(isNarrow, excludedColumns)}
            conditionalRowStyles={conditionalStyles}
            customStyles={styles}
            data={data}
            defaultSortFieldId={EventTxSummaryCols.EVENT_DATE}
            fixedHeader
            fixedHeaderScrollHeight={SCROLL_HEIGHT}
            highlightOnHover={isClickable}
            onRowClicked={isClickable ? onRowClick : undefined}
            pointerOnHover={isClickable}
            progressComponent={progressComponent}
            progressPending={isLoading}
            responsive
            striped
          />
          {!isLoading && (
            <TableTotals
              data={data}
              excludedColumns={totalsExcludedColumns}
              isNarrow={isNarrow}
            />
          )}
        </TableContainer>
      )
  );
};

const TableContainer = styled.div`
  border: 1px solid #eee;
  border-radius: 8px;
`;

export { TableProps };
export default Table;
