import React from 'react';
import styled from 'styled-components';
import { SegmentedControl, SegmentedGroup } from '#/shared/clientReporting/web-platform-components/Controls';
import { TIME_INPUT_LABEL, TIME_OPTIONS, type TimeMeasurement } from '../utils/menuOptions';

interface TimeViewToggleProps {
  isLoading?: boolean;
  onChange: (value: TimeMeasurement) => void;
  selectedValue: TimeMeasurement
}

const TimeViewToggle: React.FC<TimeViewToggleProps> = ({ isLoading, onChange, selectedValue }) => (
  <ToggleWrapper isLoading={isLoading}>
    <ToggleLabel htmlFor='event-collection-time-selection'>{TIME_INPUT_LABEL}</ToggleLabel>
    <SegmentedGroup
      name='event-collection-time-selection'
      onChange={onChange}
      value={selectedValue}
    >
      {TIME_OPTIONS.map((p) => (
        <SegmentedControl
          disabled={isLoading}
          isSmall
          key={p.key}
          value={p.key}
        >
          {p.label}
        </SegmentedControl>
      ))}
    </SegmentedGroup>
  </ToggleWrapper>
);

const ToggleLabel = styled.label`
  font-size: 0.8rem;
  letter-spacing: 0;
  font-weight: 600;
`;

interface ToggleProps {
  isLoading?: boolean;
}

const ToggleWrapper = styled.div<ToggleProps>`
  width: 10rem;
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-left: 1rem;
  letter-spacing: 0;
  opacity: ${(props: ToggleProps): number => props.isLoading ? 0.4 : 1};
  transition: all 0.2s;
`;

export default TimeViewToggle;
