import ApiSnapshot, { CurrentStateData } from '../../types/Snapshot';

const STANDARD = 3;
const ZERO = 0;
const TWO = 2;

const DEFAULT: CurrentStateData = {
  abListingCount: null,
  abLogPriceRatio: null,
  abNormalizedListingCount: null,
  competitorListingCount: null,
  competitorLogPriceRatio: null,
  competitorNormalizedListingCount: null,
  daysToEvent: null,
  futureDemand: null,
  isUnconstrained: null,
  localDemand: null,
  localElasticity: null,
  localMeanTicketsPerTransaction: null,
  logHoursToEvent: null,
  modelAbListingsRemaining: null,
  modelLogPriceRatio: null,
  modelLogPriceRatioTarget: null,
  projectedSelldown: null,
  ticketsSoldCount: 0,
  ticketsSoldGtv: 0,
  ticketsUnsoldCount: 0,
  transactionCount: 0,
  ticketsRemaining: 0,
  listingsRemaining: 0,
};

// http://www.jacklmoore.com/notes/rounding-in-javascript/
const editDecimals = (value: number, decimalPlaces: number): number => {
  if (!value)
    return value;
  return Number(
    Math.floor(Number(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces,
  );
};

const formatCurrentStateData = (snapshot: ApiSnapshot): CurrentStateData => {
  if (!snapshot)
    return DEFAULT as CurrentStateData;
  const { actual, model, projected } = snapshot;

  return {
    abListingCount: actual?.autobroker?.listingCt,
    abLogPriceRatio: editDecimals(
      actual?.autobroker?.logPriceRatio,
      STANDARD,
    ),
    abNormalizedListingCount: actual?.autobroker?.normalizedListingCt,
    competitorListingCount: actual?.competitor?.listingCt,
    competitorLogPriceRatio: editDecimals(
      actual?.competitor?.logPriceRatio,
      STANDARD,
    ),
    competitorNormalizedListingCount: actual?.competitor?.normalizedListingCt,
    daysToEvent: editDecimals(
      snapshot.daysToEvent,
      TWO,
    ),
    futureDemand: editDecimals(model?.parameters?.futureDemand, ZERO),
    isUnconstrained: model?.isUnconstrained,
    localDemand: editDecimals(model?.localDemand, ZERO),
    localElasticity: editDecimals(model?.localElasticity, STANDARD),
    localMeanTicketsPerTransaction: editDecimals(
      model?.localMeanTicketsPerTransaction,
      STANDARD,
    ),
    logHoursToEvent: editDecimals(snapshot.logHoursToEvent, STANDARD),
    modelAbListingsRemaining: model?.listingsRemaining,
    modelLogPriceRatio: editDecimals(model?.logPriceRatio, STANDARD),
    modelLogPriceRatioTarget: editDecimals(
      model?.logPriceRatioTarget,
      STANDARD,
    ),
    projectedSelldown: editDecimals(projected?.selldown, STANDARD),
    ticketsSoldCount: actual?.sales?.ticketsSoldCt,
    ticketsSoldGtv: editDecimals(actual?.sales?.ticketsSoldGtv, TWO),
    ticketsUnsoldCount: actual?.sales?.ticketsUnsoldCt,
    transactionCount: actual?.sales?.transactionCt,
    ticketsRemaining: model?.ticketsRemaining,
    listingsRemaining: model?.listingsRemaining,
  };
};

export default formatCurrentStateData;
