import React, {
  useState, useCallback, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import {
  SectionWrapper, Title, Content,
} from '#/shared/modalComponents';
import ModalButtons from '../Buttons';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import postEventRebuild from '#/api/postEventRebuild';
import formatApiError from '#/api/utils/formatApiError';
import Loader from '#/shared/Loader';
import {
  EventRebuildApiPost,
  EventRebuildPartitionApiPost,
  PricingMode,
  TrainingSetType,
} from '#/types/Event';
import EventPartitionForm from './EventPartitionForm';
import Button from '#/shared/Button';
import palette from '#/theme/palettes/main';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';

const ADD = '+';
const REMOVE = '-';
const HEADLINE = 'Rebuild Event';
const DESCRIPTION = `
WARNING! This action will decommission this event and replace it with another event. 
This action is irreversible and should only be used if we need really need to generate a new onsale 
model for this event. Note that this action will result in the loss of all competitive 
intelligence data collected for this event so far.

This action will turn off the supervisor, unlink the sources, debroadcast available inventory, 
and delete the sink. Then, it will create a new event that is set up with the same configuration
as this event.
`;
const rebuildModalStyles = {
  ...modalStyles,
  content: {
    ...modalStyles.content,
    maxWidth: 700,
    maxHeight: '90%',
  },
};

interface RebuildEventModalProps {
  closeModal: () => void;
  retry: () => void;
}

const RebuildEventModal: React.FC<RebuildEventModalProps> = ({
  closeModal,
  retry,
}): React.ReactElement => {
  const disabled = false;
  const { activeEvent, setActiveEvent } = useContext(ActiveEventContext);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [partitions, setPartitions] = useState<EventRebuildPartitionApiPost[]>([]);

  const addPartition = useCallback(() => {
    if (activeEvent) {
      setPartitions((ps) => {
        return [
          ...ps,
          (
            ps.length > 0
              ? { ...ps[ps.length - 1], key: ps.length }
              : {
                key: ps.length,
                seatgeek_event_id: activeEvent.config.seatgeekEventId,
                pricing_mode: activeEvent.config.pricingMode as PricingMode,
                section_subset: activeEvent.config.sectionSubset,
                onsale_pricing_model: activeEvent.config.onsalePricingModel as TrainingSetType,
              }
          ),
        ];
      });
    }
  }, [setPartitions, activeEvent]);

  const removePartition = useCallback(() => {
    setPartitions((ps) => {
      return ps.filter((p, i) => i < ps.length - 1);
    });
  }, [setPartitions]);

  useEffect(() => {
    if (activeEvent && partitions.length === 0)
      addPartition();
  }, [activeEvent, addPartition, partitions, setPartitions]);

  const setPartition = useCallback((partition: EventRebuildPartitionApiPost) => {
    setPartitions((ps) => {
      const newPartitions = [...ps];

      newPartitions[partition.key] = partition;
      return newPartitions;
    });
  }, [setPartitions]);

  const handleSave = useCallback(async () => {
    setError('');
    setShowLoader(true);
    try {
      if (activeEvent?.id) {
        const request: EventRebuildApiPost = { partitions };
        const newEvents = await postEventRebuild(activeEvent.id, request);

        if (newEvents.length > 0)
          setActiveEvent(newEvents[0].id);

        await retry();
        closeModal();
      }
    } catch (e) {
      setError(formatApiError(e));
    }
    setShowLoader(false);
  }, [activeEvent, closeModal, partitions, retry, setActiveEvent]);

  useSetupModal(closeModal);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={rebuildModalStyles}
    >
      <Title className='rebuild_modal'>{HEADLINE}</Title>
      {error && <Error>{error}</Error>}
      <SectionWrapper>
        <Content>
          <Description>{DESCRIPTION}</Description>
          <InnerWrapper>
            {partitions.map((partition) => (
              <EventPartitionForm
                key={partition.key}
                partition={partition}
                setPartition={setPartition}
              />
            ))}
          </InnerWrapper>
          <InnerWrapper>
            <HorizontalWrapper>
              <Button disabled={disabled} onClick={addPartition}>{ADD}</Button>
              <Button disabled={disabled} onClick={removePartition}>{REMOVE}</Button>
            </HorizontalWrapper>
          </InnerWrapper>
        </Content>
        {showLoader
          ? <Loader hexColor={palette.brand.base} />
          : (
            <ModalButtons
              disabled={!(activeEvent?.id)}
              onCancel={closeModal}
              onSave={handleSave}
            />
          )}
      </SectionWrapper>
    </Modal>
  );
};

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0.5rem;
`;

const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Description = styled.p`
  ${({ theme }: { theme: Theme }): string => theme.text3};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.secondary};
  border-bottom: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}`};
  padding-bottom: 1rem;
`;
const Error = styled.p`
  ${({ theme }: { theme: Theme }): string => theme.text2};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.error};
`;

export default RebuildEventModal;
