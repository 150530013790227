import styled from 'styled-components';

import Edit from '#images/Edit.svg';
import Notes from '#images/Notes.svg';
import Refresh from '#images/Refresh.svg';

const Buttons = styled.div`
  margin-top: .5rem;
  margin-right: 1rem;
  position:absolute;
  top:0;
  right:0;
  display:flex;
  flex-direction:row;
`;

const EditImg = styled.img`
  cursor: pointer;
  content: url(${Edit});
  height: 25px;
  padding-top: 4px;
  padding-left: .75rem;
`;

const NotesImg = styled.img`
  cursor: pointer;
  content: url(${Notes});
  height: 27px;
  padding-top: 5px;
  padding-left: .75rem;
`;

const RefreshImg = styled.img`
  cursor: pointer;
  content: url(${Refresh});
  height: 27px;
  padding-top: 5px;
  padding-left: .75rem;
`;

export default Buttons;
export { EditImg, NotesImg, RefreshImg };
