
import { OverrideChangeLogEntry } from '#/types/Override';
import { formatChanges, formatCreatedAt, formatType } from '#/pages/ViewPage/components/OverrideLogs/OverrideLogsTable/utils/helpers';

const filterLogsBySearch = (overrideLogs: OverrideChangeLogEntry[], search: string):
OverrideChangeLogEntry[] => {
  if (!overrideLogs || !search || search === '')
    return overrideLogs;

  return overrideLogs.filter((log) => {
    const type = formatType(log.type).toLowerCase();
    const user = log.user?.name?.toLowerCase() || '';
    const notes = log.notes?.toLowerCase() || '';
    const changes = formatChanges(log.changes).toLowerCase() || '';
    const createdAt = formatCreatedAt(log.createdAt).toLowerCase();

    // negative search to exclude matches
    if (search[0] === '!' && search.length > 1) {
      const negativeSearch = search.slice(1);

      return (
        !type.includes(negativeSearch)
        && !user.includes(negativeSearch)
        && !notes.includes(negativeSearch)
        && !changes.includes(negativeSearch)
        && !createdAt.includes(negativeSearch)
      );
    }

    return (
      type.includes(search)
      || user.includes(search)
      || notes.includes(search)
      || changes.includes(search)
      || createdAt.includes(search)
    );
  });
};

export default filterLogsBySearch;
