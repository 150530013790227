import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { EventConfigTableData } from '../utils/formatEventInfo';
import Cards from './Cards';
import UpdateEventConfigModal from './UpdateEventConfigModal';
import useEventConfigModal from './useEventConfigModal';
import useUpdateEventConfig from './useUpdateEventConfig';
import SupervisorToggle from './SupervisorToggle';
import { ButtonSmall } from '#/shared/Button';
import Event from '#/types/Event';
import RunSupervisorButton from './RunSupervisorButton';
import { User } from '#/types/User';
import useSetupModal from '#/pages/useSetupModal';
import { FetchGroupsHook } from '#/pages/useFetchGroups';
import RunInventoryUpdaterButton from './RunInventoryUpdaterButton';

interface EventConfigTableProps {
  user: User;
  activeEvent: Event;
  retry: () => void;
  tableData: EventConfigTableData;
  groupsHook: FetchGroupsHook;
}

const EVENT_CONFIG = 'Event Config';
const EDIT_CONFIG = 'Edit Event Config';

const EventConfig: React.FC<EventConfigTableProps> = ({
  user,
  activeEvent,
  retry,
  tableData,
  groupsHook,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { updatedConfig, updateEventConfig } = useUpdateEventConfig(
    activeEvent.config,
    isEditing,
  );

  const {
    error,
    handleSubmit,
    loading,
    modalIsOpen,
    success,
    toggleModal,
  } = useEventConfigModal(
    activeEvent,
    updatedConfig,
    retry,
    groupsHook,
  );

  const handleEditing = useCallback(() => {
    toggleModal();
    setIsEditing((prev) => !prev);
  }, [toggleModal]);

  const disabled = user.isReadOnly;

  useSetupModal(() => {
    if (modalIsOpen)
      toggleModal();
  });

  const isPrimaryEvent = useMemo(() => {
    const sinkNames = activeEvent.sinkEvents.map((sink) => sink.sinkName);

    return sinkNames.includes('sro-seat-level-pricing') || sinkNames.includes('sro-price-level-pricing');
  }, [activeEvent]);

  return (
    <>
      <Header>
        <Title>{EVENT_CONFIG}</Title>
        <Wrapper>
          <SupervisorToggle activeEvent={activeEvent} disabled={disabled} retry={retry} />
          {!disabled && <RunSupervisorButton activeEvent={activeEvent} />}
          {!disabled && isPrimaryEvent && <RunInventoryUpdaterButton activeEvent={activeEvent} />}
          {!disabled && <EditButton onClick={handleEditing}>{EDIT_CONFIG}</EditButton>}
        </Wrapper>
      </Header>
      <Cards event={activeEvent} tableData={tableData} user={user} />
      <UpdateEventConfigModal
        closeModal={handleEditing}
        enableOnsalePricingModel={!activeEvent?.models?.onsalePricingModel}
        error={error}
        groupsHook={groupsHook}
        handleSubmit={handleSubmit}
        loading={loading}
        modalIsOpen={modalIsOpen}
        success={success}
        updateEventConfig={updateEventConfig}
        updatedConfig={updatedConfig}
      />
    </>
  );
};

const EditButton = styled(ButtonSmall as any)`
  padding: 0 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export default EventConfig;
