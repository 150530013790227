import React, {
  useMemo, useRef, useState, useEffect, useCallback,
} from 'react';
import styled from 'styled-components';
import { debounce } from '@graphique/graphique';
import Loader from '#/shared/Loader';
import Error from '#/shared/Error';
import { OverrideChangeLogEntry } from '#/types/Override';
import OverrideLogsTable from './OverrideLogsTable';
import filterLogsBySearch from './utils';
import OverrideLogsDetail from './OverrideLogsDetail';
import { User } from '#/types/User';
import OverrideNotesModal from './OverrideNotesModal';
import { LogColumns } from '#/pages/ViewPage/components/OverrideLogs/OverrideLogsTable/utils/helpers';

const ERROR_COPY = 'Something went wrong retrieving override logs!';
const SEARCH_PLACEHOLDER = 'Search by user, type, changes, or notes';

interface OverrideLogsTableAndDetailProps {
  user: User;
  overrideLogs: OverrideChangeLogEntry[];
  error: string;
  retry: () => void;
  columnsToHide?: LogColumns[];
  isContainerHeight?: boolean;
}

const OverrideLogsTableAndDetail: React.FC<OverrideLogsTableAndDetailProps> = ({
  user, overrideLogs, error, retry, columnsToHide, isContainerHeight,
}) => {
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<OverrideChangeLogEntry>(null);
  const [closeable, setCloseable] = useState<boolean>(true);
  const [overrideNotesModal, setOverrideNotesModal] = useState<OverrideChangeLogEntry>(null);
  const filteredLogs = useMemo(() => (
    filterLogsBySearch(overrideLogs, search)
  ), [overrideLogs, search]);

  const searchRef = useRef<HTMLInputElement>(null);
  const handleSearchChange = debounce(300, () => {
    const searchString = searchRef?.current?.value?.trim().toLowerCase();

    setSearch(searchString);
    setSelected(null);
  });

  useEffect(() => {
    if (filteredLogs && filteredLogs.length === 1) {
      setSelected(filteredLogs[0]);
      setCloseable(false);
      return;
    }
    setCloseable(true);
  }, [setSelected, setCloseable, filteredLogs]);

  const closeDetail = useCallback(() => {
    setSelected(null);
  }, [setSelected]);

  const closeOverrideNotesModal = useCallback(() => {
    setOverrideNotesModal(null);
    retry();
  }, [setOverrideNotesModal, retry]);

  const disabled = useMemo(() => (
    user.isReadOnly || selected?.user.id !== user.id
  ), [user, selected]);

  const isLoading = useMemo(() => !overrideLogs && !error, [overrideLogs, error]);

  return (
    <>
      {isLoading && (
        <CenteredContainer>
          <Loader />
        </CenteredContainer>
      )}
      {error && (
        <CenteredContainer>
          <Error copy={ERROR_COPY} retry={retry} />
        </CenteredContainer>
      )}
      {filteredLogs && !isLoading && !error && (
        <div style={{ width: '100%' }}>
          <TableHeaderContainer>
            <LogSearch
              onChange={handleSearchChange}
              placeholder={SEARCH_PLACEHOLDER}
              ref={searchRef}
              spellCheck='false'
              type='search'
            />
          </TableHeaderContainer>
          {selected && (
            <OverrideLogsDetail
              closeDetail={closeDetail}
              closeable={closeable}
              disabled={disabled}
              log={selected}
              setOverrideNotesModal={setOverrideNotesModal}
            />
          )}
          {!disabled && selected && overrideNotesModal && (
            <OverrideNotesModal closeModal={closeOverrideNotesModal} log={selected} />
          )}
          <OverrideLogsTable
            columnsToHide={columnsToHide}
            isContainerHeight={isContainerHeight}
            logs={filteredLogs}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      )}
    </>
  );
};

const LogSearch = styled.input`
  font-family: Roobert;
  height: 40px;
  width: 370px;
  border: 1px solid;
  border-color: ${({ theme }: { theme: Theme }): string => theme.palette.silver.dark};
  border-radius: .3rem;
  padding: 4px 6px 4px 12px;
  font-size: 14px;
  letter-spacing: 0;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const CenteredContainer = styled.div`
  display: flex;
  height: calc(25vh + 50px);
  flex-direction: column;
`;

export default OverrideLogsTableAndDetail;
