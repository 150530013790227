import React, { useCallback, useState } from 'react';
import Select, { StylesConfig, type CSSObjectWithLabel } from 'react-select';
import {
  Wrapper, Element,
} from '#/shared/Card';
import { ButtonTransparent } from '#/shared/Button';
import ErrorModal from '#/shared/ErrorModal';
import {
  PatchUserUpdate, StakeholderAccessType, User, UserType,
} from '#/types/User';
import useUser from './useUser';
import { Option } from '#/pages/useFetchStakeholders';
import {
  ButtonsWrapper, FormWrapper, Label, Success,
} from './components';
import type { GenericOption } from '#/types/GenericOption';

const UPDATE = 'Update';
const DELETE = 'Delete';
const SUCCESS = 'Success!';
const STAKEHOLDERS = 'Stakeholders';
const STAKEHOLDER_ACCESS = 'Access to Stakeholders';

const USER_TYPE = 'User Type';
const USER_TYPES = [
  { value: 'reporting', label: 'Reporting' },
  { value: 'read_only', label: 'Read Only' },
  { value: 'read_write', label: 'Read Write' },
  { value: 'admin', label: 'Admin' },
  { value: 'superuser', label: 'Superuser' },
];
const ACTIVE = 'User Status';

const ACTIVE_OPTIONS = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];

const ACCESS_OPTIONS = [
  { value: 'read_write', label: 'Read/Write' },
  { value: 'read_only', label: 'Read Only' },
  { value: 'none', label: 'None' },
];

const customStyles: StylesConfig = {
  control: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 22,
    minHeight: 22,
    minWidth: 200,
  }),
  valueContainer: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    padding: '0 6px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    height: 22,
  }),
};

const stakeholderSelectStyles = {
  control: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    textAlign: 'left',
    maxHeight: 75,
    minWidth: 200,
  }),
  valueContainer: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    maxHeight: 72,
    padding: '0 6px',
    overflowY: 'scroll',
  }),
};

interface UserDetailCardProps {
  user: User;
  disabled: boolean;
  stakeholderLabels: Option<string>[];
}

const UserDetailCard: React.FC<UserDetailCardProps> = ({ user, disabled, stakeholderLabels }) => {
  const [userPatchBody, setUserPatchBody] = useState<PatchUserUpdate>({});
  const {
    clearError,
    error,
    handleSubmit,
    handleDelete,
    success,
  } = useUser(
    user,
    userPatchBody,
  );
  const onChangeUserType = useCallback((event: GenericOption<string, string>) => {
    const userType = event?.value as UserType || null;

    setUserPatchBody((u: PatchUserUpdate): PatchUserUpdate => ({
      ...u, userType,
    }));
  }, [setUserPatchBody]);

  const onChangeActiveStatus = useCallback((event: GenericOption<string, boolean>) => {
    const isActive = event?.value;

    setUserPatchBody((u: PatchUserUpdate): PatchUserUpdate => ({
      ...u, isActive,
    }));
  }, [setUserPatchBody]);

  const onChangeStakeholders = useCallback((event: Option<string>[]): void => {
    const stakeholders = event && event.length > 0 ? event.map((el) => el.value) : null;

    setUserPatchBody((u: PatchUserUpdate): PatchUserUpdate => ({
      ...u, stakeholders,
    }));
  }, [setUserPatchBody]);

  const onChangeStakeholderAccess = useCallback((event: GenericOption<string, string>) => {
    const stakeholderAccessType = event?.value as StakeholderAccessType || null;

    setUserPatchBody((u: PatchUserUpdate): PatchUserUpdate => ({
      ...u, stakeholderAccessType,
    }));
  }, [setUserPatchBody]);

  return (
    <>
      <Wrapper style={{ height: '20rem' }}>
        <Success hidden={!success}>{SUCCESS}</Success>
        <Element>
          <FormWrapper>
            <Label>{ACTIVE}</Label>
            <Select
              defaultValue={ACTIVE_OPTIONS.find((s) => s.value === user.isActive)}
              isDisabled={disabled}
              isSearchable={false}
              key={user.id}
              onChange={onChangeActiveStatus}
              options={ACTIVE_OPTIONS}
              styles={customStyles}
            />
          </FormWrapper>
          <FormWrapper>
            <Label>{USER_TYPE}</Label>
            <Select
              defaultValue={USER_TYPES.find((s) => s.value === user.userType)}
              isDisabled={disabled}
              isSearchable={false}
              key={user.id}
              onChange={onChangeUserType}
              options={USER_TYPES}
              styles={customStyles}
            />
          </FormWrapper>
          {!disabled && (
            <>
              <FormWrapper>
                <Label>{STAKEHOLDERS}</Label>
                <Select
                  isDisabled={disabled}
                  isMulti
                  key={user.id}
                  onChange={onChangeStakeholders}
                  options={stakeholderLabels}
                  styles={stakeholderSelectStyles}
                />
              </FormWrapper>
              <FormWrapper>
                <Label>{STAKEHOLDER_ACCESS}</Label>
                <Select
                  isDisabled={disabled}
                  isSearchable={false}
                  key={user.id}
                  onChange={onChangeStakeholderAccess}
                  options={ACCESS_OPTIONS}
                  styles={customStyles}
                />
              </FormWrapper>
              <ButtonsWrapper>
                <ButtonTransparent
                  disabled={disabled}
                  onClick={handleSubmit}
                >
                  {UPDATE}
                </ButtonTransparent>
                <ButtonTransparent
                  disabled={disabled}
                  onClick={handleDelete}
                >
                  {DELETE}
                </ButtonTransparent>
              </ButtonsWrapper>
            </>
          )}
        </Element>
      </Wrapper>
      <ErrorModal clearError={clearError} error={error} />
    </>
  );
};

export default UserDetailCard;
