import React, { useMemo } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import generateColumns from './utils/generateColumns';
import tableStyles from '#/shared/tableStyles';
import { OverrideChangeLogEntry } from '#/types/Override';
import { LogColumns } from './utils/helpers';

interface OverrideLogsTableProps {
  logs: OverrideChangeLogEntry[];
  selected: OverrideChangeLogEntry;
  setSelected: React.Dispatch<React.SetStateAction<OverrideChangeLogEntry>>;
  columnsToHide?: LogColumns[];
  isContainerHeight?: boolean;
}

const OverrideLogsTable: React.FC<OverrideLogsTableProps> = ({
  logs, selected, setSelected, columnsToHide, isContainerHeight,
}) => {
  const columns = useMemo(() => generateColumns(columnsToHide), [columnsToHide]);
  const scrollHeight = `calc(${isContainerHeight ? '34rem' : '65vh'} - 54px)`;

  const conditionalStyles = useMemo(() => [
    {
      when: (row: OverrideChangeLogEntry): boolean => (
        row.ids[0] === selected?.ids[0] && selected?.createdAt?.equals(row.createdAt)
      ),
      style: tableStyles.rows.selectedHighlightStyle,
    },
  ], [selected]);

  const customStyles = {
    ...tableStyles,
    rows: {
      ...tableStyles.rows,
      style: {
        ...tableStyles.rows.style,
        minHeight: '3rem',
      },
    },
  };

  return (
    <TableContainer>
      <DataTable
        columns={columns}
        conditionalRowStyles={conditionalStyles}
        customStyles={customStyles}
        data={logs}
        defaultSortAsc={false}
        defaultSortFieldId='createdAt'
        fixedHeader
        fixedHeaderScrollHeight={scrollHeight}
        highlightOnHover
        onRowClicked={setSelected}
        pointerOnHover
        responsive
        striped
      />
    </TableContainer>
  );
};

const TableContainer = styled.div`
  margin: 12px 12px 0 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgb(36 63 97 / 16%), 0px 1px 4px rgb(36 63 97 / 10%);
`;

export default OverrideLogsTable;
