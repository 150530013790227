import { AxiosResponse } from 'axios';
import { Deal, PostDealRequest } from '#/types/Deal';
import client from './_clientFormatted';

const postDeal = async (request: PostDealRequest): Promise<Deal> => {
  const response: AxiosResponse<Deal> = await client.post('/proxy/deals', request);

  return response?.data;
};

export default postDeal;
