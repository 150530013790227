import { DateTime, FixedOffsetZone } from 'luxon';

const formatEventStartsAtString = (
  eventStartsAt: DateTime,
  eventStartsAtLocal: DateTime,
): string[] => {
  const timezoneOffsetMins = eventStartsAtLocal.diff(eventStartsAt, 'minutes').minutes;
  const timezone = FixedOffsetZone.instance(timezoneOffsetMins);
  const eventStartsAtTz = eventStartsAtLocal.setZone(timezone, { keepLocalTime: true });
  const eventDateString = eventStartsAtTz.toLocaleString(DateTime.DATE_SHORT);
  const eventTimeString = eventStartsAtTz.toFormat("t 'UTC'Z");

  return [eventDateString, eventTimeString];
};

export default formatEventStartsAtString;
