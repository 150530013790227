import { Boundary } from '#/types/Preview';
import calcMax from '../calcMax';

const calculateMaxWeight = (boundaries: Boundary[]): number => {
  let max: number;
  const ONE = 1;

  boundaries.forEach((boundary: Boundary, index: number) => {
    const nextBoundary = boundaries[index + ONE] || boundary;
    const weight = nextBoundary.probability - boundary.probability;

    max = calcMax(max, weight);
  });
  return max;
};

export default calculateMaxWeight;
