import client from './_clientFormatted';
import IntegrationConfig from '#/types/IntegrationConfig';

/* eslint-disable camelcase */

interface APICall {
  status: number;
  message?: string;
}

const postIntegration = async (
  integration: IntegrationConfig,
): Promise<APICall> => {
  const response = await client.post('/proxy/integration', integration);

  return { status: response.status };
};

export default postIntegration;
