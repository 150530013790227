import client from './_client';

type APICall = (sourceID: number) => Promise<number>;

const postSourceLink: APICall = async (sourceId) => {
  const response = await client.post(`/proxy/source_event/${sourceId}/relink`);

  return response.status;
};

export default postSourceLink;
