import { DateTime } from 'luxon';
import { SinkEvent } from '#/types/Event';

interface SinkTableData {
  activatedAt: string;
  autobrokerEventId: number;
  id: number;
  listingMaxCount: number;
  listingMinCount: number;
  pVisible: number;
  marketplaces: string;
  qaMode: string;
  resetVisibility: string;
  sinkExternalEventId: string;
  sinkId: number;
  sinkName: string;
}

const marketplaceAbbreviations: { [n: string]: string } = {
  axs: 'AXS',
  fanxchange: 'FNX',
  gametime: 'GT',
  goldstar: 'GS',
  seatgeek: 'SG',
  stubhub: 'STH',
  'ticket evolution': 'TEV',
  'ticket monster': 'TMO',
  ticketmaster: 'TM',
  ticketnetwork: 'TNW',
  tickpick: 'TP',
  viagogo: 'VGO',
  'vivid seats': 'VS',
};

const formatSinkInfo = (sinkEvent: SinkEvent): SinkTableData => {
  const {
    activatedAt,
    marketplaces,
    qaMode,
    resetVisibility,
  } = sinkEvent;

  return {
    ...sinkEvent,
    activatedAt: activatedAt
      .toLocaleString(DateTime.DATETIME_SHORT)
      .replace(',', ' ·') + ' UTC',
    marketplaces: marketplaces
      .map((el) => {
        return marketplaceAbbreviations[el];
      })
      .join(', '),
    qaMode: qaMode ? 'on' : 'off',
    resetVisibility: resetVisibility ? 'on' : 'off',
  };
};

export { SinkTableData };
export default formatSinkInfo;
