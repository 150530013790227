import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import palette from '#/theme/palettes/main';
import { CollectionSelectionsContext } from '../contexts';

interface Props {
  isLoading?: boolean;
}

const LABEL = 'Only inventory with deal terms';

const IncludeOnlyDealTermInventoryCheckbox: React.FC<Props> = ({ isLoading }) => {
  const { hasDealTerm, handleDealTermFilter } = useContext(CollectionSelectionsContext);

  const handleSelection = useCallback(() => {
    if (!isLoading)
      handleDealTermFilter();
  }, [handleDealTermFilter, isLoading]);

  return (
    <Container isLoading={isLoading}>
      <Checkbox
        checked={hasDealTerm}
        disabled={isLoading}
        isLoading={isLoading}
        onChange={handleSelection}
        title={LABEL}
        type="checkbox"
      />
      <Label
        isLoading={isLoading}
        onClick={handleSelection}
      >
        {LABEL}
      </Label>
    </Container>
  );
};

interface LoadingProps {
  isLoading?: boolean;
}

const Container = styled.div<LoadingProps>`
  display: flex;
  align-items: center;
  max-width: 150px;
  margin: 4px 8px;
  padding: 2px;
  border-right: 1px solid #ddd;
  opacity: ${({ isLoading }): number => isLoading ? 0.4 : 1};
  pointer-events: ${({ isLoading }): string => isLoading ? 'none' : 'all'};
`;

const Label = styled.label<LoadingProps>`
  font-size: 14px;
  line-height: 1.1;
  letter-spacing: 0;
  cursor: ${({ isLoading }): string => isLoading ? 'default' : 'pointer'};
`;

const Checkbox = styled.input<LoadingProps>`
  height: 1rem;
  margin-right: 0.5rem;
  cursor: ${({ isLoading }): string => isLoading ? 'default' : 'pointer'};

  &:checked {
    background-color: ${palette.brand.base};
  }
  &:after {
    background-color: ${palette.brand.base};
  }
`;

export default IncludeOnlyDealTermInventoryCheckbox;
