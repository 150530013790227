import React from 'react';
import styled from 'styled-components';
import { TooltipContainer } from '@graphique/graphique';
import startCase from 'lodash/startCase';
import { formatMetricLabel } from '../utils/textFormatting';
import { formatLargeNumber } from '#/shared/formatNumberForDisplay';
import { type CumulativeMetricKey, METRIC_OPTIONS } from '../utils/menuOptions';
import type { MapRollupDatum } from '#/types/MapRollup';
import type { PickingInfo } from '@deck.gl/core';

const SECTION_LABEL = 'Section: ';
const TICKETS_SOLD = 'Tickets Sold';

interface Props {
  hoverInfo: PickingInfo<MapRollupDatum>;
  selectedMetric: CumulativeMetricKey;
}

const Tooltip: React.FC<Props> = ({ hoverInfo, selectedMetric }) => {
  return (
    <TooltipContainer style={{ position: 'absolute', left: hoverInfo.x, top: hoverInfo.y }}>
      <TooltipContent>
        <SectionInfo>
          <SectionLabel>{SECTION_LABEL}</SectionLabel>
          <div>{startCase(hoverInfo.object.label)}</div>
        </SectionInfo>
        {Object.keys(hoverInfo.object.metrics).length > 0 && (
          <Metrics>
            {Number.isFinite(hoverInfo.object.metrics.ingestedTicketCt) && (
              <MetricInfo isBold={selectedMetric === 'cumulativeTicketsSold'}>
                <MetricLabel>{`${TICKETS_SOLD}:`}</MetricLabel>
                <div>
                  {formatLargeNumber(
                    hoverInfo.object.metrics.cumulativeTicketsSold, false,
                  )}
                </div>
              </MetricInfo>
            )}
            {Object.entries(hoverInfo.object.metrics)
              .filter(([key]) => !['ingestedTicketCt', 'cumulativeTicketsSold'].includes(key))
              .map(([key, value]) => {
                const { key: metricKey, format, label } = (
                  METRIC_OPTIONS.find((m) => m.key === key)
                );
                const isSelected = metricKey === selectedMetric;

                return (
                  Number.isFinite(hoverInfo.object.metrics[metricKey]) && (
                    <MetricInfo isBold={isSelected} key={key}>
                      <MetricLabel>{`${formatMetricLabel(label)}:`}</MetricLabel>
                      <div>
                        {format({ value })}
                      </div>
                    </MetricInfo>
                  )
                );
              })}
          </Metrics>
        )}
      </TooltipContent>
    </TooltipContainer>
  );
};

const SectionInfo = styled.div`
  display: flex;
  align-items: center;
  color: #777;
  font-size: 0.85rem;
  margin: 0.25rem 0;
`;

const SectionLabel = styled.div`
  margin-right: 0.25rem;
`;

const Metrics = styled.div`
  margin-top: 0.6rem;
`;

const MetricLabel = styled.div`
  margin-right: 0.25rem;
`;

interface MetricInfoProps {
  isBold?: boolean;
}

const MetricInfo = styled.div<MetricInfoProps>`
  margin: 0.35rem 0 0.25rem 0;
  display: flex;
  align-items: center;
  font-weight: ${({ isBold }): number => isBold ? 600 : 400};
`;

const TooltipContent = styled.div`
  letter-spacing: 0;
  font-size: 0.9rem;
  line-height: 1;
`;

export default Tooltip;
