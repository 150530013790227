import React from 'react';
import { Link } from 'react-router-dom';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { Partition } from '#/types/Event';
import Plus from '#images/Plus.svg';
import Minus from '#images/Minus.svg';

interface CardProps {
  autobrokerEventId: number;
  partitions: Partition[];
}

const PARTITION = 'Partitions';
const COLLECTION = 'Collection';

const PartitionCard: React.FC<CardProps> = ({ autobrokerEventId, partitions }) => {
  const autobrokerEventIds = [
    autobrokerEventId,
    ...partitions.map((partition) => partition.autobrokerEventId),
  ].join(',');

  return (
    <NarrowWrapper>
      <Element>
        <Name>{PARTITION}</Name>
        <Value>
          <Link to={`/view/collection?autobroker_event_ids=${autobrokerEventIds}`}>
            {COLLECTION}
          </Link>
        </Value>
      </Element>
      <Element>
        {partitions.map((partition) => (
          <Value key={`partition_${partition.autobrokerEventId}`}>
            { (partition.sectionSubsetType === 'positive' || partition.sectionSubsetType === 'negative') && (
              <img
                alt="section-subset"
                height="15"
                src={partition.sectionSubsetType === 'positive' ? Plus : Minus}
                title={partition.sectionSubsetType + ' section subset'}
              />
            )}
            {' '}
            <Link to={`/view/${partition.autobrokerEventId}#Summary`}>
              {partition.autobrokerEventId}
            </Link>
          </Value>
        ))}
      </Element>
    </NarrowWrapper>
  );
};

export default PartitionCard;
