import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Event from '#/types/Event';

interface CardProps {
  event: Event;
}

const SALES = 'Sales';
const TICKETS = 'Sold Tickets';

const SalesCard: React.FC<CardProps> = ({ event }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{SALES}</Name>
        <Value>{event.summary.saleCt}</Value>
      </Element>
      <Element>
        <Name>{TICKETS}</Name>
        <Value>{event.summary.saleTicketCt}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default SalesCard;
