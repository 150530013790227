import { OverrideMap, Settings, OverrideType } from '#/types/Override';

const editDecimals = (value: number, decimalPlaces: number): number => {
  const multiplier = 10 ** decimalPlaces;

  return Math.round(value * multiplier) / multiplier;
};

const mapSettings = (parameters: OverrideMap): Settings => {
  const settings = {} as Settings;
  const keys = Object.values(OverrideType);

  keys.forEach((key: OverrideType) => {
    const rawVal = (parameters[key] as OverrideMap) as number;
    const value = editDecimals(rawVal, 2);

    settings[key] = value;
  });
  return settings;
};

export default mapSettings;
