import {
  useContext, useEffect, useState, useCallback,
} from 'react';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import formatApiError from '#/api/utils/formatApiError';
import getOverrideLogs from '#/api/getOverrideLogs';
import { OverrideChangeLogEntry } from '#/types/Override';

type Hook = () => {
  overrideError?: string;
  overrideLogs: OverrideChangeLogEntry[];
  retry: () => void;
};

const useFetchOverrideLogs: Hook = () => {
  const { activeEvent } = useContext(ActiveEventContext);
  const [overrideError, setOverrideError] = useState<string | undefined>();
  const [overrideLogs, setOverrideLogs] = useState<OverrideChangeLogEntry[]>(null);
  const id = activeEvent?.id;

  const retry = useCallback(async () => {
    setOverrideError(undefined);
    try {
      const { logs } = await getOverrideLogs(id);

      setOverrideLogs(logs);
    } catch (err) {
      const errorString = formatApiError(err);

      setOverrideError(errorString);
    }
  }, [id]);

  useEffect(() => {
    if (id)
      retry();
  }, [id, activeEvent, retry]);

  return {
    overrideError,
    overrideLogs,
    retry,
  };
};

export default useFetchOverrideLogs;
