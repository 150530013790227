import styled from 'styled-components';

const Input = styled.input`
  display: block;
  width: 98%;
  margin-top: 0.75rem;
  -webkit-appearance: none;
  background: transparent;
  &:focus {
    outline: none;
  }
  &::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.highlight};
    border-radius: 16px;
    transition: 250ms all ease-in-out;
    &:focus {
      background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.selected};
    }
  }

  &::-moz-range-thumb {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
    margin-top: -0.575rem;
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
    border: 1px solid
      ${({ theme }: { theme: Theme }): string => theme.color.border.selected};
    transition: 250ms all ease-in-out;
    &:hover {
      box-shadow: 0 2px 0.24rem rgba(15, 15, 15, 0.2);
      background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.selected};
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.slider};
    border-radius: 16px;
    transition: 250ms all ease-in-out;
    &:active {
      background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.selected};
      border-color: ${({ theme }: { theme: Theme }): string => theme.color.border.selected};
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
    margin-top: -0.575rem;
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
    ${({ theme }: { theme: Theme }): string => theme.shadows.down};
    transition: 250ms all ease-in-out;
    &:hover {
      box-shadow: 0 2px 0.24rem rgba(15, 15, 15, 0.2);
      background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.selected};
    }
  }
`;

export default Input;
