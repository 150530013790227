import { useCallback, useState, FormEvent } from 'react';
import postEventConfigUpdate from '#/api/postEventConfigUpdate';
import formatApiError from '#/api/utils/formatApiError';
import { UpdateEventConfig } from './types';
import Event from '#/types/Event';
import { FetchGroupsHook } from '#/pages/useFetchGroups';

type Hook = (
  activeEvent: Event,
  updatedConfig: UpdateEventConfig,
  retry: () => void,
  groupsHook: FetchGroupsHook
) => {
  error: string;
  handleSubmit: (event: FormEvent) => void;
  loading: boolean;
  modalIsOpen: boolean;
  success: boolean;
  toggleModal: () => void;
};

const useEventConfigModal: Hook = (activeEvent, updatedConfig, retry, groupsHook) => {
  const { fetchGroups } = groupsHook;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const toggleModal = useCallback(() => {
    setSuccess(false);
    setError('');
    setModalIsOpen(!modalIsOpen);
  }, [modalIsOpen]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.stopPropagation();
      event.preventDefault();
      setLoading(true);
      try {
        setError('');
        const updatedEvent = await postEventConfigUpdate(
          activeEvent,
          updatedConfig,
        );

        if (updatedEvent.id)
          setSuccess(true);
        retry();
        fetchGroups();
      } catch (err) {
        const errorString = formatApiError(err);

        setError(errorString);
      }
      setLoading(false);
    },
    [activeEvent, updatedConfig, retry, fetchGroups],
  );

  return {
    error,
    handleSubmit,
    loading,
    modalIsOpen,
    success,
    toggleModal,
  };
};

export default useEventConfigModal;
