import { GraphPlot } from '#/types/Snapshot';
import {
  ExpectedValueBinSnapshotFormatted,
  ExpectedValueBinMeasure,
} from '#/types/SelldownByExpectedValue';

const getCombinedEvTiersSelldown = (
  preview: GraphPlot[], formattedSnapshots: ExpectedValueBinSnapshotFormatted[],
): ExpectedValueBinSnapshotFormatted[] => {
  const mostRecentVals = (
    formattedSnapshots.filter(
      (d) => d.logHoursToEvent === Math.min(...formattedSnapshots.map((v) => v.logHoursToEvent)),
    )
  );

  const filteredPreview = preview.filter(({ x }) => x <= mostRecentVals[0].logHoursToEvent);
  const previewSelldownMaxTarget = Math.max(...filteredPreview.map((d) => d.y));

  const formattedPreview = mostRecentVals.flatMap((v) => (
    filteredPreview.map(({ x, y }) => {
      const targetFraction = y / previewSelldownMaxTarget;

      return ({
        selldownActual: v.selldownActual * targetFraction,
        ticketsRemaining: v.ticketsRemaining * targetFraction,
        logHoursToEvent: x,
        group: v.group,
        measure: ExpectedValueBinMeasure.TARGET,
      });
    })
  ));

  // used to join the preview to the actual selldown
  const formattedIntersections = mostRecentVals.map((v) => {
    return ({
      ...v,
      measure: ExpectedValueBinMeasure.TARGET,
      isIntersection: true,
    });
  });

  const combinedData = [
    ...formattedSnapshots,
    ...formattedPreview,
    ...formattedIntersections,
  ]
    .sort((a, b) => (
      b.logHoursToEvent - a.logHoursToEvent
      || (b.measure < a.measure ? 1 : -1)
      || (a.group.lower - b.group.lower)
    ));

  return combinedData;
};

export default getCombinedEvTiersSelldown;
