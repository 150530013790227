import React from 'react';
import styled from 'styled-components';

const renderResponse = (
  error: string,
  success: boolean,
): React.ReactElement => {
  const SUCCESS = 'Success!';

  if (error)
    return <Error>{error}</Error>;

  if (success)
    return <Success>{SUCCESS}</Success>;

  return <div />;
};

const Error = styled.div`
  color: ${({ theme }: { theme: Theme }): string => theme.color.action.warning};
`;

const Success = styled.div`
  color: ${({ theme }: { theme: Theme }): string => theme.color.action.money};
`;

export default renderResponse;
