import React from 'react';
import styled from 'styled-components';
import Button from '#/shared/Button';
import Loader from '#/shared/Loader';

const RESET = 'Reset';
const SAVE = 'Save';

interface SaveButtonProps {
  saveSettings: () => void;
  saveInProgress: boolean;
}

const SaveButton: React.FC<SaveButtonProps> = ({
  saveSettings,
  saveInProgress,
}) => {
  if (saveInProgress) {
    return (
      <Button disabled>
        <Loader size={0.75} />
      </Button>
    );
  }
  return <Button onClick={saveSettings}>{SAVE}</Button>;
};

interface ButtonsProps {
  saveInProgress: boolean;
  saveError: string;
  resetSettings: () => void;
  saveSettings: () => void;
}

const Buttons: React.FC<ButtonsProps> = ({
  resetSettings,
  saveSettings,
  saveInProgress,
  saveError,
}) => (
  <>
    {saveError && <Container><Error>{saveError}</Error></Container>}
    <Container className="graph-control-buttons">
      <Button onClick={resetSettings}>{RESET}</Button>
      <SaveButton saveInProgress={saveInProgress} saveSettings={saveSettings} />
    </Container>
  </>
);

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
`;

const Error = styled.div`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.onError};
`;

export default Buttons;
