import React, {
  useState, ChangeEvent, useCallback, useEffect,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Select from 'react-select';
import {
  SectionWrapper,
  InnerWrapper,
  Title,
  Label,
  Error,
  Input,
  ButtonsWrapper,
  Content,
  modalMenuProps,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import postOverridesCsv, { FileType } from '#/api/postOverridesCsv';
import CsvSchemaType from '#/types/Csv';
import formatApiError from '#/api/utils/formatApiError';
import Loader from '#/shared/Loader';
import modalStyles from '../modalStyles';
import useSetupModal from '#/pages/useSetupModal';
import { PriceGranularity } from '#/types/Event';
import type { GenericOption } from '#/types/GenericOption';

const TITLE = 'Upload Input Inventory Overrides';
const DESCRIPTION = 'Upload overrides, of various granularities, that will apply to available inventory and affect pricing or availability.';
const FILE = 'Overrides File';
const FILE_TYPE = 'File Type';
const FILE_TYPES = [
  { value: FileType.CSV, label: 'CSV' },
];
const CSV_SCHEMA_TYPE = 'CSV Schema Type';
const CSV_SCHEMA_TYPES = [
  { value: CsvSchemaType.FIXED_COLUMNS_WITHOUT_HEADER, label: 'Fixed Columns Without Header' },
  { value: CsvSchemaType.VARIABLE_COLUMNS_WITH_REQUIRED_HEADER, label: 'Variable Columns With Required Header' },
];
const GRANULARITY = 'Granularity';
const GRANULARITY_OPTIONS = [
  { value: null, label: 'Any' },
  { value: PriceGranularity.EVENT, label: 'Event' },
  { value: PriceGranularity.PRICE_LEVEL, label: 'Price Level' },
  { value: PriceGranularity.SECTION, label: 'Section' },
  { value: PriceGranularity.ROW, label: 'Row' },
  { value: PriceGranularity.INGESTION, label: 'Ingestion' },
  { value: PriceGranularity.LISTING, label: 'Listing' },
];

const SUBMIT = 'Submit';
const CANCEL = 'Cancel';

interface UploadOverridesModalProps {
  closeModal: (refresh: boolean) => void;
}

const UploadOverridesModal: React.FC<UploadOverridesModalProps> = ({
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [file, setFile] = useState<File>(null);
  const [fileType, setFileType] = useState<FileType>(FileType.CSV);
  const [
    csvSchemaType, setCsvSchemaType,
  ] = useState<CsvSchemaType>(CsvSchemaType.VARIABLE_COLUMNS_WITH_REQUIRED_HEADER);
  const [granularity, setGranularity] = useState<PriceGranularity>(null);

  useEffect(() => Modal.setAppElement('body'), []);

  const onChangeFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event?.currentTarget?.files[0];

    if (newFile)
      setFile(newFile);
  }, [setFile]);
  const onChangeType = useCallback((event: GenericOption<string, FileType>) => {
    setFileType(event.value);
  }, [setFileType]);
  const onChangeCsvSchemaType = useCallback((event: GenericOption<string, CsvSchemaType>) => {
    setCsvSchemaType(event.value);
  }, [setCsvSchemaType]);
  const onChangeGranularity = useCallback((event: GenericOption<string, PriceGranularity>) => {
    setGranularity(event.value);
  }, [setGranularity]);

  const onSubmit = useCallback(async () => {
    if (!file) {
      setError('Please select a file.');
      return;
    }
    setIsLoading(true);
    try {
      await postOverridesCsv(file, fileType, csvSchemaType, granularity);
      setError(null);
      setIsLoading(false);
      closeModal(true);
    } catch (err) {
      setError(formatApiError(err));
      setIsLoading(false);
    }
  }, [file, fileType, csvSchemaType, granularity, closeModal]);

  const onCancel = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  useSetupModal(onCancel);

  return (
    <Modal
      isOpen
      onRequestClose={onCancel}
      style={modalStyles}
    >
      <Title>{TITLE}</Title>
      <SectionWrapper>
        <InnerWrapper>
          <Description>{DESCRIPTION}</Description>
        </InnerWrapper>
        <Content>
          <InnerWrapper>
            <Label>{FILE}</Label>
            <Input
              accept="csv"
              onChange={onChangeFile}
              type="file"
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{GRANULARITY}</Label>
            <Select
              onChange={onChangeGranularity}
              options={GRANULARITY_OPTIONS}
              value={GRANULARITY_OPTIONS.find((v) => v.value === granularity)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...modalMenuProps}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{FILE_TYPE}</Label>
            <Select
              onChange={onChangeType}
              options={FILE_TYPES}
              value={FILE_TYPES.find((t) => t.value === fileType)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...modalMenuProps}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{CSV_SCHEMA_TYPE}</Label>
            <Select
              onChange={onChangeCsvSchemaType}
              options={CSV_SCHEMA_TYPES}
              value={CSV_SCHEMA_TYPES.find((v) => v.value === csvSchemaType)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...modalMenuProps}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Error>{error}</Error>
          </InnerWrapper>
        </Content>
        {isLoading
          ? <Loader />
          : (
            <ButtonsWrapper>
              <Button onClick={onSubmit}>{SUBMIT}</Button>
              <Button onClick={onCancel}>{CANCEL}</Button>
            </ButtonsWrapper>
          )}
      </SectionWrapper>
    </Modal>
  );
};

const Description = styled.p``;

export default UploadOverridesModal;
