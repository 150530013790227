import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

interface SkeletonProps {
  height?: string;
  width?: string;
  background?: string;
}

const Skeleton = styled.div.attrs<SkeletonProps>(
  ({
    height = '2.5rem',
    width = '100%',
    background = '#e9e9e9',
  }) => ({
    style: {
      width,
      height,
      background,
    },
  }),
)<SkeletonProps>`
  transition-property: height, width;
  transition-duration: 0.5s;
  transition-timing-function: ease-out; 
  position: relative;
  overflow: hidden;
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      rgba(255,255,255,0) 0,
      rgba(255,255,255,0.2) 20%,
      rgba(255,255,255,0.5) 60%,
      rgba(255,255,255,0)
    );
    animation: ${shimmer} 1.3s infinite ease-in-out;
    content: '';
  }
`;

export default Skeleton;
