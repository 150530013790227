import React from 'react';
import EventErrorCard from './EventErrorCard';
import ListingErrorCard from './ListingErrorCard';
import NoErrorsCard from './NoErrorsCard';
import { CardsWrapper } from '#/shared/Card';
import { User } from '#/types/User';
import Errors, { EventError, ListingError } from '#/types/Errors';

interface EventConfigTableProps {
  user: User;
  errors: Errors;
  openEventErrorModal: (event: EventError) => void;
  openListingErrorModal: (listing: ListingError) => void;
  openListingOverrideModal: (listing: ListingError) => void;
}

const ErrorCards: React.FC<EventConfigTableProps> = ({
  user,
  errors,
  openEventErrorModal,
  openListingErrorModal,
  openListingOverrideModal,
}) => {
  const eventErrors = errors.event.sort((a, b) => a.errorLevel > b.errorLevel ? 1 : -1);

  return (
    <CardsWrapper>
      {eventErrors.map((error) => (
        <EventErrorCard
          disabled={user.isReadOnly}
          error={error}
          key={`error_${error.id}`}
          // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
          openNotesModal={(): void => openEventErrorModal(error)}
        />
      ))}
      {errors.listings.map((listing) => (
        <ListingErrorCard
          disabled={user.isReadOnly}
          key={`listing_${listing.id}`}
          listing={listing}
          // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
          openEditModal={(): void => openListingOverrideModal(listing)}
          // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
          openNotesModal={(): void => openListingErrorModal(listing)}
        />
      ))}
      {errors.event.length === 0 && errors.listings.length === 0 && (
        <NoErrorsCard />
      )}
    </CardsWrapper>
  );
};

export default ErrorCards;
