import { TooltipContent } from '@graphique/graphique';
import { ExpectedValueBinSnapshotFormatted as EvSnapshot } from '#/types/SelldownByExpectedValue';

const excludeIntersection = (
  value?: TooltipContent<EvSnapshot>[],
): TooltipContent<EvSnapshot>[] => {
  // exclude the repeated groups in a selldown chart that share the intersection point
  // (actual and target measurements)
  // reverse to provide a natural order when reading top to bottom
  const groupValues: TooltipContent<EvSnapshot>[] = [];

  if (value) {
    value.reverse().forEach((v) => {
      const datum: EvSnapshot[] = [];

      v.datum.forEach((d) => {
        const isIntersection = d.isIntersection;

        if (!isIntersection)
          datum.push(d);
      });
      if (!groupValues.map((gv) => gv.group).includes(v.group)) {
        groupValues.push({
          ...v,
          datum,
        });
      }
    });
  }
  return groupValues;
};

export default excludeIntersection;
