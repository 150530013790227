import React, { useState, useReducer } from 'react';
import type { APICall } from '#/api/postBulkOverrides';
import { OverrideOperation, type RequestedOverride, type RollupOverrideParams } from '#/types/ReportingRollup';
import overridesReducer from '../utils/overridesReducer';

interface OverridesContextValue {
  isVisibleRequestedOverridesModal?: boolean
  setIsVisibleRequestedOverridesModal: React.Dispatch<React.SetStateAction<boolean>>
  setRequestedOverrides: React.Dispatch<React.SetStateAction<RequestedOverride[]>>
  requestedOverrides: RequestedOverride[]
  overridesSubmission: APICall
  setOverridesSubmission: React.Dispatch<React.SetStateAction<APICall>>
  isLoadingOverridesSubmission?: boolean
  setIsLoadingOverridesSubmission: React.Dispatch<React.SetStateAction<boolean>>
  overridesState: RollupOverrideParams[]
  dispatchOverrides: React.Dispatch<RollupOverrideParams | null>
  overrideMode: OverrideOperation
  setOverrideMode: React.Dispatch<React.SetStateAction<OverrideOperation>>
}

const CollectionOverridesContext = React.createContext<OverridesContextValue>(null);

const CollectionOverridesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [
    isVisibleRequestedOverridesModal, setIsVisibleRequestedOverridesModal,
  ] = useState<boolean>();
  const [requestedOverrides, setRequestedOverrides] = useState<RequestedOverride[]>();
  const [overridesSubmission, setOverridesSubmission] = useState<APICall>();
  const [isLoadingOverridesSubmission, setIsLoadingOverridesSubmission] = useState(false);
  const [overridesState, dispatchOverrides] = useReducer(overridesReducer, []);
  const [overrideMode, setOverrideMode] = useState<OverrideOperation>(OverrideOperation.SET);

  const value: OverridesContextValue = {
    isVisibleRequestedOverridesModal,
    setIsVisibleRequestedOverridesModal,
    requestedOverrides,
    setRequestedOverrides,
    overridesSubmission,
    setOverridesSubmission,
    isLoadingOverridesSubmission,
    setIsLoadingOverridesSubmission,
    overridesState,
    dispatchOverrides,
    overrideMode,
    setOverrideMode,
  };

  return (
    <CollectionOverridesContext.Provider value={value}>
      {children}
    </CollectionOverridesContext.Provider>
  );
};

export {
  CollectionOverridesContext,
  CollectionOverridesProvider,
  type OverridesContextValue,
};
