import client from './_client';
import Sink from '#/types/Sink';
import mapSink from './utils/mapSink';

const getSinks = async (): Promise<Sink[]> => {
  const response = await client.get('/proxy/sink');
  const rawSinks = response?.data?.sinks || [];

  return rawSinks.map(mapSink);
};

export default getSinks;
