import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import {
  ApiSnapshotResponse,
  ListingsGraphInfo,
  SelldownGraphInfo,
  PriceRatioGraphInfo,
} from '#/types/Snapshot';
import formatSelldownSnapshots from './utils/formatSelldownSnapshots';
import formatPriceRatioSnapshots from './utils/formatPriceRatioSnapshots';
import formatListingsSnapshots from './utils/formatListingsSnapshots';

interface SnapshotsResponse {
  listingsGraphInfo: ListingsGraphInfo;
  priceRatioGraphInfo: PriceRatioGraphInfo;
  selldownGraphInfo: SelldownGraphInfo;
}

const getSnapshots = async (eventId: number): Promise<SnapshotsResponse> => {
  const response: AxiosResponse<ApiSnapshotResponse> = await client.get(
    `/proxy/history/${eventId}?n_samples=1024`,
  );

  const rawSnapshots = response?.data?.snapshots || [];
  const selldownGraphInfo = formatSelldownSnapshots(rawSnapshots);
  const priceRatioGraphInfo = formatPriceRatioSnapshots(rawSnapshots);
  const listingsGraphInfo = formatListingsSnapshots(rawSnapshots);

  return {
    listingsGraphInfo,
    priceRatioGraphInfo,
    selldownGraphInfo,
  };
};

export default getSnapshots;
