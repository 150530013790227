import { AxiosResponse } from 'axios';
import Group, { GroupState } from '#/types/Group';
import client from './_clientFormatted';

const postGroup = async (
  request: GroupState,
): Promise<Group> => {
  const response: AxiosResponse<Group> = await client.post('/proxy/groups', request);

  return response?.data;
};

export default postGroup;
