import React, {
  useContext, useState, useCallback,
} from 'react';
import styled from 'styled-components';
import { ActiveEventContext } from '../../contexts/ActiveEvent';
import SummaryCards from './SummaryCards';
import SummaryHeader from './SummaryHeader';
import BreakdownHeader from './BreakdownHeader';
import BreakdownTable from './BreakdownTable';
import ErrorCards from './ErrorCards';
import ErrorStatusModal, { ErrorStatusProps } from '#/shared/ErrorStatusModal';
import ListingOverrideModal, { InputListing } from '#/shared/ListingOverrideModal';
import { User } from '#/types/User';
import { EventError, ListingError, ErrorType } from '#/types/Errors';
import { ValueField } from '#/types/Event';
import Loader from '#/shared/Loader';
import palette from '#/theme/palettes/main';
import { RollupGroupVar } from '#/types/ReportingRollup';

const ERRORS = 'Errors';

interface SummaryProps {
  user: User;
  error: boolean;
  loading: boolean;
}

const Summary: React.FC<SummaryProps> = ({ user, error, loading }) => {
  const { activeEvent, retrieveActiveEvent } = useContext(ActiveEventContext);
  const [errorStatusModal, setErrorStatusModal] = useState<ErrorStatusProps>({
    title: '',
    description: '',
    isOpen: false,
    existing: null,
    reference: null,
  });
  const [listingOverrideModal, setListingOverrideModal] = useState<InputListing[]>([]);
  const [valueField, setValueField] = useState<ValueField>(ValueField.COST);
  const [showBreakdown, setShowBreakdown] = useState<boolean>(false);
  const [breakdownGroupBy, setBreakdownGroupBy] = useState<RollupGroupVar>('deal_term');
  const [breakdownHasDealTerm, setBreakdownHasDealTerm] = useState<boolean>(false);
  const showResults = activeEvent && !error && !loading;

  const closeErrorStatusModal = useCallback((refresh: boolean) => {
    if (refresh && activeEvent)
      retrieveActiveEvent(activeEvent.id);

    setErrorStatusModal({
      title: '', description: '', isOpen: false, existing: null, reference: null,
    });
  }, [activeEvent, retrieveActiveEvent]);

  const closeListingOverrideModal = useCallback((refresh: boolean) => {
    if (refresh && activeEvent)
      retrieveActiveEvent(activeEvent.id);

    setListingOverrideModal([]);
  }, [activeEvent, retrieveActiveEvent]);

  const openEventErrorModal = useCallback((event: EventError) => {
    setErrorStatusModal({
      title: 'Annotate Event Error',
      description: `${event.errorType}\n${event.errorReason}`,
      isOpen: true,
      existing: event.errorStatus,
      reference: {
        id: event.id,
        type: ErrorType.AUTOBROKER_EVENT_ERRORS,
      },
    });
  }, []);

  const openListingErrorModal = useCallback((listing: ListingError) => {
    setErrorStatusModal({
      title: 'Annotate Listing Error',
      description: `${listing.errorType}\n${listing.errorReason}`,
      isOpen: true,
      existing: listing.errorStatus,
      reference: {
        id: listing.id,
        type: ErrorType.INPUT_INVENTORY_ERRORS,
      },
    });
  }, []);

  const openListingOverrideModal = useCallback((listing: ListingError) => {
    const inputListing = {
      autobroker_event_id: listing.autobrokerEventId,
      source_name: listing.sourceName,
      source_external_event_id: listing.sourceExternalEventId,
      source_inventory_id: listing.sourceInventoryId,
      ingestion_listing_id: listing.ingestionListingId,
      section: listing.section,
      row: listing.row,
    };

    setListingOverrideModal([inputListing]);
  }, []);

  const showBreakdownTable = useCallback(() => (
    setShowBreakdown((i) => !i)
  ), [setShowBreakdown]);

  return (
    <div id="summary">
      <Wrapper>
        {showResults && (
          <>
            <Section>
              <SummaryHeader
                event={activeEvent}
                setValueField={setValueField}
                showBreakdown={showBreakdownTable}
                valueField={valueField}
              />
              <SummaryCards
                event={activeEvent}
                valueField={valueField}
              />
            </Section>
            {showBreakdown && activeEvent && activeEvent.summary.ingestedTicketCt > 0 && (
              <Section>
                <BreakdownHeader
                  groupBy={breakdownGroupBy}
                  hasDealTerm={breakdownHasDealTerm}
                  setGroupBy={setBreakdownGroupBy}
                  setHasDealTerm={setBreakdownHasDealTerm}
                />
                <BreakdownTable
                  autobrokerEventId={activeEvent?.id}
                  filterHasDealTerm={breakdownHasDealTerm}
                  groupBy={breakdownGroupBy}
                  setFilterHasDealTerm={setBreakdownHasDealTerm}
                />
              </Section>
            )}
            {typeof activeEvent.errors !== 'undefined' && (
              <Section>
                <Title>{ERRORS}</Title>
                <ErrorCards
                  errors={activeEvent.errors}
                  openEventErrorModal={openEventErrorModal}
                  openListingErrorModal={openListingErrorModal}
                  openListingOverrideModal={openListingOverrideModal}
                  user={user}
                />
              </Section>
            )}
          </>
        )}
        {loading && (
          <Section>
            <Loader hexColor={palette.brand.base} />
          </Section>
        )}
        {errorStatusModal.isOpen && (
          <ErrorStatusModal
            closeModal={closeErrorStatusModal}
            props={errorStatusModal}
          />
        )}
        {listingOverrideModal.length > 0 && (
          <ListingOverrideModal
            closeModal={closeListingOverrideModal}
            editOnlyCurrentGranularity={false}
            listings={listingOverrideModal}
          />
        )}
      </Wrapper>
    </div>
  );
};

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const Wrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 8rem;
  width: 100%;
  margin-top: 3.75rem;
`;

export default Summary;
