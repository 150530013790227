import CsvSchemaType from '#/types/Csv';
import { PriceGranularity } from '#/types/Event';
import client from './_client';

enum FileType {
  CSV = 'csv_file',
}

type APICall = (
  file: File,
  type: FileType,
  csvSchemaType: CsvSchemaType,
  granularity?: PriceGranularity,
  autobrokerEventId?: number,
  notes?: string,
  group?: string,
) => Promise<number>;

const postOverridesCsv: APICall = async (
  file, type, csvSchemaType, granularity, autobrokerEventId, notes, group,
) => {
  const formData = new FormData();

  formData.append('csv_schema_type', csvSchemaType);
  formData.append(type, file);
  if (granularity)
    formData.append('granularity', granularity);
  if (autobrokerEventId)
    formData.append('autobroker_event_id', autobrokerEventId.toString());
  if (group)
    formData.append('group', group);
  if (notes)
    formData.append('notes', notes);

  const response = await client.post('/proxy/overrides/bulk/listings', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.status;
};

export default postOverridesCsv;
export { FileType, CsvSchemaType };
