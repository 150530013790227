import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import { User, UsersListResponse, UserFilters } from '#/types/User';

const getUsersList = async (
  filters: UserFilters,
): Promise<User[]> => {
  const response: AxiosResponse<UsersListResponse> = await client.get('/auth/users', { params: filters });

  return response.data.users.map((u: Partial<User>) => new User(u));
};

export default getUsersList;
