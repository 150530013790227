import React from 'react';
import Loader from '#/shared/Loader';
import palette from '#/theme/palettes/main';

interface LoadingProps {
  loading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ loading }) => {
  return loading ? <Loader hexColor={palette.brand.base} /> : null;
};

export default Loading;
