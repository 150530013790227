import React, { useMemo } from 'react';

import { Wrapper, Section } from '#/shared/Detail';
import { OverrideChangeLogEntry } from '#/types/Override';
import OverrideLogsDetailHeader from './OverrideLogsDetailHeader';
import ChangeCard from './ChangeCard';
import NotesCard from './NotesCard';
import GroupsCard from './GroupsCard';

interface OverrideLogsDetailProps {
  log: OverrideChangeLogEntry;
  closeDetail: () => void;
  closeable: boolean;
  setOverrideNotesModal: (log: OverrideChangeLogEntry) => void;
  disabled: boolean;
}

const OverrideLogsDetail: React.FC<OverrideLogsDetailProps> = ({
  log,
  closeDetail,
  closeable,
  setOverrideNotesModal,
  disabled,
}) => {
  const showGroups = useMemo(() => log.groups && log.groups.length > 0, [log]);

  return (
    <Wrapper>
      <Section>
        <OverrideLogsDetailHeader
          closeDetail={closeDetail}
          closeable={closeable}
          disabled={disabled}
          log={log}
          setOverrideNotesModal={setOverrideNotesModal}
        />
      </Section>
      <Section>
        {showGroups && (
          <GroupsCard log={log} />
        )}
        <ChangeCard log={log} />
        <NotesCard log={log} />
      </Section>
    </Wrapper>
  );
};

export default OverrideLogsDetail;
