import { TableColumn } from 'react-data-table-component';
import { OverrideChangeLogEntry } from '#/types/Override';
import {
  formatColName,
  formatTableCell,
  LogColumns,
  growTableCell,
  renderTableCell,
} from './helpers';

const generateColumns = (
  columnsToHide: LogColumns[] = [],
): TableColumn<OverrideChangeLogEntry>[] => {
  return Object.values(LogColumns)
    .map((key: keyof OverrideChangeLogEntry) => ({
      id: key,
      name: formatColName(key),
      selector: (row: OverrideChangeLogEntry): any => row[key],
      sortable: true,
      cell: renderTableCell(key),
      wrap: key === LogColumns.NOTES || key === LogColumns.CHANGES,
      omit: key === LogColumns.GROUPS || columnsToHide.includes(key as LogColumns),
      format: formatTableCell(key),
      grow: growTableCell(key),
    }));
};

export default generateColumns;
