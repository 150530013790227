import { AxiosResponse } from 'axios';
import client from './_client';

interface Response {
  aggressiveness: number;
}

const getOverrideAggressivenessHelper = async (
  eventId: number,
  selldownTarget: number,
  daysFromNow: number,
): Promise<number> => {
  const url = `/proxy/overrides/${eventId}/helper/aggressiveness`;
  const params = `selldown_target=${selldownTarget}&days_from_now=${daysFromNow}`;
  const response: AxiosResponse<Response> = await client.get(`${url}?${params}`);

  return response?.data?.aggressiveness;
};

export default getOverrideAggressivenessHelper;
