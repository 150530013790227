import React, {
  useState, ChangeEvent, useCallback, useEffect,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  SectionWrapper,
  InnerWrapper,
  Title,
  Label,
  Error,
  Input,
  ButtonsWrapper,
  Content,
  modalMenuProps,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import postIngestionCsv, { FileType } from '#/api/postIngestionCsv';
import CsvSchemaType from '#/types/Csv';
import formatApiError from '#/api/utils/formatApiError';
import Loader from '#/shared/Loader';
import modalStyles from '../modalStyles';
import useSetupModal from '#/pages/useSetupModal';

interface FileTypeOption {
  value: FileType;
  label: string;
}

interface CsvSchemaTypeOption {
  value: CsvSchemaType;
  label: string;
}

const TITLE = 'Upload Inventory';
const DESCRIPTION = 'Upload inventory in either CSV or ZIP format';
const FILE = 'Inventory File';
const FILE_TYPE = 'File Type';
const FILE_TYPES: FileTypeOption[] = [
  { value: FileType.CSV, label: 'CSV' },
  { value: FileType.ZIP, label: 'ZIP' },
];
const CSV_SCHEMA_TYPE = 'CSV Schema Type';
const CSV_SCHEMA_TYPES: CsvSchemaTypeOption[] = [
  { value: CsvSchemaType.FIXED_COLUMNS_WITHOUT_HEADER, label: 'Fixed Columns Without Header' },
  { value: CsvSchemaType.VARIABLE_COLUMNS_WITH_REQUIRED_HEADER, label: 'Variable Columns With Required Header' },
];

const SUBMIT = 'Submit';
const CANCEL = 'Cancel';

interface UploadInventoryModalProps {
  closeModal: (refresh: boolean) => void;
}

const UploadInventoryModal: React.FC<UploadInventoryModalProps> = ({
  closeModal,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [file, setFile] = useState<File>(null);
  const [fileType, setFileType] = useState<FileType>(FileType.CSV);
  const [
    csvSchemaType, setCsvSchemaType,
  ] = useState<CsvSchemaType>(CsvSchemaType.FIXED_COLUMNS_WITHOUT_HEADER);

  useEffect(() => Modal.setAppElement('body'), []);

  const onChangeFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event?.currentTarget?.files[0];

    if (newFile) {
      setFile(newFile);

      const extension = newFile.name.split('.').pop();

      if (extension === 'zip')
        setFileType(FileType.ZIP);
      else if (extension === 'csv')
        setFileType(FileType.CSV);
    }
  }, [setFile]);
  const onChangeType = useCallback((option: FileTypeOption) => {
    setFileType(option.value);
  }, [setFileType]);
  const onChangeCsvSchemaType = useCallback((option: CsvSchemaTypeOption) => {
    setCsvSchemaType(option.value);
  }, [setCsvSchemaType]);

  const onSubmit = useCallback(async () => {
    if (!file) {
      setError('Please select a file.');
      return;
    }
    setIsLoading(true);
    try {
      await postIngestionCsv(file, fileType, csvSchemaType);
      setError(null);
      setIsLoading(false);
      closeModal(true);
      navigate('/ingestions');
    } catch (err) {
      setError(formatApiError(err));
      setIsLoading(false);
    }
  }, [file, fileType, csvSchemaType, closeModal, navigate]);

  const onCancel = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  useSetupModal(onCancel);

  return (
    <Modal
      isOpen
      onRequestClose={onCancel}
      style={modalStyles}
    >
      <Title>{TITLE}</Title>
      <SectionWrapper>
        <InnerWrapper>
          <Description>{DESCRIPTION}</Description>
        </InnerWrapper>
        <Content>
          <InnerWrapper>
            <Label>{FILE}</Label>
            <Input
              accept="csv, zip"
              onChange={onChangeFile}
              type="file"
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{FILE_TYPE}</Label>
            <Select
              onChange={onChangeType}
              options={FILE_TYPES}
              value={FILE_TYPES.find((t) => t.value === fileType)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...modalMenuProps}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{CSV_SCHEMA_TYPE}</Label>
            <Select
              onChange={onChangeCsvSchemaType}
              options={CSV_SCHEMA_TYPES}
              value={CSV_SCHEMA_TYPES.find((v) => v.value === csvSchemaType)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...modalMenuProps}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Error>{error}</Error>
          </InnerWrapper>
        </Content>
        {isLoading
          ? <Loader />
          : (
            <ButtonsWrapper>
              <Button onClick={onSubmit}>{SUBMIT}</Button>
              <Button onClick={onCancel}>{CANCEL}</Button>
            </ButtonsWrapper>
          )}
      </SectionWrapper>
    </Modal>
  );
};

const Description = styled.p``;

export default UploadInventoryModal;
