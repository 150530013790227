import palette from '#/theme/palettes/main';

const getDiffColor = (diff: number, hasPreviousOverride: boolean): string => {
  if (diff > 0)
    return palette.mint.base;

  if (diff < 0 || hasPreviousOverride)
    return palette.red.dark;

  return '#000';
};

export default getDiffColor;
