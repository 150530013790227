import client from './_clientFormatted';
import { PreviewData, GetPreviewParams } from '#/types/Preview';
import formatPreviewData from './utils/formatPriceDistributionData';
import formatPreviewSnapshots from './utils/formatPreviewSnapshots';
import {
  formatPriceDistributionListings,
  getUniqueMarketplaces,
} from '#/api/utils/formatPriceDistributionData/formatPriceDistributionListings';

const getPreview = async (eventId: number, params: GetPreviewParams): Promise<PreviewData> => {
  const response = await client.get(
    `/proxy/preview/${eventId}`,
    { params },
  );
  const rawDistributions = response?.data?.distributions || null;
  const rawSnapshots = response?.data?.selldown || null;
  const rawListings = response?.data?.listings || null;

  const priceDistributionGraphInfo = formatPreviewData(rawDistributions);
  const selldownTargetInfo = formatPreviewSnapshots(rawSnapshots, false);
  const priceDistributionListings = formatPriceDistributionListings(rawListings);
  const listingMarketplaces = getUniqueMarketplaces(rawListings);

  return {
    priceDistributionGraphInfo,
    selldownTargetInfo,
    priceDistributionListings,
    listingMarketplaces,
  };
};

export default getPreview;
