import React, { useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { ScaleOverrideType, SetOverrideType, ShiftOverrideType } from '#/types/Override';
import NumberField from '#/shared/NumberField';
import { LABELS, MAXS, MINS } from '#/shared/OverrideParams';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import SVG from 'react-inlinesvg';
import Clear from '#images/Clear.svg';
import { OverrideOperation, RollupOverrideParams } from '#/types/ReportingRollup';
import { CollectionOverridesContext } from '../../contexts';
import { MAX_SCALE_VALUE, MIN_SCALE_VALUE } from '../../constants';

interface Props {
  override: SetOverrideType | ShiftOverrideType | ScaleOverrideType
  isLoading: boolean
  onChange: React.Dispatch<RollupOverrideParams>
  onSelect: React.Dispatch<RollupOverrideParams>
  overridesState: RollupOverrideParams[]
}

const ClearableOverrideInput: React.FC<Props> = ({
  override, isLoading, onChange, overridesState,
}) => {
  const thisOverride = useMemo(() => (
    overridesState.find((d) => d?.type === override)
  ), [overridesState, override]);

  const { overrideMode } = useContext(CollectionOverridesContext);

  const handleClear = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (thisOverride?.operation === OverrideOperation.CLEAR)
      onChange({ type: override, operation: overrideMode, value: null });
    else
      onChange({ type: override, operation: OverrideOperation.CLEAR, value: null });
  }, [onChange, override, thisOverride, overrideMode]);

  const isCleared = useMemo(() => (
    thisOverride?.operation === OverrideOperation.CLEAR
  ), [thisOverride]);

  const handleChange = useCallback((value: number): void => {
    onChange({ operation: overrideMode, value, type: override });
  }, [onChange, override, overrideMode]);

  // make min/max input values dependent on override mode
  const isAdditionalTickets = override === SetOverrideType.ADDITIONAL_TICKETS;
  const isScale = overrideMode === OverrideOperation.SCALE;
  const isShiftOrScale = isScale || overrideMode === OverrideOperation.SHIFT;
  const isUnconstrained = isAdditionalTickets || isShiftOrScale;

  let minVal: number | undefined;
  let maxVal: number | undefined;

  if (isScale) {
    minVal = MIN_SCALE_VALUE;
    maxVal = MAX_SCALE_VALUE;
  } else if (!isUnconstrained) {
    minVal = MINS[override];
    maxVal = MAXS[override];
  }

  return (
    <OverrideContainer>
      <NumberField
        id={override}
        isDisabled={isLoading || isCleared}
        label={LABELS[override]}
        maxValue={maxVal}
        minValue={minVal}
        onChange={handleChange}
        step={isAdditionalTickets ? 5 : undefined}
        value={thisOverride?.value ?? NaN}
      />
      <ButtonContainer>
        <SmallButton
          data-testid={`clear-${override}`}
          isDisabled={isLoading}
          kind={isCleared ? 'primary' : 'secondary'}
          label={<SVG height={16} src={Clear} width={16} />}
          onClick={handleClear}
          padding='0.2rem 0.3rem 0.2rem 0.22rem'
          title='Clear existing override'
        />
      </ButtonContainer>
    </OverrideContainer>
  );
};

const OverrideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 1;
  padding: 0.75rem 0;
  :not(:last-child) {
    border-bottom: 1px solid #dfdfdf;
  }
`;

const ButtonContainer = styled.div`
  align-self: end;
`;

export default ClearableOverrideInput;
