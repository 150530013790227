import React from 'react';
import styled from 'styled-components';
import SkeletonBar from '../../SkeletonBar';

interface LoadingComponentProps {
  height?: string;
  width?: string;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ height = '36px', width = '75px' }) => (
  <Container width={width}>
    <SkeletonBar height={height} />
  </Container>
);

const Container = styled.div`
  margin: 10px 0 0 2px;
  width: ${({ width }: { width: string }): string => width};
`;

export default LoadingComponent;
