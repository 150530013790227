import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import { UpdateSalesPeriods, SalesPeriod } from '#/types/Event';

interface SalesPeriods {
  salesPeriods: SalesPeriod[];
}

const patchSalesPeriods = async (
  autobrokerEventId: number,
  request: UpdateSalesPeriods,
): Promise<SalesPeriod[]> => {
  const response: AxiosResponse<SalesPeriods> = (
    await client.patch(`/proxy/sales_periods/${autobrokerEventId}`, request)
  );

  return response?.data?.salesPeriods;
};

export default patchSalesPeriods;
