import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import type { EventTimeSeriesSalesSummary } from '../utils/getSalesTrendsByEvent';
import {
  Control, ControlBar, TimeIntervalMenu, RelatedControls,
} from '../Controls';
import { getDefaultFromDatetime, validateTransactionDateRange, generateTransactionDateRangeOverflow } from './utils';
import TimeHeatmapChart from './TimeHeatmapChart';
import { CollectionSelectionsContext } from '../contexts';
import { METRIC_OPTIONS } from '../utils/menuOptions';

interface Props {
  isLoading?: boolean;
  onChartUnfocus?: () => void;
  onDatumSelection: (d: EventTimeSeriesSalesSummary[], id: number) => void;
}

const TimeHeatmap: React.FC<Props> = ({
  isLoading,
  onChartUnfocus,
  onDatumSelection,
}) => {
  const {
    metricSelection, handleMetricSelection, timeIntervalSelection, dateFilters,
  } = useContext(CollectionSelectionsContext);

  const defaultFromDatetime = useMemo(() => {
    return getDefaultFromDatetime(timeIntervalSelection);
  }, [timeIntervalSelection]);

  const { transactionAtMin, transactionAtMax } = dateFilters;

  const {
    isValid: hasValidTransactionDateRange, effectiveFromDate,
  } = useMemo(() => validateTransactionDateRange({
    transactionAtMin,
    transactionAtMax,
    timeIntervalSelection,
    defaultFromDate: defaultFromDatetime,
  }), [
    transactionAtMin,
    transactionAtMax,
    timeIntervalSelection,
    defaultFromDatetime,
  ]);

  useEffect(() => {
    if (metricSelection.key === 'cumulativeTicketsUnsoldPercent')
      handleMetricSelection(METRIC_OPTIONS[1].key);
  }, [metricSelection, handleMetricSelection]);

  return (
    <>
      {hasValidTransactionDateRange ? (
        <TimeHeatmapChart
          effectiveFromDate={effectiveFromDate}
          isLoading={isLoading}
          onChartUnfocus={onChartUnfocus}
          onDatumSelection={onDatumSelection}
        />
      ) : (
        <>
          <ControlBar isLoading={isLoading}>
            <RelatedControls align='top' fillSpace>
              <Control>
                <TimeIntervalMenu isLoading={isLoading} />
              </Control>
            </RelatedControls>
          </ControlBar>
          <ErrorContainer>
            {generateTransactionDateRangeOverflow({
              hasValidTransactionDateRange,
              timeIntervalSelection,
            })}
          </ErrorContainer>
        </>
      )}
    </>
  );
};

const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
`;

export default TimeHeatmap;
export { generateTransactionDateRangeOverflow };
