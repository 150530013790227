import React from 'react';
import Button from '#/shared/Button';

interface SaveButtonProps {
  disabled: boolean;
  handleSave: () => void;
}

const SaveButton: React.FC<SaveButtonProps> = ({ disabled, handleSave }) => {
  const SAVE = 'Save';

  return (
    <Button disabled={disabled} onClick={handleSave}>
      {SAVE}
    </Button>
  );
};

export default SaveButton;
