import React from 'react';
import { PriceGranularity } from '#/types/Event';
import { PriceConstraint } from '#/types/PriceConstraints';
import { formatCurrency } from '#/shared/formatNumberForDisplay';
import { getSourceInventoryId, InputListing } from '#/shared/ListingOverrideModal';

type TableCell = ((row: InputListing) => React.ReactNode) | undefined;

enum OverrideColumns {
  ID = 'id',
  MAPKEY = 'mapkey',
  PRICE_CONSTRAINT = 'price_constraint',
  EXPECTED_VALUE = 'expected_value',
  PROPERTIES = 'properties',
  EDIT = 'edit',
}

enum OverrideSummaryColumns {
  GRANULARITY = 'granularity',
  OVERRIDE_COUNT = 'override_count',
}

interface OverrideSummary {
  granularity?: PriceGranularity;
  override_count?: number;
  overrides?: InputListing[];
}

interface FormattedOverrideSummary {
  label: string;
  value: OverrideSummary;
}

enum PriceGranularitySort {
  'listing',
  'ingestion',
  'row',
  'section',
  'price_level',
  'event',
}

const customGranularitySort = (rowA : OverrideSummary, rowB : OverrideSummary) : number => {
  const a = PriceGranularitySort[rowA.granularity];
  const b = PriceGranularitySort[rowB.granularity];

  return a - b;
};

const customEVSort = (rowA : InputListing, rowB : InputListing) : number => {
  const a = rowA.override.expected_value ?? -1;
  const b = rowB.override.expected_value ?? -1;

  return a - b;
};

const customIDSort = (rowA : InputListing, rowB : InputListing) : number => {
  const a = getSourceInventoryId(rowA, rowA.override.granularity);
  const b = getSourceInventoryId(rowB, rowB.override.granularity);

  if (a === b)
    return 0;

  return a > b ? 1 : -1;
};

const customPriceConstraintSort = (
  rowA : InputListing,
  rowB : InputListing,
) : number => {
  const a = [
    rowA.override.price_constraint.price_floor,
    rowA.override.price_constraint.price_ceiling,
    rowA.override.all_in_price,
    rowA.override.display_price,
    rowA.override.broadcast_price,
  ];
  const b = [
    rowB.override.price_constraint.price_floor,
    rowB.override.price_constraint.price_ceiling,
    rowB.override.all_in_price,
    rowB.override.display_price,
    rowB.override.broadcast_price,
  ];

  return a.map((s, i) => s - b[i]).find((e:number) => e);
};

const getSortFunction = (key: OverrideColumns) :
(rowA : InputListing, rowB : InputListing) => number => {
  switch (key) {
    case OverrideColumns.EXPECTED_VALUE:
      return customEVSort;
    case OverrideColumns.PRICE_CONSTRAINT:
      return customPriceConstraintSort;
    case OverrideColumns.ID:
      return customIDSort;
    default:
      return undefined;
  }
};

const formatColumn = (type : keyof OverrideSummary | OverrideColumns): string => {
  if (type === OverrideColumns.EXPECTED_VALUE)
    return 'EV';
  return type.split('_').map((c) => `${c[0].toUpperCase()}${c.slice(1)}`).join(' ');
};

const formatMapkey = (s: string, r: string, m: string): string => {
  const sectionText = s ? `Section ${s}` : '';
  const rowText = r ? `Row ${r}` : '';
  const mapKeyText = m ? `${m}` : '';

  return `${[sectionText, rowText, mapKeyText].filter((e) => e !== '').join('\n')}`;
};

const formatConstraint = (
  constraint: PriceConstraint,
  broadcastPrice: number,
  allInPrice: number,
  displayPrice: number,
): string => {
  const priceConstraintList = [];

  if (constraint?.price_floor)
    priceConstraintList.push('floor');
  if (constraint?.price_ceiling)
    priceConstraintList.push('ceiling');
  if (allInPrice || broadcastPrice || displayPrice)
    priceConstraintList.push('price');

  const ceilingText = constraint?.price_ceiling ? `${constraint.price_constraint_type} < ${formatCurrency(constraint.price_ceiling)}` : '';
  const floorText = constraint?.price_floor && ceilingText ? `${formatCurrency(constraint.price_floor)} < ` : '';
  const floorOnlyText = constraint?.price_floor && !floorText ? `${constraint.price_constraint_type} > ${formatCurrency(constraint.price_floor)}` : '';
  const allInText = allInPrice ? `\nall_in_price = ${formatCurrency(allInPrice)}` : '';
  const broadcastText = broadcastPrice ? `\nbroadcast_price = ${formatCurrency(broadcastPrice)}` : '';
  const displayText = displayPrice ? `\ndisplay_price = ${formatCurrency(displayPrice)}` : '';

  return `${priceConstraintList.join(', ')}\n${floorText}${floorOnlyText}${ceilingText}${allInText}${broadcastText}${displayText}`;
};

const growTableCell = (key: string): number => {
  if (key === OverrideColumns.EDIT || key === OverrideColumns.EXPECTED_VALUE)
    return 1.0;
  if (key === OverrideColumns.PROPERTIES || key === OverrideColumns.PRICE_CONSTRAINT)
    return 2.0;
  if (key === OverrideColumns.ID || key === OverrideColumns.MAPKEY)
    return 1.5;
  return undefined;
};

export {
  TableCell,
  OverrideColumns,
  OverrideSummaryColumns,
  FormattedOverrideSummary,
  OverrideSummary,
  PriceGranularitySort,
  formatColumn,
  formatMapkey,
  formatConstraint,
  customGranularitySort,
  getSortFunction,
  growTableCell,
};
