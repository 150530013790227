import React, { Dispatch, FormEvent } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import CheckboxForm from './CheckboxForm';
import DatetimeForm from './DatetimeForm';
import SelectForm from './SelectForm';
import Action, { UpdateEventConfig, UpdateEventConfigType } from '../types';
import {
  DYNAMIC_PRICING_MODEL_OPTIONS,
  PRICING_MODE_OPTIONS,
  PRICING_BASIS_OPTIONS,
  SELLDOWN_PROJECTION_MODEL_OPTIONS,
  ONSALE_PRICING_MODEL_OPTIONS,
  SUPPORTS_ONSALE_DEMAND_FRACTION,
} from '#/types/Event';
import { FormContainer } from './formComponents';
import Button from '#/shared/Button';
import Loading from '../../sharedComponents/Loading';
import Notification from '#/shared/Notification';
import InputForm, { InputType } from '#/pages/ViewPage/components/Configuration/Modals/Forms/InputForm';
import modalStyles from '#/shared/modalStyles';
import { Content } from '#/shared/modalComponents';
import SelectGroups from './SelectGroups';
import { FetchGroupsHook } from '#/pages/useFetchGroups';

interface Props {
  closeModal: () => void;
  handleSubmit: (event: FormEvent) => void;
  error: string;
  loading: boolean;
  modalIsOpen: boolean;
  success: boolean;
  updatedConfig: UpdateEventConfig;
  updateEventConfig: Dispatch<Action>;
  groupsHook: FetchGroupsHook;
  enableOnsalePricingModel?: boolean;
}

const SUBMIT = 'Submit';
const TITLE = 'Event configuration options';

const EventConfigModal: React.FC<Props> = ({
  closeModal,
  handleSubmit,
  error,
  loading,
  modalIsOpen,
  success,
  updatedConfig,
  updateEventConfig,
  groupsHook,
  enableOnsalePricingModel = true,
}) => {
  const exitText = success ? 'Done' : 'Cancel';

  return (
    <>
      <Modal
        className='configModal'
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <Header>{TITLE}</Header>
        <Notification error={error} success={success} />
        {!loading && (
          <FormContainer>
            <Content>
              <CheckboxForm
                handleInputChange={updateEventConfig}
                label="Event is activated"
                type={UpdateEventConfigType.IS_ACTIVE}
                value={updatedConfig.isActive}
              />
              <CheckboxForm
                handleInputChange={updateEventConfig}
                label="Reset prices"
                type={UpdateEventConfigType.RESET_PRICES}
                value={updatedConfig.resetPrices}
              />
              <CheckboxForm
                handleInputChange={updateEventConfig}
                label="Keep Supervisor Active"
                type={UpdateEventConfigType.KEEP_SUPERVISOR_ACTIVE}
                value={updatedConfig.keepSupervisorActive}
              />
              <SelectForm
                handleInputChange={updateEventConfig}
                initialValue={updatedConfig.pricingMode}
                label="Pricing mode"
                options={PRICING_MODE_OPTIONS}
                type={UpdateEventConfigType.PRICING_MODE}
              />
              <SelectForm
                handleInputChange={updateEventConfig}
                initialValue={updatedConfig.pricingBasis}
                label="Pricing basis"
                options={PRICING_BASIS_OPTIONS}
                type={UpdateEventConfigType.PRICING_BASIS}
              />
              <SelectForm
                handleInputChange={updateEventConfig}
                initialValue={updatedConfig.selldownProjectionModel}
                isClearable
                label="Selldown projection model"
                options={SELLDOWN_PROJECTION_MODEL_OPTIONS}
                type={UpdateEventConfigType.SELLDOWN_PROJECTION_MODEL}
              />
              {
                SUPPORTS_ONSALE_DEMAND_FRACTION.includes(updatedConfig.selldownProjectionModel) && (
                  <InputForm
                    handleInputChange={updateEventConfig}
                    initialVal={updatedConfig.onsaleDemandFraction}
                    inputType={InputType.number}
                    label="Onsale demand fraction"
                    max={0.99}
                    min={0.01}
                    step="0.01"
                    type={UpdateEventConfigType.ONSALE_DEMAND_FRACTION}
                  />
                )
              }
              <SelectForm
                handleInputChange={updateEventConfig}
                initialValue={updatedConfig.onsalePricingModel}
                isClearable
                isDisabled={!enableOnsalePricingModel}
                label="Onsale pricing model"
                options={ONSALE_PRICING_MODEL_OPTIONS}
                type={UpdateEventConfigType.ONSALE_PRICING_MODEL}
              />
              <SelectForm
                handleInputChange={updateEventConfig}
                initialValue={updatedConfig.dynamicPricingModel}
                label="Dynamic pricing model"
                options={DYNAMIC_PRICING_MODEL_OPTIONS}
                type={UpdateEventConfigType.DYNAMIC_PRICING_MODEL}
              />
              <SelectGroups
                groupsHook={groupsHook}
                handleInputChange={updateEventConfig}
                initialValues={updatedConfig.groups}
                label="Groups"
                type={UpdateEventConfigType.GROUPS}
              />
              <DatetimeForm
                handleInputChange={updateEventConfig}
                initialVal={updatedConfig.listingsRemovedAt}
                label="Remove listings at (UTC)"
                type={UpdateEventConfigType.LISTINGS_REMOVED_AT}
              />
              <DatetimeForm
                handleInputChange={updateEventConfig}
                initialVal={updatedConfig.onsaleStartsAt}
                label="Onsale starts at (UTC)"
                type={UpdateEventConfigType.ONSALE_STARTS_AT}
              />
              <CheckboxForm
                handleInputChange={updateEventConfig}
                label="Use SeatGeek Transactions"
                type={UpdateEventConfigType.USE_SEATGEEK_TRANSACTIONS}
                value={updatedConfig.useSeatgeekTransactions}
              />
              <InputForm
                handleInputChange={updateEventConfig}
                initialVal={updatedConfig.demandChangepointCt}
                inputType={InputType.number}
                label="Demand Changepoint Ct"
                type={UpdateEventConfigType.DEMAND_CHANGEPOINT_CT}
              />
              <InputForm
                handleInputChange={updateEventConfig}
                initialVal={updatedConfig.notes}
                inputType={InputType.text}
                label="Notes"
                type={UpdateEventConfigType.NOTES}
              />
            </Content>
            <Wrapper>
              <Button onClick={closeModal} type="button">
                {exitText}
              </Button>
              <Button onClick={handleSubmit}>{SUBMIT}</Button>
            </Wrapper>
            {error && <Text>{error}</Text>}
          </FormContainer>
        )}
        {loading && <Loading loading={loading} />}
      </Modal>
    </>
  );
};

const Header = styled.h3`
  ${({ theme }: { theme: Theme }): string => theme.header3}
  margin-top: 0;
`;

const Text = styled.p`
  ${({ theme }: { theme: Theme }): string => theme.text1};
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export default EventConfigModal;
