import { AxiosResponse } from 'axios';
import Stakeholder, { Stakeholders } from '#/types/Stakeholder';
import client from './_clientFormatted';

const getStakeholders = async (autobrokerEventId?:number): Promise<Stakeholder[]> => {
  const response: AxiosResponse<Stakeholders> = await client.get(
    '/proxy/stakeholders',
    { params: { autobrokerEventId } },
  );

  return response?.data?.stakeholders || [];
};

export default getStakeholders;
