const checkListingsConstraints = (
  listingMin: number,
  listingMax: number,
): string => {
  if (listingMax < listingMin)
    return 'Min listings cannot be greater than max listings';

  return '';
};

export default checkListingsConstraints;
