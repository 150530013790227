import React from 'react';
import {
  NarrowWrapper, Element, Name,
} from '#/shared/Card';
import PriceLevel from '#/types/PriceLevel';

interface CardProps {
  priceLevel: PriceLevel;
}

const NAME = 'Name';
const SECTIONS = 'Sections';

const SectionsCard: React.FC<CardProps> = ({ priceLevel }) => {
  const { name } = priceLevel;
  const { sections } = priceLevel.summary;

  return (
    <NarrowWrapper>
      {name && (
        <Element>
          <Name>{NAME}</Name>
          <Name>{name}</Name>
        </Element>
      )}
      {sections.length > 0 && (
        <Element>
          <Name>{SECTIONS}</Name>
          <Name>{sections.join(', ')}</Name>
        </Element>
      )}
    </NarrowWrapper>
  );
};

export default SectionsCard;
