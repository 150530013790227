import React from 'react';
import Listing from '#/types/Listing';
import SourceCard from './SourceCard';
import StakeholderCard from './StakeholderCard';
import InfoCard from './InfoCard';
import SeatsCard from './SeatsCard';
import VisibilityCard from './VisibilityCard';

interface ListingDetailCardsProps {
  listing: Listing;
}

const ListingDetailCards: React.FC<ListingDetailCardsProps> = ({
  listing,
}) => {
  const {
    stakeholder,
  } = listing;

  return (
    <>
      <SourceCard listing={listing} />
      <SeatsCard listing={listing} />
      {stakeholder && <StakeholderCard listing={listing} />}
      <InfoCard listing={listing} />
      <VisibilityCard listing={listing} />
    </>
  );
};

export default ListingDetailCards;
