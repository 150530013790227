import React from 'react';
import {
  ErrorStatusType, EventError, PostErrorStatus,
} from '#/types/Errors';
import Checklist, { ChecklistConfigItem } from './checklist';

interface PricesAtBoundaryChecklistProps {
  error: EventError;
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const CONFIG: ChecklistConfigItem[] = [
  {
    id: 0,
    level: 1,
    status: ErrorStatusType.RESOLVED,
    notes: 'Min/Max Offset Shifted to not constrain the Model Log Price Ratio',
  },
  {
    id: 1,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Cannot Change Min/Max Offset',
  },
  {
    id: 2,
    level: 2,
    status: ErrorStatusType.WONTFIX,
    notes: 'Using current Min/Max Offset to prevent all listings from being clamped at Section/Row Price Constraints',
    parent_id: 1,
  },
  {
    id: 3,
    level: 2,
    status: ErrorStatusType.WONTFIX,
    notes: "Don't set prices outside of the current Min/Max Offset",
    parent_id: 1,
  },
];

const PricesAtBoundaryErrorChecklist: React.FC<PricesAtBoundaryChecklistProps> = (
  { error, setErrorStatus },
) => {
  return (
    <Checklist config={CONFIG} error={error} setErrorStatus={setErrorStatus} />
  );
};

export default PricesAtBoundaryErrorChecklist;
