enum Environment {
  DEV = 'dev',
  STAGING = 'staging',
  PRODUCTION = 'production',
  UNKNOWN = 'unknown',
}

const getClientEnvironment = (): Environment => {
  const { href = '' } = window?.location || {};

  switch (true) {
    case href.includes('localhost'):
    case href.includes('.local'):
      return Environment.DEV;
    case href.includes('.stag'):
    case href.includes('tixcast'):
      return Environment.STAGING;
    case href.includes('.com'):
      return Environment.PRODUCTION;
    default:
      return Environment.UNKNOWN;
  }
};

export {
  Environment,
  getClientEnvironment,
};
