import React from 'react';
import {
  DropdownButton, UploadLabel, UploadWrapper, DropdownContent, Dropdown,
} from '../DropDown';

const UPLOAD = 'New';
const EVENT = 'Event';
const LISTING = 'Listing';
const INVENTORY = 'Inventory';
const INGESTION_UPDATES = 'Ingestion Updates';
const OVERRIDES = 'Overrides';
const PRICE_CONSTRAINTS = 'Price Constraints';
const EXTERNAL_TRANSACTIONS = 'Ext. Transactions';

interface UploadMenuProps {
  openEventModal: () => void;
  openListingModal: () => void;
  openInventoryModal: () => void;
  openIngestionUpdatesModal: () => void;
  openOverridesModal: () => void;
  openPriceConstraintsModal: () => void;
  openExternalTransactionsModal: () => void;
}

const UploadMenu: React.FC<UploadMenuProps> = ({
  openEventModal,
  openListingModal,
  openInventoryModal,
  openIngestionUpdatesModal,
  openOverridesModal,
  openPriceConstraintsModal,
  openExternalTransactionsModal,
}) => {
  return (
    <Dropdown>
      <DropdownButton>{UPLOAD}</DropdownButton>
      <DropdownContent>
        <UploadWrapper onClick={openEventModal}>
          <UploadLabel>{EVENT}</UploadLabel>
        </UploadWrapper>
        <UploadWrapper onClick={openListingModal}>
          <UploadLabel>{LISTING}</UploadLabel>
        </UploadWrapper>
        <UploadWrapper onClick={openInventoryModal}>
          <UploadLabel>{INVENTORY}</UploadLabel>
        </UploadWrapper>
        <UploadWrapper onClick={openIngestionUpdatesModal}>
          <UploadLabel>{INGESTION_UPDATES}</UploadLabel>
        </UploadWrapper>
        <UploadWrapper onClick={openOverridesModal}>
          <UploadLabel>{OVERRIDES}</UploadLabel>
        </UploadWrapper>
        <UploadWrapper onClick={openPriceConstraintsModal}>
          <UploadLabel>{PRICE_CONSTRAINTS}</UploadLabel>
        </UploadWrapper>
        <UploadWrapper onClick={openExternalTransactionsModal}>
          <UploadLabel>{EXTERNAL_TRANSACTIONS}</UploadLabel>
        </UploadWrapper>
      </DropdownContent>
    </Dropdown>
  );
};

export default UploadMenu;
