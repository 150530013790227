import { useState, useCallback } from 'react';

interface Hook {
  showSales: boolean;
  handleShowSales: () => void;
}

const useShowSales = (): Hook => {
  const [showSales, setShowSales] = useState(false);

  const handleShowSales = useCallback(() => {
    setShowSales((prev) => !prev);
  }, []);

  return {
    showSales,
    handleShowSales,
  };
};

export default useShowSales;
