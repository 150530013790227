import React, { useCallback, useContext } from 'react';
import { GG, ScaleFill, Labels } from '@graphique/graphique';
import { Theme } from '#/shared/graphique';
import { GeomTile, Legend as FillLegend } from '@graphique/geom-tile';
import { interpolateRdYlBu } from 'd3-scale-chromatic';
import styled from 'styled-components';
import { scaleSequentialSqrt } from 'd3-scale';
import { CollectionSelectionsContext } from '../contexts';

interface Props<Datum> {
  data: Datum[];
  fill: (d: Datum) => number;
  isLoading?: boolean;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const Legend = <Datum, >({ data, fill, isLoading }: Props<Datum>): React.ReactNode => {
  const { metricSelection } = useContext(CollectionSelectionsContext);

  const formatLegendTicks = useCallback((v: number) => (
    metricSelection.format({ value: v, showDecimal: false, abbreviate: true })), [metricSelection]);

  return (
    <Wrapper>
      {data?.length > 0 && (
        <GG
          aes={{
            x: (): number => 0,
            fill,
          }}
          data={data}
          height={0}
          width={320}
        >
          <GeomTile />
          <Labels
            header={(
              !isLoading && (
                <FillLegend
                  format={formatLegendTicks}
                  numTicks={3}
                  style={{ fontSize: '0.725rem', marginTop: 0 }}
                  title={<Title>{metricSelection.label}</Title>}
                />
              )
            )}
          />
          <ScaleFill reverse type={scaleSequentialSqrt} values={interpolateRdYlBu} />
          <Theme axis={{ showAxisLines: false }} />
        </GG>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 320px;
  display: flex;
`;

const Title = styled.span`
  font-size: 0.85rem;
  font-weight: 600;
`;

export default Legend;
