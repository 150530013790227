import { AxiosResponse } from 'axios';
import { DealTerm, PostDealTermBody } from '#/types/Deal';
import client from './_clientFormatted';

const postDealTerm = async (
  request: PostDealTermBody,
): Promise<DealTerm> => {
  const response: AxiosResponse<DealTerm> = await client.post('/proxy/deal_term', request);

  return response?.data;
};

export default postDealTerm;
