import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import { User } from '#/types/User';
import useFetchIngestions from '#/pages/IngestionsPage/useFetchIngestions';
import usePatchIngestions from '#/pages/IngestionsPage/usePatchIngestions';
import IngestionsTableAndDetail from '#/pages/IngestionsPage/IngestionsTableAndDetail';
import Filters from './Filters';
import Ingestion, { IngestionFilters } from '#/types/Ingestion';
import { StakeholderLogos } from '#/pages/useFetchStakeholders';

interface IngestionsProps {
  user: User;
  stakeholderLogos: StakeholderLogos;
}

const Ingestions: React.FC<IngestionsProps> = ({ user, stakeholderLogos }) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const autobrokerEventId = useMemo(() => activeEvent?.id, [activeEvent]);
  const [curPinnedIngestions, setCurPinnedIngestions] = useState<Ingestion[]>([]);

  const initialFilters = { per_page: 10, autobroker_event_id: autobrokerEventId };
  const ingestionsHook = useFetchIngestions(initialFilters);
  const { filters, setFilters, setPinnedIngestions } = ingestionsHook;
  const patchIngestionsHook = usePatchIngestions(initialFilters);
  const { setPatchFilters } = patchIngestionsHook;

  useEffect(() => {
    const updateAutobrokerEventId = (id: number): void => {
      setFilters({
        ...filters,
        autobroker_event_id: id,
      });
    };

    if (autobrokerEventId)
      updateAutobrokerEventId(autobrokerEventId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autobrokerEventId]);

  useEffect(() => {
    const updateFilters = (newFilters: IngestionFilters): void => {
      setPatchFilters(newFilters);
    };

    if (filters)
      updateFilters(filters);
  }, [filters, setPatchFilters]);

  return (
    <div id="ingestions">
      <Wrapper>
        <FilterWrapper>
          <Filters
            filters={filters}
            pinnedIngestions={curPinnedIngestions}
            setFilters={setFilters}
            setPinnedIngestions={setPinnedIngestions}
          />
        </FilterWrapper>
        <Section>
          <IngestionsTableAndDetail
            ingestionsHook={ingestionsHook}
            patchIngestionsHook={patchIngestionsHook}
            pinnedIngestions={curPinnedIngestions}
            setPinnedIngestions={setCurPinnedIngestions}
            stakeholderLogos={stakeholderLogos}
            user={user}
          />
        </Section>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
`;

const FilterWrapper = styled(Section as any)`
  margin-top: 3.75rem;
`;

export default Ingestions;
