import React, { useMemo } from 'react';
import styled from 'styled-components';
import { User } from '#/types/User';
import ClientReporting from '../ClientReporting';
import { Option } from '#/pages/useFetchStakeholders';
import { Logo, ReportingTitle } from '#/shared/clientReporting/typography';
import { Row, Card } from './components/Card';
import EventSalesTable from './components/EventSalesTable';
import SummaryDataWells from './components/SummaryDataWells';
import MarketplaceMix from './components/MarketplaceMix';
import TicketingHistogram from './components/TicketingHistogram';
import PerspectiveToggle from './components/PerspectiveToggle';

interface PageProps {
  user: User;
  stakeholder: Option<string>;
}

const DashboardPage: React.FC<PageProps> = ({ user, stakeholder }) => {
  const stakeholderLabel = useMemo(() => (
    stakeholder?.label
  ), [stakeholder]);
  const logoUrl = user.isReporting ? undefined : stakeholder.logoUrl;

  return (
    <ClientReporting
      stakeholder={stakeholder}
      user={user}
    >
      {stakeholder && (
        <>
          <TitleRow>
            <ReportingTitle>
              {logoUrl && <Logo src={logoUrl} />}
              {`${stakeholderLabel} Ticketing`}
            </ReportingTitle>
            <PerspectiveToggle />
          </TitleRow>
          <Row>
            <Card>
              <SummaryDataWells />
            </Card>
          </Row>
          <Row>
            <Card percWidth={0.4}>
              <MarketplaceMix />
            </Card>
            <Card percWidth={0.6}>
              <TicketingHistogram />
            </Card>
          </Row>
          <Row>
            <Card>
              <EventSalesTable />
            </Card>
          </Row>
        </>
      )}
    </ClientReporting>
  );
};

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export default DashboardPage;
