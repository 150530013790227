import { useState, useEffect } from 'react';
import getUploaders from '#/api/getUploaders';
import DisplayableUploader from '#/types/Uploader';

interface UploadersHook {
  uploaders: DisplayableUploader[]
}
const useFetchUploaders = (): UploadersHook => {
  const [uploaders, setUploaders] = useState<DisplayableUploader[]>([]);

  useEffect(() => {
    const fetchUploaders = async (): Promise<void> => {
      try {
        const uploadersData = await getUploaders();

        setUploaders(uploadersData);
      } catch (err) {
        console.log(err);
      }
    };

    fetchUploaders();
  }, []);

  return {
    uploaders,
  };
};

export default useFetchUploaders;
