import { DateTime } from 'luxon';
import { PriceConstraint } from './PriceConstraints';
import { PriceGranularity, PricingBasis } from './Event';

interface Overrides {
  autobroker_event_id: number;
  parameters?: PostParameterOverride[];
  listings?: PostListingOverride[];
  markets?: MarketOverrides[] | [];
  notes?: string;
}

enum Marketplace {
  SEATGEEK = 'seatgeek',
}

enum SeatGeekMarket {
  OPEN = 'open',
  SELLERDIRECT = 'sellerdirect',
  OPEN_MARKETPLACE = 'open_marketplace',
  MARKETPLACE = 'marketplace',
}

interface Market {
  marketplace: Marketplace;
  market: SeatGeekMarket;
}

interface MarketPolicy {
  buy_fee_offset: number;
  buy_fee_fraction: number;
  display_price_markdown_offset: number;
  display_price_markdown_fraction: number;
}

interface MarketOverrides {
  market: Market;
  policy: MarketPolicy;
}

interface PostParameterOverride {
  type: OverrideType;
  value: number | null;
  notes?: string;
}

enum SetOverrideType {
  DEMAND_PRIOR = 'demand_prior',
  ONSALE_DEMAND_PRIOR = 'onsale_demand_prior',
  LOG_QUANTITY_SLOPE = 'log_quantity_slope',
  IS_ODD_QUANTITY_SLOPE = 'is_odd_quantity_slope',
  AGGRESSIVENESS = 'aggressiveness',
  ADDITIONAL_TICKETS = 'additional_tickets',
  SELLOUT_BY_SELLDOWN = 'sellout_by_selldown',
  MAX_ELASTICITY = 'max_elasticity',
  SPREAD = 'spread',
  ANCHOR_BIAS = 'anchor_bias',
  ANCHOR_PROBABILITY = 'p_anchor',
}

enum ShiftOverrideType {
  MIN_OFFSET = 'min_offset',
  MAX_OFFSET = 'max_offset',
  PIVOT_PRIOR = 'pivot_prior',
  DEMAND_PRIOR = 'demand_prior',
  ONSALE_DEMAND_PRIOR = 'onsale_demand_prior',
  LOG_EXPECTED_VALUE_SLOPE = 'slope',
  LOG_EXPECTED_VALUE_CURVATURE = 'curvature',
  LOG_QUANTITY_SLOPE = 'log_quantity_slope',
  IS_ODD_QUANTITY_SLOPE = 'is_odd_quantity_slope',
  AGGRESSIVENESS = 'aggressiveness',
  ADDITIONAL_TICKETS = 'additional_tickets',
  SELLOUT_BY_SELLDOWN = 'sellout_by_selldown',
  MAX_ELASTICITY = 'max_elasticity',
}

enum ScaleOverrideType {
  SPREAD = 'spread',
  ANCHOR_BIAS = 'anchor_bias',
  ANCHOR_PROBABILITY = 'p_anchor',
  MAX_ELASTICITY = 'max_elasticity',
}

enum OverrideType {
  BIAS = 'bias',
  SPREAD = 'spread',
  ANCHOR_PROBABILITY = 'p_anchor',
  ANCHOR_BIAS = 'anchor_bias',
  MIN_OFFSET = 'min_offset',
  MAX_OFFSET = 'max_offset',
  LOG_EXPECTED_VALUE_SLOPE = 'slope',
  LOG_EXPECTED_VALUE_CURVATURE = 'curvature',
  ADDITIONAL_TICKETS = 'additional_tickets',
  AGGRESSIVENESS = 'aggressiveness',
  SELLOUT_BY_SELLDOWN = 'sellout_by_selldown',
  MAX_ELASTICITY = 'max_elasticity',
  PIVOT_PRIOR = 'pivot_prior',
  DEMAND_PRIOR = 'demand_prior',
  ONSALE_DEMAND_PRIOR = 'onsale_demand_prior',
  LOG_QUANTITY_SLOPE = 'log_quantity_slope',
  IS_ODD_QUANTITY_SLOPE = 'is_odd_quantity_slope',
}

interface PostListingOverride {
  source_name: string;
  source_external_event_id: string;
  source_inventory_id: string;
  face_value?: Price;
  granularity?: PriceGranularity;
  is_cascading?: boolean;
  section?: string;
  row?: string;
  mapkey?: string;
  expected_value?: number;
  price_constraint?: PriceConstraint;
  is_visible?: boolean;
  all_in_price?: number;
  display_price?: number;
  broadcast_price?: number;
  notes?: string;
}

interface Price {
  amount: number;
  basis: PricingBasis;
}

interface ParameterOverride {
  id: number;
  type: OverrideType;
  value?: number;
  userId: number;
  expiresAt?: DateTime;
  createdAt: DateTime;
  notes?: string;
}

interface Settings {
  [OverrideType.ANCHOR_BIAS]?: number;
  [OverrideType.ANCHOR_PROBABILITY]?: number;
  [OverrideType.BIAS]?: number;
  [OverrideType.MAX_OFFSET]?: number;
  [OverrideType.MIN_OFFSET]?: number;
  [OverrideType.SPREAD]?: number;
  [OverrideType.LOG_EXPECTED_VALUE_SLOPE]?: number;
  [OverrideType.LOG_EXPECTED_VALUE_CURVATURE]?: number;
  [OverrideType.ADDITIONAL_TICKETS]?: number;
  [OverrideType.AGGRESSIVENESS]?: number;
  [OverrideType.SELLOUT_BY_SELLDOWN]?: number;
  [OverrideType.MAX_ELASTICITY]?: number;
  [OverrideType.PIVOT_PRIOR]?: number;
  [OverrideType.DEMAND_PRIOR]?: number;
  [OverrideType.ONSALE_DEMAND_PRIOR]?: number;
  [OverrideType.LOG_QUANTITY_SLOPE]?: number;
  [OverrideType.IS_ODD_QUANTITY_SLOPE]?: number;
}

interface OverrideMap {
  [OverrideType.BIAS]?: ParameterOverride;
  [OverrideType.SPREAD]?: ParameterOverride;
  [OverrideType.ANCHOR_PROBABILITY]?: ParameterOverride;
  [OverrideType.ANCHOR_BIAS]?: ParameterOverride;
  [OverrideType.MIN_OFFSET]?: ParameterOverride;
  [OverrideType.MAX_OFFSET]?: ParameterOverride;
  [OverrideType.LOG_EXPECTED_VALUE_SLOPE]?: ParameterOverride;
  [OverrideType.LOG_EXPECTED_VALUE_CURVATURE]?: ParameterOverride;
  [OverrideType.ADDITIONAL_TICKETS]?: ParameterOverride;
  [OverrideType.AGGRESSIVENESS]?: ParameterOverride;
  [OverrideType.SELLOUT_BY_SELLDOWN]?: ParameterOverride;
  [OverrideType.MAX_ELASTICITY]?: ParameterOverride;
  [OverrideType.PIVOT_PRIOR]?: ParameterOverride;
  [OverrideType.DEMAND_PRIOR]?: ParameterOverride;
  [OverrideType.ONSALE_DEMAND_PRIOR]?: ParameterOverride;
  [OverrideType.LOG_QUANTITY_SLOPE]?: ParameterOverride;
  [OverrideType.IS_ODD_QUANTITY_SLOPE]?: ParameterOverride;
}

interface OverrideChangeLog {
  autobrokerEventId: number;
  logs: OverrideChangeLogEntry[];
}

interface OverrideChangeLogEntry {
  ids: number[];
  type: OverrideChangeType;
  user?: OverrideUser;
  notes?: string;
  groups: OverrideChange[];
  changes: OverrideChange[];
  createdAt: DateTime;
  isDeleted: boolean;
}

enum OverrideChangeType {
  EVENT_PARAMETER = 'event_parameter',
  LISTING = 'listing',
  PRICE_EXPLORATION = 'price_exploration',
  PRICE_CONSTRAINT = 'price_constraint',
  EVENT_CONFIGURATION = 'event_configuration',
  SINK_EVENT = 'sink_event',
}

interface OverrideUser {
  id: number;
  name: string;
}

interface OverrideChange {
  field: string;
  value?: string;
}

interface PatchOverrideNotesRequest {
  type: OverrideChangeType;
  overrideIds: number[];
  notes: string;
  isDeleted: boolean;
}

interface BulkSettings extends Settings {
  id: number
  autobrokerEventId: number
  createdAt: DateTime
}

interface BulkOverrides {
  autobrokerEventId: number
  parameters: ParameterOverride[]
}

interface BulkOverrideEvent {
  autbrokerEventId: number
  parameters: BulkSettings
  overrides: BulkOverrides
}

interface BulkOverridesAndSettings {
  events: BulkOverrideEvent[]
}

export default ParameterOverride;
export {
  Overrides,
  PostParameterOverride,
  PostListingOverride,
  OverrideType,
  OverrideMap,
  Settings,
  OverrideChangeLog,
  OverrideChangeLogEntry,
  OverrideChange,
  OverrideChangeType,
  PatchOverrideNotesRequest,
  SetOverrideType,
  ShiftOverrideType,
  ScaleOverrideType,
  BulkOverrides,
  BulkOverridesAndSettings,
};
