import React, { useState, useCallback, useEffect } from 'react';
import {
  NarrowWrapper, Element, Name,
} from '#/shared/Card';
import Ingestion from '#/types/Ingestion';
import UploaderEvent from '#/types/UploaderEvent';
import getUploaderEvent from '#/api/getUploaderEvent';

interface CardProps {
  ingestion: Ingestion;
}

const UploaderEventCard: React.FC<CardProps> = ({ ingestion }) => {
  const { uploader, uploader_event_id } = ingestion;
  const [uploaderEvent, setUploaderEvent] = useState<UploaderEvent>(null);

  const update = useCallback(async () => {
    setUploaderEvent(null);
    if (uploader_event_id) {
      try {
        const newEvent = await getUploaderEvent(uploader, uploader_event_id);

        setUploaderEvent(newEvent);
      } catch (e) {
        console.error(e);
      }
    }
  }, [uploader, uploader_event_id]);

  useEffect(() => {
    update();
  }, [update]);

  const title = `${uploader} event: ${uploader_event_id}`;

  return (
    <NarrowWrapper>
      {!uploaderEvent && (
        <Element>
          <Name>{title}</Name>
        </Element>
      )}
      {uploaderEvent && (
        <>
          <Element>
            <Name>{title}</Name>
          </Element>
          <Element>
            <Name title={title}>{uploaderEvent.eventTitle}</Name>
          </Element>
          <Element>
            <Name>{`${uploaderEvent.eventDate} • ${uploaderEvent.eventTime}`}</Name>
          </Element>
        </>
      )}
    </NarrowWrapper>
  );
};

export default UploaderEventCard;
