import { AxiosResponse } from 'axios';
import { PatchUserUpdate, User } from '#/types/User';
import client from './_clientFormatted';

const patchUpdateUser = async (
  userId: number,
  request: PatchUserUpdate,
): Promise<User> => {
  const response: AxiosResponse<User> = await client.patch(`/auth/user/${userId}`, request);

  return response.data;
};

export default patchUpdateUser;
