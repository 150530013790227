import { GraphDomain, SelldownGraphData, GraphPlot } from '#/types/Snapshot';
import { PriceDistributionGraphData } from '#/types/Preview';

type GraphData = PriceDistributionGraphData | SelldownGraphData;

const confirmModelHasData = (overlayData: GraphData): boolean => {
  let hasData = false;

  if (!overlayData)
    return hasData;
  const keys = Object.keys(overlayData);

  keys.forEach((key: keyof GraphData) => {
    const data: GraphPlot[] = overlayData[key];

    if (data.length > 0)
      hasData = true;
  });

  return hasData;
};

const adjustDomainForOverlay = (
  overlayData: PriceDistributionGraphData | SelldownGraphData,
  original: GraphDomain,
  overlay: GraphDomain,
): GraphDomain => {
  const modelHasData = confirmModelHasData(overlayData);

  if (!overlay || !modelHasData)
    return original;

  const xMin = Math.min(original.x[0], overlay.x[0]);
  const xMax = Math.max(original.x[1], overlay.x[1]);

  const yMin = Math.min(original.y[0], overlay.y[0]);
  const yMax = Math.max(original.y[1], overlay.y[1]);

  const adjusted = { x: [xMin, xMax], y: [yMin, yMax] };

  return adjusted;
};

export default adjustDomainForOverlay;
