import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Tooltip as GGTooltip, TooltipContainer, TooltipContent } from '@graphique/graphique';
import { formatMeasureText, LABEL_COLORS } from '../utils';
import { checkIsCurrency, SALES_METRICS } from '#/shared/reporting/accumulationUtils';
import { formatLargeNumber, formatPercentage } from '#/shared/formatNumberForDisplay';
import { formatAccountingCurrency } from '#/pages/clientReporting/DashboardPage/utils';
import type { SparklineDatum } from '#/pages/clientReporting/DashboardPage/types';
import type { CumulativeMmt } from '#/types/ReportingRollup';

interface Props {
  datum: SparklineDatum[];
  measure?: CumulativeMmt;
  isExtraNarrow?: boolean;
}

const renderTooltipContent = (
  value: TooltipContent<SparklineDatum>[],
  measure: CumulativeMmt,
  isExtraNarrow = false,
): React.ReactNode => {
  let percentValue: string;

  // show the sell through rate of managed inventory
  if (value?.[0] && measure === SALES_METRICS.ticketsSold) {
    const [denominator, numerator] = value?.[0].datum;

    percentValue = denominator.value
      ? formatPercentage(numerator.value / denominator.value)
      : undefined;
  }

  return (
    <TooltipContainer>
      <Wrapper>
        <DateContainer>
          {value?.[0].formattedX}
        </DateContainer>
        {/*
            the tooltip `value` is reversed to show the tooltip groups/values in order of
            decreasing salience, since the full set of data has the groups ordered by
            in such a way that ensures the most salient traces/lines are drawn last
            (i.e. on top of the others)
        */}
        {value?.reverse()?.map(({ formattedY, group, y }, i) => {
          const measureText = formatMeasureText(group as CumulativeMmt, isExtraNarrow);

          return (
            !Number.isNaN(y) && (
              <ValueContainer i={i} key={group.toString()}>
                <>
                  <GroupContainer i={i}>
                    {measureText}
                  </GroupContainer>
                </>
                {formattedY}
                {i === 0 && percentValue && <Percentage>{`(${percentValue})`}</Percentage>}
              </ValueContainer>
            )
          );
        })}
      </Wrapper>
    </TooltipContainer>
  );
};

const Tooltip: React.FC<Props> = ({
  datum, measure, isExtraNarrow,
}) => {
  const renderContent = useCallback((value: TooltipContent<SparklineDatum>[]) => {
    return renderTooltipContent(value, measure, isExtraNarrow);
  }, [measure, isExtraNarrow]);

  const isCurrency = useMemo(() => checkIsCurrency(measure), [measure]);
  const yFormat = useCallback((value: number) => (
    isCurrency ? formatAccountingCurrency(value, true) : formatLargeNumber(value)
  ), [isCurrency]);

  return (
    <GGTooltip
      content={renderContent}
      datum={datum}
      yFormat={yFormat}
    />
  );
};

const Wrapper = styled.div`
  padding: 0.18rem 0.15rem;
`;

const DateContainer = styled.div`
  font-size: 14px;
  color: #555;
  margin-bottom: 0.35rem;
`;

const Percentage = styled.span`
  font-size: 14px;
  margin: 0 0 0.05rem 0.3rem;
  color: #555;
`;

interface IndexProps {
  i: number;
}

const GroupContainer = styled.div<IndexProps>`
  font-size: 14px;
  font-weight: 600;
  margin-right: 0.25rem;
  color: ${({ i }: IndexProps): string => LABEL_COLORS[i]};
`;

const ValueContainer = styled.div<IndexProps>`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: ${({ i }: IndexProps): string => i === 1 ? '0.2rem' : '0.05rem'};
`;

export default Tooltip;
