import React from 'react';
import styled from 'styled-components';
import SkeletonBar from '../SkeletonBar';

const SKELETON_ROWS = [...Array(8).keys()];

const TableSkeleton: React.FC = () => (
  <Container>
    {
      SKELETON_ROWS.map((i) => {
        return (
          <RowContainer key={i}>
            <SkeletonBar background='#f3f3f3' height='2.7rem' />
          </RowContainer>
        );
      })
    }
  </Container>
);

const Container = styled.div`
  width: 100%;
  padding: 0.75rem 0.8rem;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.65rem 0.8rem;
`;

export default TableSkeleton;
