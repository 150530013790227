import client from './_clientFormatted';

interface APICall {
  status: number;
  message?: string;
}

const postInventoryUpdater = async (
  eventId: number,
): Promise<APICall> => {
  const response = await client.post(`/proxy/inventory_updater/${eventId}`);

  return { status: response.status };
};

export default postInventoryUpdater;
