import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { Schedule } from '#/types/Event';
import { GreenEllipse, RedEllipse, YellowEllipse } from '#/shared/Ellipse';
import WarningIcon from './WarningIcon';

interface CardProps {
  schedule: Schedule;
}

const LAST_RUN_AT = 'Last Run';
const ESTIMATED_NEXT_RUN_AT = 'Estimated Next Run';
const NOT_RUNNING_REASONS = ['is_inactive', 'no_run_yet'];
const ERROR = 'error';
const ERROR_MESSAGE = 'Last run attempt failed';

const ScheduledRunCard: React.FC<CardProps> = ({ schedule }) => {
  let Icon = RedEllipse;

  if (schedule.isScheduled) {
    Icon = GreenEllipse;
    if (schedule.status === ERROR)
      Icon = YellowEllipse;
  }
  return (
    <NarrowWrapper>
      <WarningIcon
        warning={schedule.status === ERROR ? ERROR_MESSAGE : undefined}
      >
        <Element>
          <Name>
            <Icon />
            {ESTIMATED_NEXT_RUN_AT}
          </Name>
          <Value>
            {
              NOT_RUNNING_REASONS.includes(schedule.reason)
                ? 'N/A'
                : schedule.estimatedNextRunAt.toRelative()
            }
          </Value>
        </Element>
      </WarningIcon>
      <Element>
        <Name>{LAST_RUN_AT}</Name>
        <Value>{schedule.lastRunAt.toRelative()}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export { NOT_RUNNING_REASONS };
export default ScheduledRunCard;
