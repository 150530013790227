import { LogData, GraphPlot } from '#/types/Snapshot';

interface Response {
  actualCompetitorLogPriceRatio: GraphPlot;
  actualCompetitorPercentilesMedium: GraphPlot;
  actualCompetitorPercentilesNarrow: GraphPlot;
  actualCompetitorPercentilesWide: GraphPlot;
}

const formatCompetitorData = (
  competitor: LogData,
  logHoursToEvent: number,
): Response => {
  const response: Response = {
    actualCompetitorLogPriceRatio: { x: logHoursToEvent, y: null },
    actualCompetitorPercentilesMedium: { x: logHoursToEvent, y: null, y0: null },
    actualCompetitorPercentilesNarrow: { x: logHoursToEvent, y: null, y0: null },
    actualCompetitorPercentilesWide: { x: logHoursToEvent, y: null, y0: null },
  };

  if (!competitor)
    return response;

  response.actualCompetitorPercentilesMedium.y = competitor.logPriceRatio90Pct;
  response.actualCompetitorPercentilesMedium.y0 = competitor.logPriceRatio10Pct;
  response.actualCompetitorPercentilesNarrow.y = competitor.logPriceRatio75Pct;
  response.actualCompetitorPercentilesNarrow.y0 = competitor.logPriceRatio25Pct;
  response.actualCompetitorPercentilesWide.y = competitor.logPriceRatio95Pct;
  response.actualCompetitorPercentilesWide.y0 = competitor.logPriceRatio5Pct;
  response.actualCompetitorLogPriceRatio.y = competitor.logPriceRatio;

  return response;
};

export default formatCompetitorData;
