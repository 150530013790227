import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  Tooltip as GGTooltip, TooltipContainer, TooltipContent, formatDate,
} from '@graphique/graphique';
import { type TimeOption, type MetricOption, TimeMeasurement } from '../utils/menuOptions';
import { type EventTimeSeriesSalesSummary } from '../utils/getSalesTrendsByEvent';
import { formatTooltipX } from '#/pages/ViewPage/components/Pricing/utils/dataFormatting';
import { useWindowWidth } from '@react-hook/window-size';
import { VIEWPORT_SMALL_PX } from '../constants';
import EventDetails from '../EventDetails';
import { COLON, formatMetricLabel } from '../utils/textFormatting';
import palette from '#/theme/palettes/main';

interface Props {
  metricSelection: MetricOption
  timeGroup: TimeOption
  focusedDatum?: EventTimeSeriesSalesSummary[]
}

const positionTooltipY = ({ height, y }: { height?: number; y?: number; }): number => (
  68 * (y > height / 2 ? 1 : -1)
);

const generateContent = (metricSelection: MetricOption, maxWidth: number, metric: 'y' | 'fill' = 'y') => (
  (value: TooltipContent<EventTimeSeriesSalesSummary>[]): JSX.Element => {
    return (
      value?.[0] ? (
        <TooltipContainer style={{ maxWidth, background: `${palette.white.dark}ee` }}>
          <Wrapper>
            <StyledEvent event={value?.[0]?.datum[0].autobrokerEvent} isCondensed />
            <Measurements>
              <TimeMeasure>
                {value?.[0].formattedX}
              </TimeMeasure>
              <MetricWrapper>
                <div>
                  {formatMetricLabel(metricSelection.label)}
                  {COLON}
                </div>
                <FormattedMetric>
                  {metric === 'y' ? value?.[0].formattedY : value?.[0].formattedMeasure}
                </FormattedMetric>
              </MetricWrapper>
            </Measurements>
          </Wrapper>
        </TooltipContainer>
      )
        : null
    );
  }
);

const Tooltip: React.FC<Props> = ({ metricSelection, timeGroup, focusedDatum }) => {
  const windowWidth = useWindowWidth();
  const maxWidth = windowWidth < VIEWPORT_SMALL_PX ? 220 : 350;

  const xFormat = useMemo(() => (
    timeGroup.key === TimeMeasurement.TIME_TO_EVENT ? formatTooltipX : formatDate
  ), [timeGroup]);

  const yFormat = useCallback((d: number) => (
    metricSelection?.format ? metricSelection.format({ value: d }) : d.toLocaleString()
  ), [metricSelection]);

  return (
    <GGTooltip
      content={generateContent(metricSelection, maxWidth)}
      datum={focusedDatum}
      dy={positionTooltipY}
      xFormat={xFormat}
      yFormat={yFormat}
    />
  );
};

const Wrapper = styled.div`
  letter-spacing: 0;
  white-space: normal;
`;

const StyledEvent = styled(EventDetails)`
  margin: 0.3rem 0;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eee;
`;

const Measurements = styled.div`
  display: flex;
  align-items: flex-baseline;
  column-gap: 0.55rem;
  margin-top: 0.5rem;
  flex-wrap: wrap;
`;

const TimeMeasure = styled.div`
  font-size: 0.83rem;
  color: #888;
`;

const MetricWrapper = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
  font-size: 0.88rem;
  font-weight: 500;
`;

const FormattedMetric = styled.div`
  font-size: 0.91rem;
  font-weight: 500;
`;

export { generateContent };
export default Tooltip;
