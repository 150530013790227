import React, { useMemo, useContext, useEffect } from 'react';
import styled from 'styled-components';
import ReportingContext from '#/pages/clientReporting/ReportingContext';
import useFetchReportingRollup from '#/pages/useFetchReportingRollup';
import { SectionTitleContainer, SectionTitle } from '#/shared/clientReporting/typography';
import { EXTRA_NARROW_BREAKPOINT } from '#/shared/clientReporting/utils';
import Histogram from './Histogram';

const TITLE = 'Ticketing Distribution';

const TicketingHistogram: React.FC = () => {
  const {
    stakeholder,
    setHasPendingData,
    queryParams,
    reportingSelection,
  } = useContext(ReportingContext);

  const {
    rollup, isLoading, hasError, fetchRollup,
  } = useFetchReportingRollup({
    stakeholder: stakeholder?.value,
    group: ['price'],
    ...queryParams,
  });
  const shouldLoad = useMemo(() => (
    isLoading && (rollup?.length === 0 || !reportingSelection?.selectedEvent)
  ), [isLoading, rollup, reportingSelection]);

  useEffect(() => {
    setHasPendingData(isLoading);
  }, [isLoading, setHasPendingData]);

  return (
    <Container>
      <SectionTitleContainer>
        <SectionTitle>{TITLE}</SectionTitle>
      </SectionTitleContainer>
      <Histogram
        data={rollup}
        hasError={hasError}
        loading={shouldLoad}
        retry={fetchRollup}
        selectedEvent={reportingSelection?.selectedEvent}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 1rem 1rem 0 1rem;
  height: 282px;
  @media (max-width: ${EXTRA_NARROW_BREAKPOINT}px) {
    margin: 1rem 0 0 0;
  };
`;

export default TicketingHistogram;
