import styled from 'styled-components';
import palette from '#/theme/palettes/main';

const Button = styled.button`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.light};
  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 12px;
  min-width: 4rem;
  height: 2.25rem;
  margin: 0.25rem 0.5rem;
  ${({ theme }: { theme: Theme }): string => theme.text1bold};
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.selected};
  transition: all 0.2s ease;
  text-align: center;
  overflow: hidden;
  :hover {
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.action.highlight};
  }
  :disabled {
    background-color: ${palette.brand.light56};
    cursor: not-allowed;
  }
`;

const ButtonSmall = styled(Button as any)`
  border-radius: 8px;
  font-size: 1rem;
  height: 2rem;
  white-space: nowrap;
  line-height: 1rem;
`;

const ButtonSmallest = styled(Button as any)`
  border-radius: 6px;
  font-size: 16px;
  height: 2rem;
  white-space: nowrap;
  padding-left:0.4rem;
  padding-right:0.4rem;
  padding-bottom:0.1rem;
  padding-top:0.25rem;
  margin: 0.25rem 0.5rem 0rem 0.5rem; 
`;

const ButtonSmallLink = styled.a`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.light};
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  min-width: 4rem;
  max-height: 1.75rem;
  margin: 0.25rem 0.25rem;
  ${({ theme }: { theme: Theme }): string => theme.text1bold};
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.selected};
  transition: all 0.2s ease;
  text-align: center;
  overflow: hidden;
  font-size: 1rem;
  white-space: nowrap;
  line-height: 2rem;
  text-decoration: none;
  :hover {
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.action.highlight};
  }
  :disabled {
    background-color: ${palette.brand.light56};
    cursor: not-allowed;
  }
`;

const ButtonTransparent = styled(ButtonSmall as any)`
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.highlight};
  min-width: 1rem;
  :hover {
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.highlight};
  }
  :disabled {
    color: ${({ theme }: { theme: Theme }): string => theme.color.text.tertiary};
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
  }
`;

export {
  ButtonSmall, ButtonSmallest, ButtonTransparent, ButtonSmallLink,
};
export default Button;
