import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  Wrapper, Element, Name,
} from '#/shared/Card';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import usePriceExplorationReducer from './usePriceExplorationReducer';
import { ButtonTransparent } from '#/shared/Button';
import Loading from '../../../Loading';
import usePriceExploration from './usePriceExploration';
import ExplorationInputs from './ExplorationInputs';
import ErrorModal from '#/shared/ErrorModal';

const PRICE_EXPLORATION = 'Price Exploration';
const RESET = 'Reset';
const UPDATE = 'Update';
const DELETE = 'Delete';
const SUCCESS = 'Success!';

interface PriceExplorationCardProps {
  disabled: boolean;
}

const PriceExplorationCard: React.FC<PriceExplorationCardProps> = ({ disabled }) => {
  const { activeEvent, retrieveActiveEvent } = useContext(ActiveEventContext);
  const { priceExploration } = activeEvent;
  const {
    priceExplorationConfig,
    resetPriceExplorationConfig,
    updatePriceExplorationConfig,
  } = usePriceExplorationReducer(priceExploration);

  const {
    clearError,
    error,
    loading,
    handleDelete,
    handleReset,
    handleSubmit,
    success,
  } = usePriceExploration(
    activeEvent,
    priceExplorationConfig,
    resetPriceExplorationConfig,
    retrieveActiveEvent,
  );

  const deleteButtonDisabled = (!priceExplorationConfig.autobrokerEventId) || disabled;
  const updateButtonDisabled = priceExplorationConfig && disabled;
  const resetButtonDisabled = disabled;

  return (
    <>
      <Wrapper>
        <Success hidden={!success}>{SUCCESS}</Success>
        {!loading && (
          <Element>
            <Name>{PRICE_EXPLORATION}</Name>
            <form onSubmit={handleSubmit}>
              <ExplorationInputs
                disabled={disabled}
                priceExplorationConfig={priceExplorationConfig}
                updatePriceExplorationConfig={updatePriceExplorationConfig}
              />
              <ButtonsWrapper>
                <ButtonTransparent
                  disabled={deleteButtonDisabled}
                  onClick={handleDelete}
                  type="button"
                >
                  {DELETE}
                </ButtonTransparent>
                <ButtonTransparent disabled={resetButtonDisabled} onClick={handleReset} type="button">
                  {RESET}
                </ButtonTransparent>
                <ButtonTransparent
                  disabled={updateButtonDisabled}
                  type="submit"
                >
                  {UPDATE}
                </ButtonTransparent>
              </ButtonsWrapper>
            </form>
          </Element>
        )}
        {loading && <Loading loading={loading} />}
      </Wrapper>
      <ErrorModal clearError={clearError} error={error} />
    </>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0.5rem 1rem 0 1rem;
  justify-content: center;
`;

const Success = styled.p`
  position: absolute;
  top: 0rem;
  right: 0.75rem;
  ${({ theme }: { theme: Theme }): string => theme.text4};
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  border: solid 1px
    ${({ theme }: { theme: Theme }): string => theme.color.text.success};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.success};
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.success};
`;

export default PriceExplorationCard;
