import { useState, useCallback } from 'react';

type Hook = () => {
  isPrice: boolean;
  handleYScaleChange: () => void;
}

const useYScaleChange: Hook = () => {
  const [isPrice, setIsPrice] = useState(false);
  const handleYScaleChange = useCallback(() => {
    setIsPrice((prev) => !prev);
  }, []);

  return {
    isPrice,
    handleYScaleChange,
  };
};

export default useYScaleChange;
