import { TransactionFiltersFormatted } from '#/types/Transaction';

type Kind = 'sales' | 'listings';

const createFileName = ({ stakeholder, seatgeekEventId }: TransactionFiltersFormatted, kind: Kind, extension = 'csv'): string => {
  const datePart = (new Date()).toISOString().split('.')[0];
  const stakeholderPart = stakeholder ? `_${stakeholder}` : '';
  const eventPart = seatgeekEventId ? `_${seatgeekEventId}` : '';
  const extensionPart = `.${extension}`;

  return (
    `${datePart}_${kind}${stakeholderPart}${eventPart}${extensionPart}`
  );
};

export default createFileName;
