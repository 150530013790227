import React, {
  useContext,
  useMemo,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { ActiveEventContext } from '../../contexts/ActiveEvent';
import formatConfigTableData from './utils/formatConfigTableData';
import { SOURCE_CONFIG_HEADERS, SINK_CONFIG_HEADERS } from './constants';
import Loading from './Loading';
import EventConfig from './EventConfig';
import SourceTable from './SourceTable';
import SinkTable from './SinkTable';
import SalesPeriodTable from './SalesPeriodTable';
import useSinkModal from './useSinkModal';
import Modals from './Modals';
import { User } from '#/types/User';
import { FetchGroupsHook } from '#/pages/useFetchGroups';

interface ConfigProps {
  user: User;
  error: boolean;
  loading: boolean;
  groupsHook: FetchGroupsHook;
}

const SALES_PERIODS = 'Sales Periods';
const SOURCE = 'Source Config';
const SINK = 'Sink Config';

const Configuration: React.FC<ConfigProps> = ({
  user, error, loading, groupsHook,
}) => {
  const { activeEvent, retrieveActiveEvent } = useContext(ActiveEventContext);
  const id = activeEvent?.id;

  const {
    activeEventSink,
    sinkModalIsOpen,
    setSinkModalIsOpen,
    updateSink,
  } = useSinkModal();

  const showResults = activeEvent && !error && !loading;
  const { eventConfigInfo, sinkInfo, sourceInfo } = useMemo(
    () => formatConfigTableData(activeEvent),
    [activeEvent],
  );
  const fetchEvent = useCallback(() => {
    if (id)
      retrieveActiveEvent(id);
  }, [id, retrieveActiveEvent]);

  return (
    <div id="configuration">
      <Wrapper>
        {showResults && (
          <>
            <Section>
              <EventConfig
                activeEvent={activeEvent}
                groupsHook={groupsHook}
                retry={fetchEvent}
                tableData={eventConfigInfo}
                user={user}
              />
            </Section>
            <Section>
              <Title>{SALES_PERIODS}</Title>
              <SalesPeriodTable
                config={activeEvent.config}
                overrides={activeEvent.overrides}
                periods={activeEvent.salesPeriods}
              />
            </Section>
            <Section>
              <Title>{SOURCE}</Title>
              <SourceTable
                columns={SOURCE_CONFIG_HEADERS}
                disabled={user.isReadOnly}
                retry={fetchEvent}
                tableData={sourceInfo}
              />
            </Section>
            <Section>
              <Title>{SINK}</Title>
              <SinkTable
                columns={SINK_CONFIG_HEADERS}
                setActiveEventSink={updateSink}
                setSinkModalIsOpen={setSinkModalIsOpen}
                tableData={sinkInfo}
                user={user}
              />
            </Section>

            {user.isReadWrite && (
              <Modals
                eventId={id}
                retry={fetchEvent}
                salesPeriods={activeEvent.salesPeriods}
                setSinkModalIsOpen={setSinkModalIsOpen}
                sink={activeEventSink}
                sinkModalIsOpen={sinkModalIsOpen}
              />
            )}
          </>
        )}
        <Loading loading={loading} />
      </Wrapper>
    </div>
  );
};

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const Wrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 8rem;
  width: 100%;
  margin-top: 3.75rem;
`;

export default Configuration;
