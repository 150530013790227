import React from 'react';
import styled from 'styled-components';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { Transaction } from '#/types/Ingestion';
import Cancelled from '#images/Cancelled.svg';
import Created from '#images/Created.svg';
import Fulfilled from '#images/Fulfilled.svg';

const SALE = 'Sale';
const AUTOBROKER_EVENT = 'Autobroker Event';
const SUBMITTED_PRICE = 'Submitted Price';

interface CardProps {
  transaction: Transaction;
}

const TransactionCard: React.FC<CardProps> = ({ transaction }) => {
  const {
    autobroker_event_id, quantity, submitted_price_per_ticket, state, created_at,
  } = transaction;

  let stateImage = Cancelled;

  if (state === 'created')
    stateImage = Created;
  else if (state === 'fulfilled' || state === 'purchased')
    stateImage = Fulfilled;

  return (
    <NarrowWrapper>
      <Element>
        <Center>
          <Name>{SALE}</Name>
        </Center>
        <StateImg src={stateImage} title={state} />
      </Element>
      <Element>
        <Name>
          {AUTOBROKER_EVENT}
          <Link
            href={`/view/${autobroker_event_id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {autobroker_event_id}
          </Link>
        </Name>
      </Element>
      <Element>
        <Name>{SUBMITTED_PRICE}</Name>
        <Value>{`${quantity}x • $${submitted_price_per_ticket ? submitted_price_per_ticket.toFixed(2) : '???'}`}</Value>
      </Element>
      <Element>
        <Name>{`Sold ${created_at.toRelative()}`}</Name>
      </Element>
    </NarrowWrapper>
  );
};

const Link = styled.a`
  padding-left: 0.35rem
`;

const StateImg = styled.img`
  height: 18px;
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
`;

const Center = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`;

export default TransactionCard;
