import React, { useCallback } from 'react';
import Action, { EventType } from '../types';
import { DYNAMIC_PRICING_MODEL_OPTIONS } from '#/types/Event';
import SelectForm, { type Option } from '../Forms/SelectForm';

interface SelectProps {
  handleInputChange: React.Dispatch<Action>;
  label: string;
}

const SelectDynamicPricingModel: React.FC<SelectProps> = ({
  handleInputChange,
  label,
}) => {
  const handleChange = useCallback(
    (option: Option) => {
      const { value } = option;

      handleInputChange({ type: EventType.DYNAMIC_PRICING_MODEL, value });
    },
    [handleInputChange],
  );
  const defaultOption = DYNAMIC_PRICING_MODEL_OPTIONS[0];

  return (
    <SelectForm
      defaultValue={defaultOption}
      label={label}
      onChange={handleChange}
      options={DYNAMIC_PRICING_MODEL_OPTIONS}
    />
  );
};

export default SelectDynamicPricingModel;
