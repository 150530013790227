import React, {
  useState, useCallback, useEffect, useContext, useMemo,
} from 'react';
import {
  FilterSelect, getOption, Form, SmallInput,
} from '#/shared/Filters';
import TableWrapper, { Cell, RowWrapper, SmallHeader } from '#/shared/TableComponents';
import Button from '#/shared/Button';
import { TransactionFilters, STATES } from '#/types/Transaction';
import type { GenericOption } from '#/types/GenericOption';
import useFetchStakeholders, { type Option as StakeholderOption } from '#/pages/useFetchStakeholders';
import { ActiveEventContext } from '../../contexts/ActiveEvent';
import useFetchDeals, { type Option as DealOption } from '#/pages/useFetchDeals';

const SEARCH = 'Search';
const COLUMNS = [
  'Section',
  'Row',
  'Stakeholder',
  'Deal',
  'Deal Term',
  'Source',
  'State',
  '',
];

interface FilterProps {
  filters: TransactionFilters;
  setFilters: (filters: TransactionFilters) => void;
}

const Filters: React.FC<FilterProps> = ({
  filters,
  setFilters,
}) => {
  const [input, setInput] = useState<TransactionFilters>(filters);
  const { activeEvent } = useContext(ActiveEventContext);
  const autobrokerEventId = useMemo(() => activeEvent?.id, [activeEvent]);
  const {
    dealOptions, selectedDeal, setSelectedDeal, dealTermOptions,
  } = useFetchDeals({ stakeholder: input.stakeholder, autobrokerEventId: autobrokerEventId });
  const { stakeholderLabels } = useFetchStakeholders(autobrokerEventId);
  const stateOptions = Object.values(STATES).map((state) => ({ label: state, value: state }));
  const sourceOptions = useMemo(() => activeEvent?.sourceEvents?.map(
    (s) => ({ label: s.sourceName, value: s.sourceName }),
  ) || [], [activeEvent]);

  const setSection = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const section = event?.target?.value || null;

    setInput((i) => ({ ...i, section }));
  }, [setInput]);

  const setRow = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const row = event?.target?.value || null;

    setInput((i) => ({ ...i, row }));
  }, [setInput]);

  const setStakeholder = useCallback((event: StakeholderOption<string>) => {
    const stakeholder = event?.value || null;

    setSelectedDeal(null);
    setInput((i) => ({
      ...i, stakeholder, deal: undefined, deal_term: undefined,
    }));
  }, [setSelectedDeal, setInput]);

  const setSourceName = useCallback((event: GenericOption<string, string>) => {
    setInput({ ...input, source_name: event?.value });
  },
  [input]);

  const setTransactionState = useCallback((event: GenericOption<STATES, STATES>) => {
    const state = event?.value;

    setInput((i) => ({ ...i, state }));
  }, [setInput]);

  const setDeal = useCallback((event: DealOption<string>) => {
    const deal = event?.value || null;

    setSelectedDeal(deal);
    setInput((i) => ({ ...i, deal, deal_term: undefined }));
  }, [setSelectedDeal, setInput]);

  const setDealTerm = useCallback((event: DealOption<string>) => {
    const deal_term = event?.value || null;

    setInput((i) => ({ ...i, deal_term }));
  }, [setInput]);

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    setFilters(input);
  }, [input, setFilters]);

  useEffect(() => {
    if (filters.autobroker_event_id)
      setInput(filters);
  }, [filters]);

  const hideDealFilter = useMemo(() => {
    return !input.stakeholder || dealOptions?.length === 0;
  }, [input.stakeholder, dealOptions]);

  const hideDealTermFilter = useMemo(() => !selectedDeal, [selectedDeal]);

  const hideColumn = useCallback((column: string) => {
    switch (column) {
      case 'Deal':
        return hideDealFilter;
      case 'Deal Term':
        return hideDealTermFilter;
      default:
        return false;
    }
  }, [hideDealFilter, hideDealTermFilter]);

  return (
    <Form onSubmit={handleSubmit}>
      <TableWrapper>
        <thead>
          <SmallHeader>
            {COLUMNS.map((column) => (
              <Cell hidden={hideColumn(column)} key={column}>{column}</Cell>
            ))}
          </SmallHeader>
        </thead>
        <tbody>
          <RowWrapper>
            <Cell>
              <SmallInput
                defaultValue={filters.section}
                onChange={setSection}
                type='text'
              />
            </Cell>
            <Cell>
              <SmallInput
                defaultValue={filters.row}
                onChange={setRow}
                type='text'
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.stakeholder, stakeholderLabels)}
                onChange={setStakeholder}
                options={stakeholderLabels}
              />
            </Cell>
            {!hideDealFilter
              && (
                <Cell>
                  <FilterSelect
                    defaultValue={getOption(filters.deal, dealOptions)}
                    onChange={setDeal}
                    options={dealOptions}
                    width="9rem"
                  />
                </Cell>
              )}
            {!hideDealTermFilter
              && (
                <Cell>
                  <FilterSelect
                    defaultValue={getOption(filters.deal_term, dealTermOptions)}
                    onChange={setDealTerm}
                    options={dealTermOptions}
                    width="9rem"
                  />
                </Cell>
              )}
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.source_name, sourceOptions)}
                onChange={setSourceName}
                options={sourceOptions}
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.state, stateOptions)}
                onChange={setTransactionState}
                options={stateOptions}
              />
            </Cell>
            <Cell>
              <Button type='submit'>{SEARCH}</Button>
            </Cell>
          </RowWrapper>
        </tbody>
      </TableWrapper>
    </Form>
  );
};

export default Filters;
