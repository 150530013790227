import UploaderEvent, { APIUploaderEvent } from '#/types/UploaderEvent';

const mapUploaderEvent = (event: APIUploaderEvent): UploaderEvent => {
  return {
    id: event.id,
    uploader: event.uploader,
    eventTitle: event.event_title,
    eventPerformer: event.event_performer,
    eventDate: event.event_date,
    eventTime: event.event_time,
    venueName: event.venue_name,
    venueCity: event.venue_city,
    venueState: event.venue_state,
    stubhubEventId: event.stubhub_event_id,
    uploaderTicketCount: event.uploader_ticket_count,
    uploaderListingCount: event.uploader_listing_count,
  };
};

export default mapUploaderEvent;
