import React from 'react';
import { NarrowWrapper, Element, Name } from '#/shared/Card';
import ButtonSmall from '#/shared/Button';

const DEALS = 'Deals';
const CREATE = 'Create';
const UPDATE = 'Update';

interface DealCardProps {
  openCreateModal: () => void;
  openUpdateModal: () => void;
}

const DealCard: React.FC<DealCardProps> = ({ openCreateModal, openUpdateModal }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{DEALS}</Name>
      </Element>
      <Element>
        <ButtonSmall onClick={openCreateModal}>{CREATE}</ButtonSmall>
      </Element>
      <Element>
        <ButtonSmall onClick={openUpdateModal}>{UPDATE}</ButtonSmall>
      </Element>
    </NarrowWrapper>
  );
};

export default DealCard;
