import React, { useContext, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import { User } from '#/types/User';
import Loader from '#/shared/Loader';
import palette from '#/theme/palettes/main';
import ListingsTableAndDetail from './ListingsTableAndDetail';
import useFetchListings from './useFetchListings';
import Filters from './Filters';
import { StakeholderLogos } from '#/pages/useFetchStakeholders';

interface ListingsProps {
  user: User;
  error: boolean;
  loading: boolean;
  stakeholderLogos: StakeholderLogos;
}

const Listings: React.FC<ListingsProps> = ({
  user, error, loading, stakeholderLogos,
}) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const autobrokerEventId = useMemo(() => activeEvent?.id, [activeEvent]);

  const listingsHook = useFetchListings({ autobrokerEventId, perPage: 10 });

  const {
    filters, setFilters, showResults,
  } = listingsHook;

  useEffect(() => {
    const updateAutobrokerEventId = (id: number): void => {
      setFilters({
        ...filters,
        autobrokerEventId: id,
      });
    };

    if (autobrokerEventId)
      updateAutobrokerEventId(autobrokerEventId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autobrokerEventId]);

  const showListingsResults = useMemo(() => {
    return autobrokerEventId && !error && !loading && showResults;
  }, [autobrokerEventId, error, loading, showResults]);

  return (
    <div id="listings">
      <Wrapper>
        <FilterWrapper>
          <Filters
            filters={filters}
            setFilters={setFilters}
          />
        </FilterWrapper>
        <Section>
          {showListingsResults && (
            <ListingsTableAndDetail
              listingsHook={listingsHook}
              stakeholderLogos={stakeholderLogos}
              user={user}
            />
          )}
        </Section>
        {!showListingsResults && (
          <Section>
            <Loader hexColor={palette.brand.base} />
          </Section>
        )}
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
    width: 77.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 6rem;
  `;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
`;

const FilterWrapper = styled(Section as any)`
  margin-top: 3.75rem;
`;

export default Listings;
