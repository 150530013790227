import React, { useMemo } from 'react';
import styled from 'styled-components';
import Header, { HEADER_HEIGHT } from './Header';
import FilterContainer, { FILTER_CONTAINER_HEIGHT } from '#/shared/clientReporting/FilterContainer';
import { EXTRA_NARROW_BREAKPOINT } from './utils';

interface Props {
  hasStakeholder?: boolean;
  children?: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children, hasStakeholder }) => {
  const headerOffset = useMemo(() => (
    hasStakeholder
      ? `${HEADER_HEIGHT} + ${FILTER_CONTAINER_HEIGHT}`
      : HEADER_HEIGHT
  ), [hasStakeholder]);

  return (
    <div>
      <Header />
      {hasStakeholder && (
        <FilterContainer />
      )}
      <PageContainer
        headerOffset={headerOffset}
        id='page-container'
      >
        <PageContent>
          {children}
          <BottomSpacing />
        </PageContent>
      </PageContainer>
    </div>
  );
};

interface PageContainerProps {
  headerOffset: string;
}

const PageContainer = styled.div<PageContainerProps>`
  display: flex;
  position: fixed;
  top: ${({ headerOffset }): string => `calc(${headerOffset})`};
  width: 100%;
  height: ${({ headerOffset }): string => `calc(100% - (${headerOffset}))`};
  overflow-x: auto;
  overflow-y: scroll;
  justify-content: center;
  margin-top: 1px;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  align-items: center;
  padding: 0 1rem;
  max-width: 1350px;
  @media (max-width: ${EXTRA_NARROW_BREAKPOINT}px) {
    padding: 0;
    width: calc(100% - 2rem);
  };
`;

const BottomSpacing = styled.div`
  height: '2rem';
`;

export default Layout;
