import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import PriceLevel from '#/types/PriceLevel';
import { formatCurrency, formatGtv, formatNumber } from '#/shared/formatNumberForDisplay';

interface CardProps {
  priceLevel: PriceLevel;
}

const EXPECTED_VALUE = 'Expected Value';
const LISTING_EXPECTED_VALUES = 'Listing Expected Values';
const OVERRIDE_EXPECTED_VALUE = 'Override Expected Value';

const ExpectedValueCard: React.FC<CardProps> = ({ priceLevel }) => {
  const { price, summary } = priceLevel;

  const expectedValue = price.logExpectedValue && formatCurrency(Math.exp(price.logExpectedValue));
  const minExpectedValue = (
    summary.minLogExpectedValue
    && Math.exp(summary.minLogExpectedValue)
  );
  const maxExpectedValue = (
    summary.maxLogExpectedValue
    && Math.exp(summary.maxLogExpectedValue)
  );
  const scale = minExpectedValue && maxExpectedValue && (maxExpectedValue / minExpectedValue);

  const overrideExpectedValue = priceLevel.override?.expectedValue;

  return (
    <NarrowWrapper>
      {expectedValue && (
        <Element>
          <Name>{EXPECTED_VALUE}</Name>
          <Value>{expectedValue}</Value>
        </Element>
      )}
      {scale && (
        <Element>
          <Name>{LISTING_EXPECTED_VALUES}</Name>
          <Value>{`${formatGtv(minExpectedValue)} - ${formatGtv(maxExpectedValue)} (${formatNumber(scale)}x)`}</Value>
        </Element>
      )}
      {overrideExpectedValue && (
        <Element>
          <Name>{OVERRIDE_EXPECTED_VALUE}</Name>
          <Value>{formatCurrency(overrideExpectedValue)}</Value>
        </Element>
      )}
    </NarrowWrapper>
  );
};

export default ExpectedValueCard;
