import React from 'react';
import styled from 'styled-components';
import InfoTooltip from '#/shared/InfoTooltip';

const DEFINITION = 'Total payout to be received from managed inventory';
const DISCLAIMER = 'Financial numbers are estimates subject to final adjustment and should not be used for settlement purposes.';

const TakeInfoTooltip: React.FC = () => (
  <InfoTooltip
    ariaLabel='take-tooltip'
    closeDelay={80}
    delay={80}
  >
    {DEFINITION}
    <SecondaryInfo>
      {DISCLAIMER}
    </SecondaryInfo>
  </InfoTooltip>
);

const SecondaryInfo = styled.div`
  margin-top: 4px;
  color: #777;
  font-size: 12px;
  line-height: 1;
`;

export default TakeInfoTooltip;
export { DEFINITION };
