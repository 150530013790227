import { SelldownGraphInfo } from '#/types/Snapshot';

const ZERO = 0;

const determineShowOverlay = (
  overlayError: string,
  selldownOverlay: SelldownGraphInfo,
): boolean => {
  if (overlayError || !selldownOverlay)
    return false;
  if (selldownOverlay.graphData.ticketsTargetOverlay.length === ZERO)
    return false;
  return true;
};

export default determineShowOverlay;
