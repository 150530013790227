import styled from 'styled-components';
import palette from '#/theme/palettes/main';
import { menuStyles } from './modalStyles';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 50vh;
`;

const Title = styled.div`
  margin: 1.5rem 0;
  font-size: 1.75rem;
  line-height: 1.2;
  color: ${palette.gray.base};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.5rem 0 0.5rem 0;
`;

const Error = styled.div`
  color: ${({ theme }: { theme: Theme }): string => theme.color.action.warning};
  height: 1.5rem;
`;

const Header = styled.h3`
  ${({ theme }: { theme: Theme }): string => theme.header3}
`;

const InnerWrapper = styled.div`
  margin-bottom: 1rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  justify-content: center;
  font-size: 15px;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Label = styled.label`
  text-align: left;
  width: 10rem;
  padding: 4px;
`;

const Info = styled.label`
  text-align: left;
  width: 100%;
  padding: 4px;
  font-size: 14px;
  display: block
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid ${palette.silver.dark};
  padding: 4px;
  font-size: 14px;
`;

const Checkbox = styled.input`
  height: 1.8rem;
  width: 3rem;

  &:checked {
    background-color: ${palette.brand.base};
  }
  &:after {
    background-color: ${palette.brand.base};
  }
`;

const Content = styled.div.attrs(({ className }: { className: string }) => ({
  className: `${className} modalContent`,
}))`
  padding: 0.9rem 0;
  border-top: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}`};
  border-bottom: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}`};
  max-height: 50vh;
  overflow-y: auto;
`;

const isContentScrolling = (e: Event): boolean => {
  const target = e.target as HTMLElement;

  return (
    target?.classList?.contains('modalContent')
    || target?.nodeName === '#document'
  );
};

const modalMenuProps = {
  isMulti: false,
  styles: menuStyles,
  closeMenuOnScroll: isContentScrolling,
  menuPortalTarget: document.body,
};

const TextArea = styled.textarea`
  width: 100%;
  height: 5rem;
  border: 1px solid ${palette.silver.dark};
  padding: 4px;
  font-size: 14px;
`;

export {
  Error,
  ButtonsWrapper,
  SectionWrapper,
  Title,
  Header,
  InnerWrapper,
  HorizontalWrapper,
  Label,
  Info,
  Input,
  Checkbox,
  TextArea,
  Content,
  modalMenuProps,
};
