import React from 'react';
import { EventConfigTableData } from '../../utils/formatEventInfo';
import EventDateCard from './EventDateCard';
import IdCard from './IdCard';
import ModelOptionsCard from './ModelOptionsCard';
import SupervisorDateCard from './SupervisorDateCard';
import PricingConstraintsButton from '../../PricingConstraintsButton';
import PriceExplorationCard from './PriceExplorationCard';
import { CardsWrapper } from '#/shared/Card';
import { User } from '#/types/User';
import Event from '#/types/Event';

interface EventConfigTableProps {
  user: User;
  event: Event;
  tableData: EventConfigTableData;
}

const Cards: React.FC<EventConfigTableProps> = ({ user, event, tableData }) => {
  return (
    <CardsWrapper>
      <EventDateCard tableData={tableData} />
      <SupervisorDateCard tableData={tableData} />
      <IdCard tableData={tableData} />
      <ModelOptionsCard tableData={tableData} />
      <PriceExplorationCard disabled={user.isReadOnly} />
      {user.isReadWrite && (
        <PricingConstraintsButton
          eventId={event.id}
          hasPriceConstraints={event.hasPriceConstraints}
        />
      )}
    </CardsWrapper>
  );
};

export default Cards;
