import styled from 'styled-components';
import palette from '#/theme/palettes/main';

const FormWrapper = styled.form`
  display: flex;
  margin: 0.5rem;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  input {
    height: 1.6rem;
  }
`;

const Label = styled.label`
  min-width: 40%;
`;

const Input = styled.input`
  width: 15rem;
  border: 1px solid ${palette.silver.dark};
  padding: 4px;
  font-size: 14px;
`;

export { FormWrapper, Label, Input };
