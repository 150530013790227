import type { BulkOverrides, Settings } from '#/types/Override';
import { OverrideOperation, RequestedOverride } from '#/types/ReportingRollup';
import { isDefined, type RollupEvent } from './getSalesTrendsByEvent';
import snakecaseKeys from 'snakecase-keys';

interface Params {
  requestedOverrides?: RequestedOverride[]
  existingOverrides?: BulkOverrides[]
  existingParameters?: Settings[]
  events?: RollupEvent[]
  overrideMode: OverrideOperation
}

interface BeforeAfterEventOverride extends RollupEvent {
  overrides: {
    before?: number
    after: number
    diff: number
    label: string
    previousOverrideValue?: number
  }[]
}

const calcValueByMode = (
  before: number | undefined,
  value: number | null,
  overrideMode: OverrideOperation,
): number | null => {
  const hasDefinedValues = isDefined(before) && isDefined(value);

  if (hasDefinedValues && overrideMode === OverrideOperation.SHIFT)
    return before + value;

  if (hasDefinedValues && overrideMode === OverrideOperation.SCALE)
    return before * value;

  return value;
};

const assembleRequestedOverridesData = ({
  requestedOverrides, events, existingOverrides, existingParameters, overrideMode,
}: Params): BeforeAfterEventOverride[] => {
  const combined = events?.map((event, i) => {
    const overrides = requestedOverrides?.map(({ label, value, type }) => {
      const previousOverrideValue = existingOverrides?.[i]?.parameters
        ?.find((p) => p.type === type)?.value;
      const previousParameterValue = existingParameters?.[i]
        ? snakecaseKeys(existingParameters[i])?.[type]
        : undefined;
      const before = previousOverrideValue ?? previousParameterValue;
      const calculatedAfter = calcValueByMode(before, value, overrideMode);

      return ({
        label,
        after: calculatedAfter,
        before,
        previousOverrideValue,
        diff: (typeof before === 'undefined' || calculatedAfter === null) ? undefined : calculatedAfter - before,
      });
    });

    return {
      ...event,
      overrides,
    };
  });

  return combined;
};

export default assembleRequestedOverridesData;
