import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { usePageVisibility } from 'react-page-visibility';
import getListings from '#/api/getListings';
import Listing, { ListingFilters } from '#/types/Listing';

interface UseListingsHook {
  listings: Listing[];
  perPage: number,
  pages: number[];
  nextPage: () => void;
  prevPage: () => void;
  filters: ListingFilters;
  setFilters: (newFilters: ListingFilters) => void;
  retry: () => void;
  showError: boolean;
  showLoader: boolean;
  showResults: boolean;
}

const useFetchListings = (initial?: ListingFilters): UseListingsHook => {
  const initialFiltersString = JSON.stringify(initial);
  const isVisible = usePageVisibility();
  const [filters, setFiltersRaw] = useState<ListingFilters>(initial);
  const [pages, setPages] = useState<number[]>([]);
  const [listings, setListings] = useState<Listing[]>([]);
  const [perPage, setPerPage] = useState<number>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const retry = useCallback(async () => {
    setHasError(false);
    try {
      if (filters.autobrokerEventId || !initialFiltersString) {
        const [returnedListings, returnedPerPage] = await getListings(filters) || [];

        setListings(returnedListings);
        setPerPage(returnedPerPage);
      }
    } catch {
      setHasError(true);
    }
    setLoading(false);
  }, [filters, initialFiltersString]);

  const showResults = useMemo(() => !isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showLoader = useMemo(() => isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showError = useMemo(() => !isLoading && hasError, [
    hasError,
    isLoading,
  ]);

  // refresh the event data
  useEffect(() => {
    setLoading(true);
    retry();
    const interval = setInterval(() => {
      if (pages.length === 0 && isVisible)
        retry();
    }, 30000);

    return (): void => clearInterval(interval);
  }, [pages.length, retry, isVisible]);

  const nextPage = useCallback(() => {
    setPages((p) => [...p, filters.fromId]);
    const fromId = listings[listings.length - 1].id;

    setFiltersRaw({ ...filters, fromId: fromId });
  },
  [listings, filters]);

  const prevPage = useCallback(() => {
    setFiltersRaw({ ...filters, fromId: pages[pages.length - 1] });
    setPages((p) => p.slice(0, p.length - 1));
  },
  [filters, pages]);

  const setFilters = useCallback((newFilters: ListingFilters) => {
    setFiltersRaw({ ...newFilters, fromId: undefined });
    setPages([]);
  }, []);

  return {
    listings,
    perPage,
    pages,
    nextPage,
    prevPage,
    filters,
    setFilters,
    retry,
    showError,
    showLoader,
    showResults,
  };
};

export { UseListingsHook };
export default useFetchListings;
