import { AxiosResponse } from 'axios';
import client from './_client';
import Event, { APIEvent } from '#/types/Event';
import mapEvent from './utils/mapEvent';

/* eslint-enable camelcase */

const getEvent = async (eventId: number): Promise<Event> => {
  const response: AxiosResponse<APIEvent> = await client.get(
    `/proxy/event/${eventId}`,
  );
  const apiEvent: APIEvent = response?.data || null;

  return mapEvent(apiEvent);
};

export default getEvent;
