import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Check from '#/shared/clientReporting/Icons/Check';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import {
  CANCEL, CONFIRMATION_MSG, NOTES, SUBMIT, setCustomModalStyles,
} from './constants';
import type Event from '#/types/Event';

interface Props extends React.PropsWithChildren {
  title: string
  isOpen?: boolean
  isDisabled?: boolean
  onClose: () => void
  onSubmit: () => void
  selectedEvents?: Event[]
  maxWidth?: number
  notesRef: React.MutableRefObject<HTMLInputElement>
}

const BulkEditModal: React.FC<Props> = ({
  title, isOpen, isDisabled, onClose, onSubmit, selectedEvents, maxWidth = 302, notesRef, children,
}) => {
  const numSelectedEvents = selectedEvents?.length;

  const confirmationMsg = (
    <>
      {CONFIRMATION_MSG}
      <NumEvents>{`${numSelectedEvents} event(s)`}</NumEvents>
    </>
  );

  return (
    <Modal
      appElement={document.body}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={setCustomModalStyles(maxWidth)}
    >
      <Header>
        <Title>{title}</Title>
        <Confirmation>{confirmationMsg}</Confirmation>
      </Header>
      <Main>
        <Content>
          {children}
        </Content>
        <NotesContainer>
          <Label htmlFor='notes'>{NOTES}</Label>
          <NotesInput disabled={isDisabled} id='notes' ref={notesRef} />
        </NotesContainer>
      </Main>
      <ButtonContainer>
        <Button
          kind='secondary'
          label={CANCEL}
          onClick={onClose}
          padding='0.75rem 1.5rem'
        />
        <Button
          Icon={<IconWrapper><Check /></IconWrapper>}
          isDisabled={isDisabled}
          label={SUBMIT}
          onClick={onSubmit}
          padding='0.75rem 1.5rem'
        />
      </ButtonContainer>
    </Modal>
  );
};

const Header = styled.div`
  border-bottom: 1px solid #dedede;
`;

const Title = styled.h3`
  ${({ theme }: { theme: Theme }): string => theme.header3};
  margin: 0;
  line-height: 1.5;
  font-size: 1.75rem;
`;

const Confirmation = styled.p`
  font-size: 0.95rem;
`;

const NumEvents = styled.span`
  font-weight: 600;
`;

const Main = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  margin: 0 -2rem;
  padding: 0 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0.75rem;
  gap: 0.7rem;
  padding: 1rem 0 0.5rem 0;
`;

const NotesContainer = styled.div`
  width: 100%;
  padding: 0.4rem 0 0.9rem 0;
  margin-top: 1rem;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
`;

const Label = styled.label`
  font-size: 0.85rem;
  font-weight: 550;
  line-height: 2;
`;

const NotesInput = styled.input`
  padding: 0.5rem;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  width: calc(100% - 1rem);
`;

const ButtonContainer = styled.div`
  padding-top: 0.8rem;
  display: flex;
  gap: 0.8rem;
  width: 100%;
  justify-content: flex-end;
`;

const Button = styled(SmallButton)`
  font-size: 1rem;
`;

const IconWrapper = styled.div`
  height: 1.1rem;
  width: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Label };
export default BulkEditModal;
