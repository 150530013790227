import {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import getPriceList from '#/api/getPricelist';
import PriceLevel from '#/types/PriceLevel';

interface UsePriceListHook {
  priceList: PriceLevel[];
  retry: () => void;
  showError: boolean;
  showLoader: boolean;
  showResults: boolean;
}

const useFetchPriceList = (autobrokerEventId: number): UsePriceListHook => {
  const [priceList, setPriceList] = useState<PriceLevel[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const retry = useCallback(async () => {
    setHasError(false);
    try {
      if (autobrokerEventId) {
        const result = await getPriceList(autobrokerEventId);
        const formatted = result.map((entry, i) => ({
          ...entry,
          faceValue: entry.price,
          id: `${i}. ${entry.name}`,
          override: entry?.override && {
            ...entry.override,
            isActive: true,
          },
        }));

        setPriceList(formatted);
      }
    } catch {
      setHasError(true);
    }
    setLoading(false);
  }, [autobrokerEventId]);

  useEffect(() => {
    retry();
  }, [retry]);

  const showResults = useMemo(() => !isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showLoader = useMemo(() => isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showError = useMemo(() => !isLoading && hasError, [
    hasError,
    isLoading,
  ]);

  return {
    priceList,
    retry,
    showError,
    showLoader,
    showResults,
  };
};

export { UsePriceListHook };
export default useFetchPriceList;
