import postIntegration from '#/api/postIntegration';
import { IntegrationConfig } from '../../types';
import formatApiError from '#/api/utils/formatApiError';

// This function navigates the two flows of the integration modal
// -- add an integration to an existing event (multistep is false)
// or add an integration to a new event. In the case of the latter,
// the user would have just created a new event we want to
// navigate them to the new event.

const createHandleSave = (
  closeModal: () => void,
  goToEventPage: () => void,
  integrationConfig: IntegrationConfig,
  multistep: boolean,
  resetIntegrationConfig: () => void,
  setError: (error: string) => void,
  retry?: () => void,
): (() => Promise<void>
  ) => {
  return async (): Promise<void> => {
    try {
      await postIntegration(integrationConfig);
      resetIntegrationConfig();
      if (retry)
        retry();
      closeModal();
      if (multistep)
        goToEventPage();
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  };
};

export default createHandleSave;
