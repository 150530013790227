import typography from './typography';
import shadows from './shadows';

// eslint-disable-next-line max-lines-per-function
const getTheme = (palette: Palette): Theme => {
  const action = {
    destroy: palette.red.base,
    favorite: palette.red.base,
    highlight: palette.brand.dark,
    money: palette.mint.base,
    neutral: palette.white.base,
    primary: palette.brand.base,
    secondary: palette.gray.dark,
    tertiary: palette.brand.light56,
    warning: palette.orange.base,
  };

  const background = {
    error: palette.salmon.light56,
    highlight: palette.brand.light08,
    info: palette.gray.light24,
    primary: palette.white.base,
    secondary: palette.gray.light08,
    selected: palette.brand.base,
    slider: palette.brand.light56,
    success: palette.mint.light24,
    teritary: palette.gray.light24,
    warning: palette.red.light24,
    critical: palette.salmon.base,
    newPrimary: palette.yellow.light08,
    newSecondary: palette.yellow.light24,
  };

  const border = {
    error: palette.red.light56,
    focus: palette.brand.light56,
    focusOutline: palette.brand.light08,
    light: palette.white.base,
    primary: palette.gray.light24,
    selected: palette.gray.base,
  };

  const text = {
    error: palette.red.base,
    highlight: palette.brand.base,
    light: palette.white.base,
    link: palette.brand.base,
    money: palette.mint.dark,
    onError: palette.red.dark,
    onSuccess: palette.mint.dark,
    onWarning: palette.orange.dark,
    primary: palette.gray.dark,
    secondary: palette.gray.base,
    selected: palette.gray.base,
    success: palette.mint.dark,
    tertiary: palette.gray.light56,
    warning: palette.orange.base,
  };

  const color = {
    action,
    background,
    border,
    brand: palette.brand.base,
    divider: palette.gray.base,
    text,
  };

  return {
    ...typography, color, palette, shadows,
  };
};

export default getTheme;
