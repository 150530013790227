import React from 'react';
import SelldownGraph from '../SelldownGraph';
import PriceRatioGraph from '../PriceRatioGraph';
import ListingsGraph from '../ListingsGraph';
import PriceDistributionGraph from '../PriceDistributionGraph';
import CurrentStateDataTable from '../CurrentStateDataTable';
import { Graphs } from './constants';
import { Data } from '../types';
import PriceDistributionGraphInfo, { PreviewData } from '#/types/Preview';
import { SelldownGraphInfo } from '#/types/Snapshot';
import { OverrideChangeLogEntry } from '#/types/Override';
import OverrideLogsTableAndDetail from '#/pages/ViewPage/components/OverrideLogs/OverrideLogsTableAndDetail';
import { User } from '#/types/User';
import { LogColumns } from '#/pages/ViewPage/components/OverrideLogs/OverrideLogsTable/utils/helpers';

const COLUMNS_TO_HIDE: LogColumns[] = [LogColumns.TYPE];

const renderGraph = (
  activeGraphTab: string,
  data: Data,
  distributionOverlay: PriceDistributionGraphInfo,
  error: string,
  listingsLoading: boolean,
  loading: boolean,
  overlayError: string,
  previewLoading: boolean,
  previewError: string,
  previewData: PreviewData,
  selldownOverlay: SelldownGraphInfo,
  overrideLogs: OverrideChangeLogEntry[],
  overrideError: string,
  overrideRetry: () => void,
  user: User,
): React.ReactElement => {
  const { listingsGraphInfo, selldownGraphInfo, priceRatioGraphInfo } = data;

  return (
    <>
      <PriceDistributionGraph
        distributionOverlay={distributionOverlay}
        listingMarketplaces={previewData.listingMarketplaces}
        listingsLoading={listingsLoading}
        overlayError={overlayError}
        previewError={previewError}
        previewLoading={previewLoading}
        priceDistributionGraphInfo={previewData.priceDistributionGraphInfo}
        priceDistributionListings={previewData.priceDistributionListings}
        showGraph={activeGraphTab === Graphs.PRICE_DISTRIBUTION}
      />

      <SelldownGraph
        error={error}
        loading={loading || previewLoading}
        overlayError={overlayError}
        selldownGraphInfo={selldownGraphInfo}
        selldownOverlay={selldownOverlay}
        selldownTargetInfo={previewData.selldownTargetInfo}
        showGraph={activeGraphTab === Graphs.SELLDOWN}
      />

      <PriceRatioGraph
        error={error}
        loading={loading}
        priceRatioGraphInfo={priceRatioGraphInfo}
        showGraph={activeGraphTab === Graphs.PRICE_RATIO}
      />

      <ListingsGraph
        error={error}
        listingsGraphInfo={listingsGraphInfo}
        loading={loading}
        showGraph={activeGraphTab === Graphs.LISTINGS}
      />
      <CurrentStateDataTable
        data={selldownGraphInfo?.currentStateData}
        loading={loading}
        showData={activeGraphTab === Graphs.CURRENT_STATE_DATA}
      />
      {activeGraphTab === Graphs.OVERRIDE_LOGS && overrideLogs && (
        <OverrideLogsTableAndDetail
          columnsToHide={COLUMNS_TO_HIDE}
          error={overrideError}
          isContainerHeight
          overrideLogs={overrideLogs}
          retry={overrideRetry}
          user={user}
        />
      )}
    </>
  );
};

export default renderGraph;
