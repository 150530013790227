import axios, {
  AxiosInstance, AxiosError, InternalAxiosRequestConfig,
} from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { stringify } from 'query-string';
import handleDate from './utils/handleDate';

const isJson = (headers: any): boolean => headers['content-type'] === 'application/json';
const handleError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);
const handleRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  return (
    {
      ...config,
      transformRequest: (data): any => (
        data
          ? JSON.stringify(snakecaseKeys(data, { deep: true }))
          : undefined
      ),
      transformResponse: (data, headers): any => {
        if (isJson(headers)) {
          return (
            handleDate(camelcaseKeys(JSON.parse(data), { deep: true }))
          );
        }
        return data;
      },
      paramsSerializer: {
        serialize: (params): string => (
          stringify(snakecaseKeys(params, { deep: true }))
        ),
      },
    }
  );
};

const createInstance = (): AxiosInstance => {
  const connection = axios.create({
    timeout: 300000,
  });

  connection.interceptors.request.use(handleRequest, handleError);

  return connection;
};

export default createInstance();
