import { SelldownGraphInfo } from '#/types/Snapshot';
import adjustDomainForOverlay from '../../utils/adjustDomainForOverlay';

const combineGraphInfo = (
  snapshot: SelldownGraphInfo,
  preview: SelldownGraphInfo,
): SelldownGraphInfo => {
  const graphData = {
    ...snapshot.graphData,
    ticketsTarget: preview.graphData.ticketsTarget,
  };
  const graphDataDaysX = {
    ...snapshot.graphDataDaysX,
    ticketsTarget: preview.graphDataDaysX.ticketsTarget,
  };
  const graphDomain = adjustDomainForOverlay(
    preview.graphData,
    snapshot.graphDomain,
    preview.graphDomain,
  );
  const graphDomainHistorical = adjustDomainForOverlay(
    preview.graphDataDaysX,
    snapshot.graphDomainHistorical,
    preview.graphDomainHistorical,
  );
  const currentStateData = snapshot.currentStateData;

  return {
    currentStateData,
    graphData,
    graphDataDaysX,
    graphDomain,
    graphDomainHistorical,
  };
};

export default combineGraphInfo;
