import React from 'react';
import SVG from 'react-inlinesvg';
import close from '#images/Close.svg';

interface Props {
  title?: string;
}

const Close: React.FC<Props> = ({ title = 'Close' }) => (
  <SVG id='close' src={close} title={title} />
);

export default Close;
