import React, { useEffect, useState } from 'react';
import { Settings } from '#/types/Override';
import useSettings from './useSettings';
import useSaveSettings from './useSaveSettings';
import Action from '../../types';

type Hook = (
  initialSettings: Settings,
  fetchPreview: () => Promise<void>,
  fetchSnapshots: () => Promise<void>
) => {
  canonicalSettings: Settings;
  resetSettings: () => void;
  saveError: string;
  saveInProgress: boolean;
  saveSettings: (notes?: string) => void;
  settings: Settings;
  updateSetting: React.Dispatch<Action>;
};

const useGraphControls: Hook = (
  initialSettings,
  fetchPreview,
  fetchSnapshots,
) => {
  const [canonicalSettings, setCanonicalSettings] = useState<Settings>(
    initialSettings,
  );

  useEffect(() => {
    setCanonicalSettings(initialSettings);
  }, [initialSettings]);
  const {
    resetSettings, settings, updateSetting,
  } = useSettings(
    canonicalSettings,
  );

  const { saveError, saveInProgress, saveSettings } = useSaveSettings(
    canonicalSettings,
    fetchPreview,
    fetchSnapshots,
    settings,
    setCanonicalSettings,
  );

  return {
    canonicalSettings,
    resetSettings,
    saveError,
    saveInProgress,
    saveSettings,
    settings,
    updateSetting,
  };
};

export default useGraphControls;
