import client from './_client';

type APICall = (eventId: number, file: File, notes?: string) => Promise<number>;

const postPriceConstraints: APICall = async (eventId, file, notes) => {
  const formData = new FormData();

  formData.append('csv_file', file);
  if (notes)
    formData.append('notes', notes);
  const response = await client.post(`/proxy/price_constraints/${eventId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.status;
};

export default postPriceConstraints;
