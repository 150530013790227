import client from './_client';

interface APICall {
  status: number;
}

const deleteSinkConfig = async (id: number, notes?: string): Promise<APICall> => {
  const response = await client.delete(`/proxy/sink_event/${id}`, { data: { notes } });

  return { status: response.status };
};

export default deleteSinkConfig;
