import { useEffect, useState, useCallback } from 'react';
import {
  Rollup, RollupGroupVar, RollupFilters, Perspective, InventorySourceType, RollupPreview,
} from '#/types/ReportingRollup';
import getReportingRollup from '#/api/getReportingRollup';
import getReportingRollupPreview from '#/api/getReportingRollupPreview';

interface UseFetchReportingRollupHook {
  isLoading: boolean;
  hasError: boolean;
  rollup: Rollup[];
  rollupPreview: RollupPreview[];
  fetchRollup: () => Promise<void>;
}

interface RollupProps {
  hasValidInputs?: boolean;
  stakeholder?: string;
  eventId?: string[];
  autobrokerEventId?: string[];
  autobrokerEventGroup?: string;
  fromEventDate?: string;
  toEventDate?: string;
  fromDate?: string;
  fromDatetime?: string;
  toDate?: string;
  toDatetime?: string;
  group?: RollupGroupVar[];
  deal?: string;
  dealTerm?: string;
  hasDealTerm?: boolean;
  isUnmanifested?: boolean;
  perspective?: Perspective;
  inventorySourceType?: InventorySourceType;
  timeInterval?: string;
  logInterval?: number;
  includeExpectedValue?: boolean;
  includePortfolioValue?: boolean;
  bypassCache?: boolean;
  isPreview?: boolean;
}

interface GetRollupProps {
  filters?: RollupFilters;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setHasError: (value: React.SetStateAction<boolean>) => void;
  setRollup: React.Dispatch<React.SetStateAction<Rollup[]>>;
  setRollupPreview?: React.Dispatch<React.SetStateAction<RollupPreview[]>>;
  isPreview?: boolean;
}

const getRollup = async ({
  filters = {}, setIsLoading, setHasError, setRollup, setRollupPreview, isPreview,
}: GetRollupProps): Promise<void> => {
  setIsLoading(true);
  setHasError(false);
  try {
    let result;

    if (isPreview) {
      result = await getReportingRollupPreview(filters);
      setRollupPreview(result);
    } else {
      result = await getReportingRollup(filters);
      setRollup(result);
    }

    if (!result)
      throw new Error('There was an error processing the request...');
  } catch (err) {
    setHasError(true);
  }
  setIsLoading(false);
};

const useFetchReportingRollup = (
  { hasValidInputs = true, ...props }: RollupProps,
): UseFetchReportingRollupHook => {
  const [isLoading, setIsLoading] = useState(hasValidInputs);
  const [hasError, setHasError] = useState(false);
  const [rollup, setRollup] = useState<Rollup[]>([]);
  const [rollupPreview, setRollupPreview] = useState<RollupPreview[]>([]);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const stringifiedDeps = JSON.stringify({ hasValidInputs, ...props });

  const fetchRollup = useCallback(() => {
    const parsedDeps = JSON.parse(stringifiedDeps) as RollupProps;
    const { group, eventId, ...rollupFilters } = parsedDeps;

    const filters: RollupFilters = {
      ...rollupFilters,
      seatgeekEventId: parsedDeps?.eventId
        ? [parsedDeps.eventId].flat().map((v) => Number(v))
        : undefined,
      autobrokerEventId: (
        parsedDeps?.autobrokerEventId
          ? [parsedDeps.autobrokerEventId].flat().map((v) => Number(v))
          : undefined
      ),
      groups: parsedDeps.group ?? [],
      timeZone,
    };

    if (hasValidInputs) {
      return (
        getRollup({
          filters,
          setIsLoading,
          setHasError,
          setRollup,
          setRollupPreview,
          isPreview: parsedDeps?.isPreview,
        }));
    }

    return undefined;
  }, [
    timeZone,
    stringifiedDeps,
    hasValidInputs,
  ]);

  useEffect(() => {
    if (hasValidInputs)
      fetchRollup();
  }, [fetchRollup, hasValidInputs]);

  return {
    isLoading,
    hasError,
    rollup,
    rollupPreview,
    fetchRollup,
  };
};

export default useFetchReportingRollup;
export type { UseFetchReportingRollupHook, RollupProps };
