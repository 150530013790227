import React, {
  useCallback, useReducer, useEffect,
} from 'react';
import { Settings } from '#/types/Override';
import RESET from './constants';
import Action from '../../types';

type Reducer = (previousState: Settings, action: Action) => Settings;

const reducer: Reducer = (previousState, action) => {
  const { type, value } = action;

  if (type === RESET)
    return value as Settings;

  const state = { ...previousState };
  const parsed = parseFloat(value as string);

  state[type] = Number.isNaN(parsed) ? null : parsed;
  return state;
};

type Hook = (
  canonicalSettings: Settings
) => {
  resetSettings: () => void;
  settings: Settings;
  updateSetting: React.Dispatch<Action>;
};

const useGraphControls: Hook = (canonicalSettings) => {
  const [settings, updateSetting] = useReducer<Reducer>(
    reducer,
    canonicalSettings,
  );

  const resetSettings = useCallback(() => {
    updateSetting({ type: RESET, value: canonicalSettings });
  }, [canonicalSettings]);

  useEffect(() => {
    resetSettings();
  }, [resetSettings]);

  return {
    resetSettings,
    settings,
    updateSetting,
  };
};

export default useGraphControls;
