import React from 'react';
import styled from 'styled-components';

const SIZE = 40 / 16;

const Container = styled.div`
  align-items: center;
  background-color: #d2d2d2;
  background-position: center;
  background-size: cover;
  ${({ src }: { src: string}): string => src ? `background-image: url(${src});` : ''}
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex: 0 0 ${SIZE}rem;
  font-size: 1rem;
  font-weight: bold;
  height: ${SIZE}rem;
  justify-content: center;
  width: ${SIZE}rem;
  cursor: inherit;
`;

interface AvatarProps {
  profileImageUrl?: string;
  fullName?: string;
  initials?: string;
  // className is needed in order to pass styles through via styled(UserAvatar)``
  // when using UserAvatar as a wrapped styled-component
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  profileImageUrl = '',
  fullName = '',
  initials = '',
  className = '',
}) => (
  <Container
    className={className}
    src={profileImageUrl}
    title={fullName || ''}
  >
    {profileImageUrl ? '' : initials || ''}
  </Container>
);

export { AvatarProps };
export default Avatar;
