import React from 'react';
import ClientReportingErrorPage from './ErrorPage';
import ClientReportingPending from './ClientReportingPending';
import type { Option } from '../useFetchStakeholders';
import renderDashboard from './renderDashboard';
import { User } from '#/types/User';
import { useParams } from 'react-router';

interface Props {
  user: User;
  stakeholderOptions?: Option<string>[];
  stakeholderError?: string;
}

const ClientReportingDashboard: React.FC<Props> = ({
  user, stakeholderOptions, stakeholderError,
}) => {
  const { slug } = useParams();
  const hasStakeholders = stakeholderOptions?.length > 0;
  const stakeholder = stakeholderOptions?.find((o) => o.value === slug);

  if (stakeholderError)
    return <ClientReportingErrorPage user={user} />;

  if (hasStakeholders)
    return renderDashboard(user, stakeholder, '404');
  return <ClientReportingPending user={user} />;
};

export default ClientReportingDashboard;
