import { useEffect } from 'react';

const useSetupModal = (close: () => void): void => {
  useEffect(() => {
    // close the modal when the viewport changes size
    window.addEventListener('resize', close);

    return (): void => {
      window.removeEventListener('resize', close);
    };
  }, [close]);
};

export default useSetupModal;
