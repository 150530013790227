import { DateTime } from 'luxon';

const formatDateForState = (date: string): DateTime => {
  if (!date)
    return null;

  return DateTime.fromISO(date, { zone: 'utc' });
};

export default formatDateForState;
