/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import Select from 'react-select';
import {
  InnerWrapper,
  Label,
  HorizontalWrapper,
  modalMenuProps,
} from '#/shared/modalComponents';
import {
  PostIngestion,
  Uploader,
} from '#/types/Ingestion';
import { Deal } from '#/types/Deal';
import useFetchStakeholders, { type Option as StakeholderOption } from '#/pages/useFetchStakeholders';
import useFetchDeals from '#/pages/useFetchDeals';
import Separator from './Separator';
import type { GenericOption } from '#/types/GenericOption';

const STAKEHOLDER = 'Stakeholder';
const DEAL = 'Deal';
const DEAL_TERM = 'Deal Term';
const UPLOADER = 'Uploader';

interface UploadListingModalProps {
  ingestion: PostIngestion;
  setIngestion: React.Dispatch<React.SetStateAction<PostIngestion>>;
  setEventDetail: React.Dispatch<React.SetStateAction<string | null>>;
}

const StakeholderForm: React.FC<UploadListingModalProps> = ({
  ingestion, setIngestion, setEventDetail,
}) => {
  const { stakeholderLabels } = useFetchStakeholders();
  const { deals } = useFetchDeals();
  const [selectedDeal, setSelectedDeal] = useState<Deal>(null);

  const uploaderOptions = Object.values(Uploader).map((u) => ({ value: u, label: u }));

  const dealOptions = useMemo(() => (deals
    .filter((d) => d.stakeholderId === ingestion.stakeholder_id)
    .map((d) => ({ value: d.id, label: d.displayName }))
  ), [deals, ingestion.stakeholder_id]);

  const dealTermOptions = useMemo(() => {
    const dealTerms = selectedDeal?.dealTerms || [];

    return dealTerms.map((dt) => ({ value: dt.id, label: `(${dt.id}) ${dt.name}` }));
  }, [selectedDeal]);

  const onChangeUploader = useCallback((event: GenericOption<Uploader, Uploader>) => {
    const uploader = event.value;

    setEventDetail(null);
    setIngestion((i) => ({ ...i, uploader, uploader_event_id: null }));
  }, [setEventDetail, setIngestion]);

  const onChangeStakeholder = useCallback((event: StakeholderOption<string>) => {
    const stakeholder_id = Number(event?.id) || null;

    setIngestion((i) => ({ ...i, stakeholder_id, deal_term_id: null }));
  }, [setIngestion]);

  const onChangeDeal = useCallback((event: GenericOption<string, number>) => {
    const dealId = Number(event?.value) || null;

    setSelectedDeal(deals.find((d) => d.id === dealId));
  }, [deals, setSelectedDeal]);

  const onChangeDealTerm = useCallback((event: GenericOption<string, number>) => {
    const deal_term_id = Number(event?.value) || null;

    setIngestion((i) => ({ ...i, deal_term_id }));
  }, [setIngestion]);

  useEffect(() => {
    if (ingestion.deal_term_id) {
      const deal = deals
        .filter((d) => d.dealTerms.some((dt) => dt.id === ingestion.deal_term_id))[0];

      if (deal)
        setSelectedDeal(deal);
    }
  }, [ingestion, deals, setSelectedDeal]);

  return (
    <>
      <InnerWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{STAKEHOLDER}</Label>
            <Select
              onChange={onChangeStakeholder}
              options={stakeholderLabels}
              value={
                stakeholderLabels.find((l) => l.id === ingestion.stakeholder_id)
              }
              {...modalMenuProps}
            />
          </Separator>
        </HorizontalWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{DEAL}</Label>
            <Select
              onChange={onChangeDeal}
              options={dealOptions}
              value={
                dealOptions.find((d) => d.value && d.value === selectedDeal?.id) || null
              }
              {...modalMenuProps}
            />
          </Separator>
          <Separator>
            <Label>{DEAL_TERM}</Label>
            <Select
              isClearable
              onChange={onChangeDealTerm}
              options={dealTermOptions}
              value={
                dealTermOptions
                  .find((dt) => dt.value === ingestion.deal_term_id) || null
              }
              {...modalMenuProps}
            />
          </Separator>
          <Separator>
            <Label>{UPLOADER}</Label>
            <Select
              defaultValue={uploaderOptions.find((u) => u.value === ingestion.uploader)}
              onChange={onChangeUploader}
              options={uploaderOptions}
              {...modalMenuProps}
            />
          </Separator>
        </HorizontalWrapper>
      </InnerWrapper>
    </>
  );
};

export default StakeholderForm;
