import React from 'react';
import styled from 'styled-components';
import logo from '#images/SeatGeekIQ.svg';

const Logo: React.FC = () => (
  <Image alt='SeatGeekIQ' src={logo} />
);

const LogoMark = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2rem;
  margin-top: 0.3rem;
`;

const Image = styled.img`
  height: 1.25rem;
`;

export { LogoMark };
export default Logo;
