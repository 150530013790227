import React from 'react';
import Select, { CSSObjectWithLabel, StylesConfig } from 'react-select';
import styled from 'styled-components';
import { EditEventsOptions } from '#/types/Event';

interface Option<T> {
  value: T;
  label: T;
}

interface EditEventSelectProps {
  onChange: (value: Option<EditEventsOptions>) => void;
  options: Option<EditEventsOptions>[];
  placeholder?: string;
  isDisabled?: boolean;
  value?: any;
}

const EditEventSelect: React.FC<EditEventSelectProps> = ({
  onChange,
  options,
  placeholder,
  isDisabled,
  value,
}) => {
  return (
    <SelectWrapper
      data-testid='edit-event-select-div'
      isDisabled={isDisabled}
    >
      <Select
        isDisabled={isDisabled}
        menuPlacement="auto"
        menuPosition="fixed"
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        styles={CUSTOM_STYLES}
        value={value}
      />
    </SelectWrapper>
  );
};

const SelectWrapper = styled.div< {isDisabled?: boolean} >`
  border: ${(props) : string => props.isDisabled ? '1px solid #DEDDDB' : '1px solid #525252'};
  border-radius: 5px;
  opacity: ${(props) : number => props.isDisabled ? 0.6 : 1};
  pointer-events: ${ (props): string => props.isDisabled ? 'none' : 'auto' };
  width: 11rem;
`;

const CUSTOM_STYLES: Partial<StylesConfig<Option<any>, boolean>> = {
  control: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...baseStyles,
    backgroundColor: 'white',
  }),
};

export type { Option };
export default EditEventSelect;
