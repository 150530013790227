import React, { useMemo } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import {
  NUMBER_COLS,
  getTotalColumns,
  totalStyles,
} from './helpers';
import { EventTxSummary, EventTotals, EventTxSummaryCols } from '../../types';

interface TableTotalsProps {
  data?: EventTxSummary[];
  isNarrow?: boolean;
  excludedColumns?: EventTxSummaryCols[];
}

const TOTALS = 'TOTALS';

const TableTotals: React.FC<TableTotalsProps> = ({ data, isNarrow, excludedColumns = [] }) => {
  const totals = useMemo(() => {
    if (data && data.length > 1) {
      const sums: EventTotals = {
        event: <TotalsLabel>{TOTALS}</TotalsLabel>,
        events: data.length,
      };

      NUMBER_COLS.forEach((c) => {
        sums[(c as keyof typeof sums)] = data.map((e) => e[c] as number)
          .reduce((a, b) => a + b);
      });
      return [sums];
    }
    return undefined;
  }, [data]);

  if (!totals)
    return null;

  return (
    <DataTable
      columns={getTotalColumns(isNarrow, excludedColumns)}
      customStyles={totalStyles}
      data={totals}
      noTableHead
    />
  );
};

const TotalsLabel = styled.div`
  font-size: 11.5px;
  font-weight: 600;
  color: #000;
  letter-spacing: 1.5px;
  min-width: 88px;
`;

export default TableTotals;
