import { GraphPlot } from '#/types/Snapshot';
import { FormattedPricingChartDatum } from '../../types';
import { isDefined } from '../../utils/dataFormatting';
import { FilterField, Options } from '../types';

/*
For listings, in addition to the x (log price ratio) and y (log expected value)
datapoints, we have attached a market name, listing quantity and seller ID
to each graph data object. "mapOptions" takes the array of listings graph data and a
field for filtering, and returns an array containing a set of unique options
for that field, sorted and formatted for the Select dropdown component.

Example:

  const listings = [
    {inventorySourceType: primary, listingQuantity: 2, sellerID: undefined, x: 4.589, y: .95},
    {inventorySourceType: primary, listingQuantity: 4, sellerID: 123, x: 4.5, y: .95},
  ]
  mapOptions(listings, marketplaceTransactions, inventorySourceType)
  '--> [{label: primary, value: primary}]
  mapOptions(listings, marketplaceTransactions, listingQuantity)
  '--> [{label: 2, value: 2}, {label: 4, value: 4}]
  mapOptions(listings, marketplaceTransactions, sellerID)
  '--> [{label: 123, value: 123}]
*/

const mapOptions = (
  listings: GraphPlot[],
  marketplaceTransactions: FormattedPricingChartDatum[],
  filterField: FilterField,
): Options => {
  const uniqueOptionsMap = new Map();
  const isQuantity = filterField === FilterField.QUANTITY;
  const isSeller = filterField === FilterField.SELLER_ID;

  const definedListings = listings.filter((l) => isDefined(l.x) && isDefined(l.y));
  const listingsAndTransactions = [...definedListings, ...(marketplaceTransactions ?? [])];

  listingsAndTransactions.forEach((record) => {
    const option = record[filterField] as string | number;

    if (option && !uniqueOptionsMap.get(option)) {
      uniqueOptionsMap.set(option, 1);
    } else if (option) {
      const prevVal = uniqueOptionsMap.get(option);

      uniqueOptionsMap.set(option, prevVal + 1);
    }
  });

  const uniqueOptions = Object.fromEntries(uniqueOptionsMap);

  if (isSeller) {
    const sellerIdCounts = Object.keys(uniqueOptions)
      .sort((a, b) => uniqueOptions[b] - uniqueOptions[a])
      .map((key) => ({
        label: `${key} (${uniqueOptions[key].toLocaleString()})`,
        value: Number(key),
      })) as Options;

    return sellerIdCounts;
  }

  const uniqueOptionsArray = Object.keys(uniqueOptions)
    .map((v) => isQuantity ? Number(v) : v);

  if (isQuantity)
    uniqueOptionsArray.sort((a: number, b: number) => a - b);
  else
    uniqueOptionsArray.sort();

  return uniqueOptionsArray.map((value) => ({
    label: value.toString(),
    value,
  })) as Options;
};

export default mapOptions;
