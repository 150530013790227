import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  NarrowWrapper, Element, Name, ScrollBox,
} from '#/shared/Card';
import { User } from '#/types/User';
import { InputListingError } from '#/types/Ingestion';
import ErrorStatusCard from './ErrorStatusCard';
import { InputListing } from '#/shared/ListingOverrideModal';
import { ErrorStatusProps } from '#/shared/ErrorStatusModal';
import { ErrorType } from '#/types/Errors';
import HeaderButtons from './HeaderButtons';

const AUTOBROKER_EVENT = 'Autobroker Event';

interface CardProps {
  user: User;
  error: InputListingError;
  setErrorStatusModal: (props: ErrorStatusProps) => void;
  setOverrideListingModal: (listings: InputListing[]) => void;
}

const InputListingErrorCard: React.FC<CardProps> = ({
  user,
  error,
  setErrorStatusModal,
  setOverrideListingModal,
}) => {
  const openErrorStatusModal = useCallback(() => {
    if (error !== null) {
      setErrorStatusModal({
        title: 'Annotate Input Listing Error',
        description: `${error.error_type}\n${error.error_reason}`,
        isOpen: true,
        existing: error.error_status,
        reference: {
          id: error.id,
          type: ErrorType.INPUT_INVENTORY_ERRORS,
        },
      });
    }
  }, [error, setErrorStatusModal]);

  const openEditModal = useCallback(() => {
    if (error !== null)
      setOverrideListingModal([error]);
  }, [error, setOverrideListingModal]);

  const disabled = user.isReadOnly;

  return (
    <>
      <NarrowWrapper>
        <Element>
          <HeaderButtons
            disabled={disabled}
            openEditModal={openEditModal}
            openNotesModal={openErrorStatusModal}
          >
            <Name>{error.error_type.split('_').join(' ')}</Name>
          </HeaderButtons>
        </Element>
        <Element>
          <Name>
            {AUTOBROKER_EVENT}
            <Link
              href={`/view/${error.autobroker_event_id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {error.autobroker_event_id}
            </Link>
          </Name>
        </Element>
        <ScrollBox>
          <Name>
            {error.error_reason}
          </Name>
        </ScrollBox>
        <Element>
          <Name>{`Created ${error.created_at.toRelative()}`}</Name>
        </Element>
      </NarrowWrapper>
      {error.error_status && (
        <ErrorStatusCard key={error.error_status.id} status={error.error_status} />
      )}
    </>
  );
};

const Link = styled.a`
  padding-left: 0.35rem
`;

export default InputListingErrorCard;
