/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, ChangeEvent } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import styled from 'styled-components';
import { UpdateSalesPeriod, SalesPeriodType } from '#/types/Event';
import { modalMenuProps } from '#/shared/modalComponents';
import {
  Button, CancelledImg, ErrorImg, VipImg,
} from '#/shared/multiSelectDetail';
import formatDateForState from '#/shared/formatDateForState';

const TYPE = 'Type';
const STARTS_AT = 'Starts At';
const ENDS_AT = 'Ends At';
const DEMAND_PRIOR = 'Demand Prior';
const ACCESS_CODES = 'Access Codes';
const selectStyleSmall = { width: '7rem', height: '1.5rem' };
const selectStyleLarge = { width: '28rem', height: '1.5rem' };

const SALES_PERIOD_TYPE_OPTIONS = [
  { value: SalesPeriodType.PRESALE, label: SalesPeriodType.PRESALE },
];

interface SalesPeriodFormProps {
  period: UpdateSalesPeriod;
  setPeriod: (key: number, f: (s: UpdateSalesPeriod) => UpdateSalesPeriod) => void;
  removePeriod: (key: number) => void;
  includeHeader: boolean;
}

const SalesPeriodForm: React.FC<SalesPeriodFormProps> = ({
  period,
  setPeriod,
  removePeriod,
  includeHeader,
}) => {
  const accessCodeOptions = period
    ?.accessCodes
    ?.map((code) => ({ value: code, label: code }))
    || [];

  const updatePeriod = useCallback((f: (s: UpdateSalesPeriod) => UpdateSalesPeriod) => {
    setPeriod(period.key, f);
  }, [setPeriod, period.key]);

  const deletePeriod = useCallback(() => {
    removePeriod(period.key);
  }, [removePeriod, period.key]);

  const setError = useCallback((error?: string) => {
    updatePeriod((p) => ({ ...p, error }));
  }, [updatePeriod]);

  // validate the sales period request
  useEffect(() => {
    if (
      !period.id && (!period.type || !period.startsAt || !period.endsAt)
    )
      setError('Type, Starts at, and Ends at are required for a new sales period');
    else if (
      period.startsAt
      && period.endsAt
      && (
        formatDateForState(period.startsAt) >= formatDateForState(period.endsAt)
      )
    )
      setError('Starts at must be before Ends at');
    else
      setError(undefined);
  }, [period.id, period.type, period.startsAt, period.endsAt, setError]);

  const onChangeStartsAt = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const startsAt = event?.target?.value || null;

    updatePeriod((p) => ({ ...p, startsAt }));
  }, [updatePeriod]);

  const onChangeEndsAt = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const endsAt = event?.target?.value || null;

    updatePeriod((p) => ({ ...p, endsAt }));
  }, [updatePeriod]);

  const onChangeDemandPrior = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const demandPriorStr = event?.target?.value || null;
    const demandPrior = demandPriorStr ? Number(demandPriorStr) : undefined;

    updatePeriod((p) => ({
      ...p,
      demandPrior,
      clearDemandPrior: (p.id && !demandPriorStr) ? true : undefined,
    }));
  }, [updatePeriod]);

  const onChangeAccessCodes = useCallback((values: any) => {
    const accessCodes = values ? values.map((v: any) => v.value) : [];

    updatePeriod((p) => ({ ...p, accessCodes }));
  }, [updatePeriod]);

  return (
    <>
      <HorizonalWrapper>
        <InnerWrapper>
          {includeHeader && <Label>&nbsp;</Label>}
          <Container>
            {period.error ? <ErrorImg title={period.error} /> : <VipImg />}
          </Container>
        </InnerWrapper>
        <InnerWrapper>
          {includeHeader && <Label>{TYPE}</Label>}
          <div style={selectStyleSmall}>
            <Select
              defaultValue={SALES_PERIOD_TYPE_OPTIONS.find((s) => s.value === period.type)}
              isDisabled
              options={SALES_PERIOD_TYPE_OPTIONS}
              {...modalMenuProps}
            />
          </div>
        </InnerWrapper>
        <InnerWrapper>
          {includeHeader && <Label>{STARTS_AT}</Label>}
          <MediumInput
            defaultValue={period.startsAt}
            onChange={onChangeStartsAt}
            type="datetime-local"
          />
        </InnerWrapper>
        <InnerWrapper>
          {includeHeader && <Label>{ENDS_AT}</Label>}
          <MediumInput
            defaultValue={period.endsAt}
            onChange={onChangeEndsAt}
            type="datetime-local"
          />
        </InnerWrapper>
        <InnerWrapper>
          {includeHeader && <Label>{DEMAND_PRIOR}</Label>}
          <SmallInput
            defaultValue={period.demandPrior}
            onChange={onChangeDemandPrior}
            type="number"
          />
        </InnerWrapper>
        <InnerWrapper>
          {includeHeader && <Label>{ACCESS_CODES}</Label>}
          <div style={selectStyleLarge}>
            <Creatable
              defaultValue={accessCodeOptions}
              isMulti
              onChange={onChangeAccessCodes}
              options={accessCodeOptions}
              placeholder="Enter a code..."
            />
          </div>
        </InnerWrapper>
        <InnerWrapper>
          {includeHeader && <Label>&nbsp;</Label>}
          <Button onClick={deletePeriod} title="remove sales period">
            <CancelledImg />
          </Button>
        </InnerWrapper>
      </HorizonalWrapper>
    </>
  );
};

const Container = styled.div`
  width: 1.5rem;
`;

const Label = styled.div`
  align-content: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorizonalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

const Input = styled.input`
  ${({ theme }: { theme: Theme }): string => theme.text4};
  padding: 4px;
  border: 1px solid
    ${({ theme }: { theme: Theme }): string => theme.color.border.primary};
  border-radius: 6px;
  transition: all 0.2s ease;
  &:focus {
    outline: none;
    outline-offset: none;
    border-color: ${({ theme }: { theme: Theme }): string => theme.color.border.selected};
  }
`;

const SmallInput = styled(Input as any)`
  max-width: 6rem;
  height: 1.8rem;
`;

const MediumInput = styled(Input as any)`
  max-width: 11rem;
  height: 1.8rem;
`;

export default SalesPeriodForm;
