import React, {
  useMemo, useState, useCallback, useEffect, useContext,
} from 'react';
import styled from 'styled-components';
import ReportingContext from '#/pages/clientReporting/ReportingContext';
import type { CumulativeMmt } from '#/types/ReportingRollup';
import { SectionTitleContainer, SectionTitle } from '#/shared/clientReporting/typography';
import createSalesTrendData, { SALES_METRICS, checkIsCurrency } from '#/shared/reporting/accumulationUtils';
import { formatEventLabel } from '../../utils';
import useFetchReportingRollup from '#/pages/useFetchReportingRollup';
import DataWell from './DataWell';
import { EXTRA_NARROW_BREAKPOINT } from '#/shared/clientReporting/utils';
import RollupErrorComponent from '../RollupErrorComponent';
import { SparklineDatum } from '../../types';

const TITLE = 'Sales Trends';

const METRICS_TO_INCLUDE = [SALES_METRICS.ticketsSold, SALES_METRICS.revenue, SALES_METRICS.take];
const METRICS = Object.values(SALES_METRICS).filter((m) => METRICS_TO_INCLUDE.includes(m));

const SummaryDataWells: React.FC = () => {
  const {
    stakeholder,
    setHasPendingData,
    isExtraNarrow,
    queryParams,
    reportingSelection,
  } = useContext(ReportingContext);

  const {
    rollup, isLoading, hasError, fetchRollup,
  } = useFetchReportingRollup({
    stakeholder: stakeholder?.value,
    group: ['day'],
    ...queryParams,
  });

  const salesTrendData = useMemo(() => (
    rollup ? createSalesTrendData(rollup) : undefined
  ), [rollup]);

  useEffect(() => {
    setHasPendingData(isLoading);
  }, [isLoading, setHasPendingData]);

  const shouldLoad = useMemo(() => (
    isLoading && (
      rollup?.length === 0 || salesTrendData?.length === 0 || !reportingSelection?.selectedEvent
    )
  ), [isLoading, salesTrendData, reportingSelection, rollup]);

  const [focusedDate, setFocusedDate] = useState<undefined | Date>();
  const onDatumFocus = useCallback((d: SparklineDatum[]) => (
    setFocusedDate(d[0].transactionDate)
  ), []);
  const onDatumUnfocus = useCallback(() => setFocusedDate(undefined), []);

  return (
    <Container>
      <SectionTitleContainer>
        <SectionTitle>
          {TITLE}
        </SectionTitle>
        {reportingSelection?.selectedEvent && (
          <Event>
            {formatEventLabel(reportingSelection.selectedEvent)}
          </Event>
        )}
      </SectionTitleContainer>
      <DataWellsContainer>
        {hasError ? <RollupErrorComponent retry={fetchRollup} /> : (
          METRICS.map((measure) => (
            <DataWell
              data={salesTrendData}
              focusedDate={focusedDate}
              isCurrency={checkIsCurrency(measure)}
              isExtraNarrow={isExtraNarrow}
              key={measure}
              loading={shouldLoad}
              measure={measure as CumulativeMmt}
              onDatumFocus={onDatumFocus}
              onDatumUnfocus={onDatumUnfocus}
            />
          ))
        )}
      </DataWellsContainer>
    </Container>
  );
};

const DataWellsContainer = styled.div`
  display: flex;
  padding: 4px;
  @media (max-width: ${EXTRA_NARROW_BREAKPOINT}px) {
    padding: 4px 0;
  };
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Container = styled.div`
  margin: 12px 0 0 12px;
  @media (max-width: ${EXTRA_NARROW_BREAKPOINT}px) {
    margin-left: 0;
  };
`;

const Event = styled.div`
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
  border-left: 1px solid #ccc;
  @media (max-width: ${EXTRA_NARROW_BREAKPOINT}px) {
    font-size: 14px;
    line-height: 1.3;
  };
`;

export default SummaryDataWells;
