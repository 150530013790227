import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Event from '#/types/Event';

interface CardProps {
  event: Event;
}

const LISTINGS = 'Listings';
const TICKETS = 'Listed Tickets';

const InventoryCard: React.FC<CardProps> = ({ event }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{LISTINGS}</Name>
        <Value>{event.summary.listingCt}</Value>
      </Element>
      <Element>
        <Name>{TICKETS}</Name>
        <Value>{event.summary.listingTicketCt}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default InventoryCard;
