import styled from 'styled-components';
import palette from '#/theme/palettes/main';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: none;
`;

const InputWrapper = styled.div`
  display: flex;
  margin: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  input {
    height: 1.6rem;
  }
`;

const Label = styled.label`
  min-width: 40%;
  padding-right: 4px;
`;

const Input = styled.input`
  width: 15rem;
  border: 1px solid ${palette.silver.dark};
  padding: 4px;
  font-size: 14px;
`;

export {
  FormContainer, InputWrapper, Label, Input,
};
