import React from 'react';
import { NarrowWrapper, Element, Name } from '#/shared/Card';
import ButtonSmall from '#/shared/Button';

const SETTINGS = 'Settings';
const UPDATE = 'Update';

interface SettingsCardProps {
  openModal: () => void;
}

const SettingsCard: React.FC<SettingsCardProps> = ({ openModal }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{SETTINGS}</Name>
      </Element>
      <Element>
        <ButtonSmall onClick={openModal}>{UPDATE}</ButtonSmall>
      </Element>
    </NarrowWrapper>
  );
};

export default SettingsCard;
