import React, {
  useContext, useEffect, useMemo,
} from 'react';
import styled from 'styled-components';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import TransactionsTable from '#/pages/SalesPage/TransactionsTable';
import useFetchTransactions from '#/pages/useFetchTransactions';
import { StakeholderLogos } from '#/pages/useFetchStakeholders';
import { User } from '#/types/User';
import Loader from '#/shared/Loader';
import palette from '#/theme/palettes/main';
import Filters from './Filters';

const SALES = 'Sales';

interface SummaryProps {
  user: User;
  error: boolean;
  loading: boolean;
  stakeholderLogos: StakeholderLogos;
}

const Sales: React.FC<SummaryProps> = ({ error, loading, stakeholderLogos }) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const eventId = useMemo(() => activeEvent?.id, [activeEvent]);

  const transactionsHook = useFetchTransactions({ autobroker_event_id: eventId });
  const { filters, setFilters, showResults } = transactionsHook;

  useEffect(() => {
    const updateEventId = (id: number): void => {
      setFilters({
        ...filters,
        autobroker_event_id: id,
      });
    };

    if (eventId)
      updateEventId(eventId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const showSalesResults = useMemo(() => {
    return eventId && !error && !loading && showResults;
  }, [eventId, error, loading, showResults]);

  return (
    <div id="sales">
      <Wrapper>
        <FilterWrapper>
          <Filters
            filters={filters}
            setFilters={setFilters}
          />
        </FilterWrapper>
        <Section>
          <Title>{SALES}</Title>
          {showSalesResults && (
            <TransactionsTable
              stakeholderLogos={stakeholderLogos}
              transactionsHook={transactionsHook}
            />
          )}
        </Section>
        {!showSalesResults && (
          <Section>
            <Loader hexColor={palette.brand.base} />
          </Section>
        )}
      </Wrapper>
    </div>
  );
};

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const Wrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
`;

const FilterWrapper = styled(Section as any)`
  margin-top: 3.75rem;
`;

export default Sales;
