import { DealTermRevenueShare } from '#/types/Deal';

function getRevenueShares(
  revenueShares?: DealTermRevenueShare[],
  defaultRevenueShares: DealTermRevenueShare[] = [],
): DealTermRevenueShare[] {
  return revenueShares ?? [...defaultRevenueShares];
}

export default getRevenueShares;
