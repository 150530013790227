import React from 'react';
import {
  EventErrorType, FormattedEventError, EventErrorName, ErrorValue,
} from '#/types/Errors';
import { EventLabel, ValueLabel } from '..';

enum ErrorColumns {
  ID = 'autobrokerEventId',
  EVENT_DATE = 'eventStartsAt',
  EVENT_DATE_DISPLAY = 'eventStartsAtDisplay',
  EVENT = 'event',
  LEVEL = 'level',
  STATUS = 'status',
  TYPE = 'type',
  VALUE = 'value',
  STATUS_UPDATED = 'statusUpdated',
}

type TableCell = ((row: FormattedEventError) => React.ReactNode) | undefined;

const formatErrorType = (error: EventErrorType): string => error.replace('_error', '').replace(/_/g, ' ');
const formatColName = (column: string): string => (
  column.split(/(?=[A-Z])/).map((c) => `${c[0].toUpperCase()}${c.slice(1)}`).join(' ')
);

const formatTableCell = (key: string): TableCell => {
  if (key === ErrorColumns.EVENT_DATE) {
    return (row: FormattedEventError): string => (
      row.eventStartsAtDisplay
    );
  }
  if (key === ErrorColumns.TYPE)
    return (row: FormattedEventError): string => formatErrorType(row[key]);

  if (key === ErrorColumns.STATUS_UPDATED) {
    return (row: FormattedEventError): string => (
      row.statusUpdated ? row.statusUpdated.toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })
        : ''
    );
  }
  return undefined;
};

const renderTableCell = (key: string): TableCell => {
  if (key === ErrorColumns.EVENT) {
    return (row: FormattedEventError): React.ReactNode => (
      <EventLabel title={row[key].title} venue={row[key].venue} />
    );
  }
  if (key === ErrorColumns.VALUE) {
    return (row: FormattedEventError): React.ReactNode => (
      <ValueLabel value={row[key]} />
    );
  }
  return undefined;
};

const growTableCell = (key: string): number => {
  if (key === ErrorColumns.EVENT)
    return 1.75;
  if (key === ErrorColumns.TYPE)
    return 1.5;
  if (key === ErrorColumns.EVENT_DATE)
    return 1.5;
  if (key === ErrorColumns.LEVEL)
    return 0.4;
  if (key === ErrorColumns.STATUS)
    return 0.4;
  if (key === ErrorColumns.VALUE)
    return 0.65;
  if (key === ErrorColumns.STATUS_UPDATED)
    return 1.0;
  return undefined;
};

const sortByEvent = (eventA: EventErrorName, eventB: EventErrorName): number => {
  const a = eventA.title.toLowerCase();
  const b = eventB.title.toLowerCase();

  if (a > b)
    return 1;
  if (b > a)
    return -1;
  return 0;
};

const sortByValue = (valueA: ErrorValue, valueB: ErrorValue): number => {
  const a = valueA.portfolioValue > valueA.ingestedUnsoldCost
    ? valueA.portfolioValue
    : valueA.ingestedUnsoldCost;
  const b = valueB.portfolioValue > valueB.ingestedUnsoldCost
    ? valueB.portfolioValue
    : valueB.ingestedUnsoldCost;

  if (a > b)
    return 1;
  if (b > a)
    return -1;
  return 0;
};

const sortFunction = (key: string): (
(rowA: FormattedEventError, rowB: FormattedEventError) => number) => {
  if (key === ErrorColumns.EVENT)
    return (rowA, rowB): number => sortByEvent(rowA[key], rowB[key]);
  if (key === ErrorColumns.VALUE)
    return (rowA, rowB): number => sortByValue(rowA[key], rowB[key]);
  return undefined;
};

export {
  ErrorColumns,
  formatErrorType,
  formatTableCell,
  formatColName,
  renderTableCell,
  growTableCell,
  sortFunction,
};
