import { AxiosResponse } from 'axios';
import { Settings, OverrideMap } from '#/types/Override';
import client from './_client';
import mapSettings from './utils/mapSettings';

const getParameters = async (eventId: number): Promise<Settings> => {
  const response: AxiosResponse<OverrideMap> = await client.get(
    `/proxy/parameters/${eventId}`,
  );

  return mapSettings(response.data);
};

export default getParameters;
