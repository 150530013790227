import React from 'react';
import {
  NarrowWrapper, Element, Name, Value, NameValue,
} from '#/shared/Card';
import Listing from '#/types/Listing';

const SOURCE = 'Source';
const SOURCE_EVENT = 'Source Event';
const SOURCE_LISTING_ID = 'Source Listing ID';

interface CardProps {
  listing: Listing;
}

const SourceCard: React.FC<CardProps> = ({ listing }) => {
  const {
    source_name, source_inventory_id, source_external_event_id,
  } = listing;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{SOURCE}</Name>
        <Value>{source_name}</Value>
      </Element>
      <Element>
        <Name>{SOURCE_EVENT}</Name>
        <Value>{source_external_event_id}</Value>
      </Element>
      <Element>
        <Name>{SOURCE_LISTING_ID}</Name>
        <NameValue>
          {source_inventory_id}
        </NameValue>
      </Element>
    </NarrowWrapper>
  );
};

export default SourceCard;
