import React, {
  useCallback, useState, ChangeEvent, useEffect,
} from 'react';

import palette from '#/theme/palettes/main';

import styled from 'styled-components';
import { ButtonTransparent } from '#/shared/Button';

const SECTION = 'Section';
const ROW = 'Row';
const FORM_EXPLANATION = 'Select seats to DELETE:';
const CLEAR_ALL = 'Clear All';
const SELECT_ALL = 'Select All';
const ATTEMPT_TO_RELIST = ' - Attempt to relist';
const EMPTY_SET = new Set<number>();

interface SeatDeletionFormProps {
  section: string;
  row: string;
  seats: number[],
  setTicketRelistingRequests: (seatsToRelist: Set<number>) => void;
}

const SeatDeletionForm: React.FC<SeatDeletionFormProps> = ({
  section,
  row,
  seats,
  setTicketRelistingRequests,
}) => {
  const [seatsToRelist, setSeatsToRelist] = useState<Set<number>>(new Set());

  useEffect(() => {
    setTicketRelistingRequests(seatsToRelist);
  }, [setTicketRelistingRequests, seatsToRelist]);

  // We want all seats to initially be selected for deletion.
  useEffect(() => {
    setSeatsToRelist(EMPTY_SET);
  }, [setSeatsToRelist]);

  const clearAllSeatDeletions = useCallback(() => {
    setSeatsToRelist(new Set(seats));
  }, [setSeatsToRelist, seats]);

  const selectAllSeatDeletions = useCallback(() => {
    setSeatsToRelist(EMPTY_SET);
  }, [setSeatsToRelist]);

  const onChangeSeatToDelete = useCallback((seat: number) => (
    (event: ChangeEvent<HTMLInputElement>): void => {
      const isCheckedForDeletion = !!event?.target?.checked;
      const isRelistingRequested = !isCheckedForDeletion;

      setSeatsToRelist((s) => {
        const newSeatsToRelist = new Set(s);

        if (isRelistingRequested)
          newSeatsToRelist.add(seat);
        else
          newSeatsToRelist.delete(seat);

        return newSeatsToRelist;
      });
    }
  ), [setSeatsToRelist]);

  return (
    <SeatDeletionFormWrapper>
      <ListingInfoWrapper>
        <Label>{`${SECTION}: ${section}`}</Label>
        <Label>{`${ROW}: ${row}`}</Label>
      </ListingInfoWrapper>
      <Label>{FORM_EXPLANATION}</Label>
      <SeatDeletionCheckboxesWrapper>
        {seats
          .sort((a, b) => a - b)
          .map((seat) => (
            <SeatDeletionCheckbox
              isChecked={!seatsToRelist.has(seat)}
              key={seat}
              onChange={onChangeSeatToDelete(seat)}
              seat={seat}
            />
          ))}
      </SeatDeletionCheckboxesWrapper>
      <ButtonsWrapper>
        <ButtonTransparentWithOutline onClick={clearAllSeatDeletions}>
          {CLEAR_ALL}
        </ButtonTransparentWithOutline>
        <ButtonTransparentWithOutline onClick={selectAllSeatDeletions}>
          {SELECT_ALL}
        </ButtonTransparentWithOutline>
      </ButtonsWrapper>
    </SeatDeletionFormWrapper>
  );
};

interface SeatDeletionCheckboxProps {
  seat: number;
  isChecked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SeatDeletionCheckbox: React.FC<SeatDeletionCheckboxProps> = ({
  seat,
  isChecked,
  onChange,
}) => {
  return (
    <SeatDeletionCheckboxWrapper>
      <Checkbox
        checked={isChecked}
        onChange={onChange}
        type="checkbox"
      />
      <Label>{`${seat}${isChecked ? '' : ATTEMPT_TO_RELIST}`}</Label>
    </SeatDeletionCheckboxWrapper>
  );
};

const SeatDeletionFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 15px;
    margin: 1rem;
  `;

const ListingInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
`;

const SeatDeletionCheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
`;

const SeatDeletionCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Checkbox = styled.input`
  height: 1.5rem;
  width: 3rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;

  &:checked {
    background-color: ${palette.brand.base};
  }
  &:after {
    background-color: ${palette.brand.base};
  }
`;

const Label = styled.label`
  text-align: left;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonTransparentWithOutline = styled(ButtonTransparent as any)`
  border-width: 1.5px;
  border-style: solid;
  border-color: ${({ theme }: { theme: Theme }): string => theme.color.background.selected};
  display: flex;
  align-items: center;
`;

export default SeatDeletionForm;
