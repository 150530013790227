import { useCallback, useReducer } from 'react';

import Action, { IntegrationConfig } from '../types';
import RESET from '../constants';

type Reducer = (
  previousState: IntegrationConfig,
  action: Action
) => IntegrationConfig;

const reducer: Reducer = (previousState, action) => {
  const { type, value } = action;

  if (type === RESET)
    return value as IntegrationConfig;

  const state = { ...previousState, [type]: value } as IntegrationConfig;

  return state;
};

type Hook = (
  initalConfig: IntegrationConfig
) => {
  integrationConfig: IntegrationConfig;
  resetIntegrationConfig: () => void;
  updateIntegrationConfig: React.Dispatch<Action>;
};

const useIntegration: Hook = (initialConfig) => {
  const [integrationConfig, updateIntegrationConfig] = useReducer<Reducer>(
    reducer,
    initialConfig,
  );

  const resetIntegrationConfig = useCallback(() => {
    updateIntegrationConfig({ type: RESET, value: initialConfig });
  }, [initialConfig]);

  return {
    integrationConfig,
    resetIntegrationConfig,
    updateIntegrationConfig,
  };
};

export default useIntegration;
