import { AxiosResponse } from 'axios';
import client from './_client';
import Event, { EventFilters, APIEvent } from '#/types/Event';
import mapEvent from './utils/mapEvent';

interface GetEventResponse {
  events: APIEvent[];
  request: APIRequest;
}

interface APIRequest {
  per_page: number;
}

/* eslint-enable camelcase */

const getEvents = async (
  filters: EventFilters = {},
): Promise<[Event[], number]> => {
  const response: AxiosResponse<GetEventResponse> = await client.get(
    '/proxy/events',
    { params: filters },
  );
  const rawEvents = response?.data?.events || [];
  const perPage = response?.data?.request?.per_page;

  return [rawEvents.map(mapEvent), perPage];
};

export default getEvents;
