import client from './_client';

type APICall = (autobrokerEventId: number, notes?: string) => Promise<number>;

const deletePriceExploration: APICall = async (autobrokerEventId: number, notes?: string) => {
  const response = await client.delete(
    `/proxy/exploration/${autobrokerEventId}`,
    { data: { notes } },
  );

  return response.status;
};

export default deletePriceExploration;
