import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { PeakpassDomain } from '#/types/PeakpassDomain';
import getPeakpassDomains from '#/api/getPeakpassDomains';
import formatApiError from '#/api/utils/formatApiError';

interface PeakpassDomainsHook {
  peakpassDomains: PeakpassDomain[];
  peakpassDomainError: string;
  peakpassDomainStakeholders: Set<string>;
  fetchPeakpassDomains: () => void;
}

const useFetchPeakpassDomains = (): PeakpassDomainsHook => {
  const [peakpassDomains, setPeakpassDomains] = useState<PeakpassDomain[]>([]);
  const [peakpassDomainError, setPeakpassDomainError] = useState<string>('');

  const fetchPeakpassDomains = useCallback(async () => {
    try {
      const domains = await getPeakpassDomains();

      setPeakpassDomains(domains);
    } catch (err) {
      const errorString = formatApiError(err);

      setPeakpassDomainError(errorString);
    }
  }, [setPeakpassDomains, setPeakpassDomainError]);

  useEffect(() => {
    fetchPeakpassDomains();
  }, [fetchPeakpassDomains]);

  const peakpassDomainStakeholders = useMemo(() => {
    return new Set(peakpassDomains.map((domain) => domain.stakeholder));
  }, [peakpassDomains]);

  return {
    peakpassDomains,
    peakpassDomainError,
    fetchPeakpassDomains,
    peakpassDomainStakeholders,
  };
};

export default useFetchPeakpassDomains;
export { PeakpassDomainsHook };
