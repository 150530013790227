import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ButtonSmall } from '#/shared/Button';
import postEventConfigUpdate from '#/api/postEventConfigUpdate';
import Event from '#/types/Event';
import toast, { Toaster } from 'react-hot-toast';

interface Props {
  activeEvent: Event;
}

const TEXT = 'Run Supervisor';
const TOAST_SUCCESS_MESSAGE = 'Running the Supervisor!';
const TOAST_FAIL_MESSAGE = 'Unable to run the Supervisor due to an error';

const RunSupervisorButton: React.FC<Props> = ({ activeEvent }) => {
  const isActive = activeEvent.config.isActive;
  const handleClick = useCallback(async () => {
    await toast.promise(postEventConfigUpdate(activeEvent, {}, true), {
      loading: '',
      success: TOAST_SUCCESS_MESSAGE,
      error: TOAST_FAIL_MESSAGE,
    });
  }, [activeEvent]);

  return (
    <div>

      <RunButton disabled={!isActive} onClick={handleClick}>
        {TEXT}
      </RunButton>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 5000,
          success: {
            style: {
              background: '#ECF8F3',
              color: '#0B6F46',
            },
          },
          error: {
            style: {
              background: '#FDF0F0',
              color: '#BA2626',
            },
          },
        }}
      />
    </div>
  );
};

const RunButton = styled(ButtonSmall as any)`
  padding: 0 1rem;
`;

export default RunSupervisorButton;
