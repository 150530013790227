import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import {
  InnerWrapper,
  Label,
  Input,
  HorizontalWrapper,
} from '#/shared/modalComponents';
import { PostIngestion } from '#/types/Ingestion';
import getUploaderEventBySeatgeekEventId from '#/api/getUploaderEventBySeatgeekEventId';
import getUploaderEvent from '#/api/getUploaderEvent';
import Separator from './Separator';
import UploaderEvent from '#/types/UploaderEvent';

const SEATGEEK_EVENT_ID = 'SeatGeek Event ID';
const UPLOADER_EVENT_ID = 'Uploader Event ID';

interface EventFormProps {
  ingestion: PostIngestion;
  setIngestion: React.Dispatch<React.SetStateAction<PostIngestion>>;
  eventDetail: string | null;
  setEventDetail: React.Dispatch<React.SetStateAction<string | null>>;
}

const EventForm: React.FC<EventFormProps> = ({
  ingestion, setIngestion, eventDetail, setEventDetail,
}) => {
  const { seatgeek_event_id, uploader, uploader_event_id } = ingestion;

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (uploader && seatgeek_event_id && !uploader_event_id) {
        const event = await getUploaderEventBySeatgeekEventId(
          uploader, seatgeek_event_id,
        );

        if (event) {
          setEventDetail(formatEvent(event));
          setIngestion((i) => (
            { ...i, uploader_event_id: event.id }
          ));
        } else {
          setEventDetail(null);
        }
      } else if (uploader && uploader_event_id) {
        const event = await getUploaderEvent(uploader, uploader_event_id);

        if (event)
          setEventDetail(formatEvent(event));
      } else {
        setEventDetail(null);
      }
    };

    fetchData();
  }, [uploader, seatgeek_event_id, uploader_event_id, setEventDetail, setIngestion]);

  const onChangeSeatgeekEventId = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const seatgeekEventId = Number(event.target.value) || null;

      setEventDetail(null);
      setIngestion((i) => (
        { ...i, seatgeek_event_id: seatgeekEventId }
      ));
    }, [setEventDetail, setIngestion],
  );

  const onChangeUploaderEventId = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const uploaderEventId = event.target?.value || null;

      setEventDetail(null);
      setIngestion((i) => (
        { ...i, uploader_event_id: uploaderEventId }
      ));
    }, [setEventDetail, setIngestion],
  );

  return (
    <>
      <InnerWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{SEATGEEK_EVENT_ID}</Label>
            <Input
              onChange={onChangeSeatgeekEventId}
              type="number"
              value={seatgeek_event_id || ''}
            />
          </Separator>
          <Separator>
            <Label>{UPLOADER_EVENT_ID}</Label>
            <Input
              onChange={onChangeUploaderEventId}
              type="string"
              value={uploader_event_id || ''}
            />
          </Separator>
        </HorizontalWrapper>
      </InnerWrapper>
      {eventDetail && (
        <InnerWrapper>
          <EventDetail>{eventDetail}</EventDetail>
        </InnerWrapper>
      )}
    </>
  );
};

const formatEvent: (event: UploaderEvent) => null | string = (event) => {
  if (!event || !event.eventTitle || !event.eventDate || !event.eventTime)
    return null;

  let formatted = '';

  if (event.eventTitle)
    formatted += event.eventTitle + '\n';

  if (event.eventDate && event.eventTime)
    formatted += `${event.eventDate} ${event.eventTime}\n`;

  if (event.venueName) {
    formatted += event.venueName;
    if (event.venueCity && event.venueState)
      formatted += `, ${event.venueCity}, ${event.venueState}`;
  }
  return formatted;
};

const EventDetail = styled.div`
  white-space: pre;
  text-align: center;
  overflow: hidden;
  font-size: 0.8rem;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.highlight};
  padding: 0.5rem;
`;

export default EventForm;
