import React from 'react';

interface InputProps {
  fileName: string;
  onFileChange: (event: React.ChangeEvent) => void;
}

const Input: React.FC<InputProps> = ({ fileName, onFileChange }) => {
  return (
    <label className='uploadLabel' htmlFor='pricing-constraints'>
      {fileName}
      <input accept='.csv, .txt' id='pricing-constraints' onChange={onFileChange} type='file' />
    </label>
  );
};

export default Input;
