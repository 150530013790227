import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { PRICING_BASIS_OPTIONS } from '#/types/Event';
import { OutputListing } from '#/types/Ingestion';
import HeaderButtons from './HeaderButtons';
import InputListingOverrideCard from '#/shared/InputListingOverrideCard';
import { InputListing } from '#/shared/ListingOverrideModal';
import { User } from '#/types/User';

const LISTING = 'Listing';
const AUTOBROKER_EVENT = 'Autobroker Event';
const FALLBACK_PRICING = 'Using Fallback Pricing';

interface CardProps {
  user: User;
  listing: OutputListing;
  setOverrideListingModal: (listings: InputListing[]) => void;
}

const OutputListingCard: React.FC<CardProps> = ({
  user,
  listing,
  setOverrideListingModal,
}) => {
  const disabled = user.isReadOnly;
  const openEditModal = useCallback(() => {
    setOverrideListingModal([listing]);
  }, [setOverrideListingModal, listing]);
  const priceLabel = PRICING_BASIS_OPTIONS
    .find((option) => option.value === listing.price.basis)
    ?.label;

  return (
    <>
      {listing.override && (!listing.override.is_empty || !listing.override.is_active) && (
        <InputListingOverrideCard override={listing.override} />
      )}
      <NarrowWrapper>
        <Element>
          <HeaderButtons
            disabled={disabled}
            openEditModal={openEditModal}
            openNotesModal={null}
          >
            <Name>{LISTING}</Name>
          </HeaderButtons>
        </Element>
        <Element>
          <Name>
            {AUTOBROKER_EVENT}
            <Link
              href={`/view/${listing.autobroker_event_id}`}
              rel="noopener"
              target="_blank"
            >
              {listing.autobroker_event_id}
            </Link>
          </Name>
        </Element>
        <Element>
          <Name>{priceLabel}</Name>
          <Value>{`${listing.quantity}x • $${listing.price.amount.toFixed(2)}`}</Value>
        </Element>
        {listing.price.fallback_pricing_policy
          && (
            <Element>
              <Name>{FALLBACK_PRICING}</Name>
            </Element>
          )}
        <Element>
          <Name>{`Updated ${listing.created_at.toRelative()}`}</Name>
        </Element>
      </NarrowWrapper>
    </>
  );
};

const Link = styled.a`
  padding-left: 0.35rem
`;

export default OutputListingCard;
