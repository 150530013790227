import { type PriceExploration, PriceExplorationType } from '#/types/Event';

interface Action {
  type: PriceExplorationType | 'reset' | 'notes';
  value: string | number | PriceExploration;
}

const RESET = 'reset';

export { RESET };

export default Action;
