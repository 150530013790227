import React, { useState } from 'react';
import type { EventSalesTrends } from '../utils/getSalesTrendsByEvent';
import type { MappedRollup } from '#/types/MapRollup';

interface SalesDataContextValue {
  hasOverlaidSalesTarget?: boolean;
  setHasOverlaidSalesTarget: React.Dispatch<React.SetStateAction<boolean>>;
  trends?: EventSalesTrends;
  setEventSalesTrends: React.Dispatch<React.SetStateAction<EventSalesTrends>>;
  mapData?: MappedRollup;
  setMapData: React.Dispatch<React.SetStateAction<MappedRollup>>;
}

const CollectionSalesDataContext = React.createContext<SalesDataContextValue>(null);

const CollectionSalesDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [hasOverlaidSalesTarget, setHasOverlaidSalesTarget] = useState(true);
  const [trends, setEventSalesTrends] = useState<EventSalesTrends>();
  const [mapData, setMapData] = useState<MappedRollup>();

  const value: SalesDataContextValue = {
    hasOverlaidSalesTarget,
    setHasOverlaidSalesTarget,
    trends,
    setEventSalesTrends,
    mapData,
    setMapData,
  };

  return (
    <CollectionSalesDataContext.Provider value={value}>
      {children}
    </CollectionSalesDataContext.Provider>
  );
};

export {
  CollectionSalesDataContext,
  CollectionSalesDataProvider,
  type SalesDataContextValue,
};
