import { DateTime } from 'luxon';
import {
  SelldownGraphData,
  GraphDomain,
  SelldownGraphInfo,
} from '#/types/Snapshot';
import Event from '#/types/Event';

interface GraphInputs {
  data: SelldownGraphData;
  domain: GraphDomain;
  eventDateForCursor: DateTime;
  overlay: SelldownGraphData;
  xLabel: string;
  zoomButtonText: string;
}

const determineGraphTimeframe = (
  historical: boolean,
  graphData: SelldownGraphData,
  graphDataDaysX: SelldownGraphData,
  selldownOverlay: SelldownGraphInfo,
  graphDomain: GraphDomain,
  graphDomainHistorical: GraphDomain,
  activeEvent: Event,
): GraphInputs => {
  return historical
    ? {
      data: graphDataDaysX,
      domain: graphDomainHistorical,
      eventDateForCursor: activeEvent.config.eventStartsAt,
      overlay: selldownOverlay?.graphDataDaysX,
      xLabel: 'Days To Event',
      zoomButtonText: 'Zoom Out',
    }
    : {
      data: graphData,
      domain: graphDomain,
      eventDateForCursor: null,
      overlay: selldownOverlay?.graphData,
      xLabel: 'Log Hours To Event',
      zoomButtonText: 'Zoom In',
    };
};

export default determineGraphTimeframe;
