import { AxiosResponse } from 'axios';
import { PriceLevelFilters, GetPriceLevelsResponse, PriceLevelEntry } from '#/types/PriceLevel';
import client from './_clientFormatted';

const getPriceLevels = async (filters: PriceLevelFilters): Promise<PriceLevelEntry[]> => {
  const response: AxiosResponse<GetPriceLevelsResponse> = await client.get(
    '/proxy/price_levels', { params: filters },
  );

  return response.data.priceLevels;
};

export default getPriceLevels;
