import React from 'react';
import {
  ErrorStatusType, EventError, PostErrorStatus,
} from '#/types/Errors';
import Checklist, { ChecklistConfigItem } from './checklist';

interface SellerdirectMatchingChecklistProps {
  error: EventError;
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const MISMATCHED_CONFIG: ChecklistConfigItem[] = [
  {
    id: 0,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'SeatGeek Event is Hidden',
  },
  {
    id: 1,
    level: 2,
    status: ErrorStatusType.WONTFIX,
    notes: 'Another Event Exists corresponding to this SeatGeek Event ID',
    parent_id: 0,
  },
  {
    id: 2,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: 'No Other Event Exists corresponding to this SeatGeek Event ID',
    parent_id: 0,
  },
  {
    id: 3,
    level: 3,
    status: ErrorStatusType.RESOLVED,
    notes: 'Rebuilt this Event with Correct SeatGeek Event ID',
    parent_id: 2,
  },
  {
    id: 4,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Wrong Oneticket Event Linked to SeatGeek Event',
  },
  {
    id: 5,
    level: 2,
    status: ErrorStatusType.RESOLVED,
    notes: 'Source is Unlinked and Sink is Deleted',
    parent_id: 4,
  },
  {
    id: 6,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Sellerdirect Event Matching is Incorrect',
  },
  {
    id: 7,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Using Sellerdirect Event Matching Override Tool',
    link: 'https://sellers.seatgeek.com/matching?activeTab=exportUnmatched',
    parent_id: 6,
  },
  {
    id: 8,
    level: 3,
    status: ErrorStatusType.RESOLVED,
    notes: 'Event Matched in Sellerdirect',
    parent_id: 7,
  },
];

const FAILED_CONFIG = [
  {
    id: 0,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Using Sellerdirect Event Matching Override Tool',
    link: 'https://sellers.seatgeek.com/matching?activeTab=exportUnmatched',
  },
  {
    id: 1,
    level: 2,
    status: ErrorStatusType.RESOLVED,
    notes: 'Event Matched in Sellerdirect',
    parent_id: 0,
  },
];

const SellerdirectMatchingErrorChecklist: React.FC<SellerdirectMatchingChecklistProps> = (
  { error, setErrorStatus },
) => {
  const config = error.errorReason.toLowerCase().includes('event matching to a seatgeek event failed in sellerdirect') ? FAILED_CONFIG : MISMATCHED_CONFIG;

  return (
    <Checklist config={config} error={error} setErrorStatus={setErrorStatus} />
  );
};

export default SellerdirectMatchingErrorChecklist;
