import React, { useState, useCallback, useEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import IngestionsTable from './IngestionsTable';
import IngestionDetail from './IngestionDetail';
import { User } from '#/types/User';
import Ingestion from '#/types/Ingestion';
import { UseIngestionsHook } from './useFetchIngestions';
import { UsePatchIngestionsHook } from './usePatchIngestions';
import EditListingModal from './EditListingModal';
import DisplayPdfModal from './DisplayPdfModal';
import MultiIngestionsDetail from './MultiIngestionsDetail';
import ListingOverrideModal, { InputListing } from '#/shared/ListingOverrideModal';
import ErrorStatusModal, { ErrorStatusProps } from '#/shared/ErrorStatusModal';
import getIngestion from '#/api/getIngestion';
import UploadListingModal from '#/shared/Header/UploadListingModal';
import styled from 'styled-components';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import ErrorModal from '#/shared/ErrorModal';
import { StakeholderLogos } from '../useFetchStakeholders';

interface IngestionsPageProps {
  user: User;
  ingestionsHook: UseIngestionsHook;
  stakeholderLogos: StakeholderLogos;
  patchIngestionsHook: UsePatchIngestionsHook;
  pinnedIngestions: Ingestion[];
  setPinnedIngestions: (value: React.SetStateAction<Ingestion[]>) => void;
}
const EDIT_ALL_RESULTS = 'Edit ALL Results (Across Pages)';

const IngestionsPage: React.FC<IngestionsPageProps> = ({
  user,
  ingestionsHook,
  stakeholderLogos,
  patchIngestionsHook,
  pinnedIngestions,
  setPinnedIngestions,
}) => {
  const [selected, setSelected] = useState<Ingestion[]>([]);
  const [errorStatusModal, setErrorStatusModal] = useState<ErrorStatusProps>(null);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showBulkEditModal, setShowBulkEditModal] = useState<boolean>(false);
  const [showRelistModal, setShowRelistModal] = useState<boolean>(false);
  const [overrideListingModal, setOverrideListingModal] = useState<InputListing[]>([]);
  const [closeable, setCloseable] = useState<boolean>(true);
  const [pdfUrl, setPdfUrl] = useState<string>(null);
  const { ingestions, pages, retry } = ingestionsHook;
  const {
    patchIngestions,
    setPatchIngestions,
    getAllIngestions,
    showLoader,
    showResults,
    showPatchIngestionError,
    clearError,
    patchIngestionErrorCopy,
  } = patchIngestionsHook;

  const isVisible = usePageVisibility();

  const updateCurrent = useCallback(async () => {
    if (selected.length === 1) {
      try {
        const newCurrent = await getIngestion(selected[0].id);

        setSelected([newCurrent]);
      } catch (error) {
        console.error(error);
      }
    }
  }, [selected, setSelected]);

  // if there is only one ingestion, show it, and remove the close button
  useEffect(() => {
    if (ingestions.length === 1 && pages.length === 0) {
      setSelected([ingestions[0]]);
      setCloseable(false);
      return;
    }
    setCloseable(true);
  }, [setSelected, setCloseable, ingestions, pages]);

  // refresh the current listing
  useEffect(() => {
    const interval = setInterval(() => {
      if (isVisible)
        updateCurrent();
    }, 20000);

    return (): void => clearInterval(interval);
  }, [updateCurrent, isVisible]);

  const closeModal = useCallback(() => {
    setErrorStatusModal(null);
    updateCurrent();
    retry();
  }, [setErrorStatusModal, updateCurrent, retry]);

  const closeDetail = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  const openEditModal = useCallback(() => {
    setShowEditModal(true);
  }, [setShowEditModal]);

  const closeEditModal = useCallback(() => {
    setShowEditModal(false);
    setShowBulkEditModal(false);
    setPatchIngestions([...ingestions]);
    updateCurrent();
    retry();
  }, [
    setShowEditModal,
    setShowBulkEditModal,
    setPatchIngestions,
    ingestions,
    updateCurrent,
    retry,
  ]);

  const openBulkEditModal = useCallback(async () => {
    await getAllIngestions();
    if (ingestions && patchIngestions.length > 0)
      setShowBulkEditModal(true);
  }, [setShowBulkEditModal, getAllIngestions, ingestions, patchIngestions]);

  useEffect(() => {
    if (patchIngestions.length <= ingestions.length)
      setPatchIngestions([...ingestions]);
  }, [ingestions, patchIngestions.length, setPatchIngestions]);

  const openRelistModal = useCallback(() => {
    setShowRelistModal(true);
  }, [setShowRelistModal]);

  const closeRelistModal = useCallback(() => {
    setShowRelistModal(false);
  }, [setShowRelistModal]);

  const closeOverrideModal = useCallback(() => {
    setOverrideListingModal([]);
    updateCurrent();
    retry();
  }, [setOverrideListingModal, updateCurrent, retry]);

  const closePdfModal = useCallback(() => {
    setPdfUrl(null);
  }, [setPdfUrl]);

  const disabled = user.isReadOnly;

  return (
    <>
      {selected.length === 0 && (
        <SelectionControls>
          <SmallButton
            isDisabled={ingestions?.length === 0}
            isLoading={showLoader}
            kind='secondary'
            label={EDIT_ALL_RESULTS}
            onClick={openBulkEditModal}
          />
        </SelectionControls>
      )}
      {selected.length === 1 && (
        <IngestionDetail
          closeDetail={closeDetail}
          closeable={closeable}
          ingestion={selected[0]}
          openEditModal={openEditModal}
          openRelistModal={openRelistModal}
          setErrorStatusModal={setErrorStatusModal}
          setOverrideListingModal={setOverrideListingModal}
          setPdfUrl={setPdfUrl}
          user={user}
        />
      )}
      {selected.length > 1 && (
        <MultiIngestionsDetail
          closeDetail={closeDetail}
          ingestions={selected}
          openEditModal={openEditModal}
          user={user}
        />
      )}
      <IngestionsTable
        ingestionsHook={ingestionsHook}
        pinnedIngestions={pinnedIngestions}
        selectedIds={selected.map((s) => s.id)}
        setPinnedIngestions={setPinnedIngestions}
        setSelected={setSelected}
        stakeholderLogos={stakeholderLogos}
      />
      {!disabled && errorStatusModal && (
        <ErrorStatusModal closeModal={closeModal} props={errorStatusModal} />
      )}
      {showPatchIngestionError && (
        <ErrorModal clearError={clearError} error={patchIngestionErrorCopy} />
      )}
      {!disabled && selected.length > 0 && showEditModal && (
        <EditListingModal closeModal={closeEditModal} ingestions={selected} isBulk={false} />
      )}
      {!disabled && showBulkEditModal && showResults && (
        <EditListingModal closeModal={closeEditModal} ingestions={patchIngestions} isBulk />
      )}
      {!disabled && selected.length === 1 && showRelistModal && (
        <UploadListingModal closeModal={closeRelistModal} initial={selected[0]} />
      )}
      {!disabled && overrideListingModal.length > 0 && (
        <ListingOverrideModal closeModal={closeOverrideModal} listings={overrideListingModal} />
      )}
      {pdfUrl && (
        <DisplayPdfModal closeModal={closePdfModal} pdfUrl={pdfUrl} />
      )}
    </>
  );
};

const SelectionControls = styled.div`
  padding-bottom: 1rem;
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  display: flex;
  justify-content: end;
  width: 100%;
`;

export default IngestionsPage;
