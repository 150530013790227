import React from 'react';
import {
  NarrowWrapper, Element, Name, ScrollBox,
} from '#/shared/Card';
import Ingestion from '#/types/Ingestion';

const NOTES = 'External Notes';

interface CardProps {
  ingestion: Ingestion;
}

const ExternalNotesCard: React.FC<CardProps> = ({ ingestion }) => {
  const notes = ingestion.external_notes || 'No Notes';

  return (
    <NarrowWrapper>
      <Element>
        <Name>{NOTES}</Name>
      </Element>
      <ScrollBox title={notes}>{notes}</ScrollBox>
    </NarrowWrapper>
  );
};

export default ExternalNotesCard;
