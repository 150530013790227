import React, { useCallback, useContext } from 'react';
import Map from './Map';
import MissingData from '../utils/MissingData';
import { CollectionEventsContext, CollectionSalesDataContext, CollectionSelectionsContext } from '../contexts';
import { Control, ControlBar } from '../Controls';
import Legend from './Legend';
import { MapRollupDatum } from '#/types/MapRollup';

interface Props {
  mapConfigId?: number;
}

const MapRollup: React.FC<Props> = ({ mapConfigId }) => {
  const { selectedEventIds } = useContext(CollectionEventsContext);
  const { metricSelection } = useContext(CollectionSelectionsContext);
  const { mapData } = useContext(CollectionSalesDataContext);

  const fillAccessor = useCallback((d: MapRollupDatum) => (
    d.metrics?.[metricSelection.key]
  ), [metricSelection]);

  return (
    <>
      <ControlBar isLoading={!mapData?.isReady}>
        {mapData?.isReady && (
          <Control>
            <Legend data={mapData?.mapRollup} fill={fillAccessor} />
          </Control>
        )}
      </ControlBar>
      {selectedEventIds?.length === 0 ? (
        <MissingData />
      ) : (
        <Map mapConfigId={mapConfigId} />
      )}
    </>
  );
};

export default MapRollup;
