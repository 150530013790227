import { elongate } from '@graphique/graphique';
import { pick } from 'lodash';
import { extent } from 'd3-array';
import { DateTime } from 'luxon';
import sortBy from 'lodash/sortBy';
import { SALES_METRICS } from '#/shared/reporting/accumulationUtils';
import palette from '#/theme/palettes/main';
import { formatLargeNumber } from '#/shared/formatNumberForDisplay';
import { SparklineDatum } from '#/pages/clientReporting/DashboardPage/types';
import { formatColName } from '#/pages/clientReporting/DashboardPage/utils';
import type { CumulativeMmt, SalesSummaryMeasurement } from '#/types/ReportingRollup';

const TAKE_ZERO_LINE = [{ value: 0 }];
const STROKE_COLORS = ['#587B8D', '#bbb', palette.brand.base];
const LABEL_COLORS = [palette.brand.base, '#999', '#587B8D'];
const TX_DATE = 'transactionDate';
const DELIM = '|';

const createSparklineData = (
  data: SalesSummaryMeasurement[],
  measure: CumulativeMmt,
  additionalTraces: CumulativeMmt[] = [],
): SparklineDatum[] => {
  const keysToInclude = [measure, ...additionalTraces];
  const wideData = data.map((d) => pick(d, [TX_DATE, ...keysToInclude]));

  // we'll sort the full set of data so that the most salient groups are
  // arranged last, and then by date
  return sortBy(
    elongate(wideData, 'measure', 'value', [TX_DATE]),
    [(a): number => a.measure === measure ? 1 : -1, TX_DATE],
  );
};

// This calculates the y range of the take chart and ensures that it includes 0.
//
// Its output is used as a dependency when calculating the chart's ScaleYDomain
// and returns a string to avoid unnecessary re-renders triggered by
// React's comparison on referential equality.
const calcTakeYRange = (data: SparklineDatum[], isTake: boolean): string => {
  if (!isTake)
    return undefined;

  const yRange = extent(data, (d: SparklineDatum) => d.value);

  return [Math.min(yRange[0], 0), Math.max(0, yRange[1])].join(DELIM);
};

const getFocusedDatum = (
  sparklineData: SparklineDatum[],
  focusedIndex: number,
  isTicketsSold: boolean,
): SparklineDatum[] => {
  const datum = isTicketsSold
    ? sparklineData.filter((d) => (
      Number(d?.transactionDate) === Number(sparklineData?.[focusedIndex]?.transactionDate)
    ))
    : [sparklineData?.[focusedIndex]];

  return typeof focusedIndex === 'undefined' ? undefined : datum;
};

const formatNumber = (value: number, isCurrency: boolean): string => {
  const leadingChar = isCurrency ? '$' : '';
  const isNegative = value < 0;
  const displayValue = `${leadingChar}${formatLargeNumber(Math.abs(value))}`;
  const negativeValue = `(${displayValue})`;

  return `${isNegative ? negativeValue : displayValue}`;
};

const formatMeasureText = (measure: CumulativeMmt, isNarrow = false): string => {
  const measureText = Object.keys(SALES_METRICS)
    .find((k: keyof typeof SALES_METRICS) => SALES_METRICS[k] === measure);

  const formatted = formatColName(measureText, isNarrow);

  if (isNarrow) {
    if (measure === SALES_METRICS.soldFaceValue)
      return `${formatted} (sold)`;

    if (measure === SALES_METRICS.totalFaceValue)
      return `${formatted} (total)`;
  }
  return formatted;
};

const getLastValue = (
  data: SalesSummaryMeasurement[], measure: CumulativeMmt,
): number => {
  const lastDay = data[data.length - 1];

  return lastDay?.[measure];
};

const formatMonth = (d: Date): string => {
  const month = d.getMonth();

  return d.toLocaleDateString(undefined, {
    month: 'short',
    year: month === 0 ? 'numeric' : undefined,
  });
};

const formatMonthAndDay = (d: Date): string => (
  d.toLocaleString(undefined, { month: 'short', day: 'numeric' })
);

const getNumXTicks = (width: number): number => (
  width > 250 ? 3 : 2
);

const isNarrowTimeframe = (data: SparklineDatum[]): boolean => {
  const dateRange = extent(data, (d) => DateTime.fromJSDate(d.transactionDate));
  const timeInMonths = dateRange[1].diff(dateRange[0]).as('months');

  return timeInMonths <= 2;
};

export {
  createSparklineData,
  calcTakeYRange,
  getFocusedDatum,
  formatNumber,
  formatMeasureText,
  formatMonth,
  formatMonthAndDay,
  getNumXTicks,
  isNarrowTimeframe,
  getLastValue,
  TAKE_ZERO_LINE,
  STROKE_COLORS,
  LABEL_COLORS,
  DELIM,
};
