import client from './_client';

type APICall = (file: File, notes?: string) => Promise<number>;

const postBulkPriceConstraints: APICall = async (file: File, notes?: string) => {
  const formData = new FormData();

  formData.append('csv_file', file);
  if (notes)
    formData.append('notes', notes);
  const response = await client.post('/proxy/price_constraints', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.status;
};

export default postBulkPriceConstraints;
