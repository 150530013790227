import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import Errors from '#/types/Errors';

const getErrors = async (eventId: number): Promise<Errors> => {
  const response: AxiosResponse<Errors> = await client.get(
    `/proxy/errors/${eventId}`,
  );

  return response?.data || null;
};

export default getErrors;
