import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import {
  APPLY_DEFAULTS,
  FREQUENCY_STEP, INVALID_BOUNDS_ERROR_MSG, NOTE_PREFIX, TITLE, PRICE_EXPLORATION_DEFAULTS,
} from './constants';
import BulkEditModal from '../sharedBulkEditModals/BulkEditModal';
import type Event from '#/types/Event';
import toast from 'react-hot-toast';
import formatApiError from '#/api/utils/formatApiError';
import { type PriceExploration, StrategyType, PriceExplorationType } from '#/types/Event';
import postPriceExploration from '#/api/postPriceExploration';
import { SUCCESS_MSG } from '../sharedBulkEditModals/constants';
import StrategyDropdown from './StrategyDropdown';
import { isDefined } from '#/pages/EventCollectionPage/utils/getSalesTrendsByEvent';
import useSetupModal from '#/pages/useSetupModal';
import NumberField from '#/shared/NumberField';
import { pickBy, startCase } from 'lodash';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';

interface Props {
  isOpen?: boolean
  onClose: () => void
  onSubmit: () => void
  selectedEvents?: Event[]
}

const EditPriceExplorationModal: React.FC<Props> = ({
  isOpen, selectedEvents, onClose, onSubmit,
}) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [priceExplorationState, setPriceExplorationState] = useState<Partial<PriceExploration>>();
  const [hasInvalidBoundsError, setHasInvalidBoundsError] = useState(false);

  const notesRef = useRef<HTMLInputElement>(null);

  const handleStrategySelection = useCallback(
    (selection: { value: StrategyType }) => {
      setPriceExplorationState((prev) => ({
        ...prev,
        strategyType: selection?.value,
      }));
    }, [],
  );

  useEffect(() => {
    const updatedValues = priceExplorationState ? Object.values(priceExplorationState) : [];
    const hasDefinedUpdates = updatedValues?.length > 0
      && updatedValues?.every((update) => isDefined(update));

    setCanSubmit(
      (!!priceExplorationState && hasDefinedUpdates && !hasInvalidBoundsError),
    );
  }, [priceExplorationState, hasInvalidBoundsError]);

  const handleClose = useCallback(() => {
    onClose();
    setPriceExplorationState(undefined);
  }, [onClose]);

  useSetupModal(handleClose);

  // validate lower/upper percentile inputs
  useEffect(() => {
    const lowerGreaterThanUpper = (
      priceExplorationState?.[PriceExplorationType.LOWER_PERCENTILE]
        > priceExplorationState?.[PriceExplorationType.UPPER_PERCENTILE]
    );

    setHasInvalidBoundsError(lowerGreaterThanUpper);
  }, [priceExplorationState]);

  const handleChange = useCallback((explorationProperty: string) => {
    return (value: number): void => {
      setPriceExplorationState((prev) => {
        const state = {
          ...prev,
          [explorationProperty]: value,
        };

        // set only valid updates
        return pickBy(state, isDefined);
      });
    };
  }, []);

  const handleApplyDefaults = useCallback(() => {
    setPriceExplorationState((prev) => {
      const state = {
        ...prev,
        ...PRICE_EXPLORATION_DEFAULTS,
      };

      return state;
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    const notesText = notesRef.current?.value?.trim();

    if (selectedEvents && canSubmit) {
      const numSelectedEvents = selectedEvents?.length;

      try {
        const priceExplorationUpdates = selectedEvents.map(({ id, priceExploration: prev }) => {
          const postBody: PriceExploration = {
            ...prev,
            autobrokerEventId: id,
            strategyType: priceExplorationState?.strategyType ?? prev?.strategyType,
            frequency: priceExplorationState?.frequency ?? prev?.frequency,
            lowerPercentile: priceExplorationState?.lowerPercentile ?? prev?.lowerPercentile,
            upperPercentile: priceExplorationState?.upperPercentile ?? prev?.upperPercentile,
            notes: notesText ? `${NOTE_PREFIX}: ${notesText}` : NOTE_PREFIX,
          };

          return postPriceExploration(postBody, id);
        });

        onClose();
        toast.loading(`Updating ${numSelectedEvents} event(s)...`);
        await Promise.all(priceExplorationUpdates);
        toast.dismiss();
        toast.success(`${numSelectedEvents}${SUCCESS_MSG}`);
        onSubmit();
      } catch (err) {
        toast.dismiss();
        toast.error(formatApiError(err));
      }
      handleClose();
    }
  }, [canSubmit, selectedEvents, onSubmit, onClose, handleClose, priceExplorationState]);

  const hasEmptyStrategy = useMemo(() => (
    !isDefined(priceExplorationState?.strategyType)
  ), [priceExplorationState]);

  // unset numeric values when there's an empty strategy type selected
  useEffect(() => {
    if (hasEmptyStrategy) {
      setPriceExplorationState((prev) => ({
        ...prev,
        frequency: undefined,
        lowerPercentile: undefined,
        upperPercentile: undefined,
      }));
    }
  }, [hasEmptyStrategy]);

  return (
    <BulkEditModal
      isDisabled={!canSubmit}
      isOpen={isOpen}
      maxWidth={340}
      notesRef={notesRef}
      onClose={handleClose}
      onSubmit={handleSubmit}
      selectedEvents={selectedEvents}
      title={TITLE}
    >
      <ContentWrapper>
        <StrategyControls>
          <Button
            kind='secondary'
            label={(
              <ButtonContent>
                {APPLY_DEFAULTS}
              </ButtonContent>
            )}
            onClick={handleApplyDefaults}
          />
          <StrategyDropdown
            onChange={handleStrategySelection}
            selectedValue={{
              value: priceExplorationState?.strategyType,
              label: startCase(priceExplorationState?.strategyType),
            }}
          />
        </StrategyControls>
        <NumberFields>
          <NumberField
            id={PriceExplorationType.FREQUENCY}
            isDisabled={hasEmptyStrategy}
            label={startCase(PriceExplorationType.FREQUENCY)}
            minValue={0}
            onChange={handleChange(PriceExplorationType.FREQUENCY)}
            step={FREQUENCY_STEP}
            value={priceExplorationState?.[PriceExplorationType.FREQUENCY] ?? NaN}
          />
          <NumberField
            id={PriceExplorationType.LOWER_PERCENTILE}
            isDisabled={hasEmptyStrategy}
            label={startCase(PriceExplorationType.LOWER_PERCENTILE)}
            minValue={0}
            onChange={handleChange(PriceExplorationType.LOWER_PERCENTILE)}
            value={priceExplorationState?.[PriceExplorationType.LOWER_PERCENTILE] ?? NaN}
          />
          <NumberField
            id={PriceExplorationType.UPPER_PERCENTILE}
            isDisabled={hasEmptyStrategy}
            label={startCase(PriceExplorationType.UPPER_PERCENTILE)}
            minValue={0}
            onChange={handleChange(PriceExplorationType.UPPER_PERCENTILE)}
            value={priceExplorationState?.[PriceExplorationType.UPPER_PERCENTILE] ?? NaN}
          />
        </NumberFields>
        {hasInvalidBoundsError && <Error>{INVALID_BOUNDS_ERROR_MSG}</Error>}
      </ContentWrapper>
    </BulkEditModal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 0.7rem;
`;

const StrategyControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  width: 17rem;
`;

const Button = styled(SmallButton)`
  width: 100%;
`;

const ButtonContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0.1rem 0;
`;

const NumberFields = styled.div`
  display: flex;
  flex-direction: column;
  width: 17rem;
  gap: 0.7rem;
  margin-left: 1.7rem;
`;

const Error = styled.p`
  font-size: 0.9rem;
  line-height: 1.1;
  color: ${({ theme }: { theme: Theme }): string => theme.palette.red.dark };
`;

export { Props as EditPriceExplorationModalProps };
export default EditPriceExplorationModal;
