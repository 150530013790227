import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { pickBy } from 'lodash';
import NumberField from '#/shared/NumberField';
import type Event from '#/types/Event';
import { ConfigType, type SinkConfig } from '../../ViewPage/components/Configuration/Modals/types';
import postSinkConfig from '#/api/postSinkConfig';
import toast from 'react-hot-toast';
import { isDefined } from '../../EventCollectionPage/utils/getSalesTrendsByEvent';
import formatApiError from '#/api/utils/formatApiError';
import { SUCCESS_MSG } from '../sharedBulkEditModals/constants';
import {
  MAX_LISTINGS, MIN_LISTINGS, MIN_MAX_ERROR_MSG, NOTE_PREFIX,
  N_LISTINGS_STEP, P_VISIBLE, TITLE,
} from './constants';
import BulkEditModal from '../sharedBulkEditModals/BulkEditModal';

interface Props {
  isOpen?: boolean
  onClose: () => void
  onSubmit: () => void
  selectedEvents?: Event[]
}

const EditVisibilityModal: React.FC<Props> = ({
  isOpen, onClose, onSubmit, selectedEvents,
}) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [editVisibilityState, setEditVisibilityState] = useState<Partial<SinkConfig>>();
  const [hasMinMaxError, setHasMinMaxError] = useState(false);
  const sinkEvents = useMemo(() => (
    selectedEvents?.map((e) => e?.sinkEvents)?.flat()
  ), [selectedEvents]);

  const notesRef = useRef<HTMLInputElement>(null);
  const numSelectedEvents = selectedEvents?.length;

  useEffect(() => {
    const updatedValues = editVisibilityState ? Object.values(editVisibilityState) : [];

    setCanSubmit(
      !!editVisibilityState
      && updatedValues?.length > 0
      && updatedValues?.every((update) => isDefined(update))
      && !hasMinMaxError,
    );
  }, [editVisibilityState, hasMinMaxError]);

  // validate min/max listings inputs
  useEffect(() => {
    const minGreaterThanMax = (
      editVisibilityState?.[ConfigType.LISTING_MIN_COUNT]
        > editVisibilityState?.[ConfigType.LISTING_MAX_COUNT]
    );

    setHasMinMaxError(minGreaterThanMax);
  }, [editVisibilityState]);

  const handleChange = useCallback((configType: ConfigType) => {
    return function visibilityChange(value: number): void {
      setEditVisibilityState((prev) => {
        const state = {
          ...prev,
          [configType]: value,
        };

        // set only valid updates
        return pickBy(state, isDefined);
      });
    };
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    setEditVisibilityState(undefined);
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    const notesText = notesRef.current?.value?.trim();

    if (sinkEvents && canSubmit) {
      try {
        const visibilityUpdates = sinkEvents.map((sinkEvent) => {
          const postBody: SinkConfig = {
            ...sinkEvent,
            pVisible: editVisibilityState?.pVisible ?? sinkEvent?.pVisible,
            listingMinCount: editVisibilityState?.listingMinCount ?? sinkEvent?.listingMinCount,
            listingMaxCount: editVisibilityState?.listingMaxCount ?? sinkEvent?.listingMaxCount,
            notes: notesText ? `${NOTE_PREFIX}: ${notesText}` : NOTE_PREFIX,
          };

          return postSinkConfig(postBody);
        });

        onClose();
        toast.loading(`Updating ${numSelectedEvents} event(s)...`);
        await Promise.all(visibilityUpdates);
        toast.dismiss();
        toast.success(`${numSelectedEvents}${SUCCESS_MSG}`);
        onSubmit();
      } catch (err) {
        toast.dismiss();
        toast.error(formatApiError(err));
      }

      setEditVisibilityState(undefined);
    }
  }, [onClose, onSubmit, sinkEvents, editVisibilityState, canSubmit, numSelectedEvents]);

  return (
    <BulkEditModal
      isDisabled={!canSubmit}
      isOpen={isOpen}
      notesRef={notesRef}
      onClose={handleClose}
      onSubmit={handleSubmit}
      selectedEvents={selectedEvents}
      title={TITLE}
    >
      <NumberField
        formatOptions={{
          style: 'percent',
        }}
        id={ConfigType.P_VISIBLE}
        label={P_VISIBLE}
        maxValue={1}
        minValue={0}
        onChange={handleChange(ConfigType.P_VISIBLE)}
        value={editVisibilityState?.[ConfigType.P_VISIBLE] ?? NaN}
      />
      <NumberField
        id={ConfigType.LISTING_MIN_COUNT}
        label={MIN_LISTINGS}
        minValue={0}
        onChange={handleChange(ConfigType.LISTING_MIN_COUNT)}
        step={N_LISTINGS_STEP}
        value={editVisibilityState?.[ConfigType.LISTING_MIN_COUNT] ?? NaN}
      />
      <NumberField
        id={ConfigType.LISTING_MAX_COUNT}
        label={MAX_LISTINGS}
        minValue={N_LISTINGS_STEP}
        onChange={handleChange(ConfigType.LISTING_MAX_COUNT)}
        step={N_LISTINGS_STEP}
        value={editVisibilityState?.[ConfigType.LISTING_MAX_COUNT] ?? NaN}
      />
      {hasMinMaxError && <Error>{MIN_MAX_ERROR_MSG}</Error>}
    </BulkEditModal>
  );
};

const Error = styled.p`
  font-size: 0.9rem;
  line-height: 1.1;
  color: ${({ theme }: { theme: Theme }): string => theme.palette.red.dark };
`;

export { type Props as EditVisibilityModalProps };
export default EditVisibilityModal;
