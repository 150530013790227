import { useCallback, useReducer } from 'react';

import Action, { EventConfig } from '../types';
import RESET from '../constants';

type Reducer = (previousState: EventConfig, action: Action) => EventConfig;

const reducer: Reducer = (previousState, action) => {
  const { type, value } = action;

  if (type === RESET)
    return value as EventConfig;

  const state = { ...previousState, [type]: value } as EventConfig;

  return state;
};

type Hook = (
  initalConfig: EventConfig
) => {
  eventConfig: EventConfig;
  resetEventConfig: () => void;
  updateEventConfig: React.Dispatch<Action>;
};

const useEvent: Hook = (initialConfig) => {
  const [eventConfig, updateEventConfig] = useReducer<Reducer>(
    reducer,
    initialConfig,
  );

  const resetEventConfig = useCallback(() => {
    updateEventConfig({ type: RESET, value: initialConfig });
  }, [initialConfig]);

  return {
    eventConfig,
    resetEventConfig,
    updateEventConfig,
  };
};

export default useEvent;
