import styled from 'styled-components';
import { LARGE_BREAKPOINT, NARROW_BREAKPOINT } from '#/shared/clientReporting/utils';

const HORIZONTAL_PAD = 34;

const Row = styled.div`
  display: flex;
  width: calc(100%);
  flex-wrap: wrap;
  justify-content: space-between;
`;

interface CardProps {
  percWidth?: number;
}

const Card = styled.div<CardProps>`
  border: 1px solid rgb(0 0 0 / 8%);
  border-radius: 8px;
  padding: 1rem;
  margin: 0 0 1.5rem 0;
  @media (max-width: ${LARGE_BREAKPOINT - 1}px) {
    width: calc(100% - ${HORIZONTAL_PAD}px);
  }
  @media (max-width: ${NARROW_BREAKPOINT}px) {
    width: calc(100%);
    padding: 1rem 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
  }
  width: ${({ percWidth = 1 }): string => {
    const isFraction = percWidth < 1;
    const proportionalDiff = HORIZONTAL_PAD / (1 / percWidth);
    const proportionalSpacing = isFraction ? `${HORIZONTAL_PAD / 2}px - 0.75rem` : '0px';

    return `calc(${100 * percWidth}% - ${proportionalDiff}px - ${proportionalSpacing})`;
  }
};
`;

export {
  Row,
  Card,
};
