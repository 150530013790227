import React from 'react';
import { User } from '#/types/User';
import Ingestion from '#/types/Ingestion';
import { ErrorStatusProps } from '#/shared/ErrorStatusModal';
import ErrorStatusCard from './ErrorStatusCard';
import CandidateEventCard from './CandidateEventCard';
import IngestionListingCard from './IngestionListingCard';
import InputListingErrorCard from './InputListingErrorCard';
import OutputListingCard from './OutputListingCard';
import TransactionCard from './TransactionCard';
import SummaryCard from './SummaryCard';
import RelistingInfoCard from './RelistingInfoCard';
import { InputListing } from '#/shared/ListingOverrideModal';

interface IngestionFunnelCardsProps {
  user: User;
  ingestion: Ingestion;
  openEditModal: () => void;
  setErrorStatusModal: (props: ErrorStatusProps) => void;
  setOverrideListingModal: (listings: InputListing[]) => void;
}

const IngestionFunnelCards: React.FC<IngestionFunnelCardsProps> = ({
  user,
  ingestion,
  openEditModal,
  setErrorStatusModal,
  setOverrideListingModal,
}) => {
  const {
    candidate_event, error_status, previous_ingestion_listing, relisted_ingestion_listings,
  } = ingestion;

  const wasRelistedFrom = relisted_ingestion_listings?.length > 0;

  return (
    <>
      {candidate_event && <CandidateEventCard event={candidate_event} />}
      <SummaryCard ingestion={ingestion} />
      <IngestionListingCard
        ingestion={ingestion}
        openEditModal={openEditModal}
        setErrorStatusModal={setErrorStatusModal}
        user={user}
      />
      {error_status && (
        <ErrorStatusCard status={error_status} />
      )}
      {ingestion.input_listing_errors.map((error) => (
        <InputListingErrorCard
          error={error}
          key={error.id}
          setErrorStatusModal={setErrorStatusModal}
          setOverrideListingModal={setOverrideListingModal}
          user={user}
        />
      ))}
      {ingestion.output_listings.map((listing) => (
        <OutputListingCard
          key={listing.id}
          listing={listing}
          setOverrideListingModal={setOverrideListingModal}
          user={user}
        />
      ))}
      {ingestion.transactions.map((transaction) => (
        <TransactionCard key={transaction.id} transaction={transaction} />
      ))}
      {(previous_ingestion_listing || wasRelistedFrom) && (
        <RelistingInfoCard
          previousIngestionListing={previous_ingestion_listing}
          relistedIngestionListings={relisted_ingestion_listings}
        />
      )}
    </>
  );
};

export default IngestionFunnelCards;
