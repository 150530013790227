import { useState, useCallback, useEffect } from 'react';

interface Hook {
  addNewIntegration: () => void;
  updateSalesPeriods: () => void;
  rebuildEvent: () => void;
  deleteEvent: () => void;
  closeModal: () => void;
  integrationModalIsOpen: boolean;
  salesPeriodModalIsOpen: boolean;
  rebuildEventModalIsOpen: boolean;
  deleteEventModalIsOpen: boolean;
  openModal: boolean;
}

const useModals = (
  setSinkModalIsOpen: (input: boolean) => void,
  sinkModalIsOpen: boolean,
): Hook => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [integrationModalIsOpen, setintegrationModalIsOpen] = useState<boolean>(
    false,
  );
  const [salesPeriodModalIsOpen, setSalesPeriodModalIsOpen] = useState<boolean>(false);
  const [rebuildEventModalIsOpen, setRebuildEventModalIsOpen] = useState<boolean>(
    false,
  );

  const [deleteEventModalIsOpen, setDeleteEventModalIsOpen] = useState<boolean>(
    false,
  );

  const toggleModal = useCallback(() => {
    setOpenModal((prev) => !prev);
  }, []);

  const addNewIntegration = useCallback(() => {
    setintegrationModalIsOpen(true);
    toggleModal();
  }, [setintegrationModalIsOpen, toggleModal]);

  const updateSalesPeriods = useCallback(() => {
    setSalesPeriodModalIsOpen(true);
    toggleModal();
  }, [setSalesPeriodModalIsOpen, toggleModal]);

  const rebuildEvent = useCallback(() => {
    setRebuildEventModalIsOpen(true);
    toggleModal();
  }, [setRebuildEventModalIsOpen, toggleModal]);

  const deleteEvent = useCallback(() => {
    setDeleteEventModalIsOpen(true);
    toggleModal();
  }, [setDeleteEventModalIsOpen, toggleModal]);

  const resetForms = useCallback(() => {
    setintegrationModalIsOpen(false);
    setSinkModalIsOpen(false);
    setRebuildEventModalIsOpen(false);
    setDeleteEventModalIsOpen(false);
    setSalesPeriodModalIsOpen(false);
  }, [setintegrationModalIsOpen, setSinkModalIsOpen]);

  const closeModal = useCallback(() => {
    toggleModal();
    resetForms();
  }, [resetForms, toggleModal]);

  useEffect(() => {
    if (sinkModalIsOpen || integrationModalIsOpen)
      setOpenModal(true);
  }, [integrationModalIsOpen, sinkModalIsOpen]);

  return {
    addNewIntegration,
    updateSalesPeriods,
    rebuildEvent,
    deleteEvent,
    closeModal,
    integrationModalIsOpen,
    salesPeriodModalIsOpen,
    rebuildEventModalIsOpen,
    deleteEventModalIsOpen,
    openModal,
  };
};

export default useModals;
