import { cloneDeep } from 'lodash';
import { FormattedBoundary, PriceDistributionGraphData } from '#/types/Preview';
import { DEFAULT_GRAPH_DATA } from './constants';

const mapBoundaries = (
  autobroker: FormattedBoundary[],
  competitor: FormattedBoundary[],
  model: FormattedBoundary[],
): PriceDistributionGraphData => {
  const graphData: PriceDistributionGraphData = cloneDeep(DEFAULT_GRAPH_DATA);

  if (autobroker) {
    autobroker.forEach((boundary) => {
      graphData.autobrokerMedium.push(boundary.medium);
      graphData.autobrokerNarrow.push(boundary.narrow);
      graphData.autobrokerTarget.push(boundary.target);
      graphData.autobrokerWide.push(boundary.wide);
    });
  }

  if (competitor) {
    competitor.forEach((boundary) => {
      graphData.competitorMedium.push(boundary.medium);
      graphData.competitorNarrow.push(boundary.narrow);
      graphData.competitorTarget.push(boundary.target);
      graphData.competitorWide.push(boundary.wide);
    });
  }
  if (model) {
    model.forEach((boundary) => {
      graphData.modelMedium.push(boundary.medium);
      graphData.modelNarrow.push(boundary.narrow);
      graphData.modelTarget.push(boundary.target);
      graphData.modelWide.push(boundary.wide);
    });
  }

  return graphData;
};

export default mapBoundaries;
