import React, { useCallback, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { IngestionTicket, EditTicket, StockType } from '#/types/Ingestion';
import palette from '#/theme/palettes/main';

interface EditTicketFormProps {
  stock_type: StockType;
  ticket: IngestionTicket;
  setTicket: (t: EditTicket) => void;
  addFile: (file: File) => void;
}

const EditTicketForm: React.FC<EditTicketFormProps> = ({
  stock_type,
  ticket,
  setTicket,
  addFile,
}) => {
  const [edit, setEdit] = useState<EditTicket>(
    { seat: ticket.seat, in_hand: ticket.in_hand_at !== null },
  );

  const onChangeUpdatedSeat = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const updated_seat = Number(event?.target?.value) || null;

    setEdit((e) => {
      const newEdit = { ...e, updated_seat };

      setTicket(newEdit);
      return newEdit;
    });
  }, [setTicket, setEdit]);

  const onChangeInHand = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const checked = event?.target?.checked;
    const in_hand = checked === null || typeof (checked) === 'undefined' ? null : checked;

    setEdit((e) => {
      const newEdit = { ...e, in_hand };

      setTicket(newEdit);
      return newEdit;
    });
  }, [setTicket, setEdit]);

  const onChangeBarcode = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const barcode = event?.target?.value || null;

    setEdit((e) => {
      const newEdit = { ...e, barcode };

      setTicket(newEdit);
      return newEdit;
    });
  }, [setTicket, setEdit]);

  const onChangeLink = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const wallet_link = event?.target?.value || null;

    setEdit((e) => {
      const newEdit = { ...e, wallet_link };

      setTicket(newEdit);
      return newEdit;
    });
  }, [setTicket, setEdit]);

  const onChangePaulbunyanTicketId = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const paulbunyan_ticket_id = Number(event?.target?.value) || null;

    setEdit((e) => {
      const newEdit = { ...e, in_hand: true, paulbunyan_ticket_id };

      setTicket(newEdit);
      return newEdit;
    });
  }, [setTicket, setEdit]);

  const onChangeFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event?.currentTarget?.files[0];
    const pdf_path = newFile.name;

    addFile(newFile);
    setEdit((e) => {
      const newEdit = {
        ...e, pdf_path, in_hand: true, paulbunyan_ticket_id: null as number,
      };

      setTicket(newEdit);
      return newEdit;
    });
  }, [setTicket, setEdit, addFile]);

  return (
    <>
      <HorizonalWrapper>
        <TinyInput
          onChange={onChangeUpdatedSeat}
          placeholder={ticket.seat}
          title="Seat"
          type="number"
        />
        <Checkbox
          checked={edit.in_hand}
          disabled={!!(ticket.s3_path || edit.pdf_path)}
          onChange={onChangeInHand}
          title="In Hand"
          type="checkbox"
        />
        {stock_type !== StockType.WALLET_LINKS && (
          <MediumInput
            onChange={onChangeBarcode}
            placeholder={ticket.barcode || 'Barcode'}
            title="Barcode"
            type="string"
          />
        )}
        {stock_type === StockType.WALLET_LINKS && (
          <MediumInput
            onChange={onChangeLink}
            placeholder={ticket.wallet_link || 'Link'}
            title="Link"
            type="string"
          />
        )}
        <SmallInput
          disabled={!!edit.pdf_path}
          onChange={onChangePaulbunyanTicketId}
          placeholder={ticket.paulbunyan_ticket_id ? String(ticket.paulbunyan_ticket_id) : 'PB Ticket ID'}
          title="Paulbunyan Ticket ID"
          type="number"
          value={edit.paulbunyan_ticket_id || ''}
        />
        <LargeInput
          onChange={onChangeFile}
          type="file"
        />
      </HorizonalWrapper>
    </>
  );
};

const HorizonalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
`;

const Input = styled.input`
  ${({ theme }: { theme: Theme }): string => theme.text4};
  padding: 4px;
  margin-right: 0.7rem;
  border: 1px solid
    ${({ theme }: { theme: Theme }): string => theme.color.border.primary};
  border-radius: 6px;
  transition: all 0.2s ease;
  &:focus {
    outline: none;
    outline-offset: none;
    border-color: ${({ theme }: { theme: Theme }): string => theme.color.border.selected};
  }
`;

const TinyInput = styled(Input as any)`
  width: 1.5rem;
`;

const SmallInput = styled(Input as any)`
  width: 5rem;
`;

const MediumInput = styled(Input as any)`
  width: 7rem;
`;

const LargeInput = styled(Input as any)`
  width: 9rem;
`;

const Checkbox = styled.input`
  height: 1.5rem;
  width: 1.75rem;
  margin-right: .7rem;

  &:checked {
    background-color: ${palette.brand.base};
  }
  &:after {
    background-color: ${palette.brand.base};
  }
`;

export default EditTicketForm;
