import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import PriceLevel from '#/types/PriceLevel';

interface CardProps {
  priceLevel: PriceLevel;
}

const LISTINGS = 'Listings';
const TICKETS = 'Listed Tickets';

const ListingsCard: React.FC<CardProps> = ({ priceLevel }) => {
  const { listingCt, ticketCt } = priceLevel.summary;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{LISTINGS}</Name>
        <Value>{listingCt ?? 0}</Value>
      </Element>
      <Element>
        <Name>{TICKETS}</Name>
        <Value>{ticketCt ?? 0}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default ListingsCard;
