import React, { useCallback } from 'react';
import Action, { EventType } from '../types';
import { PRICING_MODE_OPTIONS, PricingMode } from '#/types/Event';
import SelectForm from '../Forms/SelectForm';
import type { GenericOption } from '#/types/GenericOption';

interface SelectProps {
  handleInputChange: React.Dispatch<Action>;
  label: string;
}

const SelectPricingMode: React.FC<SelectProps> = ({
  handleInputChange,
  label,
}) => {
  const handleChange = useCallback(
    (option: GenericOption<string, PricingMode>) => {
      const { value } = option;

      handleInputChange({ type: EventType.PRICING_MODE, value });
    },
    [handleInputChange],
  );
  const defaultOption = PRICING_MODE_OPTIONS[1];

  return (
    <SelectForm
      defaultValue={defaultOption}
      label={label}
      onChange={handleChange}
      options={PRICING_MODE_OPTIONS}
    />
  );
};

export default SelectPricingMode;
