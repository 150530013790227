import React, { useMemo } from 'react';
import ClientReporting from './ClientReporting';
import ErrorContent from '#/shared/clientReporting/ErrorContent';
import { User } from '#/types/User';

interface Props {
  user?: User;
  errorStatus?: string;
}

const MSG_401 = 'Authenticating...';
const MSG_404 = 'Sorry, we couldn\'t find that page.';
const MSG_500 = 'Sorry, something went wrong.';

const ErrorPage: React.FC<Props> = ({ user, errorStatus = '500' }) => {
  const anyOtherError = useMemo(() => (
    errorStatus && !['401', '404'].includes(errorStatus)
  ), [errorStatus]);

  return (
    <ClientReporting user={user}>
      {errorStatus === '401' && <ErrorContent content={MSG_401} />}
      {errorStatus === '404' && <ErrorContent content={MSG_404} />}
      {anyOtherError && <ErrorContent content={MSG_500} />}
    </ClientReporting>
  );
};

export default ErrorPage;
