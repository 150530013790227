import React, { useCallback } from 'react';
import Action from './types';
import Input, { FormWrapper, Label } from './sharedComponents';
import { PriceExplorationType } from '#/types/Event';

interface InputFormProps {
  initialVal: number | string;
  label: string;
  disabled?: boolean;
  type: PriceExplorationType;
  handleInputChange: React.Dispatch<Action>;
}

const InputForm: React.FC<InputFormProps> = ({
  initialVal,
  label,
  disabled = false,
  type,
  handleInputChange,
}) => {
  const value = initialVal === null ? '' : initialVal;
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = Number(event.target.value);

      handleInputChange({ type, value: newVal });
    },
    [type, handleInputChange],
  );

  return (
    <FormWrapper>
      <Label htmlFor={type}>{label}</Label>
      <Input
        disabled={disabled}
        onChange={handleChange}
        step={0.01}
        type='number'
        value={value}
      />
    </FormWrapper>
  );
};

export default InputForm;
