import { AxiosResponse } from 'axios';
import { Overrides } from '#/types/Override';
import client from './_client';

const getOverrides = async (eventId: number): Promise<Overrides> => {
  const response: AxiosResponse<Overrides> = await client.get(`/proxy/overrides/${eventId}`);

  const rawOverrides = response?.data;

  return rawOverrides;
};

export default getOverrides;
