import React, { useContext } from 'react';
import { Dropdown, Item } from '#/shared/clientReporting/Dropdown';
import { Label } from '#/shared/clientReporting/ListBox';
import { Label as InputLabel } from '#/shared/clientReporting/Inputs';
import { TIME_INTERVAL_OPTIONS, TIME_INTERVAL_LABEL } from '../utils/menuOptions';
import { CollectionSelectionsContext } from '../contexts';

interface Props {
  isLoading?: boolean;
}

const TimeIntervalMenu: React.FC<Props> = ({ isLoading }) => {
  const {
    timeIntervalSelection,
    handleTimeIntervalSelection,
  } = useContext(CollectionSelectionsContext);

  return (
    <>
      <InputLabel isLoading={isLoading}>{TIME_INTERVAL_LABEL}</InputLabel>
      <Dropdown
        aria-label='event-collection-overlaid-metric-selection'
        disabledKeys={TIME_INTERVAL_OPTIONS.filter((o) => o.disabled)?.map((o) => o.key)}
        id='event-collection-overlaid-metric-menu'
        isLoading={isLoading}
        items={TIME_INTERVAL_OPTIONS}
        onSelectionChange={handleTimeIntervalSelection}
        selectedKey={timeIntervalSelection.key}
      >
        {({ key, label }): JSX.Element => (
          <Item
            key={key}
            textValue={label ?? key}
          >
            <Label maxWidth={180}>
              {label ?? key}
            </Label>
          </Item>
        )}
      </Dropdown>
    </>
  );
};

export default TimeIntervalMenu;
