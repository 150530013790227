import { useCallback, useContext } from 'react';
import { isCancel } from 'axios';
import toast from 'react-hot-toast';
import postBulkOverrides from '#/api/postBulkOverrides';
import redirectUnauthorizedToAuth from '#/api/utils/redirectUnauthorizedToAuth';
import type { PostRollupBody } from '#/types/ReportingRollup';
import { CollectionOverridesContext } from '../contexts';
import { ToastId, ToastMsg } from '../constants';

interface Hook {
  submitOverrides: ({ overrides, group }: PostRollupBody) => Promise<unknown>
  clearOverrideInputs: (form?: HTMLFormElement) => void
}

const useSubmitBulkOverrides = (): Hook => {
  const {
    setOverridesSubmission,
    setIsLoadingOverridesSubmission,
    dispatchOverrides,
  } = useContext(CollectionOverridesContext);

  const clearOverrideInputs = useCallback((form?: HTMLFormElement) => {
    dispatchOverrides(null);
    const overridesForm = form ?? document.querySelector('form');

    if (overridesForm)
      overridesForm.reset();
  }, [dispatchOverrides]);

  const submitOverrides = useCallback(async ({ overrides, group }: PostRollupBody) => {
    toast.loading(ToastMsg.LOADING_OVERRIDES, { id: ToastId.OVERRIDES_STATUS });
    setIsLoadingOverridesSubmission(true);
    try {
      const overridesSubmission = await postBulkOverrides({ overrides, group });

      clearOverrideInputs();
      setOverridesSubmission(overridesSubmission);
      toast.success(ToastMsg.SUCCESS_OVERRIDES, { id: ToastId.OVERRIDES_STATUS });
    } catch (err) {
      if (!isCancel(err)) {
        redirectUnauthorizedToAuth(err);
        toast.error(ToastMsg.ERROR_OVERRIDES, { id: ToastId.OVERRIDES_STATUS });
      }
    }
    setIsLoadingOverridesSubmission(false);

    return null;
  }, [setOverridesSubmission, clearOverrideInputs, setIsLoadingOverridesSubmission]);

  return {
    submitOverrides,
    clearOverrideInputs,
  };
};

export { Hook };
export default useSubmitBulkOverrides;
