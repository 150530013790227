import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import {
  GG, ScaleX, ScaleFill, Labels,
} from '@graphique/graphique';
import { GeomBar, Legend } from '@graphique/geom-bar';
import { MarketplaceSummary, EventTxSummary, MarketplaceMixMeasure } from '#/pages/clientReporting/DashboardPage/types';
import { formatGtv, formatLargeNumber } from '#/shared/formatNumberForDisplay';
import { Theme } from '#/shared/graphique';
import MeasureTitle from '#/pages/clientReporting/DashboardPage/components/MeasureTitle';
import LoadingComponent from './LoadingComponent';
import Tooltip from './Tooltip';
import RollupErrorComponent from '../../RollupErrorComponent';
import MissingDataComponent from '../../MissingDataComponent';

interface SummaryBarProps {
  data: MarketplaceSummary[];
  measure: MarketplaceMixMeasure;
  selectedEvent?: EventTxSummary;
  loading?: boolean;
  loadingComponent?: React.ReactNode;
  hasError?: boolean;
  retry?: () => void;
}

const getTickNumbers = (width: number): number => width < 600 ? 3 : 5;

const LEFT_MARGIN = 10;
const RIGHT_MARGIN = 18;
const legendStyles = {
  display: 'flex',
  justifyContent: 'center',
  fontSize: 14,
};
const LABEL = 'Tickets sold';

const SummaryBar: React.FC<SummaryBarProps> = ({
  data,
  measure,
  selectedEvent,
  hasError,
  loading,
  loadingComponent = <LoadingComponent />,
  retry,
}) => {
  const isMoney = useMemo(() => measure === 'cost' || measure === 'revenue', [measure]);
  const formatX = useCallback(({ value }: { value: number }): string => (
    isMoney ? formatGtv(value, false) : formatLargeNumber(value, false)
  ), [isMoney]);
  const barData: MarketplaceSummary[] = (
    useMemo(() => data?.filter((d) => d[measure] > 0), [data, measure])
  );

  if (hasError)
    return <RollupErrorComponent retry={retry} />;

  if (barData?.length === 0 && !loading)
    return <MissingDataComponent label={LABEL} selectedEvent={selectedEvent} />;

  return (
    <Wrapper>
      {loading ? loadingComponent : (
        <GG
          aes={{
            x: (d): number => d[measure],
            y: (): string => '',
            fill: ({ marketplaceDisplay }): string => marketplaceDisplay,
            key: ({ marketplaceDisplay }): string => marketplaceDisplay,
          }}
          data={barData}
          height={140}
          isContainerWidth
          margin={{ top: 0, right: RIGHT_MARGIN, left: LEFT_MARGIN }}
        >
          <GeomBar
            fillOpacity={0.9}
            focusType='individual'
            position='stack'
            stroke='#fff'
            strokeWidth={0.7}
          />
          <ScaleX format={formatX} numTicks={getTickNumbers} />
          <ScaleFill values={barData?.map(({ marketplaceColor }) => marketplaceColor)} />
          <Labels y={<MeasureTitle event={selectedEvent} measure={measure} />} />
          <Tooltip data={barData} isMoney={isMoney} measure={measure} />
          <Legend orientation='horizontal' style={legendStyles} />
          <Theme axis={{ showAxisLines: false }} grid={{ stroke: '#ddd' }} />
        </GG>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: -5px;
  line-height: 1.2;
  letter-spacing: 0;
`;

export default SummaryBar;
