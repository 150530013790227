import { DateTime } from 'luxon';
import {
  APIErrorStatus,
  ErrorStatus,
} from '#/types/Errors';

const mapErrorStatus = (status: APIErrorStatus): ErrorStatus => {
  return {
    ...status,
    userId: status.user_id,
    userName: status.user_name,
    createdAt: DateTime.fromISO(status.created_at, { zone: 'utc' }),
  };
};

export default mapErrorStatus;
