import { OverrideType } from '#/types/Override';

const DEFAULT_INCREMENT = 0.01;

const LABELS = {
  [OverrideType.ANCHOR_BIAS]: 'Anchor Bias',
  [OverrideType.ANCHOR_PROBABILITY]: 'Anchor Probability',
  [OverrideType.BIAS]: 'Bias',
  [OverrideType.MAX_OFFSET]: 'Max Offset',
  [OverrideType.MIN_OFFSET]: 'Minimum Offset',
  [OverrideType.SPREAD]: 'Spread',
  [OverrideType.LOG_EXPECTED_VALUE_SLOPE]: 'Log Expected Value Slope',
  [OverrideType.LOG_EXPECTED_VALUE_CURVATURE]: 'Log Expected Value Curvature',
  [OverrideType.ADDITIONAL_TICKETS]: 'Additional Tickets',
  [OverrideType.AGGRESSIVENESS]: 'Aggressiveness',
  [OverrideType.SELLOUT_BY_SELLDOWN]: 'Sellout By Selldown',
  [OverrideType.MAX_ELASTICITY]: 'Max Elasticity',
  [OverrideType.PIVOT_PRIOR]: 'Pivot Prior',
  [OverrideType.DEMAND_PRIOR]: 'Demand Prior',
  [OverrideType.ONSALE_DEMAND_PRIOR]: 'Onsale Demand Prior',
  [OverrideType.LOG_QUANTITY_SLOPE]: 'Log Quantity Slope',
  [OverrideType.IS_ODD_QUANTITY_SLOPE]: 'Is Odd Quantity Slope',
};

const MINS = {
  [OverrideType.ANCHOR_BIAS]: 0,
  [OverrideType.ANCHOR_PROBABILITY]: 0,
  [OverrideType.BIAS]: -0.5,
  [OverrideType.MAX_OFFSET]: 0,
  [OverrideType.MIN_OFFSET]: 0,
  [OverrideType.SPREAD]: 0,
  [OverrideType.LOG_EXPECTED_VALUE_SLOPE]: -0.25,
  [OverrideType.LOG_EXPECTED_VALUE_CURVATURE]: -0.25,
  [OverrideType.ADDITIONAL_TICKETS]: 0,
  [OverrideType.AGGRESSIVENESS]: -1,
  [OverrideType.SELLOUT_BY_SELLDOWN]: 0,
  [OverrideType.MAX_ELASTICITY]: -2,
  [OverrideType.PIVOT_PRIOR]: -1,
  [OverrideType.DEMAND_PRIOR]: 7,
  [OverrideType.ONSALE_DEMAND_PRIOR]: -1,
  [OverrideType.LOG_QUANTITY_SLOPE]: -0.5,
  [OverrideType.IS_ODD_QUANTITY_SLOPE]: -0.25,
};

const MAXS = {
  [OverrideType.ANCHOR_BIAS]: 1.5,
  [OverrideType.ANCHOR_PROBABILITY]: 0.5,
  [OverrideType.BIAS]: 0.5,
  [OverrideType.MAX_OFFSET]: 3,
  [OverrideType.MIN_OFFSET]: 3,
  [OverrideType.SPREAD]: 0.5,
  [OverrideType.LOG_EXPECTED_VALUE_SLOPE]: 0.25,
  [OverrideType.LOG_EXPECTED_VALUE_CURVATURE]: 0.25,
  [OverrideType.ADDITIONAL_TICKETS]: 100,
  [OverrideType.AGGRESSIVENESS]: 2,
  [OverrideType.SELLOUT_BY_SELLDOWN]: 1,
  [OverrideType.MAX_ELASTICITY]: -0.5,
  [OverrideType.PIVOT_PRIOR]: 1,
  [OverrideType.DEMAND_PRIOR]: 12,
  [OverrideType.ONSALE_DEMAND_PRIOR]: 1,
  [OverrideType.LOG_QUANTITY_SLOPE]: 0.5,
  [OverrideType.IS_ODD_QUANTITY_SLOPE]: 0.25,
};

export {
  DEFAULT_INCREMENT, LABELS, MINS, MAXS,
};
