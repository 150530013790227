import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import redirectUnauthorizedToAuth from './utils/redirectUnauthorizedToAuth';
import {
  MarketplaceTransactionFilters, MarketplaceTransactionsResponse,
} from '#/types/MarketplaceTransaction';
import formatMarketplaceTransaction from './utils/formatMarketplaceTransaction';
import { ListingSubset } from '#/types/Preview';
import { FormattedPricingChartDatum } from '#/pages/ViewPage/components/Pricing/types';

const DEFAULT_FILTERS: Omit<MarketplaceTransactionFilters, 'autobrokerEventId'> = {
  // used as sample size since order returned is random
  perPage: 300,
  positiveAllInPrice: true,
  sortBy: 'random',
  marketplace: 'seatgeek',
  state: ['purchased', 'fulfilled'],
};

const endpoint = '/proxy/marketplace_transactions';
const getListingSubsetMarketplaceTransactions = (
  autobrokerEventId: number, listingSubset: ListingSubset,
): Promise<AxiosResponse<MarketplaceTransactionsResponse>> => (
  client.get(
    endpoint,
    {
      params: {
        ...DEFAULT_FILTERS,
        listingSubset,
        autobrokerEventId,
      },
    },
  )
);

interface GetMarketplaceTransactionProps {
  autobrokerEventId: number;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setHasError: (value: React.SetStateAction<boolean>) => void;
  setMarketplaceTransactions: React.Dispatch<
  React.SetStateAction<FormattedPricingChartDatum[]>
  >;
}

const getMarketplaceTransactions = async ({
  autobrokerEventId,
  setIsLoading,
  setHasError,
  setMarketplaceTransactions,
}: GetMarketplaceTransactionProps): Promise<void> => {
  setIsLoading(true);
  setHasError(false);

  try {
    const responses = await Promise.all([
      getListingSubsetMarketplaceTransactions(autobrokerEventId, ListingSubset.AUTOBROKER),
      getListingSubsetMarketplaceTransactions(autobrokerEventId, ListingSubset.COMPETITOR),
    ]);

    const formattedMarketplaceTransactions = responses
      .flatMap((r) => r.data.marketplaceTransactions)
      ?.map(formatMarketplaceTransaction)
      // ensure we exclude any transactions with $0 prices
      ?.filter((t) => t.allInPrice > 0);

    setMarketplaceTransactions(formattedMarketplaceTransactions);
  } catch (err) {
    console.error(err);
    setHasError(true);
    redirectUnauthorizedToAuth(err);
  }
  setIsLoading(false);
};

export default getMarketplaceTransactions;
