import { useEffect, useState, useCallback } from 'react';
import getMarketplaceTransactions from '#/api/getMarketplaceTransactions';
import { FormattedPricingChartDatum } from './ViewPage/components/Pricing/types';

interface UseFetchMarketplaceTransactions {
  isLoading: boolean;
  hasError: boolean;
  marketplaceTransactions: FormattedPricingChartDatum[];
  fetchMarketplaceTransactions: () => void;
}

const useFetchMarketplaceTransactions = (
  autobrokerEventId: number,
): UseFetchMarketplaceTransactions => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [
    marketplaceTransactions, setMarketplaceTransactions,
  ] = useState<FormattedPricingChartDatum[]>(undefined);

  const fetchMarketplaceTransactions = useCallback(() => {
    if (autobrokerEventId) {
      getMarketplaceTransactions({
        autobrokerEventId,
        setIsLoading,
        setHasError,
        setMarketplaceTransactions,
      });
    }
  }, [autobrokerEventId]);

  useEffect(() => {
    fetchMarketplaceTransactions();
  }, [fetchMarketplaceTransactions]);

  return {
    isLoading,
    hasError,
    marketplaceTransactions,
    fetchMarketplaceTransactions,
  };
};

export default useFetchMarketplaceTransactions;
export { UseFetchMarketplaceTransactions };
