import React, {
  useEffect,
  CSSProperties,
  forwardRef,
  RefObject,
} from 'react';
import styled from 'styled-components';
import {
  useOverlay, DismissButton, FocusScope, mergeProps,
} from 'react-aria';

interface PopoverProps {
  popoverRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  triggerRef?: React.RefObject<HTMLButtonElement>;
  style?: CSSProperties;
}

const Wrapper = styled.div`
  z-index: 10000;
  position: absolute;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-top: 2px;
  box-shadow: 0 2px 8px #ccc;
  background: white;
`;

const Popover = forwardRef((props: PopoverProps, ref: RefObject<HTMLDivElement>): JSX.Element => {
  const {
    popoverRef = ref,
    isOpen,
    onClose,
    children,
    ...otherProps
  } = props;

  // close menu when window is resized
  useEffect(() => {
    window.addEventListener('resize', onClose);

    return (): void => window.removeEventListener('resize', onClose);
  }, [onClose]);

  // Handle events that should cause the popup to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: true,
    },
    popoverRef,
  );

  // Add a hidden <DismissButton> component at the end of the popover
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Wrapper {...mergeProps(overlayProps, otherProps)} ref={popoverRef}>
        {children}
        <DismissButton onDismiss={onClose} />
      </Wrapper>
    </FocusScope>
  );
});

export default Popover;
