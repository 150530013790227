import { DateTime } from 'luxon';
import { InventorySourceType, Perspective } from '#/types/ReportingRollup';

interface DailyTransaction {
  autobrokerEventId: number;
  title: string;
  eventStartsAt: DateTime;
  transactionDate?: Date;
  transactionDateString: string;
  marketplace: string | null;
  cost: number;
  revenue: number;
  ticketsSold: number;
  totalSales: number;
}

interface SparklineDatum {
  transactionDate: Date;
  measure: string;
  value: number;
}

interface EventTxSummary {
  seatgeekEventId?: number;
  event: string;
  venue?: string;
  eventDate: DateTime;
  ticketsSold: number;
  managedInventory: number;
  revenue: number;
  liftOfSold?: number;
  liftWithSpoilage?: number;
  cost: number;
  take?: number;
  isSelected?: boolean;
  events?: number;
  isEventInFuture?: boolean;
}

enum EventTxSummaryCols {
  SG_EVENT_ID = 'seatgeekEventId',
  EVENT = 'event',
  NUM_EVENTS = 'events',
  EVENT_DATE = 'eventDate',
  MANAGED_INVENTORY = 'managedInventory',
  TICKETS_SOLD = 'ticketsSold',
  REVENUE = 'revenue',
  LIFT_OF_SOLD = 'liftOfSold',
  LIFT_WITH_SPOILAGE = 'liftWithSpoilage',
  COST = 'cost',
  TAKE = 'take',
}

type TransactionMetric = |
'ticketsSold'
| 'managedInventory'
| 'revenue'
| 'liftOfSold'
| 'liftWithSpoilage'
| 'cost'
| 'take'

interface EventTotals extends Partial<Pick<EventTxSummary, TransactionMetric>> {
  event?: React.ReactNode;
  events?: number;
}

type Marketplace = 'seatgeek' | 'stubhub' | 'vivid seats' | 'ticketmaster' | 'gametime' | 'tickpick' | 'ticketnetwork' | 'viagogo' | 'ticket evolution' | 'gotickets' | 'box office';

type MarketplaceValue = {
  displayName: string;
  color: string;
};

type MarketplaceInfo = Record<Marketplace, MarketplaceValue>;

interface MarketplaceSummary {
  cost: number;
  revenue: number;
  ticketsSold: number;
  totalSales: number;
  managedInventory: number;
  take?: number;
  marketplace: string;
  marketplaceDisplay: string;
  marketplaceColor: string;
}

type PossiblyNegativeMetric = 'take' | 'liftOfSold' | 'liftWithSpoilage';
type MarketplaceMixMeasure = Exclude<TransactionMetric, PossiblyNegativeMetric>;

enum DateBounds {
  EVENT_FROM = 'eventFrom',
  EVENT_TO = 'eventTo',
  SALES_FROM = 'salesFrom',
  SALES_TO = 'salesTo',
}

interface DealOrTermFilter {
  name?: string;
  displayName?: string;
}

interface QueryParams {
  eventId?: string[];
  deal?: string;
  dealTerm?: string;
  fromEventDate?: string;
  toEventDate?: string;
  perspective?: Perspective;
  inventorySourceType?: InventorySourceType;
  bypassCache?: boolean;
}

export {
  DailyTransaction,
  SparklineDatum,
  MarketplaceMixMeasure,
  EventTxSummary,
  EventTxSummaryCols,
  EventTotals,
  Marketplace,
  MarketplaceInfo,
  MarketplaceSummary,
  DateBounds,
  DealOrTermFilter,
  QueryParams,
};
