import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Tooltip as GGTooltip, TooltipContainer, TooltipContent } from '@graphique/graphique';
import { formatTooltipX } from '../../utils/dataFormatting';
import { TOOLTIP_POSITION, excludeIntersection } from '../utils';
import { formatNumber, formatPercentage } from '#/shared/formatNumberForDisplay';
import { ExpectedValueBinSnapshotFormatted as EvSnapshot } from '#/types/SelldownByExpectedValue';

interface Props {
  showAsTotal?: boolean;
}

const Tooltip: React.FC<Props> = ({ showAsTotal }) => {
  const formatTooltipY = useCallback((v: number) => (
    showAsTotal
      ? formatNumber(v, 1)
      : formatPercentage(v, 1)
  ), [showAsTotal]);

  const renderContent = useCallback((value?: TooltipContent<EvSnapshot>[]) => {
    const groupValues = excludeIntersection(value);

    return (
      groupValues?.length > 0 ? (
        <TooltipContainer>
          <Wrapper>
            <XValue>{groupValues[0].formattedX}</XValue>
            {groupValues.map(({
              group, mark, formattedY,
            }) => (
              <GroupContent key={String(group)}>
                <GroupIndicator>
                  {mark}
                  <GroupLabel>{String(group)}</GroupLabel>
                </GroupIndicator>
                <YValue>{formattedY}</YValue>
              </GroupContent>
            ))}
          </Wrapper>
        </TooltipContainer>
      ) : null
    );
  }, []);

  return (
    <GGTooltip
      content={renderContent}
      position={TOOLTIP_POSITION}
      xFormat={formatTooltipX}
      yFormat={formatTooltipY}
    />
  );
};

const GroupContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 0.1rem 0;
  border-bottom: 1px solid #f3f3f3;
`;

const XValue = styled.div`
  font-size: 0.85rem;
  margin: 0.2rem 0;
  padding-bottom: 0.1rem;
  color: #555;
  border-bottom: 1px solid #f3f3f3;
  letter-spacing: 0;
  min-width: 9.2rem;
`;

const YValue = styled.div`
  font-weight: 500;
  font-size: 0.9rem;
`;

const GroupIndicator = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  padding: 0.1rem 0;
`;

const GroupLabel = styled.div`
  font-size: 0.8rem;
  margin-top: 0.1rem;
`;

const Wrapper = styled.div`
  padding: 0.1rem 0.2rem 0 0.2rem;
`;

export default Tooltip;
