import {
  useState, useCallback, useContext, useMemo,
} from 'react';
import { merge } from 'lodash';
import postOverrides from '#/api/postOverrides';
import { Settings } from '#/types/Override';
import { ActiveEventContext } from '../../../../contexts/ActiveEvent';
import formatApiError from '#/api/utils/formatApiError';

const NO_ACTIVE_EVENT_ERROR = 'You need to be configuring an event to make changes.';

type Hook = (
  canonicalSettings: Settings,
  fetchPreview: () => Promise<void>,
  fetchSnapshots: () => Promise<void>,
  settings: Settings,
  setCanonicalSettings: (newSettings: Settings) => void,
) => {
  saveError: string;
  saveInProgress: boolean;
  saveSettings: (notes?: string) => void;
};

const useSaveSettings: Hook = (
  canonicalSettings,
  fetchPreview,
  fetchSnapshots,
  settings,
  setCanonicalSettings,
) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const eventId = useMemo(() => activeEvent?.id, [activeEvent]);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<string>('');
  const saveSettings = useCallback(async (notes? : string) => {
    if (!activeEvent) {
      setSaveError(NO_ACTIVE_EVENT_ERROR);
      return;
    }
    setSaveInProgress(true);
    setSaveError('');
    try {
      const newSettings = await postOverrides(
        eventId,
        canonicalSettings,
        settings,
        notes,
      );
      const updatedSettings = merge(canonicalSettings, newSettings);

      setCanonicalSettings(updatedSettings);
      fetchSnapshots();
      fetchPreview();
    } catch (err) {
      const errorString = formatApiError(err);

      setSaveError(errorString);
    }

    setSaveInProgress(false);
  }, [
    activeEvent,
    eventId,
    canonicalSettings,
    settings,
    setCanonicalSettings,
    fetchSnapshots,
    fetchPreview,
  ]);

  return {
    saveError,
    saveInProgress,
    saveSettings,
  };
};

export default useSaveSettings;
