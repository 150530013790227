import React, { useCallback } from 'react';
import styled from 'styled-components';

import HardStock from '#images/HardStock.svg';
import Cost from '#images/Cost.svg';
import Microscope from '#images/Microscope.svg';
import Event, { ValueField } from '#/types/Event';

const SUMMARY = 'Summary';
const OPACITY = 0.7;
const SHOW_BREAKDOWN = 'Show Breakdown Table';

interface SummaryHeaderProps {
  event?: Event;
  valueField: ValueField;
  setValueField: (valueField: ValueField) => void;
  showBreakdown: () => void;
}

const SummaryHeader: React.FC<SummaryHeaderProps> = ({
  event, valueField, setValueField, showBreakdown,
}) => {
  const ingestedFaceValue = event?.summary?.ingestedFaceValue;

  const onClickCost = useCallback(() => setValueField(ValueField.COST), [setValueField]);
  const onClickFace = useCallback(() => setValueField(ValueField.FACE), [setValueField]);

  return (
    <Header>
      <Title>
        {SUMMARY}
      </Title>
      <Right>
        <Button onClick={showBreakdown} title={SHOW_BREAKDOWN}>
          <MicroscopeImg />
        </Button>
      </Right>
      {ingestedFaceValue > 0 && (
        <>
          <Right>
            <Button onClick={onClickCost} title={`View ${ValueField.COST}`}>
              <CostImg style={{ opacity: valueField === ValueField.COST ? OPACITY : 1.0 }} />
            </Button>
          </Right>
          <Right>
            <Button onClick={onClickFace} title={`View ${ValueField.FACE}`}>
              <FaceValueImg style={{ opacity: valueField === ValueField.FACE ? OPACITY : 1.0 }} />
            </Button>
          </Right>
        </>
      )}
    </Header>
  );
};

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const Header = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  padding-top: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Button = styled.div`
  cursor: pointer;
  padding-right: 0.75rem;
`;

const Right = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  width: auto;
  text-align: right;
`;

const FaceValueImg = styled.img`
  content: url(${HardStock});
  height: 24px;
  padding-top: 5px;
`;

const CostImg = styled.img`
  content: url(${Cost});
  height: 24px;
  padding-top: 5px;
`;

const MicroscopeImg = styled.img`
  content: url(${Microscope});
  height: 24px;
  padding-top: 5px;
`;

export default SummaryHeader;
