/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, ChangeEvent } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import {
  EventRebuildPartitionApiPost,
  ONSALE_PRICING_MODEL_OPTIONS,
  PRICING_MODE_OPTIONS,
  PricingMode,
  SECTION_SUBSET_TYPE_OPTIONS,
  SectionSubsetType,
  TrainingSetType,
} from '#/types/Event';
import { modalMenuProps } from '#/shared/modalComponents';
import type { GenericOption } from '#/types/GenericOption';

const SEATGEEK_EVENT_ID = 'SG ID';
const PRICING_MODE = 'Pricing Mode';
const SECTION_SUBSET_TYPE = 'Section Subset';
const SECTION_SUBSET_SECTIONS = 'Sections';
const ONSALE_PRICING_MODEL = 'Onsale Model';
const selectStyle = { width: '8.5rem', height: '1.5rem' };

interface EventPartitionFormProps {
  partition: EventRebuildPartitionApiPost;
  setPartition: (partition: EventRebuildPartitionApiPost) => void;
}

const EventPartitionForm: React.FC<EventPartitionFormProps> = ({
  partition,
  setPartition,
}) => {
  const onChangeSeatgeekEventId = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const seatgeek_event_id = Number(event?.target?.value) || null;

    setPartition({ ...partition, seatgeek_event_id });
  }, [setPartition, partition]);

  const onChangePricingMode = useCallback((event: GenericOption<string, PricingMode>) => {
    const pricing_mode = event?.value || null;

    setPartition({ ...partition, pricing_mode });
  }, [setPartition, partition]);

  const onChangeSectionSubsetType = useCallback(
    (event: GenericOption<string, SectionSubsetType>) => {
      const type = event?.value || null;

      setPartition({ ...partition, section_subset: { ...partition.section_subset, type } });
    }, [setPartition, partition],
  );

  const onChangeSectionSubsetSections = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const sectionsRaw: string = event?.target?.value || '';
    const sections = sectionsRaw.split(',').map((s) => s.trim()).filter((s) => s.length > 0);

    setPartition({ ...partition, section_subset: { ...partition.section_subset, sections } });
  }, [setPartition, partition]);

  const onChangeOnsalePricingModel = useCallback(
    (event: GenericOption<string, TrainingSetType>) => {
      const onsale_pricing_model = event?.value || null;

      setPartition({ ...partition, onsale_pricing_model });
    }, [setPartition, partition],
  );

  return (
    <>
      <HorizonalWrapper>
        <InnerWrapper>
          {partition.key === 0 && <div>{SEATGEEK_EVENT_ID}</div>}
          <TinyInput
            onChange={onChangeSeatgeekEventId}
            title="SeatGeek Event ID"
            type="number"
            value={partition.seatgeek_event_id}
          />
        </InnerWrapper>
        <InnerWrapper>
          {partition.key === 0 && <div>{PRICING_MODE}</div>}
          <div style={selectStyle}>
            <Select
              defaultValue={
                PRICING_MODE_OPTIONS.find((s) => s.value === partition.pricing_mode)
              }
              onChange={onChangePricingMode}
              options={PRICING_MODE_OPTIONS}
              {...modalMenuProps}
            />
          </div>
        </InnerWrapper>
        <InnerWrapper>
          {partition.key === 0 && <div>{SECTION_SUBSET_TYPE}</div>}
          <div style={selectStyle}>
            <Select
              defaultValue={
                SECTION_SUBSET_TYPE_OPTIONS.find((s) => s.value === partition.section_subset.type)
              }
              onChange={onChangeSectionSubsetType}
              options={SECTION_SUBSET_TYPE_OPTIONS}
              {...modalMenuProps}
            />
          </div>
        </InnerWrapper>
        <InnerWrapper>
          {partition.key === 0 && <div>{SECTION_SUBSET_SECTIONS}</div>}
          <MediumInput
            defaultValue={partition.section_subset.sections.join(',')}
            onChange={onChangeSectionSubsetSections}
            title="Sections"
            type="text"
          />
        </InnerWrapper>
        <InnerWrapper>
          {partition.key === 0 && <div>{ONSALE_PRICING_MODEL}</div>}
          <div style={selectStyle}>
            <Select
              defaultValue={
                ONSALE_PRICING_MODEL_OPTIONS.find((s) => s.value === partition.onsale_pricing_model)
              }
              isClearable
              onChange={onChangeOnsalePricingModel}
              options={ONSALE_PRICING_MODEL_OPTIONS}
              {...modalMenuProps}
            />
          </div>
        </InnerWrapper>
      </HorizonalWrapper>
    </>
  );
};

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorizonalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

const Input = styled.input`
  ${({ theme }: { theme: Theme }): string => theme.text4};
  padding: 4px;
  border: 1px solid
    ${({ theme }: { theme: Theme }): string => theme.color.border.primary};
  border-radius: 6px;
  transition: all 0.2s ease;
  &:focus {
    outline: none;
    outline-offset: none;
    border-color: ${({ theme }: { theme: Theme }): string => theme.color.border.selected};
  }
`;

const TinyInput = styled(Input as any)`
  max-width: 4rem;
  height: 1.8rem;
`;

const MediumInput = styled(Input as any)`
  max-width: 7rem;
  height: 1.8rem;
`;

export default EventPartitionForm;
