import styled from 'styled-components';
import Cancelled from '#images/Cancelled.svg';
import Edit from '#images/Edit.svg';
import Notes from '#images/Notes.svg';
import Refresh from '#images/Refresh.svg';
import Copy from '#images/Copy.svg';
import Error from '#images/Error.svg';
import Vip from '#images/Vip.svg';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  padding-bottom: 1.25rem;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;

const Header = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  padding-top: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
`;

const Right = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  width: auto;
  text-align: right;
`;

const Button = styled.div`
  cursor: pointer;
  padding-right: 0.75rem;
`;

const CancelledImg = styled.img`
  content: url(${Cancelled});
  height: 24px;
  padding-top: 5px;
`;

const EditImg = styled.img`
  content: url(${Edit});
  height: 25px;
  padding-top: 4px;
  padding-left: 5px;
`;

const NotesImg = styled.img`
  content: url(${Notes});
  height: 25px;
  padding-top: 7px;
`;

const RefreshImg = styled.img`
  content: url(${Refresh});
  height: 25px;
  padding-top: 7px;
`;

const CopyImg = styled.img`
  content: url(${Copy});
  height: 25px;
  padding-left: 5px;
`;

const ErrorImg = styled.img`
  content: url(${Error});
  height: 24px;
  padding-top: 5px;
`;

const VipImg = styled.img`
  content: url(${Vip});
  height: 25px;
  padding-top: 5px;
`;

export {
  Wrapper, Section, Header, Title, Right, Button,
  CancelledImg, CopyImg, EditImg, ErrorImg, NotesImg, RefreshImg, VipImg,
};
