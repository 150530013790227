import React, { useCallback } from 'react';
import styled from 'styled-components';

import { SmallTag } from '#/shared/Card';
import palette from '#/theme/palettes/main';
import {
  Header, Title, Right, Button, CancelledImg, EditImg,
} from '#/shared/Detail';
import PriceLevel from '#/types/PriceLevel';

interface PriceLevelDetailHeaderProps {
  priceLevel: PriceLevel;
  setOverridePriceLevelModal: (priceLevels: PriceLevel[]) => void;
  closeDetail: () => void;
  closeable: boolean;
  canEdit: boolean;
}

const PriceLevelDetailHeader: React.FC<PriceLevelDetailHeaderProps> = ({
  priceLevel,
  setOverridePriceLevelModal,
  closeDetail,
  closeable,
  canEdit,
}) => {
  const { id, updatedAt } = priceLevel;

  const openOverrideModal = useCallback(() => {
    setOverridePriceLevelModal([priceLevel]);
  }, [setOverridePriceLevelModal, priceLevel]);

  return (
    <Header>
      <Title>
        {`Price Level ${id}`}
      </Title>
      {updatedAt && (
        <Right>
          <BlueTag title={`updated ${updatedAt.toRelative()}`}>
            {'Updated ' + updatedAt.toRelative()}
          </BlueTag>
        </Right>
      )}
      {canEdit && (
        <Right>
          <Button onClick={openOverrideModal} title="edit price level">
            <EditImg />
          </Button>
        </Right>
      )}
      {closeable && (
        <Right>
          <Button onClick={closeDetail} title="close price level detail">
            <CancelledImg />
          </Button>
        </Right>
      )}
    </Header>
  );
};

const BlueTag = styled(SmallTag as any)`
  background-color: ${palette.blue.light24};
`;

export default PriceLevelDetailHeader;
