import React, {
  useContext,
  useCallback,
  useMemo,
  ChangeEvent,
} from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { HEADER_HEIGHT } from '#/shared/clientReporting/Header';
import Pill from '#/shared/clientReporting/Pill';
import { MIN_DATE } from '#/shared/clientReporting/utils';
import { TextInput, Label } from '#/shared/clientReporting/Inputs';
import ReportingContext from '#/pages/clientReporting/ReportingContext';
import { asUndefined, formatEventLabel, createQueryParams } from '#/pages/clientReporting/DashboardPage/utils';
import { DateBounds } from '#/pages/clientReporting/DashboardPage/types';
import DataExport from '#/pages/clientReporting/DashboardPage/components/DataExport';
import DealFilters from '#/pages/clientReporting/DashboardPage/components/DealFilters';
import SelectInventoryType from '#/pages/clientReporting/DashboardPage/components/SelectInventoryType';

const FILTER_CONTAINER_HEIGHT = '3.1rem';
const SHORT_EVENTS_LABEL = 'Events:';
const EVENTS_LABEL = 'Events between:';

const FilterContainer: React.FC = () => {
  const {
    stakeholder,
    isNarrow,
    isExtraNarrow,
    reportingSelection,
    setReportingSelection,
    queryParams,
  } = useContext(ReportingContext);

  const navigate = useNavigate();

  const eventsLabel = useMemo(() => (
    isNarrow ? SHORT_EVENTS_LABEL : EVENTS_LABEL
  ), [isNarrow]);

  const clearSelectedEvent = useCallback(() => {
    setReportingSelection((prev) => ({
      ...prev,
      selectedEvent: undefined,
    }));
    navigate({
      search: createQueryParams(
        queryParams,
        { eventId: undefined },
      ),
    });
  }, [setReportingSelection, navigate, queryParams]);

  const handleDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (name === DateBounds.EVENT_FROM) {
      const selectedEventBefore = (
        reportingSelection?.selectedEvent?.eventDate < DateTime.fromSQL(value)
      );

      if (selectedEventBefore) {
        setReportingSelection((prev) => ({
          ...prev,
          selectedEvent: undefined,
        }));
      }

      navigate({
        search: createQueryParams(
          queryParams,
          {
            fromEventDate: value || MIN_DATE,
            eventId: selectedEventBefore ? undefined : queryParams?.eventId,
          },
        ),
      });
    }

    if (name === DateBounds.EVENT_TO) {
      const selectedEventAfter = (
        reportingSelection?.selectedEvent?.eventDate > DateTime.fromSQL(value)
      );

      if (selectedEventAfter) {
        setReportingSelection((prev) => ({
          ...prev,
          selectedEvent: undefined,
        }));
      }

      navigate({
        search: createQueryParams(
          queryParams,
          {
            toEventDate: asUndefined(value),
            eventId: selectedEventAfter ? undefined : queryParams?.eventId,
          },
        ),
      });
    }
  }, [navigate, queryParams, reportingSelection, setReportingSelection]);

  return (
    <Container>
      <FiltersWrapper>
        <Filters>
          <EventDatesWrapper>
            <Label>{eventsLabel}</Label>
            <InputWrapper>
              <DateInput
                $isActive={queryParams?.fromEventDate && queryParams.fromEventDate !== MIN_DATE}
                height='1.4rem'
                id={DateBounds.EVENT_FROM}
                max={queryParams?.toEventDate}
                min={MIN_DATE}
                name={DateBounds.EVENT_FROM}
                onChange={handleDateChange}
                type="date"
                value={(queryParams.fromEventDate === MIN_DATE) ? '' : queryParams?.fromEventDate}
              />
            </InputWrapper>
            <InputWrapper>
              <DateInput
                $isActive={!!queryParams?.toEventDate}
                height='1.4rem'
                id={DateBounds.EVENT_TO}
                min={queryParams?.fromEventDate ?? MIN_DATE}
                name={DateBounds.EVENT_TO}
                onChange={handleDateChange}
                type="date"
                value={queryParams?.toEventDate ?? ''}
              />
            </InputWrapper>
          </EventDatesWrapper>
          {stakeholder && stakeholder.inventorySourceTypes.length > 1 && (
            <SelectInventoryType types={stakeholder.inventorySourceTypes} />
          )}
          {
            stakeholder && (
              <DealFilters stakeholder={stakeholder.value} />
            )
          }
          {reportingSelection?.selectedEvent && (
            <FilterWrapper>
              <Pill
                onClose={clearSelectedEvent}
                title={formatEventLabel(reportingSelection.selectedEvent)}
              >
                {reportingSelection.selectedEvent.event}
              </Pill>
            </FilterWrapper>
          )}
        </Filters>
        {!isExtraNarrow && <DataExport />}
      </FiltersWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 1000;
  top: ${HEADER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0.0625rem 0px;
  width: 100%;
  height: ${FILTER_CONTAINER_HEIGHT};
  letter-spacing: 0;
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1340px;
  padding: 0 0.8rem;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;
`;

const EventDatesWrapper = styled.div`
  display: flex;
  align-items: center; 
`;

const FilterWrapper = styled.div`
  margin-left: 0.3rem;
  border-left: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}`};
  padding: 0.3rem 0.3rem 0.3rem 0.6rem;
`;

interface DateInputProps {
  $isActive?: boolean;
}

const DateInput = (styled(TextInput as any) as any)<DateInputProps>`
  background: ${({ $isActive }: DateInputProps): string => $isActive ? '#111' : undefined};
  color: ${({ $isActive }: DateInputProps): string => $isActive ? '#eee' : undefined};
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: ${({ $isActive }: DateInputProps): string => $isActive ? 'invert(100%)' : undefined};;
  }
`;

const InputWrapper = styled.div`
  margin-right: 0.3rem;
`;

export default FilterContainer;
export { FILTER_CONTAINER_HEIGHT, DateInput, type DateInputProps };
