import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { FormattedEventError } from '#/types/Errors';
import generateColumns from './utils/generateColumns';
import tableStyles from '#/shared/tableStyles';

interface ErrorsTableProps {
  errors: FormattedEventError[];
}

const ErrorsTable: React.FC<ErrorsTableProps> = ({ errors }) => {
  const columns = useMemo(() => generateColumns(errors), [errors]);

  const navigateToEvent = useCallback((row: FormattedEventError) => window.open(`/view/${row.autobrokerEventId}#Troubleshooting`, '_blank'), []);

  return (
    <TableContainer>
      <DataTable
        columns={columns}
        customStyles={tableStyles}
        data={errors}
        defaultSortFieldId='eventStartsAt'
        fixedHeader
        fixedHeaderScrollHeight='calc(76vh - 54px)'
        highlightOnHover
        onRowClicked={navigateToEvent}
        pointerOnHover
        responsive
        striped
      />
    </TableContainer>
  );
};

const TableContainer = styled.div`
  margin: 12px 12px 0 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgb(36 63 97 / 16%), 0px 1px 4px rgb(36 63 97 / 10%);
`;

export { default as EventLabel } from './EventLabel';
export { default as ValueLabel } from './ValueLabel';
export default ErrorsTable;
