import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { CollectionOverridesContext } from '../../contexts';
import { SegmentedControl, SegmentedGroup } from '#/shared/clientReporting/web-platform-components/Controls';
import { OverrideOperation } from '#/types/ReportingRollup';
import { startCase } from 'lodash';

interface Props {
  isLoading?: boolean
  handleReset: () => void
}

const SELECTABLE_OVERRIDE_MODES = Object.values(OverrideOperation)
  .filter((v) => v !== OverrideOperation.CLEAR);

const OverrideModeToggle: React.FC<Props> = ({ isLoading, handleReset }) => {
  const { overrideMode, setOverrideMode } = useContext(CollectionOverridesContext);

  const handleOverrideModeChange = useCallback((v: OverrideOperation) => {
    setOverrideMode(v);
    handleReset();
  }, [setOverrideMode, handleReset]);

  return (
    <ToggleWrapper>
      <Toggle isLoading={isLoading}>
        <SegmentedGroup
          name='overrideMode'
          onChange={handleOverrideModeChange}
          value={overrideMode}
        >
          {SELECTABLE_OVERRIDE_MODES.map((mode) => (
            <SegmentedControl
              disabled={isLoading}
              isSmall
              key={mode}
              value={mode}
            >
              {startCase(mode)}
            </SegmentedControl>
          ))}
        </SegmentedGroup>
      </Toggle>
    </ToggleWrapper>
  );
};

const ToggleWrapper = styled.div`
  padding: 0 1rem;
`;

interface ToggleProps {
  isLoading?: boolean
}

const Toggle = styled.div<ToggleProps>`
  padding: 0.55rem 0;
  border-bottom: 1px solid #dfdfdf;
  opacity: ${({ isLoading }): number => isLoading ? 0.3 : 1};
`;

export default OverrideModeToggle;
