import styled from 'styled-components';

const M = 940;
const BORDER = '1px solid #e8e8e8';

const DataWellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 10px;
  width: 100%;
  @media (max-width: ${M - 1}px) {
    &:nth-child(n+2) {
      margin-top: 10px;
      padding-top: 10px;
      border-top: ${BORDER}; 
    }
  }
  @media (min-width: ${M}px) {
    &:nth-child(n+2) {
      border-left: ${BORDER};
    }
    max-width: calc(33% - 25px);
  }
  height: 120px;
`;

export default DataWellContainer;
