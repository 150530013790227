import React from 'react';
import { NarrowWrapper, Element, Name } from '#/shared/Card';
import ButtonSmall from '#/shared/Button';

const DEALTERMS = 'Deal Terms';
const CREATE = 'Create';
const UPDATE = 'Update';
const MAP = 'Map';

interface DealTermCardProps {
  openCreateModal: () => void;
  openUpdateModal: () => void;
  openMapModal: () => void;
}

const DealTermCard: React.FC<DealTermCardProps> = (
  { openCreateModal, openUpdateModal, openMapModal },
) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{DEALTERMS}</Name>
      </Element>
      <Element>
        <ButtonSmall onClick={openCreateModal}>{CREATE}</ButtonSmall>
      </Element>
      <Element>
        <ButtonSmall onClick={openUpdateModal}>{UPDATE}</ButtonSmall>
      </Element>
      <Element>
        <ButtonSmall onClick={openMapModal}>{MAP}</ButtonSmall>
      </Element>
    </NarrowWrapper>
  );
};

export default DealTermCard;
