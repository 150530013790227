const typography = {
  header1: `
        font-family: Roobert, sans-serif;
        font-size: 48px;
        letter-spacing: 0.016em;
        line-height: 56px;
    `,
  header2: `
        font-family: Roobert, sans-serif;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0.008em;
        line-height: 130%;
    `,
  header3: `
        font-family: Roobert;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.06px;
    `,
  header4: `
        font-family: Roobert, sans-serif;
        font-size: 16px;
        letter-spacing: 0em;
        line-height: 134%;
    `,
  header5: `
        font-family: Roobert;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.17px;
  `,
  text1: `
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
    `,
  text1bold: `
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
`,
  text1semibold: `
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
    `,
  // text2 is the default, as specified in index.scss
  text2: `
        font-size: 15px;
        letter-spacing: .005em;
        line-height: 20px;
        font-weight: 500;
    `,
  text2semibold: `
        font-size: 15px;
        letter-spacing: .005em;
        line-height: 20px;
        font-weight: 600;
`,
  text3: `
        font-size: 14px;
        letter-spacing: .01em;
        line-height: 20px;
        font-weight: 500;
        `,
  text4: `
        font-size: 14px;
        letter-spacing: -0.15px;
        line-height: 142%;
    `,
};

export default typography;
