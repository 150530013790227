import client from './_client';

type APICall = (file: File) => Promise<number>;

const postExternalTransactionCsv: APICall = async (file: File) => {
  const formData = new FormData();

  formData.append('csv_file', file);
  const response = await client.post('/proxy/external_transactions', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.status;
};

export default postExternalTransactionCsv;
