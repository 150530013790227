import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { Schedule } from '#/types/Event';

interface CardProps {
  schedule: Schedule;
}

const REASON = 'Reason';
const INTERVAL = 'Supervisor Interval';
const NOT_RUNNING_REASONS = ['is_inactive', 'no_run_yet'];

const ScheduleStatusCard: React.FC<CardProps> = ({ schedule }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{INTERVAL}</Name>
        <Value>
          {
            NOT_RUNNING_REASONS.includes(schedule.reason)
              ? 'N/A'
              : schedule.estimatedNextRunAt
                .toRelative({ base: schedule.lastRunAt })
                .split('in ')[1]
                .split(' ago')[0]
          }
        </Value>
      </Element>
      <Element>
        <Name>{REASON}</Name>
        <Value>
          {
            schedule.reason === 'not_scheduled'
              ? 'sales target'
              : schedule.reason.split('_').join(' ')
          }
        </Value>
      </Element>
    </NarrowWrapper>
  );
};

export default ScheduleStatusCard;
