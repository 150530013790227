import React, { PureComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import noop from 'lodash/noop';

export type Props = {
  children: ReactNode;
  checked: boolean;
  disabled?: boolean;
  name?: string;
  onChange: (
    arg0: boolean,
    arg1: Props,
    arg2: React.ChangeEvent<HTMLInputElement>
  ) => unknown;
  value: string;
  isSmall?: boolean;
};

export const Label = styled.label<Props>`
  ${({ checked, isSmall, theme }: { checked: boolean; isSmall?: boolean; theme: Theme }) => css`
    background: ${checked ? theme.color.text.primary : '#fff'};
    border: 1px solid ${checked ? theme.color.text.primary : theme.color.border.primary};
    color: ${checked ? '#fff' : theme.color.text.primary};
    font-weight: ${checked ? 600 : 400};
    font-size: ${isSmall ? '0.85em' : 'calc(15 / 16)em'};
  `};
  align-items: center;
  box-sizing: border-box;
  cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  display: flex;
  flex: 1;
  height: ${({ isSmall }): string => isSmall ? '2em' : '2.625em'};
  justify-content: center;
  line-height: 1.25em;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;

  &:focus-within {
    outline: 1px solid ${({ theme }) => theme.palette.blue.base};
  }

  &:first-child {
    border-bottom-left-radius: 0.5em;
    border-right: none;
    border-top-left-radius: 0.5em;
  }

  &:last-child {
    border-bottom-right-radius: 0.5em;
    border-left: none;
    border-top-right-radius: 0.5em;
  }
`;

const Input = styled.input`
  appearance: none;
  cursor: inherit;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export default class SegmentedControl extends PureComponent<Props> {
  static defaultProps = {
    checked: false,
    disabled: false,
    onChange: noop
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.disabled) {
      return;
    }
    return this.props.onChange(e.target.checked, this.props, e);
  };

  render() {
    const {
      checked,
      children,
      name,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      value,
      disabled,
      isSmall,
      ...props
    } = this.props;

    return (
      <Label isSmall={isSmall} checked={checked} disabled={disabled} onChange={noop} value={value}>
        <Input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={this.handleChange}
          {...props}
        />
        {children}
      </Label>
    );
  }
}
