import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import Action, { UpdateEventConfigType } from '../types';
import { InputWrapper, Label } from './formComponents';
import type { FetchGroupsHook, GroupOption } from '#/pages/useFetchGroups';

interface Option {
  id?: number;
  label: string;
  value: string;
}

interface SelectGroups {
  label: string;
  type: UpdateEventConfigType;
  handleInputChange: React.Dispatch<Action>;
  groupsHook: FetchGroupsHook;
  initialValues?: string[];
  isDisabled?: boolean;
}

const SelectGroups: React.FC<SelectGroups> = ({
  label,
  type,
  handleInputChange,
  initialValues,
  groupsHook,
  isDisabled = false,
}) => {
  const { groupLabels } = groupsHook;

  const handleChange = useCallback(
    (option: GroupOption[]) => {
      const groups = option?.map((el: Option) => el.value) ?? [];

      handleInputChange({ type, value: groups });
    },
    [handleInputChange, type],
  );

  const defaultValue = useMemo(() => (
    initialValues?.map((el: string) => (
      groupLabels?.find((group: Option) => group.value === el)
    ))
      .filter((el: Option) => !!el)
  ), [initialValues, groupLabels]);

  return (
    <InputWrapper>
      <Label htmlFor={label}>{`${label}:`}</Label>
      <Wrapper>
        <Select
          isDisabled={isDisabled}
          isMulti
          onChange={handleChange}
          options={groupLabels}
          value={defaultValue}
        />
      </Wrapper>
    </InputWrapper>
  );
};

const Wrapper = styled.div`
  width: 15.5rem;
`;

export { Option };
export default SelectGroups;
