import React, {
  useCallback, useState, useRef, type FormEvent, useEffect, useContext, useMemo,
} from 'react';
import styled from 'styled-components';
import { ScaleOverrideType, SetOverrideType, ShiftOverrideType } from '#/types/Override';
import {
  SIDEBAR_WIDTH_PX, TOP_OFFSET_PX, OVERRIDES_MENU_WIDTH_PX, SelldownPreviewOverrides,
} from '../../constants';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import Check from '#/shared/clientReporting/Icons/Check';
import { LABELS } from '#/shared/OverrideParams';
import { type RollupOverrideParams, type RequestedOverride, OverrideOperation } from '#/types/ReportingRollup';
import type { OverridePreviews } from '../../hooks/useCollectionSalesPreview';
import { CollectionEventsContext, CollectionOverridesContext } from '../../contexts';
import ClearableOverrideInput from './ClearableOverrideInput';
import SVG from 'react-inlinesvg';
import Refresh from '#images/Refresh.svg';
import OverrideModeToggle from './OverrideModeToggle';

const PRICING_OVERRIDES = 'Pricing Overrides';
const PREVIEW = 'Preview';
const APPLY = 'Apply';

interface Props {
  isCollectionLoading?: boolean
  hasPreviewOverridesLoading?: boolean
  onSubmitOverridesPreview: (rollupParams: RollupOverrideParams[]) => void
  overridePreviews?: OverridePreviews
  setOverridePreviews: React.Dispatch<React.SetStateAction<OverridePreviews>>
}

const ParameterOverridesMenu: React.FC<Props> = ({
  isCollectionLoading,
  hasPreviewOverridesLoading,
  onSubmitOverridesPreview,
  overridePreviews,
  setOverridePreviews,
}) => {
  const [canApply, setCanApply] = useState(false);
  const [_requestedOverrides, _setRequestedOverrides] = useState<RequestedOverride[]>([]);

  const { selectedFutureEventIds } = useContext(CollectionEventsContext);
  const {
    setIsVisibleRequestedOverridesModal,
    setRequestedOverrides,
    isLoadingOverridesSubmission,
    overridesState,
    dispatchOverrides,
    overrideMode,
  } = useContext(CollectionOverridesContext);

  const overrides = useMemo(() => {
    if (overrideMode === OverrideOperation.SHIFT)
      return ShiftOverrideType;

    if (overrideMode === OverrideOperation.SCALE)
      return ScaleOverrideType;

    return SetOverrideType;
  }, [overrideMode]);

  const formRef = useRef<HTMLFormElement>();

  const hasValidOverrides = useMemo(() => (
    overridesState?.length > 0
      && overridesState.every(({ value }) => !Number.isNaN(value))
      && selectedFutureEventIds?.length > 0
  ), [overridesState, selectedFutureEventIds]);

  // reset controls when changing overrides
  const handleOverrideChange = useCallback((params: RollupOverrideParams) => {
    dispatchOverrides(params);
    setCanApply(false);
  }, [dispatchOverrides]);

  // allow applying overrides after previewing
  const handleFormSubmission = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmitOverridesPreview(overridesState);

    const overrideParamsWithLabels: RequestedOverride[] = [];

    Object.values(overrides)
      .forEach((o) => {
        const thisOverride = overridesState?.find((os) => os?.type === o);

        if (thisOverride) {
          overrideParamsWithLabels.push({
            ...thisOverride,
            label: LABELS[thisOverride.type],
          });
        }
      });

    _setRequestedOverrides(overrideParamsWithLabels);
    setCanApply(true);
  }, [onSubmitOverridesPreview, overridesState, overrides]);

  // reset controls when removing override previews
  useEffect(() => {
    if (!overridePreviews)
      setCanApply(false);
  }, [overridePreviews, setCanApply]);

  // allow overrides that don't impact previews to be applied
  const canApplyWithoutPreviewing = useMemo(() => (
    overridesState?.length > 0
      && overridesState?.every((o) => !SelldownPreviewOverrides.includes(o.type))
  ), [overridesState]);

  const handleRequestedOverrides = useCallback((e: React.MouseEvent) => {
    if (canApplyWithoutPreviewing) {
      onSubmitOverridesPreview(overridesState);

      const overrideParamsWithLabels: RequestedOverride[] = [];

      Object.values(overrides)
        .forEach((o) => {
          const thisOverride = overridesState?.find((os) => os?.type === o);

          if (thisOverride) {
            overrideParamsWithLabels.push({
              ...thisOverride,
              label: LABELS[thisOverride.type],
            });
          }
        });

      _setRequestedOverrides(overrideParamsWithLabels);
      setRequestedOverrides(overrideParamsWithLabels);
    } else {
      setRequestedOverrides(_requestedOverrides);
    }

    e.preventDefault();
    setIsVisibleRequestedOverridesModal(true);
  }, [
    _requestedOverrides, setIsVisibleRequestedOverridesModal, setRequestedOverrides,
    canApplyWithoutPreviewing, onSubmitOverridesPreview, overrides, overridesState,
  ]);

  const isApplyDisabled = useMemo(() => (
    (!canApply && !canApplyWithoutPreviewing)
    || hasPreviewOverridesLoading || isLoadingOverridesSubmission
  ), [
    canApply, canApplyWithoutPreviewing, hasPreviewOverridesLoading, isLoadingOverridesSubmission,
  ]);

  const clearOverridePreviews = useCallback(() => {
    setOverridePreviews(undefined);
  }, [setOverridePreviews]);

  // clear preview changes when adjusted overrides don't impact previews
  useEffect(() => {
    if (!overridesState?.some((o) => SelldownPreviewOverrides.includes(o.type)))
      clearOverridePreviews();
  }, [overridesState, clearOverridePreviews]);

  const handleReset = useCallback(() => {
    clearOverridePreviews();
    dispatchOverrides(null);
  }, [clearOverridePreviews, dispatchOverrides]);

  return (
    <form onSubmit={handleFormSubmission} ref={formRef}>
      <Wrapper>
        <HeaderWrapper>
          <Header>
            <Title>{PRICING_OVERRIDES}</Title>
            {overridePreviews && (
              <SmallButton
                isDisabled={isCollectionLoading || hasPreviewOverridesLoading}
                kind='secondary'
                label={<SVG height={20} src={Refresh} width={20} />}
                onClick={handleReset}
                padding='0.2rem'
                title='Reset overrides'
              />
            )}
          </Header>
        </HeaderWrapper>
        <OverrideModeToggle
          handleReset={handleReset}
          isLoading={isCollectionLoading || hasPreviewOverridesLoading}
        />
        <OverridesWrapper>
          {Object.values(overrides).map((override) => (
            <ClearableOverrideInput
              isLoading={isCollectionLoading || hasPreviewOverridesLoading}
              key={override}
              onChange={handleOverrideChange}
              onSelect={handleOverrideChange}
              override={override}
              overridesState={overridesState}
            />
          ))}
        </OverridesWrapper>
        <SubmissionButtonsWrapper>
          <SmallButton
            isDisabled={isCollectionLoading || !hasValidOverrides || canApplyWithoutPreviewing}
            isLoading={hasPreviewOverridesLoading}
            kind='secondary'
            label={PREVIEW}
            padding='0.45rem 2rem 0.45rem 1.1rem'
            style={{
              width: hasPreviewOverridesLoading ? 110 : 88,
            }}
            type='submit'
          />
          <SmallButton
            Icon={<IconWrapper><Check /></IconWrapper>}
            isDisabled={isApplyDisabled}
            label={APPLY}
            onClick={handleRequestedOverrides}
            padding='0.45rem 1.1rem'
          />
        </SubmissionButtonsWrapper>
      </Wrapper>
    </form>
  );
};

const IconWrapper = styled.div`
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: absolute;
  left: ${SIDEBAR_WIDTH_PX}px;
  height: calc(100vh - ${TOP_OFFSET_PX}px);
  width: ${OVERRIDES_MENU_WIDTH_PX}px;
  border-left: 1px solid #eee;
  border-right: 1px solid #ddd;
  letter-spacing: 0;
  display: flex;
  flex-direction: column;
  z-index: 999;
  background: ${({ theme }: { theme: Theme }): string => theme.palette.silver.base };
`;

const HeaderWrapper = styled.div`
  padding: 0 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  padding: 0.5rem 0;
`;

const Title = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 2;
`;

const OverridesWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 0 1rem;
`;

const SubmissionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-top: 1px solid #ddd;
  box-shadow: 0 -5px 10px rgba(150,150,150,0.1);
`;

export { type Props as ParameterOverridesProps };
export default ParameterOverridesMenu;
