import React from 'react';
import { GeomArea } from '@graphique/geom-area';
import { GeomPoint } from '@graphique/geom-point';
import { GeomLine } from '@graphique/geom-line';
import { FormattedPricingChartDatum } from '../types';

const renderPoints = (
  data: (FormattedPricingChartDatum)[],
): React.ReactNode => (
  <GeomPoint
    aes={{ key: (d): string => d.key }}
    data={data}
    entrance='data'
    fillOpacity={0.4}
    focusedStyle={{ fillOpacity: 1, strokeOpacity: 1 }}
    r={1.8}
    strokeOpacity={0.5}
    unfocusedStyle={{
      fillOpacity: 0.1,
      strokeOpacity: 0.2,
    }}
  />
);

const renderAreas = (
  data: FormattedPricingChartDatum[],
  id: number,
  isPrice: boolean,
): React.ReactNodeArray => {
  const AreaGeoms = data
    .map((geomData) => {
      return (
        <GeomArea
          aes={{
            fill: (): string => geomData.group,
            y0: (d): number => isPrice ? d.priceMin : d.logPriceRatioMin,
            y1: (d): number => isPrice ? d.priceMax : d.logPriceRatioMax,
          }}
          data={geomData.priceBand}
          fillOpacity={geomData.probability * 0.45}
          key={`${geomData.key}-${id}-${isPrice}`}
          showTooltip={false}
        />
      );
    });

  return AreaGeoms;
};

const renderLines = (
  data: FormattedPricingChartDatum[],
  id: number,
  isPrice: boolean,
): React.ReactNodeArray => (
  data
    .map((geomData) => (
      <GeomLine
        aes={{
          stroke: (): string => geomData.group,
          y: (d): number => isPrice ? d.price : d.logPriceRatio,
        }}
        data={geomData.priceBand}
        entrance="data"
        key={`${geomData.key}-${id}`}
        showTooltip={false}
        strokeOpacity={geomData.probability * 1.5}
      />
    ))
);

export { renderAreas, renderPoints, renderLines };
