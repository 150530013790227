/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useCallback, useMemo,
} from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {
  InnerWrapper,
  Label,
  Input,
  Checkbox,
  HorizontalWrapper,
  modalMenuProps,
} from '#/shared/modalComponents';
import {
  PostIngestion,
  SplitsRule,
  StockType,
  SplittingStrategy,
  TICKET_ACCOUNT_EMAILS,
  TicketAccountPlatformType,
  Uploader,
} from '#/types/Ingestion';
import type { GenericOption } from '#/types/GenericOption';
import Separator from './Separator';
import useFetchUploaders from '#/pages/useFetchUploaders';

const STOCK_TYPE = 'Stock Type';
const SPLITS_RULE = 'Splits Rule';
const SPLITTING_STRATEGY = 'Split Strategy';
const IN_HAND = 'In Hand';
const TICKET_ACCOUNT_EMAIL = 'Ticket Account Email';
const TICKET_ACCOUNT_PLATFORM = 'Ticket Account Platform';
const SEATGEEK_USER_PURCHASE_ID = 'SG Purchase ID';
const SEATGEEK_USER_PURCHASE_RETURN_ID = 'SG Return ID';
const SEATGEEK_USER_LISTING_ID = 'SG User Listing ID';
const COST_PER_TICKET = 'Cost Per-Ticket';
const FACE_VALUE_PER_TICKET = 'Face Value Per-Ticket';
const COMMISSION_PER_TICKET = 'Commission Per-Ticket';
const RESERVED = 'Reserved';
const UNMANIFESTED = 'Unmanifested';

interface FulfillmentFormProps {
  ingestion: PostIngestion;
  setIngestion: React.Dispatch<React.SetStateAction<PostIngestion>>;
  defaultValue: PostIngestion;
}

const FulfillmentForm: React.FC<FulfillmentFormProps> = ({
  ingestion, setIngestion, defaultValue,
}) => {
  const { uploaders } = useFetchUploaders();
  const { uploader } = ingestion;
  const [inHand, setInHand] = useState<boolean>(null);

  const defaultStockTypeOption = defaultValue.stock_type && {
    value: defaultValue.stock_type, label: defaultValue.stock_type,
  };

  const ticketAccountEmailOptions = useMemo(() => {
    const e = defaultValue.ticket_account_email;
    let es = TICKET_ACCOUNT_EMAILS.map((t) => ({ value: t, label: t }));

    if (e && !TICKET_ACCOUNT_EMAILS.includes(defaultValue.ticket_account_email))
      es = [...es, { value: e, label: e }];

    return es;
  }, [defaultValue.ticket_account_email]);
  const ticketAccountPlatformOptions = Object.values(TicketAccountPlatformType)
    .map((platform) => {
      return ({ value: platform, label: platform });
    });

  const selectedUploader = useMemo(() => (
    uploaders?.filter((u) => u.name === uploader)[0]
  ), [uploader, uploaders]);

  const splitsRuleOptions = useMemo(() => (
    selectedUploader?.splitsRules.map((s) => ({ value: s, label: s }))
  ), [selectedUploader]);

  const splittingStrategyOptions = useMemo(() => (
    selectedUploader?.splittingStrategies.map((s) => ({ value: s, label: s }))
  ), [selectedUploader]);

  const stockTypeOptions = useMemo(() => (
    selectedUploader?.stockTypes.map((s) => ({ value: s, label: s }))
  ), [selectedUploader]);

  const onChangeStockType = useCallback((event: GenericOption<StockType, StockType>) => {
    const stock_type = event.value as StockType;
    const isWalletLinks = stock_type === StockType.WALLET_LINKS;

    setIngestion((i) => (
      {
        ...i,
        stock_type,
        splitting_strategy: isWalletLinks ? SplittingStrategy.NONE : i.splitting_strategy,
        splits_rule: isWalletLinks ? SplitsRule.NONE : i.splits_rule,
      }
    ));
  }, [setIngestion]);

  const onChangeSplitsRule = useCallback((event: GenericOption<SplitsRule, SplitsRule>) => {
    const splits_rule = event.value as SplitsRule;

    setIngestion((i) => ({ ...i, splits_rule }));
  }, [setIngestion]);

  const onChangeSplittingStrategy = useCallback(
    (event: GenericOption<SplittingStrategy, SplittingStrategy>) => {
      const splitting_strategy = event?.value as SplittingStrategy;

      setIngestion((i) => ({ ...i, splitting_strategy }));
    }, [setIngestion],
  );

  const onChangeTicketAccountEmail = useCallback((event: GenericOption<string, string>) => {
    const ticket_account_email = event?.value;

    setIngestion((i) => ({ ...i, ticket_account_email }));
  }, [setIngestion]);

  const onChangeTicketAccountPlatform = useCallback(
    (event: GenericOption<TicketAccountPlatformType, TicketAccountPlatformType>) => {
      const ticket_account_platform = event.value as TicketAccountPlatformType;

      setIngestion((i) => ({ ...i, ticket_account_platform }));
    }, [setIngestion],
  );

  const onChangeIsReserved = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const is_reserved = event.target.checked as boolean;

    setIngestion((i) => ({ ...i, is_reserved }));
  }, [setIngestion]);

  const onChangeInHand = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked as boolean;

    setInHand(checked);
    if (checked)
      setIngestion((i) => ({ ...i, in_hand_date: null }));
  }, [setIngestion, setInHand]);

  const onChangeInHandDate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const in_hand_date = event.target?.value || null;

    setIngestion((i) => ({ ...i, in_hand_date }));
  }, [setIngestion]);

  const onChangeCostPerTicket = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    // cost_per_ticket can be 0, so we don't want to treat 0 as equivalent to null
    const c = event.target?.value;
    const cost_per_ticket = c === null ? null : Number(c) || null;

    setIngestion((i) => ({ ...i, cost_per_ticket }));
  }, [setIngestion]);

  const onChangeFaceValuePerTicket = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const f = event.target?.value;
    const face_value_per_ticket = f === null ? null : Number(f) || null;

    setIngestion((i) => ({ ...i, face_value_per_ticket }));
  }, [setIngestion]);

  const onChangeCommissionPerTicket = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const c = event.target?.value;
    const commission_per_ticket = c === null ? null : Number(c) || null;

    setIngestion((i) => ({ ...i, commission_per_ticket }));
  }, [setIngestion]);

  const onChangeSeatgeekUserPurchaseId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const seatgeek_user_purchase_id = Number(event.target?.value) || null;

      setIngestion((i) => ({ ...i, seatgeek_user_purchase_id }));
    }, [setIngestion],
  );

  const onChangeSeatgeekUserPurchaseReturnId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const seatgeek_user_purchase_return_id = Number(event.target?.value) || null;

      setIngestion((i) => ({ ...i, seatgeek_user_purchase_return_id }));
    }, [setIngestion],
  );

  const onChangeSeatgeekUserListingId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const seatgeek_user_listing_id = Number(event.target?.value) || null;

      setIngestion((i) => ({ ...i, seatgeek_user_listing_id }));
    }, [setIngestion],
  );

  const onChangeIsUnmanifested = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const is_unmanifested = event.target.checked as boolean;

    setIngestion((i) => ({ ...i, is_unmanifested }));
  }, [setIngestion]);

  return (
    <>
      <InnerWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{TICKET_ACCOUNT_EMAIL}</Label>
            <Creatable
              defaultValue={
                ticketAccountEmailOptions.find((t) => t.value === defaultValue.ticket_account_email)
              }
              isClearable
              onChange={onChangeTicketAccountEmail}
              options={ticketAccountEmailOptions}
              placeholder="Select or type..."
              {...modalMenuProps}
            />
          </Separator>
          <Separator>
            <Label>{TICKET_ACCOUNT_PLATFORM}</Label>
            <Select
              defaultValue={
                ticketAccountPlatformOptions.find((t) => t.value
                === defaultValue.ticket_account_platform)
              }
              isClearable
              onChange={onChangeTicketAccountPlatform}
              options={ticketAccountPlatformOptions}
              {...modalMenuProps}
            />
          </Separator>
        </HorizontalWrapper>
      </InnerWrapper>
      <InnerWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{STOCK_TYPE}</Label>
            <Select
              defaultValue={defaultStockTypeOption}
              onChange={onChangeStockType}
              options={stockTypeOptions}
              {...modalMenuProps}
            />
          </Separator>
          <Separator>
            <Label>{SPLITTING_STRATEGY}</Label>
            <Select
              isClearable
              onChange={onChangeSplittingStrategy}
              options={splittingStrategyOptions}
              value={
                ingestion.splitting_strategy && (
                  splittingStrategyOptions?.find((s) => s.value === ingestion.splitting_strategy)
                )
              }
              {...modalMenuProps}
            />
          </Separator>
          <Separator>
            <Label>{SPLITS_RULE}</Label>
            <Select
              onChange={onChangeSplitsRule}
              options={splitsRuleOptions}
              value={
                ingestion.splits_rule && (
                  splitsRuleOptions?.find((s) => s.value === ingestion.splits_rule)
                )
              }
              {...modalMenuProps}
            />
          </Separator>
        </HorizontalWrapper>
      </InnerWrapper>
      <InnerWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{COST_PER_TICKET}</Label>
            <Input
              defaultValue={defaultValue.cost_per_ticket}
              onChange={onChangeCostPerTicket}
              type="number"
            />
          </Separator>
          <Separator>
            <Label>{FACE_VALUE_PER_TICKET}</Label>
            <Input
              defaultValue={defaultValue.face_value_per_ticket}
              onChange={onChangeFaceValuePerTicket}
              type="number"
            />
          </Separator>
        </HorizontalWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{COMMISSION_PER_TICKET}</Label>
            <Input
              defaultValue={defaultValue.commission_per_ticket}
              onChange={onChangeCommissionPerTicket}
              type="number"
            />
          </Separator>
        </HorizontalWrapper>
        <HorizontalWrapper>
          {uploader === Uploader.CONSUMER && (
            <Separator>
              <Label>{SEATGEEK_USER_LISTING_ID}</Label>
              <Input
                defaultValue={defaultValue.seatgeek_user_listing_id}
                onChange={onChangeSeatgeekUserListingId}
                type="number"
              />
            </Separator>
          )}
          {uploader !== Uploader.CONSUMER && (
            <>
              <Separator>
                <Label>{SEATGEEK_USER_PURCHASE_ID}</Label>
                <Input
                  defaultValue={defaultValue.seatgeek_user_purchase_id}
                  onChange={onChangeSeatgeekUserPurchaseId}
                  type="number"
                />
              </Separator>
              <Separator>
                <Label>{SEATGEEK_USER_PURCHASE_RETURN_ID}</Label>
                <Input
                  defaultValue={defaultValue.seatgeek_user_purchase_return_id}
                  onChange={onChangeSeatgeekUserPurchaseReturnId}
                  type="number"
                />
              </Separator>
            </>
          )}
        </HorizontalWrapper>
      </InnerWrapper>
      <InnerWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{IN_HAND}</Label>
            <HorizontalWrapper>
              <Checkbox
                defaultChecked={defaultValue?.in_hand || false}
                onChange={onChangeInHand}
                title="in hand"
                type="checkbox"
              />
              <Input
                disabled={inHand}
                onChange={onChangeInHandDate}
                title="in hand date"
                type="date"
                value={ingestion.in_hand_date || ''}
              />
            </HorizontalWrapper>
          </Separator>
          <Separator style={{ width: '20%' }}>
            <Label>{RESERVED}</Label>
            <HorizontalWrapper>
              <Checkbox
                defaultChecked={defaultValue?.is_reserved || false}
                onChange={onChangeIsReserved}
                title="reserved"
                type="checkbox"
              />
            </HorizontalWrapper>
          </Separator>
          <Separator style={{ width: '20%' }}>
            <Label>{UNMANIFESTED}</Label>
            <HorizontalWrapper>
              <Checkbox
                defaultChecked={defaultValue?.is_unmanifested || false}
                onChange={onChangeIsUnmanifested}
                title="unmanifested"
                type="checkbox"
              />
            </HorizontalWrapper>
          </Separator>
        </HorizontalWrapper>
      </InnerWrapper>
    </>
  );
};

export default FulfillmentForm;
