import React from 'react';
import { Legend as GeomLineLegend } from '@graphique/geom-line';
import { Legend as GeomPointLegend } from '@graphique/geom-point';

const legendStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  fontSize: 14,
  fontWeight: 400,
};

const ORIENTATION: 'horizontal' | 'vertical' = 'horizontal';

interface LegendProps {
  onSelection: (v: string) => void;
  kind?: 'line' | 'point';
  title?: React.ReactNode;
}

const legendProps = {
  orientation: ORIENTATION,
  style: legendStyles,
  ignoreDasharray: true,
};

const Legend: React.FC<LegendProps> = ({ onSelection, kind = 'line', title }) => {
  const thisLegendProps = {
    ...legendProps,
    onSelection,
    title,
  };

  return (
    kind === 'line'
      // eslint-disable-next-line react/jsx-props-no-spreading
      ? <GeomLineLegend {...thisLegendProps} />
      // eslint-disable-next-line react/jsx-props-no-spreading
      : <GeomPointLegend {...thisLegendProps} />
  );
};

export default Legend;
