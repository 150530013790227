import { AxiosResponse } from 'axios';
import Stakeholder from '#/types/Stakeholder';
import client from './_clientFormatted';

const deleteStakeholder = async (stakeholderId: number): Promise<Stakeholder> => {
  const response: AxiosResponse<Stakeholder> = await client.delete(`/proxy/stakeholder/${stakeholderId}`);

  return response?.data;
};

export default deleteStakeholder;
