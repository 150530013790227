import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { User } from '#/types/User';
import { ErrorType } from '#/types/Errors';
import Ingestion from '#/types/Ingestion';
import { ErrorStatusProps } from '#/shared/ErrorStatusModal';
import HeaderButtons from './HeaderButtons';

const INGESTION = 'Ingestion';
const SEATGEEK_EVENT = 'SeatGeek Event';
const INGESTED_COST = 'Ingested Cost';
const FACE_VALUE = 'Face Value';
const COMMISSION = 'Commission';
const COMBINED = 'Ingested Cost / Face Value';

interface CardProps {
  user: User;
  ingestion: Ingestion;
  setErrorStatusModal: (props: ErrorStatusProps) => void;
  openEditModal: () => void;
}

const IngestionListingCard: React.FC<CardProps> = ({
  user,
  ingestion,
  setErrorStatusModal,
  openEditModal,
}) => {
  const {
    state,
    cost_per_ticket,
    face_value_per_ticket,
    commission_per_ticket,
  } = ingestion;
  const disabled = user.isReadOnly;
  const openNotesModal = useCallback(() => {
    if (ingestion !== null) {
      setErrorStatusModal({
        title: 'Annotate Ingestion Listing',
        description: '',
        isOpen: true,
        existing: ingestion.error_status,
        reference: {
          id: ingestion.id,
          type: ErrorType.INGESTION_LISTINGS,
        },
      });
    }
  }, [ingestion, setErrorStatusModal]);

  const showCost = cost_per_ticket !== null && cost_per_ticket !== undefined
                    && cost_per_ticket !== face_value_per_ticket;
  const showFaceValue = face_value_per_ticket !== null && face_value_per_ticket !== undefined
                          && face_value_per_ticket !== cost_per_ticket;
  const showCombined = cost_per_ticket !== null && cost_per_ticket !== undefined
                        && cost_per_ticket === face_value_per_ticket;
  const showCommission = commission_per_ticket !== null && commission_per_ticket !== undefined
                          && commission_per_ticket !== 0;

  return (
    <NarrowWrapper>
      <Element>
        <HeaderButtons
          disabled={disabled}
          openEditModal={state === 'done' ? null : openEditModal}
          openNotesModal={openNotesModal}
        >
          <Name>{INGESTION}</Name>
        </HeaderButtons>
      </Element>
      <Element>
        <Name>
          {SEATGEEK_EVENT}
          <Link
            href={`https://seatgeek.com/e/events/${ingestion.seatgeek_event_id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {ingestion.seatgeek_event_id}
          </Link>
        </Name>
      </Element>
      {showCost && (
        <Element>
          <Name>{INGESTED_COST}</Name>
          <Value>{`${ingestion.quantity}x • $${cost_per_ticket.toFixed(2)}`}</Value>
        </Element>
      )}
      {(showFaceValue || showCombined) && (
        <Element>
          <Name>{showFaceValue ? FACE_VALUE : COMBINED}</Name>
          <Value>{`${ingestion.quantity}x • $${face_value_per_ticket.toFixed(2)}`}</Value>
        </Element>
      )}
      {showCommission && (
        <Element>
          <Name>{COMMISSION}</Name>
          <Value>{`${ingestion.sold_ct}x • $${commission_per_ticket.toFixed(2)}`}</Value>
        </Element>
      )}
      <Element>
        <Name>{`Ingested ${ingestion.created_at.toRelative()}`}</Name>
      </Element>
    </NarrowWrapper>
  );
};

const Link = styled.a`
  padding-left: 0.35rem
`;

export default IngestionListingCard;
