import React from 'react';
import styled from 'styled-components';
import {
  RelatedWrapper, Element, Tag, HorizonalWrapper,
} from '#/shared/Card';
import { ErrorStatus } from '#/types/Errors';

interface CardProps {
  status: ErrorStatus;
}

const ErrorStatusCard: React.FC<CardProps> = ({ status }) => {
  return (
    <RelatedWrapper>
      <HorizonalWrapper>
        <HorizonalWrapper>
          <Tag>{`status: ${status.status}`}</Tag>
          <Tag>
            {
              status.userName === null
                ? `user id: ${status.userId}`
                : `- ${status.userName}`
            }
          </Tag>
          <Tag>{status.createdAt.toRelative()}</Tag>
        </HorizonalWrapper>
        <Element>
          <ScrollBox>{status.notes}</ScrollBox>
        </Element>
      </HorizonalWrapper>
    </RelatedWrapper>
  );
};

const ScrollBox = styled.div`
  overflow-y: auto;
  height: 100%;
  margin: 0.5rem;
`;

export default ErrorStatusCard;
