import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { TableColumn, TableStyles } from 'react-data-table-component';
import tableStyles from '#/shared/tableStyles';
import { EventTxSummary, EventTxSummaryCols } from '../../types';
import { formatColName, formatAccountingCurrency } from '../../utils';
import { formatPercentage, formatLargeNumber } from '#/shared/formatNumberForDisplay';

const MONEY_COLS: EventTxSummaryCols[] = [
  EventTxSummaryCols.REVENUE,
  EventTxSummaryCols.COST,
  EventTxSummaryCols.TAKE,
];
const NUMBER_COLS: EventTxSummaryCols[] = [
  EventTxSummaryCols.MANAGED_INVENTORY,
  EventTxSummaryCols.TICKETS_SOLD,
  EventTxSummaryCols.LIFT_OF_SOLD,
  EventTxSummaryCols.LIFT_WITH_SPOILAGE,
  ...MONEY_COLS,
];
const RIGHT_ALIGN_COLS: EventTxSummaryCols[] = [
  EventTxSummaryCols.EVENT_DATE, ...NUMBER_COLS,
];
const EMPTY_CELL_VALUE = '—';

type TableCell = ((row: EventTxSummary) => React.ReactNode) | undefined;

const formatTableCell = (key: EventTxSummaryCols, isNarrow = false): TableCell => {
  if (key === EventTxSummaryCols.EVENT_DATE) {
    return (row: EventTxSummary): string => (
      (row[key] as DateTime)
        ?.toLocaleString(
          isNarrow
            ? {
              day: 'numeric',
              month: 'numeric',
              year: '2-digit',
            }
            : DateTime.DATE_MED,
        )
    );
  }

  if (key === EventTxSummaryCols.NUM_EVENTS)
    return (row: EventTxSummary): string => `${row[key]} events`;

  if ([EventTxSummaryCols.MANAGED_INVENTORY].includes(key)) {
    return (row: EventTxSummary): string => (
      isNarrow ? formatLargeNumber(Number(row[key])) : row[key]?.toLocaleString()
    );
  }

  return undefined;
};

const formatTableHeader = (key: EventTxSummaryCols, isNarrow = false): string => {
  if ([EventTxSummaryCols.LIFT_OF_SOLD, EventTxSummaryCols.LIFT_WITH_SPOILAGE].includes(key))
    return formatColName(key, isNarrow, true);

  return formatColName(key, isNarrow);
};

const renderNumberAndPercentage = (
  numerator: number,
  denominator: number,
  isNarrow = false,
  isCurrency = false,
  shouldExclude = false,
): React.ReactNode => {
  let value;

  if (isCurrency)
    value = formatAccountingCurrency(numerator, isNarrow);
  else
    value = isNarrow ? formatLargeNumber(numerator) : numerator.toLocaleString();

  const percentLabel = denominator
    ? `(${formatPercentage(numerator / denominator)})`
    : '';
  const isNegative = Math.round(numerator) < 0;
  const hasUndefined = (
    typeof numerator === 'undefined'
    || typeof denominator === 'undefined'
    || Number.isNaN(numerator)
    || Number.isNaN(denominator)
  );

  return (
    <FlexContainer data-tag='allowRowEvents'>
      {hasUndefined || shouldExclude
        ? <EmptyCell data-tag='allowRowEvents'>{EMPTY_CELL_VALUE}</EmptyCell>
        : (
          <>
            <MoneyCell data-tag='allowRowEvents' isNegative={isNegative}>
              <NumericValue data-tag='allowRowEvents'>{value}</NumericValue>
            </MoneyCell>
            <MoneyCell data-tag='allowRowEvents' isNegative={isNegative}>
              <Percentage data-tag='allowRowEvents'>{percentLabel}</Percentage>
            </MoneyCell>
          </>
        )}
    </FlexContainer>
  );
};

const renderTableCell = (key: EventTxSummaryCols, isNarrow = false): TableCell => {
  if (MONEY_COLS.includes(key)) {
    return (row: EventTxSummary): React.ReactNode => {
      const value = row[key] as number;
      const isNegative = value < 0;
      const displayValue = formatAccountingCurrency(value, isNarrow);

      return (
        <MoneyCell data-tag='allowRowEvents' isNegative={isNegative}>
          {displayValue}
        </MoneyCell>
      );
    };
  }

  if (key === EventTxSummaryCols.TICKETS_SOLD) {
    return (row: EventTxSummary): React.ReactNode => {
      const { ticketsSold, managedInventory } = row;

      return renderNumberAndPercentage(ticketsSold, managedInventory, isNarrow);
    };
  }

  if (key === EventTxSummaryCols.LIFT_OF_SOLD) {
    return (row: EventTxSummary): React.ReactNode => {
      const { liftOfSold, revenue } = row;

      return renderNumberAndPercentage(liftOfSold, revenue - liftOfSold, isNarrow, true);
    };
  }

  if (key === EventTxSummaryCols.LIFT_WITH_SPOILAGE) {
    return (row: EventTxSummary): React.ReactNode => {
      const { liftWithSpoilage, revenue, isEventInFuture } = row;

      return renderNumberAndPercentage(
        liftWithSpoilage,
        revenue - liftWithSpoilage,
        isNarrow,
        true,
        isEventInFuture,
      );
    };
  }

  if (key === EventTxSummaryCols.EVENT) {
    return (row: EventTxSummary): React.ReactNode => (
      <EventLabel data-tag='allowRowEvents'>{row[key]}</EventLabel>
    );
  }
  return undefined;
};

const growTableCell = (key: EventTxSummaryCols): number => {
  if (key === EventTxSummaryCols.EVENT)
    return 3;

  if ([
    EventTxSummaryCols.NUM_EVENTS,
    EventTxSummaryCols.EVENT_DATE,
  ].includes(key))
    return 2;

  return 1.2;
};

const giveWidthTableCell = (key: EventTxSummaryCols): (string | undefined) => {
  if (MONEY_COLS.includes(key))
    return '122px';

  return undefined;
};

const getTableColumns = (
  isNarrow = false,
  excludedColumns: EventTxSummaryCols[],
): TableColumn<EventTxSummary>[] => {
  return (
    Object.values(EventTxSummaryCols)
      .filter((key: EventTxSummaryCols) => !excludedColumns.includes(key))
      .map((key: EventTxSummaryCols) => ({
        id: key,
        name: formatTableHeader(key, isNarrow),
        selector: (row: EventTxSummary): any => row[key],
        sortable: true,
        cell: renderTableCell(key, isNarrow),
        right: key !== EventTxSummaryCols.EVENT,
        omit: [
          EventTxSummaryCols.SG_EVENT_ID,
          EventTxSummaryCols.NUM_EVENTS,
        ].includes(key),
        format: formatTableCell(key, isNarrow),
        grow: growTableCell(key),
        width: giveWidthTableCell(key),
      }))
  );
};

const getTotalColumns = (
  isNarrow = false,
  excludedColumns: EventTxSummaryCols[],
): TableColumn<EventTxSummary>[] => {
  return (
    [
      ...getTableColumns(isNarrow, excludedColumns).map((col) => ({
        ...col,
        omit: excludedColumns.includes(col.id as EventTxSummaryCols),
      })),
    ]
  );
};

const customStyles: TableStyles = {
  ...tableStyles,
  responsiveWrapper: {
    style: {
      '::-webkit-scrollbar': {
        height: 0,
      },
      '::-webkit-scrollbar-thumb': {
        width: 15,
        backgroundColor: '#888',
        border: '3px solid transparent',
        borderRadius: 9,
        backgroundClip: 'content-box',
      },
      overflowY: 'scroll',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  cells: {
    ...tableStyles.cells,
    style: {
      ...tableStyles.cells.style,
      paddingTop: '18px',
      paddingBottom: '18px',
    },
  },
};

const totalStyles: TableStyles = {
  ...customStyles,
  responsiveWrapper: {
    style: {
      ...customStyles.responsiveWrapper.style,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      '::-webkit-scrollbar': {
        height: 15,
      },
    },
  },
  cells: {
    style: {
      ...customStyles.cells.style,
      fontWeight: 550,
      color: '#111',
      background: '#ddd',
    },
  },
  rows: {
    style: {
      ...customStyles.rows.style,
      borderTop: '2px solid #d3d3d3',
      alignItems: 'stretch',
    },
  },
};

const synchronizeScroll = (
  kind: 'addListener' | 'removeListener' = 'addListener',
  tblRef: React.RefObject<HTMLDivElement>,
): void => {
  if (tblRef.current) {
    const eventTables = tblRef.current.getElementsByClassName('rdt_Table');
    const mainTable = eventTables[0]?.parentElement?.parentElement;
    const totalTable = eventTables[1]?.parentElement?.parentElement;

    const handleTableScroll = (): void => {
      totalTable.scrollLeft = mainTable.scrollLeft;
    };
    const handleFooterScroll = (): void => {
      mainTable.scrollLeft = totalTable.scrollLeft;
    };

    if (mainTable && totalTable) {
      if (kind === 'removeListener') {
        mainTable.removeEventListener('scroll', handleTableScroll);
        totalTable.removeEventListener('scroll', handleFooterScroll);
      } else {
        mainTable.addEventListener('scroll', handleTableScroll);
        totalTable.addEventListener('scroll', handleFooterScroll);
      }
    }
  }
};

const styleHeaders = (): void => {
  RIGHT_ALIGN_COLS.forEach((col) => {
    Array.from(document.querySelectorAll(`[data-column-id=${col}][data-sort-id=${col}]`))
      .forEach((c) => {
        const colTextEl = c.querySelector('div');

        colTextEl.style.whiteSpace = 'normal';
        colTextEl.style.textAlign = 'right';
        colTextEl.style.lineHeight = '1.3';
        colTextEl.style.width = 'fit-content';
      });
  });
};

const EventLabel = styled.div`
  font-weight: ${({ theme }: { theme: Theme }): string => theme.text1semibold};
  line-height: 1.3;
  min-width: 122px;
`;

interface MoneyCellProps {
  isNegative?: boolean;
  theme: Theme;
}

const MoneyCell = styled.div<MoneyCellProps>`
  color: ${({ theme, isNegative }): string => isNegative ? theme.palette.red.base : undefined}
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const NumericValue = styled.div`
  white-space: nowrap;
`;

const Percentage = styled.div`
  opacity: 0.77;
  font-size: 14px;
  margin: 0.2rem 0 0.15rem 0.35rem;
  white-space: nowrap;
`;

const EmptyCell = styled(Percentage as any)`
  color: #aaa;
  min-width: 64px;
  display: flex;
  justify-content: flex-end;
`;

export {
  getTableColumns,
  getTotalColumns,
  customStyles,
  totalStyles,
  NUMBER_COLS,
  formatTableCell,
  synchronizeScroll,
  styleHeaders,
  EMPTY_CELL_VALUE,
};
