import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import client from './_clientFormatted';
import createFileName from './utils/createFileName';
import redirectUnauthorizedToAuth from './utils/redirectUnauthorizedToAuth';
import { TransactionFiltersFormatted } from '#/types/Transaction';

const getData = async (filters: TransactionFiltersFormatted, csvList: string[]): Promise<void> => {
  const response: AxiosResponse<string> = await client.get(
    '/proxy/transactions',
    {
      params: {
        ...filters,
        format: 'csv',
      },
    },
  );
  const csvTransactions: string = response?.data || null;

  if (csvTransactions) {
    const fromId = response?.headers?.['x-next-from-id'];

    csvList.push(csvTransactions);

    if (fromId) {
      getData({
        ...filters,
        fromId,
        hasHeader: false,
      }, csvList);
    } else {
      fileDownload(csvList.join(''), createFileName(filters, 'sales'));
    }
  }
};

const getTransactionsCsv = async (
  filters: TransactionFiltersFormatted,
  onComplete?: () => void,
): Promise<void> => {
  const csvs: string[] = [];

  try {
    await getData(filters, csvs);
  } catch (err) {
    console.error(err);
    redirectUnauthorizedToAuth(err);
  }
  onComplete();
};

export default getTransactionsCsv;
