import { AxiosResponse } from 'axios';
import Stakeholder, { PostStakeholderBody } from '#/types/Stakeholder';
import client from './_clientFormatted';

const postStakeholder = async (
  request: PostStakeholderBody,
): Promise<Stakeholder> => {
  const response: AxiosResponse<Stakeholder> = await client.post('/proxy/stakeholders', request);

  return response?.data;
};

export default postStakeholder;
