import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import {
  GG,
  ScaleX,
  Labels,
} from '@graphique/graphique';
import { GeomCol } from '@graphique/geom-col';
import { scaleLog } from 'd3-scale';
import Tooltip from './Tooltip';
import LoadingComponent from './LoadingComponent';
import MissingDataComponent from '../../MissingDataComponent';
import RollupErrorComponent from '../../RollupErrorComponent';
import { Rollup, BinMeasure } from '#/types/ReportingRollup';
import { Theme } from '#/shared/graphique';
import MeasureTitle from '../../MeasureTitle';
import { EventTxSummary } from '#/pages/clientReporting/DashboardPage/types';
import {
  formatBinData,
  formatXTicks,
  STROKE_COLOR,
  FILL_COLOR,
} from './utils';

interface Props {
  loading?: boolean;
  hasError?: boolean;
  data?: Rollup[];
  measure?: BinMeasure;
  selectedEvent?: EventTxSummary;
  loadingComponent?: React.ReactNode;
  retry?: () => void;
}

const X_LABEL = 'Price per ticket';
const Y_LABEL = 'Tickets sold';
const LEFT_MARGIN = 52;
const RIGHT_MARGIN = 14;
const HEIGHT = 210;

const Histogram: React.FC<Props> = ({
  loading,
  data,
  measure = 'saleTicketCt',
  selectedEvent,
  loadingComponent = <LoadingComponent />,
  hasError,
  retry,
}) => {
  const histData = useMemo(() => data ? formatBinData(data, measure) : undefined, [data, measure]);

  const allZero = useMemo(() => (
    histData?.every((d) => d.aggregate[measure] === 0)
  ), [histData, measure]);

  const xTickFormatter = useCallback(({ value, width }: { value: number; width: number }) => {
    const xVals = histData?.map((d) => [d.group.priceLower, d.group.priceUpper]).flat();
    const [xMin, xMax] = [Math.min(...xVals), Math.max(...xVals)];

    return formatXTicks(value, xMax - xMin, width);
  }, [histData]);

  if (hasError)
    return <RollupErrorComponent retry={retry} />;

  if (!loading && allZero)
    return <MissingDataComponent label={Y_LABEL} selectedEvent={selectedEvent} />;

  return (
    <Wrapper>
      {(loading || histData?.length === 0) ? (
        loadingComponent
      ) : (
        <GG
          aes={{
            x: (d): number => d.group.priceLower,
            y: (d): number => d.aggregate[measure],
          }}
          data={histData}
          height={HEIGHT}
          isContainerWidth
          margin={{ right: RIGHT_MARGIN, left: LEFT_MARGIN }}
        >
          <GeomCol
            align='left'
            fill={FILL_COLOR}
            stroke={STROKE_COLOR}
            strokeWidth={7 / histData.length}
            xPadding={0}
          />
          <ScaleX format={xTickFormatter} type={scaleLog} />
          <Labels
            x={X_LABEL}
            y={(
              <YLabelContainer>
                <MeasureTitle event={selectedEvent} measure={Y_LABEL} />
              </YLabelContainer>
            )}
          />
          <Tooltip />
          <Theme axis={{ showAxisLines: false, label: { fontSize: 15 } }} />
        </GG>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  line-height: 1.2;
  letter-spacing: 0.1px;
`;

const YLabelContainer = styled.div`
  padding-bottom: 0.625rem;
`;

export { X_LABEL, Y_LABEL };
export default Histogram;
