/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import { Deal, PostDealRequest } from '#/types/Deal';
import {
  ButtonsWrapper,
  Header,
  Content,
  SectionWrapper,
  InnerWrapper,
  Label,
  Input,
  TextArea,
  modalMenuProps,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import Stakeholder from '#/types/Stakeholder';
import patchDeal from '#/api/patchDeal';
import postDeal from '#/api/postDeal';
import deleteDeal from '#/api/deleteDeal';
import formatApiError from '#/api/utils/formatApiError';
import Notification from '#/shared/Notification';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '../useSetupModal';
import type { GenericOptionWithId } from '#/types/GenericOption';

const CREATE_DEAL = 'Create Deal';
const UPDATE_DEAL = 'Update Deal';
const DEAL = 'Deal';
const STAKEHOLDER = 'Stakeholder';
const NAME = 'Name';
const DISPLAY_NAME = 'Display Name';
const DESCRIPTION = 'Description';
const START_DATE = 'Start Date';
const END_DATE = 'End Date';
const SAVE = 'Save';
const CANCEL = 'Cancel';
const DELETE = 'Delete';
const DATE_FORMAT = 'yyyy-MM-dd';

interface DealModalProps {
  isUpdate: boolean;
  deals: Deal[];
  stakeholders: Stakeholder[];
  closeModal: () => void;
}

const getStakeholderLabel = (
  s: Stakeholder,
): { value: number; label: string } => {
  return { value: s.id, label: s.displayName };
};

const getDate = (
  deal: Deal,
  postDealRequest: PostDealRequest,
  dateField: 'startDate' | 'endDate',
): string => {
  if (postDealRequest && postDealRequest[dateField])
    return postDealRequest[dateField];
  if (deal && deal[dateField])
    return deal[dateField].toFormat(DATE_FORMAT);
  return '';
};

const DealModal: React.FC<DealModalProps> = ({
  isUpdate,
  deals,
  stakeholders,
  closeModal,
}) => {
  const stakeholderOptions = stakeholders.map(getStakeholderLabel);
  const [stakeholder, setStakeholder] = useState<Stakeholder>(null);
  const [deal, setDeal] = useState<Deal>(null);
  const [postDealRequest, setPostDealRequest] = useState<PostDealRequest>({});
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const cancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  useSetupModal(cancel);

  const saveDeal = useCallback(async () => {
    try {
      setError('');
      let newDeal: Deal;

      if (isUpdate)
        newDeal = await patchDeal(deal.id, postDealRequest);
      else
        newDeal = await postDeal(postDealRequest);

      if (newDeal.id) {
        setSuccess(true);
        setTimeout(closeModal, 1000);
      }
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [deal, postDealRequest, setError, closeModal, isUpdate]);

  const deleteCurrentDeal = useCallback(async () => {
    try {
      setError('');
      if (isUpdate && deal?.id) {
        await deleteDeal(deal.id);
        setSuccess(true);
        setTimeout(closeModal, 1000);
      }
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [deal, setError, closeModal, isUpdate]);

  const handleSelectDeal = useCallback((e: GenericOptionWithId<string, number>) => {
    const dealId = Number(e?.value) || null;

    if (dealId) {
      const selectedDeal = deals.find((d) => d.id === dealId);

      setDeal(selectedDeal);
    }
  }, [deals, setDeal]);

  const handleSelectStakeholder = useCallback((e: GenericOptionWithId<string, number>) => {
    const stakeholderId = Number(e?.value) || null;

    if (stakeholderId) {
      const newStakeholder = stakeholders.find((s) => s.id === stakeholderId);

      setStakeholder(newStakeholder);
      if (!isUpdate) {
        // We only want to allow stakeholder to be modified
        // for new deals. If we are updating an existing deal,
        // we will not set the stakeholder in the PATCH request.
        setPostDealRequest((d) => ({
          ...d,
          stakeholderId,
        }));
      }
    }
  }, [stakeholders, isUpdate, setStakeholder, setPostDealRequest]);

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e?.target?.value;

    if (typeof newName === 'string') {
      setPostDealRequest((d) => ({
        ...d,
        name: newName,
      }));
    }
  }, [setPostDealRequest]);

  const handleDisplayNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newDisplayName = e?.target?.value;

    if (typeof newDisplayName === 'string') {
      setPostDealRequest((d) => ({
        ...d,
        displayName: newDisplayName,
      }));
    }
  }, [setPostDealRequest]);

  const handleDescriptionChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newDescription = e?.target?.value;

    if (typeof newDescription === 'string') {
      setPostDealRequest((d) => ({
        ...d,
        description: newDescription,
      }));
    }
  }, [setPostDealRequest]);

  const handleStartDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e?.target?.value;

    if (newStartDate) {
      setPostDealRequest((d) => ({
        ...d,
        startDate: newStartDate,
      }));
    }
  }, [setPostDealRequest]);

  const handleEndDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e?.target?.value;

    if (newEndDate) {
      setPostDealRequest((d) => ({
        ...d,
        endDate: newEndDate,
      }));
    }
  }, [setPostDealRequest]);

  useSetupModal(cancel);

  return (
    <Modal
      isOpen
      onRequestClose={cancel}
      style={modalStyles}
    >
      <Header>{isUpdate ? UPDATE_DEAL : CREATE_DEAL}</Header>
      <Notification error={error} success={success} />
      <SectionWrapper>
        <Content>
          <InnerWrapper>
            <Label>{STAKEHOLDER}</Label>
            <Select
              isClearable
              isDisabled={!!deal}
              onChange={handleSelectStakeholder}
              options={stakeholderOptions}
              {...modalMenuProps}
            />
          </InnerWrapper>
          { isUpdate && stakeholder && (
            <InnerWrapper>
              <Label>{DEAL}</Label>
              <Select
                isClearable
                onChange={handleSelectDeal}
                options={
                  deals
                    .filter((d) => d.stakeholderId === stakeholder.id)
                    .map((d) => ({ value: d.id, label: d.displayName }))
                }
                {...modalMenuProps}
              />
            </InnerWrapper>
          )}
          { (!isUpdate || deal) && (
            <>
              <InnerWrapper>
                <Label>{NAME}</Label>
                <Input
                  onChange={handleNameChange}
                  type="string"
                  value={postDealRequest?.name ?? deal?.name ?? ''}
                />
              </InnerWrapper>
              <InnerWrapper>
                <Label>{DISPLAY_NAME}</Label>
                <Input
                  onChange={handleDisplayNameChange}
                  type="string"
                  value={postDealRequest.displayName ?? deal?.displayName ?? ''}
                />
              </InnerWrapper>
              <InnerWrapper>
                <Label>{DESCRIPTION}</Label>
                <TextArea
                  onChange={handleDescriptionChange}
                  value={postDealRequest?.description ?? deal?.description ?? ''}
                />
              </InnerWrapper>
              <InnerWrapper>
                <Label>{START_DATE}</Label>
                <input
                  onChange={handleStartDateChange}
                  type='date'
                  value={getDate(deal, postDealRequest, 'startDate')}
                />
              </InnerWrapper>
              <InnerWrapper>
                <Label>{END_DATE}</Label>
                <input
                  onChange={handleEndDateChange}
                  type='date'
                  value={getDate(deal, postDealRequest, 'endDate')}
                />
              </InnerWrapper>
            </>
          )}
        </Content>
        <ButtonsWrapper>
          <Button onClick={saveDeal}>{SAVE}</Button>
          <Button onClick={cancel}>{CANCEL}</Button>
          {
            isUpdate
              && deal?.id
              && <Button onClick={deleteCurrentDeal}>{DELETE}</Button>
          }
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

export default DealModal;
