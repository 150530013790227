import React from 'react';
import styled from 'styled-components';

interface Props {
  content?: React.ReactNode;
}

const ErrorContent: React.FC<Props> = ({ content }) => (
  <Container>{content}</Container>
);

const Container = styled.div`
  width: 100%;
  position: relative;
  top: calc(30% + 20px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ErrorContent;
