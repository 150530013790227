import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Ingestion from '#/types/Ingestion';

const STAKEHOLDER = 'Stakeholder';
const FROM_PURCHASE = 'From Purchase';
const FROM_RETURN = 'From Return';
const FROM_USER_LISTING = 'From User Listing';
const DEAL_TERMS = 'Deal Terms';
const BULK_LISING_GROUP = 'Bulk Upload';

interface CardProps {
  ingestion: Ingestion;
}

const TrackingCard: React.FC<CardProps> = ({ ingestion }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{STAKEHOLDER}</Name>
        <Value>{ingestion.stakeholder}</Value>
      </Element>
      { ingestion.deal && (
        <Element>
          <Name>{DEAL_TERMS}</Name>
          <Value>{ingestion.deal}</Value>
          <Value>{ingestion.deal_term}</Value>
        </Element>
      )}
      {ingestion.seatgeek_user_purchase_id && (
        <Element>
          <Name>{FROM_PURCHASE}</Name>
          <Value>
            <a
              href={`https://rufus.seatgeekadmin.com/search?type=orders&filter=purchase_id&purchase_id=${ingestion.seatgeek_user_purchase_id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {ingestion.seatgeek_user_purchase_id}
            </a>
          </Value>
        </Element>
      )}
      {ingestion.seatgeek_user_listing && (
        <Element>
          <Name>{FROM_USER_LISTING}</Name>
          <Value>
            <a
              href={`https://rufus.seatgeekadmin.com/listings/${ingestion.seatgeek_user_listing.display_id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {ingestion.seatgeek_user_listing.id}
            </a>
            {ingestion.seatgeek_user_listing.bulk_id && (
              <>
                {', '}
                <a
                  href={`/ingestions?seatgeek_bulk_user_listing_id=${ingestion.seatgeek_user_listing.bulk_id}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {BULK_LISING_GROUP}
                </a>
              </>
            )}
          </Value>
        </Element>
      )}
      {ingestion.seatgeek_user_purchase_return_id && (
        <Element>
          <Name>{FROM_RETURN}</Name>
          <Value>
            {ingestion.seatgeek_user_purchase_return_id}
          </Value>
        </Element>
      )}
    </NarrowWrapper>
  );
};

export default TrackingCard;
