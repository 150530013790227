import React, { useContext } from 'react';
import { SinkHeaders } from './constants';
import formatSinkInfo, { SinkTableData } from './utils/formatSinkInfo';
import { ActiveEventContext } from '../../contexts/ActiveEvent';
import TableWrapper, {
  Cell,
  Header,
  RowWrapper,
} from '#/shared/TableComponents';
import Event, { SinkEvent } from '#/types/Event';
import { User } from '#/types/User';

interface SinkTableProps {
  user: User;
  columns: SinkHeaders;
  setActiveEventSink: (value: SinkEvent) => void;
  setSinkModalIsOpen: (input: boolean) => void;
  tableData: SinkTableData[];
}

interface SinkRowProps {
  disabled: boolean;
  activeEvent: Event;
  setActiveEventSink: (value: SinkEvent) => void;
  setSinkModalIsOpen: (input: boolean) => void;
  sinkId: number;
  columnNames: Array<keyof SinkHeaders>;
}

const buildHandler = (
  disabled: boolean,
  sink: SinkEvent,
  setActiveEventSink: (value: SinkEvent) => void,
  setSinkModalIsOpen: (input: boolean) => void,
): (() => void
  ) => {
  const handleClick = (): void => {
    if (!disabled) {
      setActiveEventSink(sink);
      setSinkModalIsOpen(true);
    }
  };

  return handleClick;
};

const SinkRow: React.FC<SinkRowProps> = ({
  disabled,
  activeEvent,
  setActiveEventSink,
  setSinkModalIsOpen,
  sinkId,
  columnNames,
}) => {
  const { sinkEvents } = activeEvent;
  const sink = sinkEvents.filter((el: SinkEvent) => el.id === sinkId)[0];
  const formatted = formatSinkInfo(sink);
  const handleClick = buildHandler(disabled, sink, setActiveEventSink, setSinkModalIsOpen);

  return (
    <RowWrapper className='sink' key={sinkId} onClick={handleClick}>
      {columnNames.map((el) => (
        <Cell key={`${el}_${formatted[el]}`}>{formatted[el]}</Cell>
      ))}
    </RowWrapper>
  );
};

const SinkTable: React.FC<SinkTableProps> = ({
  user,
  columns,
  setActiveEventSink,
  setSinkModalIsOpen,
  tableData,
}) => {
  const columnNames = Object.keys(columns) as Array<keyof typeof columns>;
  const { activeEvent } = useContext(ActiveEventContext);

  return (
    <TableWrapper>
      <thead>
        <Header>
          {columnNames.map((el) => (
            <Cell key={`${columns[el]}_${el}`}>{columns[el]}</Cell>
          ))}
        </Header>
      </thead>
      <tbody>
        {tableData.map((sink) => (
          <SinkRow
            activeEvent={activeEvent}
            columnNames={columnNames}
            disabled={user.isReadOnly}
            key={sink.id}
            setActiveEventSink={setActiveEventSink}
            setSinkModalIsOpen={setSinkModalIsOpen}
            sinkId={sink.id}
          />
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default SinkTable;
