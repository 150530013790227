import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import PriceLevel from '#/types/PriceLevel';

const STAKEHOLDER = 'Stakeholder';
const PACKAGE_TYPES = 'Package Type';
const UNASSIGNED = 'Unassigned';

interface CardProps {
  priceLevel: PriceLevel;
}

const StakeholderCard: React.FC<CardProps> = ({ priceLevel }) => {
  const { stakeholders, peakpassPackageTypes } = priceLevel.summary;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{STAKEHOLDER}</Name>
        <Value>{stakeholders.length > 0 ? stakeholders.join('\n') : UNASSIGNED}</Value>
      </Element>
      {peakpassPackageTypes.length > 0 && (
        <Element>
          <Name>{PACKAGE_TYPES}</Name>
          <Value>{peakpassPackageTypes.join('\n')}</Value>
        </Element>
      )}
    </NarrowWrapper>
  );
};

export default StakeholderCard;
