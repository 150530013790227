import client from './_client';
import Event, { APIEvent, EventRebuildApiPost } from '#/types/Event';
import mapEvent from './utils/mapEvent';

const postEventRebuild = async (id: number, partitions: EventRebuildApiPost): Promise<Event[]> => {
  const response = await client.post(`/proxy/event/${id}/rebuild`, partitions);

  const events: APIEvent[] = response?.data || null;

  return events.map(mapEvent);
};

export default postEventRebuild;
