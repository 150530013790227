interface PriceConstraint {
  price_floor: number | null;
  price_ceiling: number | null;
  price_constraint_type: PriceConstraintType;
}

interface SectionRowPriceConstraint extends PriceConstraint {
  section: string | null;
  row: string | null;
}

interface APIPriceConstraint {
  id: number;
  autobroker_event_id: number;
  created_at: string;
  raw_price_constraints: SectionRowPriceConstraint[];
}

enum PriceConstraintType {
  BROADCAST_PRICE = 'broadcast_price',
  DISPLAY_PRICE = 'display_price',
  ALL_IN_PRICE = 'all_in_price',
}

const PRICE_CONSTRAINT_TYPES = [
  PriceConstraintType.BROADCAST_PRICE,
  PriceConstraintType.DISPLAY_PRICE,
  PriceConstraintType.ALL_IN_PRICE,
];

export {
  PriceConstraint,
  APIPriceConstraint,
  SectionRowPriceConstraint,
  PRICE_CONSTRAINT_TYPES,
  PriceConstraintType,
};
