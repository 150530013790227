type Measure = 'Listings' | 'Sales';

const CANCEL = 'Cancel';
const DOWNLOAD = 'Download CSV';
const PER_PAGE = 20000;
const BY_SALES_DATE = 'By Sales Date';
const BY_EVENT = 'By Event';
const BY_DEAL_OR_TERMS = 'By Deal / Terms';
const START_DATE = 'Start date';
const END_DATE = 'End date';
const MEASURES: Measure[] = ['Sales', 'Listings'];

export {
  Measure,
  CANCEL,
  DOWNLOAD,
  PER_PAGE,
  BY_SALES_DATE,
  BY_EVENT,
  BY_DEAL_OR_TERMS,
  START_DATE,
  END_DATE,
  MEASURES,
};
