import React from 'react';
import styled from 'styled-components';

interface Props {
  error: string;
  success: boolean;
}

const SUCCESS = 'Success!';

const Notification: React.FC<Props> = ({ error, success }) => {
  return (
    <>
      {error && <Wrapper>{error}</Wrapper>}
      {success && <Wrapper success>{SUCCESS}</Wrapper>}
    </>
  );
};

const Wrapper = styled.div<{ success?: boolean }>`
  ${({ theme }: { theme: Theme }): string => theme.text1};
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid;
  width: 85%;
  min-height: 2rem;
  padding: 1rem;
  ${({ theme, success }: { theme: Theme; success: boolean }): string => {
    if (success) {
      return `
        background-color: ${theme.color.background.success}
        color: ${theme.color.text.success}
      `;
    }
    return `
    background-color: ${theme.color.background.error}
    color: ${theme.color.text.error}
    `;
  }};
`;

export default Notification;
