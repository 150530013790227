import { DateTime, FixedOffsetZone } from 'luxon';
import Event, {
  APIEvent,
  PriceExploration,
  RawEventError,
  RawModels,
  RawOnsalePricingModel,
  RawSelldownProjectionModel,
  RawPartition,
  RawPriceExploration,
  RawSinkEvent,
  RawSourceEvent,
  RawSummary,
  RawSinkSummary,
  SinkEvent,
  SourceEvent,
  StrategyType,
  EventConfig,
  Summary,
  SinkSummary,
  APIEventConfig,
  RawSchedule,
  Schedule,
  Partition,
  SectionSubsetType,
  PricingBasis,
  TrainingSetType,
  Models,
  OnsalePricingModel,
  SelldownProjectionModel,
  RawSalesPeriod,
  SalesPeriod,
} from '#/types/Event';
import determineEventIsActive from './determineEventIsActive';
import formatEventStartsAtString from './formatEventStartsAtString';
import { EventError } from '#/types/Errors';

const mapPriceExploration = (
  exploration: RawPriceExploration,
): PriceExploration => {
  const parsed = {
    autobrokerEventId: null,
    frequency: null,
    id: null,
    lowerPercentile: null,
    strategyType: StrategyType.NONE as StrategyType,
    upperPercentile: null,
  } as PriceExploration;

  if (!exploration)
    return parsed;

  parsed.autobrokerEventId = exploration.autobroker_event_id;
  parsed.frequency = exploration.frequency;
  parsed.id = exploration.id;
  parsed.lowerPercentile = exploration.lower_percentile;
  parsed.strategyType = exploration.strategy_type as StrategyType;
  parsed.upperPercentile = exploration.upper_percentile;

  return parsed;
};

const mapSinkEvents = (sinkEvents: RawSinkEvent[]): SinkEvent[] => {
  return sinkEvents.map(
    (sinkEvent: RawSinkEvent): SinkEvent => {
      return {
        activatedAt: DateTime.fromISO(sinkEvent.activated_at, { zone: 'utc' }),
        autobrokerEventId: sinkEvent.autobroker_event_id,
        id: sinkEvent.id,
        listingMaxCount: sinkEvent.listing_max_count,
        listingMinCount: sinkEvent.listing_min_count,
        marketplaces: sinkEvent.marketplaces.sort(),
        pVisible: sinkEvent.p_visible,
        qaMode: sinkEvent.qa_mode,
        resetVisibility: sinkEvent.reset_visibility,
        churnVisibilityRate: sinkEvent.churn_visibility_rate,
        priceIncrement: sinkEvent.price_increment,
        sinkExternalEventId: sinkEvent.sink_external_event_id,
        sinkId: sinkEvent.sink_id,
        sinkName: sinkEvent.sink_name,
      };
    },
  );
};

const mapSourceEvents = (sourceEvents: RawSourceEvent[]): SourceEvent[] => {
  return sourceEvents.map(
    (sourceEvent: RawSourceEvent): SourceEvent => {
      return {
        createdAt: DateTime.fromISO(sourceEvent.created_at, { zone: 'utc' }),
        expiredAt: sourceEvent.expired_at
          ? DateTime.fromISO(sourceEvent.expired_at, { zone: 'utc' })
          : null,
        id: sourceEvent.id,
        sourceExternalEventId: sourceEvent.source_external_event_id,
        sourceId: sourceEvent.source_id,
        sourceName: sourceEvent.source_name,
        candidateSinks: sourceEvent.candidate_sinks,
        candidatePriceGranularities: sourceEvent.candidate_price_granularities,
        updatedAt: DateTime.fromISO(sourceEvent.updated_at, { zone: 'utc' }),
      };
    },
  );
};

const mapSinkSummary = (summary: RawSinkSummary): SinkSummary => {
  return {
    sinkName: summary.sink_name,
    listingCt: summary.listing_ct,
    listingTicketCt: summary.listing_ticket_ct,
  };
};

const mapSummary = (summary: RawSummary): Summary => {
  return {
    ingestedCt: summary.ingested_ct,
    ingestedTicketCt: summary.ingested_ticket_ct,
    ingestedCost: summary.ingested_cost,
    ingestedFaceValue: summary.ingested_face_value,
    listingCt: summary.listing_ct,
    listingTicketCt: summary.listing_ticket_ct,
    constrainedListingCt: summary.constrained_listing_ct,
    constrainedListingTicketCt: summary.constrained_listing_ticket_ct,
    saleCt: summary.sale_ct,
    saleTicketCt: summary.sale_ticket_ct,
    saleGtv: summary.sale_gtv,
    saleCost: summary.sale_cost,
    saleFaceValue: summary.sale_face_value,
    portfolioValue: summary.portfolio_value,
    portfolioValueSelldownDerivative: summary.portfolio_value_selldown_derivative,
    priceTypeIds: summary.price_type_ids,
    isUnmanifested: summary.is_unmanifested,
    inventorySourceTypes: summary.inventory_source_types,
    visible: summary.visible.map((visible: RawSinkSummary) => mapSinkSummary(visible)),
  };
};

const mapPartition = (partition: RawPartition): Partition => {
  return {
    autobrokerEventId: partition.autobroker_event_id,
    sectionSubsetType: partition.section_subset_type as SectionSubsetType,
  };
};

const mapSchedule = (schedule: RawSchedule): Schedule => {
  return {
    id: schedule.id,
    autobrokerEventId: schedule.autobroker_event_id,
    isScheduled: schedule.is_scheduled,
    reason: schedule.reason,
    ticketsSoldSinceLastRun: schedule.tickets_sold_since_last_run,
    status: schedule.status,
    lastRunAt: DateTime.fromISO(schedule.last_run_at, { zone: 'utc' }),
    estimatedNextRunAt: DateTime.fromISO(schedule.estimated_next_run_at, { zone: 'utc' }),
    createdAt: DateTime.fromISO(schedule.created_at, { zone: 'utc' }),
    updatedAt: DateTime.fromISO(schedule.updated_at, { zone: 'utc' }),
  };
};

const mapEventConfig = (rawConfig: APIEventConfig, groups: string[]): EventConfig => {
  const {
    seatgeek_event_id,
    listings_removed_at,
    map_config_id,
    map_config_key,
    pricing_mode,
    reset_prices,
    supervisor_starts_at,
    supervisor_stops_at,
    onsale_starts_at,
    event_starts_at,
    event_starts_at_local,
    event_start_time_status,
    keep_supervisor_active,
    use_seatgeek_transactions,
    demand_changepoint_ct,
    pricing_basis,
    onsale_demand_fraction,
    selldown_projection_model,
    onsale_pricing_model,
    dynamic_pricing_model,
    section_subset,
    created_at,
    updated_at,
  } = rawConfig;

  const eventStartsAt = DateTime.fromISO(event_starts_at, { zone: 'utc' });
  const eventStartsAtLocal = event_starts_at_local
    ? DateTime.fromISO(event_starts_at_local, { zone: 'utc' }) : eventStartsAt;
  const [
    eventDateString,
    eventTimeString,
  ] = formatEventStartsAtString(eventStartsAt, eventStartsAtLocal);

  const timezoneOffsetMins = eventStartsAtLocal.diff(eventStartsAt, 'minutes').minutes;
  const timezone = FixedOffsetZone.instance(timezoneOffsetMins);

  const config: EventConfig = {
    eventStartsAt,
    eventTimeString,
    eventDateString,
    timezone,
    eventStartTimeStatus: event_start_time_status,
    listingsRemovedAt: listings_removed_at
      ? DateTime.fromISO(listings_removed_at, { zone: 'utc' })
      : null,
    mapConfigId: parseInt(map_config_id, 10),
    mapConfigKey: map_config_key,
    onsaleStartsAt: DateTime.fromISO(onsale_starts_at, { zone: 'utc' }),
    pricingMode: pricing_mode,
    resetPrices: reset_prices,
    keepSupervisorActive: keep_supervisor_active,
    useSeatgeekTransactions: use_seatgeek_transactions,
    demandChangepointCt: demand_changepoint_ct,
    pricingBasis: pricing_basis as PricingBasis,
    onsaleDemandFraction: onsale_demand_fraction,
    selldownProjectionModel: selldown_projection_model as TrainingSetType,
    onsalePricingModel: onsale_pricing_model as TrainingSetType,
    dynamicPricingModel: dynamic_pricing_model,
    seatgeekEventId: parseInt(seatgeek_event_id, 10),
    supervisorStartsAt: DateTime.fromISO(supervisor_starts_at, {
      zone: 'utc',
    }),
    supervisorStopsAt: DateTime.fromISO(supervisor_stops_at, { zone: 'utc' }),
    sectionSubset: section_subset,
    groups,
    createdAt: DateTime.fromISO(created_at, { zone: 'utc' }),
    updatedAt: DateTime.fromISO(updated_at, { zone: 'utc' }),
  };

  config.isActive = determineEventIsActive(config.supervisorStopsAt);

  return config;
};

const mapOnsalePricingModel = (model: RawOnsalePricingModel): OnsalePricingModel => {
  return {
    id: model.id,
    mapConfigKey: model.map_config_key,
    maxQuantity: model.max_quantity,
    sectionSubsetTicketFraction: model.section_subset_ticket_fraction,
    trainingSetType: model.training_set_type as TrainingSetType,
    modelVersion: model.model_version,
    createdAt: DateTime.fromISO(model.created_at, { zone: 'utc' }),
  };
};

const mapSellDownProjectionModel = (model: RawSelldownProjectionModel): SelldownProjectionModel => {
  return {
    id: model.id,
    onsaleStartsAt: DateTime.fromISO(model.onsale_starts_at, { zone: 'utc' }),
    eventStartsAt: DateTime.fromISO(model.event_starts_at, { zone: 'utc' }),
    binSizeSeconds: model.bin_size_seconds,
    trainingSetType: model.training_set_type as TrainingSetType,
    onsaleDemandFraction: model.onsale_demand_fraction,
    createdAt: DateTime.fromISO(model.created_at, { zone: 'utc' }),
  };
};

const mapModels = (models: RawModels): Models => {
  const { selldown_projection_model, onsale_pricing_model } = models;

  return {
    selldownProjectionModel: (
      selldown_projection_model
      && mapSellDownProjectionModel(selldown_projection_model)
    ),
    onsalePricingModel: (
      onsale_pricing_model
      && mapOnsalePricingModel(onsale_pricing_model)
    ),
  };
};

const mapEventError = (error: RawEventError): EventError => {
  return {
    id: error.id,
    errorType: error.error_type,
    errorLevel: error.error_level,
    errorReason: error.error_reason,
    errorStatus: (
      error.error_status
      && {
        ...error.error_status,
        userId: error.error_status.user_id,
        userName: error.error_status.user_name,
        createdAt: DateTime.fromISO(error.error_status.created_at, { zone: 'utc' }),
      }
    ),
    createdAt: DateTime.fromISO(error.created_at, { zone: 'utc' }),
  };
};

const mapSalesPeriod = (period: RawSalesPeriod): SalesPeriod => {
  return {
    id: period.id,
    type: period.type,
    startsAt: DateTime.fromISO(period.starts_at, { zone: 'utc' }),
    endsAt: DateTime.fromISO(period.ends_at, { zone: 'utc' }),
    demandPrior: period.demand_prior,
    accessCodes: period.access_codes,
    createdAt: DateTime.fromISO(period.created_at, { zone: 'utc' }),
    updatedAt: DateTime.fromISO(period.updated_at, { zone: 'utc' }),
  };
};

/* eslint-disable camelcase */
const mapEvent = (rawEvent: APIEvent): Event => {
  const {
    id,
    config,
    groups,
    exploration,
    sink_events,
    source_events,
    models,
    summary,
    schedule,
    partitions,
    event_errors,
    sales_periods,
  } = rawEvent;
  const {
    title, venue, is_ga, event_type,
  } = config;

  const parsedConfig = mapEventConfig(config, groups);
  const sinkEvents = mapSinkEvents(sink_events);
  const sourceEvents = mapSourceEvents(source_events);
  const parsedModels = mapModels(models);
  const priceExploration = mapPriceExploration(exploration);
  const parsedSummary = mapSummary(summary);
  const parsedSchedule = schedule === null ? null : mapSchedule(schedule);
  const parsedPartitions = partitions.map(mapPartition);
  const eventErrors = event_errors.map(mapEventError);
  const salesPeriods = sales_periods.map(mapSalesPeriod);

  return {
    config: parsedConfig,
    priceExploration,
    id,
    groups,
    sinkEvents,
    sourceEvents,
    models: parsedModels,
    title,
    venue,
    isGa: is_ga,
    eventType: event_type,
    summary: parsedSummary,
    schedule: parsedSchedule,
    partitions: parsedPartitions,
    eventErrors,
    salesPeriods,
    hasPriceConstraints: false,
  };
};

export default mapEvent;
