import { TransactionFilters } from '#/types/Transaction';

function queryToTransactionFilters(search: string): TransactionFilters {
  const query = new URLSearchParams(search);
  const filters: TransactionFilters = {};

  if (query.has('autobroker_event_id'))
    filters.autobroker_event_id = Number(query.get('autobroker_event_id'));

  if (query.has('seatgeek_event_id'))
    filters.seatgeek_event_id = Number(query.get('seatgeek_event_id'));

  if (query.has('source_name'))
    filters.source_name = String(query.get('source_name'));

  if (query.has('sink_name'))
    filters.sink_name = String(query.get('sink_name'));

  if (query.has('group'))
    filters.group = String(query.get('group'));

  if (query.has('stakeholder'))
    filters.stakeholder = String(query.get('stakeholder'));

  if (query.has('date'))
    filters.date = String(query.get('date'));

  if (query.has('state'))
    filters.state = String(query.get('state'));

  if (query.has('from_id'))
    filters.from_id = Number(query.get('from_id'));

  if (query.has('per_page'))
    filters.per_page = Number(query.get('per_page'));

  if (query.has('section'))
    filters.section = String(query.get('section'));

  if (query.has('row'))
    filters.row = String(query.get('row'));

  if (query.has('deal'))
    filters.deal = String(query.get('deal'));

  if (query.has('deal_term'))
    filters.deal_term = String(query.get('deal_term'));

  if (query.has('sort_by'))
    filters.sort_by = String(query.get('sort_by'));

  if (query.has('sort_asc'))
    filters.sort_asc = Number(query.get('sort_asc'));

  return filters;
}

export default queryToTransactionFilters;
