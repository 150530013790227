import { TableColumn } from 'react-data-table-component';
import { FormattedEventError } from '#/types/Errors';
import {
  formatColName,
  formatTableCell,
  renderTableCell,
  ErrorColumns,
  sortFunction,
  growTableCell,
} from './helpers';

const generateColumns = (errors: FormattedEventError[]): TableColumn<FormattedEventError>[] => {
  const uniqueKeys = Array.from(
    new Set((errors.flatMap((e: FormattedEventError) => Object.keys(e)))),
  );

  return uniqueKeys
    .map((key: keyof FormattedEventError) => ({
      id: key,
      name: formatColName(key),
      selector: (row: FormattedEventError): any => row[key],
      sortable: true,
      cell: renderTableCell(key),
      right: key === ErrorColumns.EVENT_DATE,
      omit: key === ErrorColumns.ID || key === ErrorColumns.EVENT_DATE_DISPLAY,
      format: formatTableCell(key),
      grow: growTableCell(key),
      sortFunction: sortFunction(key),
    }));
};

export default generateColumns;
