import { DateTime } from 'luxon';

interface APITransactions {
  transactions: APITransaction[];
  request: APIRequest;
}

interface Transaction {
  id: number;
  sink_transaction_id: string;
  autobroker_event_id: number;
  title: string;
  event_starts_at: DateTime;
  event_starts_at_local: string;
  source: string;
  sink: string;
  stakeholder: string | null;
  deal: string | null;
  deal_term: string | null;
  marketplace: string | null;
  section: string;
  row: string;
  quantity: number;
  seats: string;
  expected_value: number | null;
  submitted_price_per_ticket: number | null;
  reported_cost_per_ticket: number | null;
  face_value_per_ticket: number | null;
  gtv: number | null;
  recoupment_rate: number | null;
  price_constraint: string;
  state: string;
  transacted_at: DateTime;
}

interface APITransaction extends Omit<
Transaction,
'event_starts_at'|'event_starts_at_local'|'gtv'
|'recoupment_rate'|'price_constraint'|'transacted_at'
> {
  event_starts_at: string;
  event_starts_at_local: string;
  price_constraint: APIPriceConstraint;
  transacted_at: string;
}

interface APIRequest {
  per_page: number;
}

interface APIPriceConstraint {
  type: string;
  floor?: number;
  ceiling?: number;
}

type TransactionFormat = 'csv' | 'json';

interface TransactionFilters {
  autobroker_event_id?: number;
  seatgeek_event_id?: number;
  source_name?: string;
  sink_name?: string;
  group?: string;
  stakeholder?: string;
  state?: string;
  date?: string;
  from_id?: number;
  per_page?: number;
  format?: TransactionFormat;
  from_event_date?: string;
  to_event_date?: string;
  from_date?: string;
  to_date?: string;
  has_header?: boolean;
  section?: string;
  row?: string;
  deal?: string;
  deal_term?: string;
  sort_by?: string;
  sort_asc?: number;
  time_zone?: string;
}

interface TransactionFiltersFormatted {
  autobrokerEventId?: number;
  seatgeekEventId?: number;
  sourceName?: string;
  sinkName?: string;
  group?: string;
  stakeholder?: string;
  state?: string;
  date?: string;
  fromId?: number;
  perPage?: number;
  format?: TransactionFormat;
  fromEventDate?: string;
  toEventDate?: string;
  fromDate?: string;
  toDate?: string;
  hasHeader?: boolean;
  deal?: string;
  dealTerm?: string;
  sortBy?: string;
  sortAsc?: number;
  timeZone?: string;
}

enum STATES {
  CREATED = 'created',
  PURCHASED = 'purchased',
  FULFILLED = 'fulfilled',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
}

enum SOURCES {
  ONETICKET = 'oneticket',
  PEAKPASS = 'peakpass',
  TICKETVAULT = 'ticketvault',
  CONSUMER = 'consumer',
}

export {
  APITransactions,
  APITransaction,
  APIPriceConstraint,
  TransactionFilters,
  TransactionFiltersFormatted,
  STATES,
  SOURCES,
};
export default Transaction;
