import React, { useState, useCallback, useEffect } from 'react';
import ListingsTable from './ListingsTable';
import { User } from '#/types/User';
import Listing from '#/types/Listing';
import { UseListingsHook } from './useFetchListings';
import ListingDetail from './ListingDetail';
import ListingOverrideModal, { InputListing } from '#/shared/ListingOverrideModal';
import MultiListingsDetail from './MultiListingsDetail';
import { StakeholderLogos } from '#/pages/useFetchStakeholders';

interface ListingsPageProps {
  user: User;
  listingsHook: UseListingsHook;
  stakeholderLogos: StakeholderLogos;
}

const ListingsPage: React.FC<ListingsPageProps> = ({
  user,
  listingsHook,
  stakeholderLogos,
}) => {
  const [selected, setSelected] = useState<Listing[]>([]);
  const [closeable, setCloseable] = useState<boolean>(true);
  const [overrideListingModal, setOverrideListingModal] = useState<InputListing[]>([]);
  const { listings, pages, retry } = listingsHook;

  // if there is only one listing, show it, and remove the close button
  useEffect(() => {
    if (listings.length === 1 && pages.length === 0) {
      setSelected([listings[0]]);
      setCloseable(false);
      return;
    }
    setCloseable(true);
  }, [setSelected, setCloseable, listings, pages.length]);

  const closeDetail = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  const closeOverrideModal = useCallback(() => {
    setOverrideListingModal([]);
    retry();
  }, [setOverrideListingModal, retry]);

  const canEdit = user.isReadWrite;

  return (
    <>
      {selected.length === 1 && (
        <ListingDetail
          canEdit={canEdit}
          closeDetail={closeDetail}
          closeable={closeable}
          listing={selected[0]}
          setOverrideListingModal={setOverrideListingModal}
        />
      )}
      {selected.length > 1 && (
        <MultiListingsDetail
          canEdit={canEdit}
          closeDetail={closeDetail}
          listings={selected}
          setOverrideListingModal={setOverrideListingModal}
        />
      )}
      <ListingsTable
        canEdit={canEdit}
        listingsHook={listingsHook}
        selectedIds={selected.map((s) => s.id)}
        setOverrideListingModal={setOverrideListingModal}
        setSelected={setSelected}
        stakeholderLogos={stakeholderLogos}
      />
      {canEdit && overrideListingModal.length > 0 && (
        <ListingOverrideModal closeModal={closeOverrideModal} listings={overrideListingModal} />
      )}
    </>
  );
};

export default ListingsPage;
