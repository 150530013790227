import { AxiosResponse } from 'axios';
import { SalesPeriodType, UpdateSalesPeriod, UpdateSalesPeriods } from '#/types/Event';
import client from './_clientFormatted';

interface GetSalesPeriodSuggestionsParams {
  autobrokerEventId?: number;
  seatgeekEventId?: number;
  type?: SalesPeriodType;
}

const getSalesPeriodSuggestions = async (
  params : GetSalesPeriodSuggestionsParams,
): Promise<UpdateSalesPeriod[]> => {
  const response: AxiosResponse<UpdateSalesPeriods> = await client.get(
    '/proxy/sales_periods/suggestions',
    { params },
  );

  return response?.data.salesPeriods;
};

export default getSalesPeriodSuggestions;
