import React, { useState, useCallback, useContext } from 'react';
import Modal from 'react-modal';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import { OverrideChangeLogEntry, PatchOverrideNotesRequest } from '#/types/Override';
import formatApiError from '#/api/utils/formatApiError';
import {
  ButtonsWrapper,
  InnerWrapper,
  Label,
  SectionWrapper,
  TextArea,
  Title,
  Error,
  Content,
} from '#/shared/modalComponents';
import { WhiteSpaceValue } from '#/shared/Card';
import { formatChanges } from '#/pages/ViewPage/components/OverrideLogs/OverrideLogsTable/utils/helpers';
import Button from '#/shared/Button';
import patchOverrideNotes from '#/api/patchOverrideNotes';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';

const TITLE = 'Edit Override Log Notes';
const NOTES = 'Notes';
const SUBMIT = 'Submit';
const CANCEL = 'Cancel';

interface OverrideNotesModalProps {
  log: OverrideChangeLogEntry;
  closeModal: () => void;
}

const OverrideNotesModal: React.FC<OverrideNotesModalProps> = ({ log, closeModal }) => {
  const {
    type, ids, isDeleted, changes, notes,
  } = log;
  const { activeEvent } = useContext(ActiveEventContext);
  const id = activeEvent?.id;

  const [error, setError] = useState<string>(null);
  const [overrideNotes, setOverrideNotes] = useState<PatchOverrideNotesRequest>({
    type,
    overrideIds: ids,
    isDeleted,
    notes: notes,
  });

  const onChangeNotes = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOverrideNotes({
      ...overrideNotes,
      notes: e.target.value,
    });
  }, [overrideNotes]);

  const submitChangeNotes = useCallback(async () => {
    try {
      await patchOverrideNotes(id, overrideNotes);
      setError(null);
      closeModal();
    } catch (err) {
      setError(formatApiError(err));
    }
  }, [id, closeModal, overrideNotes]);

  useSetupModal(closeModal);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <Title>{TITLE}</Title>
      <SectionWrapper>
        <InnerWrapper>
          <WhiteSpaceValue>{formatChanges(changes)}</WhiteSpaceValue>
        </InnerWrapper>
        <Content>
          <InnerWrapper>
            <Label>{NOTES}</Label>
            <TextArea
              defaultValue={notes}
              onChange={onChangeNotes}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Error>{error}</Error>
          </InnerWrapper>
        </Content>
        <ButtonsWrapper>
          <Button onClick={submitChangeNotes}>{SUBMIT}</Button>
          <Button onClick={closeModal}>{CANCEL}</Button>
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

export default OverrideNotesModal;

