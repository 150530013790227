import { AxiosResponse } from 'axios';
import client from './_client';
import UploaderEvent, { APIUploaderEvents } from '#/types/UploaderEvent';
import mapUploaderEvent from './utils/mapUploaderEvent';

/* eslint-enable camelcase */

const getUploaderEvent = async (
  uploader: string, uploaderEventId: string,
): Promise<UploaderEvent> => {
  const response: AxiosResponse<APIUploaderEvents> = await client.get(
    `/proxy/ingestion_event?uploader=${uploader}&uploader_event_id=${uploaderEventId}`,
  );
  const apiEvents: APIUploaderEvents = response?.data || null;

  return (
    apiEvents && apiEvents.events.length > 0 && mapUploaderEvent(apiEvents.events[0])
  );
};

export default getUploaderEvent;
