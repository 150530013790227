import client from './_client';
import { SinkConfig } from '#/pages/ViewPage/components/Configuration/Modals/types';

/* eslint-disable camelcase */

interface APICall {
  status: number;
}

interface SinkPost {
  activated_at: string | null;
  autobroker_event_id: number;
  listing_max_count: number;
  listing_min_count: number;
  marketplaces: string[];
  p_visible: number;
  qa_mode: boolean;
  reset_visibility: boolean;
  churn_visibility_rate: number;
  price_increment?: number;
  sink_external_event_id: string;
  sink_name: string;
  notes?: string;
}

const formatPostBody = (postBody: SinkConfig): SinkPost => {
  return {
    activated_at: postBody.activatedAt ? postBody.activatedAt.toISO() : null,
    autobroker_event_id: postBody.autobrokerEventId,
    listing_max_count: postBody.listingMaxCount,
    listing_min_count: postBody.listingMinCount,
    marketplaces: postBody.marketplaces,
    p_visible: postBody.pVisible,
    churn_visibility_rate: postBody.churnVisibilityRate,
    price_increment: postBody.priceIncrement,
    qa_mode: postBody.qaMode,
    reset_visibility: postBody.resetVisibility,
    sink_external_event_id: postBody.sinkExternalEventId,
    sink_name: postBody.sinkName,
    notes: postBody.notes,
  };
};

const postSinkConfig = async (sink: SinkConfig): Promise<APICall> => {
  const postBody: SinkPost = formatPostBody(sink);
  const response = await client.post('/proxy/sink_event', postBody);

  return { status: response.status };
};

export default postSinkConfig;
