import styled from 'styled-components';
import palette from '#/theme/palettes/main';

const Bullet = styled.div`
  height: 0.25rem;
  width: 0.25rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: ${palette.salmon.base};
`;

export default Bullet;
