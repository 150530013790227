import { DateTime } from 'luxon';
import { EventConfig, SUPPORTS_ONSALE_DEMAND_FRACTION } from '#/types/Event';

interface Config {
  isActive?: boolean;
  resetPrices?: boolean;
  listingsRemovedAt?: DateTime;
  onsaleStartsAt?: DateTime;
  keepSupervisorActive?: boolean;
  useSeatgeekTransactions?: boolean;
  demandChangepointCt?: number;
  pricingMode?: string;
  pricingBasis?: string;
  onsaleDemandFraction?: number;
  selldownProjectionModel?: string;
  onsalePricingModel?: string;
  dynamicPricingModel?: string;
  groups?: string[];
  notes?: string;
}
interface EventUpdate {
  is_active?: boolean;
  reset_prices?: boolean;
  listings_removed_at?: string;
  onsale_starts_at?: string;
  keep_supervisor_active?: boolean;
  use_seatgeek_transactions?: boolean;
  demand_changepoint_ct?: number;
  pricing_mode?: string;
  pricing_basis?: string;
  onsale_demand_fraction?: number;
  selldown_projection_model?: string;
  onsale_pricing_model?: string;
  dynamic_pricing_model?: string;
  groups?: string[];
  notes?: string;
}

const formatEventConfigPostBody = (
  currentConfig: EventConfig,
  newConfig: Config,
): EventUpdate => {
  const {
    isActive,
    resetPrices,
    keepSupervisorActive,
    useSeatgeekTransactions,
    demandChangepointCt,
    listingsRemovedAt,
    onsaleStartsAt,
    pricingMode,
    pricingBasis,
    onsaleDemandFraction,
    selldownProjectionModel,
    onsalePricingModel,
    dynamicPricingModel,
    groups,
    notes,
  } = newConfig;

  const body = {} as EventUpdate;

  if (isActive !== currentConfig.isActive)
    body.is_active = isActive;

  if (resetPrices !== currentConfig.resetPrices)
    body.reset_prices = resetPrices;

  const listingsRemovedDateChange = listingsRemovedAt
    && !currentConfig.listingsRemovedAt.equals(listingsRemovedAt);

  if (listingsRemovedDateChange) {
    body.listings_removed_at = listingsRemovedAt.toISO();
    /* If we set `listings_removed_at` and the event is currently active, we
    should also submit "is_active": True to ensure that we will update the time
    when we would like the supervisor to turn off relative to the listing removal
    time by resetting the event as active after having updated the listing removal time.
    Otherwise, the supervisor might turn off before the new listing removal time. */
    if (isActive)
      body.is_active = isActive;
  }

  const onsaleStartsAtChange = onsaleStartsAt
    && !currentConfig.onsaleStartsAt.equals(onsaleStartsAt);

  if (onsaleStartsAtChange)
    body.onsale_starts_at = onsaleStartsAt.toISO();

  if (keepSupervisorActive !== currentConfig.keepSupervisorActive)
    body.keep_supervisor_active = keepSupervisorActive;

  if (useSeatgeekTransactions !== currentConfig.useSeatgeekTransactions)
    body.use_seatgeek_transactions = useSeatgeekTransactions;

  if (demandChangepointCt !== currentConfig.demandChangepointCt)
    body.demand_changepoint_ct = demandChangepointCt;

  if (pricingMode !== currentConfig.pricingMode)
    body.pricing_mode = pricingMode;

  if (pricingBasis !== currentConfig.pricingBasis)
    body.pricing_basis = pricingBasis;

  if (
    selldownProjectionModel !== currentConfig.selldownProjectionModel
    || onsaleDemandFraction !== currentConfig.onsaleDemandFraction
  ) {
    body.selldown_projection_model = selldownProjectionModel;
    if (SUPPORTS_ONSALE_DEMAND_FRACTION.includes(selldownProjectionModel))
      body.onsale_demand_fraction = onsaleDemandFraction;
  }

  if (onsalePricingModel !== currentConfig.onsalePricingModel)
    body.onsale_pricing_model = onsalePricingModel;

  if (dynamicPricingModel !== currentConfig.dynamicPricingModel)
    body.dynamic_pricing_model = dynamicPricingModel;

  if (groups !== currentConfig.groups)
    body.groups = groups;

  if (notes)
    body.notes = notes;

  return body;
};

export default formatEventConfigPostBody;
