import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import type {
  RollupFilters,
  RollupPreview,
  RollupPreviewResponse,
} from '#/types/ReportingRollup';
import redirectUnauthorizedToAuth from './utils/redirectUnauthorizedToAuth';

const getReportingRollupPreview = async (
  filters: RollupFilters = {},
): Promise<RollupPreview[]> => {
  try {
    const response: AxiosResponse<RollupPreviewResponse> = await client.get(
      '/proxy/reporting_rollup/preview',
      {
        params: {
          ...filters,
        },
      },
    );

    const rawRollup = response?.data?.rollup || [];

    return rawRollup;
  } catch (err) {
    redirectUnauthorizedToAuth(err);
    return null;
  }
};

export default getReportingRollupPreview;
