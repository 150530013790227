import client from './_clientFormatted';
import { PostErrorStatuses } from '#/types/Errors';

const postErrorStatuses = async (
  request: PostErrorStatuses,
): Promise<void> => {
  await client.post('/proxy/error_statuses', request);
};

export default postErrorStatuses;
