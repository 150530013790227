import { DateTime, Duration } from 'luxon';

const DEFAULT_LOOKBACK = Duration.fromObject({ years: 0.5 });
const DEFAULT_FROM_DATE = DateTime.now().minus(DEFAULT_LOOKBACK);

const TODAY = DateTime.now().toSQLDate();
const MIN_DATE = '2019-01-01';

const LARGE_BREAKPOINT = 1120;
const NARROW_BREAKPOINT = 768;
const EXTRA_NARROW_BREAKPOINT = 500;

export {
  DEFAULT_LOOKBACK,
  DEFAULT_FROM_DATE,
  LARGE_BREAKPOINT,
  NARROW_BREAKPOINT,
  EXTRA_NARROW_BREAKPOINT,
  TODAY,
  MIN_DATE,
};
