import React, {
  useState, ChangeEvent, useCallback,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import {
  SectionWrapper,
  InnerWrapper,
  Title,
  Label,
  Error,
  Input,
  ButtonsWrapper,
  TextArea,
  Content,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import postBulkPriceConstraints from '#/api/postBulkPriceConstraints';
import formatApiError from '#/api/utils/formatApiError';
import Loader from '#/shared/Loader';
import modalStyles from '../modalStyles';
import useSetupModal from '#/pages/useSetupModal';

const TITLE = 'Upload Price Constraints';
const DESCRIPTION = 'Upload price constraints in a CSV format.';
const FILE = 'Price Constraints CSV File';
const NOTES = 'Notes';

const SUBMIT = 'Submit';
const CANCEL = 'Cancel';

interface KeyboardTextAreaEvent extends React.KeyboardEvent<HTMLTextAreaElement> {
  target: HTMLTextAreaElement;
}

interface UploadPriceConstraintsModalProps {
  closeModal: (refresh: boolean) => void;
}

const UploadPriceConstraintsModal: React.FC<UploadPriceConstraintsModalProps> = ({
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [file, setFile] = useState<File>(null);
  const [notes, setNotes] = useState<string>(null);

  const onChangeFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event?.currentTarget?.files[0];

    if (newFile)
      setFile(newFile);
  }, [setFile]);

  const onChangeNotes = useCallback((event: KeyboardTextAreaEvent) => {
    const newNotes = event?.target?.value;

    if (newNotes)
      setNotes(newNotes);
  }, [setNotes]);

  const onSubmit = useCallback(async () => {
    if (!file) {
      setError('Please select a file.');
      return;
    }
    setIsLoading(true);
    try {
      await postBulkPriceConstraints(file, notes);
      setError(null);
      setIsLoading(false);
      closeModal(true);
    } catch (err) {
      setError(formatApiError(err));
      setIsLoading(false);
    }
  }, [file, notes, closeModal, setError]);

  const onCancel = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  useSetupModal(onCancel);

  return (
    <Modal
      isOpen
      onRequestClose={onCancel}
      style={modalStyles}
    >
      <Title>{TITLE}</Title>
      <SectionWrapper>
        <InnerWrapper>
          <Description>{DESCRIPTION}</Description>
        </InnerWrapper>
        <Content>
          <InnerWrapper>
            <Label>{FILE}</Label>
            <Input
              accept="csv"
              onChange={onChangeFile}
              type="file"
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{NOTES}</Label>
            <TextArea
              onKeyUp={onChangeNotes}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Error>{error}</Error>
          </InnerWrapper>
        </Content>
        {isLoading
          ? <Loader />
          : (
            <ButtonsWrapper>
              <Button onClick={onSubmit}>{SUBMIT}</Button>
              <Button onClick={onCancel}>{CANCEL}</Button>
            </ButtonsWrapper>
          )}
      </SectionWrapper>
    </Modal>
  );
};

const Description = styled.p``;

export default UploadPriceConstraintsModal;
