import React from 'react';
import {
  NarrowWrapper, Element, Name, Tag, ScrollBox,
} from '#/shared/Card';
import { ErrorStatus } from '#/types/Errors';

const ERROR_STATUS = 'Error Status';

interface CardProps {
  status: ErrorStatus;
}

const ErrorStatusCard: React.FC<CardProps> = ({ status }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{ERROR_STATUS}</Name>
        <Tag>{status.status}</Tag>
      </Element>
      {status.notes && (
        <ScrollBox title={status.notes}>
          <Name>
            {status.notes}
          </Name>
        </ScrollBox>
      )}
      <Element>
        <Name>{`- ${status.userName}`}</Name>
        <Name>{`Created ${status.createdAt.toRelative()}`}</Name>
      </Element>
    </NarrowWrapper>
  );
};

export default ErrorStatusCard;
