import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import renderGraph from './renderGraph';
import { GRAPH_HASHED_VALUES, Graphs } from './constants';
import Tab from '#/shared/Tab';
import { Data } from '../types';
import PriceDistributionGraphInfo, { PreviewData } from '#/types/Preview';
import { SelldownGraphInfo } from '#/types/Snapshot';
import { OverrideChangeLogEntry } from '#/types/Override';
import { User } from '#/types/User';
import { useNavigate, useLocation } from 'react-router-dom';
import parseHashedUrlWithParams from './parseHashedUrlWithParams';

interface GraphProps {
  user: User
  data: Data;
  distributionOverlay: PriceDistributionGraphInfo;
  error: string;
  listingsLoading: boolean;
  loading: boolean;
  overlayError: string;
  previewError: string;
  previewLoading: boolean;
  previewData: PreviewData;
  selldownOverlay: SelldownGraphInfo;
  overrideLogs: OverrideChangeLogEntry[];
  overrideError?: string;
  overrideRetry: () => void;
}

const Graph: React.FC<GraphProps> = ({
  user,
  loading,
  error,
  data,
  listingsLoading,
  distributionOverlay,
  overlayError,
  previewError,
  previewLoading,
  previewData,
  selldownOverlay,
  overrideLogs,
  overrideError,
  overrideRetry,
}) => {
  const [activeGraphTab, setActiveGraphTab] = useState<string>(
    Graphs.PRICE_DISTRIBUTION,
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const [, subHash] = parseHashedUrlWithParams(location.hash);
    const parsedView = (subHash && GRAPH_HASHED_VALUES.includes(subHash))
      ? subHash
      : undefined;

    if (!parsedView)
      return;

    setActiveGraphTab(parsedView.replaceAll('-', ' '));
  }, [location]);

  const handleTabSelection = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const graphTab = (e.target as HTMLButtonElement).value.replaceAll(' ', '-');

    navigate(`#Pricing+${graphTab}`);
  }, [navigate]);

  return (
    <GraphWrapper>
      <TabBar className="tabBar">
        <Tab
          activeTab={activeGraphTab}
          name={Graphs.PRICE_DISTRIBUTION}
          onClick={handleTabSelection}
        />
        <Tab
          activeTab={activeGraphTab}
          name={Graphs.SELLDOWN}
          onClick={handleTabSelection}
        />
        <Tab
          activeTab={activeGraphTab}
          name={Graphs.PRICE_RATIO}
          onClick={handleTabSelection}
        />
        <Tab
          activeTab={activeGraphTab}
          name={Graphs.LISTINGS}
          onClick={handleTabSelection}
        />
        <Tab
          activeTab={activeGraphTab}
          name={Graphs.CURRENT_STATE_DATA}
          onClick={handleTabSelection}
        />
        {overrideLogs?.length > 0 && (
          <Tab
            activeTab={activeGraphTab}
            name={Graphs.OVERRIDE_LOGS}
            onClick={handleTabSelection}
          />
        )}
      </TabBar>
      <Border />
      {renderGraph(
        activeGraphTab,
        data,
        distributionOverlay,
        error,
        listingsLoading,
        loading,
        overlayError,
        previewLoading,
        previewError,
        previewData,
        selldownOverlay,
        overrideLogs,
        overrideError,
        overrideRetry,
        user,
      )}
    </GraphWrapper>
  );
};

const GraphWrapper = styled.div`
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
  max-width: 58.125rem;
  width: 100%;
  min-width: 43.125rem;
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  ${({ theme }: { theme: Theme }): string => theme.shadows.down};
`;

const TabBar = styled.div`
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.primary};
  height: 2.94rem;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
`;

export default Graph;
