import { DateTime } from 'luxon';
import Transaction, { APITransaction, APITransactions, APIPriceConstraint } from '#/types/Transaction';
import formatEventStartsAtString from './formatEventStartsAtString';

const mapPriceConstraint = (priceConstraint: APIPriceConstraint | null): string | null => {
  if (!priceConstraint)
    return null;

  const output = [];

  if (typeof priceConstraint.floor !== 'undefined')
    output.push(`$${priceConstraint.floor} floor`);

  if (typeof priceConstraint.ceiling !== 'undefined')
    output.push(`$${priceConstraint.ceiling} ceiling`);

  output.push(priceConstraint.type.split('_').join(' '));

  return output.join('\n');
};

const mapTransaction = (transaction: APITransaction): Transaction => {
  const event_starts_at = DateTime.fromISO(transaction.event_starts_at, { zone: 'utc' });
  const event_starts_at_lcl = transaction.event_starts_at_local
    ? DateTime.fromISO(transaction.event_starts_at_local, { zone: 'utc' })
    : event_starts_at;
  const [eventDate, eventTime] = formatEventStartsAtString(event_starts_at, event_starts_at_lcl);
  const event_starts_at_local = `${eventDate} • ${eventTime}`;

  return {
    ...transaction,
    event_starts_at,
    event_starts_at_local,
    gtv: transaction.submitted_price_per_ticket
      ? transaction.submitted_price_per_ticket * transaction.quantity
      : null,
    recoupment_rate: transaction.submitted_price_per_ticket && transaction.reported_cost_per_ticket
      ? transaction.submitted_price_per_ticket / transaction.reported_cost_per_ticket
      : null,
    price_constraint: mapPriceConstraint(transaction.price_constraint),
    transacted_at: DateTime.fromISO(transaction.transacted_at, { zone: 'utc' }),
  };
};

const mapTransactions = (transactions: APITransactions): Transaction[] => {
  return transactions.transactions.map((transaction) => mapTransaction(transaction));
};

export default mapTransactions;
