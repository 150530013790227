import PriceDistributionGraphInfo from '#/types/Preview';

const mapModelToOverlay = (
  distributionData: PriceDistributionGraphInfo,
): PriceDistributionGraphInfo => {
  const {
    priceDistributionGraphData,
    priceDistributionGraphDomain,
  } = distributionData;
  const overlayGraphData = {
    modelMediumOverlay: priceDistributionGraphData.modelMedium,
    modelNarrowOverlay: priceDistributionGraphData.modelNarrow,
    modelTargetOverlay: priceDistributionGraphData.modelTarget,
    modelWideOverlay: priceDistributionGraphData.modelWide,
  };

  return {
    priceDistributionGraphData: overlayGraphData,
    priceDistributionGraphDomain,
  };
};

export default mapModelToOverlay;
