import React from 'react';
import { User } from '#/types/User';
import Ingestion from '#/types/Ingestion';
import SeatsCard from './SeatsCard';
import ExternalNotesCard from './ExternalNotesCard';
import IngestionCsvCard from './IngestionCsvCard';
import TrackingCard from './TrackingCard';
import FulfillmentCard from './FulfillmentCard';
import UploaderEventCard from './UploaderEventCard';
import ErrorInfoCard from './ErrorInfoCard';

interface IngestionDetailCardsProps {
  user: User;
  ingestion: Ingestion;
  setPdfUrl: (url: string) => void;
}

const IngestionDetailCards: React.FC<IngestionDetailCardsProps> = ({
  ingestion,
  setPdfUrl,
}) => {
  const {
    uploader_event_id, ingestion_csv_file, external_notes, last_state, error_reason,
  } = ingestion;

  return (
    <>
      {uploader_event_id && (
        <UploaderEventCard ingestion={ingestion} />
      )}
      <SeatsCard ingestion={ingestion} />
      <TrackingCard ingestion={ingestion} />
      <FulfillmentCard ingestion={ingestion} setPdfUrl={setPdfUrl} />
      {external_notes && (
        <ExternalNotesCard ingestion={ingestion} />
      )}
      {ingestion_csv_file && (
        <IngestionCsvCard csv={ingestion_csv_file} />
      )}
      {(error_reason && last_state) && (
        <ErrorInfoCard ingestion={ingestion} />
      )}
    </>
  );
};

export default IngestionDetailCards;
