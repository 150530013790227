import { GraphPlot, LogData } from '#/types/Snapshot';

interface Response {
  actualABLogPriceRatio: GraphPlot;
  actualABPercentilesMedium: GraphPlot;
  actualABPercentilesNarrow: GraphPlot;
  actualABPercentilesWide: GraphPlot;
}

const formatABData = (autobroker: LogData, logHoursToEvent: number): Response => {
  const response: Response = {
    actualABLogPriceRatio: { x: logHoursToEvent, y: null },
    actualABPercentilesMedium: { x: logHoursToEvent, y: null, y0: null },
    actualABPercentilesNarrow: { x: logHoursToEvent, y: null, y0: null },
    actualABPercentilesWide: { x: logHoursToEvent, y: null, y0: null },
  };

  if (!autobroker)
    return response;

  response.actualABLogPriceRatio.y = autobroker.logPriceRatio;
  response.actualABPercentilesMedium.y = autobroker.logPriceRatio90Pct;
  response.actualABPercentilesMedium.y0 = autobroker.logPriceRatio10Pct;
  response.actualABPercentilesNarrow.y = autobroker.logPriceRatio75Pct;
  response.actualABPercentilesNarrow.y0 = autobroker.logPriceRatio25Pct;
  response.actualABPercentilesWide.y = autobroker.logPriceRatio95Pct;
  response.actualABPercentilesWide.y0 = autobroker.logPriceRatio5Pct;

  return response;
};

export default formatABData;
