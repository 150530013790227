import { AxiosResponse } from 'axios';
import client from './_client';
import { EventFilters, APIEvent } from '#/types/Event';
import { FormattedEventError, EventErrorType } from '#/types/Errors';
import mapEventErrors from './utils/mapEventErrors';

interface GetEventResponse {
  events: APIEvent[];
}

const PAGE_LIMIT = 500;

const DEFAULT_ERROR_FILTERS = {
  is_active: true,
  per_page: PAGE_LIMIT,
};

const getEventErrorsByType = async (
  filters: EventFilters = {},
): Promise<APIEvent[]> => {
  const mergedFilters = { ...DEFAULT_ERROR_FILTERS, ...filters };
  const response: AxiosResponse<GetEventResponse> = await client.get(
    '/proxy/events',
    { params: mergedFilters },
  );
  let rawEvents = response?.data?.events || [];

  // check for more errors of this error type if we've reached the page limit
  if (rawEvents.length === PAGE_LIMIT) {
    const lastID = rawEvents[PAGE_LIMIT - 1].id;
    const nextFilters = { ...mergedFilters, from_id: lastID } as EventFilters;
    const nextEvents = await getEventErrorsByType(nextFilters);

    rawEvents = [...rawEvents, ...nextEvents.slice(1)];
  }

  return rawEvents;
};

const getEventErrors = async (): Promise<FormattedEventError[]> => {
  const errors = await Promise.all(
    Object.values(EventErrorType)
      .map(async (error_type) => {
        const is_active = error_type === 'event_is_conflicting_error' ? 0 : 1;
        const res = await getEventErrorsByType({ error_type, is_active });

        return res.flatMap((event) => mapEventErrors(event, error_type));
      }),
  );

  return errors?.flat();
};

export default getEventErrors;
