import React from 'react';
import styled from 'styled-components';
import useFetchOverrideLogs from '#/pages/useFetchOverrideLogs';
import OverrideLogsTableAndDetail from './OverrideLogsTableAndDetail';
import { User } from '#/types/User';

const UPDATES = 'Updates';

interface OverrideLogsProps {
  user: User
}
const OverrideLogs: React.FC<OverrideLogsProps> = ({ user }) => {
  const { overrideError, overrideLogs, retry } = useFetchOverrideLogs();

  return (
    <div id="overrides">
      <Wrapper>
        <Section>
          <Title>{UPDATES}</Title>
        </Section>
        <OverrideLogsTableAndDetail
          error={overrideError}
          overrideLogs={overrideLogs}
          retry={retry}
          user={user}
        />

      </Wrapper>
    </div>
  );
};

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const Wrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 3rem;
  width: 100%;
  margin-top: 3.75rem;
`;

export default OverrideLogs;
