import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { formatNumber } from '#/shared/formatNumberForDisplay';
import Event from '#/types/Event';

interface CardProps {
  event: Event;
}

const AVG_LISTING_QTY = 'Avg. Listing Qty';
const AVG_SALE_QTY = 'Avg. Sale Qty';
const NA = 'N/A';

const AvgCard: React.FC<CardProps> = ({ event }) => {
  const {
    listingCt, listingTicketCt, saleCt, saleTicketCt,
  } = event.summary;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{AVG_LISTING_QTY}</Name>
        <Value>
          {
            listingCt === 0
              ? NA
              : formatNumber(listingTicketCt / listingCt)
          }
        </Value>
      </Element>
      <Element>
        <Name>{AVG_SALE_QTY}</Name>
        <Value>
          {
            saleCt === 0
              ? NA
              : formatNumber(saleTicketCt / saleCt)
          }
        </Value>
      </Element>
    </NarrowWrapper>
  );
};

export default AvgCard;
