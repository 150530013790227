import React, {
  useContext, useMemo, useState, useCallback,
} from 'react';
import { DateTime } from 'luxon';
import {
  GG,
  ScaleX,
  ScaleY,
  ScaleFill,
  ScaleStroke,
  Labels,
  Zoom,
} from '@graphique/graphique';
import { max, min } from 'd3-array';
import { GeomArea } from '@graphique/geom-area';
import { GeomLine } from '@graphique/geom-line';
import { GeomPoint } from '@graphique/geom-point';
import { GeomVLine } from '@graphique/geom-vline';
import { Theme } from '#/shared/graphique';
import Legend from '../sharedGraphComponents/Legend';
import {
  groups,
  palette,
  formatData,
  getSharedYExtent,
  priceRatioKinds,
} from './utils';
import {
  formatXTicks, invSymLog, isDefined, symLog, TIME_TO_EVENT_HRS_CUTOFF,
} from '../utils/dataFormatting';
import { ActiveEventContext } from '../../../contexts/ActiveEvent';
import PricingChartCheckbox from '../sharedGraphComponents/PricingChartCheckbox';
import { Wrapper, GraphiqueWrapper } from '../sharedGraphComponents/styledComponents';
import { Graphlabels, PriceRatioGraphInfo } from '#/types/Snapshot';
import { ZoomDomain } from '../types';
import useFetchMarketplaceTransactions from '#/pages/useFetchMarketplaceTransactions';
import Tooltip from './Tooltip';
import ChartControlsContainer from '../sharedGraphComponents/ChartControlsContainer';

interface GraphProps {
  priceRatioGraphInfo: PriceRatioGraphInfo;
  showSales: boolean;
  handleShowSales: () => void;
}

const {
  AB, CP, SG,
} = priceRatioKinds;

const X_AXIS_LABEL = 'Time to event';
const Y_AXIS_LABEL = 'Log price ratio';

const Graph: React.FC<GraphProps> = ({
  priceRatioGraphInfo,
  showSales,
  handleShowSales,
}) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const { priceRatioGraphData: data } = priceRatioGraphInfo;
  const { marketplaceTransactions } = useFetchMarketplaceTransactions(activeEvent?.id);

  const formattedData = useMemo(() => (
    [...formatData(data), ...(showSales && marketplaceTransactions ? marketplaceTransactions : [])]
  ), [data, marketplaceTransactions, showSales]);

  const existingGroups = useMemo(() => (
    Array.from(new Set(
      formattedData.filter((d) => isDefined(d.logPriceRatio) && isDefined(d.logHoursToEvent))
        .map((d) => d.group),
    ))
  ), [formattedData]);

  const [includedGroups, setIncludedGroups] = useState(existingGroups);

  const handleLegendSelection = useCallback((v: string) => {
    if (!existingGroups.includes(v))
      return;
    setIncludedGroups((prev) => {
      if (prev.includes(v) && prev.length === 1)
        return existingGroups;
      return prev.includes(v) ? prev.filter((p) => p !== v) : [...prev, v];
    });
  }, [existingGroups]);

  const now = useMemo(() => {
    const eventDate = activeEvent.config.eventStartsAt;
    const xVal = eventDate ? eventDate.diff(DateTime.local(), 'hours').hours : undefined;

    return [{ logHoursToEvent: symLog(xVal) }];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const includedData = useMemo(() => {
    return formattedData.filter((d) => (
      includedGroups.includes(d.group)
        && invSymLog(d.logHoursToEvent) >= TIME_TO_EVENT_HRS_CUTOFF
    ));
  }, [formattedData, includedGroups]);

  const areaData = useMemo(() => {
    return (
      includedData.filter((d) => (
        [AB.label, CP.label].includes(d.group as Graphlabels)
      ))
    );
  }, [includedData]);

  const pointData = useMemo(() => (
    includedData.filter((d) => (
      [SG.label]
        .includes(d.measure)
      && d.logHoursToEvent !== null
      && d.logPriceRatio !== null
      && showSales
    ))
  ), [includedData, showSales]);

  const lineData = useMemo(() => (
    includedData.filter((d) => ![SG.label].includes(d.measure))
  ), [includedData]);

  const sharedYExtent = useMemo(() => {
    return getSharedYExtent(includedData);
  }, [includedData]);

  const [zoomDomain, setZoomDomain] = useState<ZoomDomain | undefined>();
  const handleZoom = useCallback((domain: ZoomDomain) => setZoomDomain(domain), []);
  const handleUnzoom = useCallback(() => setZoomDomain(undefined), []);

  const xExtent = useMemo(() => {
    const definedData = includedData.filter((d) => isDefined(d.logPriceRatio));
    const maxX = max(definedData, (d) => d.logHoursToEvent);
    const minX = min(includedData, (d) => d.logHoursToEvent);

    return [minX, maxX];
  }, [includedData]);

  return (
    <Wrapper>
      <GraphiqueWrapper>
        <GG
          aes={{
            x: (d): number => d.logHoursToEvent,
            y: (d): number => d.logPriceRatio,
            stroke: (d): string => d.group,
            fill: (d): string => d.group,
          }}
          data={includedData}
          height={505}
          isContainerWidth
          margin={{ left: 50, top: 20 }}
        >
          {now[0].logHoursToEvent && (
            <GeomVLine
              data={now}
              showTooltip={false}
              stroke='#aaa'
              strokeDasharray='3,3'
            />
          )}
          <GeomArea
            aes={{
              group: (d): string => d.measure,
              y0: (d): number => d.priceBand?.[0].logPriceRatioMin,
              y1: (d): number => d.priceBand?.[0].logPriceRatioMax,
            }}
            data={areaData}
            fillOpacity={0.15}
            showTooltip={false}
          />
          <GeomPoint
            aes={{
              key: (d): string => d.key,
              label: (d): string => d.group,
            }}
            data={pointData}
            entrance='data'
            fillOpacity={0.35}
            focusedStyle={{ fillOpacity: 1, strokeOpacity: 1 }}
            isClipped={false}
            r={2}
            showTooltip={false}
            strokeOpacity={0.25}
            unfocusedStyle={{
              strokeOpacity: 0.15,
              fillOpacity: 0.1,
            }}
          />
          <GeomLine
            brushAction='zoom'
            data={lineData}
            entrance='data'
            strokeOpacity={0.6}
            strokeWidth={2.4}
          />
          <ScaleX
            domain={zoomDomain ? zoomDomain?.x : xExtent}
            format={formatXTicks}
            numTicks={4}
            reverse
          />
          <ScaleY
            domain={zoomDomain ? zoomDomain?.y : sharedYExtent}
            numTicks={5}
          />
          <ScaleFill domain={groups} values={palette} />
          <ScaleStroke domain={groups} values={palette} />
          <Labels
            header={(
              <ChartControlsContainer>
                <Legend kind='point' onSelection={handleLegendSelection} />
                <PricingChartCheckbox
                  checked={showSales}
                  label='Show SeatGeek sales'
                  onChange={handleShowSales}
                />
              </ChartControlsContainer>
            )}
            x={X_AXIS_LABEL}
            y={Y_AXIS_LABEL}
          />
          <Tooltip />
          <Theme />
          <Zoom onUnzoom={handleUnzoom} onZoom={handleZoom} />
        </GG>
      </GraphiqueWrapper>
    </Wrapper>
  );
};

export default Graph;
export { X_AXIS_LABEL, Y_AXIS_LABEL };
