import { DateTime } from 'luxon';
import client from './_client';
import mapEvent from './utils/mapEvent';
import Event from '#/types/Event';
import formatEventConfigPostBody from './utils/formatEventConfigPostBody';

interface Config {
  isActive?: boolean;
  resetPrices?: boolean;
  listingsRemovedAt?: DateTime;
}

// This API call updates the event configuration options, but can
// also be used to run the supervisor on an active event by sending
// {isActive: true}
const postEventConfigUpdate = async (
  activeEvent: Event,
  newConfig: Config,
  runSupervisor = false,
): Promise<Event> => {
  const { config } = activeEvent;
  const postBody = runSupervisor
    ? { is_active: true }
    : formatEventConfigPostBody(config, newConfig);
  const response = await client.post(
    `/proxy/event/${activeEvent.id}`,
    postBody,
  );
  const updatedEvent = mapEvent(response.data);

  return updatedEvent;
};

export default postEventConfigUpdate;
