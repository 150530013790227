import { AxiosResponse } from 'axios';
import client from './_client';
import UploaderEvent, { APIUploaderEvents } from '#/types/UploaderEvent';
import mapUploaderEvent from './utils/mapUploaderEvent';

const getUploaderEventBySeatgeekEventId = async (
  uploader: string, seatgeekEventId: number,
): Promise<UploaderEvent> => {
  const response: AxiosResponse<APIUploaderEvents> = await client.get(
    `/proxy/ingestion_event?uploader=${uploader}&seatgeek_event_id=${seatgeekEventId}`,
  );
  const apiEvents: APIUploaderEvents = response?.data || null;

  return (
    (apiEvents && apiEvents.events.length === 1)
      ? mapUploaderEvent(apiEvents.events[0])
      : null
  );
};

export default getUploaderEventBySeatgeekEventId;
