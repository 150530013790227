import React from 'react';
import styled from 'styled-components';
import Close from '../Icons/Close';

interface Props extends React.PropsWithChildren {
  title?: string;
  onClose?: () => void;
}

const CLEAR = 'Clear';

const Pill: React.FC<Props> = ({ onClose, children, title }) => {
  return (
    <Container>
      <Label title={title}>
        {children}
      </Label>
      {onClose && (
        <CloseButton onClick={onClose}>
          <Close title={CLEAR} />
        </CloseButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  padding: 0 0.5rem 0 0.9rem;
  line-height: 1.2;
  font-size: 14px;
  height: 28px;
  border-radius: 2.125rem;
  color: #fff;
  background: ${({ theme }: { theme: Theme }): string => theme.palette.gray.dark};
`;

const Label = styled.span`
  display: inline-block;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0;
  cursor: default;
  padding-right: 0.4rem;
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: ${({ theme }: { theme: Theme }): string => theme.palette.gray.dark};
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
  transition: opacity 0.2s ease, background 0.2s ease;
  :hover {
    opacity: 0.9;
    background: ${({ theme }: { theme: Theme }): string => theme.palette.gray.base};
  }
  height: 18px;
  width: 18px;
`;

export default Pill;
