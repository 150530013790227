import Sink, { APISink } from '#/types/Sink';

/* eslint-enable camelcase */

// marketplaces is formatted this way to enable use in a Select input form
const mapSink = (sink: APISink): Sink => {
  return {
    id: sink.id,
    marketplaces: sink.marketplaces
      ? sink.marketplaces.sort().map((el) => ({ label: el, value: el }))
      : [],
    name: sink.name,
    priceIncrementMin: sink.price_increment_min,
  };
};

export default mapSink;
