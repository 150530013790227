/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useCallback, useMemo,
} from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import {
  SectionWrapper,
  ButtonsWrapper,
  Header,
  InnerWrapper,
  Label,
  Input,
  Content,
  modalMenuProps,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import formatApiError from '#/api/utils/formatApiError';
import type { Option } from '#/pages/useFetchStakeholders';
import Notification from '#/shared/Notification';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '../useSetupModal';
import PeakpassDomain, { PatchPeakpassDomain } from '#/types/PeakpassDomain';
import patchPeakpassDomain from '#/api/patchPeakpassDomain';
import type { GenericOption } from '#/types/GenericOption';

const UPDATE = 'Update Peakpass Domain';
const STAKEHOLDER = 'Stakeholder';
const PEAKPASS_DOMAIN_ID = 'Peakpass Domain ID';
const TYPE = 'Type';
const HOLD_ID = 'Hold ID';
const PRICE_TYPE_GROUP_ID = 'Price Type Group ID';
const SRO_USER_CREDENTIAL_ID = 'SRO User Credential ID';
const TICKET_ACCOUNT_EMAIL = 'Ticket Account Email';

const SUBMIT = 'Submit';
const CANCEL = 'Cancel';

const TYPE_OPTIONS = [
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
];

interface KeyboardInputEvent extends React.KeyboardEvent<HTMLInputElement> {
  target: HTMLInputElement;
}

interface PeakpassDomainModalProps {
  closeModal: () => void;
  peakpassDomains: PeakpassDomain[];
  stakeholderLabels: Option<string>[];
}

const PeakpassDomainModal: React.FC<PeakpassDomainModalProps> = ({
  closeModal, peakpassDomains, stakeholderLabels,
}) => {
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [stakeholder, setStakeholder] = useState<string>(undefined);
  const [peakpassDomain, setPeakpassDomain] = useState<PeakpassDomain>(undefined);
  const [request, setRequest] = useState<PatchPeakpassDomain>({});
  const [type, setType] = useState<string>(null);

  const onChangeStakeholder = useCallback((event: Option<string>) => {
    const newStakeholder = event?.value;
    const domains = peakpassDomains.filter((domain) => domain.stakeholder === newStakeholder);
    const domain = domains.length === 1 ? domains[0] : undefined;

    setStakeholder(newStakeholder);
    setPeakpassDomain(domain);
    setRequest({});
  }, [setStakeholder, peakpassDomains, setPeakpassDomain]);

  const onChangePeakpassDomain = useCallback((event: GenericOption<string, string>) => {
    const domainId = event?.value;
    const domains = peakpassDomains.filter((domain) => domain.peakpassDomainId === domainId);
    const domain = domains.length === 1 ? domains[0] : undefined;

    setPeakpassDomain(domain);
    setRequest({});
  }, [peakpassDomains, setPeakpassDomain]);

  const onChangeType = useCallback((event: GenericOption<string, string>) => {
    setType(event?.value);
    setRequest({});
  }, [setType, setRequest]);

  const onChangeHoldId = useCallback((event: KeyboardInputEvent) => {
    const holdId = event?.target?.value?.trim() || undefined;

    setRequest((r) => ({ ...r, holdId }));
  }, [setRequest]);

  const onChangePriceTypeGroupId = useCallback((event: KeyboardInputEvent) => {
    const priceTypeGroupId = event?.target?.value?.trim() || undefined;

    setRequest((r) => ({ ...r, priceTypeGroupId }));
  }, [setRequest]);

  const onChangeSecondaryPriceTypeGroupId = useCallback((event: KeyboardInputEvent) => {
    const secondaryPriceTypeGroupId = event?.target?.value?.trim() || undefined;

    setRequest((r) => ({ ...r, secondaryPriceTypeGroupId }));
  }, [setRequest]);

  const onChangeSecondaryTicketAccountEmail = useCallback((event: KeyboardInputEvent) => {
    const secondaryTicketAccountEmail = event?.target?.value?.trim() || undefined;

    setRequest((r) => ({ ...r, secondaryTicketAccountEmail }));
  }, [setRequest]);

  const onChangeSroUserCredentialId = useCallback((event: KeyboardInputEvent) => {
    const sroUserCredentialId = event?.target?.value?.trim() || undefined;

    setRequest((r) => ({ ...r, sroUserCredentialId }));
  }, [setRequest]);

  const cancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const submitPatchPeakpassDomain = useCallback(async () => {
    try {
      setError('');
      const peakpassDomainId = peakpassDomain?.peakpassDomainId;
      const updated = await patchPeakpassDomain(peakpassDomainId, request);

      if (updated.id) {
        setSuccess(true);
        setTimeout(closeModal, 1000);
      }
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [closeModal, peakpassDomain, request]);

  const peakpassDomainOptions = useMemo(() => {
    return peakpassDomains
      .filter((domain) => domain.stakeholder === stakeholder)
      .map((domain) => ({
        label: domain.peakpassDomainId,
        value: domain.peakpassDomainId,
      }));
  }, [peakpassDomains, stakeholder]);

  useSetupModal(cancel);

  return (
    <Modal
      isOpen
      onRequestClose={cancel}
      style={modalStyles}
    >
      <Header>{UPDATE}</Header>
      <Notification error={error} success={success} />
      <SectionWrapper>
        <Content>
          <InnerWrapper>
            <Label>{STAKEHOLDER}</Label>
            <Select
              onChange={onChangeStakeholder}
              options={stakeholderLabels}
              {...modalMenuProps}
            />
          </InnerWrapper>
          {stakeholder && (
            <>
              <InnerWrapper>
                <Label>{PEAKPASS_DOMAIN_ID}</Label>
                <Select
                  onChange={onChangePeakpassDomain}
                  options={peakpassDomainOptions}
                  value={
                    peakpassDomainOptions
                      .find(({ value }) => value === peakpassDomain?.peakpassDomainId)
                  }
                  {...modalMenuProps}
                />
              </InnerWrapper>
              <InnerWrapper>
                <Label>{TYPE}</Label>
                <Select
                  onChange={onChangeType}
                  options={TYPE_OPTIONS}
                  {...modalMenuProps}
                />
              </InnerWrapper>
            </>
          )}
          {peakpassDomain && type === 'primary' && (
            <>
              <InnerWrapper>
                <Label>{HOLD_ID}</Label>
                <Input
                  onKeyUp={onChangeHoldId}
                  placeholder={peakpassDomain?.primary?.holdId}
                  type="string"
                />
              </InnerWrapper>
              <InnerWrapper>
                <Label>{PRICE_TYPE_GROUP_ID}</Label>
                <Input
                  onKeyUp={onChangePriceTypeGroupId}
                  placeholder={peakpassDomain?.primary?.priceTypeGroupId}
                  type="string"
                />
              </InnerWrapper>
              <InnerWrapper>
                <Label>{SRO_USER_CREDENTIAL_ID}</Label>
                <Input
                  onKeyUp={onChangeSroUserCredentialId}
                  placeholder={peakpassDomain?.primary?.sroUserCredentialId}
                  type="string"
                />
              </InnerWrapper>
            </>
          )}
          {peakpassDomain && type === 'secondary' && (
            <>
              <InnerWrapper>
                <Label>{PRICE_TYPE_GROUP_ID}</Label>
                <Input
                  onKeyUp={onChangeSecondaryPriceTypeGroupId}
                  placeholder={peakpassDomain?.secondary?.priceTypeGroupId}
                  type="string"
                />
              </InnerWrapper>
              <InnerWrapper>
                <Label>{TICKET_ACCOUNT_EMAIL}</Label>
                <Input
                  onKeyUp={onChangeSecondaryTicketAccountEmail}
                  placeholder={peakpassDomain?.secondary?.ticketAccountEmail}
                  type="string"
                />
              </InnerWrapper>
            </>
          )}
        </Content>
        <ButtonsWrapper>
          <Button disabled={!peakpassDomain} onClick={submitPatchPeakpassDomain}>{SUBMIT}</Button>
          <Button onClick={cancel}>{CANCEL}</Button>
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

export default PeakpassDomainModal;
