import React from 'react';
import {
  NarrowWrapper, Element, Name,
} from '#/shared/Card';
import Listing from '#/types/Listing';
import { formatCurrency } from '#/shared/formatNumberForDisplay';

const CONSTRAINTS = 'Price Constraints';
const TYPE = 'Price Constraint Type';
const FLOOR = 'Price Floor';
const CEILING = 'Price Ceiling';

interface CardProps {
  listing: Listing;
}

const PriceConstraintCard: React.FC<CardProps> = ({ listing }) => {
  const {
    price_constraint_type, price_floor, price_ceiling,
  } = listing.price_constraint;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{CONSTRAINTS}</Name>
      </Element>
      <Element>
        <Name>
          {TYPE}
        </Name>
        <Name>
          {price_constraint_type.split('_').join(' ')}
        </Name>
      </Element>
      { price_floor !== null && (
        <Element>
          <Name>
            {FLOOR}
          </Name>
          <Name>
            {formatCurrency(price_floor)}
          </Name>
        </Element>
      )}
      { price_ceiling !== null && (
        <Element>
          <Name>
            {CEILING}
          </Name>
          <Name>
            {formatCurrency(price_ceiling)}
          </Name>
        </Element>
      )}
    </NarrowWrapper>
  );
};

export default PriceConstraintCard;
