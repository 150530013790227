import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Tooltip as GGTooltip, TooltipContainer, TooltipContent } from '@graphique/graphique';
import { formatGtv, formatLargeNumber, formatPercentage } from '#/shared/formatNumberForDisplay';
import { MarketplaceSummary, MarketplaceMixMeasure } from '#/pages/clientReporting/DashboardPage/types';

interface Props {
  isMoney?: boolean;
  measure: MarketplaceMixMeasure;
  data: MarketplaceSummary[];
}

const getTotal = (data: MarketplaceSummary[], measure: MarketplaceMixMeasure): number => {
  const total = data.map((d) => d[measure])
    .reduce((a, b) => (a + b));

  return total;
};

const Tooltip: React.FC<Props> = ({ isMoney, data, measure }) => {
  const total = useMemo(() => getTotal(data, measure), [data, measure]);

  const renderTooltipContent = useCallback((value: TooltipContent<MarketplaceSummary>[]) => {
    const {
      mark, group, formattedX, datum,
    } = value?.[0];

    const percentLabel = formatPercentage(datum[0][measure] / total);

    return (
      <TooltipContainer>
        <Wrapper>
          <LabelContainer>
            {mark}
            <Label>{String(group)}</Label>
          </LabelContainer>
          <NumberContainer>
            <NumberValue>{formattedX}</NumberValue>
            <Value>{`(${percentLabel})`}</Value>
          </NumberContainer>
        </Wrapper>
      </TooltipContainer>
    );
  }, [measure, total]);

  return (
    <GGTooltip
      content={renderTooltipContent}
      xFormat={isMoney ? formatGtv : formatLargeNumber}
    />
  );
};

const Wrapper = styled.div`
  padding: 0.18rem 0.15rem;
  font-size: 15px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const Label = styled.div`
  margin: 0.1rem 0 0 0.3rem;
`;

const NumberContainer = styled.div`
  margin-top: 0.35rem;
  display: flex;
`;

const NumberValue = styled.div`
  margin: 0 0.125rem;
  font-weight: 550;
`;

const Value = styled.div`
  margin: 0 0.125rem;
`;

export default Tooltip;
