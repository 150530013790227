import React from 'react';
import { EventError, EventErrorType, PostErrorStatus } from '#/types/Errors';
import TbdErrorChecklist from './Checklists/tbdEventError';
import EventNotActiveChecklist from './Checklists/eventNotActiveError';
import PricesAtBoundaryErrorChecklist from './Checklists/pricesAtBoundaryError';
import SellerdirectMatchingErrorChecklist from './Checklists/sellerdirectMatchingError';
import SGMarketDisabledErrorChecklist from './Checklists/sgMarketDisabledError';
import ModifiedMapErrorChecklist from './Checklists/modifiedMapError';
import InventoryMissingErrorChecklist from './Checklists/inventoryMissingError';
import EventConflictingErrorChecklist from './Checklists/eventConflictingError';
import OnsaleModelErrorChecklist from './Checklists/onsaleModelError';
import PricesAtMaxElasticityErrorChecklist from './Checklists/pricesAtMaxElasticityError';

interface ErrorInfoProps {
  error: EventError;
  allErrors: string[];
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const ErrorInfo: React.FC<ErrorInfoProps> = ({ error, allErrors, setErrorStatus }) => {
  switch (error.errorType) {
    case EventErrorType.TBD_EVENT_START_DATE_ERROR:
      return (
        <>
          <TbdErrorChecklist error={error} setErrorStatus={setErrorStatus} />
        </>
      );
    case EventErrorType.EVENT_NOT_ACTIVE_ON_SEATGEEK_ERROR:
      return (
        <>
          <EventNotActiveChecklist error={error} setErrorStatus={setErrorStatus} />
        </>
      );
    case EventErrorType.PRICES_AT_BOUNDARY_ERROR:
      return (
        <>
          <PricesAtBoundaryErrorChecklist error={error} setErrorStatus={setErrorStatus} />
        </>
      );
    case EventErrorType.PRICES_AT_MAX_ELASTICITY_ERROR:
      return (
        <>
          <PricesAtMaxElasticityErrorChecklist error={error} setErrorStatus={setErrorStatus} />
        </>
      );
    case EventErrorType.SELLERDIRECT_EVENT_MATCHING_ERROR:
      return (
        <>
          <SellerdirectMatchingErrorChecklist error={error} setErrorStatus={setErrorStatus} />
        </>
      );
    case EventErrorType.SEATGEEK_MARKET_IS_DISABLED_ERROR:
      return (
        <>
          <SGMarketDisabledErrorChecklist error={error} setErrorStatus={setErrorStatus} />
        </>
      );
    case EventErrorType.MODIFIED_MAP_ERROR:
      return (
        <>
          <ModifiedMapErrorChecklist error={error} setErrorStatus={setErrorStatus} />
        </>
      );
    case EventErrorType.INVENTORY_MISSING_FROM_SEATGEEK_ERROR:
      return (
        <>
          <InventoryMissingErrorChecklist
            allErrors={allErrors}
            error={error}
            setErrorStatus={setErrorStatus}
          />
        </>
      );
    case EventErrorType.EVENT_CONFLICT_ERROR:
      return (
        <>
          <EventConflictingErrorChecklist error={error} setErrorStatus={setErrorStatus} />
        </>
      );
    case EventErrorType.ONSALE_MODEL_ERROR:
      return (
        <>
          <OnsaleModelErrorChecklist error={error} setErrorStatus={setErrorStatus} />
        </>
      );
    default:
      return (
        <></>
      );
  }
};

export default ErrorInfo;
