import modalStyles from '#/shared/modalStyles';
import { type Styles as ModalStyles } from 'react-modal';

const setCustomModalStyles = (maxWidth: number): ModalStyles => ({
  ...modalStyles,
  content: {
    ...modalStyles.content,
    maxWidth,
  },
});

const CONFIRMATION_MSG = 'These values will be edited for ';
const NOTES = 'Notes';
const CANCEL = 'Cancel';
const SUBMIT = 'Submit';
const SUCCESS_MSG = ' event(s) successfully edited.';

export {
  CONFIRMATION_MSG,
  NOTES,
  CANCEL,
  SUBMIT,
  SUCCESS_MSG,
  setCustomModalStyles,
};
