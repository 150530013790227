import { useCallback, useState, FormEvent } from 'react';
import patchUpdateUser from '#/api/patchUpdateUser';
import formatApiError from '#/api/utils/formatApiError';
import { PatchUserUpdate, User } from '#/types/User';
import deleteUser from '#/api/deleteUser';

type Hook = (
  user: User,
  updateUser: PatchUserUpdate
) => {
  clearError: () => void;
  error: string;
  handleSubmit: (event: FormEvent) => void;
  handleDelete: (event: FormEvent) => void;
  success: boolean;
};

const useUser: Hook = (
  user,
  updateUser,
) => {
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const clearError = useCallback(() => setError(''), [setError]);

  const refresh = useCallback(() => {
    window.location.reload();
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.stopPropagation();
      event.preventDefault();
      setError('');
      setSuccess(false);
      try {
        await patchUpdateUser(user.id, updateUser);
        setSuccess(true);
        setTimeout(refresh, 1000);
      } catch (err) {
        setError(formatApiError(err));
      }
    },
    [user.id, updateUser, refresh],
  );

  const handleDelete = useCallback(
    async (event: FormEvent) => {
      event.stopPropagation();
      event.preventDefault();
      setError('');
      setSuccess(false);
      try {
        await deleteUser(user.id);
        setSuccess(true);
        setTimeout(refresh, 1000);
      } catch (err) {
        setError(formatApiError(err));
      }
    },
    [refresh, user.id],
  );

  return {
    clearError,
    error,
    handleSubmit,
    handleDelete,
    success,
  };
};

export default useUser;

