import React from 'react';
import {
  ErrorStatusType, EventError, PostErrorStatus,
} from '#/types/Errors';
import Checklist, { ChecklistConfigItem } from './checklist';

interface PricesAtMaxElasticityChecklistProps {
  error: EventError;
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const CONFIG: ChecklistConfigItem[] = [
  {
    id: 0,
    level: 1,
    status: ErrorStatusType.RESOLVED,
    notes: 'Max Elasticity shifted to return to the expectation of selling out',
  },
  {
    id: 1,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Ensured that price exploration has been set with a large wide range and frequency to increase precision of elasticity measurements',
  },
  {
    id: 2,
    level: 1,
    status: ErrorStatusType.WONTFIX,
    notes: 'Max Elasticity is consistent with client revenue maximization / sales expectation',
  },
];

const PricesAtMaxElasticityErrorChecklist: React.FC<PricesAtMaxElasticityChecklistProps> = (
  { error, setErrorStatus },
) => {
  return (
    <Checklist config={CONFIG} error={error} setErrorStatus={setErrorStatus} />
  );
};

export default PricesAtMaxElasticityErrorChecklist;
