import client from './_client';
import Listing, { ListingFilters } from '#/types/Listing';
import mapListing from './utils/mapListing';

const getListings = async (
  filters: ListingFilters,
): Promise<[Listing[], number]> => {
  const formattedFilters = {
    autobroker_event_id: filters.autobrokerEventId,
    stakeholder: filters.stakeholder,
    section: filters.section,
    row: filters.row,
    source_name: filters.sourceName,
    is_visible: filters.isVisible,
    is_reserved: filters.isReserved,
    has_price_constraint: filters.hasPriceConstraint,
    is_price_constrained: filters.isPriceConstrained,
    pricing_package_type: filters.pricingPackageType,
    from_id: filters.fromId,
    deal: filters.deal,
    deal_term: filters.dealTerm,
    per_page: filters.perPage,
    sort_by: filters.sortBy,
    sort_asc: filters.sortAsc,
    time_zone: filters.timeZone,
  };
  const response = await client.get('/proxy/output_listings', { params: formattedFilters });
  const rawListings = response?.data.listings;
  const perPage = response?.data?.request?.per_page;

  return [rawListings.map(mapListing), perPage];
};

export default getListings;
