import React from 'react';
import { ButtonTransparent } from '#/shared/Button';

interface ButtonProps {
  onClick: () => Promise<void>;
}

const DeleteButton: React.FC<ButtonProps> = ({ onClick }) => {
  const DELETE = 'Delete';

  return (
    <ButtonTransparent
      className='delete_pricing'
      onClick={onClick}
    >
      {DELETE}
    </ButtonTransparent>
  );
};

export default DeleteButton;
