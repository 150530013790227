import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ReportingProvider } from '#/pages/clientReporting/ReportingContext';
import { User } from '#/types/User';
import { Option } from '#/pages/useFetchStakeholders';
import Layout from '#/shared/clientReporting/Layout';
import ErrorPage from './ErrorPage';

interface Props {
  user?: User;
  stakeholder?: Option<string>;
  children?: React.ReactNode;
}

function FallbackComponent(): JSX.Element {
  return <ErrorPage />;
}

const ClientReporting: React.FC<Props> = ({ user, children, stakeholder }) => {
  return (
    <ReportingProvider stakeholder={stakeholder} user={user}>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Layout hasStakeholder={!!stakeholder}>
          {children}
        </Layout>
      </ErrorBoundary>
    </ReportingProvider>
  );
};

export default ClientReporting;
