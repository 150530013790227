import React from 'react';
import {
  Wrapper, Element, Name, WhiteSpaceValue,
} from '#/shared/Card';
import { OverrideChangeLogEntry } from '#/types/Override';

const NOTES = 'Notes';

interface CardProps {
  log: OverrideChangeLogEntry;
}

const NotesCard: React.FC<CardProps> = ({ log }) => {
  const {
    notes, user, createdAt,
  } = log;

  return (
    <Wrapper>
      <Element>
        <Name>{NOTES}</Name>
        <WhiteSpaceValue>{notes}</WhiteSpaceValue>
      </Element>
      <Element>
        <Name>{`Last updated by ${user?.name} on ${createdAt.toLocaleString()}`}</Name>
      </Element>
    </Wrapper>
  );
};

export default NotesCard;
