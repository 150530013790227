/* eslint-disable import/max-dependencies */
import React from 'react';
import styled from 'styled-components';

import Transaction from '#/types/Transaction';
import Oneticket from '#images/Oneticket.svg';
import Peakpass from '#images/Peakpass.svg';
import Ticketvault from '#images/Ticketvault.svg';
import Stagefront from '#images/Stagefront.svg';
import Created from '#images/Created.svg';
import Fulfilled from '#images/Fulfilled.svg';
import Cancelled from '#images/Cancelled.svg';
import PriceConstraint from '#images/PriceConstraint.svg';
import Gtv from '#images/Gtv.svg';
import SeatGeek from '#images/seatgeek.png';
import StubHub from '#images/stubhub.png';
import TicketMaster from '#images/ticketmaster.png';
import GameTime from '#images/gametime.png';
import TickPick from '#images/tickpick.png';
import TicketEvolution from '#images/ticketevolution.png';
import VividSeats from '#images/vividseats.png';
import GoTickets from '#images/gotickets.png';
import SRO from '#images/SRO.png';
import Marketplace from '#images/Marketplace.svg';
import Consumer from '#images/ConsumerUploader.svg';
import { StakeholderLogos } from '../useFetchStakeholders';

interface TransactionIconsProps {
  transaction: Transaction;
  stakeholderLogos: StakeholderLogos;
}

const INTEGRATION_IMAGES: { [key: string]: string} = {
  peakpass: Peakpass,
  oneticket: Oneticket,
  ticketvault: Ticketvault,
  consumer: Consumer,
  stagefront: Stagefront,
};
const MARKETPLACE_IMAGES: { [key: string]: string } = {
  seatgeek: SeatGeek,
  stubhub: StubHub,
  ticketmaster: TicketMaster,
  tickpick: TickPick,
  'vivid seats': VividSeats,
  gametime: GameTime,
  'ticket evolution': TicketEvolution,
  gotickets: GoTickets,
  'box office': SRO,
};

const TransactionIcons: React.FC<TransactionIconsProps> = ({ transaction, stakeholderLogos }) => {
  const integrationImage = INTEGRATION_IMAGES[transaction.source];
  const marketplaceImage = MARKETPLACE_IMAGES[transaction.marketplace] ?? Marketplace;
  const stakeholderImage = stakeholderLogos[transaction.stakeholder];

  let stateImage = Cancelled;

  if (transaction.state === 'created')
    stateImage = Created;
  else if (transaction.state === 'fulfilled' || transaction.state === 'purchased')
    stateImage = Fulfilled;

  return (
    <>
      <OffsetImage
        alt="stakeholder"
        src={stakeholderImage ?? Marketplace}
        title={`stakeholder: ${transaction.stakeholder}`}
      />
      <OffsetImage
        alt="integration"
        src={integrationImage}
        title={`source: ${transaction.source}, sink: ${transaction.sink}`}
      />
      <OffsetImage
        alt="state"
        src={stateImage}
        title={`state: ${transaction.state}`}
      />
      <OffsetImage
        alt="marketplace"
        src={marketplaceImage}
        title={`marketplace: ${transaction.marketplace}`}
      />
      {transaction.price_constraint && (
        <OffsetImage
          alt="price-constraint"
          src={PriceConstraint}
          title={transaction.price_constraint}
        />
      )}
      {transaction.gtv >= 1000.0 && (
        <OffsetImage
          alt="gtv"
          src={Gtv}
          title={`High Value Order\n$${transaction.gtv.toFixed(2)} GTV`}
        />
      )}
    </>
  );
};

const OffsetImage = styled.img`
  padding-left: 5px;
  padding-bottom: 3px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  object-fit: cover;
  object-position: center;
`;

export default TransactionIcons;
