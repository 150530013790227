import React, {
  useState, useCallback, useMemo,
} from 'react';
import styled from 'styled-components';
import {
  ErrorStatusType, ErrorType, EventError, PostErrorStatus,
} from '#/types/Errors';
import {
  Level1, Level2, Level3, Level4, Level5, Input, Link, Label, Level6,
} from '../../components';
import { ButtonSmall } from '#/shared/Button';
import ConfirmationModal from '#/shared/ConfirmationModal';
import useOnsaleConfirmationModal from '../../../../../useRerunModelConfirmationModal';

interface ChecklistConfigItem {
  id: number;
  level: number;
  status: ErrorStatusType;
  notes: string;
  link?: string;
  parent_id?: number;
  checked?: boolean;
  disabled?: boolean;
  showButton?: boolean;
}

interface ChecklistProps {
  error: EventError;
  config: ChecklistConfigItem[];
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const Checklist: React.FC<ChecklistProps> = ({
  error, config, setErrorStatus,
}) => {
  const [items, setItems] = useState(
    config.map((i) => {
      const checked = (
        i.status === error.errorStatus?.status
        && i.notes === error.errorStatus?.notes
      );

      return { ...i, checked };
    }),
  );

  const {
    openRerunOnsaleModal,
    closeRerunModal,
    rerunModel,
    showConfirmationModal,
    message: rerunMessage,
    error: rerunError,
  } = useOnsaleConfirmationModal();

  useMemo(() => {
    const level = items
      .filter((i) => i.checked)
      .map((i) => i.level)
      .reduce((a, b) => Math.max(a, b), 0)
      || 1;

    // ensure consistency
    let changed = false;

    /* eslint-disable no-restricted-syntax */
    for (const item of items) {
      const parent = items.find((i) => i.id === item.parent_id);

      if (item.checked) {
        if (parent && !parent.checked) {
          parent.checked = true;
          changed = true;
        }
        if (parent && !parent.disabled) {
          parent.disabled = true;
          changed = true;
        }
      } else if (parent) {
        if (!parent.checked && !item.disabled) {
          item.disabled = true;
          changed = true;
        }
        if (parent.checked && item.disabled) {
          item.disabled = false;
          changed = true;
        }
      }
      if (item.level < level && !item.disabled) {
        item.disabled = true;
        changed = true;
      }
    }
    /* eslint-enable no-restricted-syntax */

    if (changed)
      setItems(items);
  }, [items]);

  const handleChecked = useCallback(
    (id: number, level: number, checked: boolean) => {
      let item = items[id];

      if (!checked)
        item = items[item.parent_id];

      if (item) {
        setErrorStatus({
          status: item.status,
          notes: item.notes,
          reference: {
            id: error.id,
            type: ErrorType.AUTOBROKER_EVENT_ERRORS,
          },
        });
      } else {
        setErrorStatus({
          status: ErrorStatusType.TODO,
          notes: '',
          reference: {
            id: error.id,
            type: ErrorType.AUTOBROKER_EVENT_ERRORS,
          },
        });
      }
      setItems((prevs) => {
        return prevs.map((prev) => {
          if (prev.id === id)
            return { ...prev, checked, disabled: false };
          if (checked && prev.level === level)
            return { ...prev, checked: false, disabled: false };

          return { ...prev, disabled: false };
        });
      });
    }, [error.id, items, setErrorStatus],
  );

  return (
    <>
      {showConfirmationModal && (
        <ConfirmationModal
          closeModal={closeRerunModal}
          error={rerunError}
          handleSubmit={rerunModel}
          message={rerunMessage}
        />
      )}
      {items.map((item) => (
        <ChecklistItem
          config={item}
          handleChecked={handleChecked}
          key={item.id.toString()}
          openRerunOnsaleModal={openRerunOnsaleModal}
        />
      ))}
    </>
  );
};

interface ChecklistItemProps {
  config: ChecklistConfigItem;
  handleChecked: (id: number, level: number, checked: boolean) => void;
  openRerunOnsaleModal:() => void;
}

const ChecklistItem: React.FC<ChecklistItemProps> = ({
  config, handleChecked, openRerunOnsaleModal,
}) => {
  const {
    id, level, link, notes, checked, disabled, showButton,
  } = config;

  let Level;

  if (level === 1)
    Level = Level1;
  else if (level === 2)
    Level = Level2;
  else if (level === 3)
    Level = Level3;
  else if (level === 4)
    Level = Level4;
  else if (level === 5)
    Level = Level5;
  else
    Level = Level6;

  const onChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChecked(id, level, event.target.checked);
  }, [handleChecked, id, level]);

  const display = useMemo(() => {
    if (link)
      return <Link href={link} rel="noopener" target="_blank">{notes}</Link>;

    if (showButton) {
      return (
        <OnSaleButtonWrapper>
          <ButtonSmall disabled={disabled} onClick={openRerunOnsaleModal}>{notes}</ButtonSmall>
        </OnSaleButtonWrapper>
      );
    }

    return <Label>{notes}</Label>;
  }, [link, notes, openRerunOnsaleModal, showButton, disabled]);

  return (
    <Level>
      <Input checked={checked} disabled={disabled} onChange={onChange} type='checkbox' />
      {display}
    </Level>
  );
};

const OnSaleButtonWrapper = styled.div`
  margin-bottom: 0.2rem;
  margin-left: -1.5rem;
  width: 100rem;
  font-size: 17px;
`;

export default Checklist;
export { ChecklistConfigItem };
