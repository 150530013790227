import React from 'react';
import styled from 'styled-components';
import {
  NarrowErrorWrapper, Element, Name, ErrorOutput,
} from '#/shared/Card';
import Ingestion from '#/types/Ingestion';

const ERROR_INFO = 'Error Info';
const FOR_STRING = 'for';

const formatState = (s: string): string => s.split('_').join(' ');

interface CardProps {
  ingestion: Ingestion;
}

const ErrorInfoCard: React.FC<CardProps> = ({ ingestion }) => {
  const lastState = ingestion.last_state || 'No Last State';
  const errorReason = ingestion.error_reason || 'No Error Reason';

  return (
    <NarrowErrorWrapper>
      <Element>
        <Name>{ERROR_INFO}</Name>
      </Element>
      <Name>
        {FOR_STRING}
        <StateLabel>
          {formatState(lastState)}
        </StateLabel>
      </Name>
      <ErrorOutput>{errorReason}</ErrorOutput>
    </NarrowErrorWrapper>
  );
};

const StateLabel = styled.div`
  display: flex;
  margin-left: 4px;
  font-weight: ${({ theme }: { theme: Theme }): string => theme.text1semibold};
`;

export default ErrorInfoCard;
