const RESET = 'reset';

const P_VISIBLE_MIN = 0;
const P_VISIBLE_MAX = 1;
const LISTING_MIN = 0;
const CHURN_VISIBILITY_RATE_MIN = 0;
const CHURN_VISIBILITY_RATE_MAX = 1;
const PRICE_INCREMENT_MIN = 0.01;
const PRICE_INCREMENT_MAX = 10.0;

const EXT_EVT_ID_MIN_CHAR = 3;

export {
  EXT_EVT_ID_MIN_CHAR,
  LISTING_MIN,
  P_VISIBLE_MAX,
  P_VISIBLE_MIN,
  CHURN_VISIBILITY_RATE_MIN,
  CHURN_VISIBILITY_RATE_MAX,
  PRICE_INCREMENT_MIN,
  PRICE_INCREMENT_MAX,
};
export default RESET;
