// This function navigates the two flows of the integration modal
// -- add an integration to an existing event (multistep is false)
// or add an integration to a new event. In the case of the latter,
// the user would have just created a new event and if they decide
// to hit the cancel button on the integration, we should still
// navigate them to the new event.

const createHandleCancel = (
  closeForms: () => void,
  goToEventPage: () => void,
  multistep: boolean,
  retry?: () => void,
): (() => void
  ) => {
  if (!multistep) {
    return (): void => {
      closeForms();
    };
  }

  return async (): Promise<void> => {
    if (retry)
      await retry();
    closeForms();
    goToEventPage();
  };
};

export default createHandleCancel;
