import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { ListingPolicyPrices } from '#/types/Listing';
import { formatCurrency } from '#/shared/formatNumberForDisplay';

const ALL_IN_PRICE = 'All In Price';
const DISPLAY_PRICE = 'Display Price';
const BROADCAST_PRICE = 'Broadcast Price';

interface CardProps {
  policy_prices: ListingPolicyPrices;
}

const PolicyPricesCard: React.FC<CardProps> = ({ policy_prices }) => {
  const { all_in_price, display_price, broadcast_price } = policy_prices;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{ALL_IN_PRICE}</Name>
        <Value>{formatCurrency(all_in_price)}</Value>
      </Element>
      <Element>
        <Name>{DISPLAY_PRICE}</Name>
        <Value>{formatCurrency(display_price)}</Value>
      </Element>
      <Element>
        <Name>{BROADCAST_PRICE}</Name>
        <Value>{formatCurrency(broadcast_price)}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default PolicyPricesCard;
