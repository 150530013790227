import React, { useContext } from 'react';
import styled from 'styled-components';
import { isDefined, type RollupEvent } from '../utils/getSalesTrendsByEvent';
import { RequestedOverride } from '#/types/ReportingRollup';
import assembleRequestedOverridesData from '../utils/assembleRequestedOverridesData';
import EventDetails from '../EventDetails';
import type { BulkOverrides, Settings } from '#/types/Override';
import formatOverrideValue from '../utils/formatOverrideValue';
import getDiffColor from '../utils/getDiffColor';
import { CollectionOverridesContext } from '../contexts';

interface Props {
  events: RollupEvent[]
  existingOverrides: BulkOverrides[]
  existingParameters: Settings[]
  requestedOverrides: RequestedOverride[]
}

const SELECTED_EVENTS = 'Selected Events';

enum SecondaryHeader {
  BEFORE = 'Before',
  AFTER = 'After',
  DIFF = 'Diff',
}

const SECONDARY_HEADER_HEIGHT = '24px';

const RequestedOverridesTable: React.FC<Props> = (
  {
    events, existingOverrides, requestedOverrides, existingParameters,
  },
) => {
  const columnHeaders = requestedOverrides?.map((o) => o?.label);
  const beforeAfterHeaders: string[] = Array(columnHeaders?.length)
    .fill(Object.values(SecondaryHeader))
    .flat();

  const { overrideMode } = useContext(CollectionOverridesContext);

  const requestedOverridesData = assembleRequestedOverridesData(
    {
      events, existingOverrides, requestedOverrides, existingParameters, overrideMode,
    },
  );

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            <SelectedEventsTh rowSpan={2}>{SELECTED_EVENTS}</SelectedEventsTh>
            {columnHeaders?.map((col) => (
              <StickyTh colSpan={3} key={col}>{col}</StickyTh>
            ))}
          </tr>
          <tr>
            {beforeAfterHeaders.map((text, i) => (
              text === SecondaryHeader.DIFF
                ? <DiffTh key={`${text}-${String(i)}`}>{text}</DiffTh>
                : <BeforeAfterTh key={`${text}-${String(i)}`}>{text}</BeforeAfterTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {requestedOverridesData.map((event) => (
            <Row key={event.autobrokerEventId}>
              <EventTd aria-label={event.eventTitle}>
                <EventDetails event={event} isCondensed />
              </EventTd>
              {event.overrides?.map((override) => {
                const hasPreviousOverride = isDefined(override.previousOverrideValue);

                return (
                  <React.Fragment key={override.label}>
                    <BeforeAfterTd>{formatOverrideValue(override?.before)}</BeforeAfterTd>
                    <BeforeAfterTd>{formatOverrideValue(override?.after, true)}</BeforeAfterTd>
                    <DiffTd diff={override.diff} hasPreviousOverride={hasPreviousOverride}>
                      {formatOverrideValue(override?.diff, hasPreviousOverride)}
                    </DiffTd>
                  </React.Fragment>
                );
              })}
            </Row>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  max-height: 440px;
  max-width: 100%;
  overflow: auto;
  border: 1px solid #111;
  border-top: 0 solid #111;
  table {
    letter-spacing: 0;
    font-size: 0.85rem;
    text-align: center;
    position: relative;
    border-spacing: 0;
  }
  thead {
    background: #111;
    color: #fff;
    line-height: 1.25;
    border-bottom: 0.5px solid #444;
  }
  tbody tr > :first-child {
    background: #fff;
  }
  tbody tr:nth-child(even) > :first-child {
    background: #f8f8f8;
  }
`;

const Row = styled.tr`
  :nth-child(even) {
    background: #f8f8f8;
  }
  :nth-child(odd) {
    background: #fff;
  }
`;

const Th = styled.th`
  border: 0.5px solid #ccc;
  border-top: 0.5px solid #111;
  border-bottom: 0.5px solid #444;
  padding: 0.3rem 1rem;
  font-weight: 600;
  position: sticky;
  top: calc(${SECONDARY_HEADER_HEIGHT} + 0.6rem + 0.5px);
`;

const StickyTh = styled(Th as any)`
  position: sticky;
  top: -0.5px;
  height: ${SECONDARY_HEADER_HEIGHT};
  background: #111;
`;

const SelectedEventsTh = styled(StickyTh as any)`
  top: -0.5px;
  left: 0;
  z-index: 3;
`;

const BeforeAfterTh = styled(Th as any)`
  background: #fff;
  color: #444;
  font-weight: 400;
`;

const DiffTh = styled(Th as any)`
  background: #fff;
  color: #000;
  border-right: 0.5px solid #222;
`;

const BeforeAfterTd = styled.td`
  color: #555;
  border: 0.5px solid #ccc;
`;

interface DiffTdProps {
  diff?: number
  hasPreviousOverride: boolean
}

const DiffTd = styled(BeforeAfterTd as any) <DiffTdProps>`
  font-weight: 550;
  color: ${({ diff, hasPreviousOverride }): string => getDiffColor(diff, hasPreviousOverride)};
  background: ${({ diff, hasPreviousOverride }): string => getDiffColor(diff, hasPreviousOverride)}18;
  border-right: 0.5px solid #222;
`;

const EventTd = styled.td`
  padding: 0.5rem;
  border: 0.5px solid #ccc;
  border-right: 0.5px solid #444;
  min-width: 235px;
  position: sticky;
  z-index: 1;
  left: 0;
`;

export default RequestedOverridesTable;
