import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import Modal from 'react-modal';
import { EventType } from '../types';
import postEvent from '#/api/postEvent';
import useEvent from './useEvent';
import { DefaultEvent } from '../utils/createDefaultEvent';
import InputForm, { InputType } from '../Forms/InputForm';
import DatetimeForm from '../Forms/DatetimeForm';
import CheckboxForm from '../Forms/CheckboxForm';
import SelectGroups from './SelectGroups';
import SelectPricingMode from './SelectPricingMode';
import SelectSelldownProjectionModel from './SelectSelldownProjectionModel';
import SelectOnsalePricingModel from './SelectOnsalePricingModel';
import SelectDynamicPricingModel from './SelectDynamicPricingModel';
import SelectPricingBasis from './SelectPricingBasis';
import ModalButtons from '../Buttons';
import { SectionWrapper, Title, Content } from '#/shared/modalComponents';
import formatApiError from '#/api/utils/formatApiError';
import { SalesPeriodType, SUPPORTS_ONSALE_DEMAND_FRACTION } from '#/types/Event';
import getSalesPeriodSuggestions from '#/api/getSalesPeriodSuggestions';
import formatDateForState from '#/shared/formatDateForState';
import SelectSectionSubsetType from './SelectSectionSubsetType';

const EVENT_CONFIG_TITLES = {
  GROUPS: 'Groups',
  ONSALE_STARTS_AT: 'Onsale Starts At',
  PRICING_MODE: 'Pricing Mode',
  SEATGEEK_EVENT_ID: 'Seatgeek Event Id',
  SECTION_SUBSET_SECTIONS: 'Sections',
  SECTION_SUBSET_TYPE: 'Section Subset Type',
  SUPERVISOR_STARTS_AT: 'Supervisor Starts At',
  SUPERVISOR_STOPS_AT: 'Supervisor Stops At',
  KEEP_SUPERVISOR_ACTIVE: 'Keep Supervisor Active',
  USE_SEATGEEK_TRANSACTIONS: 'Use Seatgeek Transactions',
  DEMAND_CHANGEPOINT_CT: 'Demand Changepoint Ct',
  PRICING_BASIS: 'Pricing Basis',
  ONSALE_DEMAND_FRACTION: 'Onsale Demand Fraction',
  SELLDOWN_PROJECTION_MODEL: 'Selldown Projection Model',
  ONSALE_PRICING_MODEL: 'Onsale Pricing Model',
  DYNAMIC_PRICING_MODEL: 'Dynamic Pricing Model',
  NOTES: 'Notes',
};

const HEADLINE = 'New Event';

interface EventFormProps {
  closeModal: () => void;
  newEvent: DefaultEvent;
  onSuccess: (input: number) => void;
}

const EventForm: React.FC<EventFormProps> = ({
  closeModal,
  newEvent,
  onSuccess,
}) => {
  const { eventConfig, updateEventConfig } = useEvent(newEvent);
  const [error, setError] = useState<string>('');

  const {
    onsaleStartsAt,
    seatgeekEventId,
    sectionSubsetSections,
    sectionSubsetType,
    supervisorStartsAt,
    supervisorStopsAt,
    keepSupervisorActive,
    useSeatgeekTransactions,
    demandChangepointCt,
    onsaleDemandFraction,
  } = eventConfig;

  const showSectionSubsetSections = useMemo(() => {
    return sectionSubsetType && sectionSubsetType !== 'none';
  }, [sectionSubsetType]);

  const handleSave = useCallback(async () => {
    // We will potentially make two requests here
    // 1) Attempt first to create the event without forcing it
    // 2) If we get a 409 conflict error, we give the user the option
    //    to try the request again with force=true
    let force = false;

    for (let i = 0; i < 2; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await postEvent(eventConfig, force);

        onSuccess(response.autobrokerEventId);
        return;
      } catch (err) {
        if (err.response?.status === 409) {
          if (window.confirm('An event already exists for this seatgeek event id. Are you sure you want to create a duplicate event?')) {
            force = true;
          } else {
            window.location.href = `/search?is_active=0&per_page=50&seatgeek_event_id=${eventConfig.seatgeekEventId}`;
            return;
          }
        } else {
          const errorString = formatApiError(err);

          setError(errorString);
          return;
        }
      }
    }
  }, [eventConfig, onSuccess]);

  useEffect(() => Modal.setAppElement('body'), []);

  useEffect(() => {
    // Attempt to retrieve an onsale start time from the sales period timeline
    // which might be available for SGE events
    if (seatgeekEventId && !onsaleStartsAt) {
      try {
        const request = {
          seatgeekEventId,
          type: SalesPeriodType.ONSALE,
        };

        getSalesPeriodSuggestions(request)
          .then((suggestions) => {
            if (suggestions.length === 1) {
              const { startsAt: startsAtStr } = suggestions[0];

              if (startsAtStr) {
                const startsAt = formatDateForState(startsAtStr);

                updateEventConfig({ type: EventType.ONSALE_STARTS_AT, value: startsAt });
              }
            }
          });
      } catch (e) {
        // do nothing
      }
    }
  }, [seatgeekEventId, onsaleStartsAt, updateEventConfig]);

  return (
    <>
      <Title className='event_modal'>{HEADLINE}</Title>
      {error && <div>{error}</div>}
      <SectionWrapper>
        <Content>
          <InputForm
            handleInputChange={updateEventConfig}
            initialVal={seatgeekEventId}
            inputType={InputType.number}
            label={EVENT_CONFIG_TITLES.SEATGEEK_EVENT_ID}
            placeholder='required'
            type={EventType.SEATGEEK_EVENT_ID}
          />
          <DatetimeForm
            handleInputChange={updateEventConfig}
            initialVal={onsaleStartsAt}
            label={EVENT_CONFIG_TITLES.ONSALE_STARTS_AT}
            type={EventType.ONSALE_STARTS_AT}
          />
          <SelectPricingMode
            handleInputChange={updateEventConfig}
            label={EVENT_CONFIG_TITLES.PRICING_MODE}
          />
          <SelectPricingBasis
            handleInputChange={updateEventConfig}
            label={EVENT_CONFIG_TITLES.PRICING_BASIS}
          />
          <SelectSelldownProjectionModel
            handleInputChange={updateEventConfig}
            label={EVENT_CONFIG_TITLES.SELLDOWN_PROJECTION_MODEL}
          />
          {SUPPORTS_ONSALE_DEMAND_FRACTION.includes(eventConfig.selldownProjectionModel) && (
            <InputForm
              handleInputChange={updateEventConfig}
              initialVal={onsaleDemandFraction}
              inputType={InputType.number}
              label={EVENT_CONFIG_TITLES.ONSALE_DEMAND_FRACTION}
              max={0.99}
              min={0.01}
              step="0.01"
              type={EventType.ONSALE_DEMAND_FRACTION}
            />
          )}
          <SelectOnsalePricingModel
            handleInputChange={updateEventConfig}
            label={EVENT_CONFIG_TITLES.ONSALE_PRICING_MODEL}
          />
          <SelectDynamicPricingModel
            handleInputChange={updateEventConfig}
            label={EVENT_CONFIG_TITLES.DYNAMIC_PRICING_MODEL}
          />
          <SelectGroups
            handleInputChange={updateEventConfig}
            label={EVENT_CONFIG_TITLES.GROUPS}
          />
          <SelectSectionSubsetType
            handleInputChange={updateEventConfig}
            label={EVENT_CONFIG_TITLES.SECTION_SUBSET_TYPE}
          />
          {showSectionSubsetSections && (
            <InputForm
              handleInputChange={updateEventConfig}
              initialVal={sectionSubsetSections}
              inputType={InputType.text}
              label={EVENT_CONFIG_TITLES.SECTION_SUBSET_SECTIONS}
              placeholder='comma-separated values'
              type={EventType.SECTION_SUBSET_SECTIONS}
            />
          )}
          <DatetimeForm
            handleInputChange={updateEventConfig}
            initialVal={supervisorStartsAt}
            label={EVENT_CONFIG_TITLES.SUPERVISOR_STARTS_AT}
            type={EventType.SUPERVISOR_STARTS_AT}
          />
          <DatetimeForm
            handleInputChange={updateEventConfig}
            initialVal={supervisorStopsAt}
            label={EVENT_CONFIG_TITLES.SUPERVISOR_STOPS_AT}
            type={EventType.SUPERVISOR_STOPS_AT}
          />
          <CheckboxForm
            handleInputChange={updateEventConfig}
            label={EVENT_CONFIG_TITLES.KEEP_SUPERVISOR_ACTIVE}
            type={EventType.KEEP_SUPERVISOR_ACTIVE}
            value={keepSupervisorActive}
          />
          <CheckboxForm
            handleInputChange={updateEventConfig}
            label={EVENT_CONFIG_TITLES.USE_SEATGEEK_TRANSACTIONS}
            type={EventType.USE_SEATGEEK_TRANSACTIONS}
            value={useSeatgeekTransactions}
          />
          <InputForm
            handleInputChange={updateEventConfig}
            initialVal={demandChangepointCt}
            inputType={InputType.number}
            label={EVENT_CONFIG_TITLES.DEMAND_CHANGEPOINT_CT}
            type={EventType.DEMAND_CHANGEPOINT_CT}
          />
          <InputForm
            handleInputChange={updateEventConfig}
            inputType={InputType.text}
            label={EVENT_CONFIG_TITLES.NOTES}
            type={EventType.NOTES}
          />
        </Content>
        <ModalButtons
          disabled={!seatgeekEventId}
          onCancel={closeModal}
          onSave={handleSave}
        />
      </SectionWrapper>
    </>
  );
};

export default EventForm;
