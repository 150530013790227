import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  DateFilters,
  IncludeOnlyDealTermInventoryCheckbox,
  MetricSelectionMenu,
  SelectManifestedInventory,
} from '.';
import { CollectionSelectionsContext } from '../contexts';
import ExportCSV from './ExportCSV';

interface Props {
  isLoading?: boolean;
  data?: any[];
  children?: React.ReactNode;
}

const ControlBar: React.FC<Props> = ({ isLoading, data, children }) => {
  const { selectedGroup } = useContext(CollectionSelectionsContext);

  return (
    <ControlsContainer>
      <RelatedControls>
        <ExportCSV data={data} isLoading={isLoading} />
        <DateFilters isLoading={isLoading} />
        <IncludeOnlyDealTermInventoryCheckbox isLoading={isLoading} />
        {selectedGroup?.attributes?.summary.isUnmanifested?.length > 1 && (
          <SelectManifestedInventory
            isLoading={isLoading}
          />
        )}
        <Control>
          <MetricSelectionMenu isLoading={isLoading} />
        </Control>
      </RelatedControls>
      {children}
    </ControlsContainer>
  );
};

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 0.4rem;
  width: 100%;
`;

interface RelatedControlsProps {
  align?: 'top' | 'bottom';
  fillSpace?: boolean;
}

const RelatedControls = styled.div<RelatedControlsProps>`
  display: flex;
  align-items: ${({ align = 'bottom' }): string => align === 'bottom' ? 'flex-end' : undefined};
  flex-grow: ${({ fillSpace }): number => fillSpace ? 1 : undefined};
  justify-content: ${({ fillSpace }): string => fillSpace ? 'space-between' : undefined};
`;

const Control = styled.div`
  padding-right: 0.75rem;
`;

export { Control, RelatedControls };
export default ControlBar;
