import { Dispatch, useReducer, useEffect } from 'react';

import { DateTime } from 'luxon';
import Action, { UpdateEventConfig, RESET } from './types';
import { EventConfig, TrainingSetType } from '#/types/Event';

type Reducer = (
  previousState: UpdateEventConfig,
  action: Action
) => UpdateEventConfig;

const reducer: Reducer = (previousState, action) => {
  const { type, value } = action;

  if (type === RESET)
    return (value as unknown) as UpdateEventConfig;

  const state = { ...previousState, [type]: value } as UpdateEventConfig;

  return state;
};

type Hook = (
  config: EventConfig,
  isEditing: boolean,
) => {
  updatedConfig: UpdateEventConfig;
  updateEventConfig: Dispatch<Action>;
};

const initialVal = {
  isActive: false,
  listingsRemovedAt: null as DateTime,
  onsaleStartsAt: null as DateTime,
  resetPrices: false,
  keepSupervisorActive: false,
  useSeatgeekTransactions: true,
  demandChangepointCt: 0,
  pricingMode: 'mapped',
  pricingBasis: 'all_in_price',
  onsaleDemandFraction: null as number,
  selldownProjectionModel: null as TrainingSetType,
  onsalePricingModel: null as TrainingSetType,
  dynamicPricingModel: 'original',
  groups: null as string[],
  notes: null as string,
};

const useUpdateEventConfig: Hook = (config, isEditing) => {
  const [updatedConfig, updateEventConfig] = useReducer<Reducer>(
    reducer,
    initialVal,
  );
  // useEffect used here to allow this state to update when a user
  // changes the isActive state by using the supervisor toggle, which
  // is a separate component from the modal forms.

  useEffect(() => {
    if (!isEditing) {
      updateEventConfig({
        type: 'reset',
        value: {
          isActive: config.isActive,
          listingsRemovedAt: config.listingsRemovedAt,
          onsaleStartsAt: config.onsaleStartsAt,
          resetPrices: config.resetPrices,
          keepSupervisorActive: config.keepSupervisorActive,
          useSeatgeekTransactions: config.useSeatgeekTransactions,
          demandChangepointCt: config.demandChangepointCt,
          pricingMode: config.pricingMode,
          pricingBasis: config.pricingBasis,
          onsaleDemandFraction: config.onsaleDemandFraction,
          selldownProjectionModel: config.selldownProjectionModel,
          onsalePricingModel: config.onsalePricingModel,
          dynamicPricingModel: config.dynamicPricingModel,
          groups: config.groups,
          notes: config.notes,
        } as UpdateEventConfig,
      });
    }
  }, [config, isEditing]);

  return {
    updatedConfig,
    updateEventConfig,
  };
};

export default useUpdateEventConfig;
