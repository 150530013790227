import { IngestionFilters, InputListingErrorSubset, IngestionState } from '#/types/Ingestion';

function queryToIngestionFilters(search: string): IngestionFilters {
  const query = new URLSearchParams(search);
  const filters: IngestionFilters = {};

  if (query.has('id'))
    filters.id = Number(query.get('id'));

  if (query.has('ingestion_csv_file_id'))
    filters.ingestion_csv_file_id = Number(query.get('ingestion_csv_file_id'));

  if (query.has('seatgeek_event_id'))
    filters.seatgeek_event_id = Number(query.get('seatgeek_event_id'));

  if (query.has('autobroker_event_id'))
    filters.autobroker_event_id = Number(query.get('autobroker_event_id'));

  if (query.has('is_upcoming'))
    filters.is_upcoming = Number(query.get('is_upcoming'));

  if (query.has('from_date'))
    filters.from_date = String(query.get('from_date'));

  if (query.has('to_date'))
    filters.to_date = String(query.get('to_date'));

  if (query.has('stakeholder'))
    filters.stakeholder = String(query.get('stakeholder'));

  if (query.has('state'))
    filters.state = query.get('state') as IngestionState;

  if (query.has('is_priced'))
    filters.is_priced = Number(query.get('is_priced'));

  if (query.has('has_error'))
    filters.has_error = Number(query.get('has_error'));

  if (query.has('error_subset'))
    filters.error_subset = query.get('error_subset') as InputListingErrorSubset;

  if (query.has('in_hand'))
    filters.in_hand = Number(query.get('in_hand'));

  if (query.has('uses_fallback'))
    filters.uses_fallback = Number(query.get('uses_fallback'));

  if (query.has('is_listed'))
    filters.is_listed = Number(query.get('is_listed'));

  if (query.has('is_reserved'))
    filters.is_reserved = Number(query.get('is_reserved'));

  if (query.has('seatgeek_user_purchase_id'))
    filters.seatgeek_user_purchase_id = Number(query.get('seatgeek_user_purchase_id'));

  if (query.has('from_id'))
    filters.from_id = Number(query.get('from_id'));

  if (query.has('per_page'))
    filters.per_page = Number(query.get('per_page'));

  if (query.has('sort_by'))
    filters.sort_by = String(query.get('sort_by'));

  if (query.has('sort_asc'))
    filters.sort_asc = Number(query.get('sort_asc'));

  if (query.has('deal'))
    filters.deal = String(query.get('deal'));

  if (query.has('deal_term'))
    filters.deal_term = String(query.get('deal_term'));

  if (query.has('uploader'))
    filters.uploader = String(query.get('uploader'));

  if (query.has('section'))
    filters.section = String(query.get('section'));

  if (query.has('row'))
    filters.row = String(query.get('row'));

  if (query.has('seatgeek_user_listing_id'))
    filters.seatgeek_user_listing_id = Number(query.get('seatgeek_user_listing_id'));

  if (query.has('seatgeek_bulk_user_listing_id'))
    filters.seatgeek_bulk_user_listing_id = String(query.get('seatgeek_bulk_user_listing_id'));

  return filters;
}

export default queryToIngestionFilters;
