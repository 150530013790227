import React, {
  useCallback, useMemo, useState, ChangeEvent,
} from 'react';
import postPriceConstraints from '#/api/postPriceConstraints';
import formatApiError from '#/api/utils/formatApiError';
import deletePriceConstraints from '#/api/deletePriceConstraints';

interface Hook {
  disabled: boolean;
  error: string;
  fileName: string;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  handleDelete: () => Promise<void>;
  loading: boolean;
  onFileChange: (event: React.ChangeEvent) => void;
  onNotesChange: (event: React.ChangeEvent) => void;
  success: boolean;
}

const ZERO = 0;
const DEFAULT_TEXT = 'Upload CSV';

const useCsvUpload = (
  eventId: number,
  retrieveActiveEvent?: (id: number) => void,
  setModalSuccess?: (input: boolean) => void,
): Hook => {
  const [fileName, setFileName] = useState<string>(DEFAULT_TEXT);
  const [file, setFile] = useState<File>(null);
  const [notes, setNotes] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const retry = useCallback(() => {
    setError('');
    setLoading(true);
    try {
      setSuccess(false);
    } catch (err) {
      setError(formatApiError(err));
    }
    setLoading(false);
  }, []);

  const disabled = useMemo(() => {
    return !file;
  }, [file]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);
      setError('');
      try {
        await postPriceConstraints(eventId, file, notes);
        setSuccess(true);
        if (retrieveActiveEvent && setModalSuccess === null)
          retrieveActiveEvent(eventId);
        if (setModalSuccess)
          setModalSuccess(true);
      } catch (err) {
        const errorString = formatApiError(err);

        setError(errorString);
      }

      setFileName(DEFAULT_TEXT);
      setFile(null);
      setLoading(false);
      if (!setModalSuccess)
        setTimeout(retry, 1000);
    },
    [setModalSuccess, retry, eventId, file, notes, retrieveActiveEvent],
  );

  const handleDelete = useCallback(async () => {
    setError('');
    setLoading(true);
    setSuccess(false);
    try {
      await deletePriceConstraints(eventId, notes);
      setSuccess(true);
      if (retrieveActiveEvent && setModalSuccess === null)
        retrieveActiveEvent(eventId);
      if (setModalSuccess)
        setModalSuccess(true);
    } catch (err) {
      setError(formatApiError(err));
    }
    setLoading(false);
    if (!setModalSuccess)
      setTimeout(retry, 1000);
  }, [setModalSuccess, retry, eventId, notes, retrieveActiveEvent]);

  const onFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSuccess(false);
    setError('');
    const newFile = event?.target?.files[ZERO];

    if (newFile) {
      setFile(newFile);
      setFileName(newFile.name);
    }
  }, [setFile, setFileName]);

  const onNotesChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newNotes = event?.target?.value;

    if (newNotes)
      setNotes(newNotes);
  }, [setNotes]);

  return {
    disabled,
    error,
    fileName,
    handleSubmit,
    handleDelete,
    loading,
    onFileChange,
    onNotesChange,
    success,
  };
};

export default useCsvUpload;
