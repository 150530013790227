import React from 'react';
import styled from 'styled-components';
import { formatGtv } from '#/shared/formatNumberForDisplay';
import { ErrorValue } from '#/types/Errors';

interface ValueLabelProps {
  value: ErrorValue;
}

const ValueLabel: React.FC<ValueLabelProps> = ({ value }) => (
  <Container data-tag='allowRowEvents'>
    {value.portfolioValue > 0 && (
      <Entry data-tag='allowRowEvents'>{`${formatGtv(value.portfolioValue)} value`}</Entry>
    )}
    {value.ingestedUnsoldCost > 0 && (
      <Entry data-tag='allowRowEvents'>{`${formatGtv(value.ingestedUnsoldCost)} cost`}</Entry>
    )}
  </Container>
);

const Container = styled.div``;

const Entry = styled.div`
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.38;
`;

export default ValueLabel;
