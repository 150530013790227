import React, { useCallback, Dispatch } from 'react';
import styled from 'styled-components';
import { FormWrapper, Label } from './sharedComponents';
import type Action from './types';
import palette from '#/theme/palettes/main';
import { StrategyType, PriceExplorationType } from '#/types/Event';

interface SelectProps {
  initialVal: StrategyType;
  label?: string;
  disabled?: boolean;
  handleDropdownChange: Dispatch<Action>;
  type: PriceExplorationType;
}

const NONE = '--';

const SelectForm: React.FC<SelectProps> = ({
  initialVal,
  label,
  disabled = false,
  handleDropdownChange,
  type,
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = event.target.value;

      handleDropdownChange({ type, value: newValue });
    },
    [handleDropdownChange, type],
  );

  return (
    <FormWrapper>
      <Label htmlFor={label}>{label}</Label>
      <Select disabled={disabled} onChange={handleChange} value={initialVal}>
        <option value={StrategyType.NONE}>{NONE}</option>
        <option value={StrategyType.HEURISTIC}>{StrategyType.HEURISTIC}</option>
        <option value={StrategyType.CONSTANT}>{StrategyType.CONSTANT}</option>
        <option value={StrategyType.DECREASING}>{StrategyType.DECREASING}</option>
        <option value={StrategyType.INCREASING}>{StrategyType.INCREASING}</option>
        <option value={StrategyType.EMPIRICAL}>{StrategyType.EMPIRICAL}</option>
        <option value={StrategyType.UNIFORM}>{StrategyType.UNIFORM}</option>
        <option value={StrategyType.BERNOULLI}>{StrategyType.BERNOULLI}</option>
      </Select>
    </FormWrapper>
  );
};

const Select = styled.select`
input {
  width: 5rem;
  height: 1.5rem;
}
  border: 1px solid ${palette.silver.dark};
  border-radius: 8px;
  padding: 4px;
  font-size: 12px;
  text-align: center
`;

export default SelectForm;
