/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useCallback } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import {
  useOverlayPosition,
  useTooltipTrigger,
  useTooltip,
  OverlayContainer,
  TooltipTriggerProps,
} from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';
import info from '#images/Info.svg';

interface TooltipProps extends TooltipTriggerProps {
  ariaLabel: string;
  children?: React.ReactNode;
}

const InfoTooltip: React.FC<TooltipProps> = ({ children, ariaLabel, ...tooltipProps }) => {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const targetRef = useRef<HTMLButtonElement | null>(null);
  const tooltipTriggerState = useTooltipTriggerState(tooltipProps);
  const tooltipPosition = useOverlayPosition({
    overlayRef,
    targetRef,
    isOpen: tooltipTriggerState.isOpen,
    placement: 'bottom',
    offset: 5,
  });
  const trigger = useTooltipTrigger(tooltipProps, tooltipTriggerState, targetRef);
  const tooltip = useTooltip(trigger.tooltipProps, tooltipTriggerState);
  const closeTooltip = useCallback(() => tooltipTriggerState.close(), [tooltipTriggerState]);

  // close tooltip when scrolling
  useEffect(() => {
    const pageContainer = document.getElementById('page-container');

    if (pageContainer)
      pageContainer.addEventListener('scroll', closeTooltip);

    return (): void => pageContainer?.removeEventListener('scroll', closeTooltip);
  }, [closeTooltip]);

  return (
    <>
      <Button
        aria-label={ariaLabel}
        ref={targetRef}
        {...trigger.triggerProps}
      >
        <SVG src={info} />
      </Button>
      {tooltipTriggerState.isOpen && (
        <OverlayContainer>
          <div
            ref={overlayRef}
            {...tooltip.tooltipProps}
            {...tooltipPosition.overlayProps}
          >
            <ContentContainer>
              {children}
            </ContentContainer>
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

const Button = styled.button`
  position: relative;
  top: -2px;
  opacity: 0.4;
  &:hover {
    opacity: 0.7;
  }
  background: none;
  &:focus {
    opacity: 0.7;
  }
  transition: all 0.2s;
  width: 20px;
  height: 20px;
  padding: 1px;
`;

const ContentContainer = styled.div`
  font-size: 13px;
  line-height: 1.1;
  letter-spacing: 0;
  padding: 8px 6px;
  color: #111;
  background: #fefefef0;
  border: 1px solid #eee;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.5) 0 1px 4px;
  max-width: 220px;
`;

export default InfoTooltip;
