import React from 'react';
import styled from 'styled-components';
import { AriaListBoxOptions } from '@react-aria/listbox';
// eslint-disable-next-line import/no-unresolved
import { Node } from '@react-types/shared';
import { ListState } from 'react-stately';
import { useListBox, useOption } from 'react-aria';
import Check from './Icons/Check';

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
}

const List = styled.ul`
  max-height: 300px;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 2px 0;
  outline: none;
`;

interface ListItemProps {
  isFocused?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
}

const ListItem = styled.li<ListItemProps>`
  background: ${(props): string => (props.isFocused ? '#222' : '')};
  color: ${(props): string => {
    if (props.isFocused)
      return 'white';
    return props.isSelected ? '#000' : '#333';
  }};
  opacity: ${(props): number => (props.isDisabled ? 0.3 : 1)};
  cursor: ${(props): string => (props.isDisabled ? 'not-allowed' : 'pointer')};
  margin: 6px 8px;
  font-size: 14px;
  font-weight: 600;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  border-radius: 6px;
  border: 1px solid #eee;
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

function ListBox(props: ListBoxProps): JSX.Element {
  const ref = React.useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <List {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map((item) => (
        <Option item={item} key={item.key} state={state} />
      ))}
    </List>
  );
}

interface OptionContextValue {
  labelProps: React.HTMLAttributes<HTMLElement>;
  descriptionProps: React.HTMLAttributes<HTMLElement>;
}

const OptionContext = React.createContext<OptionContextValue>({
  labelProps: {},
  descriptionProps: {},
});

function Option({ item, state }: OptionProps): JSX.Element {
  const ref = React.useRef<HTMLLIElement>(null);
  const {
    optionProps,
    labelProps,
    descriptionProps,
    isSelected,
    isFocused,
    isDisabled,
  } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  );

  return (
    <ListItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...optionProps}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      ref={ref}
      title={item.textValue}
    >
      <ItemContent>
        <OptionContext.Provider value={{ labelProps, descriptionProps }}>
          {item.rendered}
        </OptionContext.Provider>
      </ItemContent>
      {isSelected && <IconWrapper><Check /></IconWrapper>}
    </ListItem>
  );
}

const IconWrapper = styled.div`
  margin-left: 6px;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
// The Label and Description components will be used within an <Item>.
// They receive props from the OptionContext defined above.
// This ensures that the option is ARIA labelled by the label, and
// described by the description, which makes for better announcements
// for screen reader users.

function Label(
  { children, maxWidth = 200 }: { children: React.ReactNode, maxWidth?: number },
): JSX.Element {
  const { labelProps } = React.useContext(OptionContext);

  return (
    <StyledLabel
      maxWidth={maxWidth}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...labelProps}
    >
      {children}
    </StyledLabel>
  );
}

interface StyledLabelProps {
  maxWidth?: number;
}

const StyledLabel = styled.div<StyledLabelProps>`
  max-width: ${({ maxWidth }): string => maxWidth ? `${maxWidth}px` : undefined};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledDescription = styled.div`
  font-weight: normal;
  font-size: 12px;
`;

function Description({ children }: { children: React.ReactNode }): JSX.Element {
  const { descriptionProps } = React.useContext(OptionContext);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledDescription {...descriptionProps}>{children}</StyledDescription>
  );
}

export default ListBox;
export {
  Label,
  Description,
};

