import React, { useContext, useMemo } from 'react';
import {
  ErrorStatusType, EventError, PostErrorStatus,
} from '#/types/Errors';
import { MOPS_FORM_LINK } from '../utils';
import Checklist, { ChecklistConfigItem } from './checklist';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';

interface ModifiedMapErrorChecklistProps {
  error: EventError;
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const ModifiedMapErrorChecklist: React.FC<ModifiedMapErrorChecklistProps> = (
  { error, setErrorStatus },
) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const map_link = `https://seatgeekadmin.com/maps/ticket_data?event_id=${activeEvent?.config?.seatgeekEventId}`;

  const config: ChecklistConfigItem[] = useMemo(() => ([
    {
      id: 0,
      level: 1,
      status: ErrorStatusType.RESOLVED,
      notes: 'Inventory Showing on SeatGeek',
    },
    {
      id: 1,
      level: 1,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Inventory NOT Showing on SeatGeek OR NOT being Priced',
    },
    {
      id: 2,
      level: 2,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Event is GA',
      parent_id: 1,
    },
    {
      id: 3,
      level: 3,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Listings have Expected Value Errors',
      parent_id: 2,
    },
    {
      id: 4,
      level: 4,
      status: ErrorStatusType.RESOLVED,
      notes: 'Unlink the SG Event using the Mapping Admin - Add a new venue config, set to GA',
      parent_id: 3,
      link: map_link,
    },
    {
      id: 5,
      level: 2,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Wrong Map Showing on SeatGeek',
      parent_id: 1,
    },
    {
      id: 6,
      level: 3,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Mops Form Submitted',
      parent_id: 5,
      link: MOPS_FORM_LINK,
    },
    {
      id: 7,
      level: 2,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Correct Map Showing on SeatGeek',
      parent_id: 1,
    },
    {
      id: 8,
      level: 3,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Section/Row NOT normalized',
      parent_id: 7,
    },
    {
      id: 9,
      level: 4,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Set Expected Value Override for Unpriced Inventory (Good option if we have little inventory)',
      parent_id: 8,
    },
    {
      id: 10,
      level: 4,
      status: ErrorStatusType.RESOLVED,
      notes: 'Event Rebuilt (Better Choice if we have bigger inventory position)',
      parent_id: 8,
    },
  ]), [map_link]);

  return (
    <Checklist config={config} error={error} setErrorStatus={setErrorStatus} />
  );
};

export default ModifiedMapErrorChecklist;
