import styled from 'styled-components';

const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.75s ease;
`;

export default Page;
