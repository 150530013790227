import React, { useMemo, useCallback } from 'react';

import {
  IngestionTicket, PostIngestion, PostIngestionTicket, StockType,
} from '#/types/Ingestion';
import {
  HorizontalWrapper, Info, InnerWrapper, Label,
} from '#/shared/modalComponents';
import TicketForm, { BARCODE, WALLET_LINK } from './TicketForm';
import { ButtonSmall } from '#/shared/Button';

const TICKETS = 'Tickets';
const WARNING = 'Warning! Available Tickets are SOLD or NOT adjacent';
const ADD = '+';
const REMOVE = '-';
const SOLD = 'Sold';
const SEAT = 'Seat';
const PB_ID = 'PB ID';
const IN_HAND = 'In Hand';

interface TicketsProps {
  soldTickets: IngestionTicket[];
  ingestion: PostIngestion;
  setIngestion: React.Dispatch<React.SetStateAction<PostIngestion>>;
}

const Tickets: React.FC<TicketsProps> = ({ soldTickets, ingestion, setIngestion }) => {
  const isAdjacent = useMemo(() => (
    ingestion.tickets
      ? ingestion.tickets.map((t) => t.seat)
        .sort((a, b) => a - b)
        .every((cur, idx, arr) => {
          const prev = arr[idx - 1];

          return !idx || cur === prev + 1;
        })
      : true
  ), [ingestion]);

  const isSold = useMemo(() => (
    ingestion.tickets?.some((t) => soldTickets.find((s) => s.seat === t.seat))
  ), [ingestion, soldTickets]);

  const ticketField = useMemo(() => (
    ingestion.stock_type === StockType.WALLET_LINKS ? WALLET_LINK : BARCODE
  ), [ingestion]);

  const removeTicket = useCallback(() => {
    const tickets = ingestion.tickets.slice(0, ingestion.tickets.length - 1);

    setIngestion((i) => ({
      ...i,
      start_seat: Math.min(...tickets.map((t) => t.seat)),
      end_seat: Math.max(...tickets.map((t) => t.seat)),
      tickets: tickets,
      quantity: tickets.length,
    }));
  }, [ingestion, setIngestion]);

  const setTicket = useCallback((ticket: PostIngestionTicket, seat: number) => {
    const tickets = ingestion.tickets ? [
      ...ingestion.tickets.filter((t) => t.seat !== seat), ticket,
    ].sort((a, b) => a.seat - b.seat)
      : [ticket];
    const start_seat = Math.min(...tickets.map((t) => t.seat));
    const end_seat = Math.max(...tickets.map((t) => t.seat));

    setIngestion((i) => ({
      ...i, tickets, start_seat, end_seat, quantity: tickets.length,
    }));
  }, [setIngestion, ingestion]);

  const addTicket = useCallback(() => {
    if (ingestion.tickets?.length > 0) {
      setTicket(
        {
          seat: ingestion.tickets[ingestion.tickets.length - 1].seat + 1,
        },
        -1,
      );
    } else {
      const tickets = [...Array(ingestion.quantity || 1).keys()].map((i) => ({
        seat: ingestion.start_seat + i,
      }));

      setIngestion((i) => ({
        ...i,
        tickets,
        quantity: ingestion.quantity || tickets.length,
        start_seat: ingestion.start_seat || 0,
        end_seat: ingestion.end_seat || tickets.length - 1,
      }));
    }
  }, [ingestion, setTicket, setIngestion]);

  return (
    <>
      <InnerWrapper>
        <HorizontalWrapper>
          <Label>{TICKETS}</Label>
        </HorizontalWrapper>
        {(!isAdjacent || isSold) && <Info>{WARNING}</Info>}
        <HorizontalWrapper>
          <Info>{SEAT}</Info>
          <Info>{ticketField}</Info>
          <Info>{PB_ID}</Info>
          <Info>{IN_HAND}</Info>
        </HorizontalWrapper>
        {ingestion.tickets && ingestion.tickets.map((ticket) => (
          <TicketForm
            key={ticket.seat}
            setTicket={setTicket}
            ticket={ticket}
            ticketField={ticketField}
          />
        ))}
        <HorizontalWrapper>
          <ButtonSmall onClick={addTicket}>{ADD}</ButtonSmall>
          <ButtonSmall disabled={ingestion.tickets?.length < 1} onClick={removeTicket}>
            {REMOVE}
          </ButtonSmall>
        </HorizontalWrapper>
        {soldTickets && soldTickets.map((t) => {
          return (
            <HorizontalWrapper key={t.seat}>
              <Info key={`seat_${t.seat}`}>{`Seat ${t.seat}`}</Info>
              <Info key={`expired_${t.seat}`}>{SOLD}</Info>
            </HorizontalWrapper>
          );
        })}
      </InnerWrapper>

    </>
  );
};

export default Tickets;
