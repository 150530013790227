import {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import getIngestions from '#/api/getIngestions';
import Ingestion, { IngestionFilters } from '#/types/Ingestion';

const SORT_BY = 'id';
const SORT_ASC = 0;
const MAX_FETCHED_INGESTIONS = 500;
const LIMIT = 7500;
const DEFAULT_COPY = 'Something went wrong retrieving ALL ingested listings!';

interface UsePatchIngestionsHook {
  patchIngestions: Ingestion[];
  setPatchIngestions: (newIngestions: Ingestion[]) => void;
  getAllIngestions: () => void;
  patchFilters: IngestionFilters;
  setPatchFilters: (newFilters: IngestionFilters) => void;
  patchIngestionErrorCopy: string;
  clearError: () => void;
  showPatchIngestionError: boolean;
  showLoader: boolean;
  showResults: boolean;
}

const usePatchIngestions = (initial?: IngestionFilters): UsePatchIngestionsHook => {
  const [patchFilters, setPatchFilters] = useState<IngestionFilters>(
    {
      sort_by: SORT_BY,
      sort_asc: SORT_ASC,
      is_pinned: false,
      ...initial,
    },
  );
  const [patchIngestions, setPatchIngestions] = useState<Ingestion[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [patchIngestionErrorCopy, setPatchIngestionErrorCopy] = useState<string>(DEFAULT_COPY);
  const clearError = useCallback(() => setPatchIngestionErrorCopy(''), [setPatchIngestionErrorCopy]);

  const getPatchIngestions = useCallback(async (newIngestions: Ingestion[],
    results: Ingestion[]): Promise<void> => {
    if (results.length > LIMIT) {
      setHasError(true);
      setPatchIngestionErrorCopy(`The limit of ${LIMIT} ingestions has been reached. Please try again on a smaller selection.`);
      return;
    }
    const fromId = newIngestions[newIngestions.length - 1].id;
    const [returnedIngestions] = await getIngestions(
      { ...patchFilters, per_page: MAX_FETCHED_INGESTIONS, from_id: fromId },
    ) || [];

    results.push(...returnedIngestions);
    if (returnedIngestions.length === MAX_FETCHED_INGESTIONS)
      await getPatchIngestions(returnedIngestions, results);
  }, [patchFilters]);

  const getAllIngestions = useCallback(async (): Promise<void> => {
    setHasError(false);
    setLoading(true);
    try {
      const allPatchIngestions = patchIngestions;

      if (patchFilters) {
        await getPatchIngestions(patchIngestions, allPatchIngestions);
        setPatchIngestions(allPatchIngestions);
      }
    } catch {
      setHasError(true);
    }
    setLoading(false);
  }, [patchFilters, patchIngestions, getPatchIngestions]);

  const showResults = useMemo(() => !isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showLoader = useMemo(() => isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showPatchIngestionError = useMemo(() => !isLoading && hasError, [
    hasError,
    isLoading,
  ]);

  useEffect(() => {
    setPatchIngestions([]);
    if (!showPatchIngestionError && patchIngestionErrorCopy === '')
      setPatchIngestionErrorCopy(DEFAULT_COPY);
  }, [showPatchIngestionError, patchIngestionErrorCopy]);

  return {
    patchIngestions,
    getAllIngestions,
    patchFilters,
    setPatchFilters,
    setPatchIngestions,
    clearError,
    patchIngestionErrorCopy,
    showPatchIngestionError,
    showLoader,
    showResults,
  };
};

export { UsePatchIngestionsHook };
export default usePatchIngestions;
