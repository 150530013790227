import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { formatCurrency } from '#/shared/formatNumberForDisplay';
import Event, { ValueField } from '#/types/Event';

interface CardProps {
  event: Event;
  valueField: ValueField;
}

const SOLD = 'Sale ';
const AVG_SOLD = 'Avg. Sale ';

const NA = 'N/A';

const SaleCostCard: React.FC<CardProps> = ({ event, valueField }) => {
  const { saleCost, saleFaceValue, saleTicketCt } = event.summary;

  const value = valueField === ValueField.COST ? saleCost : saleFaceValue;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{SOLD + valueField}</Name>
        <Value>{formatCurrency(value)}</Value>
      </Element>
      <Element>
        <Name>{AVG_SOLD + valueField}</Name>
        <Value>
          {
            saleTicketCt === 0
              ? NA
              : formatCurrency(value / saleTicketCt)
          }
        </Value>
      </Element>
    </NarrowWrapper>
  );
};

export default SaleCostCard;
