import { DateTime } from 'luxon';

const isoRegex = new RegExp(
  '^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(?:\\.\\d+)?(?:Z|[-+]\\d{2}:\\d{2})?$',
);
const dateParser = (key: any, value: any): any => {
  if (typeof value === 'string' && (key.endsWith('At') || key.endsWith('Date') || key === 'eventStartsAtLocal')) {
    if (isoRegex.exec(value)) {
      if (value.endsWith('Z'))
        return DateTime.fromISO(value, { zone: 'utc' });

      return DateTime.fromISO(value, { zone: 'local' });
    }
  }
  return value;
};
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const handleDate = (data: any): any => {
  const dataString = JSON.stringify(data);

  return JSON.parse(dataString, dateParser);
};

export default handleDate;
