import React from 'react';
import {
  ErrorStatusType, EventError, PostErrorStatus,
} from '#/types/Errors';
import { MOPS_FORM_LINK } from '../utils';
import Checklist, { ChecklistConfigItem } from './checklist';

interface TbdErrorChecklistProps {
  error: EventError;
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const CONFIG: ChecklistConfigItem[] = [
  {
    id: 0,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Event is Rescheduled on Primary',
  },
  {
    id: 1,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Mops Form Submitted',
    parent_id: 0,
    link: MOPS_FORM_LINK,
  },
  {
    id: 2,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Event is NOT Rescheduled on Primary',
  },
];

const TbdErrorChecklist: React.FC<TbdErrorChecklistProps> = ({ error, setErrorStatus }) => {
  return (
    <Checklist config={CONFIG} error={error} setErrorStatus={setErrorStatus} />
  );
};

export default TbdErrorChecklist;
