import { AxiosResponse } from 'axios';
import { Deal } from '#/types/Deal';
import client from './_clientFormatted';

const deleteDeal = async (dealId: number): Promise<Deal> => {
  const response: AxiosResponse<Deal> = await client.delete(`/proxy/deals/${dealId}`);

  return response.data;
};

export default deleteDeal;
