import { FormattedPricingChartDatum } from '#/pages/ViewPage/components/Pricing/types';
import { MarketplaceTransaction } from '#/types/MarketplaceTransaction';
import { ListingSubset } from '#/types/Preview';

const SG_SALE = 'SeatGeek sales';

const formatGroup = (g: string): string | null => {
  let formatted = null;

  if (g === ListingSubset.AUTOBROKER)
    formatted = 'SGIQ';

  else if (g === ListingSubset.COMPETITOR)
    formatted = 'Competitor';

  return formatted;
};

const formatMarketplaceTransaction = (
  marketplaceTransaction: MarketplaceTransaction,
): FormattedPricingChartDatum => {
  const {
    id,
    quantity,
    displayPrice,
    logExpectedValue,
    logPriceRatio,
    listingSubset,
    daysToEvent,
    logHoursToEvent,
    allInPrice,
    inventorySourceType,
  } = marketplaceTransaction;

  return ({
    key: id.toString(),
    quantity,
    displayPrice,
    allInPrice,
    daysToEvent,
    logHoursToEvent,
    logExpectedValue,
    logPriceRatio,
    group: formatGroup(listingSubset),
    inventorySourceType,
    measure: SG_SALE,
  });
};

export default formatMarketplaceTransaction;
export { SG_SALE };
