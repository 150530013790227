import React, { useCallback, useState } from 'react';
import { ActiveEventProvider } from './contexts/ActiveEvent';
import EventTray from './components/EventTray';
import Tabs from './components/Tabs';
import { FetchEventsHook } from '#/types/Event';
import { FetchGroupsHook } from '../useFetchGroups';
import { StakeholderLogos } from '../useFetchStakeholders';
import PageWrapper from '#/shared/PageWrapper';

interface ViewPageProps {
  eventsHook: FetchEventsHook;
  groupsHook: FetchGroupsHook;
  stakeholderLogos: StakeholderLogos;
}

const ViewPage: React.FC<ViewPageProps> = ({ eventsHook, groupsHook, stakeholderLogos }) => {
  const {
    user,
    events,
    retry,
    showError,
    showLoader,
    showResults,
  } = eventsHook;
  const [sliderIsOpen, setSliderIsOpen] = useState(false);
  const toggleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setSliderIsOpen(!sliderIsOpen);
    },
    [setSliderIsOpen, sliderIsOpen],
  );

  return (
    <ActiveEventProvider>
      <EventTray
        events={events}
        retry={retry}
        showError={showError}
        showLoader={showLoader}
        showResults={showResults}
        sliderIsOpen={sliderIsOpen}
        toggleOpen={toggleOpen}
      />
      <PageWrapper>
        <Tabs
          groupsHook={groupsHook}
          showError={showError}
          showLoader={showLoader}
          sliderIsOpen={sliderIsOpen}
          stakeholderLogos={stakeholderLogos}
          toggleOpen={toggleOpen}
          user={user}
        />
      </PageWrapper>
    </ActiveEventProvider>
  );
};

export default ViewPage;
