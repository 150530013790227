import React, {
  useState,
  useCallback,
  MouseEventHandler,
  useEffect,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import Logo, { LogoMark } from '#/shared/Logo';
import ToggleSliderButton from './ToggleSliderButton';
import UploadMenu from './UploadMenu';
import UploadListingModal from './UploadListingModal';
import UploadInventoryModal from './UploadInventoryModal';
import UploadIngestionUpdatesModal from './UploadIngestionUpdatesModal';
import UploadOverridesModal from './UploadOverridesModal';
import UploadPriceConstraintsModal from './UploadPriceConstraintsModal';
import UploadExternalTransactionsModal from './UploadExternalTransactionsModal';
import { User } from '#/types/User';
import MultiStepManager from '#/pages/ViewPage/components/Configuration/Modals/MultiStepManager';
import modalStyles from '../modalStyles';

const SEARCH = 'Search';
const COLLECTION = 'Collection';
const INGESTIONS = 'Ingested';
const SALES = 'Sales';
const MANAGE = 'Manage';
const ERRORS = 'Errors';
const LOG_OUT = 'Log Out';

interface HeaderProps {
  user: User;
  hasSlider?: boolean;
  toggleOpen: MouseEventHandler<HTMLButtonElement>;
}

const Header: React.FC<HeaderProps> = ({
  user,
  hasSlider = false,
  toggleOpen,
}) => {
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [listingModalOpen, setListingModalOpen] = useState(false);
  const [inventoryModalOpen, setInventoryModalOpen] = useState(false);
  const [ingestionUpdatesModalOpen, setIngestionUpdatesModalOpen] = useState(false);
  const [overridesModalOpen, setOverridesModalOpen] = useState(false);
  const [priceConstraintsModalOpen, setPriceConstraintsModalOpen] = useState(false);
  const [externalTransactionsModalOpen, setExternalTransactionsModalOpen] = useState(false);

  const openEventModal = useCallback(() => {
    setEventModalOpen(true);
  }, [setEventModalOpen]);
  const closeEventModal = useCallback(() => {
    setEventModalOpen(false);
  }, [setEventModalOpen]);
  const openListingModal = useCallback(() => {
    setListingModalOpen(true);
  }, [setListingModalOpen]);
  const closeListingModal = useCallback(() => {
    setListingModalOpen(false);
  }, [setListingModalOpen]);
  const openInventoryModal = useCallback(() => {
    setInventoryModalOpen(true);
  }, [setInventoryModalOpen]);
  const closeInventoryModal = useCallback(() => {
    setInventoryModalOpen(false);
  }, [setInventoryModalOpen]);
  const openIngestionUpdatesModal = useCallback(() => {
    setIngestionUpdatesModalOpen(true);
  }, [setIngestionUpdatesModalOpen]);
  const closeIngestionUpdatesModal = useCallback(() => {
    setIngestionUpdatesModalOpen(false);
  }, [setIngestionUpdatesModalOpen]);
  const openOverridesModal = useCallback(() => {
    setOverridesModalOpen(true);
  }, [setOverridesModalOpen]);
  const closeOverridesModal = useCallback(() => {
    setOverridesModalOpen(false);
  }, [setOverridesModalOpen]);
  const openPriceConstraintsModal = useCallback(() => {
    setPriceConstraintsModalOpen(true);
  }, [setPriceConstraintsModalOpen]);
  const closePriceConstraintsModal = useCallback(() => {
    setPriceConstraintsModalOpen(false);
  }, [setPriceConstraintsModalOpen]);
  const openExternalTransactionsModal = useCallback(() => {
    setExternalTransactionsModalOpen(true);
  }, [setExternalTransactionsModalOpen]);
  const closeExternalTransactionsModal = useCallback(() => {
    setExternalTransactionsModalOpen(false);
  }, [setExternalTransactionsModalOpen]);

  useEffect(() => Modal.setAppElement('body'), []);

  return (
    <>
      <HeaderContainer>
        <LogoContainer style={{ left: hasSlider ? 0 : 36 }}>
          {hasSlider && <ToggleSliderButton toggleOpen={toggleOpen} />}
          <TitleLink to="/">
            <LogoMark>
              <Logo />
            </LogoMark>
          </TitleLink>
        </LogoContainer>
        <Links>
          {user.isReadWrite && (
            <UploadMenu
              openEventModal={openEventModal}
              openExternalTransactionsModal={openExternalTransactionsModal}
              openIngestionUpdatesModal={openIngestionUpdatesModal}
              openInventoryModal={openInventoryModal}
              openListingModal={openListingModal}
              openOverridesModal={openOverridesModal}
              openPriceConstraintsModal={openPriceConstraintsModal}
            />
          )}
          <HeaderLink to="/search">{SEARCH}</HeaderLink>
          <HeaderLink to="/view/collection">{COLLECTION}</HeaderLink>
          <HeaderLink to="/ingestions">{INGESTIONS}</HeaderLink>
          <HeaderLink to="/errors">{ERRORS}</HeaderLink>
          <HeaderLink to="/sales">{SALES}</HeaderLink>
          <HeaderLink to="/manage">{MANAGE}</HeaderLink>
          <HeaderLink to="/logout">{LOG_OUT}</HeaderLink>
        </Links>
      </HeaderContainer>
      {eventModalOpen && (
        <Modal
          isOpen
          onRequestClose={closeEventModal}
          style={modalStyles}
        >
          <MultiStepManager closeModal={closeEventModal} />
        </Modal>
      )}
      {listingModalOpen && (
        <UploadListingModal closeModal={closeListingModal} initial={null} />
      )}
      {inventoryModalOpen && (
        <UploadInventoryModal closeModal={closeInventoryModal} />
      )}
      {ingestionUpdatesModalOpen && (
        <UploadIngestionUpdatesModal closeModal={closeIngestionUpdatesModal} />
      )}
      {overridesModalOpen && (
        <UploadOverridesModal closeModal={closeOverridesModal} />
      )}
      {priceConstraintsModalOpen && (
        <UploadPriceConstraintsModal closeModal={closePriceConstraintsModal} />
      )}
      {externalTransactionsModalOpen && (
        <UploadExternalTransactionsModal closeModal={closeExternalTransactionsModal} />
      )}
    </>
  );
};

const LogoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 12px;
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.highlight};
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.75rem 0;
  #logo {
    padding-left: 4px;
    padding-right: 12px;
    width: 26px;
    height: 20px;
  }
`;

const Links = styled.div`
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;

const HeaderLink = styled(Link)`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.highlight};
  text-decoration: none;
  font-weight: 600;
  padding: 15px;
`;

export default Header;
