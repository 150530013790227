import {
  useState, useEffect, useCallback,
} from 'react';
import getSettings from '#/api/getSettings';
import { Settings } from '#/types/Settings';
import formatApiError from '#/api/utils/formatApiError';

interface SettingsData {
  settings: Settings;
  settingsError: string;
  fetchSettings: () => void;
}

const SECONDS_PER_DAY = 24 * 60 * 60;

const useFetchSettings = (): SettingsData => {
  const [settings, setSettings] = useState<Settings>();
  const [settingsError, setSettingsError] = useState<string>('');

  const fetchSettings = useCallback(async () => {
    try {
      const s = await getSettings();

      convertToSeconds(s);
    } catch (err) {
      const errorString = formatApiError(err);

      setSettingsError(errorString);
    }
  }, [setSettingsError]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const convertToSeconds = ((s: Settings): void => {
    const { min, max } = s.inventoryUpdaterInterval;

    if (min.days)
      min.seconds = (min.seconds || 0) + min.days * SECONDS_PER_DAY;
    min.days = undefined;

    if (max.days)
      max.seconds = (max.seconds || 0) + max.days * SECONDS_PER_DAY;
    max.days = undefined;
    setSettings(s);
  });

  return {
    settings,
    settingsError,
    fetchSettings,
  };
};

export default useFetchSettings;
