import React, { useState, useCallback } from 'react';
import PriceLevelsTable from './PriceLevelsTable';
import { User } from '#/types/User';
import PriceLevel from '#/types/PriceLevel';
import { UsePriceLevelsHook } from './useFetchPriceLevels';
import { UsePriceListHook } from './useFetchPriceList';
import PriceLevelDetail from './PriceLevelDetail';
import PriceLevelOverrideModal from './PriceLevelOverrideModal';
import MultiPriceLevelsDetail from './MultiPriceLevelsDetail';
import { StakeholderLogos } from '#/pages/useFetchStakeholders';

interface PriceLevelsTableAndDetailProps {
  priceLevelsHook: UsePriceLevelsHook;
  priceListHook: UsePriceListHook;
  showPrices: boolean;
  user: User;
  stakeholderLogos: StakeholderLogos;
}

const PriceLevelsTableAndDetail: React.FC<PriceLevelsTableAndDetailProps> = ({
  user,
  showPrices,
  priceLevelsHook,
  priceListHook,
  stakeholderLogos,
}) => {
  const [selected, setSelected] = useState<PriceLevel[]>([]);
  const [overridePriceLevelModal, setOverridePriceLevelModal] = useState<PriceLevel[]>([]);
  const { retry: retryPriceLevels } = priceLevelsHook;
  const { retry: retryPriceList } = priceListHook;

  const closeDetail = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  const closeOverrideModal = useCallback(() => {
    setOverridePriceLevelModal([]);
    retryPriceLevels();
    retryPriceList();
  }, [setOverridePriceLevelModal, retryPriceLevels, retryPriceList]);

  const canEdit = user.isReadWrite;

  return (
    <>
      {selected.length === 1 && (
        <PriceLevelDetail
          canEdit={canEdit}
          closeDetail={closeDetail}
          closeable
          priceLevel={selected[0]}
          setOverridePriceLevelModal={setOverridePriceLevelModal}
          showPrices={showPrices}
        />
      )}
      {selected.length > 1 && (
        <MultiPriceLevelsDetail
          canEdit={canEdit}
          closeDetail={closeDetail}
          priceLevels={selected}
          setOverridePriceLevelModal={setOverridePriceLevelModal}
        />
      )}
      <PriceLevelsTable
        canEdit={canEdit}
        priceLevelsHook={priceLevelsHook}
        priceListHook={priceListHook}
        selectedIds={selected.map((s) => s.id)}
        setOverridePriceLevelModal={setOverridePriceLevelModal}
        setSelected={setSelected}
        showPrices={showPrices}
        stakeholderLogos={stakeholderLogos}
      />
      {canEdit && overridePriceLevelModal.length > 0 && (
        <PriceLevelOverrideModal
          closeModal={closeOverrideModal}
          priceLevels={overridePriceLevelModal}
        />
      )}
    </>
  );
};

export default PriceLevelsTableAndDetail;
