import CsvSchemaType from '#/types/Csv';
import client from './_client';

enum FileType {
  CSV = 'csv_file',
  ZIP = 'zip_file',
}

type APICall = (file: File, type: FileType, csvSchemaType: CsvSchemaType) => Promise<number>;

const postIngestionCsv: APICall = async (
  file: File,
  type: FileType,
  csvSchemaType: CsvSchemaType,
) => {
  const formData = new FormData();

  formData.append(type, file);
  const response = await client.post('/proxy/ingestion_csv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      csv_schema_type: csvSchemaType,
    },
  });

  return response.status;
};

export default postIngestionCsv;
export { FileType, CsvSchemaType };
