import React from 'react';
import ShowImageButton from './ShowImageButton';
import arrowicon from '#images/arrowicon.svg';

interface ShowButtonProps {
  show: boolean;
  toggleShow: () => void;
}

const ShowRangeButton: React.FC<ShowButtonProps> = ({ show, toggleShow }) => {
  return (
    <ShowImageButton
      image={arrowicon}
      imageText="arrow"
      show={show}
      toggleShow={toggleShow}
    />
  );
};

export default ShowRangeButton;
