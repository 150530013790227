import React from 'react';
import {
  NarrowWrapper, Element, Name, Value, NameValue,
} from '#/shared/Card';
import PriceLevel from '#/types/PriceLevel';

const SOURCE = 'Source';
const SOURCE_EVENT = 'External Event';
const SOURCE_PRICE_LEVEL = 'Source Price Level';

interface CardProps {
  priceLevel: PriceLevel;
}

const SourceCard: React.FC<CardProps> = ({ priceLevel }) => {
  const {
    sourceName, sourceExternalEventId, sourcePriceLevelId,
  } = priceLevel.level;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{SOURCE}</Name>
        <Value>{sourceName}</Value>
      </Element>
      <Element>
        <Name>{SOURCE_EVENT}</Name>
        <Value>{sourceExternalEventId}</Value>
      </Element>
      <Element>
        <Name>{SOURCE_PRICE_LEVEL}</Name>
        <NameValue>
          {sourcePriceLevelId}
        </NameValue>
      </Element>
    </NarrowWrapper>
  );
};

export default SourceCard;
