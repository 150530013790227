import React, { useContext, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router';
import Tab from '#/shared/Tab';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';

interface TabBarProps {
  activeTab: TabLabel;
  setActiveTab: (activeTab: TabLabel) => void;
}

enum TabLabel {
  CONFIGURATION = 'Configuration',
  PRICING = 'Pricing',
  SUMMARY = 'Summary',
  INGESTIONS = 'Ingestions',
  LISTINGS = 'Listings',
  PRICE_LEVELS = 'Price-Levels',
  SALES = 'Sales',
  UPDATES = 'Updates',
  PRICE_CONSTRAINTS = 'Price-Constraints',
  OVERRIDES = 'Overrides',
  TROUBLESHOOTING = 'Troubleshooting',
}

function getTabLabel(hash?: string): (TabLabel | null) {
  switch (hash?.toLowerCase()) {
    case '#configuration':
      return TabLabel.CONFIGURATION;
    case '#pricing':
      return TabLabel.PRICING;
    case '#summary':
      return TabLabel.SUMMARY;
    case '#ingestions':
      return TabLabel.INGESTIONS;
    case '#listings':
      return TabLabel.LISTINGS;
    case '#price-levels':
      return TabLabel.PRICE_LEVELS;
    case '#sales':
      return TabLabel.SALES;
    case '#updates':
      return TabLabel.UPDATES;
    case '#price-constraints':
      return TabLabel.PRICE_CONSTRAINTS;
    case '#overrides':
      return TabLabel.OVERRIDES;
    case '#troubleshooting':
      return TabLabel.TROUBLESHOOTING;
    default:
      return null;
  }
}

const TabBar: React.FC<TabBarProps> = ({ activeTab, setActiveTab }) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const hasPriceConstraints = useMemo(() => activeEvent?.hasPriceConstraints, [activeEvent]);
  const hasEventErrors = useMemo(() => activeEvent?.eventErrors.length > 0, [activeEvent]);
  const hasPriceLevels = useMemo(() => activeEvent?.sourceEvents.filter(
    (s) => s.sourceName === 'peakpass',
  ).length > 0,
  [activeEvent]);

  const navigate = useNavigate();
  const location = useLocation();

  // handles the case where the previously-active tab is no longer available
  useEffect(() => {
    if (activeEvent && activeTab === TabLabel.PRICE_CONSTRAINTS && !hasPriceConstraints) {
      setActiveTab(TabLabel.PRICING);
      navigate(location.pathname, { replace: true });
    }
  }, [activeTab, hasPriceConstraints, activeEvent, navigate, location, setActiveTab]);

  return (
    <Wrapper>
      <Tab
        activeTab={activeTab}
        header
        name={TabLabel.PRICING}
        setActiveTab={setActiveTab}
      />
      <Tab
        activeTab={activeTab}
        header
        name={TabLabel.CONFIGURATION}
        setActiveTab={setActiveTab}
      />
      <Tab
        activeTab={activeTab}
        header
        name={TabLabel.SUMMARY}
        setActiveTab={setActiveTab}
      />
      <Tab
        activeTab={activeTab}
        header
        name={TabLabel.INGESTIONS}
        setActiveTab={setActiveTab}
      />
      <Tab
        activeTab={activeTab}
        header
        name={TabLabel.LISTINGS}
        setActiveTab={setActiveTab}
      />
      {
        hasPriceLevels && (
          <Tab
            activeTab={activeTab}
            header
            name={TabLabel.PRICE_LEVELS}
            setActiveTab={setActiveTab}
          />
        )
      }
      <Tab
        activeTab={activeTab}
        header
        name={TabLabel.SALES}
        setActiveTab={setActiveTab}
      />
      <Tab
        activeTab={activeTab}
        header
        name={TabLabel.UPDATES}
        setActiveTab={setActiveTab}
      />
      <Tab
        activeTab={activeTab}
        header
        name={TabLabel.OVERRIDES}
        setActiveTab={setActiveTab}
      />
      { hasPriceConstraints && (
        <Tab
          activeTab={activeTab}
          header
          name={TabLabel.PRICE_CONSTRAINTS}
          setActiveTab={setActiveTab}
        />
      )}
      { hasEventErrors && (
        <Tab
          activeTab={activeTab}
          header
          name={TabLabel.TROUBLESHOOTING}
          setActiveTab={setActiveTab}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-left: 6.25rem;
  width: 83.75rem;
`;

export { TabLabel, getTabLabel };
export default TabBar;
