import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { formatGtv, formatPercentage } from '#/shared/formatNumberForDisplay';
import Event from '#/types/Event';

interface CardProps {
  event: Event;
}

const RISK_TODAY = 'Risk Today';
const RISK_THIS_WEEK = 'Risk This Week';

const PortfolioRiskCard: React.FC<CardProps> = ({ event }) => {
  const { portfolioValue, portfolioValueSelldownDerivative } = event.summary;
  const { now, tomorrow, nextWeek } = event.selldown;

  const riskToday = (
    tomorrow > 0 && tomorrow <= now
      ? portfolioValueSelldownDerivative * ((tomorrow - now) / now)
      : -portfolioValue
  );
  const riskThisWeek = (
    nextWeek > 0 && nextWeek <= now
      ? portfolioValueSelldownDerivative * ((nextWeek - now) / now)
      : -portfolioValue
  );

  return (
    <NarrowWrapper>
      <Element>
        <Name>{RISK_THIS_WEEK}</Name>
        <Value>{`${formatGtv(riskThisWeek)} (${formatPercentage(Math.abs(riskThisWeek / portfolioValue))})`}</Value>
      </Element>
      <Element>
        <Name>{RISK_TODAY}</Name>
        <Value>{`${formatGtv(riskToday)} (${formatPercentage(Math.abs(riskToday / portfolioValue))})`}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default PortfolioRiskCard;
