import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';
import getTransactions from '#/api/getTransactions';
import queryToTransactionFilters from '#/api/utils/queryToTransactionFilters';
import Transaction, { TransactionFilters } from '#/types/Transaction';

const PER_PAGE = 25;

interface UseTransactionsHook {
  transactions: Transaction[];
  perPage: number;
  pages: number[];
  nextPage: () => void;
  prevPage: () => void;
  filters: TransactionFilters;
  setFilters: (newFilters: TransactionFilters) => void;
  retry: () => void;
  showError: boolean;
  showLoader: boolean;
  showResults: boolean;
}

const useFetchTransactions = (initial?: TransactionFilters): UseTransactionsHook => {
  const initialFiltersString = JSON.stringify(initial);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const isVisible = usePageVisibility();

  const [filters, setFiltersRaw] = useState<TransactionFilters>(
    { per_page: PER_PAGE, ...initial, ...queryToTransactionFilters(search) },
  );
  const [pages, setPages] = useState<number[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [perPage, setPerPage] = useState<number>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const retry = useCallback(async () => {
    setHasError(false);
    try {
      if (filters.autobroker_event_id || !initialFiltersString) {
        const [returnedTransactions, returnedPerPage] = await getTransactions(filters) || [];

        setTransactions(returnedTransactions);
        setPerPage(returnedPerPage);
      }
    } catch {
      setHasError(true);
    }
    setLoading(false);
  }, [filters, initialFiltersString]);

  const updateQuery = useCallback(() => {
    if (pathname === '/sales') {
      const filterStrings = Object.entries(filters)
        .filter((key_value) => typeof key_value[1] !== 'undefined' && key_value[1] !== null)
        .map(([key, value]) => [key, String(value)]);

      navigate({
        pathname,
        search: '?' + new URLSearchParams(filterStrings).toString(),
      });
    }
  }, [filters, pathname, navigate]);

  const showResults = useMemo(() => !isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showLoader = useMemo(() => isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showError = useMemo(() => !isLoading && hasError, [
    hasError,
    isLoading,
  ]);

  // refresh the event data
  useEffect(() => {
    setLoading(true);
    retry();
    const interval = setInterval(() => {
      if (pages.length === 0 && isVisible)
        retry();
    }, 20000);

    return (): void => clearInterval(interval);
  }, [pages.length, retry, isVisible]);

  useEffect(() => {
    updateQuery();
  }, [updateQuery]);

  const nextPage = useCallback(() => {
    setPages((p) => [...p, filters.from_id]);
    const fromId = transactions[transactions.length - 1].id;

    setFiltersRaw({ ...filters, from_id: fromId });
  },
  [transactions, filters]);

  const prevPage = useCallback(() => {
    setFiltersRaw({ ...filters, from_id: pages[pages.length - 1] });
    setPages((p) => p.slice(0, p.length - 1));
  },
  [filters, pages]);

  const setFilters = useCallback((newFilters: TransactionFilters) => {
    setFiltersRaw({ ...newFilters, from_id: undefined });
    setPages([]);
  }, []);

  return {
    transactions,
    perPage,
    pages,
    nextPage,
    prevPage,
    filters,
    setFilters,
    retry,
    showError,
    showLoader,
    showResults,
  };
};

export { PER_PAGE, UseTransactionsHook };
export default useFetchTransactions;
