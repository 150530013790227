import React, { useCallback } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { RollupGroupVar } from '#/types/ReportingRollup';
import { Checkbox } from '#/shared/Filters';

const SUMMARY = 'Breakdown';
const GROUP_BY = 'Group By';
const CHECKBOX_LABEL = 'Only Deal Term Inventory';

interface SummaryHeaderProps {
  groupBy: RollupGroupVar;
  setGroupBy: (newGroupBy: RollupGroupVar) => void;
  hasDealTerm: boolean;
  setHasDealTerm: (newHasDealTerm: boolean) => void;
}

interface GroupByOption {
  value?: RollupGroupVar;
  label: string;
  id: number;
}

const GROUP_BY_OPTIONS: GroupByOption[] = [
  { value: undefined, label: 'Overall', id: 0 },
  { value: 'stakeholder', label: 'Stakeholder', id: 1 },
  { value: 'deal', label: 'Deal', id: 2 },
  { value: 'deal_term', label: 'Deal Term', id: 3 },
  { value: 'inventory_source_type', label: 'Inventory Source Type', id: 4 },
  { value: 'section_group', label: 'Section Group', id: 5 },
  { value: 'section', label: 'Section', id: 6 },
  { value: 'marketplace', label: 'Marketplace', id: 7 },
  { value: 'week', label: 'Week', id: 8 },
  { value: 'month', label: 'Month', id: 9 },
  { value: 'price', label: 'Price', id: 10 },
  { value: 'quantity', label: 'Quantity', id: 11 },
];
const BreakdownHeader: React.FC<SummaryHeaderProps> = ({
  groupBy,
  setGroupBy,
  hasDealTerm,
  setHasDealTerm,
}) => {
  const onChangeGroupBy = useCallback((option: GroupByOption) => {
    setGroupBy(option?.value);
  }, [setGroupBy]);

  const onChangeHasDealTerm = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHasDealTerm(event.target.checked);
  }, [setHasDealTerm]);

  return (
    <>
      <Header>
        <Title>
          {SUMMARY}
        </Title>
        <CheckboxContainer>
          <CheckboxLabel>
            {CHECKBOX_LABEL}
          </CheckboxLabel>
          <Checkbox
            checked={hasDealTerm}
            onChange={onChangeHasDealTerm}
            title="Filter to inventory that has a deal term"
            type="checkbox"
          />
        </CheckboxContainer>
        <SelectContainer>
          <Select
            defaultValue={
              GROUP_BY_OPTIONS.find((s) => s.value === groupBy)
            }
            isMulti={false}
            onChange={onChangeGroupBy}
            options={GROUP_BY_OPTIONS}
            placeholder={GROUP_BY}
          />
        </SelectContainer>
      </Header>
    </>
  );
};

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const Header = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  padding-top: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SelectContainer = styled.div`
  width: 14rem;
  height: 1.5rem;
  margin-bottom: 0.4rem;
  ${({ theme }: { theme: Theme }): string => theme.text2};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.secondary};
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.4rem;
  margin-right: 0.5rem;
`;

const CheckboxLabel = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.text2};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.secondary};
  margin-right: 0.5rem;
`;

export default BreakdownHeader;
