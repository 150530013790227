import { type AxiosError } from 'axios';
import { type GenericAPIErrorResponse } from '#/types/Errors';

const formatApiError = (error: AxiosError<GenericAPIErrorResponse>): string => {
  const { response } = error;

  if (response?.data?.reason)
    return `${response.data.reason}: ${response.data.message}`;

  return error.toString();
};

export default formatApiError;
