import { GraphPlot } from '#/types/Snapshot';
import { ZERO } from '../../constants';
import { FilterField } from '../types';

interface Filters {
  [FilterField.INVENTORY_SOURCE_TYPE]: string[];
  [FilterField.QUANTITY]: number[];
  [FilterField.SELLER_ID]: number[];
}

const filterByParam = (
  data: GraphPlot[],
  param: keyof GraphPlot,
  values: Array<string | number>,
): GraphPlot[] => {
  const filtered = data.filter((plotPoint: GraphPlot) => {
    if (values.includes(plotPoint[param]))
      return plotPoint;
    return null;
  });

  return filtered;
};

const filterListings = (data: GraphPlot[], filters: Filters): GraphPlot[] => {
  const filterKeys = Object.keys(filters).filter(
    (key: keyof Filters) => filters[key].length > ZERO,
  );

  let filtered: GraphPlot[] = [...data];

  filterKeys.forEach((key: keyof Filters) => {
    filtered = filterByParam(filtered, key, filters[key]);
  });

  return filtered;
};

export { Filters, filterListings };
