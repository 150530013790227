import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getEvents from '#/api/getEvents';
import queryToEventFilters from '#/api/utils/queryToEventFilters';
import Event, { EventFilters, FetchEventsHook } from '#/types/Event';
import { User } from '#/types/User';

const useEventTray = (user: User): FetchEventsHook => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [pages, setPages] = useState<number[]>([]);
  const [filters, setFiltersRaw] = useState<EventFilters>(
    { is_active: 1, per_page: 50, ...queryToEventFilters(search) },
  );
  const [events, setEvents] = useState<Event[]>([]);
  const [perPage, setPerPage] = useState<number>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const retry = useCallback(async () => {
    setLoading(true);
    setHasError(false);
    try {
      const [returnedEvents, returnedPerPage] = await getEvents(filters) || [];

      setEvents(returnedEvents);
      setPerPage(returnedPerPage);
    } catch {
      setHasError(true);
    }
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    if (user && !user.isReporting)
      retry();
  }, [retry, user]);

  const updateQuery = useCallback(() => {
    if (pathname === '/search') {
      const filterStrings = Object.entries(filters)
        .filter((key_value) => typeof key_value[1] !== 'undefined' && key_value[1] !== null)
        .map(([key, value]) => [key, String(value)]);

      navigate({
        pathname,
        search: `?${new URLSearchParams(filterStrings)}`,
      });
    }
  }, [filters, pathname, navigate]);

  useEffect(() => {
    updateQuery();
  }, [updateQuery]);

  const showResults = useMemo(() => !isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showLoader = useMemo(() => isLoading && !hasError, [
    hasError,
    isLoading,
  ]);
  const showError = useMemo(() => !isLoading && hasError, [
    hasError,
    isLoading,
  ]);

  const nextPage = useCallback(() => {
    setPages((p) => [...p, filters.from_id]);
    const fromId = events[events.length - 1].id;

    setFiltersRaw({ ...filters, from_id: fromId });
  },
  [events, filters]);

  const prevPage = useCallback(() => {
    setFiltersRaw({ ...filters, from_id: pages[pages.length - 1] });
    setPages((p) => p.slice(0, p.length - 1));
  },
  [filters, pages]);

  const setFilters = useCallback((newFilters: EventFilters) => {
    setFiltersRaw({ ...newFilters, from_id: undefined });
    setPages([]);
  }, []);

  return {
    user,
    events,
    perPage,
    filters,
    setFilters,
    nextPage,
    prevPage,
    retry,
    showError,
    showLoader,
    showResults,
  };
};

export default useEventTray;
