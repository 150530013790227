import React from 'react';
import {
  Wrapper, Element, Name, WhiteSpaceValue,
} from '#/shared/Card';
import { OverrideChangeLogEntry } from '#/types/Override';
import { formatChanges, formatType } from '#/pages/ViewPage/components/OverrideLogs/OverrideLogsTable/utils/helpers';

const TYPE = 'Type';
const UPDATES = 'Updates';
const DELETED_UPDATES = 'Deleted Updates';

interface CardProps {
  log: OverrideChangeLogEntry;
}

const ChangeCard: React.FC<CardProps> = ({ log }) => {
  const {
    type, changes, isDeleted,
  } = log;

  return (
    <Wrapper>
      <Element>
        <Name>{TYPE}</Name>
        <WhiteSpaceValue>{formatType(type)}</WhiteSpaceValue>
      </Element>
      <Element>
        <Name>{isDeleted ? DELETED_UPDATES : UPDATES}</Name>
        <WhiteSpaceValue>{formatChanges(changes)}</WhiteSpaceValue>
      </Element>
    </Wrapper>
  );
};

export default ChangeCard;
