import React from 'react';
import Modal from 'react-modal';
import {
  SectionWrapper,
  ButtonsWrapper,
} from '#/shared/modalComponents';
import Button from '../../shared/Button';
import modalStyles from '../../shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';
import styled from 'styled-components';
import palette from '#/theme/palettes/main';

const CANCEL = 'Cancel';
const SUBMIT = 'Submit';

interface EditEventConfirmationModalProps {
  title: string,
  message?: string,
  sources?: string,
  closeModal: () => void;
  handleSubmit: () => void;
}

const EditEventConfirmationModal: React.FC<EditEventConfirmationModalProps> = ({
  title, message, sources, closeModal, handleSubmit,
}) => {
  useSetupModal(closeModal);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <Content>
        <Title>{title}</Title>
        {message && <Message>{message}</Message>}
        {sources && (<Source>{sources}</Source>)}
      </Content>
      <SectionWrapper>
        <ButtonsWrapper>
          <Button onClick={handleSubmit}>{SUBMIT}</Button>
          <Button onClick={closeModal}>{CANCEL}</Button>
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

const Title = styled.div`
  font-size: 21px;
  line-height: 1.2;
`;

const Message = styled.div`
  font-size: 14px;
  color: ${palette.gray.base};
  margin-top: 0.75rem;
`;

const Source = styled.div`
  padding: 6px 20px 6px 20px;
  font-size: 15px;
  margin-top: 1.75rem;
`;

const Content = styled.div.attrs(({ className }) => ({
  className: `${className} modalContent` as string,
}))`
  padding: 0.9rem 0;
  text-align: center;
  max-height: 50vh;
  white-space: pre-line;
`;

export { EditEventConfirmationModalProps };
export default EditEventConfirmationModal;
