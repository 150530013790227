import React from 'react';
import Modal from 'react-modal';
import ModalContents from '../../sharedComponents/ModalContents';
import useLinkModal from './useLinkModal';
import modalStyles from '#/shared/modalStyles';

interface LinkButtonProps {
  cancelButtonText: string;
  closeModal: () => void;
  handleClickLink: () => void;
  linkButtonText: string;
  linkError: string;
  linkLoading: boolean;
  linkModalIsOpen: boolean;
  linkModalText: string;
}

const SourceLinkModal: React.FC<LinkButtonProps> = ({
  cancelButtonText,
  closeModal,
  handleClickLink,
  linkButtonText,
  linkError,
  linkLoading,
  linkModalIsOpen,
  linkModalText,
}) => {
  return linkModalIsOpen ? (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <ModalContents
        cancelButtonText={cancelButtonText}
        closeModal={closeModal}
        error={linkError}
        handleSubmit={handleClickLink}
        loading={linkLoading}
        modalText={linkModalText}
        submitButtonText={linkButtonText}
      />
    </Modal>
  ) : null;
};

export default SourceLinkModal;
export { useLinkModal };
