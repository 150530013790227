import React from 'react';
import styled from 'styled-components';
import { FormattedEventError } from '#/types/Errors';

interface ErrorCountsProps {
  errors: FormattedEventError[];
}

const measureTemplate = (measure: 'error' | 'event', count: number): string => `${count.toLocaleString()} ${measure}${count === 1 ? '' : 's'}`;

const ErrorCounts: React.FC<ErrorCountsProps> = ({ errors }) => {
  const numErrors = errors.length;
  const numEvents = Array.from(new Set(errors.map((e) => e.autobrokerEventId))).length;

  return (
    <Container>
      <div>{`${measureTemplate('error', numErrors)} in ${measureTemplate('event', numEvents)}`}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  justify-content: flex-end;
  letter-spacing: 0;
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.secondary};
`;

export default ErrorCounts;
