import React from 'react';
import {
  Wrapper, Element, Name, Value,
} from '#/shared/Card';
import { EventConfigTableData } from '../../utils/formatEventInfo';
import { GreenEllipse, RedEllipse, YellowEllipse } from '#/shared/Ellipse';

interface CardProps {
  tableData: EventConfigTableData;
}

const START = 'Supervisor Start';
const REMOVE = 'Listings Removed';
const STOP = 'Supervisor Stop';

const StartLabel: React.FC = () => {
  return (
    <Name>
      <GreenEllipse />
      {START}
    </Name>
  );
};

const RemoveLabel: React.FC = () => {
  return (
    <Name>
      <YellowEllipse />
      {REMOVE}
    </Name>
  );
};

const StopLabel: React.FC = () => {
  return (
    <Name>
      <RedEllipse />
      {STOP}
    </Name>
  );
};

const SupervisorDateCard: React.FC<CardProps> = ({ tableData }) => {
  return (
    <Wrapper>
      <Element>
        <StartLabel />
        <Value>
          {tableData.supervisorStartsAt ? `${tableData.supervisorStartsAt} UTC` : 'Not set'}
        </Value>
      </Element>
      <Element>
        <RemoveLabel />
        <Value>
          {(tableData.listingsRemovedAt === 'Not set') ? `${tableData.listingsRemovedAt}` : `${tableData.listingsRemovedAt} UTC`}
        </Value>
      </Element>
      <Element>
        <StopLabel />
        <Value>
          {tableData.supervisorStopsAt ? `${tableData.supervisorStopsAt} UTC` : 'Not set'}
        </Value>
      </Element>
    </Wrapper>
  );
};

export default SupervisorDateCard;
