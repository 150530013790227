import { DateTime } from 'luxon';

const RESET = 'reset';

interface Action {
  type: UpdateEventConfigType | 'reset';
  value: boolean | string | number | DateTime | UpdateEventConfig | string[];
}

enum UpdateEventConfigType {
  IS_ACTIVE = 'isActive',
  LISTINGS_REMOVED_AT = 'listingsRemovedAt',
  ONSALE_STARTS_AT = 'onsaleStartsAt',
  RESET_PRICES = 'resetPrices',
  KEEP_SUPERVISOR_ACTIVE = 'keepSupervisorActive',
  USE_SEATGEEK_TRANSACTIONS = 'useSeatgeekTransactions',
  DEMAND_CHANGEPOINT_CT = 'demandChangepointCt',
  PRICING_MODE = 'pricingMode',
  PRICING_BASIS = 'pricingBasis',
  ONSALE_DEMAND_FRACTION = 'onsaleDemandFraction',
  SELLDOWN_PROJECTION_MODEL = 'selldownProjectionModel',
  ONSALE_PRICING_MODEL = 'onsalePricingModel',
  DYNAMIC_PRICING_MODEL = 'dynamicPricingModel',
  GROUPS = 'groups',
  NOTES = 'notes',
}

interface UpdateEventConfig {
  [UpdateEventConfigType.IS_ACTIVE]: boolean;
  [UpdateEventConfigType.LISTINGS_REMOVED_AT]: DateTime;
  [UpdateEventConfigType.ONSALE_STARTS_AT]: DateTime;
  [UpdateEventConfigType.PRICING_MODE]: string;
  [UpdateEventConfigType.RESET_PRICES]: boolean;
  [UpdateEventConfigType.KEEP_SUPERVISOR_ACTIVE]: boolean;
  [UpdateEventConfigType.USE_SEATGEEK_TRANSACTIONS]: boolean;
  [UpdateEventConfigType.DEMAND_CHANGEPOINT_CT]: number;
  [UpdateEventConfigType.PRICING_BASIS]: string;
  [UpdateEventConfigType.ONSALE_DEMAND_FRACTION]: number;
  [UpdateEventConfigType.SELLDOWN_PROJECTION_MODEL]: string;
  [UpdateEventConfigType.ONSALE_PRICING_MODEL]: string;
  [UpdateEventConfigType.DYNAMIC_PRICING_MODEL]: string;
  [UpdateEventConfigType.GROUPS]?: string[];
  [UpdateEventConfigType.NOTES]?: string;
}

export { UpdateEventConfig, UpdateEventConfigType, RESET };
export default Action;
