import styled from 'styled-components';

const ScrollBody = styled.div`
  position: absolute;
  width: 100%;
  top: 218px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0 6.25rem;
`;

export default ScrollBody;
