import React from 'react';
import { NarrowWrapper, Element, Name } from '#/shared/Card';
import ButtonSmall from '#/shared/Button';

const GROUPS = 'Event Groups';
const CREATE = 'Create';
const UPDATE = 'Update';

interface GroupCardProps {
  openCreateModal: () => void;
  openUpdateModal: () => void;
}

const GroupCard: React.FC<GroupCardProps> = ({
  openCreateModal,
  openUpdateModal,
}) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{GROUPS}</Name>
      </Element>
      <Element>
        <ButtonSmall onClick={openCreateModal}>{CREATE}</ButtonSmall>
      </Element>
      <Element>
        <ButtonSmall onClick={openUpdateModal}>{UPDATE}</ButtonSmall>
      </Element>
    </NarrowWrapper>
  );
};

export default GroupCard;
