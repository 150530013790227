import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { formatCurrency } from '#/shared/formatNumberForDisplay';
import Event, { ValueField } from '#/types/Event';

interface CardProps {
  event: Event;
  valueField: ValueField;
}

const INGESTED = 'Ingested ';
const AVG_INGESTED = 'Avg. ';
const NA = 'N/A';

const IngestedCostCard: React.FC<CardProps> = ({ event, valueField }) => {
  const { ingestedCost, ingestedFaceValue, ingestedTicketCt } = event.summary;

  const value = valueField === ValueField.COST ? ingestedCost : ingestedFaceValue;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{INGESTED + valueField}</Name>
        <Value>{formatCurrency(value)}</Value>
      </Element>
      <Element>
        <Name>{AVG_INGESTED + valueField}</Name>
        <Value>
          {
            ingestedTicketCt === 0
              ? NA
              : formatCurrency(value / ingestedTicketCt)
          }
        </Value>
      </Element>
    </NarrowWrapper>
  );
};

export default IngestedCostCard;
