import { useCallback, useState, FormEvent } from 'react';
import postPriceExploration from '#/api/postPriceExploration';
import formatApiError from '#/api/utils/formatApiError';
import deletePriceExploration from '#/api/deletePriceExploration';
import Event, { type PriceExploration } from '#/types/Event';

type Hook = (
  activeEvent: Event,
  priceExplorationConfig: PriceExploration,
  resetPriceExplorationConfig: () => void,
  retrieveActiveEvent: (id: number) => void
) => {
  clearError: () => void;
  error: string;
  loading: boolean;
  handleDelete: () => void;
  handleReset: () => void;
  handleSubmit: (event: FormEvent) => void;
  success: boolean;
};

const usePriceExploration: Hook = (
  activeEvent,
  priceExplorationConfig,
  resetPriceExplorationConfig,
  retrieveActiveEvent,
) => {
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const clearError = useCallback(() => setError(''), []);

  const handleReset = useCallback(() => {
    setError('');
    setSuccess(false);
    resetPriceExplorationConfig();
  }, [resetPriceExplorationConfig]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.stopPropagation();
      event.preventDefault();
      setError('');
      setSuccess(false);
      setLoading(true);
      try {
        await postPriceExploration(priceExplorationConfig, activeEvent.id);
        setSuccess(true);
        retrieveActiveEvent(activeEvent.id);
        setTimeout(handleReset, 1000);
      } catch (err) {
        setError(formatApiError(err));
      }
      setLoading(false);
    },
    [activeEvent.id, handleReset, priceExplorationConfig, retrieveActiveEvent],
  );

  const handleDelete = useCallback(async () => {
    setError('');
    setLoading(true);
    setSuccess(false);
    try {
      await deletePriceExploration(
        priceExplorationConfig.autobrokerEventId,
        priceExplorationConfig.notes,
      );
      setSuccess(true);
      retrieveActiveEvent(activeEvent.id);
      setTimeout(handleReset, 1000);
    } catch (err) {
      setError(formatApiError(err));
    }
    setLoading(false);
  }, [activeEvent.id, handleReset, priceExplorationConfig, retrieveActiveEvent]);

  return {
    clearError,
    error,
    loading,
    handleDelete,
    handleReset,
    handleSubmit,
    success,
  };
};

export default usePriceExploration;
