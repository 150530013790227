import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

interface TabProps {
  activeTab: string;
  name: string;
  setActiveTab?: (name: string) => void;
  header?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Tab: React.FC<TabProps> = ({
  activeTab, name, setActiveTab, header, onClick,
}) => {
  const active = useMemo(() => activeTab === name, [activeTab, name]);
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (setActiveTab)
      setActiveTab(name);

    if (onClick)
      onClick(e);
  }, [name, setActiveTab, onClick]);

  return (
    <StyledButton
      active={active}
      header={header}
      onClick={handleClick}
      type='button'
      value={name.replace('-', ' ')}
    >
      {name.replace('-', ' ')}
    </StyledButton>
  );
};

interface StyleProps {
  active: boolean;
  header: boolean;
  type: string;
}

const StyledButton = styled.button<StyleProps>`
  ${({ theme }: { theme: Theme }): string => theme.text2};

  margin: 0 1.75rem 0 0;
  padding: 0.875rem 2px;
  height: 100%;
  background-color: ${({
    header,
    theme,
  }: {
    header: boolean;
    theme: Theme;
  }): string => {
    if (header)
      return `${theme.color.background.info}`;

    return `${theme.color.background.primary}`;
  }};
  ${({ active, theme }: { active: boolean; theme: Theme }): string => {
    if (active) {
      return `
        color: ${theme.color.text.primary};
        border-bottom: 2px solid ${theme.color.border.selected};
        `;
    }
    return `
    color: ${theme.color.text.secondary};
    cursor: pointer;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: ${theme.color.background.highlight}
    }
  `;
  }};
`;

export default Tab;
