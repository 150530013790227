import React from 'react';
import { ErrorStatusType, EventError } from '#/types/Errors';
import {
  BlueTag, GreenTag, PurpleTag, YellowTag,
} from './components';

const MOPS_FORM_LINK = 'https://airtable.com/appv3ePfEXWdsL15b/shrbV1jwD0Yz5ItlI';
const getErrorStatusTag = (error: EventError): JSX.Element => {
  const status = error.errorStatus?.status;

  switch (status) {
    case ErrorStatusType.INPROGRESS:
      return (
        <YellowTag>{status}</YellowTag>
      );
    case ErrorStatusType.WONTFIX:
      return (
        <BlueTag>{status}</BlueTag>
      );
    case ErrorStatusType.RESOLVED:
      return (
        <GreenTag>{status}</GreenTag>
      );
    default:
      return (
        <PurpleTag>{ErrorStatusType.TODO}</PurpleTag>
      );
  }
};

const formatEventErrorType = (error: EventError): string => {
  return error.errorType.split('_').map((errorType) => errorType.charAt(0).toUpperCase() + errorType.slice(1)).join(' ');
};

export {
  MOPS_FORM_LINK, getErrorStatusTag, formatEventErrorType,
};
