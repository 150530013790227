import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { PRICING_BASIS_OPTIONS } from '#/types/Event';
import Listing from '#/types/Listing';
import { formatCurrency } from '#/shared/formatNumberForDisplay';

interface CardProps {
  listing: Listing;
}

const InfoCard: React.FC<CardProps> = ({ listing }) => {
  const { price, quantity } = listing;
  const {
    amount, basis, log_price_ratio, expected_value, actual,
  } = price;

  const priceLabel = PRICING_BASIS_OPTIONS.find((option) => option.value === basis)?.label;

  const strikeThroughPrice = (
    price.is_constrained && Math.abs(price.actual - price.amount) > 0.01
      ? formatCurrency(amount)
      : undefined
  );
  const renderPrice = strikeThroughPrice ? formatCurrency(actual) : formatCurrency(amount);

  return (
    <NarrowWrapper>
      {expected_value && (
        <Element>
          <Name>{`Expected Value: ${formatCurrency(expected_value)}`}</Name>
        </Element>
      )}
      {log_price_ratio && (
        <Element>
          <Name>{`Log Price Ratio: ${log_price_ratio.toFixed(2)}`}</Name>
        </Element>
      )}
      <Element>
        <Name>{priceLabel}</Name>
        {strikeThroughPrice && (
          <Value style={{ textDecoration: 'line-through' }}>
            {`${quantity}x • ${strikeThroughPrice}`}
          </Value>
        )}
        <Value>{`${quantity}x • ${renderPrice}`}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default InfoCard;
