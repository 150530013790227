import { Boundary, FormattedBoundary } from '#/types/Preview';
import { GraphPlot } from '#/types/Snapshot';
/* eslint-disable camelcase */

interface PlotDistribution {
  medium: GraphPlot;
  narrow: GraphPlot;
  probability: number;
  targetLine: GraphPlot;
  wide: GraphPlot;
}

const formatPlotPoint = (boundary: Boundary): PlotDistribution => {
  if (!boundary)
    return null;
  const { logExpectedValue, logPriceRatio, probability } = boundary;
  const {
    pct5,
    pct10,
    pct25,
    pct75,
    pct90,
    pct95,
    target,
  } = logPriceRatio;

  const wide = { x: logExpectedValue, y: pct95, y0: pct5 };
  const medium = { x: logExpectedValue, y: pct90, y0: pct10 };
  const narrow = { x: logExpectedValue, y: pct75, y0: pct25 };
  const targetLine = { x: logExpectedValue, y: target, y0: target };

  return {
    medium, narrow, probability, targetLine, wide,
  };
};

const formatPreviewBoundary = (
  boundary: Boundary,
  nextBoundary: Boundary,
  maxWeight: number,
): FormattedBoundary | null => {
  const OPACITY_FACTOR = 0.5;

  const boundaryStart = formatPlotPoint(boundary);

  if (!boundaryStart)
    return null;

  const boundaryEnd = formatPlotPoint(nextBoundary) || boundaryStart;

  const probability = (OPACITY_FACTOR * (boundaryEnd.probability - boundaryStart.probability))
    / maxWeight;

  const wide = {
    graphPlot: [boundaryStart.wide, boundaryEnd.wide],
    probability,
  };
  const medium = {
    graphPlot: [boundaryStart.medium, boundaryEnd.medium],
    probability,
  };
  const narrow = {
    graphPlot: [boundaryStart.narrow, boundaryEnd.narrow],
    probability,
  };
  const target = {
    graphPlot: [boundaryStart.targetLine, boundaryEnd.targetLine],
    probability,
  };

  return {
    medium, narrow, target, wide,
  };
};

export default formatPreviewBoundary;
