import React, {
  useEffect, useState, useCallback,
} from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { ConfigType, IntegrationConfig } from '../types';
import useIntegration from './useIntegration';
import checkListingsConstraints from '../utils/checkListingsConstraints';
import { SINK_CONFIG_HEADERS } from '../../constants';
import {
  LISTING_MIN,
  P_VISIBLE_MAX,
  P_VISIBLE_MIN,
  CHURN_VISIBILITY_RATE_MIN,
  CHURN_VISIBILITY_RATE_MAX,
  PRICE_INCREMENT_MAX,
} from '../constants';
import InputForm, { InputType } from '../Forms/InputForm';
import CheckboxForm from '../Forms/CheckboxForm';
import ModalButtons from '../Buttons';
import SelectSink from './SelectSink';
import {
  SectionWrapper,
  Title,
  Error,
  Content,
} from '#/shared/modalComponents';
import createHandleSave from './utils/createHandleSave';
import createHandleCancel from './utils/createHandleCancel';
import useFetchSinks from '#/pages/useFetchSinks';
import SelectMarketplaces from '../SelectMarketplaces';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';
import Sink from '#/types/Sink';

const NOTES = 'Notes';

interface IntegrationModalProps {
  closeModal: () => void;
  integration: IntegrationConfig;
  multistep?: boolean;
  retry?: () => void;
}

const IntegrationModal: React.FC<IntegrationModalProps> = ({
  closeModal,
  integration,
  multistep,
  retry,
}) => {
  const [error, setError] = useState('');
  const [listingCountError, setListingCountError] = useState('');
  const [currentSink, setCurrentSink] = useState<Sink>(undefined);

  useEffect(() => Modal.setAppElement('body'), []);

  const {
    resetIntegrationConfig,
    integrationConfig,
    updateIntegrationConfig,
  } = useIntegration(integration);

  const {
    integrationType,
    listingMaxCount,
    listingMinCount,
    pVisible,
    churnVisibilityRate,
    priceIncrement,
    qaMode,
    externalEventId,
  } = integrationConfig;

  const navigate = useNavigate();
  const goToEventPage = useCallback(async () => {
    if (retry)
      await retry();
    navigate(`/view/${integrationConfig.autobrokerEventId}`);
  }, [navigate, integrationConfig.autobrokerEventId, retry]);

  const handleSave = createHandleSave(
    closeModal,
    goToEventPage,
    integrationConfig,
    multistep,
    resetIntegrationConfig,
    setError,
    retry,
  );

  const handleCancel = createHandleCancel(
    closeModal,
    goToEventPage,
    multistep,
    retry,
  );

  const HEADLINE = 'New Integration';

  const {
    sinkLabels,
    sinks,
    sinksError,
  } = useFetchSinks();

  useEffect(() => {
    const selectedSink = sinks?.find((sink) => sink.name === integrationType);

    setCurrentSink(selectedSink);
  }, [integrationType, sinks]);

  useEffect(() => {
    setListingCountError(
      checkListingsConstraints(listingMinCount, listingMaxCount),
    );
  }, [listingMaxCount, listingMinCount]);

  useSetupModal(closeModal);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <Title className='integration_modal'>{HEADLINE}</Title>
      <SectionWrapper>
        <Content>
          <SelectSink
            handleInputChange={updateIntegrationConfig}
            sinkLabels={sinkLabels}
            sinksError={sinksError}
          />
          <InputForm
            handleInputChange={updateIntegrationConfig}
            initialVal={externalEventId}
            inputType={InputType.text}
            label={SINK_CONFIG_HEADERS.sinkExternalEventId}
            placeholder='optional'
            type={ConfigType.EXTERNAL_EVENT_ID}
          />
          <InputForm
            handleInputChange={updateIntegrationConfig}
            initialVal={listingMaxCount}
            inputType={InputType.number}
            label={SINK_CONFIG_HEADERS.listingMaxCount}
            min={LISTING_MIN}
            type={ConfigType.LISTING_MAX_COUNT}
          />
          <InputForm
            handleInputChange={updateIntegrationConfig}
            initialVal={listingMinCount}
            inputType={InputType.number}
            label={SINK_CONFIG_HEADERS.listingMinCount}
            min={LISTING_MIN}
            type={ConfigType.LISTING_MIN_COUNT}
          />
          <InputForm
            handleInputChange={updateIntegrationConfig}
            initialVal={pVisible}
            inputType={InputType.number}
            label={SINK_CONFIG_HEADERS.pVisible}
            max={P_VISIBLE_MAX}
            min={P_VISIBLE_MIN}
            type={ConfigType.P_VISIBLE}
          />
          <InputForm
            handleInputChange={updateIntegrationConfig}
            initialVal={churnVisibilityRate}
            inputType={InputType.number}
            label={SINK_CONFIG_HEADERS.churnVisibilityRate}
            max={CHURN_VISIBILITY_RATE_MAX}
            min={CHURN_VISIBILITY_RATE_MIN}
            type={ConfigType.CHURN_VISIBILITY_RATE}
          />
          { currentSink?.priceIncrementMin && (
            <InputForm
              handleInputChange={updateIntegrationConfig}
              initialVal={priceIncrement}
              inputType={InputType.number}
              label={SINK_CONFIG_HEADERS.priceIncrement}
              max={PRICE_INCREMENT_MAX}
              min={currentSink?.priceIncrementMin}
              step={String(currentSink?.priceIncrementMin)}
              type={ConfigType.PRICE_INCREMENT}
            />
          )}
          { currentSink?.marketplaces && (
            <SelectMarketplaces
              error={sinksError}
              initialVal={null}
              label={SINK_CONFIG_HEADERS.marketplaces}
              onChange={updateIntegrationConfig}
              possibleMarketplaces={currentSink?.marketplaces}
              type={ConfigType.MARKETPLACES}
            />
          )}
          <CheckboxForm
            handleInputChange={updateIntegrationConfig}
            label={SINK_CONFIG_HEADERS.qaMode}
            type={ConfigType.QA_MODE}
            value={qaMode}
          />
          <InputForm
            handleInputChange={updateIntegrationConfig}
            inputType={InputType.text}
            label={NOTES}
            type={ConfigType.NOTES}
          />
        </Content>
        <Error className='listingCountError'>{listingCountError}</Error>
        <Error>{error}</Error>
        <ModalButtons
          disabled={!!listingCountError}
          onCancel={handleCancel}
          onSave={handleSave}
        />
      </SectionWrapper>
    </Modal>
  );
};

export default IntegrationModal;
