import React, {
  useMemo, useRef, useState, useCallback, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import { debounce } from '@graphique/graphique';
import { useNavigate } from 'react-router-dom';
import ReportingContext from '#/pages/clientReporting/ReportingContext';
import { SectionTitleContainer, SectionTitle } from '#/shared/clientReporting/typography';
import Table from './Table';
import { createEventTransactionSummary, filterEventsBySearch } from './utils';
import { createQueryParams } from '../../utils';
import { EXTRA_NARROW_BREAKPOINT } from '#/shared/clientReporting/utils';
import useFetchReportingRollup from '#/pages/useFetchReportingRollup';
import { EventTxSummary } from '#/pages/clientReporting/DashboardPage/types';

const TITLE = 'Event Detail';
const SEARCH_PLACEHOLDER = 'Find an event';

const EventSalesTable: React.FC = () => {
  const {
    stakeholder,
    setHasPendingData,
    hasPendingData,
    isNarrow,
    queryParams,
    setReportingSelection,
  } = useContext(ReportingContext);

  const {
    rollup,
    isLoading,
    hasError,
    fetchRollup,
  } = useFetchReportingRollup({
    ...queryParams,
    stakeholder: stakeholder?.value,
    group: ['event'],
    eventId: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    setHasPendingData(isLoading);
  }, [isLoading, setHasPendingData]);

  const eventTransactionSummary = useMemo(() => (
    rollup ? createEventTransactionSummary(rollup) : undefined
  ), [rollup]);

  const queriedEventId = queryParams?.eventId;

  useEffect(() => {
    if (eventTransactionSummary) {
      const queriedEvent = eventTransactionSummary
        .find((e) => e.seatgeekEventId === Number(queriedEventId));

      setReportingSelection((prev) => ({
        ...prev,
        selectedEvent: queriedEvent,
      }));
    }
  }, [eventTransactionSummary, queriedEventId, setReportingSelection]);

  const [search, setSearch] = useState('');
  const filteredEvents = useMemo(() => {
    const filtered = filterEventsBySearch(eventTransactionSummary, search);

    return filtered.map((d) => ({
      ...d,
      isSelected: d.seatgeekEventId === Number(queryParams?.eventId),
    }));
  }, [eventTransactionSummary, search, queryParams]);

  const searchRef = useRef<HTMLInputElement>(null);
  const handleSearchChange = debounce(300, () => {
    const searchString = searchRef?.current?.value?.trim().toLowerCase();

    setSearch(searchString);
  });

  const handleRowClick = useCallback((row: EventTxSummary) => {
    document.getElementById('page-container').scrollTo({ top: 0, behavior: 'smooth' });

    if (row.seatgeekEventId === Number(queryParams?.eventId)) {
      setReportingSelection((prev) => ({
        ...prev,
        selectedEvent: undefined,
      }));
      navigate({
        search: createQueryParams(
          queryParams,
          { eventId: undefined },
        ),
      });
    } else {
      setReportingSelection((prev) => ({
        ...prev,
        selectedEvent: row,
      }));
      navigate({
        search: createQueryParams(
          queryParams,
          { eventId: [String(row.seatgeekEventId)] },
        ),
      });
    }
  }, [navigate, queryParams, setReportingSelection]);

  const isClickable = useMemo(() => (
    filteredEvents?.length > 0 && !hasPendingData
  ), [filteredEvents, hasPendingData]);

  return (
    <Container>
      <SectionTitleContainer>
        <SectionTitle>{TITLE}</SectionTitle>
      </SectionTitleContainer>
      <>
        {!hasError && (
          <Search
            disabled={isLoading}
            onChange={handleSearchChange}
            placeholder={SEARCH_PLACEHOLDER}
            ref={searchRef}
            spellCheck='false'
            type='search'
          />
        )}
        <Table
          data={filteredEvents}
          hasError={hasError}
          isClickable={isClickable}
          isLoading={isLoading}
          isNarrow={isNarrow}
          onRowClick={handleRowClick}
          retry={fetchRollup}
        />
      </>
    </Container>
  );
};

const Container = styled.div`
  margin: 12px;
  min-height: 180px;
  @media (max-width: ${EXTRA_NARROW_BREAKPOINT}px) {
    margin: 1rem 0 0 0;
  };
`;

const Search = styled.input`
  font-family: Roobert;
  height: 40px;
  width: 290px;
  border: 1px solid;
  border-color: ${({ theme }: { theme: Theme }): string => theme.palette.silver.dark};
  border-radius: .3rem;
  padding: 4px 6px 4px 12px;
  margin: 1rem 0 1.2rem 0;
  font-size: 14px;
  letter-spacing: 0;
  ::placeholder {
    color: ${({ disabled }): string => (
    disabled ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0.4)'
  )};
  };
`;

export default EventSalesTable;
