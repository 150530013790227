import { Rollup } from '#/types/ReportingRollup';
import { MarketplaceSummary, Marketplace, MarketplaceMixMeasure } from '../../types';
import marketplaces from './constants';

const getMarketplaceDisplayName = (m: Marketplace): string => (
  marketplaces[m]?.displayName ?? m ?? '[unknown]'
);

const getMarketplaceColor = (m: Marketplace): string => marketplaces[m]?.color ?? '#bbb';

const createMarketplaceSummary = (
  rollup: Rollup[],
  measure: MarketplaceMixMeasure,
): MarketplaceSummary[] => {
  if (!rollup || rollup?.length === 0)
    return [];

  const marketplaceSummary = rollup.map(({ group, aggregate }) => ({
    marketplace: group.marketplace,
    marketplaceDisplay: getMarketplaceDisplayName(group.marketplace as Marketplace),
    marketplaceColor: getMarketplaceColor(group.marketplace as Marketplace),
    ticketsSold: aggregate.saleTicketCt,
    totalSales: aggregate.saleCt,
    revenue: aggregate.saleRevenue,
    cost: aggregate.ingestedCost,
    managedInventory: aggregate.ingestedTicketCt,
  }));

  return marketplaceSummary
    .sort((a, b) => (b[measure] - a[measure]));
};

const formatX = ({ value }: { value: number }): string => value.toLocaleString(undefined, { style: 'percent' });

export {
  createMarketplaceSummary,
  formatX,
};
