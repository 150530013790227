import {
  Graphlabels, SelldownGraphData, ListingsGraphData, LongData, GraphPlot,
} from '#/types/Snapshot';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const elongate = (data: any): LongData[] => {
  const dataKeys = Object.keys(data);

  const longData = dataKeys.flatMap((t: keyof SelldownGraphData | keyof ListingsGraphData) => {
    return (data[t] as GraphPlot[]).map((d: GraphPlot) => {
      return {
        group: Graphlabels[t],
        ...d,
      };
    });
  });

  return longData;
};

const HRS_IN_DAY = 24;

const formatX = (d: number, givenXUnits: 'hr' | 'days' = 'hr', maximumFractionDigits = 1): string => {
  let value;

  if (d > HRS_IN_DAY * 2 && givenXUnits === 'hr')
    value = `${(d / HRS_IN_DAY).toLocaleString(undefined, { maximumFractionDigits })} days`;
  else
    value = `${d.toLocaleString(undefined, { maximumFractionDigits })} ${givenXUnits}`;

  return value;
};

const calcPrice = (lev: number, lpr: number): number => Math.E ** (lev + lpr);
const exponentiate = (d: number): number => Math.E ** d;

const invSymLog = (d: number): number => (d < 0 ? -1 : 1) * (Math.E ** Math.abs(d) - 1);
const symLog = (d: number): number => (d < 0 ? -1 : 1) * Math.log(Math.abs(d) + 1);

const formatXTicks = ({ value }: { value: number }): string => {
  return formatX(invSymLog(value), 'hr', 0);
};

const formatTooltipX = (d: number, givenXUnits: 'hr' | 'days' = 'hr', isLog = true): string => {
  const convertedValue = isLog ? invSymLog(d) : d;

  return `Time to event: ${formatX(convertedValue, givenXUnits)}`;
};

const formatYTicks = ({ value }: {value: number}): string => (
  value?.toLocaleString(undefined, { maximumFractionDigits: 4 })
);

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const isDefined = (v: any): boolean => typeof v !== 'undefined' && v !== null;

const TIME_TO_EVENT_HRS_CUTOFF = -8;

export {
  elongate,
  formatXTicks,
  formatTooltipX,
  formatYTicks,
  isDefined,
  calcPrice,
  exponentiate,
  invSymLog,
  symLog,
  TIME_TO_EVENT_HRS_CUTOFF,
};
