import React, { useCallback } from 'react';
import styled from 'styled-components';

import { User } from '#/types/User';
import { SmallTag } from '#/shared/Card';
import Ingestion, { IngestionState } from '#/types/Ingestion';
import palette from '#/theme/palettes/main';
import { ErrorType } from '#/types/Errors';
import { ErrorStatusProps } from '#/shared/ErrorStatusModal';
import {
  Header, Title, Right, Button, EditImg, CancelledImg, CopyImg, NotesImg, RefreshImg,
} from '#/shared/multiSelectDetail';

const SOLD = 'sold';
const PRICED = 'priced';
const ERROR = 'error';
const RESERVED = 'reserved';

interface IngestionDetailHeaderProps {
  user: User;
  ingestion: Ingestion;
  setErrorStatusModal: (props: ErrorStatusProps) => void;
  openEditModal: () => void;
  openRelistModal: () => void;
  closeDetail: () => void;
  closeable: boolean;
}

const IngestionDetailHeader: React.FC<IngestionDetailHeaderProps> = ({
  user,
  ingestion,
  setErrorStatusModal,
  openEditModal,
  openRelistModal,
  closeDetail,
  closeable,
}) => {
  const {
    id, is_sold, is_priced, is_reserved, input_listing_errors, state, created_at,
  } = ingestion;

  const openErrorStatusModal = useCallback(() => {
    if (ingestion !== null) {
      setErrorStatusModal({
        title: 'Annotate Ingestion Listing',
        description: '',
        isOpen: true,
        existing: ingestion.error_status,
        reference: {
          id: ingestion.id,
          type: ErrorType.INGESTION_LISTINGS,
        },
      });
    }
  }, [ingestion, setErrorStatusModal]);

  const copyLink = useCallback(() => {
    if (id) {
      navigator.clipboard.writeText(
        `${window.location.origin}/ingestions?id=${id}`,
      );
    }
  }, [id]);

  const disabled = user.isReadOnly;

  return (
    <Header>
      <Title>
        {'Ingestion Listing ' + id}
        <Button onClick={copyLink} title="copy link">
          <CopyImg />
        </Button>
      </Title>
      {is_reserved && (
        <Right>
          <GoldTag title={RESERVED}>{RESERVED}</GoldTag>
        </Right>
      )}
      {is_sold && (
        <Right>
          <BlueTag title={SOLD}>{SOLD}</BlueTag>
        </Right>
      )}
      {is_priced && (
        <Right>
          <PurpleTag title={PRICED}>{PRICED}</PurpleTag>
        </Right>
      )}
      {input_listing_errors.length > 0 && (
        <Right>
          <RedTag title={ERROR}>{ERROR}</RedTag>
        </Right>
      )}
      <Right>
        <IngestionStateTag state={state} />
      </Right>
      <Right>
        <GrayTag title={`ingested ${created_at.toRelative()}`}>
          {created_at.toRelative()}
        </GrayTag>
      </Right>
      {!disabled && state === IngestionState.DELETED && (
        <Right>
          <Button onClick={openRelistModal} title="relist deleted listing">
            <RefreshImg />
          </Button>
        </Right>
      )}
      {!disabled && (
        <Right>
          <Button onClick={openErrorStatusModal} title="add a listing note">
            <NotesImg />
          </Button>
        </Right>
      )}
      {!disabled && (
        <Right>
          <Button onClick={openEditModal} title="edit listing">
            <EditImg />
          </Button>
        </Right>
      )}
      {closeable && (
        <Right>
          <Button onClick={closeDetail} title="close listing detail">
            <CancelledImg />
          </Button>
        </Right>
      )}
    </Header>
  );
};

interface IngestionStateTagProps {
  state: IngestionState;
}

const IngestionStateTag: React.FC<IngestionStateTagProps> = ({
  state,
}) => {
  const {
    FAILED, EVENT_FAILED, UPLOAD_FAILED, DONE, DELETED, DELETION_FAILED,
  } = IngestionState;
  const formatted = state.split('_').join(' ');

  if (
    state === FAILED
    || state === EVENT_FAILED
    || state === UPLOAD_FAILED
    || state === DELETION_FAILED
  )
    return <RedTag>{formatted}</RedTag>;
  if (state === DONE)
    return <GreenTag>{formatted}</GreenTag>;
  if (state === DELETED)
    return <OrangeTag>{formatted}</OrangeTag>;
  return <YellowTag>{formatted}</YellowTag>;
};

const RedTag = styled(SmallTag as any)`
  background-color: ${palette.red.light24};
`;

const YellowTag = styled(SmallTag as any)`
  background-color: ${palette.yellow.light24};
`;

const GreenTag = styled(SmallTag as any)`
  background-color: ${palette.mint.light24};
`;

const GrayTag = styled(SmallTag as any)`
  background-color: ${palette.gray.light24};
`;

const OrangeTag = styled(SmallTag as any)`
  background-color: ${palette.orange.light24};
`;

const PurpleTag = styled(SmallTag as any)`
  background-color: ${palette.purple.light24};
`;

const BlueTag = styled(SmallTag as any)`
  background-color: ${palette.blue.light24};
`;

const GoldTag = styled(SmallTag as any)`
  background-color: ${palette.gold.light56};
`;

export default IngestionDetailHeader;
