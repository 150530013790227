import { stringify } from 'query-string';
import snakecaseKeys from 'snakecase-keys';
import { EventTxSummary, QueryParams } from './types';
import { formatLargeNumber } from '#/shared/formatNumberForDisplay';
import { DEFAULT_FROM_DATE } from '#/shared/clientReporting/utils';
import { User } from '#/types/User';

// converts camelCase to Title Case
const formatColName = (column: string | number, isNarrow = false, asIs = false): string => {
  const colNameParts = column.toString()
    .split(/(?=[A-Z])/)
    .map((c) => `${c[0].toUpperCase()}${c.slice(1)}`);

  const spacedString = colNameParts.join(' ');

  if (asIs)
    return isNarrow ? spacedString.replace(/[Ww]ith/, 'w/') : spacedString;

  return isNarrow && colNameParts.length > 1 ? colNameParts[1] : spacedString;
};

const formatSlugToTitle = (slug: string): string => {
  const slugParts = slug.split('-').map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);

  return slugParts.join(' ').replace(/^Sro /, 'SRO ');
};

const formatAccountingCurrency = (value: number, isNarrow = false): string => {
  const isNegative = value < 0;
  const numericValue = isNarrow
    ? formatLargeNumber(Math.abs(value))
    : Math.abs(value).toLocaleString(undefined, { maximumFractionDigits: 0 });
  const displayValue = `$${numericValue}`;
  const negativeValue = `(${displayValue})`;

  return isNegative ? negativeValue : displayValue;
};

const formatEventLabel = (selectedEvent: EventTxSummary): string => {
  const { event, eventDate } = selectedEvent;

  return `${event} ${eventDate ? '(' + eventDate.toLocaleString() + ')' : ''}`;
};

const asUndefined = (dateString: string): string | undefined => (
  dateString === '' ? undefined : dateString
);

const NON_DATE_PARAMS: (keyof QueryParams)[] = [
  'eventId',
  'fromEventDate',
  'deal',
  'dealTerm',
  'inventorySourceType',
];

const getShouldUseDefaultStartDate = (queryParams: QueryParams): boolean => {
  const usedParams = Object.keys(queryParams) as (keyof QueryParams)[];

  return !usedParams?.some((p) => NON_DATE_PARAMS.includes(p));
};

// use default fromEventDate when there is:
// 1. no fromEventDate provided and
// 2. no other filters provided in the url/query

const DEFAULT_EVENT_FROM_QUERY: QueryParams = {
  fromEventDate: DEFAULT_FROM_DATE.toSQLDate(),
};

const createQueryParams = (
  existingParams: QueryParams,
  newParams: QueryParams = {},
): string => {
  const usesDefaultStartDate = getShouldUseDefaultStartDate(existingParams);
  const usedParams = usesDefaultStartDate
    ? {
      ...newParams,
      ...DEFAULT_EVENT_FROM_QUERY,
    }
    : newParams;

  return (
    stringify(
      snakecaseKeys(({
        ...existingParams,
        ...usedParams,
      })),
    )
  );
};

const CACHED_QUERY_PARAMS_KEY = 'clientReportingQueryParams';

const getCachedQueryParams = (): QueryParams => {
  const cachedParams = localStorage.getItem(CACHED_QUERY_PARAMS_KEY);

  return cachedParams ? JSON.parse(cachedParams) : {};
};

const setCachedQueryParams = (queryParams: QueryParams, user: User): void => {
  if (!user)
    return;

  const subset = {
    perspective: user.isReporting ? undefined : queryParams?.perspective,
  };

  localStorage.setItem(CACHED_QUERY_PARAMS_KEY, JSON.stringify(subset));
};

export {
  formatColName,
  formatSlugToTitle,
  formatAccountingCurrency,
  formatEventLabel,
  asUndefined,
  createQueryParams,
  getShouldUseDefaultStartDate,
  getCachedQueryParams,
  setCachedQueryParams,
  DEFAULT_EVENT_FROM_QUERY,
};
