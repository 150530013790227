import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SinkConfig } from './types';
import IntegrationConfig from '#/types/IntegrationConfig';
import SinkModal from './SinkModal';
import IntegrationModal from './IntegrationModal';
import RebuildEventModal from './RebuildEventModal';
import ConfirmationModal from '#/shared/ConfirmationModal';
import SalesPeriodsModal from './SalesPeriodsModal';
import formatApiError from '#/api/utils/formatApiError';
import deleteEvent from '#/api/deleteEvent';
import { SalesPeriod } from '#/types/Event';

interface ModalFormProps {
  eventId: number;
  sinkModalIsOpen: boolean;
  integrationModalIsOpen: boolean;
  rebuildEventModalIsOpen: boolean;
  deleteEventModalIsOpen: boolean;
  salesPeriodModalIsOpen: boolean;
  closeModal: () => void;
  retry: () => void;
  sink: SinkConfig;
  newIntegration: IntegrationConfig;
  salesPeriods: SalesPeriod[];
}
const DELETE_MESSAGE = 'Are you sure you want to delete this event? This action cannot be reverted.';
const ModalForms: React.FC<ModalFormProps> = ({
  eventId,
  closeModal,
  integrationModalIsOpen,
  rebuildEventModalIsOpen,
  deleteEventModalIsOpen,
  salesPeriodModalIsOpen,
  newIntegration,
  retry,
  sink,
  sinkModalIsOpen,
  salesPeriods,
}) => {
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const deleteEventSubmit = useCallback(async () => {
    try {
      if (eventId) {
        await deleteEvent(eventId);
        retry();
        closeModal();
        navigate('/search', { replace: true });
        window.location.reload();
      }
    } catch (e) {
      setError(formatApiError(e));
    }
  }, [eventId, closeModal, navigate, retry]);

  return (
    <>
      {sinkModalIsOpen && (
        <SinkModal
          closeModal={closeModal}
          retry={retry}
          sink={sink}
        />
      )}
      {integrationModalIsOpen && (
        <IntegrationModal
          closeModal={closeModal}
          integration={newIntegration}
          retry={retry}
        />
      )}
      {rebuildEventModalIsOpen && (
        <RebuildEventModal
          closeModal={closeModal}
          retry={retry}
        />
      )}
      {deleteEventModalIsOpen && (
        <ConfirmationModal
          closeModal={closeModal}
          error={error}
          handleSubmit={deleteEventSubmit}
          message={DELETE_MESSAGE}
        />
      )}
      {salesPeriodModalIsOpen && (
        <SalesPeriodsModal
          closeModal={closeModal}
          eventId={eventId}
          retry={retry}
          salesPeriods={salesPeriods}
        />
      )}
    </>
  );
};

export default ModalForms;
