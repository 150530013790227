import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Tooltip as GGTooltip, TooltipContent, TooltipContainer } from '@graphique/graphique';
import { formatTooltipX } from '../utils/dataFormatting';
import { FormattedPricingChartDatum } from '../types';

const TOOLTIP_POSITION = 'top';

const Tooltip: React.FC = () => {
  const renderContent = useCallback(
    (value: TooltipContent<FormattedPricingChartDatum>[]): React.ReactNode => {
      return (
        <TooltipContainer>
          <TooltipWrapper>
            {value?.[0] && (
              <XValueWrapper>{value[0].formattedX}</XValueWrapper>
            )}
            {value?.map(({
              group, mark, formattedY,
            }) => (
              <React.Fragment key={String(group)}>
                <ValueWrapper>
                  {mark}
                  <ValueLabel>{String(group)}</ValueLabel>
                  <Value>{formattedY}</Value>
                </ValueWrapper>
              </React.Fragment>
            ))}
          </TooltipWrapper>
        </TooltipContainer>
      );
    }, [],
  );

  return (
    <GGTooltip
      content={renderContent}
      position={TOOLTIP_POSITION}
      xFormat={formatTooltipX}
    />
  );
};

const TooltipWrapper = styled.div`
  font-size: 1.15em;
  margin-top: 3px;
  margin-bottom: 4px;
`;

const XValueWrapper = styled.div`
  color: #555;
  margin-bottom: 3px;
`;

const ValueWrapper = styled.div`
  display: flex;
  padding-top: 3px;
  align-items: center;
  gap: 4px;
`;

const ValueLabel = styled.div``;

const Value = styled.div`
  font-weight: 500;
  font-size: 1.05em;
  text-transform: capitalize;
`;

export default Tooltip;
