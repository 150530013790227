import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import postEventConfigUpdate from '#/api/postEventConfigUpdate';
import Selected from '#images/Selected.svg';
import Unselected from '#images/Unselected.svg';
import palette from '#/theme/palettes/main';
import formatApiError from '#/api/utils/formatApiError';
import Event from '#/types/Event';

interface ButtonProps {
  disabled: boolean;
  activeEvent: Event;
  retry: () => void;
}

const SupervisorToggle: React.FC<ButtonProps> = ({ disabled, activeEvent, retry }) => {
  const [error, setError] = useState<string>('');
  const isActive = activeEvent.config.isActive;
  const handleClick = useCallback(async () => {
    if (disabled)
      return;
    try {
      await postEventConfigUpdate(activeEvent, {
        isActive: !isActive,
      });
      retry();
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [activeEvent, disabled, isActive, retry]);
  const source = useMemo(() => (isActive ? Selected : Unselected), [isActive]);

  const ACTIVATED = 'Activated';

  return (
    <Outer>
      <Error>{error}</Error>
      {ACTIVATED}
      <Wrapper className="toggle" onClick={handleClick} onKeyDown={handleClick}>
        <img alt="toggle" src={source} />
      </Wrapper>
    </Outer>
  );
};

const Error = styled.div`
  display: flex;
  color: ${palette.orange.base};
  width: 20rem;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Outer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
    margin-left: 1.25rem;
  }
`;

export default SupervisorToggle;
