import React from 'react';
import styled from 'styled-components';
import Select, { createFilter, type GroupBase, type OptionsOrGroups } from 'react-select';
import DropdownFormOption from './types';

interface SelectProps {
  isMulti?: boolean;
  label: string;
  options: OptionsOrGroups<DropdownFormOption, GroupBase<DropdownFormOption>>;
  onChange: React.Dispatch<DropdownFormOption[]>;
  placeholder: string;
  value: DropdownFormOption[];
}

interface Config {
  stringify: (option: DropdownFormOption) => string;
}

const filterConfig: Config = {
  stringify: (option) => `${option.value}`,
};

const SelectInput: React.FC<SelectProps> = ({
  label,
  options,
  onChange,
  isMulti,
  placeholder,
  value,
}) => {
  return (
    <FormWrapper>
      <Label htmlFor={label}>{`${label}:`}</Label>
      <Wrapper>
        <Select
          filterOption={createFilter(filterConfig)}
          isMulti={isMulti}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          value={value}
        />
      </Wrapper>
    </FormWrapper>
  );
};

const Wrapper = styled.div`
  width: 12.6rem;
`;

const FormWrapper = styled.form`
  ${({ theme }: { theme: Theme }): string => theme.text3};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.secondary};
  display: flex;
  flex-direction: column;
  margin: 0.125rem 0.25rem;
  justify-content: flex-start;
  align-items: flex-start;
  input {
    height: 1rem;
  }
`;

const Label = styled.label`
  height: 0;
  visibility: hidden;
`;

export default SelectInput;
