import {
  useContext, useMemo, useCallback, useState, useEffect,
} from 'react';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import formatApiError from '#/api/utils/formatApiError';
import getSelldownByExpectedValue from '#/api/getSelldownByExpectedValues';
import { GraphArray } from '#/types/Snapshot';
import { ExpectedValueBinSnapshotResponse } from '#/types/SelldownByExpectedValue';
import { getCombinedEvTiersSelldown } from './utils';

type Hook = (target: GraphArray) => {
  loading?: boolean
  error?: string
  evTiersSelldown?: ExpectedValueBinSnapshotResponse
}

const useFetchEvTiersSelldown: Hook = (target) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const eventId = useMemo(() => activeEvent?.id, [activeEvent]);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [evTiersSelldown, setEvTiersSelldown] = useState<ExpectedValueBinSnapshotResponse>();

  const preview = target?.reverse();

  const fetchEvTiersSelldown = useCallback(async (): Promise<void> => {
    if (!preview)
      return;

    setError('');
    try {
      const {
        formattedData: formattedSnapshots,
        expectedValueBins,
      } = await getSelldownByExpectedValue(eventId);
      const formattedData = getCombinedEvTiersSelldown(preview, formattedSnapshots);

      setEvTiersSelldown({ formattedData, expectedValueBins });
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
    setLoading(false);
  }, [eventId, preview]);

  useEffect(() => {
    fetchEvTiersSelldown();
  }, [fetchEvTiersSelldown]);

  return {
    loading,
    error,
    evTiersSelldown,
  };
};

export default useFetchEvTiersSelldown;
