import React from 'react';
import { NarrowWrapper, Element, Name } from '#/shared/Card';
import { ButtonSmallLink } from '#/shared/Button';

const REPORTS = 'Reports';
const USERS_MANAGEMENT = 'User Management';
const CLIENT_REPORTING = 'Client Reporting';

const ReportCard: React.FC = () => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{REPORTS}</Name>
      </Element>
      <Element>
        <ButtonSmallLink href='/users'>{USERS_MANAGEMENT}</ButtonSmallLink>
      </Element>
      <Element>
        <ButtonSmallLink href='/reporting'>{CLIENT_REPORTING}</ButtonSmallLink>
      </Element>
    </NarrowWrapper>
  );
};

export default ReportCard;
