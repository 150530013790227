import React, { MouseEventHandler, useContext, useCallback } from 'react';
import styled from 'styled-components';
import Event from '#/types/Event';
import Header from './Header';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';

interface EventsProps {
  events: Event[];
  toggleOpen: MouseEventHandler<HTMLButtonElement>;
}

interface TrayEventProps {
  event: Event;
}

const Events: React.FC<EventsProps> = ({ events, toggleOpen }) => (
  <>
    <Header toggleOpen={toggleOpen} />
    <EventsWrapper>
      <Divider />
      {events.map((event) => (
        <TrayEvent event={event} key={event.id} />
      ))}
    </EventsWrapper>
  </>
);

const TrayEvent: React.FC<TrayEventProps> = ({ event }) => {
  const { activeEvent, setActiveEvent } = useContext(ActiveEventContext);
  const isActiveEvent = activeEvent?.id === event.id;

  const handleClick = useCallback(() => {
    setActiveEvent(event.id);
  }, [event, setActiveEvent]);
  const { title, config, venue } = event;
  const eventDatetime = `${config.eventDateString} · ${config.eventTimeString} (${config.eventStartTimeStatus})`;

  return (
    <Button
      isActiveEvent={isActiveEvent}
      onClick={handleClick}
      type='button'
    >
      <Title>{title}</Title>
      <EventDate>{venue}</EventDate>
      <EventDate>
        {eventDatetime}
      </EventDate>
    </Button>
  );
};

interface ButtonProps {
  isActiveEvent?: boolean;
}

const Button = styled.button<ButtonProps>`
  text-align: left;
  width: 100%;
  min-height: 3.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0.25rem 1.25rem;
  background-color: ${({ theme, isActiveEvent }: { theme: Theme } & ButtonProps): string => (
    isActiveEvent ? theme.palette.brand.light24 : theme.color.background.primary
  )};
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  :hover {
    background-color: ${({ theme, isActiveEvent }: { theme: Theme } & ButtonProps): string => (
    isActiveEvent ? theme.palette.brand.light24 : theme.color.background.highlight
  )};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
`;

const EventsWrapper = styled.div`
  margin-top: 6.5rem;
`;

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.text2};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
`;

const EventDate = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.text3};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.tertiary};
`;

export default Events;
