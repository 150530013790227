import PriceDistributionGraphInfo, {
  Distributions,
  Boundary,
  Data,
  FormattedBoundary,
} from '#/types/Preview';
import calcPriceDistributionGraphDomain from './calcPriceDistributionGraphDomain';
import formatPriceDistributionBoundary from './formatPriceDistributionBoundary';
import mapBoundaries from './mapBoundaries';
import calculateMaxWeight from './calculateMaxWeight';
import defaultData from './constants';

const formatBoundaries = (data: Data): FormattedBoundary[] => {
  if (!data)
    return null;
  const { boundaries } = data;
  const maxWeight = calculateMaxWeight(boundaries);
  const ONE = 1;

  return boundaries.map((boundary: Boundary, index: number) => {
    const nextBoundary: Boundary = boundaries[index + ONE];

    return formatPriceDistributionBoundary(boundary, nextBoundary, maxWeight);
  });
};

const formatPriceDistributionData = (
  rawData: Distributions,
): PriceDistributionGraphInfo => {
  if (!rawData)
    return defaultData;
  const { autobroker, competitor, model } = rawData;

  const autobrokerBoundaries = formatBoundaries(autobroker);
  const competitorBoundaries = formatBoundaries(competitor);
  const modelBoundaries = formatBoundaries(model);

  const priceDistributionGraphData = mapBoundaries(
    autobrokerBoundaries,
    competitorBoundaries,
    modelBoundaries,
  );
  const priceDistributionGraphDomain = calcPriceDistributionGraphDomain(
    priceDistributionGraphData,
  );

  return { priceDistributionGraphData, priceDistributionGraphDomain };
};

export default formatPriceDistributionData;
