import { DateTime, FixedOffsetZone } from 'luxon';
import { User } from '#/types/User';
import Errors, {
  ErrorStatusType, EventError, ErrorLevelType, EventErrorType,
} from '#/types/Errors';
import { Overrides } from '#/types/Override';
import { InventorySourceType } from './ReportingRollup';

interface APIEvent {
  id: number;
  seatgeek_event_id: number;
  config: APIEventConfig;
  groups: string[];
  exploration: RawPriceExploration;
  sink_events: RawSinkEvent[];
  source_events?: RawSourceEvent[];
  models: RawModels;
  summary: RawSummary;
  schedule: RawSchedule | null;
  partitions: RawPartition[];
  event_errors: RawEventError[];
  sales_periods: RawSalesPeriod[];
}

interface APIEventConfig {
  autobroker_event_id: string;
  seatgeek_event_id: string;
  title: string;
  listings_removed_at: string;
  map_config_id: string;
  map_config_key: string;
  is_ga: boolean;
  pricing_mode: string;
  reset_prices: boolean;
  keep_supervisor_active: boolean;
  use_seatgeek_transactions: boolean;
  demand_changepoint_ct: number;
  pricing_basis: string;
  onsale_demand_fraction: number;
  selldown_projection_model: string;
  onsale_pricing_model: string;
  dynamic_pricing_model: string;
  model_status: string;
  supervisor_starts_at: string;
  supervisor_stops_at: string;
  onsale_starts_at: string;
  event_starts_at: string;
  event_starts_at_local: string;
  event_start_time_status: string;
  venue: string;
  event_type: string;
  section_subset: SectionSubset;
  notes?: string;
  created_at: string;
  updated_at: string;
}

enum SectionSubsetType {
  NONE = 'none',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

enum PricingBasis {
  ALL_IN_PRICE = 'all_in_price',
  DISPLAY_PRICE = 'display_price',
}

enum TrainingSetType {
  HIGH_DEMAND_EVENTS = 'high_demand_events',
  SINGLE_MAP_CONFIG = 'single_map_config',
  SINGLE_VENUE_EVENT_TYPE = 'single_venue_event_type',
  SINGLE_VENUE = 'single_venue',
  SINGLE_EVENT_TYPE = 'single_event_type',
  MONOLITHIC = 'monolithic',
  MONOLITHIC_WITH_SEATS = 'monolithic_with_seats',
}

enum PriceGranularity {
  LISTING = 'listing',
  INGESTION = 'ingestion',
  ROW = 'row',
  SECTION = 'section',
  PRICE_LEVEL = 'price_level',
  EVENT = 'event',
}

interface SectionSubset {
  type: SectionSubsetType;
  sections: string[];
}

interface RawModels {
  onsale_pricing_model: RawOnsalePricingModel | null;
  selldown_projection_model: RawSelldownProjectionModel | null;
}

interface RawOnsalePricingModel {
  id: number;
  map_config_key: string | null;
  max_quantity: number;
  section_subset_ticket_fraction: number;
  training_set_type: string | null;
  model_version: string | null;
  created_at: string;
}

interface RawSelldownProjectionModel {
  id: number;
  onsale_starts_at: string;
  event_starts_at: string;
  bin_size_seconds: number;
  training_set_type: string | null;
  onsale_demand_fraction: number | null;
  created_at: string;
}

interface RawPriceExploration {
  autobroker_event_id: number;
  frequency: number;
  id: number;
  lower_percentile: number;
  strategy_type: string;
  upper_percentile: number;
  notes?: string;
}

interface RawSinkEvent {
  activated_at: string;
  autobroker_event_id: number;
  id: number;
  listing_max_count: number;
  listing_min_count: number;
  marketplaces: string[];
  p_visible: number;
  qa_mode: boolean;
  reset_visibility: boolean;
  churn_visibility_rate: number;
  price_increment: number;
  sink_id: number;
  sink_name: string;
  sink_external_event_id: string;
  notes?: string;
}

interface RawSourceEvent {
  autobroker_event_id: number;
  created_at: string;
  expired_at?: string | null;
  id: number;
  source_id: number;
  source_name: string;
  source_external_event_id: string;
  candidate_sinks?: string[] | null;
  candidate_price_granularities?: PriceGranularity[] | null;
  updated_at: string;
}

interface RawSummary {
  ingested_ct: number;
  ingested_ticket_ct: number;
  ingested_cost: number;
  ingested_face_value: number;
  listing_ct: number;
  listing_ticket_ct: number;
  constrained_listing_ct: number;
  constrained_listing_ticket_ct: number;
  sale_ct: number;
  sale_ticket_ct: number;
  sale_gtv: number;
  sale_cost: number;
  sale_face_value: number;
  portfolio_value: number;
  portfolio_value_selldown_derivative: number;
  price_type_ids: string[];
  is_unmanifested: boolean[];
  inventory_source_types: InventorySourceType[];
  stakeholders: string[];
  visible: RawSinkSummary[];
}

interface RawSinkSummary {
  sink_name: string;
  listing_ct: number;
  listing_ticket_ct: number;
}

interface RawEventErrorStatus {
  id: number;
  user_id: number;
  user_name: string | null;
  notes: string | null;
  status: ErrorStatusType;
  created_at: string | null;
}

interface RawEventError {
  id: number;
  error_type: EventErrorType;
  error_level: ErrorLevelType;
  error_reason: string;
  error_status: RawEventErrorStatus;
  created_at: string | null;
  updated_at: string | null;
}

interface RawSchedule {
  id: number;
  autobroker_event_id: number;
  is_scheduled: boolean;
  reason: string;
  tickets_sold_since_last_run: number;
  status?: string;
  last_run_at: string;
  estimated_next_run_at: string;
  created_at: string;
  updated_at: string;
}

interface RawPartition {
  autobroker_event_id: number;
  section_subset_type: string;
}

enum SalesPeriodType {
  ONSALE = 'onsale',
  PRESALE = 'presale',
}

interface RawSalesPeriod {
  id: number;
  type: SalesPeriodType;
  starts_at: string;
  ends_at: string;
  demand_prior: number | null;
  access_codes: string[];
  created_at: string;
  updated_at: string;
}

interface EventConfig {
  eventStartsAt: DateTime;
  eventTimeString: string;
  eventDateString: string;
  eventStartTimeStatus: string;
  timezone: FixedOffsetZone;
  isActive?: boolean;
  listingsRemovedAt: DateTime;
  mapConfigId: number;
  mapConfigKey: string;
  onsaleStartsAt: DateTime;
  pricingMode: string;
  resetPrices: boolean;
  keepSupervisorActive: boolean;
  useSeatgeekTransactions: boolean;
  demandChangepointCt: number;
  pricingBasis: PricingBasis;
  onsaleDemandFraction: number;
  selldownProjectionModel: string;
  onsalePricingModel: string;
  dynamicPricingModel: string;
  modelStatus: string;
  seatgeekEventId: number;
  supervisorStartsAt: DateTime;
  supervisorStopsAt: DateTime;
  sectionSubset: SectionSubset;
  groups: string[];
  notes?: string;
  createdAt: DateTime;
  updatedAt: DateTime;
}

enum StrategyType {
  HEURISTIC = 'heuristic',
  CONSTANT = 'constant',
  DECREASING = 'decreasing',
  INCREASING = 'increasing',
  UNIFORM = 'uniform',
  EMPIRICAL = 'empirical',
  BERNOULLI = 'bernoulli',
  NONE = 'none',
}

interface PriceExploration {
  id: number;
  autobrokerEventId: number;
  frequency: number;
  lowerPercentile: number;
  strategyType: StrategyType;
  upperPercentile: number;
  notes?: string;
}

enum PriceExplorationType {
  ID = 'id',
  AUTOBROKER_EVENT_ID = 'autobrokerEventId',
  FREQUENCY = 'frequency',
  LOWER_PERCENTILE = 'lowerPercentile',
  STRATEGY_TYPE = 'strategyType',
  UPPER_PERCENTILE = 'upperPercentile',
}

interface SinkEvent {
  autobrokerEventId: number;
  id: number;
  sinkId: number;
  sinkName: string;
  sinkExternalEventId: string;
  pVisible: number;
  listingMinCount: number;
  listingMaxCount: number;
  marketplaces: string[];
  qaMode: boolean;
  resetVisibility: boolean;
  churnVisibilityRate: number;
  priceIncrement: number;
  activatedAt?: DateTime;
  notes?: string;
}

interface SourceEvent {
  id: number;
  sourceId: number;
  sourceName: string;
  sourceExternalEventId: string;
  candidateSinks?: string[] | null;
  candidatePriceGranularities?: PriceGranularity[] | null;
  createdAt: DateTime | string;
  updatedAt: DateTime | string;
  expiredAt?: DateTime | string | null;
}

interface Summary {
  ingestedCt: number;
  ingestedTicketCt: number;
  ingestedCost: number;
  ingestedFaceValue: number;
  listingCt: number;
  listingTicketCt: number;
  constrainedListingCt: number;
  constrainedListingTicketCt: number;
  saleCt: number;
  saleTicketCt: number;
  saleGtv: number;
  saleCost: number;
  saleFaceValue: number;
  portfolioValue: number;
  portfolioValueSelldownDerivative: number;
  priceTypeIds: string[];
  isUnmanifested: boolean[];
  inventorySourceTypes: InventorySourceType[];
  visible: SinkSummary[];
}

interface SinkSummary {
  sinkName: string;
  listingCt: number;
  listingTicketCt: number;
}

interface Schedule {
  id: number;
  autobrokerEventId: number;
  isScheduled: boolean;
  reason: string;
  ticketsSoldSinceLastRun: number;
  status?: string;
  lastRunAt: DateTime;
  estimatedNextRunAt: DateTime;
  createdAt: DateTime;
  updatedAt: DateTime;
}

interface Partition {
  autobrokerEventId: number;
  sectionSubsetType: SectionSubsetType;
}

interface SelldownProjections {
  now?: number;
  tomorrow?: number;
  nextWeek?: number;
}

interface OnsalePricingModel {
  id: number;
  mapConfigKey: string | null;
  maxQuantity: number;
  sectionSubsetTicketFraction: number;
  trainingSetType: TrainingSetType | null;
  modelVersion: string | null;
  createdAt: DateTime;
}

interface SelldownProjectionModel {
  id: number;
  onsaleStartsAt: DateTime;
  eventStartsAt: DateTime;
  binSizeSeconds: number;
  trainingSetType: TrainingSetType | null;
  onsaleDemandFraction: number | null;
  createdAt: DateTime;
}

interface Models {
  onsalePricingModel: OnsalePricingModel | null;
  selldownProjectionModel: SelldownProjectionModel | null;
}

interface SalesPeriod {
  id: number;
  type: SalesPeriodType;
  startsAt: DateTime;
  endsAt: DateTime;
  demandPrior: number | null;
  accessCodes: string[];
  createdAt: DateTime;
  updatedAt: DateTime;
}

interface Event {
  id: number;
  title: string;
  config: EventConfig;
  groups: string[];
  priceExploration: PriceExploration;
  sinkEvents: SinkEvent[];
  sourceEvents?: SourceEvent[];
  models: Models;
  venue: string;
  isGa: boolean;
  eventType: string;
  summary: Summary;
  schedule: Schedule | null;
  partitions: Partition[];
  salesPeriods: SalesPeriod[];
  errors?: Errors;
  eventErrors: EventError[];
  overrides?: Overrides;
  selldown?: SelldownProjections;
  hasPriceConstraints: boolean;
}

interface FetchEventsHook {
  user: User;
  events: Event[];
  perPage: number,
  filters: EventFilters;
  setFilters: (filters: EventFilters) => void;
  nextPage: () => void;
  prevPage: () => void;
  retry: () => void;
  showError: boolean;
  showLoader: boolean;
  showResults: boolean;
}

enum PricingMode {
  ga = 'ga',
  mapped = 'mapped',
  parking = 'parking',
}

enum DynamicPricingModel {
  original = 'original',
  multivalue = 'multivalue',
}

interface EventPostBody {
  groups?: string[];
  onsaleStartsAt: DateTime;
  pricingMode: PricingMode;
  seatgeekEventId: number;
  sectionSubsetSections?: string;
  sectionSubsetType?: SectionSubsetType;
  supervisorStartsAt?: DateTime;
  supervisorStopsAt?: DateTime;
  keepSupervisorActive?: boolean;
  useSeatgeekTransactions?: boolean;
  demandChangepointCt?: number;
  pricingBasis?: PricingBasis;
  onsaleDemandFraction?: number;
  selldownProjectionModel?: TrainingSetType;
  onsalePricingModel?: TrainingSetType;
  dynamicPricingModel?: DynamicPricingModel;
  notes?: string;
  forceCreate?: boolean;
}

interface EventApiPost {
  groups?: string[];
  onsale_starts_at: string;
  pricing_mode: PricingMode;
  seatgeek_event_id: number;
  section_subset?: SectionSubset;
  supervisor_starts_at?: string;
  supervisor_stops_at?: string;
  keep_supervisor_active?: boolean;
  use_seatgeek_transactions?: boolean;
  demand_changepoint_ct?: number;
  pricing_basis?: PricingBasis;
  onsale_demand_fraction?: number;
  selldown_projection_model?: TrainingSetType;
  onsale_pricing_model?: TrainingSetType;
  dynamic_pricing_model?: DynamicPricingModel;
  notes?: string;
  force_create?: boolean;
}

interface EventRebuildApiPost {
  partitions: EventRebuildPartitionApiPost[];
}

interface EventRebuildPartitionApiPost {
  key: number;
  seatgeek_event_id: number;
  pricing_mode: PricingMode;
  section_subset: SectionSubset;
  onsale_pricing_model?: TrainingSetType;
}

interface UpdateSalesPeriods {
  salesPeriods: UpdateSalesPeriod[];
  notes?: string;
}

interface UpdateSalesPeriod {
  key: number;
  id?: number;
  type?: SalesPeriodType;
  startsAt?: string;
  endsAt?: string;
  demandPrior?: number;
  clearDemandPrior?: boolean;
  accessCodes?: string[];
  isDeleted?: boolean;
  error?: string;
}

interface EventFilters {
  autobroker_event_id?: number;
  seatgeek_event_id?: number;
  query?: string;
  is_scheduled?: number;
  is_active?: number;
  has_error?: number;
  error_status?: string;
  error_type?: string;
  source_name?: string;
  source_external_event_id?: string;
  sink_name?: string;
  sink_external_event_id?: string;
  event_type?: string;
  group?: string;
  from_date?: string;
  to_date?: string;
  from_id?: number;
  per_page?: number;
  is_include_qa_only?: number;
}

enum ValueField {
  COST = 'Cost',
  FACE = 'Face Value',
}

enum ModelType {
  ONSALE = 'Onsale',
  SELLDOWN = 'Selldown',
}

enum EditEventsOptions {
  ADD_QA = 'Add to QA Mode',
  REMOVE_QA = 'Remove from QA Mode',
  EDIT_VISIBILITY = 'Edit Visibility',
  EDIT_PRICE_EXPLORATION = 'Edit Price Exploration',
  UNLINK = 'Unlink',
  RELINK = 'Relink',
  CREATE_GROUP = 'Create Group',
  UPDATE_GROUP = 'Update Group',
}

const PRICING_MODE_OPTIONS = [
  { label: 'GA', value: PricingMode.ga },
  { label: 'Mapped', value: PricingMode.mapped },
  { label: 'Parking', value: PricingMode.parking },
];

const DYNAMIC_PRICING_MODEL_OPTIONS = [
  { label: 'Original', value: DynamicPricingModel.original },
  { label: 'Multi-Value', value: DynamicPricingModel.multivalue },
];

const PRICING_BASIS_OPTIONS = [
  { label: 'All In Price', value: PricingBasis.ALL_IN_PRICE },
  { label: 'Display Price', value: PricingBasis.DISPLAY_PRICE },
];

const SECTION_SUBSET_TYPE_OPTIONS = [
  { label: 'None', value: SectionSubsetType.NONE },
  { label: 'Positive', value: SectionSubsetType.POSITIVE },
  { label: 'Negative', value: SectionSubsetType.NEGATIVE },
];

const SELLDOWN_PROJECTION_MODEL_OPTIONS = [
  { label: 'Monolithic', value: TrainingSetType.MONOLITHIC },
  { label: 'High Demand Onsale', value: TrainingSetType.HIGH_DEMAND_EVENTS },
  { label: 'Single Map', value: TrainingSetType.SINGLE_MAP_CONFIG },
  { label: 'Single Venue, Event Type', value: TrainingSetType.SINGLE_VENUE_EVENT_TYPE },
  { label: 'Single Venue', value: TrainingSetType.SINGLE_VENUE },
  { label: 'Single Event Type', value: TrainingSetType.SINGLE_EVENT_TYPE },
];

const ONSALE_PRICING_MODEL_OPTIONS = [
  { label: 'Monolithic', value: TrainingSetType.MONOLITHIC },
  { label: 'Monolithic, with Seat Granularity', value: TrainingSetType.MONOLITHIC_WITH_SEATS },
  { label: 'Single Map', value: TrainingSetType.SINGLE_MAP_CONFIG },
  { label: 'Single Venue, Event Type', value: TrainingSetType.SINGLE_VENUE_EVENT_TYPE },
  { label: 'Single Venue', value: TrainingSetType.SINGLE_VENUE },
];

const SUPPORTS_ONSALE_DEMAND_FRACTION = [
  TrainingSetType.HIGH_DEMAND_EVENTS.toString(),
  TrainingSetType.MONOLITHIC.toString(),
];

export {
  APIEvent,
  APIEventConfig,
  DynamicPricingModel,
  TrainingSetType,
  EditEventsOptions,
  EventApiPost,
  EventRebuildApiPost,
  EventRebuildPartitionApiPost,
  EventConfig,
  EventFilters,
  EventPostBody,
  FetchEventsHook,
  Partition,
  PriceExploration,
  PriceExplorationType,
  PriceGranularity,
  PricingMode,
  PricingBasis,
  RawEventError,
  RawPartition,
  RawPriceExploration,
  RawSinkEvent,
  RawSourceEvent,
  RawSummary,
  RawSinkSummary,
  RawSchedule,
  RawModels,
  RawOnsalePricingModel,
  RawSalesPeriod,
  RawSelldownProjectionModel,
  SalesPeriod,
  SalesPeriodType,
  Schedule,
  SinkEvent,
  SourceEvent,
  StrategyType,
  SectionSubset,
  SectionSubsetType,
  SelldownProjections,
  Summary,
  SinkSummary,
  ValueField,
  ModelType,
  Models,
  OnsalePricingModel,
  SelldownProjectionModel,
  UpdateSalesPeriod,
  UpdateSalesPeriods,
  PRICING_MODE_OPTIONS,
  DYNAMIC_PRICING_MODEL_OPTIONS,
  PRICING_BASIS_OPTIONS,
  SECTION_SUBSET_TYPE_OPTIONS,
  SELLDOWN_PROJECTION_MODEL_OPTIONS,
  ONSALE_PRICING_MODEL_OPTIONS,
  SUPPORTS_ONSALE_DEMAND_FRACTION,
};
export default Event;
