import styled from 'styled-components';
import Notes from '#images/Notes.svg';
import { SmallTag } from '#/shared/Card';
import palette from '#/theme/palettes/main';

const Title = styled.div`
${({ theme }: { theme: Theme }): string => theme.header5};
margin-top: 3.75rem;
width: 100%;
text-align: left;
display: flex;
flex-direction: row;
`;

const Wrapper = styled.div`
width: 77.5rem;
display: flex;
flex-direction: column;
align-items: center;
padding-bottom: 6rem;
`;

const Section = styled.div`
display: flex;
flex-direction: column;
align-items: center;
min-height: 8rem;
width: 100%;
margin-top: 3.75rem;
`;

const NotesImg = styled.img`
cursor: pointer;
content: url(${Notes});
margin-bottom: 1.25rem;
margin-top: 3.75rem;
`;

const FormWrapper = styled.form`
  display: flex;
  margin-top: 0.1rem;
  margin-bottom: 0.5rem;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const Level1 = styled(FormWrapper as any)`
  left: 0rem;
`;

const Level2 = styled(FormWrapper as any)`
  left: 2rem;
`;

const Level3 = styled(FormWrapper as any)`
  left: 4rem;
`;

const Level4 = styled(FormWrapper as any)`
  left: 6rem;
`;

const Level5 = styled(FormWrapper as any)`
  left: 8rem;
`;

const Level6 = styled(FormWrapper as any)`
  left: 10rem;
`;

const Name = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.text2semibold};
  margin-bottom: 1.25rem;
  margin-top: 3.75rem;
  margin-right: 1rem;
  width: 100%;
  text-align: right;
`;

const Tag = styled(SmallTag as any)`
    ${({ theme }: { theme: Theme }): string => theme.text2semibold};
    margin-top:-0.05rem;
    overflow: visible;
`;

const PurpleTag = styled(Tag as any)`
  background-color: ${palette.purple.light24};
`;

const BlueTag = styled(Tag as any)`
  background-color: ${palette.blue.light24};
  ${({ theme }: { theme: Theme }): string => theme.text2semibold};
  margin-top:-0.1rem;
  overflow: visible;
`;

const YellowTag = styled(Tag as any)`
  background-color: ${palette.yellow.light24};
`;

const GreenTag = styled(Tag as any)`
  background-color: ${palette.mint.light24};
`;

const Input = styled.input`
  width: 10%;
  border: 1px solid ${palette.silver.dark};
  font-size: 17px;
`;

const Label = styled.label`
  ${({ theme }: { theme: Theme }): string => theme.text2};
  margin-bottom: 0.25rem;
  width: 100rem;
  font-size: 17px;
  margin-left: -1.5rem;
`;

const Reason = styled.div`
${({ theme }: { theme: Theme }): string => theme.text2};
width: 90%;
text-align: left;
margin-top: -0.9rem;
margin-bottom: 2rem;
display: flex;
flex-direction: row;
font-size: 17px
`;

const Link = styled.a`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.link};
  text-decoration: none;
  margin-bottom: 0.2rem;
  margin-left: -1.5rem;
  width: 100rem;
  font-size: 17px;
`;

export {
  Title,
  Wrapper,
  Section,
  NotesImg,
  FormWrapper,
  Level1,
  Level2,
  Level3,
  Level4,
  Level5,
  Level6,
  Name,
  PurpleTag,
  BlueTag,
  YellowTag,
  GreenTag,
  Input,
  Label,
  Reason,
  Link,
};
