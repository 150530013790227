import client from './_client';
import { OverrideType, Settings } from '#/types/Override';
import formatPriceDistributionData from './utils/formatPriceDistributionData';
import PriceDistributionGraphInfo from '#/types/Preview';
import { SelldownGraphInfo } from '#/types/Snapshot';
import mapModelToOverlay from './utils/mapModelToOverlay';
import formatPreviewSnapshots from './utils/formatPreviewSnapshots';
import camelcaseKeys from 'camelcase-keys';

interface APIOverride {
  type: OverrideType;
  value: number | null;
}

interface PostBody {
  autobroker_event_id: number;
  parameters: APIOverride[];
}

interface PreviewData {
  priceDistribution: PriceDistributionGraphInfo;
  selldown: SelldownGraphInfo;
}

type APICall = (
  eventId: number,
  canonicalSettings: Settings,
  settings: Settings
) => Promise<PreviewData>;

/* eslint-disable camelcase */
const formatPostBody = (
  eventId: number,
  canonicalSettings: Settings,
  settings: Settings,
): PostBody => {
  const body = {
    autobroker_event_id: eventId,
    parameters: [] as APIOverride[],
  };

  Object.keys(settings).forEach((key) => {
    const type = key as OverrideType;
    const updatedSetting = settings[type] !== canonicalSettings[type];

    if (updatedSetting) {
      body.parameters.push({
        type: type as OverrideType,
        value: settings[type] ?? null,
      });
    }
  });
  return body;
};
/* eslint-enable camelcase */

const postPreview: APICall = async (
  eventId,
  canonicalSettings,
  settings,
) => {
  const postBody = formatPostBody(
    eventId,
    canonicalSettings,
    settings,
  );
  const response = await client.post(`/proxy/preview/${eventId}`, postBody);

  // TODO: remove camelcasing here when switching to _clientFormatted axios client
  // (will require adjusting a bunch of types related to Settings, Overrides, etc.)
  const rawDistributions = camelcaseKeys(
    response?.data?.distributions || [],
    { deep: true },
  );
  const rawSnapshots = camelcaseKeys(
    response?.data?.selldown || [],
    { deep: true },
  );

  const parsedDistriutions = formatPriceDistributionData(rawDistributions);

  const priceDistribution = mapModelToOverlay(parsedDistriutions);

  const selldown = formatPreviewSnapshots(rawSnapshots);

  return { priceDistribution, selldown };
};

export default postPreview;
