import React, {
  useMemo, useRef, useCallback, useState,
} from 'react';
import Select, { type CSSObjectWithLabel, type StylesConfig } from 'react-select';
import styled from 'styled-components';
import { debounce } from '@graphique/graphique';
import Loader from '#/shared/Loader';
import Error from '#/shared/Error';
import ErrorCounts from './ErrorCounts';
import ErrorsTable from './ErrorsTable';
import filterErrorsBySearch, { Filters } from './utils/filterErrorsBySearch';
import {
  FormattedEventError, ErrorLevelType, ErrorStatusType, EventErrorType,
} from '#/types/Errors';
import { formatErrorType } from '#/pages/ErrorsPage/ErrorsTable/utils/helpers';
import { Option } from '../useFetchStakeholders';
import formatOptionLabel from '#/shared/formatOptionLabel';

const ERROR_COPY = 'Something went wrong retrieving event errors!';
const SEARCH_PLACEHOLDER = 'Search by event, status, or type';

const LEVEL_OPTIONS = Object.values(ErrorLevelType).map((level) => ({
  value: level,
  label: level,
}));
const STATUS_OPTIONS = Object.values(ErrorStatusType).map((status) => ({
  value: status,
  label: status,
}));
const TYPE_OPTIONS = Object.values(EventErrorType).map((type) => ({
  value: type,
  label: formatErrorType(type),
}));
const SELECT_STYLES: StylesConfig = {
  control: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    maxHeight: 75,
    minWidth: 200,
  }),
  valueContainer: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    maxHeight: 72,
    padding: '0 6px',
    overflowY: 'scroll',
  }),
  menu: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    zIndex: 3,
  }),
};

interface EventErrorsDetailProps {
  eventErrors: FormattedEventError[];
  stakeholderOptions: Option<string>[];
  isLoading: boolean;
  hasError: boolean;
  retry: () => void;
}

const EventErrorsDetail: React.FC<EventErrorsDetailProps> = ({
  eventErrors, stakeholderOptions, isLoading, hasError, retry,
}) => {
  const [filters, setFilters] = useState<Filters>({ search: '' });
  const filteredErrors = useMemo(() => (
    filterErrorsBySearch(eventErrors, filters)
  ), [eventErrors, filters]);

  const searchRef = useRef<HTMLInputElement>(null);
  const handleSearchChange = debounce(300, () => {
    const search = searchRef?.current?.value?.trim().toLowerCase();

    setFilters((f: Filters) => ({ ...f, search }));
  });
  const handleLevelChange = useCallback((option: { value: ErrorLevelType }): void => {
    setFilters((f: Filters) => ({ ...f, level: option?.value }));
  }, []);
  const handleStatusChange = useCallback((option: { value: ErrorStatusType }): void => {
    setFilters((f: Filters) => ({ ...f, status: option?.value }));
  }, []);
  const handleTypeChange = useCallback((option: { value: EventErrorType }): void => {
    setFilters((f: Filters) => ({ ...f, type: option?.value }));
  }, []);

  const handleStakeholderChange = useCallback((option: { value: string }): void => {
    setFilters((f: Filters) => ({ ...f, stakeholder: option?.value }));
  }, []);

  return (
    <>
      {isLoading && (
        <CenteredContainer>
          <Loader />
        </CenteredContainer>
      )}
      {hasError && (
        <CenteredContainer>
          <Error copy={ERROR_COPY} retry={retry} />
        </CenteredContainer>
      )}
      {filteredErrors && !isLoading && !hasError && (
        <div style={{ width: '100%' }}>
          <TableHeaderContainer>
            <ErrorSearch
              onChange={handleSearchChange}
              placeholder={SEARCH_PLACEHOLDER}
              ref={searchRef}
              spellCheck='false'
              title={SEARCH_PLACEHOLDER}
              type='search'
            />
            <Select
              isClearable
              onChange={handleLevelChange}
              options={LEVEL_OPTIONS}
              placeholder='Filter by level'
              styles={SELECT_STYLES}
            />
            <Select
              isClearable
              onChange={handleStatusChange}
              options={STATUS_OPTIONS}
              placeholder='Filter by status'
              styles={SELECT_STYLES}
            />
            <Select
              isClearable
              onChange={handleTypeChange}
              options={TYPE_OPTIONS}
              placeholder='Filter by type'
              styles={SELECT_STYLES}
            />
            <Select
              formatOptionLabel={
                formatOptionLabel
              }
              isClearable
              onChange={handleStakeholderChange}
              options={stakeholderOptions}
              placeholder='Filter by stakeholder'
              styles={SELECT_STYLES}
            />
            <ErrorCounts errors={filteredErrors} />
          </TableHeaderContainer>
          <ErrorsTable errors={filteredErrors} />
        </div>
      )}
    </>
  );
};

const ErrorSearch = styled.input`
  font-family: Roobert;
  height: 40px;
  width: 270px;
  border: 1px solid;
  border-color: ${({ theme }: { theme: Theme }): string => theme.palette.silver.dark};
  border-radius: .3rem;
  padding: 4px 6px 4px 12px;
  font-size: 14px;
  letter-spacing: 0;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.8em;
  align-items: center;
  padding: 10px;
`;

const CenteredContainer = styled.div`
  display: flex;
  height: calc(25vh + 50px);
  flex-direction: column;
`;

export default EventErrorsDetail;
