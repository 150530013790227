import React, {
  useState, useCallback, useContext, useRef,
} from 'react';
import styled from 'styled-components';
import { CollectionOverridesContext } from '../contexts';
import Modal, { type Styles as ModalStyles } from 'react-modal';
import type { RollupEvent } from '../utils/getSalesTrendsByEvent';
import modalStyles from '#/shared/modalStyles';
import Check from '#/shared/clientReporting/Icons/Check';
import RequestedOverridesTable from './RequestedOverridesTable';
import Loader from '#/shared/Loader';
import useGetExistingOverrides from '../hooks/useGetExistingOverrides';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import type { RollupOverrideParams } from '#/types/ReportingRollup';
import { VIEWPORT_SMALL_PX } from '../constants';
import useSubmitBulkOverrides from '../hooks/useSubmitBulkOverrides';

interface Props {
  events: RollupEvent[]
  group?: string
}

const TITLE = 'Submit pricing overrides';
const customModalStyles: ModalStyles = {
  ...modalStyles,
  content: {
    ...modalStyles.content,
    maxWidth: 1000,
  },
};

const CONFIRMATION_MSG_PT_1 = 'You are about to modify pricing parameters for the selected events (';
const CONFIRMATION_MSG_PT_2 = ') at the same time. Please make sure this is what you want to do and that these values are correct.';
const NOTES = 'Notes';
const ACK_MSG = 'I have reviewed these changes and understand their implications';
const SUBMIT = 'Submit';
const CANCEL = 'Cancel';
const ERROR_MSG = 'Sorry, something went wrong getting existing overrides';
const NOTE_PREFIX = 'Bulk Override';

const RequestedOverridesModal: React.FC<Props> = ({ events, group }) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const notesRef = useRef<HTMLInputElement>(null);

  const confirmationMsg = (
    <>
      {CONFIRMATION_MSG_PT_1}
      <NumEvents>{events?.length}</NumEvents>
      {CONFIRMATION_MSG_PT_2}
    </>
  );

  const {
    requestedOverrides,
    isVisibleRequestedOverridesModal,
    setIsVisibleRequestedOverridesModal,
  } = useContext(CollectionOverridesContext);

  const {
    isLoading, hasError, existingOverrides, existingParameters,
  } = useGetExistingOverrides({ events });

  const closeModal = useCallback(() => {
    setIsVisibleRequestedOverridesModal(false);
    setCanSubmit(false);
  }, [setIsVisibleRequestedOverridesModal]);

  const handleAcknowledgement = useCallback(() => {
    setCanSubmit((prev) => !prev);
  }, []);

  const { submitOverrides } = useSubmitBulkOverrides();

  const handleSubmit = useCallback(async () => {
    const notesText = notesRef.current?.value?.trim();
    const autobrokerEventIds = events?.map(({ autobrokerEventId: id }) => id);
    const parameters: RollupOverrideParams[] = requestedOverrides?.map((override) => {
      const { label, ...params } = override;

      return params;
    });

    closeModal();

    const overrides = [{
      autobrokerEventIds,
      parameters,
      notes: notesText ? `${NOTE_PREFIX}: ${notesText}` : NOTE_PREFIX,
    }];

    submitOverrides({ overrides, group });
  }, [closeModal, events, requestedOverrides, submitOverrides, group]);

  return (
    <Modal
      appElement={document.body}
      isOpen={isVisibleRequestedOverridesModal}
      onRequestClose={closeModal}
      style={customModalStyles}
    >
      <Header>
        <Title>{TITLE}</Title>
        <FirstContainer>
          <Confirmation>{confirmationMsg}</Confirmation>
        </FirstContainer>
      </Header>
      <Main>
        <Content>
          {hasError && <p>{ERROR_MSG}</p>}
          {isLoading && !hasError
            ? (
              <Loader
                data-testid='overrides-modal-spinner'
                size={3.5}
                thickness={0.3}
              />
            )
            : (
              <RequestedOverridesTable
                events={events}
                existingOverrides={existingOverrides}
                existingParameters={existingParameters}
                requestedOverrides={requestedOverrides}
              />
            )}
        </Content>
        {!isLoading && !hasError && (
          <NotesContainer>
            <Notes>
              <NotesLabel htmlFor='overrides-note'>{NOTES}</NotesLabel>
              <NotesInput id='overrides-note' ref={notesRef} />
            </Notes>
          </NotesContainer>
        )}
      </Main>
      {!isLoading && !hasError && (
        <Controls>
          <CheckboxContainer>
            <Checkbox
              id='acknowledge-changes'
              onChange={handleAcknowledgement}
              type='checkbox'
            />
            <Label htmlFor='acknowledge-changes'>{ACK_MSG}</Label>
          </CheckboxContainer>
          <ButtonContainer>
            <Button
              kind='secondary'
              label={CANCEL}
              onClick={closeModal}
              padding='0.75rem 1.5rem'
            />
            <Button
              Icon={<IconWrapper><Check /></IconWrapper>}
              isDisabled={!canSubmit || hasError}
              isLoading={isLoading}
              label={SUBMIT}
              onClick={handleSubmit}
              padding='0.75rem 1.5rem'
            />
          </ButtonContainer>
        </Controls>
      )}
    </Modal>
  );
};

const Header = styled.div`
  border-bottom: 1px solid #dedede;
`;

const Title = styled.h3`
  ${({ theme }: { theme: Theme }): string => theme.header3};
  margin: 0;
  line-height: 1.2;
  font-size: 2rem;
`;

const FirstContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

const Confirmation = styled.p`
  font-size: 1.05rem;
`;

const NumEvents = styled.span`
  font-weight: 600;
  padding: 0 0.1rem;
`;

const Main = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  margin: 0 -2rem;
  padding: 0 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 0.5rem 0;
  height: 400px;
  max-height: 50vh;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${VIEWPORT_SMALL_PX}px) {
    flex-direction: column;
    justify-content: center;
  };
  justify-content: space-between;
  padding-top: 1rem;
  gap: 1rem;
  border-top: 1px solid #dedede;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  min-width: 300px;
`;

const Checkbox = styled.input`
  height: 1.1rem;
  width: 1.2rem;
  cursor: pointer;
`;

const Label = styled.label`
  font-size: 0.9rem;
  line-height: 1.1;
  cursor: pointer;
`;

const NotesContainer = styled.div`
  width: 100%;
  padding: 0.7rem 0 1.2rem 0;
`;

const Notes = styled.div`
  padding: 0 1rem;
`;

const NotesLabel = styled(Label as any)`
  cursor: default;
`;

const NotesInput = styled.input`
  padding: 0.5rem;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  width: calc(100% - 1rem);
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const Button = styled(SmallButton)`
  font-size: 1rem;
`;

const IconWrapper = styled.div`
  height: 1.1rem;
  width: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  Props, TITLE, ERROR_MSG, ACK_MSG,
};
export default RequestedOverridesModal;
