import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import Action, { UpdateEventConfigType } from '../types';
import formatDateForForm from '#/shared/formatDateForForm';
import formatDateForState from '#/shared/formatDateForState';
import { InputWrapper, Label } from './formComponents';

interface DatetimeFormProps {
  initialVal: DateTime;
  label: string;
  type: UpdateEventConfigType;
  handleInputChange: React.Dispatch<Action>;
}

const DatetimeForm: React.FC<DatetimeFormProps> = ({
  initialVal,
  label,
  type,
  handleInputChange,
}) => {
  const dateValue = formatDateForForm(initialVal);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = event.target.value;
      const dateObj = formatDateForState(newVal);

      handleInputChange({ type, value: dateObj });
    },
    [type, handleInputChange],
  );

  return (
    <InputWrapper>
      <Label htmlFor={type}>{`${label}:`}</Label>
      <input onChange={handleChange} type="datetime-local" value={dateValue} />
    </InputWrapper>
  );
};

export default DatetimeForm;
