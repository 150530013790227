import React, {
  useCallback, useState, useMemo,
} from 'react';
import styled from 'styled-components';
import DataTable, { TableColumn } from 'react-data-table-component';
import PageWrapper from '#/shared/PageWrapper';
import Header from '#/shared/Header';
import tableStyles from '#/shared/tableStyles';
import ScrollBody from '#/shared/ScrollBody';
import Loader from '#/shared/Loader';
import Error from '#/shared/Error';
import { User, UserType } from '#/types/User';
import useFetchUsersList from './useFetchUsersList';
import UserInfoCard from './UserInfoCard';
import UserDetailCard from './UserDetailCard';
import { CardsWrapper } from '#/shared/Card';
import useFetchStakeholders from '../useFetchStakeholders';
import filterUsersBySearch from './utils';
import Filters from './Filters';

interface UsersReportPageProps {
  user: User;
}

const ERROR_COPY = 'An error occurred';
const TITLE = 'User Management';

const tableColumns: TableColumn<User>[] = [{
  id: 'id',
  name: 'ID',
  selector: (user: User): number => user.id,
  sortable: true,
},
{
  id: 'first_name',
  name: 'First Name',
  selector: (user: User): string => user.firstName || '',
  sortable: true,
},
{
  id: 'last_name',
  name: 'Last Name',
  selector: (user: User): string => user.lastName || '',
  sortable: true,
},
{
  id: 'email',
  name: 'Email',
  selector: (user: User): string => user.email,
  minWidth: '300px',
  sortable: true,
},
{
  id: 'role',
  name: 'Role',
  selector: (user: User): UserType => user.userType,
  sortable: true,
},
{
  id: 'user_status',
  name: 'User Status',
  selector: (user: User): string => user.isActive ? 'Active' : 'Inactive',
  sortable: true,
}];

const UsersReportPage: React.FC<UsersReportPageProps> = ({
  user,
}) => {
  const { stakeholderLabels } = useFetchStakeholders();
  const [selectedUser, setSelectedUser] = useState<User>(null);
  const {
    users, timestamp, isLoading, hasError, filters, setFilters, retry,
  } = useFetchUsersList();

  const handleRowClick = useCallback((row: User) => {
    if (selectedUser && selectedUser.id === row.id)
      setSelectedUser(null);
    else
      setSelectedUser(row);
  }, [setSelectedUser, selectedUser]);

  const conditionalStyles = useMemo(() => [
    {
      when: (row: User): boolean => row.id === selectedUser?.id,
      style: tableStyles.rows.selectedHighlightStyle,
    },
  ], [selectedUser]);

  const [search, setSearch] = useState('active');
  const filteredUsers = useMemo(() => (
    filterUsersBySearch(users, search)
  ), [users, search]);

  return (
    <PageWrapper>
      <Container>
        <Header
          toggleOpen={null}
          user={user}
        />
      </Container>
      <Scroller>
        <Wrapper>

          <MainTitle>{TITLE}</MainTitle>

          <TableHeaderContainer>
            <FilterWrapper>
              <Filters
                filters={filters}
                setFilters={setFilters}
                setSearch={setSearch}
                setSelectedUser={setSelectedUser}
              />
            </FilterWrapper>
          </TableHeaderContainer>
          {selectedUser
                && (
                  <Section>
                    <CardsWrapper>
                      <UserInfoCard stakeholderLabels={stakeholderLabels} user={selectedUser} />
                      <UserDetailCard
                        disabled={user.userType !== UserType.SUPERUSER}
                        stakeholderLabels={stakeholderLabels}
                        user={selectedUser}
                      />
                    </CardsWrapper>
                  </Section>
                )}
          { isLoading && <Loader /> }
          { hasError && <Error copy={ERROR_COPY} retry={retry} /> }
          {!isLoading && !hasError && (
            <>
              <Title>{`${filteredUsers.length} users, as of ${timestamp.toLocaleString()}`}</Title>
              <TableContainer>
                <DataTable<User>
                  columns={tableColumns}
                  conditionalRowStyles={conditionalStyles}
                  customStyles={tableStyles}
                  data={filteredUsers}
                  defaultSortFieldId='id'
                  highlightOnHover
                  onRowClicked={handleRowClick}
                  pointerOnHover
                  responsive
                  striped
                />
              </TableContainer>
            </>
          )}

        </Wrapper>
      </Scroller>
    </PageWrapper>
  );
};

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  padding: 10px;
  width: 100%
`;

const Container = styled.div`
  z-index: 2;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.info};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: padding 0.75s ease;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem;
  text-align: left;
`;

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.0rem;
  width: 100%;
  text-align: left;
`;

const Scroller = styled(ScrollBody as any)`
  top: 76px;
`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  box-shadow: 0px 0px 1px rgb(36 63 97 / 16%), 0px 1px 4px rgb(36 63 97 / 10%);
  border-radius: 8px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 8rem;
    width: 100%;
    margin-bottom: 25px;
  `;

const FilterWrapper = styled(Section as any)`
  margin-top: 25px;
`;

const MainTitle = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header3};
  padding: 2rem 0.25rem 2rem 0;
  line-height: 0.5;
  width:100%;
`;

export default UsersReportPage;
