import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Listing from '#/types/Listing';

const SECTION_ROW = 'Section • Row';
const SEATS = 'Seats';
const PRICE_LEVEL = 'Price Level';

interface CardProps {
  listing: Listing;
}

const SeatsCard: React.FC<CardProps> = ({ listing }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{SECTION_ROW}</Name>
        <Value>{`${listing.section} • ${listing.row}`}</Value>
      </Element>
      {listing.source_price_level_name && (
        <Element>
          <Name>{PRICE_LEVEL}</Name>
          <Value>{listing.source_price_level_name}</Value>
        </Element>
      )}
      <Element>
        <Name>{SEATS}</Name>
        <Value>{`${listing.start_seat}-${listing.end_seat}`}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default SeatsCard;
