import React, {
  useState, useCallback, ChangeEvent, useMemo,
} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import Select from 'react-select';
import { startCase } from 'lodash';
import {
  SectionWrapper,
  ButtonsWrapper,
  Header,
  Label,
  Content,
  modalMenuProps,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import SliderInput from '#/shared/SliderInput';
import formatApiError from '#/api/utils/formatApiError';
import SettingsPostBody, { Settings, TimeUnit } from '#/types/Settings';
import postSettings from '#/api/postSettings';
import Notification from '#/shared/Notification';
import {
  TITLE,
  SUBMIT,
  CANCEL,
  NUMBER_SLIDERS,
  INTERVAL_SLIDERS,
  TIME_UNIT_OPTIONS,
} from './constants';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';
import type { GenericOption } from '#/types/GenericOption';

interface UpdateSettingsModalProps {
  closeModal: () => void;
  settings: Settings;
}

interface FilterSelectProps {
  defaultValue: any;
  onChange: (value: any) => void;
  options: typeof TIME_UNIT_OPTIONS;
  width?: string;
}

const formatLabel = (v: string): string => {
  return startCase(v);
};

const UpdateSettingsModal: React.FC<UpdateSettingsModalProps> = ({
  closeModal,
  settings,
}) => {
  const [settingsPostBody, setSettingsPostBody] = useState<SettingsPostBody>(settings);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [units, setUnits] = useState<TimeUnit>(TimeUnit.SECS);

  const cancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const submitSettingsUpdate = useCallback(async () => {
    try {
      setError('');
      const updatedSettings = await postSettings(settingsPostBody);

      if (updatedSettings.id) {
        setSuccess(true);
        setTimeout(closeModal, 1000);
      }
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [closeModal, settingsPostBody]);

  const convertToUnit = useCallback((t: number) => {
    if (units === TimeUnit.MINS)
      return Math.ceil((t / 60) * 10) / 10;

    if (units === TimeUnit.HRS)
      return Math.ceil((t / 60 / 60) * 10) / 10;

    if (units === TimeUnit.DAYS)
      return Math.ceil((t / 60 / 60 / 24) * 10) / 10;

    return t;
  }, [units]);

  const onChangeSliderRange = useCallback(
    (key: keyof SettingsPostBody, kind?: string) => (
      e: ChangeEvent<HTMLInputElement>,
    ): void => {
      const updatedValue = Number(e.target.value);
      const isScheduler = key === 'scheduler_interval';

      setSettingsPostBody((prev) => {
        let newSettings;

        if (kind) {
          newSettings = {
            ...prev,
            [key]: {
              ...prev[key],
              [kind]: isScheduler ? {
                [TimeUnit.SECS]: updatedValue,
              } : {
                [units]: updatedValue,
              },
            },
          } as SettingsPostBody;
        } else {
          newSettings = {
            ...prev,
            [key]: updatedValue,
          };
        }
        return newSettings;
      });
    }, [setSettingsPostBody, units],
  );

  const onTimeUnitChange = useCallback((event: GenericOption<TimeUnit, TimeUnit>) => {
    const selectedTimeUnit = event?.value || null;

    setUnits(selectedTimeUnit);
  }, [setUnits]);

  const openHelper = useCallback(() => null, []);

  const selectedTimeUnit = useMemo(() => TIME_UNIT_OPTIONS.find((tu) => tu.label === units),
    [units]);

  useSetupModal(cancel);

  return (
    <Modal
      isOpen
      onRequestClose={cancel}
      style={modalStyles}
    >
      <Header>{TITLE}</Header>
      <SectionWrapper>
        <NotificationContainer>
          <Notification error={error} success={success} />
        </NotificationContainer>
      </SectionWrapper>
      <SectionWrapper>
        <SettingsInnerWrapper className='modalContent'>
          {INTERVAL_SLIDERS.map((interval, intervalInd) => {
            const intervalKey = Object.keys(interval)[0];
            const isScheduler = intervalKey === 'scheduler_interval';

            return (
              <IntervalContainer key={intervalKey}>
                <SettingsHeader>
                  <SettingsLabel>{formatLabel(intervalKey)}</SettingsLabel>
                  {intervalInd === 1 && (
                    <FilterSelect
                      defaultValue={selectedTimeUnit}
                      onChange={onTimeUnitChange}
                      options={TIME_UNIT_OPTIONS}
                    />
                  )}
                </SettingsHeader>
                {Object.values(interval).map((v) => {
                  return v.map((k) => {
                    const valueKeyLabel = `${intervalKey}-${k.key}`;

                    // always keep scheduler interval in seconds
                    const settingsValue = isScheduler
                      ? settingsPostBody[intervalKey][k.key][TimeUnit.SECS]
                      : settingsPostBody[intervalKey][k.key][units];
                    const previousValue = isScheduler
                      ? settings[intervalKey][k.key][TimeUnit.SECS]
                      : convertToUnit(settings[intervalKey][k.key][TimeUnit.SECS]);

                    return (
                      <SliderContainer key={valueKeyLabel}>
                        <SliderInput
                          disabled={false}
                          hasHelper={false}
                          label={`${k.label} (${isScheduler ? TimeUnit.SECS : units})`}
                          max={isScheduler ? k.extent[1] : convertToUnit(k.extent[1])}
                          min={isScheduler ? k.extent[0] : convertToUnit(k.extent[0])}
                          onChange={onChangeSliderRange(intervalKey, k.key)}
                          openHelper={openHelper}
                          parameterValue={previousValue}
                          step={isScheduler ? k.step : convertToUnit(k.step)}
                          value={settingsValue ?? previousValue}
                        />
                      </SliderContainer>
                    );
                  });
                })}
              </IntervalContainer>
            );
          })}
          {
            NUMBER_SLIDERS.map((slider) => {
              return (
                <React.Fragment key={slider.key}>
                  {
                    slider.title && (
                      <SettingsHeader>
                        <SettingsLabel>{slider.title}</SettingsLabel>
                      </SettingsHeader>
                    )
                  }
                  <SliderContainer>
                    <SliderInput
                      disabled={false}
                      hasHelper={false}
                      label={slider.label}
                      max={slider.extent[1]}
                      min={slider.extent[0]}
                      onChange={onChangeSliderRange(slider.key)}
                      openHelper={openHelper}
                      parameterValue={settings[slider.key] as number}
                      step={slider.step}
                      value={settingsPostBody[slider.key] as number}
                    />
                  </SliderContainer>
                </React.Fragment>
              );
            })
          }
        </SettingsInnerWrapper>
        <ButtonsWrapper>
          <Button onClick={submitSettingsUpdate}>{SUBMIT}</Button>
          <Button onClick={cancel}>{CANCEL}</Button>
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

const IntervalContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const NotificationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SliderContainer = styled.div`
  margin-left: 4px;
`;

const SettingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const SettingsLabel = styled(Label as any)`
  font-weight: ${({ theme }: { theme: Theme }): string => theme.text2semibold};
  margin-left: -4px;
`;

const SettingsInnerWrapper = styled(Content as any)`
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.secondary};
  padding: 12px;
  border-radius: 4px;
`;

const FilterSelect: React.FC<FilterSelectProps> = ({
  defaultValue,
  onChange,
  options,
  width = '9rem',
}) => {
  return (
    <div style={{ width, marginTop: 12 }}>
      <Select
        defaultValue={defaultValue}
        menuPlacement='auto'
        onChange={onChange}
        options={options}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...modalMenuProps}
      />
    </div>
  );
};

export default UpdateSettingsModal;
