import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import Drawer from '#images/Drawer.svg';

interface ButtonProps {
  toggleOpen: MouseEventHandler<HTMLButtonElement>;
}

const ToggleSliderButton: React.FC<ButtonProps> = ({ toggleOpen }) => {
  return (
    <ButtonWrapper id='hamburger' onClick={toggleOpen}>
      <img alt='event list' src={Drawer} />
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button`
  border: none;
  height: 0.875rem;
  width: 1rem;
  margin: 0 16px 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
`;

export default ToggleSliderButton;
