import styled from 'styled-components';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0.5rem 1rem 0 1rem;
  justify-content: center;
`;

const Success = styled.p`
  position: absolute;
  top: 0rem;
  right: 0.75rem;
  ${({ theme }: { theme: Theme }): string => theme.text4};
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  border: solid 1px
    ${({ theme }: { theme: Theme }): string => theme.color.text.success};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.success};
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.success};
`;

const FormWrapper = styled.form`
  display: flex;
  margin: 0.1rem;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  
`;

const Label = styled.label`
  ${({ theme }: { theme: Theme }): string => theme.text3};
  margin-bottom: 0.25rem;
  padding: 4px;
  width: 10rem;
`;

export {
  ButtonsWrapper, Success, FormWrapper, Label,
};
