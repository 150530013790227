import { DateTime } from 'luxon';
import { SourceEvent } from '#/types/Event';

interface SourceTableData {
  id: number;
  sourceId: number;
  sourceName: string;
  sourceExternalEventId: string;
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
}

const formatSourceInfo = (sourceEvent: SourceEvent): SourceEvent => {
  const { createdAt, updatedAt, expiredAt } = sourceEvent;

  return {
    ...sourceEvent,
    createdAt: (createdAt
      .toLocaleString(DateTime.DATETIME_SHORT)
      .replace(',', ' ·')) + ' UTC',
    expiredAt: expiredAt
      ? expiredAt.toLocaleString(DateTime.DATETIME_SHORT).replace(',', ' ·') + ' UTC'
      : '',
    updatedAt: (updatedAt
      .toLocaleString(DateTime.DATETIME_SHORT)
      .replace(',', ' ·')) + ' UTC',
  };
};

export { SourceTableData };
export default formatSourceInfo;
