// Disabling sort-keys for the headers objects because they
// control the order of the table columns
/* eslint-disable sort-keys */

const SOURCE_CONFIG_HEADERS = {
  sourceName: 'Source',
  sourceExternalEventId: 'External Event ID',
  createdAt: 'Created',
  updatedAt: 'Updated',
  expiredAt: 'Expired',
};

const SINK_CONFIG_HEADERS = {
  sinkName: 'Sink',
  sinkExternalEventId: 'External Event ID',
  pVisible: 'Visible Fraction',
  listingMinCount: 'Min Listings',
  listingMaxCount: 'Max Listings',
  marketplaces: 'Marketplaces',
  qaMode: 'QA Mode',
  resetVisibility: 'Reset Visibility',
  churnVisibilityRate: 'Churn Visibility Rate',
  priceIncrement: 'Price Increment',
  activatedAt: 'Activated',
};

const TABLE_HEADERS = {
  SOURCE: SOURCE_CONFIG_HEADERS,
  SINK: SINK_CONFIG_HEADERS,
};

interface EventConfigHeaders {
  eventStartsAt: string;
  listingsRemovedAt: string;
  mapConfigId: string;
  mapConfigKey: string;
  onsaleStartsAt: string;
  resetPrices: boolean;
  seatgeekEventId: string;
  supervisorStartsAt: string;
  supervisorStopsAt: string;
}

interface SourceHeaders {
  createdAt: string;
  expiredAt: string;
  sourceName: string;
  sourceExternalEventId: string;
  updatedAt: string;
}

interface SinkHeaders {
  activatedAt: string;
  listingMaxCount: string;
  listingMinCount: string;
  pVisible: string;
  qaMode: string;
  sinkName: string;
  sinkExternalEventId: string;
}

interface TableHeaders {
  EVENT_CONFIG: EventConfigHeaders;
  SINK: SinkHeaders;
  SOURCE: SourceHeaders;
}

export {
  EventConfigHeaders,
  SINK_CONFIG_HEADERS,
  SinkHeaders,
  SOURCE_CONFIG_HEADERS,
  SourceHeaders,
  TableHeaders,
};
export default TABLE_HEADERS;
