import client from './_clientFormatted';
import Group from '#/types/Group';

const getGroups = async (): Promise<Group[]> => {
  const response = await client.get('/proxy/groups', {
    params: { perPage: 200 },
  });

  return response?.data?.groups || [];
};

export default getGroups;
