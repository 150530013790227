import { AxiosResponse } from 'axios';
import client from './_client';
import Transaction, { APITransactions, TransactionFilters } from '#/types/Transaction';
import mapTransactions from './utils/mapTransactions';

const getTransactions = async (
  filters: TransactionFilters,
): Promise<[Transaction[], number]> => {
  const response: AxiosResponse<APITransactions> = await client.get(
    '/proxy/transactions', { params: filters },
  );
  const apiTransactions: APITransactions = response?.data || null;
  const perPage = response?.data?.request?.per_page;

  return [mapTransactions(apiTransactions), perPage];
};

export default getTransactions;
