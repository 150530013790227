import Event, { SourceEvent } from '#/types/Event';
import formatEventInfo, { EventConfigTableData } from './formatEventInfo';
import formatSourceInfo from './formatSourceInfo';
import formatSinkInfo, { SinkTableData } from './formatSinkInfo';

interface TableData {
  eventConfigInfo: EventConfigTableData;
  sinkInfo: SinkTableData[];
  sourceInfo: SourceEvent[];
}

const formatConfigTableData = (activeEvent: Event): TableData => {
  if (!activeEvent)
    return { eventConfigInfo: null, sinkInfo: [], sourceInfo: [] };
  const { sinkEvents, sourceEvents } = activeEvent;

  const { eventConfigInfo } = formatEventInfo(activeEvent);
  const sourceInfo = sourceEvents.map(formatSourceInfo);
  const sinkInfo = sinkEvents.map(formatSinkInfo);

  return {
    eventConfigInfo,
    sinkInfo,
    sourceInfo,
  };
};

export { TableData };
export default formatConfigTableData;
