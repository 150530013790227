import React, { useCallback } from 'react';
import styled from 'styled-components';
import Select, { type GroupBase, type OptionsOrGroups } from 'react-select';
import Action, { UpdateEventConfigType } from '../types';
import { InputWrapper, Label } from './formComponents';
import { modalMenuProps } from '#/shared/modalComponents';

interface Option {
  id?: number;
  label: string;
  value: string;
}

interface SelectProps {
  label: string;
  type: UpdateEventConfigType;
  handleInputChange: React.Dispatch<Action>;
  initialValue?: string;
  initialValues?: string[];
  options: OptionsOrGroups<Option, GroupBase<Option>>;
  isMulti?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
}

const SelectForm: React.FC<SelectProps> = ({
  label,
  type,
  handleInputChange,
  initialValue,
  options,
  isMulti,
  isClearable,
  isDisabled = false,
}) => {
  const handleChange = useCallback(
    (option: Option) => {
      const value = option?.value || null;

      handleInputChange({ type, value });
    },
    [type, handleInputChange],
  );

  let defaultValue: Option = null;

  options.forEach((option: Option) => {
    if (option.value === initialValue)
      defaultValue = option;
  });

  return (
    <InputWrapper>
      <Label htmlFor={label}>{`${label}:`}</Label>
      <Wrapper>
        <Select
          defaultValue={defaultValue}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isMulti={isMulti}
          onChange={handleChange}
          options={options}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...modalMenuProps}
        />
      </Wrapper>
    </InputWrapper>
  );
};

const Wrapper = styled.div`
  width: 15.5rem;
`;

export { Option };
export default SelectForm;
