import React, {
  useContext, useMemo, useState, useCallback,
} from 'react';
import {
  GG, ScaleX, ScaleY, ScaleStroke, Labels, Tooltip, Zoom,
} from '@graphique/graphique';
import { GeomLine } from '@graphique/geom-line';
import { GeomVLine } from '@graphique/geom-vline';
import { max, min } from 'd3-array';
import { DateTime } from 'luxon';
import { Theme } from '#/shared/graphique';
import Legend from '../sharedGraphComponents/Legend';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import { Wrapper, GraphiqueWrapper, YAxisLabel } from '../sharedGraphComponents/styledComponents';
import { ListingsGraphInfo } from '#/types/Snapshot';
import { ZoomDomain } from '../types';
import {
  TIME_TO_EVENT_HRS_CUTOFF,
  elongate, formatTooltipX, formatXTicks, invSymLog, isDefined, symLog,
} from '../utils/dataFormatting';
import { groups, palette } from './utils';

interface GraphProps {
  graphInfo: ListingsGraphInfo;
}

const X_AXIS_LABEL = 'Time to event';
const LISTINGS = 'Listings';
const Y_AXIS_LABEL = <YAxisLabel>{LISTINGS}</YAxisLabel>;
const TOOLTIP_POSITION = 'top';

const Graph: React.FC<GraphProps> = ({ graphInfo }) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const { listingsGraphData: data } = graphInfo;

  const [includedGroups, setIncludedGroups] = useState(groups);

  const now = useMemo(() => {
    const eventDate = activeEvent.config.eventStartsAt;
    const xVal = eventDate ? eventDate.diff(DateTime.local(), 'hours').hours : undefined;

    return [{ x: symLog(xVal) }];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const longData = useMemo(() => elongate(data), [data]);

  const includedData = useMemo(() => {
    return longData.filter((d) => (
      includedGroups.includes(d.group)
        && invSymLog(d.x) >= TIME_TO_EVENT_HRS_CUTOFF
    ));
  }, [longData, includedGroups]);

  const yExtent = useMemo(() => {
    return [
      0,
      max(includedData, (d) => d.y),
    ];
  }, [includedData]);

  const xExtent = useMemo(() => {
    const definedData = includedData.filter((d) => isDefined(d.y));
    const maxX = max(definedData, (d) => d.x);
    const minX = min(includedData, (d) => d.x);

    return [minX, maxX];
  }, [includedData]);

  const [zoomDomain, setZoomDomain] = useState<ZoomDomain | undefined>();
  const handleZoom = useCallback((domain: ZoomDomain) => {
    setZoomDomain(domain);
  }, []);
  const handleUnzoom = useCallback(() => setZoomDomain(undefined), []);

  const handleLegendSelection = useCallback((v: string) => {
    setIncludedGroups((prev) => {
      if (prev.includes(v) && prev.length === 1)
        return groups;
      return prev.includes(v) ? prev.filter((p) => p !== v) : [...prev, v];
    });
  }, []);

  const resolvedYDomain = useMemo(() => (
    zoomDomain?.y ? [0, zoomDomain?.y[1]] : yExtent
  ), [zoomDomain, yExtent]);

  return (
    <Wrapper>
      <GraphiqueWrapper>
        <GG
          aes={{
            x: (d): number => d.x,
            y: (d): number => d.y,
            stroke: (d): string => d.group,
          }}
          data={includedData}
          isContainerWidth
          margin={{ left: 50 }}
        >
          {now[0].x && (
            <GeomVLine
              data={now}
              showTooltip={false}
              stroke='#aaa'
              strokeDasharray='3,3'
            />
          )}
          <GeomLine
            brushAction='zoom'
            entrance='data'
            strokeOpacity={0.67}
            strokeWidth={2.5}
          />
          <ScaleX
            domain={zoomDomain ? zoomDomain?.x : xExtent}
            format={formatXTicks}
            numTicks={4}
            reverse
          />
          <ScaleY domain={resolvedYDomain} numTicks={5} />
          <ScaleStroke domain={groups} values={palette} />
          <Labels
            header={<Legend onSelection={handleLegendSelection} />}
            x={X_AXIS_LABEL}
            y={Y_AXIS_LABEL}
          />
          <Tooltip
            position={TOOLTIP_POSITION}
            xFormat={formatTooltipX}
          />
          <Theme />
          <Zoom onUnzoom={handleUnzoom} onZoom={handleZoom} />
        </GG>
      </GraphiqueWrapper>
    </Wrapper>
  );
};

export default Graph;
export { X_AXIS_LABEL, Y_AXIS_LABEL };
