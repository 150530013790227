import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Select from 'react-select';
import { Option } from '#/pages/useFetchStakeholders';
import { User } from '#/types/User';
import ClientReporting from '../ClientReporting';
import { MainTitle } from '#/shared/clientReporting/typography';
import { Label } from '#/shared/modalComponents';
import Marketplace from '#images/Marketplace.svg';

interface PageProps {
  user: User;
  stakeholderOptions: Option<string>[];
}

const TITLE = 'Client Reporting';
const LABEL = 'Choose a client';

const LandingPage: React.FC<PageProps> = ({ user, stakeholderOptions }) => {
  const navigate = useNavigate();

  const stakeholders = useMemo(() => (
    stakeholderOptions.sort((a, b) => a.label < b.label ? -1 : 1)
  ), [stakeholderOptions]);

  const onSelectStakeholder = useCallback(({ value, label }: { value: string, label: string }) => {
    if (value) {
      const selectionPath = `/reporting/${value}`;

      navigate(selectionPath, { state: { displayName: label } });
    }
  }, [navigate]);

  return (
    <ClientReporting user={user}>
      <>
        <TitleContainer>
          <MainTitle>{TITLE}</MainTitle>
        </TitleContainer>
        {stakeholders && (
          <Container>
            <Label>{LABEL}</Label>
            <SelectContainer>
              <Select
                formatOptionLabel={
                  user.isReporting ? undefined : formatOptionLabel
                }
                isMulti={false}
                onChange={onSelectStakeholder}
                options={stakeholders}
              />
            </SelectContainer>
          </Container>
        )}
      </>
    </ClientReporting>
  );
};

const formatOptionLabel = (stakeholder: Option<string>): JSX.Element => (
  <LogoContainer>
    <Logo src={stakeholder.logoUrl ?? Marketplace} />
    <span>{stakeholder.label}</span>
  </LogoContainer>
);

const TitleContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  margin-left: 1rem;
  margin-top: -1.5rem;
`;

const SelectContainer = styled.div`
  max-width: 18rem;
  margin-top: 0.4rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  object-fit: cover;
  object-position: center;
`;

export default LandingPage;
