import React from 'react';
import styled from 'styled-components';
import Button from '#/shared/Button';
import Loading from './Loading';
import { Content as ModalContent } from '#/shared/modalComponents';

interface ContentProps {
  error: string;
  loading: boolean;
  modalText?: string;
  closeModal: () => void;
  cancelButtonText: string;
  handleSubmit: () => void;
  submitButtonText: string;
}

const ModalContents: React.FC<ContentProps> = ({
  error,
  loading,
  modalText,
  closeModal,
  cancelButtonText,
  handleSubmit,
  submitButtonText,
}) => {
  return (
    <Contents>
      <ModalContent>
        <Loading loading={loading} />
        <Text>{error}</Text>
        {!loading && <Text>{modalText}</Text>}
        <Wrapper>
          <Button onClick={closeModal}>{cancelButtonText}</Button>
          <Button onClick={handleSubmit}>{submitButtonText}</Button>
        </Wrapper>
      </ModalContent>
    </Contents>
  );
};

const Contents = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem 0 2rem 0;
`;

const Text = styled.p`
  ${({ theme }: { theme: Theme }): string => theme.text1};
  text-align: center;
`;

export default ModalContents;
