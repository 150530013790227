import { DateTime } from 'luxon';
import Event, { SectionSubsetType, SUPPORTS_ONSALE_DEMAND_FRACTION } from '#/types/Event';
import { formatPercentage } from '#/shared/formatNumberForDisplay';

interface EventConfigTableData {
  eventStartsAt: string;
  eventTimeString: string;
  eventDateString: string;
  id: number;
  listingsRemovedAt: string;
  mapConfig: string;
  onsaleStartsAt: string;
  resetPrices: string;
  keepSupervisorActive: string;
  seatgeekEventId: number;
  supervisorStartsAt: string;
  supervisorStopsAt: string;
  title: string;
  useSeatgeekTransactions: string;
  pricingMode: string;
  sectionSubset: string;
  demandChangepointCt: string;
  highDemandOnsale: string;
}

interface EventTableData {
  eventConfigInfo: EventConfigTableData;
}

const formatEventInfo = (activeEvent: Event): EventTableData => {
  const {
    config, id, title, isGa,
  } = activeEvent;
  const {
    eventStartsAt,
    eventTimeString,
    eventDateString,
    eventStartTimeStatus,
    listingsRemovedAt,
    mapConfigId,
    mapConfigKey,
    pricingMode,
    onsaleStartsAt,
    resetPrices,
    keepSupervisorActive,
    seatgeekEventId,
    supervisorStartsAt,
    supervisorStopsAt,
    useSeatgeekTransactions,
    demandChangepointCt,
    sectionSubset,
    pricingBasis,
    onsaleDemandFraction,
    selldownProjectionModel,
    dynamicPricingModel,
  } = config;
  const supervisorStartDate = !supervisorStartsAt.invalidReason;
  const eventStartsAtString = eventStartsAt
    .toLocaleString(DateTime.DATETIME_SHORT)
    .replace(',', ' ·');
  const on = '✔';
  const off = '✗';

  return {
    eventConfigInfo: {
      eventStartsAt: `${eventStartsAtString} UTC (${eventStartTimeStatus.replace('_', ' ')})`,
      eventTimeString: eventTimeString,
      eventDateString: eventDateString,
      id,
      listingsRemovedAt: listingsRemovedAt
        ? listingsRemovedAt
          .toLocaleString(DateTime.DATETIME_SHORT)
          .replace(',', ' ·')
        : 'Not set',
      mapConfig:
        isGa
          ? 'GA'
          : `ID: ${mapConfigId} | Key: ${mapConfigKey}`,
      onsaleStartsAt: onsaleStartsAt
        .toLocaleString(DateTime.DATETIME_SHORT)
        .replace(',', ' ·'),
      seatgeekEventId,
      supervisorStartsAt: supervisorStartDate
        ? supervisorStartsAt
          .toLocaleString(DateTime.DATETIME_SHORT)
          .replace(',', ' ·')
        : '',
      supervisorStopsAt: supervisorStopsAt
        .toLocaleString(DateTime.DATETIME_SHORT)
        .replace(',', ' ·'),
      title,
      useSeatgeekTransactions: `${useSeatgeekTransactions ? on : off} SeatGeek Transactions`,
      demandChangepointCt: `${demandChangepointCt || off} Demand Changepoints`,
      resetPrices: `${resetPrices ? on : off} Reset Prices`,
      keepSupervisorActive: `${keepSupervisorActive ? on : off} Keep Supervisor Active`,
      pricingMode: (
        `${pricingMode}, ${pricingBasis.split('_').join(' ')} basis`
        + (dynamicPricingModel === 'original' ? '' : `, ${dynamicPricingModel} model`)
      ),
      sectionSubset:
        sectionSubset.type === SectionSubsetType.NONE
          ? null
          : `${sectionSubset.type === SectionSubsetType.POSITIVE ? 'Includes: ' : 'Excludes: '} ${sectionSubset.sections.join(', ')}`,
      highDemandOnsale:
        SUPPORTS_ONSALE_DEMAND_FRACTION.includes(selldownProjectionModel)
          ? `${onsaleDemandFraction ? formatPercentage(onsaleDemandFraction) : on} High Demand Onsale`
          : `${off} High Demand Onsale`,
    },
  };
};

export { EventTableData, EventConfigTableData };
export default formatEventInfo;
