import { DateTime } from 'luxon';
import { PricingMode } from '#/types/Event';

interface DefaultEvent {
  groups?: string[];
  onsaleStartsAt: DateTime;
  pricingMode: PricingMode;
  seatgeekEventId: number;
  sectionSubset?: string;
  supervisorStartsAt?: DateTime;
  supervisorStopsAt?: DateTime;
  keepSupervisorActive?: boolean;
  useSeatgeekTransactions?: boolean;
  demandChangepointCt?: number;
  notes?: string;
}

const createDefaultEvent = (): DefaultEvent => {
  return {
    groups: [],
    onsaleStartsAt: null,
    pricingMode: PricingMode.mapped,
    seatgeekEventId: null,
    sectionSubset: '',
    supervisorStartsAt: null,
    supervisorStopsAt: null,
    keepSupervisorActive: false,
    useSeatgeekTransactions: true,
    demandChangepointCt: 10,
    notes: null,
  };
};

export { DefaultEvent };
export default createDefaultEvent;
