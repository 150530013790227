import React, {
  useState, ChangeEvent, useCallback, useContext,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import {
  SectionWrapper,
  Title,
  Error,
  Content,
} from '#/shared/modalComponents';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import formatApiError from '#/api/utils/formatApiError';
import getOverrideAggressivenessHelper from '#/api/getOverrideAggressivenessHelper';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';

const TITLE = 'Aggressiveness Helper';
const DESCRIPTION = `
    Calculate the aggressiveness override needed to sell down to a given selldown
    target in a given number of days. The selldown target must be between 0 and 1,
    and the number of days must be positive.
`;
const TARGET_SELLDOWN = 'Target Selldown';
const DAYS_FROM_NOW = 'Days From Now';
const AGGRESSIVENESS = 'Aggressiveness';

interface AggressivenessHelperProps {
  setNewValue: (newVal: number) => void;
  closeHelper: () => void;
}

const AggressivenessHelper: React.FC<AggressivenessHelperProps> = ({
  setNewValue,
  closeHelper,
}) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const [aggressiveness, setAggressiveness] = useState<number>(null);
  const [selldownTarget, setSelldownTarget] = useState<number>(null);
  const [daysFromNow, setDaysFromNow] = useState<number>(null);
  const [error, setError] = useState<string>(null);

  const updateAggressiveness = useCallback(async (s: number, d: number) => {
    if (s === null || d === null || activeEvent.id === null) {
      setAggressiveness(null);
      setError(null);
      return;
    }
    try {
      const aStr = await getOverrideAggressivenessHelper(
        activeEvent.id, s, d,
      );
      const a = Number(aStr.toFixed(3));

      setAggressiveness(a);
      setNewValue(a);
      setError(null);
    } catch (e) {
      setAggressiveness(null);
      setError(formatApiError(e));
    }
  }, [activeEvent, setNewValue, setError, setAggressiveness]);

  const onChangeSelldownTarget = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const s = Number(e.target.value);

      setSelldownTarget(s);
      updateAggressiveness(s, daysFromNow);
    },
    [setSelldownTarget, updateAggressiveness, daysFromNow],
  );
  const onChangeDaysFromNow = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const d = Number(e.target.value);

      setDaysFromNow(d);
      updateAggressiveness(selldownTarget, d);
    },
    [setDaysFromNow, updateAggressiveness, selldownTarget],
  );

  useSetupModal(closeHelper);

  return (
    <Modal
      isOpen
      onRequestClose={closeHelper}
      style={modalStyles}
    >
      <Title>{TITLE}</Title>
      <SectionWrapper>
        <InnerWrapper>
          <Description>{DESCRIPTION}</Description>
        </InnerWrapper>
        <Content>
          <InnerWrapper>
            <Label>{TARGET_SELLDOWN}</Label>
            <Input onChange={onChangeSelldownTarget} step="0.01" type="number" />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{DAYS_FROM_NOW}</Label>
            <Input onChange={onChangeDaysFromNow} step="0.01" type="number" />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{AGGRESSIVENESS}</Label>
            <Input disabled type="number" value={aggressiveness} />
          </InnerWrapper>
          <InnerWrapper>
            <Error>{error}</Error>
          </InnerWrapper>
        </Content>
      </SectionWrapper>
    </Modal>
  );
};

const Description = styled.p``;

const InnerWrapper = styled.div`
  text-align: center;
  font-size: 15px;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  text-align: left;
  width: 10rem;
  padding: 4px;
`;

const Input = styled.input`
  ${({ theme }: { theme: Theme }): string => theme.text4};
  width: 6rem;
  height: 1.5rem;
  text-align: center;
  padding: 4px;
  border: 1px solid
    ${({ theme }: { theme: Theme }): string => theme.color.border.primary};
  border-radius: 6px;
  transition: all 0.2s ease;
  &:focus {
    outline: none;
    outline-offset: none;
    border-color: ${({ theme }: { theme: Theme }): string => theme.color.border.selected};
  }
`;

export default AggressivenessHelper;
