import React from 'react';
import Map from './Map';
import type { MetricOption } from '../utils/menuOptions';
import MissingData from '../utils/MissingData';

interface Props {
  bypassCache?: boolean;
  hasDealTerm?: boolean;
  isUnmanifested?: boolean;
  metricSelection: MetricOption;
  selectedEventIds?: number[];
  mapConfigId?: number;
}

const MapRollup: React.FC<Props> = ({
  bypassCache,
  hasDealTerm,
  isUnmanifested,
  metricSelection,
  selectedEventIds,
  mapConfigId,
}) => {
  return selectedEventIds?.length === 0 ? (
    <MissingData />
  ) : (
    <Map
      bypassCache={bypassCache}
      hasDealTerm={hasDealTerm}
      isUnmanifested={isUnmanifested}
      mapConfigId={mapConfigId}
      metricSelection={metricSelection}
      selectedEventIds={selectedEventIds}
    />
  );
};

export default MapRollup;
