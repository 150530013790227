import React, {
  useContext, useState, useCallback,
} from 'react';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';

import ErrorInfo from './ErrorInfo';
import {
  FormWrapper,
  Name,
  NotesImg,
  Reason,
  Section,
  Title,
  Wrapper,
} from './components';
import {
  ErrorType, EventError, PostErrorStatus,
} from '#/types/Errors';
import { User } from '#/types/User';
import ErrorStatusModal, { ErrorStatusProps } from '#/shared/ErrorStatusModal';
import postErrorStatuses from '#/api/postErrorStatuses';
import { formatEventErrorType, getErrorStatusTag } from './utils';

const ADD_NOTES = 'Add Notes';

interface TroubleshootingProps {
  user: User;
}

const Troubleshooting: React.FC<TroubleshootingProps> = ({ user }) => {
  const { activeEvent, retrieveActiveEvent } = useContext(ActiveEventContext);
  const [errorStatusModal, setErrorStatusModal] = useState<ErrorStatusProps>({
    title: '',
    description: '',
    isOpen: false,
    existing: null,
    reference: null,
  });

  const openErrorStatusModal = useCallback((event: EventError) => {
    setErrorStatusModal({
      title: 'Annotate Event Error',
      description: `${event.errorType}\n${event.errorReason}`,
      isOpen: true,
      existing: event.errorStatus,
      reference: {
        id: event.id,
        type: ErrorType.AUTOBROKER_EVENT_ERRORS,
      },
    });
  }, [setErrorStatusModal]);

  const closeErrorStatusModal = useCallback((refresh: boolean) => {
    if (refresh && activeEvent)
      retrieveActiveEvent(activeEvent.id);

    setErrorStatusModal({
      title: '', description: '', isOpen: false, existing: null, reference: null,
    });
  }, [activeEvent, retrieveActiveEvent]);

  const handleErrorStatus = useCallback(async (status: PostErrorStatus) => {
    try {
      await postErrorStatuses({ errorStatuses: [status] });
      if (activeEvent)
        retrieveActiveEvent(activeEvent.id);
    } catch (err) {
      console.log(err);
    }
  }, [activeEvent, retrieveActiveEvent]);

  const allErrorTypes = activeEvent?.eventErrors.map((error) => { return error.errorType; });
  const currentErrors = activeEvent?.eventErrors.map((error) => {
    return (
      <Section key={error.id}>
        <FormWrapper>
          <Title>
            {formatEventErrorType(error)}
            {getErrorStatusTag(error)}
          </Title>
          {error.errorStatus?.userName && (
            <Name>{`Last updated by ${error.errorStatus.userName} at ${error.errorStatus.createdAt.toLocaleString()}`}</Name>
          )}
          {user.isReadWrite && (
            <NotesImg
            onClick={(): void => openErrorStatusModal(error)} // eslint-disable-line
              title={ADD_NOTES}
            />
          )}
        </FormWrapper>
        <Reason>{error.errorReason}</Reason>
        <ErrorInfo allErrors={allErrorTypes} error={error} setErrorStatus={handleErrorStatus} />
      </Section>
    );
  });

  return (
    <>
      <Wrapper>
        {currentErrors}
      </Wrapper>
      {errorStatusModal.isOpen && (
        <ErrorStatusModal
          closeModal={closeErrorStatusModal}
          props={errorStatusModal}
        />
      )}
    </>
  );
};

export default Troubleshooting;
