import React, { useCallback } from 'react';
import { CardsWrapper } from '#/shared/Card';
import { User } from '#/types/User';
import StakeholderCard from './StakeholderCard';
import DealCard from './DealCard';
import DealTermCard from './DealTermCard';
import PeakpassDomainCard from './PeakpassDomainCard';
import GroupCard from './GroupCard';
import SettingsCard from './SettingsCard';
import ReportCard from './ReportCard';

enum ModalType {
  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
  CREATE_STAKEHOLDER = 'CREATE_STAKEHOLDER',
  UPDATE_STAKEHOLDER = 'UPDATE_STAKEHOLDER',
  CREATE_DEAL = 'CREATE_DEAL',
  UPDATE_DEAL = 'UPDATE_DEAL',
  CREATE_DEAL_TERM = 'CREATE_DEAL_TERM',
  UPDATE_DEAL_TERM = 'UPDATE_DEAL_TERM',
  MAP_DEAL_TERM = 'MAP_DEAL_TERM',
  UPDATE_PEAKPASS_DOMAIN = 'UPDATE_PEAKPASS_DOMAIN',
  CREATE_GROUP = 'CREATE_GROUP',
  UPDATE_GROUP = 'UPDATE_GROUP',
}

interface ManageCardsProps {
  setModal: React.Dispatch<React.SetStateAction<ModalType>>;
  user: User;
}

const ManageCards: React.FC<ManageCardsProps> = ({ setModal, user }) => {
  const openModal = useCallback((modal: ModalType): (() => void) => {
    return (): void => setModal(modal);
  }, [setModal]);

  return (
    <CardsWrapper>
      {user.isAdmin && (
        <>
          {user.isSuperUser && (
            <>
              <SettingsCard openModal={openModal(ModalType.UPDATE_SETTINGS)} />
              <StakeholderCard
                openCreateModal={openModal(ModalType.CREATE_STAKEHOLDER)}
                openUpdateModal={openModal(ModalType.UPDATE_STAKEHOLDER)}
              />
            </>
          )}
          <DealCard
            openCreateModal={openModal(ModalType.CREATE_DEAL)}
            openUpdateModal={openModal(ModalType.UPDATE_DEAL)}
          />
          <DealTermCard
            openCreateModal={openModal(ModalType.CREATE_DEAL_TERM)}
            openMapModal={openModal(ModalType.MAP_DEAL_TERM)}
            openUpdateModal={openModal(ModalType.UPDATE_DEAL_TERM)}
          />
          <PeakpassDomainCard
            openUpdateModal={openModal(ModalType.UPDATE_PEAKPASS_DOMAIN)}
          />
          <GroupCard
            openCreateModal={openModal(ModalType.CREATE_GROUP)}
            openUpdateModal={openModal(ModalType.UPDATE_GROUP)}
          />
        </>
      )}
      <ReportCard />
    </CardsWrapper>
  );
};

export default ManageCards;
export { ModalType };
