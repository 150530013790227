import client from './_client';

type APICall = (autobrokerEventId: number, notes?: string) => Promise<number>;

const deletePriceConstraints: APICall = async (autobrokerEventId: number, notes?: string) => {
  const response = await client.delete(`/proxy/price_constraints/${autobrokerEventId}`, { data: { notes } });

  return response.status;
};

export default deletePriceConstraints;
