import React, { useCallback } from 'react';
import styled from 'styled-components';
import SkeletonBar from '#/pages/clientReporting/DashboardPage/components/SkeletonBar';

const SKELETON_COLS = [...Array(20).keys()];

const LoadingComponent: React.FC = () => {
  const genRandomHeight = useCallback(() => Math.random() * 200, []);

  return (
    <Container>
      {
        SKELETON_COLS.map((i) => {
          const COL_HEIGHT = genRandomHeight();

          return (
            <ColContainer key={i}>
              <SkeletonBar height={`${COL_HEIGHT}px`} />
            </ColContainer>
          );
        })
      }
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 200px;
  margin: 2.5rem 2rem 2rem 2rem;
  transform: rotate(180deg);
`;

const ColContainer = styled.div`
  flex-grow: 1;
  padding: 0.65rem 0.08rem;
`;

export default LoadingComponent;
