import client from './_clientFormatted';

const deleteEvent = async (autobrokerEventId: number): Promise<number> => {
  const response = await client.delete(
    `/proxy/event/${autobrokerEventId}`,
  );

  return response.status;
};

export default deleteEvent;
