import React from 'react';
import {
  NarrowWrapper,
  NameValue,
} from '#/shared/Card';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IngestionListingMinimalInfo } from '#/types/Ingestion';

const RELISTED_FROM = 'Relisted From';
const RELISTED_TO = 'Relisted To';
const LISTING_INFO_LABEL_TEXT = '(Seats • Listing ID)';

interface CardProps {
  previousIngestionListing: IngestionListingMinimalInfo | null;
  relistedIngestionListings: IngestionListingMinimalInfo[];
}

const PreviousIngestionListingCard: React.FC<CardProps> = ({
  previousIngestionListing, relistedIngestionListings,
}) => {
  const hasPreviousListing = !!previousIngestionListing;
  const hasRelistedListing = relistedIngestionListings?.length > 0;

  return (
    <NarrowWrapper>
      {hasPreviousListing && (
        <PreviousListingWrapper>
          <NameValue>{RELISTED_FROM}</NameValue>
          <NameValue>{LISTING_INFO_LABEL_TEXT}</NameValue>
          <ListingInfoParentContainer scrollable={false}>
            <ListingInfo listing={previousIngestionListing} />
          </ListingInfoParentContainer>
        </PreviousListingWrapper>
      )}
      {hasRelistedListing && (
        <RelistedListingsWrapper>
          <NameValue>{RELISTED_TO}</NameValue>
          <NameValue>{LISTING_INFO_LABEL_TEXT}</NameValue>
          <ListingInfoParentContainer scrollable>
            {
              relistedIngestionListings
                .sort((a, b) => a.start_seat - b.start_seat)
                .map((listing) => (<ListingInfo key={listing.id} listing={listing} />))
            }
          </ListingInfoParentContainer>
        </RelistedListingsWrapper>
      )}
    </NarrowWrapper>
  );
};

const formatSeatRange = (startSeat: number, endSeat: number): string => {
  return `${startSeat}-${endSeat}`;
};

interface ListingInfoProps {
  listing: IngestionListingMinimalInfo
}

const ListingInfo: React.FC<ListingInfoProps> = ({ listing }) => {
  const SEPARATOR = '•';

  return (
    <ListingInfoWrapper>
      <NameValue>
        {formatSeatRange(listing.start_seat, listing.end_seat)}
        &nbsp;
        {SEPARATOR}
        &nbsp;
      </NameValue>
      <Link
        rel="noopener noreferrer"
        target="_blank"
        to={`/ingestions/?id=${listing.id}`}
      >
        {listing.id}
      </Link>
    </ListingInfoWrapper>
  );
};

const ListingInfoWrapper = styled.div`
  display: flex;
  flex: 100%;
  justify-content: center;
`;

const PreviousListingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 0.35rem
`;

const RelistedListingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 0em;
`;

interface ListingInfoParentContainerProps {
  scrollable?: boolean;
}

const ListingInfoParentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: ${(props: ListingInfoParentContainerProps): string => props.scrollable ? 'auto' : 'visible'};
  width: 100%;
  margin-top: 0.2rem;
  ${({ theme }: { theme: Theme }): string => theme.text3};
`;

export default PreviousIngestionListingCard;
