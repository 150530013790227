import React, { useCallback, useState } from 'react';
import TableWrapper, {
  Cell,
  Header,
  RowWrapper,
} from '#/shared/TableComponents';
import Button from '#/shared/Button';
import { TransactionFilters, STATES, SOURCES } from '#/types/Transaction';
import type { FetchGroupsHook, GroupOption } from '#/pages/useFetchGroups';
import useFetchStakeholders, { type Option as StakeholderOption } from '#/pages/useFetchStakeholders';
import {
  Form, SmallInput, LargeInput, FilterSelect, getOption,
} from '#/shared/Filters';
import type { GenericOption } from '#/types/GenericOption';

const SEARCH = 'Search';
const COLUMNS = [
  'Event ID',
  'SG ID',
  'Group',
  'Stakeholder',
  'Source',
  'State',
  'Date',
  'Per Page',
];

interface FilterProps {
  filters: TransactionFilters;
  setFilters: (filters: TransactionFilters) => void;
  groupsHook?: FetchGroupsHook;
}

const Filters: React.FC<FilterProps> = ({
  filters,
  setFilters,
  groupsHook,
}) => {
  const [input, setInput] = useState(filters);
  const { groupLabels } = groupsHook;
  const { stakeholderLabels } = useFetchStakeholders();

  const stateOptions = Object.values(STATES).map((state) => ({ label: state, value: state }));
  const sourceOptions = Object.values(SOURCES).map((source) => ({ label: source, value: source }));

  const setAutobrokerEventId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, autobroker_event_id: Number(event.target.value) || null });
  },
  [input]);
  const setSeatGeekEventId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, seatgeek_event_id: Number(event.target.value) || null });
  },
  [input]);
  const setGroup = useCallback((event: GroupOption) => {
    setInput({ ...input, group: event?.value });
  },
  [input]);
  const setStakeholder = useCallback((event: StakeholderOption<string>) => {
    setInput({ ...input, stakeholder: event?.value });
  },
  [input]);
  const setSourceName = useCallback((event: GenericOption<SOURCES, SOURCES>) => {
    setInput({ ...input, source_name: event?.value });
  },
  [input]);
  const setState = useCallback((event: GenericOption<STATES, STATES>) => {
    setInput({ ...input, state: event?.value });
  },
  [input]);
  const setDate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, date: event?.target.value || null });
  },
  [input]);
  const setPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, per_page: Number(event.target.value) });
  },
  [input]);
  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    setFilters({ ...input, sort_by: filters.sort_by, sort_asc: filters.sort_asc });
  }, [filters.sort_asc, filters.sort_by, input, setFilters]);

  return (
    <Form onSubmit={handleSubmit}>
      <TableWrapper>
        <thead>
          <Header>
            {COLUMNS.map((column) => (
              <Cell key={column}>{column}</Cell>
            ))}
          </Header>
        </thead>
        <tbody>
          <RowWrapper>
            <Cell>
              <SmallInput
                defaultValue={filters.autobroker_event_id}
                onChange={setAutobrokerEventId}
                type='number'
              />
            </Cell>
            <Cell>
              <SmallInput
                defaultValue={filters.seatgeek_event_id}
                onChange={setSeatGeekEventId}
                type='number'
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.group, groupLabels)}
                onChange={setGroup}
                options={groupLabels}
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.stakeholder, stakeholderLabels)}
                onChange={setStakeholder}
                options={stakeholderLabels}
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.source_name, sourceOptions)}
                onChange={setSourceName}
                options={sourceOptions}
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.state, stateOptions)}
                onChange={setState}
                options={stateOptions}
              />
            </Cell>
            <Cell>
              <LargeInput
                defaultValue={filters.date}
                onChange={setDate}
                type='date'
              />
            </Cell>
            <Cell>
              <SmallInput
                defaultValue={filters.per_page}
                onChange={setPerPage}
                type='number'
              />
            </Cell>
            <Cell>
              <Button type="submit">{SEARCH}</Button>
            </Cell>
          </RowWrapper>
        </tbody>
      </TableWrapper>
    </Form>
  );
};

export default Filters;
