const palette: Palette = {
  brand: {
    base: '#FF5B49',
    dark: '#DB1600',
    light08: '#FFF2F0',
    light24: '#FFD8D3',
    light56: '#FFA399',
  },
  gold: {
    base: '#A99981',
    dark: '#766751',
    light08: '#F8F7F5',
    light24: '#EAE7E1',
    light56: '#CFC6B9',
  },
  gray: {
    base: '#525252',
    dark: '#181818',
    light08: '#F5F5F4',
    light24: '#DEDDDB',
    light56: '#767575',
  },
  silver: {
    base: '#F5F5F4',
    dark: '#DEDDDB',
    light08: '#F5F5F4',
    light24: '#F5F5F4',
    light56: '#F5F5F4',
  },
  mint: {
    base: '#11A669',
    dark: '#0B6F46',
    light08: '#ECF8F3',
    light24: '#C6EADB',
    light56: '#7ACDAB',
  },
  orange: {
    base: '#F26907',
    dark: '#B84D00',
    light08: '#FEF3EC',
    light24: '#FCDBC4',
    light56: '#F8AB74',
  },
  purple: {
    base: '#9837FF',
    dark: '#6400CF',
    light08: '#F7EFFF',
    light24: '#E6CFFF',
    light56: '#C58FFF',
  },
  red: {
    base: '#DD3C3C',
    dark: '#BA2626',
    light08: '#FDF0F0',
    light24: '#F7D0D0',
    light56: '#EC9292',
  },
  white: {
    base: '#FFFFFF',
    dark: '#FFFFFF',
    light08: '#FFFFFF',
    light24: '#FFFFFF',
    light56: '#FFFFFF',
  },
  black: {
    base: '#000000',
    dark: '#000000',
    light08: '#000000',
    light24: '#000000',
    light56: '#000000',
  },
  blue: {
    base: '#267DFF',
    dark: '#004EC3',
    light08: '#EEF5FF',
    light24: '#CBE0FF',
    light56: '#85B6FF',
  },
  yellow: {
    base: '#FDBF2D',
    dark: '#BE8F22',
    light08: '#FFFAEE',
    light24: '#FFF0CD',
    light56: '#FEDB8A',
  },
  cyan: {
    base: '#23A0CD',
    dark: '#0277A1',
    light08: '#EEF8FB',
    light24: '#CAE8F3',
    light56: '#84CAE3',
  },
  salmon: {
    base: '#F3695E',
    dark: '#CA3B30',
    light08: '#FEF3F3',
    light24: '#FCDBD9',
    light56: '#F8ABA5',
  },
};

export default palette;
