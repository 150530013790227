import React, { useCallback, useMemo, useState } from 'react';
import { Tab } from '../constants';
import {
  CumulativeMetricKey, MANIFEST_OPTIONS, ManifestOption, METRIC_OPTIONS, MetricOption, TAKE_KEY,
  TIME_OPTIONS,
  TimeMeasurement,
  TimeOption,
  TimeIntervalSelection,
  TIME_INTERVAL_OPTIONS,
  ManifestOptionSelection,
} from '../utils/menuOptions';
import type Group from '#/types/Group';
import { MapGranularity } from '#/types/MapRollup';
import { TimeUnit } from '#/shared/reporting/accumulationUtils';

interface SelectionsContextValue {
  selectedGroup?: Group;
  setSelectedGroup: React.Dispatch<React.SetStateAction<Group>>;
  autobrokerEventIds?: string[];
  setAutobrokerEventIds: React.Dispatch<React.SetStateAction<string[]>>;
  activeTab: Tab;
  setActiveTab: React.Dispatch<React.SetStateAction<Tab>>;
  metricSelection: MetricOption;
  handleMetricSelection: (value: CumulativeMetricKey) => void;
  hasDealTerm: boolean;
  handleDealTermFilter: () => void;
  timeGroup: TimeOption;
  handleTimeSelection: (value: TimeMeasurement) => void;
  mapGranularity: MapGranularity;
  setMapGranularity: (value: MapGranularity) => void;
  timeIntervalSelection: TimeIntervalSelection;
  handleTimeIntervalSelection: (value: TimeUnit) => void;
  manifestOptionsSubset: ManifestOptionSelection[];
  handleUnmanifestedFilter: (value: ManifestOption) => void;
  isUnmanifested?: boolean;
  isUnmanifestedFilter?: boolean;
  bypassCache?: boolean;
  setBypassCache: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollectionSelectionsContext = React.createContext<SelectionsContextValue>(null);

const CollectionSelectionsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [autobrokerEventIds, setAutobrokerEventIds] = useState<string[]>();
  const [bypassCache, setBypassCache] = useState<boolean>();
  const [activeTab, setActiveTab] = useState(Tab.TRENDS);

  const [metricSelection, setMetricSelection] = useState(METRIC_OPTIONS[0]);
  const handleMetricSelection = useCallback((value: CumulativeMetricKey) => {
    setMetricSelection(METRIC_OPTIONS.find((option) => option.key === value));
  }, []);

  const [hasDealTerm, setHasDealTerm] = useState<boolean>(true);
  const handleDealTermFilter = useCallback(() => {
    if (metricSelection.key === TAKE_KEY)
      handleMetricSelection(METRIC_OPTIONS[0].key);

    setHasDealTerm((prev) => !prev);
  }, [metricSelection, handleMetricSelection]);

  const manifestOptionsSubset = MANIFEST_OPTIONS.filter(
    (o) => selectedGroup?.attributes?.summary.isUnmanifested.includes(o.value),
  );

  const [isUnmanifested, setIsUnmanifested] = useState<boolean | undefined>(null);
  const handleUnmanifestedFilter = useCallback((value: ManifestOption) => {
    setIsUnmanifested(MANIFEST_OPTIONS.find((m) => m.name === value)?.value);
  }, []);
  const isUnmanifestedFilter = useMemo(() => (
    selectedGroup?.attributes?.summary.isUnmanifested.includes(isUnmanifested)
      ? isUnmanifested ?? undefined
      : undefined
  ), [isUnmanifested, selectedGroup]);

  const [mapGranularity, setMapGranularity] = useState<MapGranularity>(MapGranularity.SECTION);

  const [
    timeIntervalSelection, setTimeIntervalSelection,
  ] = useState<TimeIntervalSelection>(TIME_INTERVAL_OPTIONS[1]);

  const handleTimeIntervalSelection = useCallback((value: string) => {
    setTimeIntervalSelection(TIME_INTERVAL_OPTIONS.find((option) => option.key === value));
  }, []);

  const [timeGroup, setTimeGroup] = useState(TIME_OPTIONS[0]);
  const handleTimeSelection = useCallback((value: TimeMeasurement) => {
    setTimeGroup(TIME_OPTIONS.find((option) => option.key === value));
  }, []);

  const value: SelectionsContextValue = {
    selectedGroup,
    setSelectedGroup,
    autobrokerEventIds,
    setAutobrokerEventIds,
    activeTab,
    setActiveTab,
    metricSelection,
    handleMetricSelection,
    hasDealTerm,
    handleDealTermFilter,
    timeGroup,
    handleTimeSelection,
    mapGranularity,
    setMapGranularity,
    timeIntervalSelection,
    handleTimeIntervalSelection,
    manifestOptionsSubset,
    handleUnmanifestedFilter,
    isUnmanifested,
    isUnmanifestedFilter,
    bypassCache,
    setBypassCache,
  };

  return (
    <CollectionSelectionsContext.Provider value={value}>
      {children}
    </CollectionSelectionsContext.Provider>
  );
};

export {
  type SelectionsContextValue,
  CollectionSelectionsContext,
  CollectionSelectionsProvider,
};
