import React, {
  useContext, useMemo, useState, useCallback,
} from 'react';
import styled from 'styled-components';
import OverridesTable from './OverridesTable';
import Loader from '#/shared/Loader';
import palette from '#/theme/palettes/main';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import ListingOverrideModal, { InputListing } from '#/shared/ListingOverrideModal';
import { PostListingOverride } from '#/types/Override';
import { PriceGranularity } from '#/types/Event';

const OVERRIDES = 'Overrides';
const ERROR_COPY = 'Something went wrong retrieving the overrides summary!';
const HEADLINE = 'This action was unsuccessful.';

const Overrides: React.FC = () => {
  const { activeEvent, apiError } = useContext(ActiveEventContext);
  const showResults = useMemo(() => activeEvent?.overrides?.listings && !apiError,
    [activeEvent, apiError]);
  const isLoading = useMemo(() => !apiError && !activeEvent?.overrides?.listings,
    [activeEvent, apiError]);
  const error = useMemo(() => apiError && !activeEvent?.overrides?.listings,
    [activeEvent, apiError]);

  const [overrideModal, setOverrideModal] = useState<InputListing[]>([]);

  const closeOverrideModal = useCallback(() => {
    setOverrideModal([]);
  }, [setOverrideModal]);

  const listingOverrides = useMemo(() => {
    return activeEvent?.overrides?.listings.map((o: PostListingOverride) => {
      return {
        autobroker_event_id: activeEvent?.id,
        source_name: o.source_name,
        source_external_event_id: o.source_external_event_id,
        source_inventory_id:
        o.granularity === PriceGranularity.LISTING
          ? o.source_inventory_id : undefined,
        ingestion_listing_id:
        o.granularity === PriceGranularity.INGESTION
          ? Number(o.source_inventory_id) : undefined,
        source_price_level_id:
        o.granularity === PriceGranularity.PRICE_LEVEL
          ? o.source_inventory_id : undefined,
        section:
        o.granularity === PriceGranularity.SECTION
          ? o.source_inventory_id : undefined,
        mapkey:
          o.granularity === PriceGranularity.ROW
            ? o.source_inventory_id : undefined,
        override: {
          id: undefined,
          granularity: o.granularity,
          section: o.section,
          row: o.row,
          mapkey: o.mapkey,
          expected_value: o.expected_value,
          price_constraint: o.price_constraint,
          is_visible: o.is_visible,
          all_in_price: o.all_in_price,
          display_price: o.display_price,
          broadcast_price: o.broadcast_price,
          user_name: undefined,
          created_at: undefined,
          is_cascading: undefined,
          is_active: undefined,
          is_empty: false,
        },
      };
    });
  }, [activeEvent]);

  return (
    <div id="overrides">
      <Wrapper>
        <Section>
          {showResults
            && (
              <>
                <Title>{OVERRIDES}</Title>
                <div style={{ width: '100%' }}>
                  <OverridesTable
                    listingOverrides={listingOverrides}
                    setOverrideModal={setOverrideModal}
                  />
                </div>
              </>
            )}
          {error && (
            <>
              <HeaderText>{HEADLINE}</HeaderText>
              <ErrorMessage>{ERROR_COPY}</ErrorMessage>
            </>
          )}
        </Section>
        {isLoading && (
          <Section>
            <Loader hexColor={palette.brand.base} />
          </Section>
        )}
        {overrideModal.length > 0 && (
          <ListingOverrideModal
            closeModal={closeOverrideModal}
            editOnlyCurrentGranularity
            listings={overrideModal}
          />
        )}
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
    width: 77.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 6rem;
  `;

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 8rem;
  width: 100%;
  margin-top: 3.75rem;
`;

const ErrorMessage = styled.p`
  margin: 0 0 1rem 0;
`;

const HeaderText = styled.h4`
  ${({ theme }: { theme: Theme }): string => theme.header4};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.error};
  margin: 1rem 0;
`;

export default Overrides;
