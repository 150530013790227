import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import Selldown from './Selldown';
import { SelldownGraphInfo } from '#/types/Snapshot';
import Loading from '../sharedGraphComponents/Loading';
import Error from '../sharedGraphComponents/Error';
import { Dropdown, Item } from '#/shared/clientReporting/Dropdown';
import { Label } from '#/shared/clientReporting/ListBox';
import { SELLDOWN_VIEW_OPTIONS, combineGraphInfo, determineShowOverlay } from './utils';
import useFetchEvTiersSelldown from './useFetchEvTiersSelldown';
import EvTiersSelldown from './EvTiersSelldown';
import { SelldownViewMode } from '#/types/SelldownByExpectedValue';

const SELLDOWN_VIEW = 'Selldown view';

interface SelldownGraphProps {
  error: string;
  loading: boolean;
  overlayError: string;
  selldownGraphInfo: SelldownGraphInfo;
  selldownTargetInfo: SelldownGraphInfo;
  selldownOverlay: SelldownGraphInfo;
  showGraph: boolean;
}

const SelldownGraph: React.FC<SelldownGraphProps> = ({
  error,
  loading,
  overlayError,
  selldownGraphInfo,
  selldownOverlay,
  selldownTargetInfo,
  showGraph,
}) => {
  const [historical, setHistorical] = useState(false);
  const handleClick = useCallback(() => setHistorical(!historical), [
    historical,
  ]);
  const [selldownView, setSelldownView] = useState(SelldownViewMode.OVERALL);

  const combinedGraphInfo = useMemo(() => {
    if (selldownGraphInfo && selldownTargetInfo)
      return combineGraphInfo(selldownGraphInfo, selldownTargetInfo);

    return selldownGraphInfo;
  }, [selldownGraphInfo, selldownTargetInfo]);

  const okToRender = showGraph && !loading && !error;
  const showOverlay = determineShowOverlay(overlayError, selldownOverlay);

  const {
    evTiersSelldown,
    loading: evTiersLoading,
    error: evTiersError,
  } = useFetchEvTiersSelldown(
    showOverlay
      ? selldownOverlay?.graphData?.ticketsTargetOverlay
      : selldownTargetInfo?.graphData?.ticketsTarget,
  );

  const disabledSelldownOption = useMemo(() => {
    const hasEvTiersSelldown = (
      evTiersSelldown?.formattedData?.length > 0 && evTiersSelldown?.expectedValueBins?.length > 0
    );

    return hasEvTiersSelldown ? undefined : [SelldownViewMode.EXPECTED_VALUE];
  }, [evTiersSelldown]);

  const handleViewSelection = useCallback((name?: SelldownViewMode) => {
    setSelldownView(name);
  }, []);

  return (
    showGraph ? (
      <>
        <ControlsArea>
          <SelectionWrapper>
            <SelectionLabel htmlFor='selldown-view-menu'>
              {SELLDOWN_VIEW}
            </SelectionLabel>
            <Dropdown
              aria-label={SELLDOWN_VIEW}
              disabledKeys={disabledSelldownOption}
              id='selldown-view-menu'
              isLoading={loading || evTiersLoading}
              items={SELLDOWN_VIEW_OPTIONS}
              onSelectionChange={handleViewSelection}
              selectedKey={selldownView}
            >
              {({ label }): JSX.Element => (
                <Item
                  key={label}
                  textValue={label}
                >
                  <Label maxWidth={180}>{label}</Label>
                </Item>
              )}
            </Dropdown>
          </SelectionWrapper>
        </ControlsArea>
        {okToRender && selldownView === SelldownViewMode.OVERALL && (
          <ChartWrapper>
            <Selldown
              handleClick={handleClick}
              historical={historical}
              overlayError={overlayError}
              selldownGraphInfo={combinedGraphInfo}
              selldownOverlay={selldownOverlay}
              showOverlay={showOverlay}
              yLabel='Tickets'
            />
          </ChartWrapper>
        )}
        <>
          <Loading loading={loading} showGraph={showGraph} />
          <Error error={error} showGraph={showGraph} />
        </>
        {okToRender && selldownView === SelldownViewMode.EXPECTED_VALUE && (
          <ChartWrapper>
            {showGraph && !evTiersLoading && !evTiersError && (
              <EvTiersSelldown data={evTiersSelldown} />
            )}
            <Loading loading={evTiersLoading} showGraph={showGraph} />
            <Error error={evTiersError} showGraph={showGraph} />
          </ChartWrapper>
        )}
      </>
    ) : null
  );
};

const ChartWrapper = styled.div`
  width: 100%;
`;

const ControlsArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
  padding: 0.3rem 0 0.25rem 0.5rem;
`;

const SelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  letter-spacing: 0.2px;
  margin: 0 0.8rem;
`;

const SelectionLabel = styled.label`
  font-weight: 600;
  font-size: 0.8rem;
  color: #444;
`;

export default SelldownGraph;
