import React, { useCallback, ChangeEvent } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { PostIngestionTicket } from '#/types/Ingestion';
import type { GenericOption } from '#/types/GenericOption';

const BARCODE = 'Barcode';
const WALLET_LINK = 'Wallet Link';

type TicketField = 'Barcode' | 'Wallet Link';
const IN_HAND_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
const selectStyle = { width: '5.5rem', marginRight: '0.7rem', height: '1.5rem' };

interface TicketFormProps {
  ticket: PostIngestionTicket;
  setTicket: (ticket: PostIngestionTicket, seat: number) => void;
  ticketField: TicketField;
}

const TicketForm: React.FC<TicketFormProps> = ({
  ticket,
  setTicket,
  ticketField,
}) => {
  const onChangeSeat = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const seat = Number(event?.target?.value);

    setTicket({ ...ticket, seat }, ticket.seat);
  }, [setTicket, ticket]);

  const onChangeBarcode = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const barcode = event?.target?.value || null;

    setTicket({ ...ticket, barcode }, ticket.seat);
  }, [setTicket, ticket]);

  const onChangeWalletLink = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const wallet_link = event?.target?.value || null;

    setTicket({ ...ticket, wallet_link }, ticket.seat);
  }, [setTicket, ticket]);

  const onChangePbId = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const paulbunyan_ticket_id = Number(event?.target?.value);

    setTicket({ ...ticket, paulbunyan_ticket_id }, ticket.seat);
  }, [setTicket, ticket]);

  const onChangeInHand = useCallback((event: GenericOption<string, boolean>) => {
    const in_hand = event?.value || null;

    setTicket({ ...ticket, in_hand }, ticket.seat);
  }, [setTicket, ticket]);

  return (
    <>
      <HorizonalWrapper>
        <InnerWrapper>
          <TinyInput
            onChange={onChangeSeat}
            title="Seat"
            type="number"
            value={String(ticket.seat) || ''}
          />
        </InnerWrapper>
        {ticketField === BARCODE && (
          <InnerWrapper>
            <MediumInput
              onChange={onChangeBarcode}
              title="Barcode"
              type="text"
              value={ticket.barcode || ''}
            />
          </InnerWrapper>
        )}
        {ticketField === WALLET_LINK && (
          <InnerWrapper>
            <MediumInput
              onChange={onChangeWalletLink}
              title="Wallet Link"
              type="text"
              value={ticket.wallet_link || ''}
            />
          </InnerWrapper>
        )}
        <InnerWrapper>
          <TinyInput
            onChange={onChangePbId}
            title="Paulbunyan Ticket Id"
            type="number"
            value={String(ticket.paulbunyan_ticket_id) || ''}
          />
        </InnerWrapper>
        <InnerWrapper>
          <div style={selectStyle}>
            <Select
              isMulti={false}
              onChange={onChangeInHand}
              options={IN_HAND_OPTIONS}
              value={
                IN_HAND_OPTIONS.find((s) => s.value === ticket.in_hand)
              }
            />
          </div>
        </InnerWrapper>
      </HorizonalWrapper>
    </>
  );
};

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HorizonalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 0.5rem;
`;

const Input = styled.input`
  ${({ theme }: { theme: Theme }): string => theme.text4};
  padding: 4px;
  margin-right: 0.7rem;
  border: 1px solid
    ${({ theme }: { theme: Theme }): string => theme.color.border.primary};
  border-radius: 6px;
  transition: all 0.2s ease;
  &:focus {
    outline: none;
    outline-offset: none;
    border-color: ${({ theme }: { theme: Theme }): string => theme.color.border.selected};
  }
`;

const TinyInput = styled(Input as any)`
  width: 4rem;
  height: 1.8rem;
`;

const MediumInput = styled(Input as any)`
  width: 7rem;
  height: 1.8rem;
`;

export default TicketForm;
export { BARCODE, WALLET_LINK };
