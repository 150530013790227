import { extent } from 'd3-array';
import { color } from 'd3-color';
import { scaleSequential } from 'd3-scale';
import { interpolateRdYlBu } from 'd3-scale-chromatic';
import type { MappedRollup, MapRollupDatum } from '#/types/MapRollup';
import type { CumulativeMetricKey } from '../utils/menuOptions';

const simplifyText = (text: string): string => (
  text.replaceAll(/\s|-/g, '').toLocaleLowerCase()
);

type Rgb = [number, number, number];

const fillScale = ({ mapData, selectedMetric, datum }: {
  mapData?: MappedRollup, selectedMetric: CumulativeMetricKey, datum?: MapRollupDatum,
}): Rgb | undefined => {
  if (mapData) {
    const metricExtent = extent(mapData?.mapRollup?.map((d) => d?.metrics?.[selectedMetric]));

    // convert interpolated color into format used in deck.gl ([r, g, b])
    const getFill = scaleSequential<Rgb, never>((t) => {
      const { r, g, b } = color(interpolateRdYlBu(t)).rgb();

      return [r, g, b];
    })
      .domain(metricExtent.reverse());

    return getFill(datum?.metrics?.[selectedMetric]);
  }
  return undefined;
};

export {
  simplifyText,
  fillScale,
};
