import React from 'react';
import styled from 'styled-components';

interface RangeLabelsProps {
  min: number;
  max: number;
}

const RangeLabels: React.FC<RangeLabelsProps> = ({ min, max }) => (
  <Labels>
    <div>{min}</div>
    <div>{max}</div>
  </Labels>
);

const Labels = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.text3};
  display: flex;
  margin-top: 0.75rem;
  width: 100%;
  justify-content: space-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export default RangeLabels;
