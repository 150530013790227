import React from 'react';
import styled from 'styled-components';
import { ReportingProvider } from '#/pages/clientReporting/ReportingContext';
import { User } from '#/types/User';
import { Option } from '#/pages/useFetchStakeholders';
import Layout from '#/shared/clientReporting/Layout';
import Loader from '#/shared/Loader';

interface Props {
  user?: User;
  stakeholder?: Option<string>;
}

const ClientReportingPending: React.FC<Props> = ({ user }) => {
  return (
    <ReportingProvider user={user}>
      <Layout>
        <LoadingWrapper>
          <Loader size={3.5} thickness={0.3} />
        </LoadingWrapper>
      </Layout>
    </ReportingProvider>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`;

export default ClientReportingPending;
