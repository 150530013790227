import { DateTime } from 'luxon';
import startCase from 'lodash/startCase';
import { CollectionDateFilters } from '.';

const formatMillisecondsAsDateTimeString = (milli?: number): string | undefined => {
  if (milli) {
    const parsedDate = DateTime.fromMillis(milli, { zone: 'utc' });

    return parsedDate.toISO({ includeOffset: false, suppressSeconds: true });
  }

  return undefined;
};

// takes datetime strings to determine min/max bounds error

interface GenerateMaxLessThanMinProps {
  min?: string;
  max?: string;
  kind: 'event' | 'transaction';
}

const generateMaxLessThanMin = (
  { min, max, kind }: GenerateMaxLessThanMinProps,
): string | undefined => {
  if (min && max && new Date(min) >= new Date(max))
    return `${startCase(kind)} max must be greater than ${kind} min`;
  return undefined;
};

const isWithinRange = (range: [string, string], fullExtent: [number, number]): boolean => {
  const [min, max] = range;
  const [fullMin, fullMax] = fullExtent;

  const hasValidMin = !min || Number(new Date(min)) >= fullMin;
  const hasValidMax = !max || Number(new Date(max)) <= fullMax;

  return hasValidMin && hasValidMax;
};

const validateDatesWithinExtents = (
  { eventStartFullExtent, transactionAtFullExtent, dateFilters }: {
    eventStartFullExtent: [number, number];
    transactionAtFullExtent: [number, number];
    dateFilters: CollectionDateFilters;
  },
): boolean => {
  const {
    eventStartMin, eventStartMax, transactionAtMin, transactionAtMax,
  } = dateFilters;

  const eventStartRange = [eventStartMin, eventStartMax] as [string, string];
  const transactionAtRange = [transactionAtMin, transactionAtMax] as [string, string];

  const eventStartRangeValid = isWithinRange(eventStartRange, eventStartFullExtent);
  const transactionAtRangeValid = isWithinRange(transactionAtRange, transactionAtFullExtent);

  return eventStartRangeValid && transactionAtRangeValid;
};

export {
  type GenerateMaxLessThanMinProps,
  generateMaxLessThanMin,
  formatMillisecondsAsDateTimeString,
  validateDatesWithinExtents,
};
