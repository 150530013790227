import { DateTime } from 'luxon';
import ParameterOverride, {
  Overrides, OverrideType, OverrideMap, Settings,
} from '#/types/Override';

interface APIParameterOverride {
  id: number;
  type: OverrideType;
  value?: number;
  user_id: number;
  expires_at?: string;
  created_at: string;
}

/* eslint-disable camelcase */
const mapOverride = (rawOverride: APIParameterOverride): ParameterOverride => {
  const {
    id,
    type,
    value,
    user_id,
    created_at,
    expires_at,
  } = rawOverride;

  const result: ParameterOverride = {
    createdAt: DateTime.fromISO(created_at, { zone: 'utc' }),
    id,
    type,
    userId: user_id,
    value,
  };

  if (expires_at)
    result.expiresAt = DateTime.fromISO(expires_at, { zone: 'utc' });

  return result;
};
/* eslint-enable camelcase */

const rawOverridesToOverrideMap = (rawOverrides: APIParameterOverride[]): OverrideMap => {
  const result = {} as OverrideMap;

  return rawOverrides ? rawOverrides.reduce((res, rawOverride) => {
    const override = mapOverride(rawOverride);

    res[override.type] = override;
    return res;
  }, result) : result;
};

const overrideMapToSettings = (overrides: OverrideMap): Settings => {
  const initialState = {} as Settings;

  return Object.values(OverrideType).reduce((res, type: OverrideType) => {
    const value = overrides[type]?.value;

    res[type] = Number.isNaN(value) ? null : value;
    return res;
  }, initialState);
};

/*
  If it seems weird that I loop twice, to convert twice, you're right! I think I'm going
  to need OverrideMap at some point (to set/check for expiration dates). For now, I only
  need Settings, and I didn't want to further complicate the useGraphControls hook by
  doing conversions in there.
*/
const formatOverrides = (rawOverrides: Overrides): Settings => {
  const overrideMap = rawOverridesToOverrideMap(rawOverrides?.parameters as APIParameterOverride[]);

  return overrideMapToSettings(overrideMap);
};

export default formatOverrides;
