import React, {
  useState, useMemo, useEffect,
} from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useNavigate, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import camelcaseKeys from 'camelcase-keys';
import { User } from '#/types/User';
import { Option } from '#/pages/useFetchStakeholders';
import { DateFilter } from '#/types/ReportingRollup';
import { EventTxSummary, QueryParams } from '#/pages/clientReporting/DashboardPage/types';
import {
  DEFAULT_EVENT_FROM_QUERY,
  getShouldUseDefaultStartDate,
  createQueryParams,
  getCachedQueryParams,
  setCachedQueryParams,
} from '#/pages/clientReporting/DashboardPage/utils';
import {
  NARROW_BREAKPOINT,
  EXTRA_NARROW_BREAKPOINT,
} from '#/shared/clientReporting/utils';
import { Deal, DealTerm } from '#/types/Deal';

interface ReportingSelection {
  selectedEvent?: EventTxSummary;
  selectedDeal?: Deal;
  selectedDealTerm?: DealTerm;
}

interface ReportingContextValue {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  stakeholder: Option<string>;
  setStakeholder: React.Dispatch<React.SetStateAction<Option<string>>>;
  hasPendingData: boolean;
  setHasPendingData: React.Dispatch<React.SetStateAction<boolean>>;
  salesDateFilter: DateFilter;
  setSalesDateFilter: React.Dispatch<React.SetStateAction<DateFilter>>;
  isNarrow: boolean;
  isExtraNarrow: boolean;
  queryParams: QueryParams;
  reportingSelection: ReportingSelection;
  setReportingSelection: React.Dispatch<React.SetStateAction<ReportingSelection>>;
}

interface ReportingProviderProps {
  children?: React.ReactNode;
  user?: User;
  stakeholder?: Option<string>;
  isNarrow?: boolean;
  isExtraNarrow?: boolean;
}

const ReportingContext = React.createContext<ReportingContextValue | null>(null);

const ReportingProvider: React.FC<ReportingProviderProps> = (
  { children, user: providedUser, stakeholder: providedStakeholder },
) => {
  const windowWidth = useWindowWidth();
  const [isNarrow, setIsNarrow] = useState(windowWidth < NARROW_BREAKPOINT);
  const [isExtraNarrow, setIsExtraNarrow] = useState(windowWidth < EXTRA_NARROW_BREAKPOINT);
  const [user, setUser] = useState<User>(providedUser);
  const [stakeholder, setStakeholder] = useState<Option<string>>(providedStakeholder);

  const [reportingSelection, setReportingSelection] = useState<ReportingSelection | undefined>();

  const [hasPendingData, setHasPendingData] = useState(false);
  const [salesDateFilter, setSalesDateFilter] = useState<DateFilter>();

  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();
  const initialQueryParams: QueryParams = useMemo(() => ({
    ...getCachedQueryParams(),
    ...camelcaseKeys(parse(locationSearch)),
  }), [locationSearch]);
  const queryParams: QueryParams = getShouldUseDefaultStartDate(initialQueryParams)
    ? {
      ...DEFAULT_EVENT_FROM_QUERY,
      ...initialQueryParams,
    }
    : initialQueryParams;

  useEffect(() => {
    // auto-populate url/query for stakeholder's dashboard and
    // use default eventFrom date when there is:
    // 1. no eventFrom provided and
    // 2. no other filters provided in the url/query
    //
    // an explicit ?event_from=0 will allow the open-ended event start filter

    // exclude 'perspective' from query params if a reporting user tries to pass it in
    const isReportingAndHasPerspective = user?.isReporting && initialQueryParams?.perspective;

    if (getShouldUseDefaultStartDate(initialQueryParams) && stakeholder) {
      navigate({
        search: createQueryParams({
          ...queryParams,
          perspective: isReportingAndHasPerspective ? undefined : queryParams?.perspective,
        }),
      }, { replace: true });
    } else if (isReportingAndHasPerspective) {
      navigate({
        search: createQueryParams(queryParams, { perspective: undefined }),
      }, { replace: true });
    }
  }, [user, navigate, initialQueryParams, queryParams, stakeholder]);

  useEffect(() => {
    setCachedQueryParams(queryParams, user);
  }, [queryParams, user]);

  useEffect(() => {
    setIsNarrow(windowWidth < NARROW_BREAKPOINT);
    setIsExtraNarrow(windowWidth < EXTRA_NARROW_BREAKPOINT);
  }, [windowWidth]);

  const providerValue = useMemo(
    () => ({
      user,
      setUser,
      stakeholder,
      setStakeholder,
      hasPendingData,
      setHasPendingData,
      salesDateFilter,
      setSalesDateFilter,
      isNarrow,
      isExtraNarrow,
      reportingSelection,
      setReportingSelection,
      queryParams,
    }),
    [
      user,
      setUser,
      stakeholder,
      setStakeholder,
      hasPendingData,
      setHasPendingData,
      salesDateFilter,
      setSalesDateFilter,
      isNarrow,
      isExtraNarrow,
      reportingSelection,
      setReportingSelection,
      queryParams,
    ],
  );

  return (
    <ReportingContext.Provider value={providerValue}>
      {children}
    </ReportingContext.Provider>
  );
};

export default ReportingContext;
export { ReportingProvider };
