import React, { useCallback, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { OverrideType, Settings } from '#/types/Override';
import SliderInput from '#/shared/SliderInput';
import Action from '../../types';
import AggressivenessHelper from './AggressivenessHelper';
import {
  DEFAULT_INCREMENT, LABELS, MAXS, MINS,
} from '#/shared/OverrideParams';
import { round } from 'lodash';

interface GraphControlProps {
  disabled: boolean;
  error?: string;
  getPreviewOverlay: (
    settings: Settings,
    type: OverrideType,
    value: number
  ) => Promise<void>;

  settings: Settings;
  value: number;
  updateSetting: React.Dispatch<Action>;
  type: OverrideType;
  parameterValue: number;
}

const GraphControl: React.FC<GraphControlProps> = ({
  disabled,
  error,
  getPreviewOverlay,
  settings,
  value,
  updateSetting,
  type,
  parameterValue,
}) => {
  const [helperOpen, setHelperOpen] = useState(false);
  const openHelper = useCallback(() => setHelperOpen(true), []);
  const closeHelper = useCallback(() => setHelperOpen(false), []);

  const setNewValue = useCallback(
    async (newVal: number) => {
      updateSetting({ type, value: newVal });
      await getPreviewOverlay(settings, type, newVal);
    },
    [getPreviewOverlay, settings, type, updateSetting],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newVal = event.target?.value ? Number(event.target.value) : null;

      setNewValue(newVal);
    },
    [setNewValue],
  );

  const hasHelper = (type === OverrideType.AGGRESSIVENESS) && !disabled;

  return (
    <Wrapper>
      <SliderInput
        disabled={disabled}
        error={error}
        hasHelper={hasHelper}
        label={LABELS[type]}
        max={MAXS[type]}
        min={MINS[type]}
        onChange={handleChange}
        openHelper={openHelper}
        parameterValue={parameterValue}
        step={DEFAULT_INCREMENT}
        value={value ? round(value, 2) : value}
      />
      {
        hasHelper && helperOpen && (
          (type === OverrideType.AGGRESSIVENESS) && (
            <AggressivenessHelper
              closeHelper={closeHelper}
              setNewValue={setNewValue}
            />
          )
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 15rem;
  padding: 0.3rem 0;
`;

export default GraphControl;
