import {
  PriceDistributionGraphData,
  PriceDistributionGraphPlot,
} from '../../../types/Preview';
import { GraphDomain } from '../../../types/Snapshot';
import calcMin from '../calcMin';

const calcpriceDistributionGraphDomain = (
  graphData: PriceDistributionGraphData,
): GraphDomain => {
  const ZERO = 0;
  const ONE = 1;
  const graphDomain = { x: [ZERO, ONE], y: [ZERO, ONE] };

  if (!graphData)
    return graphDomain;

  let minX: number;

  const datapoints = Object.keys(graphData);

  datapoints.forEach((el: keyof PriceDistributionGraphData): void => {
    const plotArray = graphData[el] as PriceDistributionGraphPlot[];
    const lastIdx = plotArray.length - ONE;

    for (let i = ZERO; i <= lastIdx; i += ONE) {
      const xySet = plotArray[i].graphPlot[0];

      if (xySet.y)
        minX = calcMin(minX, xySet.x);

      graphDomain.y[0] = Math.min(graphDomain.y[0], xySet.y0);
      graphDomain.y[1] = Math.max(graphDomain.y[1], xySet.y);
      graphDomain.x[1] = Math.max(graphDomain.x[1], xySet.x);
    }
  });
  if (minX)
    graphDomain.x[0] = minX;

  return graphDomain;
};

export default calcpriceDistributionGraphDomain;
