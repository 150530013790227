import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Event, { EditEventsOptions, SinkEvent, FetchEventsHook } from '#/types/Event';
import TableWrapper, {
  Cell,
  ClickableRowWrapper,
  Header,
  NoWrapCell,
  RowWrapper,
} from '#/shared/TableComponents';
import Loader from '#/shared/Loader';
import Error from '#/shared/Error';
import Button from '#/shared/Button';
import EventIcons from './EventIcons';
import palette from '#/theme/palettes/main';
import { formatLargeNumber, formatGtv } from '#/shared/formatNumberForDisplay';
import EditEventSelect, { Option } from '#/pages/SearchPage/EditEventSelect';
import EditEventConfirmationModal from '#/pages/SearchPage/EditEventConfirmationModal';
import toast, { Toaster } from 'react-hot-toast';
import postSinkConfig from '#/api/postSinkConfig';
import postSourceUnlink from '#/api/postSourceUnlink';
import postSourceLink from '#/api/postSourceLink';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import GroupModal from '#/shared/GroupModal';
import { FetchGroupsHook } from '#/pages/useFetchGroups';
import EditVisibilityModal from './EditVisibilityModal';
import EditPriceExplorationModal from './EditPriceExplorationModal';

const ERROR_COPY = 'Something went wrong retrieving events!';
const COLUMNS = [
  { label: 'Bulk Select', colSpan: 1 },
  { label: 'ID', colSpan: 1 },
  { label: 'Event Start Time', colSpan: 1 },
  { label: 'Title', colSpan: 1 },
  { label: 'Venue', colSpan: 1 },
  { label: 'Inventory', colSpan: 2 },
  { label: 'Sales', colSpan: 2 },
  { label: 'Properties', colSpan: 1 },
];
const PREVIOUS_PAGE = 'Previous Page';
const NEXT_PAGE = 'Next Page';
const ACTION_OPTIONS = Object.values(EditEventsOptions)
  .map((v) => ({
    label: v,
    value: v,
  }));

const REMOVE_MSG = 'Remove Events from QA Mode';
const ADD_MSG = 'Add Events to QA Mode';
const UNLINK_MSG = 'Unlink Events';
const RELINK_MSG = 'Relink Events';
const VIEW_AS_COLLECTION = 'View as Collection';

const formatQAConfirmationMessage = (numEvents: number, editMode: EditEventsOptions): string => {
  const generalConfirmation = 'Are you sure you want to';
  const pluralizedEvents = `${numEvents} event${numEvents === 1 ? '' : 's'}`;
  const [editAction, editDirection] = editMode === EditEventsOptions.ADD_QA ? ['add', 'to'] : ['remove', 'from'];

  return (
    `${generalConfirmation} ${editAction} ${pluralizedEvents} ${editDirection} QA mode?`
  );
};

interface EventTableProps {
  eventsHook: FetchEventsHook;
  groupsHook: FetchGroupsHook;
}

const EventTable: React.FC<EventTableProps> = ({ eventsHook, groupsHook }: EventTableProps) => {
  const {
    user,
    events,
    filters,
    nextPage,
    prevPage,
    retry,
    showLoader,
    showError,
    showResults,
    perPage,
  } = eventsHook;
  const { groups, groupLabels, fetchGroups } = groupsHook;

  const navigate = useNavigate();

  const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [checkboxState, setCheckboxState] = useState<boolean[]>(
    new Array(filters.per_page).fill(false),
  );
  const [headerCheckbox, setHeaderCheckbox] = useState<boolean>(false);
  const [disableDropdown, setDisableDropdown] = useState<boolean>(true);

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showEventGroupModal, setShowEventGroupModal] = useState<boolean>(false);

  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [sources, setSources] = useState<string>('');

  const [isEditingVisibility, setIsEditingVisibility] = useState(false);
  const [isEditingPriceExploration, setIsEditingPriceExploration] = useState(false);

  const TITLE = disableDropdown ? 'Edit Events' : `Edit ${selectedEvents.length} Event(s)`;

  const headerCheck = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHeaderCheckbox(event.target.checked);
    setCheckboxState(checkboxState.map(() => event.target.checked));
    setSelectedEvents(event.target.checked ? events : []);
  }, [checkboxState, events]);

  const rowCheck = useCallback((event: Event, idx: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>): void => {
      checkboxState.splice(idx, 1, e.target.checked);
      setCheckboxState(checkboxState);
      if (e.target.checked)
        setSelectedEvents((s) => [...s, event]);
      else
        setSelectedEvents((prev) => prev.filter((element) => element.id !== event.id));
    };
  }, [checkboxState]);

  const formatLinkConfirmationMessage = useCallback(
    (numEvents: number, editMode: EditEventsOptions): string => {
      const generalConfirmation = `You're ${editMode === EditEventsOptions.UNLINK ? 'unlinking' : 'relinking'}`;
      const pluralizedEvents = `${numEvents} event${numEvents === 1 ? '' : 's'}`;
      const editAction = editMode === EditEventsOptions.UNLINK ? 'from all' : 'to all previously linked';
      const sourceNames = new Set(selectedEvents.map((e) => e.sourceEvents).flat().map((e) => ' ' + e.sourceName));

      setSources(`Source(s): ${Array.from(sourceNames)}`);
      return (
        `${generalConfirmation} ${pluralizedEvents} ${editAction} sources.`
      );
    }, [selectedEvents],
  );

  useEffect(() => {
    setDisableDropdown(selectedEvents.length === 0);
  }, [selectedEvents]);

  useEffect(() => {
    setSelectedEvents([]);
    setCheckboxState(new Array(filters.per_page).fill(false));
    setHeaderCheckbox(false);
  }, [events, filters.per_page]);

  const closeModal = useCallback(() => {
    setShowConfirmationModal(false);
    setSelectedOption(null);
  }, []);

  const closeGroupModal = useCallback(() => {
    setShowEventGroupModal(false);
    fetchGroups();
  }, [fetchGroups]);

  const updateQAMode = useCallback(async (sink: SinkEvent): Promise<void> => {
    const newSink = sink;

    newSink.qaMode = selectedOption.value === EditEventsOptions.ADD_QA;
    await postSinkConfig(newSink);
  }, [selectedOption]);

  const closeEditVisibilityModal = useCallback(() => {
    setIsEditingVisibility(false);
    setSelectedOption(null);
  }, []);

  const closeEditPriceExplorationModal = useCallback(() => {
    setIsEditingPriceExploration(false);
    setSelectedOption(null);
  }, []);

  const toastMessages = useMemo(() => ({
    loading: '',
    success: selectedEvents.length === 1 ? '1 event was successfully edited.' : selectedEvents.length + ' events were successfully edited.',
    error: selectedEvents.length === 1 ? '1 event was not edited. Please try again.' : selectedEvents.length + ' events were not edited. Please try again.',
  }), [selectedEvents]);

  const submitSinkEventsEditsWithToast = useCallback(
    async (eventsToEdit: SinkEvent[], apiCall: typeof updateQAMode) => {
      await toast.promise(
        Promise.all(eventsToEdit.map(apiCall)), toastMessages,
      );
      retry();
    }, [toastMessages, retry, updateQAMode],
  );

  const submitSourceLinkUpdateWithToast = useCallback(
    async (eventIdsToEdit: number[], apiCall: typeof postSourceLink) => {
      await toast.promise(
        Promise.all(eventIdsToEdit.map(apiCall)), toastMessages,
      );
      retry();
    }, [toastMessages, retry],
  );

  const handleSubmit = useCallback(async () => {
    setShowConfirmationModal(false);
    if (selectedOption.value === EditEventsOptions.ADD_QA
      || selectedOption.value === EditEventsOptions.REMOVE_QA) {
      const sinkEvents = selectedEvents.map((e) => e.sinkEvents).flat();

      submitSinkEventsEditsWithToast(sinkEvents, updateQAMode);
    } else if (selectedOption.value === EditEventsOptions.UNLINK
      || selectedOption.value === EditEventsOptions.RELINK) {
      const sourceEvents = selectedEvents.map((e) => e.sourceEvents).flat();
      const sourceEventIds = sourceEvents.map((e) => e.id);
      const apiCall = selectedOption.value === EditEventsOptions.UNLINK
        ? postSourceUnlink : postSourceLink;

      submitSourceLinkUpdateWithToast(sourceEventIds, apiCall);
    }
    setSelectedOption(null);
  }, [
    selectedEvents, selectedOption, submitSinkEventsEditsWithToast,
    submitSourceLinkUpdateWithToast, updateQAMode,
  ]);

  const handleAddQA = useCallback(() => {
    setTitle(ADD_MSG);
    setMessage(formatQAConfirmationMessage(selectedEvents.length, EditEventsOptions.ADD_QA));
    setSources('');
    setShowConfirmationModal(true);
  }, [selectedEvents]);

  const handleRemoveQA = useCallback(() => {
    setTitle(REMOVE_MSG);
    setMessage(formatQAConfirmationMessage(selectedEvents.length, EditEventsOptions.REMOVE_QA));
    setSources('');
    setShowConfirmationModal(true);
  }, [selectedEvents]);

  const handleUnlink = useCallback(() => {
    setTitle(UNLINK_MSG);
    setMessage(formatLinkConfirmationMessage(selectedEvents.length, EditEventsOptions.UNLINK));
    setShowConfirmationModal(true);
  }, [selectedEvents, formatLinkConfirmationMessage]);

  const handleRelink = useCallback(() => {
    setTitle(RELINK_MSG);
    setMessage(formatLinkConfirmationMessage(selectedEvents.length, EditEventsOptions.RELINK));
    setShowConfirmationModal(true);
  }, [selectedEvents, formatLinkConfirmationMessage]);

  const handleSelect = useCallback((event: Option<EditEventsOptions>) => {
    setSelectedOption(event);
    if (event?.value === EditEventsOptions.ADD_QA)
      handleAddQA();
    else if (event?.value === EditEventsOptions.REMOVE_QA)
      handleRemoveQA();
    else if (event?.value === EditEventsOptions.UNLINK)
      handleUnlink();
    else if (event?.value === EditEventsOptions.RELINK)
      handleRelink();
    else if (event?.value === EditEventsOptions.CREATE_GROUP)
      setShowEventGroupModal(true);
    else if (event?.value === EditEventsOptions.UPDATE_GROUP)
      setShowEventGroupModal(true);
    else if (event?.value === EditEventsOptions.EDIT_VISIBILITY)
      setIsEditingVisibility(true);
    else if (event?.value === EditEventsOptions.EDIT_PRICE_EXPLORATION)
      setIsEditingPriceExploration(true);
  }, [handleAddQA, handleRemoveQA, handleUnlink, handleRelink, setShowEventGroupModal]);

  const handleRowClick = useCallback((event: Event) => {
    return (e: React.ChangeEvent<HTMLInputElement>) : void => {
      if (!(e.target instanceof HTMLInputElement))
        navigate(`/view/${event.id}`);
    };
  }, [navigate]);

  const handleViewAsCollection = useCallback(() => {
    const autobrokerEventList = selectedEvents
      ?.map(({ id }) => id)
      .join(',');

    navigate(`/view/collection?autobroker_event_ids=${autobrokerEventList}`);
  }, [selectedEvents, navigate]);

  const actionOptions = ACTION_OPTIONS.filter((option) => {
    if (!(user?.isAdmin)) {
      return option.value !== EditEventsOptions.CREATE_GROUP
        && option.value !== EditEventsOptions.UPDATE_GROUP;
    }
    return true;
  });

  return (
    <>
      {showConfirmationModal && (
        <EditEventConfirmationModal
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          message={message}
          sources={sources}
          title={title}
        />
      )}
      {showEventGroupModal && (
        <GroupModal
          autobrokerEventIds={selectedEvents?.map((e) => e.id)}
          closeModal={closeGroupModal}
          groupLabels={groupLabels}
          groups={groups}
          isUpdate={selectedOption?.value === EditEventsOptions.UPDATE_GROUP}
        />
      )}
      <EditVisibilityModal
        isOpen={isEditingVisibility}
        onClose={closeEditVisibilityModal}
        onSubmit={retry}
        selectedEvents={selectedEvents}
      />
      <EditPriceExplorationModal
        isOpen={isEditingPriceExploration}
        onClose={closeEditPriceExplorationModal}
        onSubmit={retry}
        selectedEvents={selectedEvents}
      />
      <SelectionControls>
        <SmallButton
          isDisabled={selectedEvents?.length === 0}
          kind='secondary'
          label={VIEW_AS_COLLECTION}
          onClick={handleViewAsCollection}
        />
        <EditEventSelect
          isDisabled={disableDropdown}
          onChange={handleSelect}
          options={actionOptions}
          placeholder={TITLE}
          value={selectedOption}
        />
      </SelectionControls>
      <TableWrapper>
        <thead>
          <Header>
            <td>
              <Checkbox
                aria-label='Select all events'
                checked={headerCheckbox}
                disabled={showLoader}
                onChange={headerCheck}
                type='checkbox'
              />
            </td>
            {COLUMNS.slice(1).map((column) => (
              <Cell colSpan={column.colSpan} key={column.label}>{column.label}</Cell>
            ))}
          </Header>
        </thead>
        {showResults && (
          <tbody>
            {filters.from_id && (
              <RowWrapper key="load_previous_rows">
                <td colSpan={COLUMNS.map((column) => column.colSpan)
                  .reduce((a, b) => a + b)}
                >
                  <ButtonWrapper>
                    <Button onClick={prevPage}>{PREVIOUS_PAGE}</Button>
                  </ButtonWrapper>
                </td>
              </RowWrapper>
            )}
            {events.map((event, idx) => (
              <ClickableRowWrapper
                color={checkboxState[idx] ? palette.brand.light24 : null}
                key={`${event.id}`}
                onClick={handleRowClick(event)}
              >
                <Cell key={`bulk_select_${event.id}`}>
                  <Checkbox
                    checked={checkboxState[idx]}
                    onChange={rowCheck(event, idx)}
                    type='checkbox'
                  />
                </Cell>
                <Cell key={`id_${event.id}`}>{event.id}</Cell>
                <Cell key={`event_time_string_${event.id}`} style={{ whiteSpace: 'pre' }}>
                  {`${event.config.eventDateString}\n${event.config.eventTimeString}`}
                </Cell>
                <Cell key={`title_${event.id}`}>{event.title}</Cell>
                <Cell key={`venue_${event.id}`} style={{ whiteSpace: 'pre' }}>{event.venue}</Cell>
                <Cell key={`listing_ct_${event.id}`} title="listings">{formatLargeNumber(event.summary.listingCt)}</Cell>
                <Cell key={`listing_ticket_ct_${event.id}`} title="listed tickets">{formatLargeNumber(event.summary.listingTicketCt)}</Cell>
                <Cell key={`sale_ticket_ct_${event.id}`} title="sold tickets">{formatLargeNumber(event.summary.saleTicketCt)}</Cell>
                <Cell key={`sale_gtv_${event.id}`} title="USD">{formatGtv(event.summary.saleGtv)}</Cell>
                <NoWrapCell key={`properties_${event.id}`}>
                  <EventIcons event={event} />
                </NoWrapCell>
              </ClickableRowWrapper>
            ))}
            {events.length === perPage && (
              <RowWrapper key="load_next_rows">
                <td colSpan={COLUMNS.map((column) => column.colSpan).reduce((a, b) => a + b)}>
                  <ButtonWrapper>
                    <Button onClick={nextPage}>{NEXT_PAGE}</Button>
                  </ButtonWrapper>
                </td>
              </RowWrapper>
            )}
          </tbody>
        )}
      </TableWrapper>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 5000,
          success: {
            style: {
              background: '#ECF8F3',
              color: '#0B6F46',
            },
          },
          error: {
            style: {
              background: '#FDF0F0',
              color: '#BA2626',
            },
          },
        }}
      />
      {showError && (
        <CenterContainer>
          <Error copy={ERROR_COPY} retry={retry} />
        </CenterContainer>
      )}
      {showLoader && (
        <CenterContainer>
          <Loader hexColor={palette.brand.base} />
        </CenterContainer>
      )}
    </>
  );
};

const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  width: 95%;
`;

const SelectionControls = styled.div`
  z-index: 1;
  padding-bottom: 1rem;
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  display: flex;
  justify-content: end;
  gap: 0.5rem;
  width: 100%;
`;

const Checkbox = styled.input`
  height: 24px;
  width: 24px;
  top: 18px;
  left: 15px;

  &:checked {
    background-color: ${palette.brand.base};
  }
  &:after {
    background-color: ${palette.brand.base};
  }
`;

export default EventTable;
export { VIEW_AS_COLLECTION };
