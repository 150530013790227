import { type CumulativeMetricKey } from '#/pages/EventCollectionPage/utils/menuOptions';

enum MapGranularity {
  ROW = 'row',
  SECTION = 'section',
  SECTION_GROUP = 'section_group',
}

type Coord = [number, number];

interface RowData {
  [key: string]: {
    center: Coord;
    points: Coord[][];
    position: number;
  }
}

interface SectionData {
  [key: string]: {
    center: Coord;
    points: Coord[][];
    rows: RowData;
  }
}

interface MapResponse {
  meta: {
    status: number;
  }
  id: string;
  sections: SectionData;
  center: Coord;
  minzoom: number;
  maxzoom: number;
}

type Metrics = {
  [Property in CumulativeMetricKey | 'ingestedTicketCt']?: number;
}

interface MapRollupDatum {
  label: string;
  polygon: Coord[];
  metrics: Metrics;
}

interface MapGeometry {
  center: Coord;
  minZoom: number;
  maxZoom: number;
  sections: SectionData;
}

interface MappedRollup extends MapGeometry {
  mapRollup: MapRollupDatum[];
}

export {
  MapGranularity,
  type Coord,
  type Metrics,
  type MapResponse,
  type MapRollupDatum,
  type MapGeometry,
  type MappedRollup,
};
