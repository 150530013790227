import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  ReactNode,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';
import getEvent from '#/api/getEvent';
import getErrors from '#/api/getErrors';
import getOverrides from '#/api/getOverrides';
import Event, { PriceGranularity } from '#/types/Event';
import formatApiError from '#/api/utils/formatApiError';
import getSelldownProjections from '#/api/getSelldownProjections';

const PRICE_CONSTRAINT_GRANULARITIES = [
  PriceGranularity.EVENT,
  PriceGranularity.SECTION,
  PriceGranularity.ROW,
];

interface ActiveEventProviderProps {
  children?: ReactNode;
  initialValue?: Event;
}

type SetActiveEvent = (id: number) => void;
type SetApiError = (err: string) => void;
interface ActiveEventContextValue {
  activeEvent: Event;
  apiError: string;
  retrieveActiveEvent: (id: number) => void;
  setActiveEvent: SetActiveEvent;
  setApiError: SetApiError;
}

const ActiveEventContext = React.createContext<ActiveEventContextValue | null>(
  null,
);

const ActiveEventProvider: React.FC<ActiveEventProviderProps> = ({
  children,
  initialValue,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const eventId = Number(params.eventId);
  const [activeEvent, _setActiveEvent] = useState(initialValue);
  const [apiError, setApiError] = useState<string>('');
  const isVisible = usePageVisibility();
  const retrieveActiveEvent = useCallback(
    async (id: number) => {
      try {
        const [newActiveEvent, newErrors, eventOverrides, selldown] = await Promise.all(
          [
            getEvent(id),
            getErrors(id),
            getOverrides(id),
            getSelldownProjections(id),
          ],
        );

        if (newActiveEvent && newErrors)
          newActiveEvent.errors = newErrors;

        if (newActiveEvent && eventOverrides) {
          newActiveEvent.overrides = eventOverrides;
          newActiveEvent.hasPriceConstraints = eventOverrides
            .listings
            .some((listing) => (
              PRICE_CONSTRAINT_GRANULARITIES.includes(listing.granularity)
              && listing.is_cascading
              && !!listing.price_constraint
            ));
        }

        if (newActiveEvent && selldown)
          newActiveEvent.selldown = selldown;

        _setActiveEvent(newActiveEvent || initialValue);
      } catch (err) {
        console.error(err);
        const errorString = formatApiError(err);

        setApiError(errorString);
      }
    },
    [initialValue],
  );

  const setActiveEvent: (id: number) => void = useCallback(
    async (id) => {
      navigate(`/view/${id}`, { replace: true });
      await retrieveActiveEvent(id);
    },
    [navigate, retrieveActiveEvent],
  );

  useEffect(() => {
    if (!eventId) {
      _setActiveEvent(initialValue);
      return;
    }
    retrieveActiveEvent(eventId);
  }, [initialValue, eventId, retrieveActiveEvent]);

  // refresh the event data
  useEffect(() => {
    const interval = setInterval(() => {
      if (isVisible)
        retrieveActiveEvent(eventId);
    }, 10000);

    return (): void => clearInterval(interval);
  }, [eventId, retrieveActiveEvent, isVisible]);

  const value = useMemo(
    () => ({
      activeEvent,
      apiError,
      retrieveActiveEvent,
      setActiveEvent,
      setApiError,
    }),
    [activeEvent, apiError, retrieveActiveEvent, setActiveEvent, setApiError],
  );

  return (
    <ActiveEventContext.Provider value={value}>
      {children}
    </ActiveEventContext.Provider>
  );
};

export { ActiveEventContext, ActiveEventProvider };
