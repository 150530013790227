/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import styled from 'styled-components';
import {
  ButtonsWrapper,
  Header,
  InnerWrapper,
  Label,
  Info,
  Input,
  TextArea,
  Content,
  modalMenuProps,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import formatApiError from '#/api/utils/formatApiError';
import Notification from '#/shared/Notification';
import { type Option as StakeholderOption } from '#/pages/useFetchStakeholders';
import useFetchPriceTypes from '#/pages/useFetchPriceTypes';
import {
  Deal,
  DealTerm,
  DealTermType,
  DealTermGranularity,
  DealTermRevenueShare,
  DealTermRevenueShareBasis,
  PostDealTermBody,
} from '#/types/Deal';
import Stakeholder, { PriceConstraintPolicyType } from '#/types/Stakeholder';
import type { GenericOption, GenericOptionWithId } from '#/types/GenericOption';
import patchDealTerm from '#/api/patchDealTerm';
import postDealTerm from '#/api/postDealTerm';
import deleteDealTerm from '#/api/deleteDealTerm';
import { PRICE_CONSTRAINT_POLICIES } from './StakeholderModal';
import getRevenueShares from './utils/getRevenueShares';
import getNameForDealTermType from './utils/getNameForDealTermType';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '../useSetupModal';
import { updatePriceTypes } from '#/api/patchPriceTypes';

const CREATE_TITLE = 'Create New Deal Term';
const UPDATE_TITLE = 'Update Deal Term';
const MAP_DEAL_TERM_TITLE = 'Map Deal Term';
const DEAL = 'Deal';
const DEAL_TERM = 'Deal Term';
const NAME = 'Name';
const DESCRIPTION = 'Description';
const DEAL_TERM_TYPE = 'Deal Term Type';
const MAP_PRICE_TYPES = 'Map Price Type(s)';
const PRICE_TYPES = 'Price Type(s)';
const GRANULARITY = 'Granularity';
const REVENUE_SHARE_BASIS = 'Revenue Share Basis';
const PRIORITY = 'Priority';
const PRICE_CONSTRAINT_POLICY = 'Price Constraint Policy';
const PRICE_CONSTRAINT_POLICY_DEFAULT_SCALE = 'Default Price Constraint Scale';
const STAKEHOLDER = 'Stakeholder';
const REVENUE_SHARES = 'Revenue Shares';
const THRESHOLD = 'Threshold';
const SIM_REVENUE_SHARE = 'SGIQ Revenue Share';
const ADD = '+';
const REMOVE = '-';
const SUBMIT = 'Submit';
const CANCEL = 'Cancel';
const DELETE = 'Delete';

const dealTermTypeOptions = [
  DealTermType.CONSIGNMENT,
  DealTermType.DIRECT_BUY,
  DealTermType.REVENUE_SHARE,
  DealTermType.MANAGEMENT_FEE,
].map((dtt) => ({ value: dtt, label: dtt }));

const granularityOptions = [
  DealTermGranularity.AGGREGATE,
  DealTermGranularity.PER_TICKET,
].map((g) => ({ value: g, label: g }));

const revenueShareBasisOptions = Object.values(DealTermRevenueShareBasis)
  .map((rs) => ({ value: rs, label: rs }));

interface DealTermModalProps {
  isUpdate: boolean;
  isMapDealTerm: boolean;
  closeModal: () => void;
  stakeholderLabels: StakeholderOption<string>[];
  stakeholders: Stakeholder[];
  deals: Deal[];
}

const DealTermModal: React.FC<DealTermModalProps> = ({
  isUpdate,
  isMapDealTerm,
  closeModal,
  stakeholders,
  deals,
}) => {
  const [stakeholder, setStakeholder] = useState<Stakeholder>(null);
  const [associatedStakeholder, setAssociatedStakeholder] = useState<Stakeholder>(null);
  const [deal, setDeal] = useState<Deal>(null);
  const [dealTerm, setDealTerm] = useState<DealTerm>(null);
  const [dealTermPostBody, setDealTermPostBody] = useState<PostDealTermBody>({});
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [
    selectedPriceTypeOptions, setSelectedPriceTypeOptions,
  ] = useState<GenericOptionWithId<string, number>[]>([]);
  const [priceConstraintType, setPriceConstraintType] = useState<PriceConstraintPolicyType>(null);
  const {
    priceTypesOptions, alreadyMappedPriceTypesOptions,
  } = useFetchPriceTypes(associatedStakeholder?.name, dealTerm?.id);
  const associatedStakeholderOptions = stakeholders
    .filter((s) => s.peakpassDomainIds.length > 0)
    .map((s) => ({ id: s.id, value: s.id, label: s.displayName }));

  useEffect(() => {
    if (alreadyMappedPriceTypesOptions)
      setSelectedPriceTypeOptions(alreadyMappedPriceTypesOptions);
  }, [alreadyMappedPriceTypesOptions]);

  useEffect(() => {
    if (stakeholder && isMapDealTerm && !associatedStakeholder)
      setAssociatedStakeholder(stakeholder);
  }, [associatedStakeholder, stakeholder, setAssociatedStakeholder, isMapDealTerm]);

  const revenueShares = useMemo(() => (
    (dealTermPostBody.revenueShares ? dealTermPostBody : dealTerm)
      ?.revenueShares
      ?.map((rs, key) => ({ ...rs, key }))
  ), [dealTerm, dealTermPostBody]);

  const handleSelectAssociatedStakeholder = useCallback(
    (event: GenericOptionWithId<string, number>) => {
      const stakeholderId = Number(event?.value) || null;

      if (stakeholderId) {
        const newAssociatedStakeholder = stakeholders.find((s) => s.id === stakeholderId);

        setAssociatedStakeholder(newAssociatedStakeholder);
      }
    }, [stakeholders, setAssociatedStakeholder],
  );

  const handleSelectStakeholder = useCallback((event: GenericOption<string, number>) => {
    const stakeholderId = Number(event?.value) || null;

    if (stakeholderId) {
      const newStakeholder = stakeholders.find((s) => s.id === stakeholderId);

      setStakeholder(newStakeholder);
    }
  }, [stakeholders, setStakeholder]);

  const handleSelectDeal = useCallback((event: GenericOption<string, number>) => {
    const dealId = Number(event?.value) || null;

    if (dealId) {
      const newDeal = deals.find((d) => d.id === dealId);

      setDeal(newDeal);
      if (!isUpdate) {
        setDealTermPostBody((p) => ({
          ...p, dealId,
        }));
      }
    }
  }, [deals, isUpdate]);

  const handleSelectDealTerm = useCallback((event: GenericOption<string, number>) => {
    const dealTermId = Number(event?.value) || null;

    if (dealTermId && deal) {
      const newDealTerm = deal.dealTerms.find((e) => e.id === dealTermId);

      setDealTerm(newDealTerm);
      setPriceConstraintType(newDealTerm?.priceConstraintPolicy?.type);
    }
  }, [deal, setDealTerm, setPriceConstraintType]);

  const handleSelectPriceTypeOptions = useCallback(
    (event: GenericOptionWithId<string, number>[]) => {
      setSelectedPriceTypeOptions(event || []);
    }, [setSelectedPriceTypeOptions],
  );

  const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event?.target?.value;

    if (typeof newName === 'string') {
      setDealTermPostBody((p) => ({
        ...p,
        name: newName,
      }));
    }
  }, [setDealTermPostBody]);

  const handleChangeDescription = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newDescription = event?.target?.value;

    if (typeof newDescription === 'string') {
      setDealTermPostBody((p) => ({
        ...p,
        description: newDescription,
      }));
    }
  }, [setDealTermPostBody]);

  const handleSelectDealTermType = useCallback(
    (event: GenericOption<DealTermType, DealTermType>) => {
      const newDealTermType = event.value;

      setDealTermPostBody((p) => {
        const newPostBody = {
          ...p,
          dealTermType: newDealTermType,
        };

        if (!isUpdate)
          newPostBody.name = getNameForDealTermType(newDealTermType);

        return newPostBody;
      });
    }, [isUpdate, setDealTermPostBody],
  );

  const handleSelectGranularity = useCallback(
    (event: GenericOption<DealTermGranularity, DealTermGranularity>) => {
      const newGranularity = event.value;

      setDealTermPostBody((p) => ({
        ...p,
        granularity: newGranularity,
      }));
    }, [setDealTermPostBody],
  );

  const handleSelectRevenueShareBasis = useCallback(
    (event: GenericOption<DealTermRevenueShareBasis, DealTermRevenueShareBasis>) => {
      const newRevenueShareBasis = event.value;

      setDealTermPostBody((p) => ({
        ...p,
        revenueShareBasis: newRevenueShareBasis,
      }));
    }, [setDealTermPostBody],
  );

  const handleChangePriority = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;

    if (typeof value === 'string') {
      const newPriority = value === '' ? null : Number(value);
      const clearPriority = value ? undefined : true;

      setDealTermPostBody((p) => ({
        ...p,
        priority: newPriority,
        clearPriority,
      }));
    }
  }, [setDealTermPostBody]);

  const cancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleSelectPriceConstraintPolicy = useCallback(
    (event: GenericOption<string, PriceConstraintPolicyType>) => {
      const newType = (event?.value ?? undefined) as PriceConstraintPolicyType;
      const clearPriceConstraintPolicy = newType ? undefined : true;

      setPriceConstraintType(newType);
      setDealTermPostBody((p) => ({
        ...p,
        priceConstraintPolicy: newType ? {
          type: newType,
          defaultScale: dealTerm?.priceConstraintPolicy?.defaultScale ?? 1.0,
        } : undefined,
        clearPriceConstraintPolicy,
      }));
    }, [dealTerm, setDealTermPostBody, setPriceConstraintType],
  );

  const handleChangePriceConstraintPolicyDefaultScale = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value;

      if (typeof value === 'string') {
        const newDefaultScale = value === '' ? null : Number(value);

        setDealTermPostBody((p) => ({
          ...p,
          priceConstraintPolicy: {
            ...dealTerm?.priceConstraintPolicy,
            ...p.priceConstraintPolicy,
            defaultScale: newDefaultScale,
          },
        }));
      }
    }, [dealTerm, setDealTermPostBody],
  );

  const handleChangeRevenueShares = useCallback((
    index: number,
    field: keyof DealTermRevenueShare,
  ) => {
    return (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue = event.target?.value;

      setDealTermPostBody((p) => {
        const newRevenueShares = getRevenueShares(p.revenueShares, dealTerm?.revenueShares);

        newRevenueShares[index][field] = newValue === '' ? null : Number(newValue);
        return {
          ...p,
          revenueShares: newRevenueShares,
        };
      });
    };
  }, [dealTerm, setDealTermPostBody]);

  const addRevenueShare = useCallback(() => {
    setDealTermPostBody((p) => {
      const newRevenueShares = getRevenueShares(p.revenueShares, dealTerm?.revenueShares);
      const threshold = newRevenueShares.length > 0
        ? Math.max(...newRevenueShares.map((rs) => rs.threshold))
        : 0;

      newRevenueShares.push({ threshold, simRevenueShare: 1 });
      return {
        ...p, revenueShares: newRevenueShares,
      };
    });
  }, [dealTerm, setDealTermPostBody]);

  const removeRevenueShare = useCallback(() => {
    setDealTermPostBody((p) => {
      const newRevenueShares = getRevenueShares(p.revenueShares, dealTerm?.revenueShares);

      newRevenueShares.pop();
      return {
        ...p, revenueShares: newRevenueShares,
      };
    });
  }, [dealTerm, setDealTermPostBody]);

  const submitPostDealTerm = useCallback(async () => {
    try {
      setError('');
      let newDealTerm: DealTerm;

      if (isMapDealTerm) {
        await updatePriceTypes(dealTerm.id,
          selectedPriceTypeOptions.map((s) => s.id),
          alreadyMappedPriceTypesOptions.map((pt) => (pt.id)));
      } else if (isUpdate) {
        newDealTerm = await patchDealTerm(dealTerm.id, dealTermPostBody);
      } else { newDealTerm = await postDealTerm(dealTermPostBody); }

      if (isMapDealTerm || newDealTerm.id) {
        setSuccess(true);
        setTimeout(closeModal, 1000);
      }
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [closeModal, isUpdate, isMapDealTerm, dealTerm,
    selectedPriceTypeOptions, alreadyMappedPriceTypesOptions, dealTermPostBody]);

  const deleteCurrentDealTerm = useCallback(async () => {
    try {
      setError('');
      if (isUpdate && dealTerm?.id) {
        await deleteDealTerm(dealTerm.id);
        setSuccess(true);
        setTimeout(closeModal, 1000);
      }
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [closeModal, isUpdate, dealTerm]);

  const modalTitle = useMemo(() => {
    if (isUpdate)
      return UPDATE_TITLE;
    if (isMapDealTerm)
      return MAP_DEAL_TERM_TITLE;
    return CREATE_TITLE;
  }, [isMapDealTerm, isUpdate]);

  useSetupModal(cancel);
  return (
    <Modal
      isOpen
      onRequestClose={cancel}
      style={modalStyles}
    >
      <Header>
        {modalTitle}
      </Header>
      <Notification error={error} success={success} />
      <SectionWrapper>
        <Content>
          <InnerWrapper>
            <Label>{STAKEHOLDER}</Label>
            <Select
              isClearable
              isDisabled={!!dealTerm}
              onChange={handleSelectStakeholder}
              options={
                stakeholders
                  .map((s) => ({ value: s.id, label: s.displayName }))
              }
              {...modalMenuProps}
            />
          </InnerWrapper>
          { stakeholder && (
            <InnerWrapper>
              <Label>{DEAL}</Label>
              <Select
                isClearable
                isDisabled={!!dealTerm}
                onChange={handleSelectDeal}
                options={
                  deals
                    .filter((d) => d.stakeholderId === stakeholder.id)
                    .map((d) => ({ value: d.id, label: `(${d.id}) ${d.displayName}` }))
                }
                {...modalMenuProps}
              />
            </InnerWrapper>
          )}
          {
            (isUpdate || isMapDealTerm) && deal && (
              <InnerWrapper>
                <Label>{DEAL_TERM}</Label>
                <Select
                  isClearable
                  onChange={handleSelectDealTerm}
                  options={
                    deal
                      .dealTerms
                      .map((dt) => ({ value: dt.id, label: `(${dt.id}) ${dt.name}` }))
                  }
                  {...modalMenuProps}
                />
              </InnerWrapper>
            )
          }
          {
            (!isUpdate || dealTerm) && (!isMapDealTerm) && (
              <>
                <InnerWrapper>
                  <Label>{DEAL_TERM_TYPE}</Label>
                  <Select
                    defaultValue={
                      dealTerm ? dealTermTypeOptions.find(
                        (dtt) => dtt.value === dealTerm.dealTermType,
                      ) : null
                    }
                    onChange={handleSelectDealTermType}
                    options={dealTermTypeOptions}
                    {...modalMenuProps}
                  />
                </InnerWrapper>
                <InnerWrapper>
                  <Label>{NAME}</Label>
                  <Input
                    onChange={handleChangeName}
                    type='string'
                    value={dealTermPostBody?.name ?? dealTerm?.name ?? ''}
                  />
                </InnerWrapper>
                <InnerWrapper>
                  <Label>{DESCRIPTION}</Label>
                  <TextArea
                    onChange={handleChangeDescription}
                    value={dealTermPostBody?.description ?? dealTerm?.description ?? ''}
                  />
                </InnerWrapper>
                <InnerWrapper>
                  <Label>{GRANULARITY}</Label>
                  <Select
                    defaultValue={
                      dealTerm ? granularityOptions.find(
                        (g) => g.value === dealTerm.granularity,
                      ) : null
                    }
                    onChange={handleSelectGranularity}
                    options={granularityOptions}
                    {...modalMenuProps}
                  />
                </InnerWrapper>
                <InnerWrapper>
                  <Label>{REVENUE_SHARE_BASIS}</Label>
                  <Select
                    defaultValue={
                      dealTerm ? revenueShareBasisOptions.find(
                        (rs) => rs.value === dealTerm.revenueShareBasis,
                      ) : null
                    }
                    onChange={handleSelectRevenueShareBasis}
                    options={revenueShareBasisOptions}
                    {...modalMenuProps}
                  />
                </InnerWrapper>
                <InnerWrapper>
                  <Label>{PRICE_CONSTRAINT_POLICY}</Label>
                  <Select
                    defaultValue={
                      PRICE_CONSTRAINT_POLICIES.find(
                        (pcp) => pcp.value === dealTerm?.priceConstraintPolicy?.type,
                      ) || null
                    }
                    isClearable
                    onChange={handleSelectPriceConstraintPolicy}
                    options={PRICE_CONSTRAINT_POLICIES}
                    {...modalMenuProps}
                  />
                </InnerWrapper>
                {priceConstraintType === PriceConstraintPolicyType.DEFAULT && (
                  <InnerWrapper>
                    <Label>{PRICE_CONSTRAINT_POLICY_DEFAULT_SCALE}</Label>
                    <Input
                      defaultValue={dealTerm?.priceConstraintPolicy?.defaultScale ?? 1.0}
                      onChange={handleChangePriceConstraintPolicyDefaultScale}
                      type='number'
                    />
                  </InnerWrapper>
                )}
                <InnerWrapper>
                  <Label>{PRIORITY}</Label>
                  <Input
                    onChange={handleChangePriority}
                    type='number'
                    // The following expression allows the dealTermPostBody.priority
                    // to take precedence once it has been modified, even if its
                    // modified value is "null". This is necessary to make this Input
                    // behave properly.
                    value={
                      String(
                        'priority' in dealTermPostBody
                          ? dealTermPostBody?.priority
                          : (dealTerm?.priority ?? ''),
                      )
                    }
                  />
                </InnerWrapper>
                <InnerWrapper>
                  <Label>{REVENUE_SHARES}</Label>
                  {revenueShares?.map((rs, index) => (
                    <HorizontalWrapper key={rs.key}>
                      <ColumnWrapper>
                        <div>{THRESHOLD}</div>
                        <SmallInput
                          onChange={handleChangeRevenueShares(index, 'threshold')}
                          type='number'
                          value={rs.threshold ?? ''}
                        />
                      </ColumnWrapper>
                      <ColumnWrapper>
                        <div>{SIM_REVENUE_SHARE}</div>
                        <SmallInput
                          onChange={handleChangeRevenueShares(index, 'simRevenueShare')}
                          type='number'
                          value={rs.simRevenueShare ?? ''}
                        />
                      </ColumnWrapper>
                    </HorizontalWrapper>
                  ))}
                  <HorizontalWrapper>
                    <Button onClick={addRevenueShare}>{ADD}</Button>
                    <Button onClick={removeRevenueShare}>{REMOVE}</Button>
                  </HorizontalWrapper>
                </InnerWrapper>
              </>
            )
          }
          {
            (isMapDealTerm && dealTerm) && (
              <>
                <InnerWrapper>
                  <Label>{MAP_PRICE_TYPES}</Label>
                  <Info>{STAKEHOLDER}</Info>
                  <Select
                    defaultValue={
                      associatedStakeholderOptions.filter((s) => s.id === stakeholder.id)[0]
                    }
                    isClearable
                    onChange={handleSelectAssociatedStakeholder}
                    options={associatedStakeholderOptions}
                    {...modalMenuProps}
                  />
                  <Info>{PRICE_TYPES}</Info>
                  <Select
                    isClearable
                    isMulti
                    onChange={handleSelectPriceTypeOptions}
                    options={priceTypesOptions}
                    value={selectedPriceTypeOptions}
                  />
                </InnerWrapper>
              </>
            )
          }
        </Content>
        <ButtonsWrapper>
          <Button onClick={submitPostDealTerm}>{SUBMIT}</Button>
          <Button onClick={cancel}>{CANCEL}</Button>
          {
            isUpdate
              && dealTerm?.id
              && <Button onClick={deleteCurrentDealTerm}>{DELETE}</Button>
          }
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;

const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-top: 0.5rem;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SmallInput = styled(Input as any)`
  width: 93%;
`;

export default DealTermModal;
