import React, { useMemo, useState, useCallback } from 'react';
import {
  GG,
  ScaleX,
  ScaleY,
  ScaleStroke,
  ScaleStrokeDasharray,
  Labels,
  Zoom,
} from '@graphique/graphique';
import { GeomLine } from '@graphique/geom-line';
import { GeomVLine } from '@graphique/geom-vline';
import styled from 'styled-components';
import { Theme } from '#/shared/graphique';
import { SegmentedControl, SegmentedGroup } from '#/shared/clientReporting/web-platform-components/Controls';
import { formatXTicks } from '../../utils/dataFormatting';
import { Wrapper, GraphiqueWrapper } from '../../sharedGraphComponents/styledComponents';
import {
  ExpectedValueBinSnapshotResponse,
  ExpectedValueBinMeasure,
  RemainingTicketsView,
  SelldownViewMode,
} from '#/types/SelldownByExpectedValue';
import {
  DASH_ARRAYS,
  X_AXIS_LABEL,
  Y_AXIS_LABEL,
  createDivergentColorScale,
} from '../utils';
import { formatNumber, formatPercentage } from '#/shared/formatNumberForDisplay';
import Legend from '../../sharedGraphComponents/Legend';
import useZoom from './useZoom';
import Tooltip from './Tooltip';

interface Props {
  data?: ExpectedValueBinSnapshotResponse
}

const EvTiersSelldown: React.FC<Props> = ({ data }) => {
  const originalGroups = data?.expectedValueBins?.map((b) => b?.label);
  const [remainingTicketsAs, setRemainingTicketsAs] = useState(RemainingTicketsView.PERCENT);
  const [selectedGroups, setSelectedGroups] = useState(originalGroups);

  const selectedData = useMemo(() => (
    data?.formattedData?.filter((d) => selectedGroups.includes(d.group.label))
  ), [selectedGroups, data]);

  const showAsTotal = useMemo(() => (
    remainingTicketsAs === RemainingTicketsView.TOTAL
  ), [remainingTicketsAs]);

  const strokeDomain = useMemo(() => data?.expectedValueBins?.map((bin) => bin?.label), [data]);
  const strokeColors = useMemo(() => {
    const numBins = data?.expectedValueBins?.length;

    return createDivergentColorScale(numBins);
  }, [data]);

  const now = useMemo(() => {
    const mostRecent = data?.formattedData?.filter((d) => d?.isIntersection)?.[0];

    return mostRecent ? [mostRecent] : undefined;
  }, [data]);

  const formatYTicks = useCallback(({ value }: { value: number }) => (
    showAsTotal
      ? formatNumber(value)
      : formatPercentage(value)
  ), [showAsTotal]);

  const handleTicketsRemainingChange = useCallback((v: RemainingTicketsView) => (
    setRemainingTicketsAs(v)
  ), []);

  const handleLegendSelection = useCallback((v: string) => {
    const groups = selectedGroups.includes(v)
      ? selectedGroups.filter((g) => g !== v)
      : [...selectedGroups, v];

    setSelectedGroups(
      groups.length === 0
        ? data.expectedValueBins.map((b) => b.label)
        : groups,
    );
  }, [selectedGroups, data]);

  const {
    xZoomDomain,
    yZoomDomain,
    isZoomedOut,
    onZoom,
    resetZoom,
  } = useZoom(selectedData, showAsTotal);

  return selectedData ? (
    <Wrapper>
      <GraphiqueWrapper>
        <GG
          aes={{
            x: (d): number => d.logHoursToEvent,
            y: (d): number => showAsTotal ? d.ticketsRemaining : d.selldownActual,
            stroke: (d): string => d.group.label,
            strokeDasharray: (d): string => d.measure,
            group: (d): string => `${d.group.label}-${d.measure}`,
          }}
          data={selectedData}
          isContainerWidth
          margin={{ left: 50 }}
        >
          {now && (
            <GeomVLine
              aes={{ key: (d): string => String(d.isIntersection) }}
              data={now}
              showTooltip={false}
              stroke='#aaa'
              strokeDasharray='4,3'
              strokeWidth={1.1}
            />
          )}
          <GeomLine
            brushAction='zoom'
            entrance='data'
            isZoomedOut={isZoomedOut}
            opacity={0.8}
            strokeWidth={3}
          />
          <ScaleX
            format={formatXTicks}
            numTicks={4}
            reverse
          />
          <ScaleY
            format={formatYTicks}
            numTicks={5}
          />
          <ScaleStroke
            domain={strokeDomain}
            values={strokeColors}
          />
          <ScaleStrokeDasharray
            domain={Object.values(ExpectedValueBinMeasure)}
            values={DASH_ARRAYS}
          />
          <Labels
            x={X_AXIS_LABEL}
            y={(
              <HeaderContainer>
                <YTitle>
                  {Y_AXIS_LABEL}
                  <RemainingTicketsSwitchContainer>
                    <SegmentedGroup
                      name='viewType'
                      onChange={handleTicketsRemainingChange}
                      value={remainingTicketsAs}
                    >
                      {Object.values<RemainingTicketsView>(RemainingTicketsView).map((m) => (
                        <SegmentedControl
                          isSmall
                          key={m}
                          value={m}
                        >
                          {m}
                        </SegmentedControl>
                      ))}
                    </SegmentedGroup>
                  </RemainingTicketsSwitchContainer>
                </YTitle>
                <LegendContainer>
                  <Legend
                    onSelection={handleLegendSelection}
                    title={<LegendTitle>{SelldownViewMode.EXPECTED_VALUE}</LegendTitle>}
                  />
                </LegendContainer>
              </HeaderContainer>
            )}
          />
          <Tooltip showAsTotal={showAsTotal} />
          <Zoom
            onUnzoom={resetZoom}
            onZoom={onZoom}
            xDomain={xZoomDomain}
            yDomain={yZoomDomain}
          />
          <Theme />
        </GG>
      </GraphiqueWrapper>
    </Wrapper>
  ) : null;
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const LegendContainer = styled.div`
  align-self: flex-start;
  max-width: 60%;
`;

const LegendTitle = styled.span`
  font-weight: 600;
  align-self: start;
  margin-left: 0.6rem;
`;

const RemainingTicketsSwitchContainer = styled.div`
  width: 150px;
  margin-bottom: 8px;
`;

const YTitle = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export default EvTiersSelldown;
