import React, { useCallback } from 'react';
import Action, { EventType } from '../types';
import { SECTION_SUBSET_TYPE_OPTIONS, SectionSubsetType } from '#/types/Event';
import SelectForm from '../Forms/SelectForm';
import type { GenericOption } from '#/types/GenericOption';

interface SelectProps {
  handleInputChange: React.Dispatch<Action>;
  label: string;
}

const SelectSectionSubsetType: React.FC<SelectProps> = ({
  handleInputChange,
  label,
}) => {
  const handleChange = useCallback(
    (option: GenericOption<string, SectionSubsetType>) => {
      const { value } = option;

      handleInputChange({ type: EventType.SECTION_SUBSET_TYPE, value });
    },
    [handleInputChange],
  );
  const defaultOption = SECTION_SUBSET_TYPE_OPTIONS[0];

  return (
    <SelectForm
      defaultValue={defaultOption}
      label={label}
      onChange={handleChange}
      options={SECTION_SUBSET_TYPE_OPTIONS}
    />
  );
};

export default SelectSectionSubsetType;
