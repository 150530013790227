import React, { ChangeEvent } from 'react';
import Input from './Input';

interface RangeProps {
  min: number;
  max: number;
  step: number;
  value: number | '';
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Range: React.FC<RangeProps> = ({
  min, max, step, value, onChange,
}) => {
  return (
    <Input
      max={max}
      min={min}
      onChange={onChange}
      step={step}
      type='range'
      value={value}
    />
  );
};

export default Range;
