import client from './_client';
import Ingestion, { APIIngestion } from '#/types/Ingestion';
import mapIngestion from './utils/mapIngestion';

const getIngestion = async (id: number): Promise<Ingestion> => {
  const response = await client.get<APIIngestion>(`/proxy/ingestion_listing/${id}`);
  const rawIngestion = response?.data;

  return mapIngestion(rawIngestion);
};

export default getIngestion;
