import { DateTime } from 'luxon';

interface Errors {
  event: EventError[];
  listings: ListingError[];
}

interface EventError {
  id: number;
  errorType: EventErrorType;
  errorLevel: ErrorLevelType;
  errorReason: string;
  errorStatus: ErrorStatus | null;
  createdAt: DateTime;
}

interface ListingError {
  id: number;
  autobrokerEventId: number;
  sourceName: string;
  sourceExternalEventId: string;
  sourceInventoryId: string;
  ingestionListingId: number;
  section: string;
  row: string;
  quantity: number;
  errorType: string;
  errorReason: string;
  errorStatus: ErrorStatus | null;
  updatedAt: DateTime;
  createdAt: DateTime;
}

enum ErrorStatusType {
  NONE = 'none',
  TODO = 'todo',
  INPROGRESS = 'inprogress',
  RESOLVED = 'resolved',
  WONTFIX = 'wontfix',
}

enum ErrorLevelType {
  WARNING = 'warning',
  ERROR = 'error',
  CRITICAL = 'critical',
}

enum EventErrorType {
  ONSALE_MODEL_ERROR = 'onsale_model_error',
  SELLDOWN_MODEL_ERROR = 'selldown_model_error',
  SALES_PERIOD_MODEL_ERROR = 'sales_period_model_error',
  INVENTORY_MISSING_FROM_SEATGEEK_ERROR = 'inventory_missing_from_seatgeek_error',
  INVENTORY_MISSING_EXPECTED_VALUES_ERROR = 'inventory_missing_expected_values_error',
  EVENT_NOT_ACTIVE_ON_SEATGEEK_ERROR = 'event_not_active_on_seatgeek_error',
  TBD_EVENT_START_DATE_ERROR = 'tbd_event_start_date_error',
  MODIFIED_MAP_ERROR = 'modified_map_error',
  SEATGEEK_MARKET_IS_DISABLED_ERROR = 'seatgeek_market_is_disabled_error',
  EVENT_CONFLICT_ERROR = 'event_is_conflicting_error',
  PRICES_AT_BOUNDARY_ERROR = 'prices_at_boundary_error',
  PRICES_AT_CONSTRAINTS_ERROR = 'prices_at_constraints_error',
  PRICES_AT_MAX_ELASTICITY_ERROR = 'prices_at_max_elasticity_error',
  SELLERDIRECT_EVENT_MATCHING_ERROR = 'sellerdirect_event_matching_error',
  SELLERDIRECT_CSV_EXPIRED_ERROR = 'sellerdirect_csv_expired_error',
  PEAKPASS_PRICE_TYPE_ERROR = 'peakpass_price_type_error',
  MISSING_PRICING_POLICY_ERROR = 'missing_pricing_policy_error',
  NON_SGIQ_LOCK_DETECTED_ERROR = 'non_sgiq_lock_detected_error',
}

interface EventErrorName {
  title: string;
  venue: string;
}

interface ErrorValue {
  portfolioValue: number;
  ingestedUnsoldCost: number;
}

interface FormattedEventError {
  autobrokerEventId: string;
  event: EventErrorName;
  eventStartsAt: DateTime;
  eventStartsAtDisplay: string;
  level: ErrorLevelType;
  status: ErrorStatusType;
  type: EventErrorType;
  value: ErrorValue;
  statusUpdated: DateTime | null;
}

interface ErrorStatus {
  id: number;
  userId: number;
  userName: string | null;
  status: ErrorStatusType;
  notes: string;
  createdAt: DateTime;
}

// TODO: Leaving this here for mapIngestion. Remove when moving Ingestion to new axios client
interface APIErrorStatus extends Omit<ErrorStatus, 'createdAt'|'userId'|'userName'> {
  user_id: number;
  user_name: string | null;
  created_at: string;
}

enum ErrorType {
  AUTOBROKER_EVENT_ERRORS = 'autobroker_event_errors',
  INPUT_INVENTORY_ERRORS = 'input_inventory_errors',
  INGESTION_LISTINGS = 'ingestion_listings',
}

interface ErrorReference {
  id: number;
  type: ErrorType;
}

interface PostErrorStatus {
  status: ErrorStatusType;
  notes: string;
  reference: ErrorReference;
}

interface PostErrorStatuses {
  errorStatuses: PostErrorStatus[];
}

interface GenericAPIErrorResponse {
  reason?: string;
  message?: string;
}

export {
  APIErrorStatus,
  EventError,
  EventErrorType,
  FormattedEventError,
  EventErrorName,
  ListingError,
  ErrorLevelType,
  ErrorStatus,
  ErrorStatusType,
  PostErrorStatus,
  PostErrorStatuses,
  ErrorReference,
  ErrorType,
  ErrorValue,
  GenericAPIErrorResponse,
};
export default Errors;
