import { type PriceExploration, StrategyType } from '#/types/Event';
import { startCase } from 'lodash';

const TITLE = 'Price Exploration';
const NOTE_PREFIX = 'Bulk Price Exploration Update';
const APPLY_DEFAULTS = 'Apply default strategy';
const INVALID_BOUNDS_ERROR_MSG = "Lower percentile can't be greater than upper percentile";

const FREQUENCY_STEP = 0.05;

const definedStrategies = Object.values(StrategyType)
  .filter((s) => s !== StrategyType.NONE);

const strategyOptions = definedStrategies.map((strategy) => (
  { value: strategy, label: startCase(strategy) }
));

const PRICE_EXPLORATION_DEFAULTS: Partial<PriceExploration> = {
  strategyType: StrategyType.HEURISTIC,
  frequency: 10,
  lowerPercentile: 0.3,
  upperPercentile: 0.75,
};

export {
  TITLE,
  FREQUENCY_STEP,
  NOTE_PREFIX,
  APPLY_DEFAULTS,
  INVALID_BOUNDS_ERROR_MSG,
  strategyOptions,
  PRICE_EXPLORATION_DEFAULTS,
};
