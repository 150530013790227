import React from 'react';
import styled from 'styled-components';

const NO_DATA = 'No sales data available for this selection.';

const MissingData: React.FC = () => (
  <MissingDataContainer>{NO_DATA}</MissingDataContainer>
);

const MissingDataContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
`;

export default MissingData;
