import { SelldownGraphData } from '#/types/Snapshot';

// This represents the order, bottom to top, that we want the data lines to render
const SELLDOWN_GRAPH_KEYS: Array<keyof SelldownGraphData> = [
  'ticketsAdjusted',
  'ticketsSelldown',
  'ticketsNaive',
  'ticketsTarget',
  'actualUnsoldTickets',
];

enum Graphs {
  CURRENT_STATE_DATA = 'Current State Data',
  LISTINGS = 'Listings',
  PRICE_DISTRIBUTION = 'Price Distribution',
  PRICE_RATIO = 'Price Ratio',
  SELLDOWN = 'Selldown',
  OVERRIDE_LOGS = 'Override Logs',
}

const GRAPH_HASHED_VALUES = Object.values(Graphs).map((g) => g.replaceAll(' ', '-'));

export { Graphs, GRAPH_HASHED_VALUES };
export default SELLDOWN_GRAPH_KEYS;
