import React, { useCallback } from 'react';
import Action, { ConfigType } from '../types';
import SelectForm from '../Forms/SelectForm';
import { SinkLabel } from '#/pages/useFetchSinks';

interface SelectProps {
  sinkLabels: SinkLabel[];
  sinksError: string;
  handleInputChange: React.Dispatch<Action>;
}

const SelectSink: React.FC<SelectProps> = ({
  sinkLabels,
  sinksError,
  handleInputChange,
}) => {
  const integrationType = ConfigType.INTEGRATION_TYPE;
  const handleChange = useCallback(
    (option: SinkLabel) => {
      const { value } = option;

      handleInputChange({ type: integrationType, value });
    },
    [integrationType, handleInputChange],
  );

  return (
    <SelectForm
      error={sinksError}
      label='Source/Sink Options'
      onChange={handleChange}
      options={sinkLabels}
    />
  );
};

export default SelectSink;
