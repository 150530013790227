import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import ShowRangeButton from './ShowRangeButton';

interface InputToggleProps {
  disabled: boolean;
  inputVal: number | '';
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  showRange: boolean;
  toggleShowRange: () => void;
  parameterValue: number;
  error?: string;
}

const InputAndToggleRange: React.FC<InputToggleProps> = ({
  disabled,
  error,
  inputVal,
  onChange,
  showRange,
  toggleShowRange,
  parameterValue,
}) => {
  return (
    <Wrapper>
      <Input
        disabled={disabled}
        hasError={!!error}
        onChange={onChange}
        step="0.01"
        title={error}
        type="number"
        value={inputVal}
      />
      <Parameter>{parameterValue}</Parameter>
      { !disabled
        && (
          <ShowRangeButton
            show={showRange}
            toggleShow={toggleShowRange}
          />
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  z-index: 2;
`;

interface InputProps {
  hasError?: boolean;
}

const Input = styled.input<InputProps>`
  ${({ theme }: { theme: Theme }): string => theme.text4};
  width: 3.125rem;
  height: 1.5rem;
  text-align: center;
  margin-right: 0.75rem;
  border: 1px solid
    ${({ theme, hasError }: { theme: Theme, hasError?: boolean }): string => hasError ? theme.color.border.error : theme.color.border.primary};
  border-radius: 6px;
  transition: all 0.2s ease;
  &:focus {
    outline: none;
    outline-offset: none;
    border-color: ${({ theme }: { theme: Theme }): string => theme.color.border.selected};
  }
`;

const Parameter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 2.5rem;
  height: inherit;
`;

export default InputAndToggleRange;
