import React, { MouseEventHandler } from 'react';
import Error from '#/shared/Error';
import Loading from './Loading';
import Events from './Events';
import Event from '#/types/Event';

const ERROR_COPY = 'Something went wrong retrieving events!';

interface ContentsProps {
  events: Event[];
  retry: () => void;
  showError: boolean;
  showLoader: boolean;
  showResults: boolean;
  toggleOpen: MouseEventHandler<HTMLButtonElement>;
}

const Contents: React.FC<ContentsProps> = ({
  events,
  retry,
  showError,
  showLoader,
  showResults,
  toggleOpen,
}) => {
  if (showError)
    return <Error copy={ERROR_COPY} retry={retry} />;
  if (showLoader)
    return <Loading />;
  if (showResults)
    return <Events events={events} toggleOpen={toggleOpen} />;
  return null;
};

export default Contents;
