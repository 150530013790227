import { Dropdown, Item } from '#/shared/clientReporting/Dropdown';
import React from 'react';
import styled from 'styled-components';
import { Label } from '#/shared/clientReporting/ListBox';

const SELECT_INVENTORY_TYPE = 'Select inventory type';

interface Props {
  isLoading?: boolean;
  onChange: (s: any) => void;
  selected: string;
  options: any;
}

const SelectManifestedInventory: React.FC<Props> = (
  {
    isLoading, onChange, selected, options,
  },
) => {
  return (
    <Wrapper>
      <Dropdown
        aria-label={SELECT_INVENTORY_TYPE}
        isLoading={isLoading}
        items={options}
        onSelectionChange={onChange}
        placeholder={SELECT_INVENTORY_TYPE}
        selectedKey={selected ?? null}
      >
        {(item: { name: string}): JSX.Element => (
          <Item
            key={item.name}
            textValue={item.name}
          >
            <Label maxWidth={180}>{item.name}</Label>
          </Item>
        )}

      </Dropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.3rem;
    margin-left: 0.3rem;
    padding: 0 0.3rem 0 0.6rem;
`;

export default SelectManifestedInventory;
