const DEFAULT_MAX_LISTINGS = 1000;
const DEFAULT_MIN_LISTINGS = 75;
const DEFAULT_P_VISIBLE = 1;
const DEFAULT_CHURN_VISIBILITY_RATE = 1.0;
const DEFAULT_QA_MODE = true;
const DEFAULT_MARKETPLACES: string[] | null = null;

interface DefaultIntegration {
  activatedAt: '';
  autobrokerEventId: number;
  externalEventId: string;
  integrationType: '' | '/listings' | 'oneticket';
  listingMaxCount: number;
  listingMinCount: number;
  pVisible: number;
  churnVisibilityRate: number;
  priceIncrement: number;
  marketplaces: string[] | null;
  qaMode: boolean;
}

const createDefaultIntegration = (eventId: number): DefaultIntegration => {
  return {
    activatedAt: '',
    autobrokerEventId: eventId || null,
    externalEventId: '',
    integrationType: '',
    listingMaxCount: DEFAULT_MAX_LISTINGS,
    listingMinCount: DEFAULT_MIN_LISTINGS,
    pVisible: DEFAULT_P_VISIBLE,
    churnVisibilityRate: DEFAULT_CHURN_VISIBILITY_RATE,
    priceIncrement: undefined,
    marketplaces: DEFAULT_MARKETPLACES,
    qaMode: DEFAULT_QA_MODE,
  };
};

export { DefaultIntegration };
export default createDefaultIntegration;
