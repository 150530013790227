import React from 'react';
import {
  ErrorWrapper, Element, Name, Tag,
} from '#/shared/Card';
import ErrorStatusCard from './ErrorStatusCard';
import Buttons, { EditImg, NotesImg } from './Buttons';
import { ListingError } from '#/types/Errors';

const EDIT_LISTING = 'Edit Listing';
const ADD_NOTES = 'Add Notes';

interface CardProps {
  disabled: boolean;
  listing: ListingError;
  openNotesModal: () => void;
  openEditModal: () => void;
}

const ListingErrorCard: React.FC<CardProps> = ({
  listing,
  openNotesModal,
  openEditModal,
  disabled,
}) => {
  return (
    <>
      <ErrorWrapper disabled={disabled}>
        <Buttons>
          <EditImg onClick={openEditModal} title={EDIT_LISTING} />
          <NotesImg onClick={openNotesModal} title={ADD_NOTES} />
        </Buttons>
        <Tag>{listing.errorType.split('_').join(' ')}</Tag>
        <Element>
          <Name>
            {
              `${listing.sourceName} • event id: ${listing.sourceExternalEventId} • listing id: ${listing.sourceInventoryId}`
            + ` • section: ${listing.section} • row: ${listing.row} • quantity: ${listing.quantity}`
            }
          </Name>
          <Name>{listing.errorReason}</Name>
        </Element>
      </ErrorWrapper>
      {listing.errorStatus !== null && (
        <ErrorStatusCard status={listing.errorStatus} />
      )}
    </>
  );
};

export default ListingErrorCard;
