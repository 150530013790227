import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Ingestion from '#/types/Ingestion';

const SUMMARY = 'Summary';
const SOLD_INGESTED = 'Sold / Ingested';

interface CardProps {
  ingestion: Ingestion;
}

const SummaryCard: React.FC<CardProps> = ({ ingestion }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{SUMMARY}</Name>
      </Element>
      <Element>
        <Name>{SOLD_INGESTED}</Name>
        <Value>{`$${ingestion.gtv.toFixed(2)} / $${ingestion.cost.toFixed(2)}`}</Value>
      </Element>
      <Element>
        {ingestion.recoupment_rate !== null && (
          <Name>{`%${(100 * ingestion.recoupment_rate).toFixed(0)} Recoupment Rate`}</Name>
        )}
        <Name>{`${ingestion.sold_ct}/${ingestion.quantity} Sellthrough Rate`}</Name>
      </Element>
    </NarrowWrapper>
  );
};

export default SummaryCard;
