import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import {
  Title,
  Error,
  ButtonsWrapper,
  Content,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import Ingestion, {
  PostIngestion,
  Uploader,
  SplitsRule,
  StockType,
  TicketAccountPlatformType,
} from '#/types/Ingestion';
import postIngestionListing from '#/api/postIngestionListing';
import formatApiError from '#/api/utils/formatApiError';
import Loader from '#/shared/Loader';
import StakeholderForm from './StakeholderForm';
import EventForm from './EventForm';
import SeatsForm from './SeatsForm';
import FulfillmentForm from './FulfillmentForm';
import NotesForm from './NotesForm';
import Tickets from './Tickets';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';

const TITLE = 'Upload Listing';
const DESCRIPTION = 'Please ensure that all listing details are correct before uploading inventory.';

const DEFAULT_UPLOADER = Uploader.TICKETVAULT;
const DEFAULT_SPLITS_RULE = SplitsRule.DONT_LEAVE_ONE;

const SUBMIT = 'Submit';
const CANCEL = 'Cancel';

interface UploadListingModalProps {
  initial: Ingestion | null;
  closeModal: (refresh: boolean) => void;
}

const UploadListingModal: React.FC<UploadListingModalProps> = ({
  initial,
  closeModal,
}) => {
  const tickets = initial?.tickets.filter((t) => t.expired_at !== null);
  const soldTickets = initial?.tickets.filter((t) => t.expired_at === null) || [];
  const defaultValue: PostIngestion = {
    stakeholder_id: initial?.stakeholder_id || null,
    seatgeek_event_id: initial?.seatgeek_event_id || null,
    uploader: initial?.uploader as Uploader || DEFAULT_UPLOADER,
    uploader_event_id: initial?.uploader_event_id || null,
    section: initial?.section || null,
    row: initial?.row || null,
    quantity: tickets?.length || initial?.quantity || null,
    start_seat: tickets?.length ? Math.min(...tickets.map((t) => t.seat))
      : initial?.start_seat || null,
    end_seat: tickets?.length ? Math.max(...tickets.map((t) => t.seat)) : initial?.end_seat || null,
    stock_type: initial?.stock_type as StockType || null,
    ticket_account_email: initial?.ticket_account_email || null,
    ticket_account_platform: initial?.ticket_account_platform as TicketAccountPlatformType || null,
    cost_per_ticket: initial?.cost_per_ticket || 0.0,
    face_value_per_ticket: initial?.face_value_per_ticket || null,
    commission_per_ticket: initial?.commission_per_ticket || 0.0,
    seatgeek_user_purchase_id: initial?.seatgeek_user_purchase_id || null,
    seatgeek_user_purchase_return_id: initial?.seatgeek_user_purchase_return_id || null,
    tickets: tickets?.map(
      ({
        seat, barcode, wallet_link, paulbunyan_ticket_id, in_hand_at,
      }) => (
        {
          seat, barcode, wallet_link, paulbunyan_ticket_id, in_hand: in_hand_at !== null,
        }
      ),
    ) || null,
    splits_rule: initial?.splits_rule || DEFAULT_SPLITS_RULE,
    splitting_strategy: initial?.splitting_strategy || null,
    deal_term_id: initial?.deal_term_id || null,
    in_hand_date: initial?.in_hand_date || null,
    in_hand: initial?.in_hand || false,
    is_reserved: initial?.is_reserved || false,
    is_unmanifested: initial?.is_unmanifested || null,
    seatgeek_user_listing_id: initial?.seatgeek_user_listing?.id || null,
    seatgeek_bulk_user_listing_id: initial?.seatgeek_user_listing?.bulk_id || null,
    external_notes: initial?.external_notes || null,
  };

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [ingestion, setIngestion] = useState<PostIngestion>(defaultValue);
  const [eventDetail, setEventDetail] = useState<string>(null);
  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const id = await postIngestionListing(ingestion);

      setError(null);
      setIsLoading(false);
      closeModal(true);
      navigate(`/ingestions?id=${id}`);
      navigate(0);
    } catch (err) {
      setError(formatApiError(err));
      setIsLoading(false);
    }
  }, [ingestion, closeModal, navigate]);

  const onCancel = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  useSetupModal(onCancel);

  return (
    <Modal
      isOpen
      onRequestClose={onCancel}
      style={modalStyles}
    >
      <Title>{TITLE}</Title>
      <Description>{DESCRIPTION}</Description>
      <SectionWrapper>
        <Content>
          <StakeholderForm
            ingestion={ingestion}
            setEventDetail={setEventDetail}
            setIngestion={setIngestion}
          />
          <EventForm
            eventDetail={eventDetail}
            ingestion={ingestion}
            setEventDetail={setEventDetail}
            setIngestion={setIngestion}
          />
          <FulfillmentForm
            defaultValue={defaultValue}
            ingestion={ingestion}
            setIngestion={setIngestion}
          />
          <SeatsForm
            ingestion={ingestion}
            setIngestion={setIngestion}
          />
          <Tickets
            ingestion={ingestion}
            setIngestion={setIngestion}
            soldTickets={soldTickets}
          />
          <NotesForm
            ingestion={ingestion}
            setIngestion={setIngestion}
          />
          {error && <Error>{error}</Error>}
        </Content>
        {isLoading
          ? <Loader />
          : (
            <ButtonsWrapper>
              <Button onClick={onSubmit}>{SUBMIT}</Button>
              <Button onClick={onCancel}>{CANCEL}</Button>
            </ButtonsWrapper>
          )}
      </SectionWrapper>
    </Modal>
  );
};

const Description = styled.p``;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 50vh;
`;

export default UploadListingModal;
