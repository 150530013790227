import { useContext, useState } from 'react';
import postReportingRollupPreview from '#/api/postReportingRollupPreview';
import type { RollupProps } from '#/pages/useFetchReportingRollup';
import type { RollupOverride, RollupOverrideParams, RollupPreview } from '#/types/ReportingRollup';
import { CollectionEventsContext } from '../contexts';

interface UseCollectionSalesPreviewProps {
  relativeDateRollupParams: RollupProps
  calendarRollupParams: RollupProps
}

interface Hook {
  handlePreviewOverrides: (overrideParams: RollupOverrideParams[], group?: string) => Promise<void>
  isLoading: boolean
  hasError: boolean
  overridePreviews: OverridePreviews
  setOverridePreviews: React.Dispatch<React.SetStateAction<OverridePreviews>>
}

interface OverridePreviews {
  relativeDateOverridePreviewData: RollupPreview[]
  calendarOverridePreviewData: RollupPreview[]
}

const useCollectionSalesPreview = (
  rollupParams?: UseCollectionSalesPreviewProps,
): Hook => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [overridePreviews, setOverridePreviews] = useState<OverridePreviews>();

  const { selectedFutureEventIds } = useContext(CollectionEventsContext);

  const handlePreviewOverrides = async (
    overrideParams: RollupOverrideParams[], group?: string,
  ): Promise<void> => {
    if (rollupParams && selectedFutureEventIds?.length > 0 && overrideParams?.length > 0) {
      setIsLoading(true);
      const overrides: RollupOverride[] = [
        {
          autobrokerEventIds: selectedFutureEventIds,
          parameters: overrideParams,
        },
      ];

      try {
        const relativeDateOverridePreview = postReportingRollupPreview({
          ...rollupParams.relativeDateRollupParams,
          groups: rollupParams.relativeDateRollupParams.group,
          autobrokerEventId: selectedFutureEventIds,
        }, overrides, group);

        const calendarOverridePreview = postReportingRollupPreview({
          ...rollupParams.calendarRollupParams,
          groups: rollupParams.calendarRollupParams.group,
          autobrokerEventId: selectedFutureEventIds,
        }, overrides, group);

        const [
          relativeDateOverridePreviewData,
          calendarOverridePreviewData,
        ] = await Promise.all([
          relativeDateOverridePreview,
          calendarOverridePreview,
        ]);

        const overridePreviewData = {
          relativeDateOverridePreviewData,
          calendarOverridePreviewData,
        };

        setOverridePreviews(overridePreviewData);
      } catch {
        setHasError(true);
      }
      setIsLoading(false);
    }
  };

  return {
    handlePreviewOverrides,
    isLoading,
    hasError,
    overridePreviews,
    setOverridePreviews,
  };
};

export type { OverridePreviews, Hook };
export default useCollectionSalesPreview;
