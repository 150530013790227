import React, { useCallback } from 'react';
import {
  InnerWrapper,
  Label,
  Input,
  HorizontalWrapper,
} from '#/shared/modalComponents';
import {
  PostIngestion,
} from '#/types/Ingestion';
import Separator from './Separator';

const SECTION = 'Section';
const ROW = 'Row';
const QUANTITY = 'Quantity';
const START_SEAT = 'Start Seat';
const END_SEAT = 'End Seat';

interface SeatsFormProps {
  ingestion: PostIngestion;
  setIngestion: React.Dispatch<React.SetStateAction<PostIngestion>>;
}

const SeatsForm: React.FC<SeatsFormProps> = ({ ingestion, setIngestion }) => {
  const onChangeSection = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const section = event.target?.value || null;

    setIngestion((i) => ({ ...i, section }));
  }, [setIngestion]);

  const onChangeRow = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const row = event.target?.value || null;

    setIngestion((i) => ({ ...i, row }));
  }, [setIngestion]);

  const onChangeQuantity = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = Number(event.target?.value) || null;

    setIngestion((i) => ({ ...i, quantity }));
  }, [setIngestion]);

  const onChangeStartSeat = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const start_seat = Number(event.target?.value) || null;

    setIngestion((i) => ({ ...i, start_seat }));
  }, [setIngestion]);

  const onChangeEndSeat = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const end_seat = Number(event.target?.value) || null;

    setIngestion((i) => ({ ...i, end_seat }));
  }, [setIngestion]);

  return (
    <>
      <InnerWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{SECTION}</Label>
            <Input
              defaultValue={ingestion.section || ''}
              onChange={onChangeSection}
              type="string"
            />
          </Separator>
          <Separator>
            <Label>{ROW}</Label>
            <Input
              defaultValue={ingestion.row || ''}
              onChange={onChangeRow}
              type="string"
            />
          </Separator>
        </HorizontalWrapper>
      </InnerWrapper>
      <InnerWrapper>
        <HorizontalWrapper>
          <Separator>
            <Label>{QUANTITY}</Label>
            <Input
              onChange={onChangeQuantity}
              type="number"
              value={String(ingestion.quantity) || ''}
            />
          </Separator>
          <Separator>
            <Label>{START_SEAT}</Label>
            <Input
              onChange={onChangeStartSeat}
              type="number"
              value={String(ingestion.start_seat) || ''}
            />
          </Separator>
          <Separator>
            <Label>{END_SEAT}</Label>
            <Input
              onChange={onChangeEndSeat}
              type="number"
              value={String(ingestion.end_seat) || ''}
            />
          </Separator>
        </HorizontalWrapper>
      </InnerWrapper>
    </>
  );
};

export default SeatsForm;
