import React from 'react';
import styled from 'styled-components';

import palette from '#/theme/palettes/main';

interface ErrorProps {
  error: string;
  overlayError?: string;
  showGraph: boolean;
}

const Error: React.FC<ErrorProps> = ({ error, overlayError, showGraph }) => {
  const errorPresent = !!error || !!overlayError;
  const showError = errorPresent && showGraph;

  return (
    showError && (
      <>
        <ErrorDiv>{error}</ErrorDiv>
        <ErrorDiv>{overlayError}</ErrorDiv>
      </>
    )
  );
};

const ErrorDiv = styled.div`
  color: ${palette.orange.dark};
`;

export default Error;
