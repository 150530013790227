import { extent } from 'd3-array';
import { FormattedPricingChartDatum } from '../../types';
import { exponentiate } from '../../utils/dataFormatting';

const getSharedXExtent = (
  listingsData: FormattedPricingChartDatum[],
  distributionData: FormattedPricingChartDatum[],
): number[] => {
  const listingsExtent = extent(listingsData, (d) => d.logExpectedValue);
  const areaExtent = extent(
    distributionData
      .filter((d) => d.probability > 0)
      .flatMap((d) => d.priceBand),
    (d) => d.logExpectedValue,
  );

  const shared: [number, number] = extent([...listingsExtent, ...areaExtent]);

  return shared.map(exponentiate);
};

const getSharedYExtent = (
  listingsData: FormattedPricingChartDatum[],
  distributionData: FormattedPricingChartDatum[],
  isPrice: boolean,
): number[] => {
  const yExtent = extent(listingsData, (d) => isPrice ? d.allInPrice : d.logPriceRatio);
  const y0Extent = extent(
    distributionData.flatMap((d) => d.priceBand),
    (d) => isPrice ? d.priceMin : d.logPriceRatioMin,
  );
  const y1Extent = extent(
    distributionData,
    (d) => isPrice ? d.price : d.logPriceRatio,
  );

  return extent([...yExtent, ...y0Extent, ...y1Extent]);
};

export { getSharedXExtent, getSharedYExtent };
