import React from 'react';
import { CollectionLayoutProvider } from './CollectionLayoutContext';
import { CollectionEventsProvider } from './CollectionEventsContext';
import { CollectionOverridesProvider } from './CollectionOverridesContext';
import { CollectionSalesDataProvider } from './CollectionSalesDataContext';
import { CollectionSelectionsProvider } from './CollectionSelectionsContext';

const EventCollectionProviders: React.FC<React.PropsWithChildren> = ({ children }) => (
  <CollectionLayoutProvider>
    <CollectionEventsProvider>
      <CollectionSelectionsProvider>
        <CollectionOverridesProvider>
          <CollectionSalesDataProvider>
            {children}
          </CollectionSalesDataProvider>
        </CollectionOverridesProvider>
      </CollectionSelectionsProvider>
    </CollectionEventsProvider>
  </CollectionLayoutProvider>
);

export default EventCollectionProviders;
