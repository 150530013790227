import client from './_clientFormatted';
import { Settings } from '#/types/Settings';

const getSettings = async (): Promise<Settings> => {
  const response = await client.get('/proxy/settings');

  return response?.data;
};

export default getSettings;
