import PriceDistributionGraphInfo, {
  PriceDistributionGraphData,
} from '#/types/Preview';
import { GraphDomain } from '#/types/Snapshot';

const ZERO = 0;
const ONE = 1;

const DEFAULT_GRAPH_DOMAIN: GraphDomain = { x: [ZERO, ONE], y: [ZERO, ONE] };
const DEFAULT_GRAPH_DATA: PriceDistributionGraphData = {
  autobrokerMedium: [],
  autobrokerNarrow: [],
  autobrokerTarget: [],
  autobrokerWide: [],
  competitorMedium: [],
  competitorNarrow: [],
  competitorTarget: [],
  competitorWide: [],
  modelMedium: [],
  modelNarrow: [],
  modelTarget: [],
  modelWide: [],
};

const defaultData: PriceDistributionGraphInfo = {
  priceDistributionGraphData: DEFAULT_GRAPH_DATA,
  priceDistributionGraphDomain: DEFAULT_GRAPH_DOMAIN,
};

export { DEFAULT_GRAPH_DATA };
export default defaultData;
