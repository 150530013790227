import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import palette from '#/theme/palettes/main';

interface Option<Type> {
  value: Type;
  label: string;
}

function getOption<Type>(value: Type, options: Option<Type>[]): Option<Type> {
  const subset = options.filter((option) => option.value === value);

  if (subset.length > 0)
    return subset[0];

  if (value !== null && typeof value !== 'undefined')
    return { value, label: value.toString() };

  return null;
}

interface FilterSelectProps {
  defaultValue: any;
  onChange: (value: any) => void;
  options: Option<any>[];
  width?: string;
  isMulti?:boolean;
}

const FilterSelect: React.FC<FilterSelectProps> = ({
  defaultValue,
  onChange,
  options,
  width = '9rem',
  isMulti,
}) => {
  return (
  // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    <div style={{ width }}>
      <Select
        defaultValue={defaultValue}
        isClearable
        isMulti={isMulti}
        menuPlacement="auto"
        menuPosition="fixed"
        onChange={onChange}
        options={options}
      />
    </div>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SmallInput = styled.input`
  height: 1.8rem;
  width: 3.5rem;
  border: 1px solid ${palette.silver.dark};
  border-radius: .3rem;
  padding: 4px;
  font-size: 14px;
`;

const LargeInput = styled.input`
  height: 1.8rem;
  width: 8.5rem;
  border: 1px solid ${palette.silver.dark};
  border-radius: .3rem;
  padding: 4px;
  font-size: 14px;
`;

const Checkbox = styled.input`
  height: 1.8rem;
  width: 2rem;

  &:checked {
    background-color: ${palette.brand.base};
  }
  &:after {
    background-color: ${palette.brand.base};
  }
`;

export {
  getOption, FilterSelect, Form, SmallInput, LargeInput, Checkbox,
};
