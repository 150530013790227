import React, { useMemo } from 'react';
import styled from 'styled-components';
import SparklineTraces, { SparklineTracesProps } from './SparklineTraces';
import type { SparklineDatum } from '#/pages/clientReporting/DashboardPage/types';
import LoadingComponent from './LoadingComponent';
import DataWellContainer from './DataWellContainer';
import { formatNumber, getLastValue, formatMeasureText } from './utils';
import { SALES_METRICS } from '#/shared/reporting/accumulationUtils';
import type { SalesSummaryMeasurement, CumulativeMmt } from '#/types/ReportingRollup';
import TakeInfoTooltip from './TakeInfoTooltip';

interface DataWellProps {
  data: SalesSummaryMeasurement[];
  measure: CumulativeMmt;
  isCurrency: boolean;
  loading: boolean;
  loadingComponent?: React.ReactNode;
  onDatumFocus?: (d: SparklineDatum[], i: number[]) => void;
  onDatumUnfocus?: () => void;
  focusedDate?: Date;
  isExtraNarrow?: boolean;
}

const EXTRA_TIX_SOLD_TRACES: CumulativeMmt[] = [SALES_METRICS.managedInventory];
const EXTRA_REV_TRACES: CumulativeMmt[] = [
  SALES_METRICS.soldFaceValue,
  SALES_METRICS.totalFaceValue,
];

const DataWell: React.FC<DataWellProps> = ({
  data,
  measure,
  isCurrency,
  loading,
  onDatumFocus,
  onDatumUnfocus,
  focusedDate,
  loadingComponent = <LoadingComponent />,
  isExtraNarrow,
}) => {
  const value = useMemo(() => data ? getLastValue(data, measure) : undefined, [data, measure]);
  const sharedProps: SparklineTracesProps = {
    data,
    focusedDate,
    isExtraNarrow,
    loading,
    measure,
    onDatumFocus,
    onDatumUnfocus,
  };
  const isTake = useMemo(() => measure === SALES_METRICS.take, [measure]);

  return (
    <DataWellContainer>
      <MeasureContainer>
        <MeasureLabel>
          {formatMeasureText(measure)}
          {isTake && <TakeInfoTooltip />}
        </MeasureLabel>
        { loading ? loadingComponent : (
          <NumberWrapper value={value}>
            {formatNumber(value ?? 0, isCurrency)}
          </NumberWrapper>
        )}
      </MeasureContainer>
      {
        measure === SALES_METRICS.ticketsSold && (
          <SparklineTraces
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sharedProps}
            additionalTraces={EXTRA_TIX_SOLD_TRACES}
          />
        )
      }
      {
        measure === SALES_METRICS.revenue && (
          <SparklineTraces
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sharedProps}
            additionalTraces={EXTRA_REV_TRACES}
          />
        )
      }
      {
        isTake && (
          <SparklineTraces
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sharedProps}
          />
        )
      }
    </DataWellContainer>
  );
};

const MeasureContainer = styled.div`
  padding: 1px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4px 0;
`;

const MeasureLabel = styled.div`
  letter-spacing: 1px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  gap: 2px;
`;

interface NumberProps {
  theme: Theme;
  value: number;
}

const NumberWrapper = styled.div<NumberProps>`
  font-weight: 750;
  font-size: 2rem;
  color: ${({ theme, value }): string => value < 0 ? theme.palette.red.dark : undefined }
`;

export { DataWellProps };
export default DataWell;
