import React, { JSX } from 'react';
import styled from 'styled-components';
import { DateTime, Interval, FixedOffsetZone } from 'luxon';
import { SalesPeriod } from '#/types/Event';
import { Cell, RowWrapper } from '#/shared/TableComponents';
import { GreenEllipse, YellowEllipse, RedEllipse } from '#/shared/Ellipse';

const COLUMNS = ['Type', 'Starts At', 'Ends At', 'Duration', 'Demand Prior', 'Access Codes', '', 'Status'];

interface SalesPeriodRowProps {
  period: SalesPeriod;
  seatgeekEventId: number;
  timezone?: FixedOffsetZone;
}

const SalesPeriodRow: React.FC<SalesPeriodRowProps> = ({ period, seatgeekEventId, timezone }) => {
  const url = `https://seatgeek.com/e/events/${seatgeekEventId}`;
  const now = DateTime.utc();
  const {
    id, type, startsAt, endsAt, demandPrior, accessCodes,
  } = period;

  const duration = Interval.fromDateTimes(startsAt, endsAt)
    .toDuration(['days', 'hours'])
    .toFormat('d \'days\' h \'hours\'');
  let icon = <GreenEllipse title="ongoing" />;

  if (now > endsAt)
    icon = <RedEllipse title="past" />;
  else if (now < startsAt)
    icon = <YellowEllipse title="upcoming" />;

  const accessCodeLinks = accessCodes.map((code) => (
    <AccessCodeLink href={`${url}?sgo_ac=${code}`} key={code} rel="noopener" target="_blank">
      {code}
    </AccessCodeLink>
  ));

  return (
    <RowWrapper className='salesPeriod' key={id}>
      <Cell key={`type_${id}`}>{type}</Cell>
      <Cell key={`starts_at_${id}`}>{formatTimeAndLocalTime(startsAt, timezone)}</Cell>
      <Cell key={`ends_at_${id}`}>{formatTimeAndLocalTime(endsAt, timezone)}</Cell>
      <Cell key={`duration_${id}`}>{duration}</Cell>
      <Cell key={`demand_prior_${id}`}>{demandPrior}</Cell>
      <Cell key={`access_codes_${id}`}>{accessCodeLinks}</Cell>
      <Cell key={`icon_${id}`}>{icon}</Cell>
      <Cell key={`interval_${id}`}>{formatInterval(now, startsAt, endsAt)}</Cell>
    </RowWrapper>
  );
};

function formatTime(time: DateTime): string {
  return time
    .toLocaleString(DateTime.DATETIME_SHORT)
    .replace(',', ' ·') + ' UTC';
}

function formatLocalTime(time: DateTime, timezone: FixedOffsetZone): string {
  const localDateTime = time.setZone(timezone, { keepLocalTime: false });
  const localDate = localDateTime.toLocaleString(DateTime.DATE_SHORT);
  const localTime = localDateTime.toFormat("t 'UTC'Z");

  return `${localDate} · ${localTime}`;
}

function formatTimeAndLocalTime(time: DateTime, timezone?: FixedOffsetZone): JSX.Element {
  return (
    <>
      <DarkText>{formatTime(time)}</DarkText>
      {
        timezone && (
          <>
            <br />
            <LightText>{formatLocalTime(time, timezone)}</LightText>
          </>
        )
      }
    </>
  );
}

function formatInterval(now: DateTime, startsAt: DateTime, endsAt: DateTime): JSX.Element {
  let status = `Starts ${startsAt.toRelative()}`;
  let statusAux;

  if (now > startsAt && now < endsAt) {
    status = `Ends ${endsAt.toRelative()}`;
    statusAux = `Started ${startsAt.toRelative()}`;
  } else if (now > endsAt) {
    status = `Ended ${endsAt.toRelative()}`;
  }

  return (
    <>
      <DarkText>{status}</DarkText>
      {statusAux && (
        <>
          <br />
          <LightText>{statusAux}</LightText>
        </>
      )}
    </>
  );
}

const AccessCodeLink = styled.a`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.link};
  text-decoration: none;
  display: block;
  &:hover {
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.highlight};
  }
`;

const DarkText = styled.span`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
`;
const LightText = styled.span`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.tertiary};
`;

export default SalesPeriodRow;
export { COLUMNS };
