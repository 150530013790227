import { quantize, interpolateRgbBasis } from 'd3-interpolate';

const COLORS = ['#493FB4', '#2E9CF4', '#44BF70', '#F5C52B', '#FF761C', '#B31D06'];

const createDivergentColorScale = (
  numColors?: number, defaultColor = COLORS[2],
): string[] | ((t: number) => string
  ) => {
  const interpolation = interpolateRgbBasis(COLORS);
  const isDiscrete = numColors > 0;

  if (numColors === 1)
    return [defaultColor];

  return isDiscrete ? quantize(interpolation, numColors) : interpolation;
};

export default createDivergentColorScale;
