import React, { useEffect } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import TableWrapper, {
  Cell,
  Header,
  RowWrapper,
  RightSmallCell,
} from '#/shared/TableComponents';
import Loader from '#/shared/Loader';
import Error from '#/shared/Error';
import palette from '#/theme/palettes/main';
import useFetchReportingRollup from '#/pages/useFetchReportingRollup';
import { Rollup, RollupGroupVar } from '#/types/ReportingRollup';
import { formatCurrency, formatGtv } from '#/shared/formatNumberForDisplay';

const ERROR_COPY = 'Something went wrong retrieving ingested listings!';
const COLUMNS = [
  { label: 'Group', colSpan: 1 },
  { label: '', colSpan: 1 },
  { label: 'Counts', colSpan: 1 },
  { label: 'Tickets', colSpan: 1 },
  { label: 'Face Value', colSpan: 1 },
  { label: 'Cost', colSpan: 1 },
  { label: 'Revenue', colSpan: 1 },
  { label: 'Acct Profit', colSpan: 1 },
  { label: 'Gross Profit', colSpan: 1 },
  { label: 'Avg. Face Value', colSpan: 1 },
  { label: 'Avg. Cost', colSpan: 1 },
  { label: 'Avg. Price', colSpan: 1 },
];
const SORT_FIELDS: RollupGroupVar[] = [
  'stakeholder',
  'deal',
  'deal_term',
  'inventory_source_type',
  'marketplace',
];
const HAS_DEAL: RollupGroupVar[] = [
  'deal',
  'deal_term',
];

interface BreakdownTableProps {
  autobrokerEventId?: number;
  groupBy: RollupGroupVar;
  filterHasDealTerm: boolean;
  setFilterHasDealTerm?: (newHasDealTerm: boolean) => void;
}

const BreakdownTable: React.FC<BreakdownTableProps> = ({
  autobrokerEventId, groupBy, filterHasDealTerm, setFilterHasDealTerm,
}) => {
  const group = groupBy ? [groupBy] : undefined;
  const hasDealTerm = !HAS_DEAL.includes(groupBy) && filterHasDealTerm ? true : undefined;
  const {
    rollup, isLoading, hasError, fetchRollup,
  } = useFetchReportingRollup(
    { autobrokerEventId: [String(autobrokerEventId)], group, hasDealTerm },
  );
  const showResults = !!autobrokerEventId && !isLoading && !hasError;
  const showError = !showResults && !isLoading;
  const sortedRollup = SORT_FIELDS.includes(groupBy) ? rollup.sort(rollupOrder) : rollup;

  // when we group by deal or deal term, automatically set `hasDealTerm`
  // if all inventory has a deal term
  useEffect(() => {
    if (
      !isLoading
      && HAS_DEAL.includes(groupBy)
      && rollup.length > 0
      && !!rollup.every((row) => row.group.deal)
    )
      setFilterHasDealTerm(true);
  }, [isLoading, groupBy, rollup, setFilterHasDealTerm]);

  return (
    <>
      {showResults && (
        <TableWrapper>
          <thead>
            <Header>
              {COLUMNS.map((column, i) => (
                <Cell colSpan={column.colSpan} key={column.label} style={{ textAlign: i > 0 ? 'right' : 'left' }}>{column.label}</Cell>
              ))}
            </Header>
          </thead>
          <tbody>
            {sortedRollup.map((row, id) => (
              // eslint-disable-next-line react/jsx-key
              <BreakdownTableRow hasDealTerm={hasDealTerm} id={id} row={row} />
            ))}
          </tbody>
        </TableWrapper>
      )}
      {showError && (
        <CenterContainer>
          <Error copy={ERROR_COPY} retry={fetchRollup} />
        </CenterContainer>
      )}
      {isLoading && (
        <CenterContainer>
          <Loader hexColor={palette.brand.base} />
        </CenterContainer>
      )}
    </>
  );
};

interface BreakdownTableRowProps {
  id: number;
  row: Rollup;
  hasDealTerm?: boolean;
}

const BreakdownTableRow: React.FC<BreakdownTableRowProps> = ({
  id, row, hasDealTerm,
}) => {
  const { group, aggregate } = row;
  const {
    stakeholder, deal, dealTerm, inventorySourceType,
    marketplace, quantity, priceLower, priceUpper, time,
    sectionGroup, section,
  } = group;
  const isFaceValueDefined = aggregate.ingestedFaceValue > 0 && (
    aggregate.ingestedTicketCt === aggregate.ingestedFvTicketCt
  );
  const avgIngestedCost = aggregate.ingestedTicketCt > 0 ? formatCurrency(aggregate.ingestedCost / aggregate.ingestedTicketCt) : '';
  const avgSaleCost = aggregate.saleTicketCt > 0 ? formatCurrency(aggregate.saleCost / aggregate.saleTicketCt) : '';
  const avgIngestedFaceValue = aggregate.ingestedTicketCt > 0 ? formatCurrency(aggregate.ingestedFaceValue / aggregate.ingestedTicketCt) : '';
  const avgSaleFaceValue = aggregate.saleTicketCt > 0 ? formatCurrency(aggregate.saleFaceValue / aggregate.saleTicketCt) : '';
  const unsoldCost = aggregate.ingestedCost - aggregate.saleCost;
  const unsoldTicketCt = aggregate.ingestedTicketCt - aggregate.saleTicketCt;
  const avgUnsoldCost = unsoldTicketCt > 0 ? formatCurrency(-unsoldCost / unsoldTicketCt) : '';
  const avgSalePrice = aggregate.saleTicketCt > 0 ? formatCurrency(aggregate.saleRevenue / aggregate.saleTicketCt) : '';
  const showProfit = deal || hasDealTerm;

  const groupName = [
    stakeholder,
    deal,
    dealTerm,
    inventorySourceType,
    marketplace,
    quantity,
    priceUpper && `${formatCurrency(priceLower)} - ${formatCurrency(priceUpper)}`,
    time && DateTime.fromISO(time, { zone: 'utc' }).toFormat('yyyy-MM-dd'),
    section || sectionGroup,
  ].filter((s) => s).join('\n');

  return (
    <RowWrapper key={`${id}`}>
      <Cell key={`group_${id}`} style={{ whiteSpace: 'pre', maxWidth: '8rem' }}>
        {groupName}
      </Cell>
      <RightSmallCell key={`label_${id}`}>
        {'ingested:\nsold:'}
      </RightSmallCell>
      <RightSmallCell key={`ct_${id}`}>
        {`${aggregate.ingestedCt}\n${aggregate.saleCt}`}
      </RightSmallCell>
      <RightSmallCell key={`ticket_ct_${id}`}>
        {`${aggregate.ingestedTicketCt}\n${aggregate.saleTicketCt}`}
      </RightSmallCell>
      <RightSmallCell key={`fv_${id}`}>
        {isFaceValueDefined ? `${formatGtv(aggregate.ingestedFaceValue)}\n${formatGtv(aggregate.saleFaceValue)}` : ''}
      </RightSmallCell>
      <RightSmallCell key={`cost_${id}`}>
        {`${formatGtv(aggregate.ingestedCost)}\n${formatGtv(aggregate.saleCost)}`}
      </RightSmallCell>
      <RightSmallCell key={`revenue_${id}`}>
        {`${formatGtv(-aggregate.ingestedCost)}\n${formatGtv(aggregate.saleRevenue)}`}
      </RightSmallCell>
      <RightSmallCell key={`acct_profit_${id}`}>
        {showProfit ? `${formatGtv(-unsoldCost)}\n${formatGtv(aggregate.saleAccountingProfitAttr)}` : ''}
      </RightSmallCell>
      <RightSmallCell key={`gross_profit_${id}`}>
        {showProfit ? formatGtv(aggregate.overallGrossProfitAttr) : ''}
      </RightSmallCell>
      <RightSmallCell key={`avg_fv_${id}`}>
        {isFaceValueDefined ? `${avgIngestedFaceValue}\n${avgSaleFaceValue}` : ''}
      </RightSmallCell>
      <RightSmallCell key={`avg_cost_${id}`}>
        {`${avgIngestedCost}\n${avgSaleCost}`}
      </RightSmallCell>
      <RightSmallCell key={`avg_price_${id}`}>
        {`${avgUnsoldCost}\n${avgSalePrice}`}
      </RightSmallCell>
    </RowWrapper>
  );
};

const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const rollupOrder = (a: Rollup, b: Rollup): number => (
  b.aggregate.ingestedTicketCt - a.aggregate.ingestedTicketCt
);

export default BreakdownTable;
