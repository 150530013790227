import React from 'react';
import {
  NarrowWrapper, Element, Name, ScrollBox,
} from '#/shared/Card';

interface CardProps {
  header: string;
  notes: string;
}

const NotesCard: React.FC<CardProps> = ({ header, notes }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{header}</Name>
      </Element>
      <ScrollBox title={notes}>{notes}</ScrollBox>
    </NarrowWrapper>
  );
};

export default NotesCard;
