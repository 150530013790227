import React, { useMemo } from 'react';
import styled from 'styled-components';
import PageWrapper from '#/shared/PageWrapper';
import Header from '#/shared/Header';
import { User } from '#/types/User';
import EventErrorsDetail from './EventErrorsDetail';
import useFetchEventErrors from '../useFetchEventErrors';
import { Option } from '../useFetchStakeholders';

interface Props {
  stakeholderOptions: Option<string>[];
  stakeholderError: string;
  user: User;
}

const ERRORS = 'Errors';

const ErrorsPage: React.FC<Props> = ({ user, stakeholderOptions, stakeholderError }) => {
  const { eventErrors, hasError, retry } = useFetchEventErrors();
  const isLoading = useMemo(() => (!eventErrors && !hasError)
  || (!stakeholderOptions && !stakeholderError),
  [eventErrors, hasError, stakeholderOptions, stakeholderError]);

  return (
    <PageWrapper>
      <Container>
        <Header
          hasSlider={false}
          toggleOpen={null}
          user={user}
        />
      </Container>
      <ScrollBody>
        <Wrapper>
          <>
            <Title>{ERRORS}</Title>
            <EventErrorsDetail
              eventErrors={eventErrors}
              hasError={hasError || !!stakeholderError}
              isLoading={isLoading}
              retry={retry}
              stakeholderOptions={stakeholderOptions}
            />
          </>
        </Wrapper>
      </ScrollBody>
    </PageWrapper>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 2;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.info};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: padding 0.75s ease;
`;

const ScrollBody = styled.div`
  position: absolute;
  width: 100%;
  top: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem;
`;

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

export default ErrorsPage;
