import { formatXTicks } from '#/pages/ViewPage/components/Pricing/utils/dataFormatting';
import {
  formatCurrency, formatGtv, formatLargeNumber, formatPercentage,
} from '#/shared/formatNumberForDisplay';
import { startCase } from 'lodash';
import type { CumulativeMmt } from '#/types/ReportingRollup';
import { TimeUnit } from '#/shared/reporting/accumulationUtils';
import { DateTimeUnit } from 'luxon';
import { isDefined } from './getSalesTrendsByEvent';

type CumulativeMetricKey = CumulativeMmt
| 'cumulativeTicketsUnsoldPercent'
| 'cumulativeAverageTicketPrice'
| 'cumulativeLiftOfSoldPercent'

const TAKE_KEY: CumulativeMetricKey = 'cumulativeTakeAttr';
const LIFT_KEY: CumulativeMetricKey = 'cumulativeLiftOfSoldPercent';
const UNSOLD_PERC_KEY: CumulativeMetricKey = 'cumulativeTicketsUnsoldPercent';
const ATP_KEY: CumulativeMetricKey = 'cumulativeAverageTicketPrice';
const ZERO_BASELINE_METRICS: CumulativeMetricKey[] = [
  TAKE_KEY, LIFT_KEY, UNSOLD_PERC_KEY,
];
const PREVIEWABLE_METRICS: CumulativeMetricKey[] = [
  UNSOLD_PERC_KEY,
  'cumulativeTicketsSold',
  'cumulativeRevenue',
  LIFT_KEY,
  'cumulativeAverageTicketPrice',
];

interface MetricOption {
  key: CumulativeMetricKey
  format?: (
    { value, showDecimal, abbreviate }:
    { value: number; showDecimal?: boolean; abbreviate?: boolean; }) => string
  label?: string
}

const METRIC_OPTIONS: MetricOption[] = [
  {
    key: UNSOLD_PERC_KEY,
    format: ({ value }: { value: number }): string => formatPercentage(value),
    label: 'Tickets Unsold (%)',
  },
  {
    key: 'cumulativeTicketsSold',
    format: ({ value, abbreviate }: { value: number; abbreviate: boolean; }): string => {
      if (!isDefined(value))
        return '';

      return (
        abbreviate
          ? formatLargeNumber(value, false)
          : value.toLocaleString(undefined, { maximumFractionDigits: 1 })
      );
    },
  },
  {
    key: 'cumulativeManagedInventory',
    format: ({ value, abbreviate }: { value: number; abbreviate: boolean; }): string => {
      if (!isDefined(value))
        return '';

      return (
        abbreviate
          ? formatLargeNumber(value, false)
          : value.toLocaleString(undefined, { maximumFractionDigits: 1 })
      );
    },
    label: 'Tickets Ingested',
  },
  { key: 'cumulativeRevenue', format: ({ value }: { value: number }): string => formatGtv(value, false) },
  {
    key: LIFT_KEY,
    format: ({ value }: { value: number }): string => formatPercentage(value),
    label: 'Lift of Sold (%)',
  },
  {
    key: TAKE_KEY,
    format: ({ value }: { value: number }): string => formatGtv(value, false),
    label: 'Take (attr.)',
  },
  {
    key: 'cumulativeAverageTicketPrice',
    format: ({ value, showDecimal = true }: { value: number, showDecimal?: boolean }): string => (
      showDecimal
        ? formatCurrency(value)
        : formatGtv(value, false)
    ),
  },
].map((v) => ({
  label: startCase(v.key).split(' ').slice(1).join(' '),
  ...v,
})) as MetricOption[];

enum TimeMeasurement {
  CALENDAR = 'time',
  TIME_TO_EVENT = 'log_hours_to_event',
}

interface TimeOption {
  key: TimeMeasurement;
  label: string;
  format?: ({ value }: { value: number }) => string
}

const TIME_OPTIONS: TimeOption[] = [
  {
    key: TimeMeasurement.TIME_TO_EVENT,
    label: 'To event',
    format: formatXTicks,
  },
  { key: TimeMeasurement.CALENDAR, label: 'Calendar' },
];

const METRIC_INPUT_LABEL = 'Sales metric';
const TIME_INPUT_LABEL = 'Time measurement';
const TIME_INTERVAL_LABEL = 'Time interval';

enum ManifestOption {
  ALL_TYPES = 'All inventory',
  MANIFESTED_ONLY = 'Manifested only',
  UNMANIFESTED_ONLY = 'Unmanifested only',
}

interface ManifestOptionSelection {
  value?: boolean;
  id: number;
  name: ManifestOption;
}

const MANIFESTED_LABEL = 'Manifested';

const MANIFEST_OPTIONS: ManifestOptionSelection[] = [
  {
    value: null,
    id: -1,
    name: ManifestOption.ALL_TYPES,
  },
  {
    value: false,
    id: 0,
    name: ManifestOption.MANIFESTED_ONLY,
  },
  {
    value: true,
    id: 1,
    name: ManifestOption.UNMANIFESTED_ONLY,
  },
];

interface TimeIntervalSelection {
  disabled?: boolean;
  value: DateTimeUnit;
  label?: string;
  key: string;
  interval: number;
  max: number;
}

const TIME_INTERVAL_OPTIONS: readonly TimeIntervalSelection[] = Object.freeze([
  {
    value: TimeUnit.WEEK,
    interval: 1,
    key: `1 ${TimeUnit.WEEK}s`,
    label: `${startCase(TimeUnit.WEEK)}s`,
    max: 52,
  },
  {
    value: TimeUnit.DAY,
    interval: 1,
    key: `1 ${TimeUnit.DAY}s`,
    label: `${startCase(TimeUnit.DAY)}s`,
    max: 45,
  },
  {
    value: TimeUnit.HOUR,
    interval: 1,
    key: `1 ${TimeUnit.HOUR}s`,
    label: `${startCase(TimeUnit.HOUR)}s`,
    max: 60,
  },
  {
    value: TimeUnit.MINUTE,
    interval: 15,
    key: `15 ${TimeUnit.MINUTE}s`,
    max: 480,
  },
]);

export {
  METRIC_OPTIONS,
  METRIC_INPUT_LABEL,
  TIME_OPTIONS,
  TIME_INPUT_LABEL,
  TimeMeasurement,
  TAKE_KEY,
  LIFT_KEY,
  ATP_KEY,
  ZERO_BASELINE_METRICS,
  PREVIEWABLE_METRICS,
  MANIFESTED_LABEL,
  ManifestOption,
  ManifestOptionSelection,
  MANIFEST_OPTIONS,
  TimeIntervalSelection,
  TIME_INTERVAL_OPTIONS,
  TIME_INTERVAL_LABEL,
  type CumulativeMetricKey,
  type MetricOption,
  type TimeOption,
};
