import React, {
  useCallback, useContext, useEffect, useMemo, useRef,
} from 'react';
import styled from 'styled-components';
import type { RollupGroups } from '#/types/ReportingRollup';
import palette from '#/theme/palettes/main';
import type { EventTimeSeriesSalesSummary } from './utils/getSalesTrendsByEvent';
import { COLLECTION_SUMMARY_HEIGHT, SIDEBAR_WIDTH_PX, TOP_OFFSET_PX } from './constants';
import Skeleton from '#/pages/clientReporting/DashboardPage/components/SkeletonBar';
import { useNavigate } from 'react-router-dom';
import { noop } from 'lodash';
import { CollectionEventsContext } from './contexts';
import InfoTooltip from '#/shared/InfoTooltip';
import ChevronRight from '#images/ChevronRight.svg';
import Future from '#images/Future.svg';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import SVG from 'react-inlinesvg';
import EventDetails from './EventDetails';
import scrollToEvent from './utils/scrollToEvent';

const SELECT_ALL_LABEL = '(Un)select all events';
const NUM_EVENTS_LIMIT = 50;

interface EventCollectionListProps {
  isLoading?: boolean
  autobrokerEvents: RollupGroups[]
  focusedDatum?: EventTimeSeriesSalesSummary[]
  onEventFocus: (event: RollupGroups) => void
  onExit: () => void
  totalEventCt?: number
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  resetOverridePreviews: () => void
  datumSelectedEventIndex?: number
  hasPreviewOverridesLoading?: boolean
}

const LoadingSkeleton: React.FC = () => (
  <div data-testid='event-collection-event-skeleton'>
    {Array.from(Array(12)).map((_, i) => (
      <SkeletonContainer key={String(i + 1)}>
        <Skeleton height='4rem' />
      </SkeletonContainer>
    ))}
  </div>
);

const EventCollectionList: React.FC<EventCollectionListProps> = ({
  isLoading, autobrokerEvents = [], focusedDatum, onEventFocus, onExit, hasPreviewOverridesLoading,
  totalEventCt, page, setPage, resetOverridePreviews, datumSelectedEventIndex,
}) => {
  const handleEventFocus = useCallback((event: RollupGroups) => ((): void => {
    if (!hasPreviewOverridesLoading)
      onEventFocus(event);
  }), [onEventFocus, hasPreviewOverridesLoading]);

  const navigate = useNavigate();

  const eventsRef = useRef<HTMLDivElement>(null);

  const {
    selectedEventIds, selectEvents, selectAllEvents, allEventsSelected,
    futureEventIds, selectedFutureEventIds,
  } = useContext(CollectionEventsContext);

  const handleSelectAll = useCallback(() => {
    selectAllEvents();
    resetOverridePreviews();
  }, [selectAllEvents, resetOverridePreviews]);

  const handleEventSelection = useCallback((autobrokerEventId: number) => (
    (e: React.MouseEvent<HTMLDivElement>): void => {
      e.stopPropagation();
      selectEvents([autobrokerEventId]);

      // reset the preview if selecting a future event
      if (futureEventIds?.includes(autobrokerEventId))
        resetOverridePreviews();

      if (selectedEventIds.includes(autobrokerEventId))
        onExit();
    }), [
    selectEvents, selectedEventIds, futureEventIds, onExit, resetOverridePreviews,
  ]);

  const handleEventNavigation = useCallback(({ autobrokerEventId }: RollupGroups) => (
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      if (e.shiftKey) {
        selectEvents([autobrokerEventId]);

        // reset the preview if selecting a future event
        if (futureEventIds?.includes(autobrokerEventId))
          resetOverridePreviews();

        if (selectedEventIds.includes(autobrokerEventId))
          onExit();
      } else { navigate(`/view/${autobrokerEventId}`); }
    }
  ), [selectEvents, navigate, selectedEventIds, onExit, resetOverridePreviews, futureEventIds]);

  const selectAllFutureEvents = useCallback(() => {
    // reset the preview if adding future events to selection
    if (selectedFutureEventIds?.length < futureEventIds?.length)
      resetOverridePreviews();

    selectEvents(futureEventIds, true);

    // scroll to first future event in list when selecting
    const firstFutureEventIndex = autobrokerEvents?.findIndex(({ autobrokerEventId: id }) => (
      id === futureEventIds[0]
    ));

    scrollToEvent(eventsRef.current, firstFutureEventIndex);
  }, [
    selectEvents, futureEventIds, selectedFutureEventIds,
    resetOverridePreviews, autobrokerEvents,
  ]);

  // scroll to line-selected event
  useEffect(() => {
    if (datumSelectedEventIndex >= 0)
      scrollToEvent(eventsRef.current, datumSelectedEventIndex);
  }, [datumSelectedEventIndex]);

  const sortedEvents = useMemo(() => (
    autobrokerEvents
      ?.sort((a, b) => {
        const diff = Number(a.eventStartsAt) - Number(b.eventStartsAt);

        if (diff !== 0)
          return diff;

        return a?.eventPartition?.sources?.[0] < b?.eventPartition?.sources?.[0] ? 1 : -1;
      })
  ), [autobrokerEvents]);

  const maxPage = useMemo(() => Math.floor((totalEventCt - 1) / NUM_EVENTS_LIMIT), [totalEventCt]);
  const startIndex = useMemo(() => page * NUM_EVENTS_LIMIT + 1, [page]);
  const endIndex = useMemo(() => (
    Math.min((page + 1) * NUM_EVENTS_LIMIT, totalEventCt)
  ), [page, totalEventCt]);
  const selectionLabel = useMemo(() => {
    let label = `${totalEventCt} event${totalEventCt > 1 ? 's' : ''} in collection`;

    if (maxPage > 0 && allEventsSelected)
      label = `${startIndex}-${endIndex} of ${label}`;
    else if (!allEventsSelected)
      label = `${selectedEventIds.length} of ${label}`;

    return label;
  }, [selectedEventIds, allEventsSelected, totalEventCt, startIndex, endIndex, maxPage]);

  const nextPage = useCallback(() => {
    setPage((p) => Math.min(p + 1, maxPage));
    resetOverridePreviews();
  }, [setPage, maxPage, resetOverridePreviews]);
  const prevPage = useCallback(() => {
    setPage((p) => Math.max(p - 1, 0));
    resetOverridePreviews();
  }, [setPage, resetOverridePreviews]);

  // set select all to indeterminate when it should be
  const selectAllRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const isIndeterminate = selectedEventIds?.length > 0 && !allEventsSelected;

    if (selectAllRef.current)
      selectAllRef.current.indeterminate = isIndeterminate;
  }, [selectedEventIds, allEventsSelected]);

  return (
    <EventCollectionListContainer>
      <EventCollectionSummaryContainer>
        {autobrokerEvents.length > 0 && (
          <EventCollectionSummary>
            <SelectionInfo>
              <SelectAllContainer>
                <Checkbox
                  checked={allEventsSelected}
                  disabled={isLoading || hasPreviewOverridesLoading}
                  isLoading={isLoading}
                  onChange={handleSelectAll}
                  ref={selectAllRef}
                  title={SELECT_ALL_LABEL}
                  type="checkbox"
                />
              </SelectAllContainer>
              {!isLoading && selectionLabel}
              {!isLoading && maxPage > 0 && (
                <InfoTooltip
                  ariaLabel='collections-tooltip'
                  closeDelay={80}
                  delay={80}
                >
                  {`Warning: Only displaying events ${startIndex}-${endIndex} of ${totalEventCt} events in collection to improve performance.`}
                </InfoTooltip>
              )}
            </SelectionInfo>
            <SelectionAdditionalControls>
              {!isLoading && futureEventIds?.length > 0 && (
                <SmallButton
                  isDisabled={hasPreviewOverridesLoading}
                  kind='secondary'
                  label={<SVG height={16} src={Future} width={16} />}
                  onClick={selectAllFutureEvents}
                  padding='0.35rem'
                  title='Select all future events'
                />
              )}
              {!isLoading && page > 0 && <PrevPage onClick={prevPage} src={ChevronRight} />}
              {!isLoading && page < maxPage && <NextPage onClick={nextPage} src={ChevronRight} />}
            </SelectionAdditionalControls>
          </EventCollectionSummary>
        )}
      </EventCollectionSummaryContainer>
      <Events ref={eventsRef}>
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          sortedEvents
            .map((event) => {
              const isControlFocused = (
                event.autobrokerEventId === focusedDatum?.[0]?.autobrokerEvent?.autobrokerEventId
              );

              const isChecked = selectedEventIds.includes(event.autobrokerEventId);
              const isInFuture = futureEventIds?.includes(event.autobrokerEventId);

              return (
                <StyledButton
                  disabled={hasPreviewOverridesLoading}
                  isFocused={isControlFocused && !hasPreviewOverridesLoading}
                  isInFuture={isInFuture}
                  isInSelection={isChecked}
                  key={event.autobrokerEventId}
                  onClick={handleEventNavigation(event)}
                  onFocus={handleEventFocus(event)}
                  onMouseLeave={onExit}
                  onMouseOver={handleEventFocus(event)}
                  type='button'
                >
                  <EventListItem>
                    <SelectEventContainer
                      onClick={handleEventSelection(event.autobrokerEventId)}
                    >
                      <Checkbox
                        checked={isChecked}
                        disabled={hasPreviewOverridesLoading}
                        onChange={noop}
                        type='checkbox'
                      />
                    </SelectEventContainer>
                    <EventDetails event={event} />
                  </EventListItem>
                </StyledButton>
              );
            })
        )}
      </Events>
    </EventCollectionListContainer>
  );
};

const EventCollectionListContainer = styled.div`
  position: absolute;
  width: ${SIDEBAR_WIDTH_PX}px;
  border-right: 1px solid #ddd;
  letter-spacing: 0;
`;

const Events = styled.div`
  position: fixed;
  top: calc(${TOP_OFFSET_PX}px + ${COLLECTION_SUMMARY_HEIGHT});
  width: ${SIDEBAR_WIDTH_PX}px;
  height: calc(100vh - ${TOP_OFFSET_PX}px - ${COLLECTION_SUMMARY_HEIGHT});
  background: #fff;
  overflow-y: scroll;
`;

const EventCollectionSummaryContainer = styled.div`
  position: fixed;
  top: ${TOP_OFFSET_PX}px;
  width: calc(${SIDEBAR_WIDTH_PX}px - 1px);
  display: flex;
  align-items: center;
  height: ${COLLECTION_SUMMARY_HEIGHT};
  border-right: 1px solid #eee;
  border-bottom: 1px solid #ddd;
  background: #f5f5f4;
  z-index: 2;
`;

const EventCollectionSummary = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 0 0.35rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface LoadingProps {
  isLoading?: boolean;
}

const SelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const SelectionInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`;

const SelectionAdditionalControls = styled.div`
  display: flex;
  align-items: center;
`;

const SelectEventContainer = styled.div`
  display: flex;
  padding: 1.25rem 0;
  align-items: center;
`;

const Checkbox = styled.input<LoadingProps>`
  cursor: ${({ isLoading }): string => isLoading ? 'default' : 'pointer'};
  width: 1rem;
  height: 1rem;
  margin-right: 0.65rem;
  :disabled {
    cursor: progress;
  }
  &:checked {
    background-color: ${palette.brand.base};
  }
  &:after {
    background-color: ${palette.brand.base};
  }
`;

const EventListItem = styled.div`
  display: flex;
  align-items: center;
`;

interface StyledButtonProps {
  isFocused?: boolean;
  isInSelection?: boolean;
  isInFuture?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  padding: 0.5rem 0.5rem 0.5rem 0.35rem;
  border-bottom: 1px solid #ddd;
  text-align: left;
  width: 100%;
  cursor: pointer;
  background: ${(props): string => {
    if (props.isFocused)
      return palette.brand.light08;

    return props.isInFuture ? '#f0f0f0' : palette.white.base;
  }};
  opacity: ${(props): number => props.isInSelection ? 1 : 0.5};
  transition: all 0.3s;
  :disabled {
    cursor: progress;
    opacity: 0.65;
    :hover {
      background: ${(props): string => props.isFocused ? palette.brand.light08 : '#fff'};
    }
  }
  :hover {
    background: ${palette.brand.light08};
    outline-color: ${palette.brand.light24};
    border-bottom-color: ${palette.brand.light24},
  }
  :last-child {
    box-shadow: 0 5px 10px rgba(150, 150, 150, 0.1);
  }
`;

const SkeletonContainer = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #ddd;
  :last-child {
    box-shadow: 0 5px 10px rgba(150, 150, 150, 0.1);
  }
`;

const Page = styled.img`
  cursor: pointer;
  height: 20px;
`;

const NextPage = styled(Page as any)`
  right: 0;
`;

const PrevPage = styled(Page as any)`
  right: 25px;
  transform: rotate(180deg);
`;

export default EventCollectionList;
export { type EventCollectionListProps, NUM_EVENTS_LIMIT };
