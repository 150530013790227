import sample from '@stdlib/random-sample';
import { range } from 'd3-array';
import { FormattedPricingChartDatum } from '../../types';

const SAMPLE_LISTINGS_N = 350;
const sampleGroupListings = (
  groupListings: FormattedPricingChartDatum[],
): FormattedPricingChartDatum[] => {
  const sampleN = sample.factory({
    seed: 123, size: SAMPLE_LISTINGS_N, replace: false,
  });

  if (groupListings.length > SAMPLE_LISTINGS_N) {
    const sampleIndices = sampleN(range(groupListings.length));

    return groupListings.filter((_, i) => sampleIndices.includes(i));
  }
  return groupListings;
};

const sampleListings = (listings: FormattedPricingChartDatum[]): FormattedPricingChartDatum[] => {
  const groups = Array.from(new Set(listings.map((l) => l.group)));

  const sampled = groups.flatMap((g) => {
    const groupListings = listings.filter((l) => l.group === g);

    return sampleGroupListings(groupListings);
  });

  return sampled;
};

export { sampleListings, SAMPLE_LISTINGS_N };
