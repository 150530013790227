import React, { useMemo } from 'react';
import {
  Routes, Navigate, Route,
} from 'react-router-dom';
import { parse, stringify } from 'query-string';
import SearchPage from './SearchPage';
import ViewPage from './ViewPage';
import SalesPage from './SalesPage';
import ManagePage from './ManagePage';
import IngestionsPage from './IngestionsPage';
import ErrorsPage from './ErrorsPage';
import ClientReportingLandingPage from './clientReporting/LandingPage';
import renderDashboard from './clientReporting/renderDashboard';
import ClientReportingErrorPage from './clientReporting/ErrorPage';
import ErrorMessagePage from '#/pages/ErrorMessagePage';
import LogoutPage from '#/pages/LogoutPage';
import UsersReportPage from './UsersReportPage';
import { StakeholderData } from './useFetchStakeholders';
import useFetchEvents from './useFetchEvents';
import { User } from '#/types/User';
import ClientReportingPending from './clientReporting/ClientReportingPending';
import EventCollectionPage from './EventCollectionPage';
import useFetchGroups from './useFetchGroups';
import ClientReportingDashboard from './clientReporting/ClientReportingDashboard';

const notFoundMessage = 'Page Not Found';
const authenticatingMessage = 'Authenticating...';
const authErrorMessage = `There was an error authenticating your account. Please try again.
 If the problem persists, contact an administrator.`;
const authPendingMessage = `Your account has been created and is pending verification.
 Please contact an administrator if you have any questions.`;

const reportingPath = '/reporting';
const isLogoutPath = window.location.pathname === '/logout';

interface PagesProps {
  user: User | null;
  stakeholderData?: StakeholderData;
}

const Pages: React.FC<PagesProps> = ({ user, stakeholderData }) => {
  const parsedSearch = parse(window.location.search);
  const reportingSearch: Record<string, any> = {
    ...parsedSearch,
    perspective: undefined,
  };

  const {
    stakeholderOptions,
    stakeholderError,
    hasStakeholders,
    hasSingleStakeholder,
    stakeholderLogos,
  } = useMemo(() => {
    const {
      stakeholderLabels: options,
      stakeholderError: error,
      stakeholderLogos: logos,
    } = stakeholderData;
    const numStakeholders = options?.length;

    return ({
      stakeholderOptions: options,
      stakeholderError: error,
      hasStakeholders: numStakeholders > 0,
      hasSingleStakeholder: numStakeholders === 1,
      stakeholderLogos: logos,
    });
  }, [stakeholderData]);

  const eventsHook = useFetchEvents(user);
  const groupsHook = useFetchGroups();

  if (user === null || isLogoutPath) {
    return (
      <Routes>
        <Route
          element={<LogoutPage user={user} />}
          path='/logout'
        />
        <Route
          element={<ErrorMessagePage message={authErrorMessage} />}
          path='/authorization-error'
        />
        <Route
          element={<ErrorMessagePage message={authPendingMessage} />}
          path='/authorization-pending'
        />
        <Route
          element={<ErrorMessagePage message={authenticatingMessage} />}
          path='/search'
        />
        <Route
          element={<ErrorMessagePage message={authenticatingMessage} />}
          path='/sales'
        />
        <Route
          element={<ErrorMessagePage message={authenticatingMessage} />}
          path='/ingestions'
        />
        <Route
          element={<ErrorMessagePage message={authenticatingMessage} />}
          path='/view/collection'
        />
        <Route
          element={<ErrorMessagePage message={authenticatingMessage} />}
          path='/view/:eventId?'
        />
        <Route
          element={<ErrorMessagePage message={authenticatingMessage} />}
          path='/errors'
        />
        <Route
          element={
            stakeholderError
              ? <ClientReportingErrorPage errorStatus={stakeholderError} />
              : <ClientReportingPending />
          }
          path='/reporting/*'
        />
        <Route
          element={<ErrorMessagePage message={authenticatingMessage} />}
          path='/users'
        />
        <Route
          element={<ErrorMessagePage message={authenticatingMessage} />}
          path='/'
        />
        <Route
          element={<ErrorMessagePage message={notFoundMessage} />}
          path='/*'
        />
      </Routes>
    );
  }

  const reportingRoutes = (
    <>
      <Route
        element={(
          <>
            {
              hasSingleStakeholder
                ? renderDashboard(user, stakeholderOptions[0], stakeholderError)
                : (
                  hasStakeholders && (
                    <ClientReportingLandingPage
                      stakeholderOptions={stakeholderOptions}
                      user={user}
                    />
                  )
                )
            }
            {!hasStakeholders && <ClientReportingPending user={user} />}
            {stakeholderError && <ClientReportingErrorPage user={user} />}
          </>
        )}
        path={reportingPath}
      />
      {!hasSingleStakeholder && (
        <Route
          element={(
            <ClientReportingDashboard
              stakeholderError={stakeholderError}
              stakeholderOptions={stakeholderOptions}
              user={user}
            />
          )}
          path='/reporting/:slug'
        />
      )}
    </>
  );

  // for reporting users, redirect all routes to /reporting
  if (user?.isReporting) {
    return (
      <Routes>
        {reportingRoutes}
        <Route
          element={(
            <>
              <Navigate
                replace
                to={{ pathname: reportingPath, search: stringify(reportingSearch) }}
              />
            </>
          )}
          path='/*'
        />
      </Routes>
    );
  }

  return (
    <Routes>
      {reportingRoutes}
      <Route element={<Navigate replace to='/search' />} path='/' />
      <Route element={<Navigate replace to='/search' />} path='/view' />
      <Route
        element={<SearchPage eventsHook={eventsHook} groupsHook={groupsHook} />}
        path='/search'
      />
      <Route
        element={<EventCollectionPage groupsHook={groupsHook} user={user} />}
        path='/view/collection'
      />
      <Route
        element={(
          <ViewPage
            eventsHook={eventsHook}
            groupsHook={groupsHook}
            stakeholderLogos={stakeholderLogos}
          />
        )}
        path='/view/:eventId?'
      />
      <Route
        element={(
          <SalesPage
            groupsHook={groupsHook}
            stakeholderLogos={stakeholderLogos}
            user={user}
          />
        )}
        path='/sales'
      />
      <Route
        element={<ManagePage groupsHook={groupsHook} user={user} />}
        path='/manage'
      />
      <Route
        element={<IngestionsPage stakeholderLogos={stakeholderLogos} user={user} />}
        path='/ingestions'
      />
      <Route
        element={(
          <ErrorsPage
            stakeholderError={stakeholderError}
            stakeholderOptions={stakeholderOptions}
            user={user}
          />
        )}
        path='/errors'
      />
      <Route
        element={<UsersReportPage user={user} />}
        path='/users'
      />
      <Route
        element={<LogoutPage user={user} />}
        path='/logout'
      />
      <Route
        element={<ErrorMessagePage message={notFoundMessage} />}
        path='/*'
      />
    </Routes>
  );
};

export default Pages;
