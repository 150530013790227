import React from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipContainer, TooltipContent } from '@graphique/graphique';
import { formatTooltipX, formatDecimal } from './utils';
import { isDefined } from '../utils/dataFormatting';
import { FormattedPricingChartDatum } from '../types';

const EMDASH = ' — ';
const ALL_IN_PRICE = 'All in price';
const DISPLAY_PRICE = 'Display price';
const LPR = 'Log price ratio';
const QUANTITY = 'Quantity';
const SELLER = 'Seller ID';
const INVENTORY_SOURCE_TYPE = 'Inventory type';

const renderTooltip = (value: TooltipContent<FormattedPricingChartDatum>[]): React.ReactNode => {
  const {
    datum, group, xLab, formattedX,
  } = value[0];

  const {
    marketplace,
    measure,
    allInPrice,
    displayPrice,
    logPriceRatio,
    quantity,
    sellerId,
    inventorySourceType,
  } = datum?.[0];

  return (
    <TooltipContainer>
      <TooltipWrapper>
        <ValueWrapper>
          <GroupLabel>{group.toLocaleString()}</GroupLabel>
          {EMDASH}
          <ValueLabel>
            &nbsp;
            {(measure ?? marketplace ?? group).toLocaleString()}
          </ValueLabel>
        </ValueWrapper>
        <ValuesContainer>
          <>
            {isDefined(allInPrice) && (
              <ValueWrapper>
                <ValueLabel>{`${ALL_IN_PRICE}: `}</ValueLabel>
                <Value>{formatTooltipX(allInPrice)}</Value>
              </ValueWrapper>
            )}
            {isDefined(displayPrice) && (
              <ValueWrapper>
                <ValueLabel>{`${DISPLAY_PRICE}: `}</ValueLabel>
                <Value>{formatTooltipX(displayPrice)}</Value>
              </ValueWrapper>
            )}
            {isDefined(logPriceRatio) && (
              <ValueWrapper>
                <ValueLabel>{`${LPR}: `}</ValueLabel>
                <Value>{formatDecimal(logPriceRatio)}</Value>
              </ValueWrapper>
            )}
            <ValueWrapper>
              <ValueLabel>{`${xLab}: `}</ValueLabel>
              <Value>{formattedX}</Value>
            </ValueWrapper>
          </>
          <ValueWrapper>
            <ValueLabel>{`${QUANTITY}: `}</ValueLabel>
            <Value>{quantity}</Value>
          </ValueWrapper>
          {sellerId && (
            <ValueWrapper>
              <ValueLabel>{`${SELLER}: `}</ValueLabel>
              <Value>{sellerId}</Value>
            </ValueWrapper>
          )}
          {inventorySourceType && (
            <ValueWrapper>
              <ValueLabel>{`${INVENTORY_SOURCE_TYPE}: `}</ValueLabel>
              <Value>{inventorySourceType}</Value>
            </ValueWrapper>
          )}
        </ValuesContainer>
      </TooltipWrapper>
    </TooltipContainer>
  );
};

interface TooltipProps {
  isPrice: boolean;
}

const DistributionTooltip: React.FC<TooltipProps> = () => (
  <Tooltip
    content={renderTooltip}
    xFormat={formatTooltipX}
  />
);

const TooltipWrapper = styled.div`
  font-size: 1.15em;
  margin-top: 3px;
  margin-bottom: 4px;
`;

const GroupLabel = styled.div`
  font-weight: 600;
  padding-bottom: 3.5px;
  padding-right: 2px;
`;

const ValuesContainer = styled.div`
  padding-left: 2px;
  padding-right: 6px;
`;

const ValueWrapper = styled.div`
  display: flex;
  padding-top: 3px;
`;

const ValueLabel = styled.div`
  padding-right: 3px;
  text-transform: capitalize;
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 1.05em;
  text-transform: capitalize;
`;

export default DistributionTooltip;
