import React from 'react';
import styled from 'styled-components';
import { CurrentStateData } from '#/types/Snapshot';
import Bullet from './Bullet';

interface Props {
  data: CurrentStateData;
}

interface RowProps {
  name: string;
  sgiqVal: number | string;
  cpVal: number | string;
}

const HEADER = 'Competitive intelligence data';
const SGIQ = 'SGIQ';
const CP = 'CP';
const LISTING_COUNT = 'Listing count';
const NORMALIZED_LISTING_COUNT = 'Normalized listing count';
const LOG_PRICE_RATIO = 'Log price ratio';

const noValue: { [n: string]: number | string } = {
  [LISTING_COUNT]: 0,
  [LOG_PRICE_RATIO]: '-',
  [NORMALIZED_LISTING_COUNT]: 0,
};

const Header: React.FC = () => {
  return (
    <thead>
      <tr>
        <th>{HEADER}</th>
        <th>{SGIQ}</th>
        <th>{CP}</th>
      </tr>
    </thead>
  );
};

const Row: React.FC<RowProps> = ({ name, sgiqVal, cpVal }) => {
  return (
    <tr>
      <td>
        <Bullet />
        {name}
      </td>
      <td>{sgiqVal || noValue[name]}</td>
      <td>{cpVal || noValue[name]}</td>
    </tr>
  );
};

const CompetitiveView: React.FC<Props> = ({ data }) => {
  return (
    <Table>
      <Header />
      <tbody>
        <Row
          cpVal={data.competitorListingCount}
          name={LISTING_COUNT}
          sgiqVal={data.abListingCount}
        />
        <Row
          cpVal={data.competitorNormalizedListingCount}
          name={NORMALIZED_LISTING_COUNT}
          sgiqVal={data.abNormalizedListingCount}
        />
        <Row
          cpVal={data.competitorLogPriceRatio}
          name={LOG_PRICE_RATIO}
          sgiqVal={data.abLogPriceRatio}
        />
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 90%;
  thead {
  }
  th {
    ${({ theme }: { theme: Theme }): string => theme.text2};
    padding: 1rem 0 0.5rem;
  }

  td {
    text-align: center;
  }
  td:first-child {
    display: inline-flex;
    align-items: center;
    padding-left: 0.5rem;
  }
  td:first-child,
  th:first-child {
    text-align: left;
  }
`;

export default CompetitiveView;
