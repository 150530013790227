import { AxiosResponse } from 'axios';
import client from './_clientFormatted';

const deleteGroup = async (
  groupName: string,
): Promise<number> => {
  const response: AxiosResponse = await client.delete(`/proxy/group/${groupName}`);

  return response?.status;
};

export default deleteGroup;
