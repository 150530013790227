import React from 'react';
import { Dropdown, Item } from '#/shared/clientReporting/Dropdown';
import { Label } from '#/shared/clientReporting/ListBox';
import { Label as InputLabel } from '#/shared/clientReporting/Inputs';
import { startCase } from 'lodash';
import {
  TAKE_KEY, METRIC_INPUT_LABEL, METRIC_OPTIONS, type CumulativeMetricKey, LIFT_KEY,
} from '../utils/menuOptions';

interface MetricSelectionMenuProps {
  isLoading?: boolean;
  hasDealTerm?: boolean;
  excludeLift?: boolean;
  onChange: (value: CumulativeMetricKey) => void;
  selectedKey: CumulativeMetricKey
}

const MetricSelectionMenu: React.FC<MetricSelectionMenuProps> = ({
  isLoading, hasDealTerm, onChange, selectedKey, excludeLift,
}) => {
  let availableOptions = hasDealTerm
    ? METRIC_OPTIONS
    : METRIC_OPTIONS.filter((option) => option.key !== TAKE_KEY);

  availableOptions = excludeLift
    ? availableOptions.filter((option) => option.key !== LIFT_KEY)
    : availableOptions;

  return (
    <>
      <InputLabel isLoading={isLoading}>{METRIC_INPUT_LABEL}</InputLabel>
      <Dropdown
        aria-label='event-collection-overlaid-metric-selection'
        id='event-collection-overlaid-metric-menu'
        isLoading={isLoading}
        items={availableOptions}
        onSelectionChange={onChange}
        selectedKey={selectedKey}
      >
        {({ key, label }): JSX.Element => (
          <Item
            key={key}
            textValue={startCase(key)}
          >
            <Label maxWidth={180}>
              {label}
            </Label>
          </Item>
        )}
      </Dropdown>
    </>
  );
};

export default MetricSelectionMenu;
