import React from 'react';
import Modal from 'react-modal';
import {
  Error,
  SectionWrapper,
  Title,
  ButtonsWrapper,
  Content,
} from '#/shared/modalComponents';
import Button from './Button';
import modalStyles from './modalStyles';
import useSetupModal from '#/pages/useSetupModal';

const CANCEL = 'Cancel';
const CONFIRM = 'Confirm';

interface ConfirmationModalProps {
  message: string,
  error?: string,
  closeModal: () => void;
  handleSubmit: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  message, error, closeModal, handleSubmit,
}) => {
  useSetupModal(closeModal);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <Content>
        <Title>{message}</Title>
        {error && <Error>{error}</Error>}
      </Content>
      <SectionWrapper>
        <ButtonsWrapper>
          <Button onClick={handleSubmit}>{CONFIRM}</Button>
          <Button onClick={closeModal}>{CANCEL}</Button>
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

export { ConfirmationModalProps };
export default ConfirmationModal;
