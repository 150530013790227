import React from 'react';
import styled from 'styled-components';
import { EventTxSummary } from '#/pages/clientReporting/DashboardPage/types';

interface Props {
  label: string;
  selectedEvent?: EventTxSummary;
}

const AllZeroComponent: React.FC<Props> = ({ label, selectedEvent }) => {
  const missingText = `No ${label.toLowerCase()}`;
  const eventText = selectedEvent?.event ?? selectedEvent?.seatgeekEventId;

  return (
    <Container>
      {eventText && <EventLabel>{eventText}</EventLabel>}
      <Label>{missingText}</Label>
    </Container>
  );
};

const Label = styled.div`
  display: flex;
  justify-content: center;
`;
const EventLabel = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0.9rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 5rem);
  min-height: 98px;
`;

export default AllZeroComponent;
