import styled from 'styled-components';

interface TextInputProps {
  height?: string;
  width?: string;
}

const TextInput = styled.input<TextInputProps>`
  height: ${({ height = '1.6rem' }): string => height};
  width: ${({ width = '6.9rem' }): string => width};
  border: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}`};
  border-radius: .3rem;
  padding: 0.2rem 0.4rem;
  font-size: 14px;
`;

interface LoadingProps {
  isLoading?: boolean;
}

const Label = styled.label<LoadingProps>`
  font-size: 0.85rem;
  font-weight: 550;
  margin: 0 0.3rem 0 0.1rem;
  white-space: nowrap;
  opacity: ${({ isLoading }): number => isLoading ? 0.4 : 1};
`;

export { TextInput, Label };
