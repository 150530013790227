import React, { useState } from 'react';

interface SalesTrendsContextValue {
  hasOverlaidSalesTarget?: boolean
  setHasOverlaidSalesTarget: React.Dispatch<React.SetStateAction<boolean>>
}

const CollectionSalesTrendsContext = React.createContext<SalesTrendsContextValue>(null);

const CollectionSalesTrendsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [hasOverlaidSalesTarget, setHasOverlaidSalesTarget] = useState(true);

  const value: SalesTrendsContextValue = {
    hasOverlaidSalesTarget,
    setHasOverlaidSalesTarget,
  };

  return (
    <CollectionSalesTrendsContext.Provider value={value}>
      {children}
    </CollectionSalesTrendsContext.Provider>
  );
};

export {
  CollectionSalesTrendsContext,
  CollectionSalesTrendsProvider,
  type SalesTrendsContextValue,
};
