import React from 'react';
import styled from 'styled-components';
import {
  NarrowWrapper, Element, Name, ScrollBox,
} from '#/shared/Card';
import { Override } from '#/types/PriceLevel';
import Cascading from '#images/Cascading.svg';
import { formatCurrency } from '#/shared/formatNumberForDisplay';

const OVERRIDE = 'Override';
const PENDING_OVERRIDE = 'Pending Override';
const DELETED = 'Deleted';
const CASCADING = 'Applies to listings within this level';

interface CardProps {
  override: Override;
}

const OverrideCard: React.FC<CardProps> = ({ override }) => {
  const {
    expectedValue, allInPrice, displayPrice, broadcastPrice,
    priceConstraint, isActive, isEmpty, createdAt, userName,
    isCascading,
  } = override;

  return (
    <NarrowWrapper>
      <ScrollBox>
        <Name>{isActive ? OVERRIDE : PENDING_OVERRIDE}</Name>
        {isCascading && <CascadingImg title={CASCADING} />}
        {isEmpty && <Item value={DELETED} />}
        {expectedValue && <Item title="Expected Value" value={`${formatCurrency(expectedValue)}`} />}
        {priceConstraint && (
          <Item
            value={
              (priceConstraint.priceFloor ? `${formatCurrency(priceConstraint.priceFloor)} ≤ ` : '')
              + priceConstraint.priceConstraintType.split('_').join(' ')
              + (priceConstraint.priceCeiling ? ` ≤ ${formatCurrency(priceConstraint.priceCeiling)}` : '')
            }
          />
        )}
        {allInPrice && <Item title="All In Price" value={`${formatCurrency(allInPrice)}`} />}
        {displayPrice && <Item title="Display Price" value={`${formatCurrency(displayPrice)}`} />}
        {broadcastPrice && <Item title="Broadcast Price" value={`${formatCurrency(broadcastPrice)}`} />}
      </ScrollBox>
      <Element>
        <Name title={userName}>
          {`- ${userName}`}
        </Name>
        <Name>{`Created ${createdAt.toRelative()}`}</Name>
      </Element>
    </NarrowWrapper>
  );
};

interface ItemProps {
  title?: string;
  value: string;
}

const Item: React.FC<ItemProps> = ({ title, value }) => {
  return (
    <Name>{title ? `${title}: ${value}` : value}</Name>
  );
};

const CascadingImg = styled.img`
  cursor: pointer;
  content: url(${Cascading});
  height: 16px;
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
`;

export default OverrideCard;
