import { AxiosError } from 'axios';

const NO_REDIRECT_PAGES = ['/logout', '/authorization-error', '/authorization-pending'];

const redirectUnauthorizedToAuth = async (err: AxiosError): Promise<any> => {
  const pathName = window.location.pathname;

  if (err.response?.status === 401 && (!NO_REDIRECT_PAGES.includes(pathName))) {
    window.location.href = '/auth/login';
    await Promise.reject(err);
  }
  return null;
};

export default redirectUnauthorizedToAuth;
