const TITLE = 'Edit Visibility';
const P_VISIBLE = 'Percent Visible';
const MIN_LISTINGS = 'Min Listings';
const MAX_LISTINGS = 'Max Listings';
const N_LISTINGS_STEP = 5;
const NOTE_PREFIX = 'Bulk Visibility Update';
const MIN_MAX_ERROR_MSG = "Min listings can't be greater than max listings";

export {
  TITLE,
  P_VISIBLE,
  MIN_LISTINGS,
  MAX_LISTINGS,
  N_LISTINGS_STEP,
  NOTE_PREFIX,
  MIN_MAX_ERROR_MSG,
};
