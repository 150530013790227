import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import { User } from '#/types/User';
import redirectUnauthorizedToAuth from './utils/redirectUnauthorizedToAuth';

// eslint-disable-next-line complexity
const getCurrentUser = async (): Promise<User> => {
  try {
    const response: AxiosResponse<User> = await client.get('/auth/user');

    return new User(response.data);
  } catch (err) {
    redirectUnauthorizedToAuth(err);
    return null;
  }
};

export default getCurrentUser;
