import { DealTermType } from '#/types/Deal';

function getNameForDealTermType(dealTermType: DealTermType): string {
  switch (dealTermType) {
    case DealTermType.CONSIGNMENT:
      return 'consignment';
    case DealTermType.DIRECT_BUY:
      return 'direct-buy';
    case DealTermType.REVENUE_SHARE:
      return 'rev-share';
    case DealTermType.MANAGEMENT_FEE:
      return 'management-fee';
    default:
      return '';
  }
}

export default getNameForDealTermType;
