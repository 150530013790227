import React from 'react';
import Button from '#/shared/Button';

interface CancelButtonProps {
  handleCancel: () => void;
}

const CancelButton: React.FC<CancelButtonProps> = ({ handleCancel }) => {
  const CANCEL = 'Cancel';

  return <Button onClick={handleCancel}>{CANCEL}</Button>;
};

export default CancelButton;
