import { extent, min } from 'd3-array';
import { Graphlabels, LongData } from '#/types/Snapshot';

// this function is used to improve the experience of mousing over the chart
// and displaying the tooltips. It reduces the granularity of measurements to include only
// 'shared' x values when the other group lines are in the Target range

// it's useful for ensuring:
// 1) there aren't measurements that only include Target inside a "shared" x range
// 2) the other group measurements are reduced to the ones that
// also include Target inside the shared x range

// explicitly extends the Target line to meet where the Remaining/Unsold lines end
const generateConsistentX = (data: LongData[]): LongData[] => {
  const target = Graphlabels.targetLogPriceRatio;
  const remaining = Graphlabels.ticketsRemaining;
  const unsold = Graphlabels.actualUnsoldTickets;

  const groups = Array.from(new Set(data.map((d) => d.group)));

  // don't reduce granularity if we don't need to
  // - just exclude null values if only the Target and Unsold lines are selected
  // - or if the Target line is not available/selected
  const onlyTargetOrUnsold = groups.every((g) => g === target || [target, unsold].includes(g));
  const noTarget = !groups.includes(target);

  if (onlyTargetOrUnsold || noTarget)
    return data.filter((d) => d.x !== null && d.y !== null);

  // use this range to check for where to reduce the x granularity
  const targetRange = extent(
    data.filter((d) => d.group === target), (d) => d.x,
  ) as [number, number];

  const consistentXData = data.filter((d) => {
    const notNull = d.x !== null && d.y !== null;
    const thisXData = data.filter((dd) => d.x === dd.x);
    const inTargetRange = notNull && d.x >= targetRange[0] && d.x <= targetRange[1];
    const includesTarget = thisXData.map((x) => x.group).includes(target);
    const hasTargetAndShared = includesTarget && thisXData.length > 1;

    // exclude all null values and reduce x granularity by only keeping x values that are:
    // 1) outside the Target x range OR
    // 2) shared between the Target group and the other groups
    return notNull && (!inTargetRange || hasTargetAndShared);
  });

  // find the point used extend the Target line to 'meet'
  // the Remaining/Unsold lines
  const lastRemainingX = min(data.filter((d) => d.y && d.group === remaining), (d) => d.x);
  const lastUnsoldX = min(data.filter((d) => d.y && d.group === unsold), (d) => d.x);
  const meetingPoint = data.find(
    (d) => lastRemainingX
      ? (d.group === remaining && d.x === lastRemainingX)
      : (d.group === unsold && d.x === lastUnsoldX),
  );

  // explicitly include the 'meeting point' in the Target line
  const targetData = [
    { ...meetingPoint, group: target },
    ...consistentXData.filter((d) => d.group === target),
  ];

  // subsitute the Target data that includes the meeting point
  // after reducing the shared x granularity
  const combinedData = [...consistentXData.filter((d) => d.group !== target), ...targetData]
    .sort((a, b) => a.x - b.x);

  return combinedData;
};

export default generateConsistentX;
