import React, {
  useEffect, useState, useMemo, useCallback, useContext, ChangeEvent,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import ReportingContext from '#/pages/clientReporting/ReportingContext';
import modalStyles from '#/shared/modalStyles';
import { TextInput, Label } from '#/shared/clientReporting/Inputs';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import Pill from '#/shared/clientReporting/Pill';
import { SegmentedControl, SegmentedGroup } from '#/shared/clientReporting/web-platform-components/Controls';
import Download from '#/shared/clientReporting/Icons/Download';
import getTransactionsCsv from '#/api/getTransactionsCsv';
import getListingsCsv from '#/api/getListingsCsv';
import {
  Measure,
  CANCEL,
  DOWNLOAD,
  PER_PAGE,
  BY_SALES_DATE,
  BY_EVENT,
  BY_DEAL_OR_TERMS,
  START_DATE,
  END_DATE,
  MEASURES,
} from './constants';
import { formatEventLabel, asUndefined, formatSlugToTitle } from '../../utils';
import { MIN_DATE, TODAY } from '#/shared/clientReporting/utils';
import { DateBounds } from '../../types';
import ChevronRight from '#/shared/clientReporting/Icons/ChevronRight';

interface Props {
  onRequestClose?: () => void;
  onExportStart?: () => void;
  onExportComplete?: () => void;
  isOpen: boolean;
}

const ExportModal: React.FC<Props> = ({
  isOpen, onRequestClose, onExportStart, onExportComplete,
}) => {
  useEffect(() => Modal.setAppElement('#page-container'), []);

  const {
    stakeholder,
    reportingSelection,
    queryParams,
  } = useContext(ReportingContext);
  const [showSelectedEvent, setShowSelectedEvent] = useState(!!reportingSelection?.selectedEvent);
  const [showSelectedDeal, setShowSelectedDeal] = useState(!!reportingSelection?.selectedDeal);
  const [showSelectedDealTerm, setShowSelectedDealTerm] = useState(
    !!reportingSelection?.selectedDealTerm,
  );

  useEffect(() => {
    setShowSelectedEvent(!!reportingSelection?.selectedEvent);
    setShowSelectedDeal(!!reportingSelection?.selectedDeal);
    setShowSelectedDealTerm(!!reportingSelection?.selectedDealTerm);
  }, [reportingSelection]);

  const [measureType, setMeasureType] = useState<Measure>('Sales');
  const isSales = useMemo(() => measureType === 'Sales', [measureType]);
  const [fromEventDate, setFromEventDate] = useState(queryParams?.fromEventDate || '');
  const [toEventDate, setToEventDate] = useState(queryParams?.toEventDate || '');
  const [fromSalesDate, setFromSalesDate] = useState('');
  const [toSalesDate, setToSalesDate] = useState('');

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // disables the download button when there are no date filters and no selected event
  // to discourage very large download sizes
  const downloadDisabled = useMemo(() => {
    const emptyDates = [
      fromEventDate,
      toEventDate,
      fromSalesDate,
      toSalesDate,
    ].every((dateFilter) => !asUndefined(dateFilter));

    return emptyDates && !showSelectedEvent;
  }, [
    fromEventDate,
    toEventDate,
    fromSalesDate,
    toSalesDate,
    showSelectedEvent,
  ]);

  useEffect(() => {
    setFromEventDate(queryParams?.fromEventDate === MIN_DATE ? '' : (queryParams?.fromEventDate ?? ''));
    setToEventDate(queryParams?.toEventDate || '');
  }, [queryParams]);

  const handleMeasureChange = useCallback((m: Measure) => setMeasureType(m), []);
  const handleEventFilterClear = useCallback(() => setShowSelectedEvent(false), []);
  const handleDealFilterClear = useCallback(() => {
    setShowSelectedDeal(false);
    setShowSelectedDealTerm(false);
  }, []);
  const handleDealTermFilterClear = useCallback(() => setShowSelectedDealTerm(false), []);

  const handleDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    switch (name) {
      case DateBounds.EVENT_FROM:
        setFromEventDate(value === MIN_DATE ? '' : value);
        break;
      case DateBounds.EVENT_TO:
        setToEventDate(value);
        break;
      case DateBounds.SALES_FROM:
        setFromSalesDate(value);
        break;
      case DateBounds.SALES_TO:
        setToSalesDate(value);
        break;
      default:
    }
  }, []);

  const closeModal = useCallback(() => {
    setFromEventDate(queryParams?.fromEventDate || '');
    setToEventDate(queryParams?.toEventDate || '');
    setFromSalesDate('');
    setToSalesDate('');
    setShowSelectedEvent(!!reportingSelection?.selectedEvent);
    setShowSelectedDeal(!!reportingSelection?.selectedDeal);
    setShowSelectedDealTerm(!!reportingSelection?.selectedDealTerm);
    onRequestClose();
  }, [
    queryParams,
    reportingSelection,
    onRequestClose,
  ]);

  const selectedEventId = reportingSelection?.selectedEvent?.seatgeekEventId;
  const selectedDealName = reportingSelection?.selectedDeal?.name;
  const selectedDealTermName = reportingSelection?.selectedDealTerm?.name;

  const downloadData = useCallback(() => {
    const resolvedFromEventDate = showSelectedEvent ? undefined : asUndefined(fromEventDate);
    const resolvedToEventDate = showSelectedEvent ? undefined : asUndefined(toEventDate);
    const resolvedEvent = showSelectedEvent ? selectedEventId : undefined;
    const resolvedDeal = showSelectedDeal ? selectedDealName : undefined;
    const resolvedDealTerm = showSelectedDealTerm ? selectedDealTermName : undefined;

    onExportStart();
    if (isSales) {
      getTransactionsCsv(
        {
          stakeholder: stakeholder.value,
          seatgeekEventId: resolvedEvent,
          fromEventDate: resolvedFromEventDate,
          toEventDate: resolvedToEventDate,
          fromDate: asUndefined(fromSalesDate),
          toDate: asUndefined(toSalesDate),
          perPage: PER_PAGE,
          deal: resolvedDeal,
          dealTerm: resolvedDealTerm,
          timeZone,
        },
        onExportComplete,
      );
    } else {
      getListingsCsv(
        {
          stakeholder: stakeholder.value,
          seatgeekEventId: resolvedEvent,
          fromEventDate: resolvedFromEventDate,
          toEventDate: resolvedToEventDate,
          perPage: PER_PAGE,
          deal: resolvedDeal,
          dealTerm: resolvedDealTerm,
          timeZone,
        },
        onExportComplete,
      );
    }
    closeModal();
  }, [
    showSelectedEvent,
    fromEventDate,
    toEventDate,
    selectedEventId,
    showSelectedDeal,
    selectedDealName,
    showSelectedDealTerm,
    selectedDealTermName,
    onExportStart,
    isSales,
    closeModal,
    stakeholder.value,
    fromSalesDate,
    toSalesDate,
    onExportComplete,
    timeZone,
  ]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <Header>
          <Title>{`Export ${measureType}`}</Title>
          <ExportTypeContainer>
            <SegmentedGroup
              name='exportType'
              onChange={handleMeasureChange}
              value={measureType}
            >
              {MEASURES.map((m) => (
                <SegmentedControl
                  key={m}
                  value={m}
                >
                  {m}
                </SegmentedControl>
              ))}
            </SegmentedGroup>
          </ExportTypeContainer>
        </Header>
        <Content>
          <Section>
            <FilterTitle>{BY_EVENT}</FilterTitle>
            <FilterContainer>
              {showSelectedEvent && reportingSelection?.selectedEvent ? (
                <Pill
                  onClose={handleEventFilterClear}
                  title={formatEventLabel(reportingSelection?.selectedEvent)}
                >
                  {reportingSelection?.selectedEvent.event}
                </Pill>
              ) : (
                <>
                  <div>
                    <Label htmlFor={DateBounds.EVENT_FROM}>{START_DATE}</Label>
                    <TextInput
                      id={DateBounds.EVENT_FROM}
                      max={asUndefined(toEventDate)}
                      min={MIN_DATE}
                      name={DateBounds.EVENT_FROM}
                      onChange={handleDateChange}
                      type="date"
                      value={fromEventDate}
                    />
                  </div>
                  <div>
                    <Label htmlFor={DateBounds.EVENT_TO}>{END_DATE}</Label>
                    <TextInput
                      id={DateBounds.EVENT_TO}
                      min={fromEventDate || MIN_DATE}
                      name={DateBounds.EVENT_TO}
                      onChange={handleDateChange}
                      type="date"
                      value={toEventDate}
                    />
                  </div>
                </>
              )}
            </FilterContainer>
          </Section>
          {showSelectedDeal && reportingSelection?.selectedDeal && (
            <Section>
              <FilterTitle>{BY_DEAL_OR_TERMS}</FilterTitle>
              <FilterContainer>
                <Pill
                  onClose={handleDealFilterClear}
                  title={reportingSelection?.selectedDeal?.displayName}
                >
                  {reportingSelection?.selectedDeal?.displayName}
                </Pill>
              </FilterContainer>
              {showSelectedDealTerm && reportingSelection?.selectedDealTerm && (
                <SubContainer>
                  <FilterContainer>
                    <PillContainer>
                      <IconWrapper><ChevronRight /></IconWrapper>
                      <Pill
                        onClose={handleDealTermFilterClear}
                        title={formatSlugToTitle(reportingSelection?.selectedDealTerm?.name)}
                      >
                        {formatSlugToTitle(reportingSelection?.selectedDealTerm?.name)}
                      </Pill>
                    </PillContainer>
                  </FilterContainer>
                </SubContainer>
              )}
            </Section>
          )}
          {isSales && (
            <Section>
              <FilterTitle>{BY_SALES_DATE}</FilterTitle>
              <FilterContainer>
                <div>
                  <Label htmlFor={DateBounds.SALES_FROM}>{START_DATE}</Label>
                  <TextInput
                    id={DateBounds.SALES_FROM}
                    max={asUndefined(toSalesDate) || TODAY}
                    min={MIN_DATE}
                    name={DateBounds.SALES_FROM}
                    onChange={handleDateChange}
                    type="date"
                    value={fromSalesDate}
                  />
                </div>
                <div>
                  <Label htmlFor={DateBounds.SALES_TO}>{END_DATE}</Label>
                  <TextInput
                    id={DateBounds.SALES_TO}
                    max={TODAY}
                    min={fromSalesDate || MIN_DATE}
                    name={DateBounds.SALES_TO}
                    onChange={handleDateChange}
                    type="date"
                    value={toSalesDate}
                  />
                </div>
              </FilterContainer>
            </Section>
          )}
        </Content>
        <Buttons>
          <ButtonContainer>
            <SmallButton
              kind='secondary'
              label={CANCEL}
              onClick={closeModal}
              padding='0.5rem 1rem'
            />
          </ButtonContainer>
          <ButtonContainer>
            <SmallButton
              Icon={<Download />}
              isDisabled={downloadDisabled}
              label={DOWNLOAD}
              onClick={downloadData}
              padding='0.5rem 1rem'
            />
          </ButtonContainer>
        </Buttons>
      </Modal>
    </div>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.25rem;
`;

const Title = styled.h2`
  font-size: 1.3rem;
  margin: 0;
`;

const ExportTypeContainer = styled.div`
  width: 40%;
`;

const Content = styled.div`
  padding: 0.1rem 0;
  border-bottom: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}`};
  max-height: 50vh;
  overflow-y: auto;
`;

const FilterContainer = styled.div`
  display: flex;
  max-width: 270px;
  justify-content: space-between;
  padding: 0 0.3rem;
`;

const PillContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

const SubContainer = styled.div`
  margin: 0.6rem 0 0 0.6rem;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  color: #999;
`;

const Section = styled.div`
  padding: 0.3rem 0 1.8rem 0.5rem;
  border-top: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}`};
`;

const FilterTitle = styled.h3`
  font-size: 1.05rem;
  margin-bottom: 0.7rem;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.2rem;
`;
const ButtonContainer = styled.div`
  margin-left: 0.5rem;
`;

export default ExportModal;
