import React from 'react';
import {
  Wrapper, Element, Name, Value,
} from '#/shared/Card';
import { EventConfigTableData } from '../../utils/formatEventInfo';

interface CardProps {
  tableData: EventConfigTableData;
}

const SG_ID = 'SeatGeek Event';
const MAP_CONFIG = 'Map Config';
const SECTION_SUBSET = 'Section Subset';

const IdCard: React.FC<CardProps> = ({ tableData }) => {
  return (
    <Wrapper>
      <Element>
        <Name>{SG_ID}</Name>
        <Value>{tableData.seatgeekEventId}</Value>
      </Element>
      <Element>
        <Name>{MAP_CONFIG}</Name>
        <Value>{tableData.mapConfig}</Value>
      </Element>
      {tableData.sectionSubset !== null && (
        <Element>
          <Name>{SECTION_SUBSET}</Name>
          <Value>{tableData.sectionSubset}</Value>
        </Element>
      )}
    </Wrapper>
  );
};

export default IdCard;
