import { DateTime } from 'luxon';
import { InventorySourceType } from './ReportingRollup';

interface Stakeholders {
  stakeholders: Stakeholder[];
}

enum StakeholderType {
  CLIENT = 'client',
  INTERNAL = 'internal',
  TEST = 'test',
}

enum PriceConstraintPolicyType {
  IGNORE = 'ignore_all_price_constraints',
  USE = 'use_price_constraints_when_available',
  REQUIRE = 'error_on_missing_price_constraints',
  DEFAULT = 'require_price_constraints_or_default',
}

interface PriceConstraintPolicy {
  type: PriceConstraintPolicyType;
  defaultScale?: number;
}

enum FallbackPricingPolicy {
  USE_ON_MISSING_MODELS = 'fallback_on_missing_pricing_models',
  USE_ON_ALL_ERRORS = 'fallback_on_all_pricing_errors',
  BLOCK = 'block_on_all_pricing_errors',
}

interface Stakeholder {
  id: number;
  name: string;
  displayName: string;
  type: StakeholderType;
  description: string;
  priceConstraintPolicy: PriceConstraintPolicy;
  peakpassDomainIds: string[];
  priority: number;
  fallbackPricingPolicy: FallbackPricingPolicy;
  logoUrl?: string;
  inventorySourceTypes: InventorySourceType[];
  createdAt: DateTime;
  updatedAt: DateTime;
}

interface PostStakeholderBody {
  name?: string;
  displayName?: string;
  updatedName?: string;
  type?: StakeholderType;
  description?: string;
  priority?: number;
  priceConstraintPolicy?: PriceConstraintPolicy;
  fallbackPricingPolicy?: FallbackPricingPolicy;
  logoUrl?: string;
  clearLogoUrl?: boolean;
  peakpassDomainIds?: string[];
}

export {
  Stakeholders,
  StakeholderType,
  PriceConstraintPolicy,
  PriceConstraintPolicyType,
  PostStakeholderBody,
  FallbackPricingPolicy,
};
export default Stakeholder;
