import ApiSnapshot, {
  GraphPlot,
  PriceRatioGraphData,
  GraphDomain,
} from '#/types/Snapshot';
import formatABData from './formatABData';
import formatCompetitorData from './formatCompetitorData';
import formatModelData from './formatModelData';
import calcPriceRatioDomainTruncated from '../calcTruncatedDomain';

/* eslint-disable camelcase */

interface PriceRatioData {
  actualABLogPriceRatio: GraphPlot;
  actualCompetitorLogPriceRatio: GraphPlot;
  modelLogPriceRatio: GraphPlot;
  targetLogPriceRatio: GraphPlot;
  actualABPercentilesWide: GraphPlot;
  actualABPercentilesMedium: GraphPlot;
  actualABPercentilesNarrow: GraphPlot;
  actualCompetitorPercentilesWide: GraphPlot;
  actualCompetitorPercentilesMedium: GraphPlot;
  actualCompetitorPercentilesNarrow: GraphPlot;
}

const formatPriceRatioSnapshot = (rawSnapshot: ApiSnapshot): PriceRatioData => {
  const { logHoursToEvent, actual, model } = rawSnapshot;

  const defaultData: PriceRatioData = {
    actualABLogPriceRatio: { x: logHoursToEvent, y: null },
    actualABPercentilesMedium: { x: logHoursToEvent, y: null, y0: null },
    actualABPercentilesNarrow: { x: logHoursToEvent, y: null, y0: null },
    actualABPercentilesWide: { x: logHoursToEvent, y: null, y0: null },
    actualCompetitorLogPriceRatio: { x: logHoursToEvent, y: null },
    actualCompetitorPercentilesMedium: { x: logHoursToEvent, y: null, y0: null },
    actualCompetitorPercentilesNarrow: { x: logHoursToEvent, y: null, y0: null },
    actualCompetitorPercentilesWide: { x: logHoursToEvent, y: null, y0: null },
    modelLogPriceRatio: { x: logHoursToEvent, y: null },
    targetLogPriceRatio: { x: logHoursToEvent, y: null },
  };

  if (!actual)
    return defaultData;
  const { autobroker, competitor } = actual;

  const formattedAutobroker = formatABData(autobroker, logHoursToEvent);
  const formattedCompetitor = formatCompetitorData(competitor, logHoursToEvent);
  const formattedModel = formatModelData(model, logHoursToEvent);

  const response = {
    ...formattedAutobroker,
    ...formattedCompetitor,
    ...formattedModel,
  };

  return response;
};

interface PriceRatioGraphInfo {
  priceRatioGraphData: PriceRatioGraphData;
  priceRatioGraphDomain: GraphDomain;
}

const formatPriceRatioSnapshots = (
  rawSnapshots: ApiSnapshot[],
): PriceRatioGraphInfo => {
  const priceRatioGraphData: PriceRatioGraphData = {
    actualABLogPriceRatio: [],
    actualABPercentilesMedium: [],
    actualABPercentilesNarrow: [],
    actualABPercentilesWide: [],
    actualCompetitorLogPriceRatio: [],
    actualCompetitorPercentilesMedium: [],
    actualCompetitorPercentilesNarrow: [],
    actualCompetitorPercentilesWide: [],
    modelLogPriceRatio: [],
    targetLogPriceRatio: [],
  };

  rawSnapshots.reduce(
    (
      accumulator: PriceRatioGraphData,
      rawSnapshot: ApiSnapshot,
    ): PriceRatioGraphData => {
      const snapshot = formatPriceRatioSnapshot(rawSnapshot);

      if (!snapshot)
        return accumulator;

      const dataTypes = Object.keys(snapshot) as Array<
      keyof PriceRatioGraphData
      >;

      dataTypes.forEach((type) => {
        const dataArr: GraphPlot[] = accumulator[type];

        dataArr.push(snapshot[type]);
      });
      return accumulator;
    },
    priceRatioGraphData,
  );
  const priceRatioGraphDomain = calcPriceRatioDomainTruncated(
    priceRatioGraphData,
  );

  return { priceRatioGraphData, priceRatioGraphDomain };
};

export default formatPriceRatioSnapshots;
