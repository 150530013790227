import client from './_client';
import { PatchIngestionsBody } from '#/types/Ingestion';

const patchIngestions = async (
  request: PatchIngestionsBody,
): Promise<void> => {
  await client.patch('/proxy/ingestion_listings', request);
};

export default patchIngestions;
