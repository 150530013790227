import React from 'react';
import styled from 'styled-components';
import SkeletonBar from '../../SkeletonBar';

const LoadingComponent: React.FC = () => (
  <Wrapper>
    <SkeletonBar height='84px' />
    <SmallContainer>
      <SkeletonBar height='1.5rem' />
    </SmallContainer>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0.5rem 0 0.5rem;
`;

const SmallContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 4.5rem 0 0.25rem 0;
`;

export default LoadingComponent;
