import React from 'react';
import Loader from '#/shared/Loader';
import palette from '#/theme/palettes/main';

interface LoadingProps {
  loading: boolean;
  showGraph: boolean;
}

const Loading: React.FC<LoadingProps> = ({ loading, showGraph }) => {
  const showLoading = loading && showGraph;

  return showLoading ? <Loader hexColor={palette.brand.base} /> : null;
};

export default Loading;
