import React from 'react';
import styled from 'styled-components';
import {
  ErrorWrapper, Element, Name, Tag,
} from '#/shared/Card';
import ErrorStatusCard from './ErrorStatusCard';
import Buttons, { NotesImg, RefreshImg } from './Buttons';
import { EventError, EventErrorType } from '#/types/Errors';
import ConfirmationModal from '#/shared/ConfirmationModal';
import useOnsaleConfirmationModal from '../../../../useRerunModelConfirmationModal';

const ADD_NOTES = 'Add Notes';
const REGENERATE_MODEL = 'Regenerate Model';

interface CardProps {
  disabled: boolean;
  error: EventError;
  openNotesModal: () => void;
}

const EventErrorCard: React.FC<CardProps> = ({ error, openNotesModal, disabled }) => {
  const {
    closeRerunModal,
    openRerunOnsaleModal,
    openRerunSelldownModal,
    showConfirmationModal,
    rerunModel,
    message: rerunMessage,
    error: rerunError,
  } = useOnsaleConfirmationModal();

  return (
    <>
      <ErrorWrapper disabled={disabled} level={error.errorLevel}>
        {!disabled && (
          <Buttons>
            {error.errorType === EventErrorType.ONSALE_MODEL_ERROR && (
              <RefreshImg onClick={openRerunOnsaleModal} title={REGENERATE_MODEL} />
            )}
            {error.errorType === EventErrorType.SELLDOWN_MODEL_ERROR && (
              <RefreshImg onClick={openRerunSelldownModal} title={REGENERATE_MODEL} />
            )}
            <NotesImg onClick={openNotesModal} title={ADD_NOTES} />
          </Buttons>
        )}
        <Wrapper>
          <Tag>{error.errorType.split('_').join(' ')}</Tag>
        </Wrapper>
        {showConfirmationModal && (
          <ConfirmationModal
            closeModal={closeRerunModal}
            error={rerunError}
            handleSubmit={rerunModel}
            message={rerunMessage}
          />
        )}
        <Element>
          <Name>{error.errorReason}</Name>
        </Element>
      </ErrorWrapper>
      {error.errorStatus !== null && (
        <ErrorStatusCard status={error.errorStatus} />
      )}
    </>
  );
};

const Wrapper = styled.div`
display:flex;
align-items:center;`;

export default EventErrorCard;
