import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import TableWrapper, {
  Cell,
  NoWrapCell,
  SmallCell,
  Header,
  RowWrapper,
} from '#/shared/TableComponents';
import Loader from '#/shared/Loader';
import Error from '#/shared/Error';
import Button from '#/shared/Button';
import palette from '#/theme/palettes/main';
import TransactionIcons from './TransactionIcons';
import { UseTransactionsHook } from '#/pages/useFetchTransactions';
import { StakeholderLogos } from '#/pages/useFetchStakeholders';
import { formatGtv, formatCurrency, formatPercentage } from '#/shared/formatNumberForDisplay';
import DownArrow from '#images/DownArrow.svg';
import UpArrow from '#images/UpArrow.svg';

const ERROR_COPY = 'Something went wrong retrieving events!';
const COLUMNS = [
  { label: 'ID', sortValue: 'id' },
  { label: 'Event', sortValue: 'event_starts_at' },
  { label: 'Section', sortValue: 'section' },
  { label: 'Row', sortValue: 'row' },
  { label: 'Seats', sortValue: 'seats' },
  { label: 'Qty', sortValue: 'quantity' },
  { label: 'Price', sortValue: 'price' },
  { label: 'Cost', sortValue: 'cost' },
  { label: 'FV', sortValue: 'face_value' },
  { label: 'Stakeholder', sortValue: 'stakeholder' },
  { label: 'Properties' },
  { label: 'Time', sortValue: 'transacted_at' },
];
const PREVIOUS_PAGE = 'Previous Page';
const NEXT_PAGE = 'Next Page';

interface TransactionsTableProps {
  transactionsHook: UseTransactionsHook;
  stakeholderLogos: StakeholderLogos;
}

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  transactionsHook: transactionsHook, stakeholderLogos,
}) => {
  const {
    transactions,
    perPage,
    retry,
    filters,
    setFilters,
    pages,
    prevPage,
    nextPage,
    showResults,
    showError,
    showLoader,
  } = transactionsHook;

  const handleClickHeaderCell = useCallback((column: typeof COLUMNS[0]) => {
    if (column.sortValue) {
      const sortAsc = 1 - (column.sortValue === filters.sort_by ? filters.sort_asc : 0);

      setFilters({ ...filters, sort_by: column.sortValue, sort_asc: sortAsc });
    }
  }, [filters, setFilters]);

  return (
    <>
      <TableWrapper>
        <thead>
          <Header>
            {COLUMNS.map((column) => (
              <NoWrapCell
                key={column.label}
                // eslint-disable-next-line
                onClick={(): void => handleClickHeaderCell(column)}
                style={{ whiteSpace: 'pre', cursor: column.sortValue ? 'pointer' : null }}
              >
                {column.sortValue && column.sortValue === filters.sort_by && (
                  <img
                    alt='sort'
                    height='13'
                    src={filters.sort_asc ? UpArrow : DownArrow}
                    style={{ paddingRight: '2px' }}
                    title={filters.sort_asc ? 'Ascending' : 'Descending'}
                  />
                )}
                {column.label}
              </NoWrapCell>
            ))}
          </Header>
        </thead>
        {showResults && (
          <tbody>
            {pages.length > 0 && (
              <RowWrapper key="load_previous_rows">
                <td colSpan={COLUMNS.length}>
                  <ButtonWrapper>
                    <Button onClick={prevPage}>{PREVIOUS_PAGE}</Button>
                  </ButtonWrapper>
                </td>
              </RowWrapper>
            )}
            {transactions.map((t) => (
              <RowWrapper
                key={`${t.id}`}
              >
                <Cell key={`id_${t.id}`} style={{ maxWidth: '5rem' }}>
                  {`${t.id}`}
                  <SinkTransactionIDWrapper title={`${t.sink} transaction ${t.sink_transaction_id}`}>
                    {`\n${t.sink_transaction_id}`}
                  </SinkTransactionIDWrapper>
                </Cell>
                <Cell key={`event_${t.id}`} style={{ whiteSpace: 'pre', maxWidth: '16rem' }}>
                  <Link rel="noopener noreferrer" target="_blank" to={`/view/${t.autobroker_event_id}#Sales`}>
                    {Number(t.autobroker_event_id.toString())}
                  </Link>
                  {
                    ' • '
                  + t.event_starts_at_local
                  + '\n'
                  + t.title
                  }
                </Cell>
                <SmallCell key={`section_${t.id}`} title={t.section}>{t.section}</SmallCell>
                <SmallCell key={`row_${t.id}`} title={t.row}>{t.row}</SmallCell>
                <SmallCell key={`seats_${t.id}`} title={t.seats}>{t.seats}</SmallCell>
                <Cell key={`quantity_${t.id}`}>{t.quantity}</Cell>
                <Cell
                  key={`price_${t.id}`}
                  title={t.gtv ? formatGtv(t.gtv) + ' GTV' : ''}
                >
                  {t.submitted_price_per_ticket ? formatCurrency(t.submitted_price_per_ticket) : ''}
                </Cell>
                <Cell
                  key={`cost_${t.id}`}
                  title={t.recoupment_rate ? formatPercentage(t.recoupment_rate) + ' recoupment rate' : ''}
                >
                  {t.reported_cost_per_ticket ? formatCurrency(t.reported_cost_per_ticket) : ''}
                </Cell>
                <Cell key={`face_value_${t.id}`}>
                  {t.face_value_per_ticket ? formatCurrency(t.face_value_per_ticket) : ''}
                </Cell>
                <Cell key={`stakeholder_${t.id}`} style={{ whiteSpace: 'pre', maxWidth: '7rem' }}>
                  {(t.stakeholder || '') + (t.deal ? `\n${t.deal}` : '') + (t.deal_term ? `\n${t.deal_term}` : '')}
                </Cell>
                <NoWrapCell key={`properties_${t.id}`}>
                  <TransactionIcons stakeholderLogos={stakeholderLogos} transaction={t} />
                </NoWrapCell>
                <Cell key={`transacted_at_${t.id}`} style={{ whiteSpace: 'pre' }}>
                  {t.transacted_at.toRelative() + '\n' + t.transacted_at.toLocaleString(DateTime.DATETIME_SHORT)}
                </Cell>
              </RowWrapper>
            ))}
            {transactions.length === perPage && (
              <RowWrapper key="load_next_rows">
                <td colSpan={COLUMNS.length}>
                  <ButtonWrapper>
                    <Button onClick={nextPage}>{NEXT_PAGE}</Button>
                  </ButtonWrapper>
                </td>
              </RowWrapper>
            )}
          </tbody>
        )}
      </TableWrapper>
      {showError && (
        <CenterContainer>
          <Error copy={ERROR_COPY} retry={retry} />
        </CenterContainer>
      )}
      {showLoader && (
        <CenterContainer>
          <Loader hexColor={palette.brand.base} />
        </CenterContainer>
      )}
    </>
  );
};

const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  text-align:center;
  width:95%;
`;

const SinkTransactionIDWrapper = styled.div`
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
`;

export default TransactionsTable;
