function formatLargeNumber(x: number, alwaysShowDecimal = true): string {
  if (x >= 1000000) {
    return `${(x / 1000000).toLocaleString(
      undefined, { maximumFractionDigits: 2, minimumFractionDigits: alwaysShowDecimal ? 2 : 0 },
    )}M`;
  }

  if (x >= 1000) {
    return `${(x / 1000).toLocaleString(
      undefined, { maximumFractionDigits: 1, minimumFractionDigits: alwaysShowDecimal ? 1 : 0 },
    )}K`;
  }

  return `${x.toFixed(0)}`;
}

function formatGtv(gtv: number, alwaysShowDecimal = true): string {
  const prefix = gtv < 0 ? '-$' : '$';

  return `${prefix}${formatLargeNumber(Math.abs(gtv), alwaysShowDecimal)}`;
}

const formatNumber = (v: number, maximumFractionDigits = 2): string => (
  v.toLocaleString(undefined, { maximumFractionDigits })
);

const formatCurrency = (v: number): string => {
  const prefix = v < 0 ? '-$' : '$';

  return `${prefix}${Math.abs(v)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

const formatPercentage = (v: number, maximumFractionDigits = 1): string => (
  v?.toLocaleString(undefined, { style: 'percent', maximumFractionDigits })
    ?.replace('-0%', '0%')
);

export {
  formatCurrency,
  formatNumber,
  formatLargeNumber,
  formatGtv,
  formatPercentage,
};
