import React from 'react';

import Listing from '#/types/Listing';
import ListingDetailHeader from './ListingDetailHeader';
import ListingDetailCards from './ListingDetailCards';
import { Wrapper, Section } from '#/shared/Detail';
import ListingFunnelCards from './ListingFunnelCards';
import { InputListing } from '#/shared/ListingOverrideModal';

interface ListingDetailProps {
  listing: Listing;
  setOverrideListingModal: (listings: InputListing[]) => void;
  closeDetail: () => void;
  closeable: boolean;
  canEdit: boolean;
}

const ListingDetail: React.FC<ListingDetailProps> = ({
  listing,
  setOverrideListingModal,
  closeDetail,
  closeable,
  canEdit,
}) => {
  return (
    <Wrapper>
      <Section>
        <ListingDetailHeader
          canEdit={canEdit}
          closeDetail={closeDetail}
          closeable={closeable}
          listing={listing}
          setOverrideListingModal={setOverrideListingModal}
        />
      </Section>
      <Section>
        <ListingDetailCards listing={listing} />
      </Section>
      <Section>
        <ListingFunnelCards listing={listing} />
      </Section>
    </Wrapper>
  );
};

export default ListingDetail;
