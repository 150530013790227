import React, { useMemo } from 'react';
import { Theme as GGTheme, ThemeProps } from '@graphique/graphique';
import { range } from 'd3-array';
import merge from 'lodash/merge';

const defaultTheme: ThemeProps<unknown> = {
  animationDuration: 430,
  axis: {
    label: { fontSize: '14px' },
    tickLabel: { fontSize: '13px' },
    showAxisLines: true,
  },
  font: { family: 'Roobert, sans-serif' },
  grid: { stroke: '#dddddd64' },
  tooltip: { font: { size: 14 } },
};

const Theme: React.FC<ThemeProps<unknown>> = (props = defaultTheme) => {
  const resolvedTheme = useMemo(() => merge({}, defaultTheme, props), [props]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <GGTheme {...resolvedTheme} />
  );
};

const REPS = 6;
const BASE = 10;
const generateTickSequence = (vals: number[]): number[] => {
  return range(REPS).flatMap((i: number) => vals.map((v) => v * (BASE ** i)));
};
const initialVals = [1, 2, 5];
const LOG_SCALE_TICKS = [0, ...generateTickSequence(initialVals)];

export {
  Theme,
  LOG_SCALE_TICKS,
};
