import React from 'react';
import { Wrapper, Element, Name } from '#/shared/Card';
import { User } from '#/types/User';
import { Option } from '#/pages/useFetchStakeholders';

const STAKEHOLDERS = 'Stakeholders';
const NO_STAKEHOLDERS = 'No Stakeholder Authorizations';

interface CardProps {
  user: User;
  stakeholderLabels: Option<string>[];
}

const UserInfoCard: React.FC<CardProps> = ({ user, stakeholderLabels }) => {
  const { id, email, authorizations } = user;

  const currentStakeholders = authorizations.map((authorization) => {
    return (
      <Name key={authorization.stakeholderId}>
        {stakeholderLabels.find((s) => s.id === authorization.stakeholderId)?.label}
        {': '}
        {authorization.hasWriteAccess ? 'Read/Write' : 'Read Only'}
      </Name>
    );
  });

  return (
    <Wrapper style={{ height: '20rem' }}>
      <Element>
        <Name>
          {'User ID: '}
          {id}
        </Name>
        <Name>{user.fullName()}</Name>
        <Name>{email}</Name>
        <Name>{STAKEHOLDERS}</Name>
        {authorizations.length === 0 && <Name>{NO_STAKEHOLDERS}</Name>}
        {currentStakeholders}
      </Element>
    </Wrapper>
  );
};

export default UserInfoCard;
