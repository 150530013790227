import { DateTime, type DateTimeUnit } from 'luxon';
import { TimeIntervalSelection } from '../utils/menuOptions';
import { TimeUnit } from '#/shared/reporting/accumulationUtils';

interface DateValidationConfig {
  transactionAtMin?: string;
  transactionAtMax?: string;
  timeIntervalSelection: {
    max: number;
    value: DateTimeUnit;
  };
  defaultFromDate: DateTime;
}

interface DateValidationResult {
  isValid: boolean;
  effectiveFromDate?: string;
}

const validateTransactionDateRange = ({
  transactionAtMin,
  transactionAtMax,
  timeIntervalSelection,
  defaultFromDate,
}: DateValidationConfig): DateValidationResult => {
  const now = DateTime.now();

  // Convert ISO strings to DateTime objects if they exist
  const minDate = transactionAtMin ? DateTime.fromISO(transactionAtMin) : undefined;
  const maxDate = transactionAtMax ? DateTime.fromISO(transactionAtMax) : undefined;

  // Check for invalid DateTime objects
  if ((transactionAtMin && !minDate?.isValid) || (transactionAtMax && !maxDate?.isValid)) {
    return {
      isValid: false,
      effectiveFromDate: undefined,
    };
  }

  // Case 1: Both dates undefined
  if (!minDate && !maxDate) {
    return {
      isValid: true,
      effectiveFromDate: defaultFromDate?.toISO({ includeOffset: false }),
    };
  }

  // Case 2: Only min date defined
  if (minDate && !maxDate) {
    const diffInUnits = now.diff(minDate, timeIntervalSelection.value)
      .as(timeIntervalSelection.value);

    return {
      isValid: diffInUnits <= timeIntervalSelection.max,
      effectiveFromDate: minDate.toISO({ includeOffset: false }),
    };
  }

  // Case 3: Only max date defined
  if (!minDate && maxDate) {
    const effectiveFromDate = maxDate.minus({
      [timeIntervalSelection.value]: timeIntervalSelection.max,
    });

    return {
      isValid: true,
      effectiveFromDate: effectiveFromDate.toISO({ includeOffset: false }),
    };
  }

  // Case 4: Both dates defined
  const diffInUnits = maxDate.diff(minDate, timeIntervalSelection.value)
    .as(timeIntervalSelection.value);

  return {
    isValid: diffInUnits <= timeIntervalSelection.max,
    effectiveFromDate: minDate.toISO({ includeOffset: false }),
  };
};

const getDefaultFromDatetime = (timeIntervalSelection: TimeIntervalSelection): DateTime => {
  // include a default starting point for narrow time intervals
  const { value } = timeIntervalSelection;

  if (value === TimeUnit.HOUR || value === TimeUnit.MINUTE) {
    // use the current time window as a reference point
    const now = DateTime.now().endOf(timeIntervalSelection.value);

    return now.minus({ [timeIntervalSelection.value]: timeIntervalSelection.max });
  }
  return undefined;
};

const generateTransactionDateRangeOverflow = ({
  hasValidTransactionDateRange,
  timeIntervalSelection,
}: {
  hasValidTransactionDateRange: boolean,
  timeIntervalSelection: TimeIntervalSelection,
}): string | undefined => {
  if (!hasValidTransactionDateRange) {
    const { value, max } = timeIntervalSelection;

    return `Transaction date range for this interval cannot exceed ${max} ${value}s.`;
  }
  return undefined;
};

export {
  validateTransactionDateRange,
  getDefaultFromDatetime,
  generateTransactionDateRangeOverflow,
  type DateValidationResult,
  type DateValidationConfig,
};
