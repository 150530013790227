import {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import postSourceLink from '#/api/postSourceLink';
import postSourceUnlink from '#/api/postSourceUnlink';
import { SourceEvent } from '#/types/Event';
import formatApiError from '#/api/utils/formatApiError';

const LINK = 'Link';
const UNLINK = 'Unlink';
const LINK_TEXT = 'This is an important action -- please confirm that you want to activate listings in this source.';
const UNLINK_TEXT = 'This is an aggressive action -- please confirm that you want to remove all listings from this source';
const SUCCESS = 'Your request was successful';
const CANCEL = 'Cancel';
const DONE = 'Done';

type Hook = (
  activeSource: SourceEvent,
  retry: () => void,
  setActiveSource: (el: SourceEvent) => void
) => {
  cancelButtonText: string;
  closeModal: () => void;
  handleClickLink: () => void;
  linkButtonText: string;
  linkError: string;
  linkLoading: boolean;
  linkModalIsOpen: boolean;
  linkModalText: string;
  openModal: (source: SourceEvent) => void;
};

const useLinkModal: Hook = (activeSource, retry, setActiveSource) => {
  const [linkModalIsOpen, setLinkModalIsOpen] = useState<boolean>(false);
  const [linkLoading, setLoading] = useState<boolean>(false);
  const [linkError, setError] = useState<string>('');

  const [expired, setExpired] = useState<boolean>(false);

  const id = activeSource?.id;

  const [linkModalText, setLinkModalText] = useState<string>(UNLINK_TEXT);
  const [cancelButtonText, setCancelButtonText] = useState<string>(CANCEL);
  const linkButtonText = useMemo(() => (expired ? LINK : UNLINK), [expired]);

  const toggleLinkModal = useCallback(
    () => setLinkModalIsOpen(!linkModalIsOpen),
    [linkModalIsOpen, setLinkModalIsOpen],
  );

  const linkOrUnlink = useMemo(
    () => (expired ? postSourceLink : postSourceUnlink),
    [expired],
  );

  const openModal = useCallback(
    (source: SourceEvent) => {
      setActiveSource(source);
      setCancelButtonText(CANCEL);
      toggleLinkModal();
    },
    [setActiveSource, toggleLinkModal],
  );
  const closeModal = useCallback(() => {
    toggleLinkModal();
    retry();
  }, [toggleLinkModal, retry]);

  const handleClickLink = useCallback(async () => {
    setLoading(true);
    setLinkModalText('');
    try {
      setError('');
      await linkOrUnlink(id);
      setLinkModalText(SUCCESS);
      setExpired(!expired);
      setCancelButtonText(DONE);
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
    setLoading(false);
  }, [linkOrUnlink, id, expired]);

  useEffect(() => {
    const isExpired = !!activeSource?.expiredAt;
    const linkText = isExpired ? LINK_TEXT : UNLINK_TEXT;

    setExpired(isExpired);
    setLinkModalText(linkText);
  }, [setExpired, activeSource, setLinkModalText]);

  return {
    cancelButtonText,
    closeModal,
    handleClickLink,
    linkButtonText,
    linkError,
    linkLoading,
    linkModalIsOpen,
    linkModalText,
    openModal,
  };
};

export default useLinkModal;
