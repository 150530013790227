import React, {
  Children,
  cloneElement,
  PureComponent,
  ReactElement,
} from 'react';
import { Props as SegmentedControlProps } from './SegmentedControl';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
`;

type Props = {
  children: ReactElement<SegmentedControlProps>[];
  name: string;
  onChange: (
    arg0: string,
    arg1: SegmentedControlProps,
    arg2: React.ChangeEvent<HTMLInputElement>
  ) => unknown;
  value: string;
};

export default class SegmentedGroup extends PureComponent<Props> {
  handleChange = (
    checked: boolean,
    props: SegmentedControlProps,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (checked) {
      this.props.onChange(event.target.value, props, event);
    }
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, name, onChange, value, ...props } = this.props;

    return (
      <Container {...props}>
        {Children.map(children, (child, index) =>
          cloneElement(child, {
            key: index,
            name,
            checked: child.props.value === value,
            onChange: this.handleChange
          })
        )}
      </Container>
    );
  }
}
