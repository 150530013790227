/* eslint-disable import/max-dependencies */
import React from 'react';
import styled from 'styled-components';

import Peakpass from '#images/Peakpass.svg';
import PriceConstraint from '#images/PriceConstraint.svg';
import Oneticket from '#images/Oneticket.svg';
import Visible from '#images/Visible.svg';
import Ticketvault from '#images/Ticketvault.svg';
import Consumer from '#images/ConsumerUploader.svg';
import NotVisible from '#images/NotVisible.svg';
import Row from '#images/Row.svg';
import Mapkey from '#images/Mapkey.svg';
import Section from '#images/Section.svg';
import ExpectedValue from '#images/ExpectedValue.svg';
import { InputListing } from '#/shared/ListingOverrideModal';

interface OverridesIconsProps {
  listingOverride: InputListing;
}

const OverridesIcons: React.FC<OverridesIconsProps> = (
  { listingOverride } : OverridesIconsProps,
) => {
  return (
    <>
      {
        listingOverride.source_name === 'peakpass' && (
          <OffsetImage
            alt="peakpass"
            height="25"
            src={Peakpass}
            title="Peakpass source"
          />
        )
      }
      {
        listingOverride.source_name === 'oneticket' && (
          <OffsetImage
            alt="oneticket"
            height="25"
            src={Oneticket}
            title="Oneticket source"
          />
        )
      }
      {
        listingOverride.source_name === 'ticketvault' && (
          <OffsetImage
            alt="ticketvault"
            height="25"
            src={Ticketvault}
            title="Ticketvault source"
          />
        )
      }
      {
        listingOverride.source_name === 'consumer' && (
          <OffsetImage
            alt="consumer"
            height="25"
            src={Consumer}
            title="Consumer source"
          />
        )
      }
      {
        listingOverride.override.price_constraint && (
          <OffsetImage
            alt="constraint"
            height="25"
            src={PriceConstraint}
            title="Has Price Constraint"
          />
        )
      }
      {
        listingOverride.override.section && (
          <OffsetImage
            alt="section"
            height="25"
            src={Section}
            title="Section override"
          />
        )
      }
      {
        listingOverride.override.row && (
          <OffsetImage
            alt="row"
            height="25"
            src={Row}
            title="Row override"
          />
        )
      }
      {
        listingOverride.override.mapkey && (
          <OffsetImage
            alt="mapkey"
            height="25"
            src={Mapkey}
            title="Mapkey override"
          />
        )
      }
      {
        listingOverride.override.expected_value && (
          <OffsetImage
            alt="expected value"
            height="25"
            src={ExpectedValue}
            title="Expected value override"
          />
        )
      }
      {
        listingOverride.override.is_visible && (
          <OffsetImage
            alt="sinks"
            height="25"
            src={Visible}
            title="Visible on sink"
          />
        )
      }
      {
        listingOverride.override.is_visible === false && (
          <OffsetImage
            alt="sinks"
            height="25"
            src={NotVisible}
            title="Not visible on sink"
          />
        )
      }
    </>
  );
};

const OffsetImage = styled.img`
  padding-left: 5px;
  padding-bottom: 3px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  object-fit: cover;
  object-position: center;
`;

export default OverridesIcons;
