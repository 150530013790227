import React from 'react';
import styled from 'styled-components';
import Loader from '#/shared/Loader';
import palette from '#/theme/palettes/main';

interface LoadingProps {
  loading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ loading }) => {
  return (
    loading && (
      <DefaultNoEvent>
        <Loader hexColor={palette.brand.base} />
      </DefaultNoEvent>
    )
  );
};

const DefaultNoEvent = styled.div`
  width: 100%;
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Loading;
