import client from './_client';
import {
  APIEventConfig, EventApiPost, EventPostBody, SectionSubsetType,
} from '#/types/Event';

interface APIResponse {
  status: number;
  autobrokerEventId: number;
}
/* eslint-disable camelcase */

const translateStringToArray = (input: string): string[] => {
  return input.split(',').map((str) => str.trim()).filter((s) => s.length > 0);
};

const formatPostBody = (post: EventPostBody): EventApiPost => {
  const formattedSectionSubset = post.sectionSubsetSections
    ? translateStringToArray(post.sectionSubsetSections)
    : [];

  return {
    groups: post.groups,
    onsale_starts_at: post.onsaleStartsAt
      ? post.onsaleStartsAt.toISO()
      : null,
    pricing_mode: post.pricingMode,
    seatgeek_event_id: post.seatgeekEventId,
    section_subset: post.sectionSubsetType && post.sectionSubsetType !== SectionSubsetType.NONE
      ? { type: post.sectionSubsetType, sections: formattedSectionSubset }
      : null,
    supervisor_starts_at: post.supervisorStartsAt
      ? post.supervisorStartsAt.toISO()
      : null,
    supervisor_stops_at: post.supervisorStopsAt
      ? post.supervisorStopsAt.toISO()
      : null,
    keep_supervisor_active: post.keepSupervisorActive,
    use_seatgeek_transactions: post.useSeatgeekTransactions,
    demand_changepoint_ct: post.demandChangepointCt,
    pricing_basis: post.pricingBasis,
    onsale_demand_fraction: post.onsaleDemandFraction,
    selldown_projection_model: post.selldownProjectionModel,
    onsale_pricing_model: post.onsalePricingModel,
    dynamic_pricing_model: post.dynamicPricingModel,
    notes: post.notes,
    force_create: post.forceCreate,
  };
};

const postEvent = async (post: EventPostBody, force = false): Promise<APIResponse> => {
  const postBody = formatPostBody({ ...post, forceCreate: force });

  const response = await client.post('/proxy/event', postBody);

  const eventConfig: APIEventConfig = response?.data || null;

  return {
    autobrokerEventId: Number(eventConfig.autobroker_event_id),
    status: response.status,
  };
};

export default postEvent;
