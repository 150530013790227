import React, { useCallback } from 'react';
import { UpdateEventConfigType } from '#/pages/ViewPage/components/Configuration/EventConfig/types';
import Action, { ConfigType, EventType } from '../types';
import { FormWrapper, Label, Input } from './formComponents';

enum InputType {
  text = 'text',
  number = 'number',
}

interface InputFormProps {
  initialVal?: number | string;
  inputType: InputType;
  label: string;
  min?: number;
  max?: number;
  step?: string;
  placeholder?: string;
  type: ConfigType | EventType | UpdateEventConfigType;
  handleInputChange: React.Dispatch<Action>;
}

const InputForm: React.FC<InputFormProps> = ({
  initialVal,
  inputType,
  label,
  min,
  max,
  step,
  placeholder,
  type,
  handleInputChange,
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newVal: string | number = event.target.value;

      if (inputType === 'number') {
        newVal = Number.parseFloat(newVal);
        newVal = Number.isNaN(newVal) ? undefined : newVal;
      } else {
        newVal = newVal.toString();
      }
      handleInputChange({ type, value: newVal });
    },
    [inputType, type, handleInputChange],
  );

  return (
    <FormWrapper>
      <Label htmlFor={type}>{`${label}:`}</Label>
      <Input
        defaultValue={initialVal ?? ''}
        id={type}
        max={max}
        min={min}
        onChange={handleChange}
        placeholder={placeholder}
        step={step}
        type={inputType}
      />
    </FormWrapper>
  );
};

export { InputType };
export default InputForm;
