import client from './_client';
import { EditIngestion } from '#/types/Ingestion';

const patchIngestion = async (
  id: number, edit: EditIngestion, files: File[] = [],
): Promise<void> => {
  let data = null;
  let contentType = null;

  if (files.length > 0) {
    const formData = new FormData();

    contentType = 'multipart/form-data';

    files.forEach((file) => {
      formData.append('pdf_files', file);
    });
    formData.append('body', JSON.stringify(edit));
    data = formData;
  } else {
    data = edit;
    contentType = 'application/json';
  }

  await client.patch(`/proxy/ingestion_listing/${id}`, data, {
    headers: { 'Content-Type': contentType },
  });
};

export default patchIngestion;
