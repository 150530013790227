import React from 'react';
import styled from 'styled-components';
import Deleted from '#images/Cancelled.svg';

interface Props {
  isDeleted: boolean;
}

const DeletedIcon: React.FC<Props> = ({ isDeleted }) => {
  if (isDeleted) {
    return (
      <OffsetImage
        data-tag='allowRowEvents'
        src={Deleted}
        title="Removed Override"
      />
    );
  }
  return <></>;
};

const OffsetImage = styled.img`
  padding-left: 5px;
`;

export default DeletedIcon;
