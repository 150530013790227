import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link as InternalLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { ActiveEventContext } from '../../contexts/ActiveEvent';
import { DropdownContent, UploadLabel } from '#/shared/DropDown';
import { FetchGroupsHook } from '#/pages/useFetchGroups';

const RUFUS = 'Rufus';
const SG = 'SG';
const CATALOG = 'Catalog';
const MAP = 'Map';
const VIEW_INVENTORY = 'View inventory';
const VIEW_INVENTORY_ONETICKET = 'OneTicket';
const VIEW_INVENTORY_TICKETVAULT = 'TicketVault';
const VIEW_INVENTORY_PRIMARY = 'Primary';
const VIEW_INVENTORY_CONSUMER = 'Consumer';
const TRAFFIC = 'Traffic';
const GROUPS = 'Groups';

interface EventTitleProps {
  groupsHook: FetchGroupsHook;
}

const EventTitle: React.FC<EventTitleProps> = ({ groupsHook }) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const groups = groupsHook?.groups || [];

  const title = `${activeEvent?.title} · ${activeEvent?.venue}`;
  const eventTimeString = activeEvent?.config?.eventTimeString;
  const eventDateString = activeEvent?.config?.eventDateString;
  const eventStartTimeStatus = activeEvent?.config?.eventStartTimeStatus;
  const eventDateFormatted = `${eventDateString} · ${eventTimeString} (${eventStartTimeStatus})`;
  const id = activeEvent?.config?.seatgeekEventId;
  const priceTypeIds = encodeURIComponent(activeEvent?.summary?.priceTypeIds?.join(','));
  const now = DateTime.utc();
  // for convenience, automatically link to the event page with the only access code, if
  // before the onsale time, and there is only one active presale with one active access code
  const codes = activeEvent
    ?.salesPeriods
    ?.filter((p) => (now >= p.startsAt && now <= p.endsAt))
    ?.map((p) => p.accessCodes);
  const code = (
    (
      now < activeEvent?.config?.onsaleStartsAt
      && codes?.length === 1
      && codes[0]?.length === 1
    )
      ? codes[0][0]
      : null
  );
  const qSgoAc = code ? `?sgo_ac=${code}` : '';
  const aSgoAc = code ? `&sgo_ac=${code}` : '';
  const seatgeekEventPage = `https://seatgeek.com/e/events/${id}${qSgoAc}`;
  const rufusEventPage = `https://rufus.seatgeekadmin.com/events/${id}`;
  const catalogEventPage = `https://rufus.seatgeekadmin.com/catalog/event?sg_id=${id}`;
  const mapEventPage = `https://rufus.seatgeekadmin.com/maps/events/${id}`;
  const oneticketInventory = `https://seatgeek.com/e/events/${id}?market=sellerdirect&seller_id=2448${aSgoAc}`;
  const ticketvaultInventory = `https://seatgeek.com/e/events/${id}?market=sellerdirect&seller_id=3192${aSgoAc}`;
  const primaryInventory = `https://seatgeek.com/e/events/${id}?market=open&price_type_id=${priceTypeIds}${aSgoAc}`;
  const consumerInventory = `https://seatgeek.com/e/events/${id}?is_smart_priced=1${aSgoAc}`;
  const rufusEventTrafficPage = `https://rufus.seatgeekadmin.com/events/${id}/traffic`;
  const link = (sinkName: string): any => {
    if (sinkName === 'oneticket') {
      return (
        <DropDownLink href={oneticketInventory} key={sinkName} rel="noopener" target="_blank">
          <UploadLabel>{VIEW_INVENTORY_ONETICKET}</UploadLabel>
        </DropDownLink>
      );
    }
    if (sinkName === 'ticketvault') {
      return (
        <DropDownLink href={ticketvaultInventory} key={sinkName} rel="noopener" target="_blank">
          <UploadLabel>{VIEW_INVENTORY_TICKETVAULT}</UploadLabel>
        </DropDownLink>
      );
    }
    if ((sinkName === 'sro-seat-level-pricing' || sinkName === 'sro-price-level-pricing') && priceTypeIds !== '') {
      return (
        <DropDownLink href={primaryInventory} key={sinkName} rel="noopener" target="_blank">
          <UploadLabel>{VIEW_INVENTORY_PRIMARY}</UploadLabel>
        </DropDownLink>
      );
    }
    if (sinkName === 'consumer') {
      return (
        <DropDownLink href={consumerInventory} key={sinkName} rel="noopener" target="_blank">
          <UploadLabel>{VIEW_INVENTORY_CONSUMER}</UploadLabel>
        </DropDownLink>
      );
    }

    return null;
  };
  const sinkLinks = activeEvent?.sinkEvents
    .map((sink) => link(sink.sinkName))
    .filter((l) => !!l) || [];

  return (
    <EventHeader>
      {activeEvent && (
        <Wrapper>
          <Title>{title}</Title>
          <Details>
            <div>{eventDateFormatted}</div>
            <Break />
            <Link href={rufusEventPage} rel="noopener" target="_blank">
              {RUFUS}
            </Link>
            <Link href={seatgeekEventPage} rel="noopener" target="_blank">
              {SG}
            </Link>
            <Link href={catalogEventPage} rel="noopener" target="_blank">
              {CATALOG}
            </Link>
            <Link href={mapEventPage} rel="noopener" target="_blank">
              {MAP}
            </Link>
            {sinkLinks.length > 0
            && (
              <Dropdown>
                <DropdownButton>{VIEW_INVENTORY}</DropdownButton>
                <DropdownContent>
                  {sinkLinks}
                </DropdownContent>
              </Dropdown>
            )}
            <Link href={rufusEventTrafficPage} rel="noopener" target="_blank">
              {TRAFFIC}
            </Link>
            {activeEvent.groups.length > 0 && (
              <Dropdown>
                <DropdownButton>{GROUPS}</DropdownButton>
                <DropdownContent>
                  {activeEvent.groups
                    ?.map((name) => groups?.find((group) => group.name === name))
                    .filter((group) => !!group)
                    .map((group) => (
                      <DropDownInternalLink
                        key={group.id}
                        to={`/view/collection?group=${group.name}`}
                      >
                        <UploadLabel>{group.displayName}</UploadLabel>
                      </DropDownInternalLink>
                    ))}
                </DropdownContent>
              </Dropdown>
            )}
          </Details>
        </Wrapper>
      )}
    </EventHeader>
  );
};

const EventHeader = styled.div`
  width: 90rem;
`;

const Wrapper = styled.div`
  width: 90%;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 6.25rem;
  align-items: center;
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
`;

const Title = styled.div`
  width: 100%;
  margin: 0 0.375rem;
  ${({ theme }: { theme: Theme }): string => theme.header3};
`;
const Break = styled.div`
  width: 1px;
  height: 0.875rem;
  background: rgba(0, 0, 0, 0.16);
  margin-left: 1.25rem;
  margin-right: .625rem;
`;
const Link = styled.a`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.link};
  text-decoration: none;
  margin: 0 .625rem;
`;

const DropDownLink = styled.a`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.link};
  text-decoration: none;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  &:hover {
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.highlight};
  }
`;

const DropDownInternalLink = styled(InternalLink as any)`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.link};
  text-decoration: none;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  &:hover {
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.highlight};
  }
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.375rem 0;
  ${({ theme }: { theme: Theme }): string => theme.text1semibold};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.secondary};
`;

const DropdownButton = styled.button`
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.highlight};
  text-decoration: none;
  font-weight: 600;
  padding: 0.625rem;
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.background.teritary};
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${DropdownButton} {
    background-color: #fff2f0;
  }
  &:hover ${DropdownContent} {
    display: block;
  }
`;

export default EventTitle;
