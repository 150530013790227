import { AxiosResponse } from 'axios';
import { DealTerm, PostDealTermBody } from '#/types/Deal';
import client from './_clientFormatted';

const patchDealTerm = async (
  dealTermId: number,
  request: PostDealTermBody,
): Promise<DealTerm> => {
  const response: AxiosResponse<DealTerm> = await client.patch(`/proxy/deal_term/${dealTermId}`, request);

  return response?.data;
};

export default patchDealTerm;
