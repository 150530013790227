import { AxiosResponse } from 'axios';
import client from './_clientFormatted';
import type {
  PostRollupBody,
  RollupFilters,
  RollupOverride,
  RollupPreview,
  RollupPreviewResponse,
} from '#/types/ReportingRollup';
import redirectUnauthorizedToAuth from './utils/redirectUnauthorizedToAuth';

const postReportingRollupPreview = async (
  filters: RollupFilters = {},
  overrides: RollupOverride[],
  group?: string,
): Promise<RollupPreview[]> => {
  try {
    const response: AxiosResponse<RollupPreviewResponse> = (
      await client.post<PostRollupBody, AxiosResponse<RollupPreviewResponse>, PostRollupBody>(
        '/proxy/reporting_rollup/preview',
        { overrides, group },
        {
          params: {
            ...filters,
          },
        },
      )
    );

    const rawRollup = response?.data?.rollup || [];

    return rawRollup;
  } catch (err) {
    redirectUnauthorizedToAuth(err);
    throw new Error(err);
  }
};

export default postReportingRollupPreview;
