import React, {
  useState, useCallback, useMemo, useRef,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { debounce } from '@graphique/graphique';
import {
  SectionWrapper, Title, Content, TextArea,
} from '#/shared/modalComponents';
import ModalButtons from '../Buttons';
import patchSalesPeriods from '#/api/patchSalesPeriods';
import formatApiError from '#/api/utils/formatApiError';
import Loader from '#/shared/Loader';
import {
  SalesPeriod, SalesPeriodType, UpdateSalesPeriod, UpdateSalesPeriods,
} from '#/types/Event';
import SalesPeriodForm from './SalesPeriodForm';
import Button from '#/shared/Button';
import palette from '#/theme/palettes/main';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';
import formatDateForForm from '#/shared/formatDateForForm';
import formatDateForState from '#/shared/formatDateForState';
import getSalesPeriodSuggestions from '#/api/getSalesPeriodSuggestions';

const ADD = '+';
const SUGGEST = 'Suggest';
const NOTES = 'Notes';
const HEADLINE = 'Sales Periods';
const DESCRIPTION = 'Update the auxilliary sales periods for this event (e.g. presales). For updating the main selling period use the "Edit Event Config" form. The "Suggest" button can be used to auto-generate changes so that our sales periods match the SGE client\'s.';
const rebuildModalStyles = {
  ...modalStyles,
  content: {
    ...modalStyles.content,
    maxWidth: 1200,
    maxHeight: '90%',
  },
};

interface SalesPeriodsModalProps {
  eventId?: number;
  salesPeriods?: SalesPeriod[];
  closeModal: () => void;
  retry: () => void;
}

const SalesPeriodsModal: React.FC<SalesPeriodsModalProps> = ({
  eventId,
  salesPeriods,
  closeModal,
  retry,
}): React.ReactElement => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const notesRef = useRef<HTMLTextAreaElement>(null);
  const [notes, setNotes] = useState<string>(undefined);
  const initialPeriods = salesPeriods.map((s, key) => {
    const {
      id, type, startsAt: startsAtDt, endsAt: endsAtDt, demandPrior, accessCodes,
    } = s;
    const startsAt = formatDateForForm(startsAtDt);
    const endsAt = formatDateForForm(endsAtDt);

    return {
      key, id, type, startsAt: startsAt, endsAt, demandPrior, accessCodes,
    };
  }) || [];
  const [periods, setPeriods] = useState<UpdateSalesPeriod[]>(initialPeriods);
  const disabled = useMemo(() => !eventId || periods.some((p) => !!p.error), [eventId, periods]);

  const addPeriod = useCallback(() => {
    setPeriods((p: UpdateSalesPeriod[]) => {
      const key = p.length === 0 ? 0 : p[p.length - 1].key + 1;

      return [...p, { key, type: SalesPeriodType.PRESALE }];
    });
  }, [setPeriods]);

  const removePeriod = useCallback((key: number) => {
    setPeriods((p: UpdateSalesPeriod[]) => {
      return p.map((sp) => {
        if (sp.key !== key)
          return sp;

        if (sp.id)
          return { key: sp.key, id: sp.id, isDeleted: true };

        return null;
      })
        .filter((sp) => sp !== null);
    });
  }, [setPeriods]);

  const updatePeriod = useCallback(
    (key: number, update: (p: UpdateSalesPeriod) => UpdateSalesPeriod) => {
      setPeriods((p: UpdateSalesPeriod[]) => {
        return p.map((sp) => {
          if (sp.key !== key)
            return sp;

          return update(sp);
        });
      });
    }, [setPeriods],
  );

  const onChangeNotes = debounce(300, () => {
    const notesString = notesRef?.current?.value?.trim() || undefined;

    setNotes(notesString);
  });

  const handleSuggestions = useCallback(async () => {
    setError('');
    setShowLoader(true);
    try {
      if (eventId) {
        const request = { autobrokerEventId: eventId, type: SalesPeriodType.PRESALE };
        const suggestions = await getSalesPeriodSuggestions(request);

        setPeriods((p) => suggestions.map((s, key) => {
          const startsAt = s.startsAt && formatDate(s.startsAt);
          const endsAt = s.endsAt && formatDate(s.endsAt);

          return {
            key: key + p.length, ...s, startsAt, endsAt,
          };
        }));
      }
    } catch (e) {
      setError(formatApiError(e));
    }
    setShowLoader(false);
  }, [eventId, setPeriods]);

  const handleSave = useCallback(async () => {
    setError('');
    setShowLoader(true);
    try {
      if (eventId) {
        const request: UpdateSalesPeriods = {
          salesPeriods: periods, notes,
        };

        await patchSalesPeriods(eventId, request);

        retry();
        closeModal();
      }
    } catch (e) {
      setError(formatApiError(e));
    }
    setShowLoader(false);
  }, [eventId, closeModal, periods, retry, notes]);

  useSetupModal(closeModal);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={rebuildModalStyles}
    >
      <Title className='sales_period_modal'>{HEADLINE}</Title>
      {error && <Error>{error}</Error>}
      <SectionWrapper>
        <Content>
          <Description>{DESCRIPTION}</Description>
          <InnerWrapper>
            {periods.filter((period) => !period.isDeleted).map((period, i) => (
              <SalesPeriodForm
                includeHeader={i === 0}
                key={period.key}
                period={period}
                removePeriod={removePeriod}
                setPeriod={updatePeriod}
              />
            ))}
          </InnerWrapper>
          <InnerWrapper>
            <HorizontalWrapper>
              <Button onClick={handleSuggestions}>{SUGGEST}</Button>
              <Button onClick={addPeriod}>{ADD}</Button>
            </HorizontalWrapper>
          </InnerWrapper>
        </Content>
        <InnerWrapper>
          <TextArea
            onChange={onChangeNotes}
            placeholder={NOTES}
            ref={notesRef}
          />
        </InnerWrapper>
        {showLoader
          ? <Loader hexColor={palette.brand.base} />
          : (
            <ModalButtons
              disabled={disabled}
              onCancel={closeModal}
              onSave={handleSave}
            />
          )}
      </SectionWrapper>
    </Modal>
  );
};

function formatDate(dt: string): string {
  return formatDateForForm(formatDateForState(dt));
}

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0.5rem;
`;

const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Description = styled.p`
  ${({ theme }: { theme: Theme }): string => theme.text3};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.secondary};
  border-bottom: ${({ theme }: { theme: Theme }): string => `1px solid ${theme.palette.silver.dark}`};
  padding-bottom: 1rem;
`;

const Error = styled.p`
  ${({ theme }: { theme: Theme }): string => theme.text2};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.error};
`;

export default SalesPeriodsModal;
