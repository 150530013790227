import styled from 'styled-components';
import palette from '#/theme/palettes/main';

const TableWrapper = styled.table`
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border-radius: 8px;
  ${({ theme }: { theme: Theme }): string => theme.shadows.down};
  .sink {
    :hover {
      background-color: ${palette.brand.light24};
      transition: all 0.5s ease-out;
    }

    cursor: pointer;
  }
`;

const Header = styled.tr`
  width: 100%;
  height: 3.75rem;
  ${({ theme }: { theme: Theme }): string => theme.text3};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.secondary};
  background-color: ${palette.silver.dark};
  text-align: left;
  td:first-child {
    padding-left: 1rem;
    border-top-left-radius: 8px;
  }
  td:last-child {
    border-top-right-radius: 8px;
  }
`;

const SmallHeader = styled(Header as any)`
  height: 2rem;
`;

const RowWrapper = styled.tr`
  width: 100%;
  height: 3.75rem;
  text-align: left;
  ${({ theme }: { theme: Theme }): string => theme.text2};
  td:first-child {
    padding-left: 1rem;
    border-bottom-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 8px;
  }
  &:nth-child(odd) {
    background-color: ${({ theme, color }: { theme: Theme; color?: string }): string => color || theme.color.background.primary};
  }
  &:nth-child(even) {
    background-color: ${({ theme, color }: { theme: Theme; color?: string }): string => color || theme.color.background.secondary};
  }
  &:hover {
    background-color: ${({ theme, color }: { theme: Theme; color?: string }): string => color || theme.color.background.highlight};
  }
`;

const ClickableRowWrapper = styled(RowWrapper as any)`
  cursor: pointer;
`;

const Cell = styled.td`
  padding: 0.4rem;
  max-width: 19rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MediumCell = styled(Cell as any)`
  padding: 0.4rem;
  max-width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

const SmallCell = styled(Cell as any)`
  padding: 0.4rem;
  max-width: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

const RightSmallCell = styled(SmallCell as any)`
  text-align: right;
`;

const NoWrapCell = styled.td`
  padding: 0.4rem;
  white-space: nowrap;
`;

export {
  Cell,
  MediumCell,
  SmallHeader,
  Header,
  RowWrapper,
  NoWrapCell,
  ClickableRowWrapper,
  SmallCell,
  RightSmallCell,
};
export default TableWrapper;
