import React from 'react';
import styled from 'styled-components';
import {
  NarrowWrapper, Element, Name,
} from '#/shared/Card';
import { CandidateEvent } from '#/types/Ingestion';

const EVENT = 'Event';

interface CardProps {
  event: CandidateEvent;
}

const CandidateEventCard: React.FC<CardProps> = ({ event }) => {
  const {
    title, venue, event_starts_at, event_starts_at_local,
    event_start_time_status, autobroker_event_ids,
  } = event;
  const description = `${title}\n\n${venue}\n\n${event_starts_at_local}`;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{EVENT}</Name>
      </Element>
      {autobroker_event_ids.length > 0 && (
        <Element>
          <Name>
            {autobroker_event_ids.length > 1 ? 'AB Event' : 'Autobroker Event'}
            {autobroker_event_ids.map((id) => (
              <Link href={`/view/${id}`} key={`id_${id}`} rel="noopener noreferrer" target="_blank">{id}</Link>
            ))}
          </Name>
        </Element>
      )}
      <Element>
        <Name title={description}>{title}</Name>
      </Element>
      <Element>
        <Name>
          {(event_start_time_status === 'scheduled' || event_start_time_status === 'time_tbd')
            ? `Starting ${event_starts_at.toRelative()}`
            : 'TBD'}
        </Name>
      </Element>
    </NarrowWrapper>
  );
};

const Link = styled.a`
  padding-left: 0.35rem
`;

export default CandidateEventCard;
