import React from 'react';
import {
  Wrapper, Element, Name, WhiteSpaceValue,
} from '#/shared/Card';
import { OverrideChangeLogEntry } from '#/types/Override';
import { formatChanges } from '#/pages/ViewPage/components/OverrideLogs/OverrideLogsTable/utils/helpers';

const GROUPS = 'Grouped By';

interface CardProps {
  log: OverrideChangeLogEntry;
}

const GroupsCard: React.FC<CardProps> = ({ log }) => {
  const {
    groups,
  } = log;

  return (
    <Wrapper>
      <Element>
        <Name>{GROUPS}</Name>
        <WhiteSpaceValue>{formatChanges(groups)}</WhiteSpaceValue>
      </Element>
    </Wrapper>
  );
};

export default GroupsCard;
