import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PricingConstraintsButton from '../../PricingConstraintsButton';
import Button from '#/shared/Button';
import { ButtonsWrapper, Content } from '#/shared/modalComponents';

interface PricingConstraintsModalProps {
  closeModal: () => void;
  eventId: number;
  onSuccess: () => void;
}

const ADD_INTEGRATION_TEXT = 'Add Integration';
const GO_TO_EVENT = 'Skip And Go To Event';

const PricingConstraintsModal: React.FC<PricingConstraintsModalProps> = ({
  closeModal,
  eventId,
  onSuccess,
}) => {
  const [success, setModalSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const goToEvent = useCallback(async () => {
    closeModal();
    navigate(`/view/${eventId}`, { replace: true });
  }, [closeModal, eventId, navigate]);

  useState(() => {
    if (success)
      onSuccess();
  });

  return (
    <Wrapper>
      <Content>
        <WidgetWrapper>
          <PricingConstraintsButton
            eventId={eventId}
            hasPriceConstraints={false}
            setModalSuccess={setModalSuccess}
          />
        </WidgetWrapper>
      </Content>
      <ButtonsWrapper>
        <Button onClick={goToEvent}>{GO_TO_EVENT}</Button>
        <Button onClick={onSuccess}>
          {ADD_INTEGRATION_TEXT}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

const WidgetWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end
`;

export default PricingConstraintsModal;
