import React, { useCallback } from 'react';
import { type GroupBase, type OptionsOrGroups } from 'react-select';
import SelectForm, { Option } from './Forms/SelectForm';
import Action, { ConfigType } from './types';

type MarketplaceOptions = OptionsOrGroups<Option, GroupBase<Option>>;
interface Props {
  error: string;
  initialVal: string[];
  onChange: React.Dispatch<Action>;
  label: string;
  possibleMarketplaces: MarketplaceOptions;
  type: ConfigType;
}

const SelectMarketplaces: React.FC<Props> = ({
  error,
  initialVal,
  onChange,
  label,
  possibleMarketplaces,
  type,
}) => {
  const defaultValue = initialVal
    ? initialVal.map((el) => ({ label: el, value: el }))
    : [];

  const handleChange = useCallback(
    (input: Option[]): void => {
      const newValue = input && input.length > 0 ? input.map((el) => el.value) : null;

      onChange({ type, value: newValue });
    },
    [onChange, type],
  );

  return (
    <SelectForm
      defaultValue={defaultValue}
      error={error}
      isMulti
      label={label}
      onChange={handleChange}
      options={possibleMarketplaces}
    />
  );
};

export default SelectMarketplaces;
