import {
  Dispatch, useState, useMemo, useCallback,
} from 'react';
import { PriceDistributionListings } from '#/types/Preview';
import { Filters, filterListings, mapOptions } from '../utils';
import DropdownFormOption, { FilterField, Options } from '../types';
import { FormattedPricingChartDatum } from '../../types';

type Hook = (
  canonicalListings: PriceDistributionListings,
  marketplaceTransactions?: FormattedPricingChartDatum[],
) => {
  inventorySourceTypeOptions: Options;
  quantityOptions: Options;
  sellerIdOptions: Options;
  filteredListings: PriceDistributionListings;
  handleInventoryTypeFilter: Dispatch<DropdownFormOption[]>;
  handleQuantityFilter: Dispatch<DropdownFormOption[]>;
  handleSellerIdFilter: Dispatch<DropdownFormOption[]>;
  resetFilters: () => void;
  inventorySourceTypes: string[];
  quantities: number[];
  sellerIds: number[];
};

const useFilterListingsAndTransactions: Hook = (canonicalListings, marketplaceTransactions) => {
  const [inventorySourceTypes, setInventorySourceTypes] = useState<string[]>([]);
  const [quantities, setQuantities] = useState<number[]>([]);
  const [sellerIds, setSellerIds] = useState<number[]>([]);

  const { autobrokerListings, competitorListings } = canonicalListings;

  const inventorySourceTypeOptions = useMemo(() => {
    const allListings = [...autobrokerListings, ...competitorListings];

    return mapOptions(allListings, marketplaceTransactions, FilterField.INVENTORY_SOURCE_TYPE);
  }, [autobrokerListings, competitorListings, marketplaceTransactions]);

  const quantityOptions = useMemo(() => {
    const allListings = [...autobrokerListings, ...competitorListings];

    return mapOptions(allListings, marketplaceTransactions, FilterField.QUANTITY);
  }, [autobrokerListings, competitorListings, marketplaceTransactions]);

  const sellerIdOptions = useMemo(() => {
    return mapOptions(competitorListings, marketplaceTransactions, FilterField.SELLER_ID);
  }, [competitorListings, marketplaceTransactions]);

  const filteredListings = useMemo(() => {
    const filters: Filters = {
      quantity: quantities,
      inventorySourceType: inventorySourceTypes,
      sellerId: sellerIds,
    };

    const abFiltered = filterListings(autobrokerListings, filters);
    const compFiltered = filterListings(competitorListings, filters);

    return { autobrokerListings: abFiltered, competitorListings: compFiltered };
  }, [autobrokerListings, competitorListings, inventorySourceTypes, quantities, sellerIds]);

  const handleInventoryTypeFilter = useCallback(
    (selectedOptions: DropdownFormOption[] = []) => {
      if (selectedOptions) {
        setInventorySourceTypes(
          selectedOptions.map((el: DropdownFormOption) => el.value) as string[],
        );
      } else {
        setInventorySourceTypes([]);
      }
    },
    [setInventorySourceTypes],
  );

  const handleQuantityFilter = useCallback(
    (selectedOptions: DropdownFormOption[] = []) => {
      if (selectedOptions) {
        setQuantities(
          selectedOptions.map((el: DropdownFormOption) => el.value) as number[],
        );
      } else { setQuantities([]); }
    },
    [setQuantities],
  );

  const handleSellerIdFilter = useCallback(
    (selectedOptions: DropdownFormOption[] = []) => {
      if (selectedOptions)
        setSellerIds(selectedOptions.map((el: DropdownFormOption) => el.value) as number[]);
      else
        setSellerIds([]);
    },
    [setSellerIds],
  );

  const resetFilters = useCallback(() => {
    handleInventoryTypeFilter();
    handleQuantityFilter();
    handleSellerIdFilter();
  }, [handleInventoryTypeFilter, handleQuantityFilter, handleSellerIdFilter]);

  return {
    filteredListings,
    handleInventoryTypeFilter,
    handleQuantityFilter,
    handleSellerIdFilter,
    resetFilters,
    inventorySourceTypeOptions,
    quantityOptions,
    sellerIdOptions,
    inventorySourceTypes,
    quantities,
    sellerIds,
  };
};

export default useFilterListingsAndTransactions;
