import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { formatCurrency } from '#/shared/formatNumberForDisplay';
import Event from '#/types/Event';

interface CardProps {
  event: Event;
}

const PORTFOLIO_VALUE = 'Portfolio Value';
const AVG_VALUE = 'Avg. Value';
const NA = 'N/A';

const PortfolioValueCard: React.FC<CardProps> = ({ event }) => {
  const { portfolioValue, listingTicketCt } = event.summary;

  return (
    <NarrowWrapper>
      <Element>
        <Name>{PORTFOLIO_VALUE}</Name>
        <Value>{formatCurrency(portfolioValue)}</Value>
      </Element>
      <Element>
        <Name>{AVG_VALUE}</Name>
        <Value>
          {
            listingTicketCt === 0
              ? NA
              : formatCurrency(portfolioValue / listingTicketCt)
          }
        </Value>
      </Element>
    </NarrowWrapper>
  );
};

export default PortfolioValueCard;
