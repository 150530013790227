import DropdownFormOption, { Options } from '../types';
import mapOptions from './mapOptions';

const getSelectedOptions = (options: Options, selected: (string | number)[]):
DropdownFormOption[] => {
  const selectedValues = selected.map((s: (string | number)) => s.toString());

  return (options as DropdownFormOption[])
    .filter((o) => selectedValues.includes(o.value.toString()));
};

const palette = ['#1C71EF', '#F3685E', '#11A669', '#A20870'];

export { getSelectedOptions, palette, mapOptions };
export * from './dataFormatting';
export * from './getSharedExtents';
export * from './filterListings';
export * from './sampleListings';
