import {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import request from 'axios';
import getStakeholders from '#/api/getStakeholders';
import Stakeholder from '#/types/Stakeholder';
import formatApiError from '#/api/utils/formatApiError';
import { InventorySourceType } from '#/types/ReportingRollup';

interface Option<Type> {
  id: number;
  value: Type;
  label: string;
  logoUrl?: string;
  inventorySourceTypes?: InventorySourceType[];
}

interface StakeholderLogos {
  [key: string]: string;
}

interface StakeholderData {
  stakeholderLabels: Option<string>[];
  stakeholders: Stakeholder[];
  stakeholderError: string;
  stakeholderLogos: StakeholderLogos;
  fetchStakeholders: () => void;
}

const useFetchStakeholders = (autobrokerEventId?:number): StakeholderData => {
  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([]);
  const [stakeholderError, setStakeholderError] = useState<string>('');

  const fetchStakeholders = useCallback(async () => {
    try {
      const s = await getStakeholders(autobrokerEventId);

      setStakeholders(s);
    } catch (err) {
      if (request.isAxiosError(err))
        setStakeholderError(err?.response?.status?.toString());
      else
        setStakeholderError(formatApiError(err));
    }
  }, [autobrokerEventId, setStakeholderError, setStakeholders]);

  useEffect(() => {
    fetchStakeholders();
  }, [fetchStakeholders]);

  const stakeholderLabels = useMemo(() => {
    return stakeholders.map((stakeholder) => ({
      id: stakeholder.id,
      value: stakeholder.name,
      label: stakeholder.displayName,
      logoUrl: stakeholder.logoUrl,
      inventorySourceTypes: stakeholder.inventorySourceTypes,
    }));
  }, [stakeholders]);

  const stakeholderLogos = useMemo(() => {
    return stakeholders.reduce((acc, stakeholder) => {
      acc[stakeholder.name] = stakeholder.logoUrl;

      return acc;
    }, {} as StakeholderLogos);
  }, [stakeholders]);

  return {
    stakeholderLabels,
    stakeholders,
    stakeholderError,
    stakeholderLogos,
    fetchStakeholders,
  };
};

export default useFetchStakeholders;
export { Option, StakeholderData, StakeholderLogos };
