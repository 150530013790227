import React, { useCallback } from 'react';

import Listing from '#/types/Listing';
import {
  Wrapper, Section, Header, Title, Right, Button, EditImg, CancelledImg,
} from '#/shared/multiSelectDetail';

interface MultiListingsDetailProps {
  canEdit: boolean;
  listings: Listing[];
  setOverrideListingModal: (listings: Listing[]) => void;
  closeDetail: () => void;
}

const MultiListingsDetail: React.FC<MultiListingsDetailProps> = ({
  canEdit,
  listings,
  setOverrideListingModal,
  closeDetail,
}) => {
  const openEditModal = useCallback(() => {
    setOverrideListingModal(listings);
  }, [setOverrideListingModal, listings]);

  return (
    <Wrapper>
      <Section>
        <Header>
          <Title>{`${listings.length} Listings: ${listings.map((i) => `${i.source_inventory_id}`).join(', ')}`}</Title>
          {canEdit && (
            <Right>
              <Button onClick={openEditModal} title="Edit Listing">
                <EditImg />
              </Button>
            </Right>
          )}
          <Right>
            <Button onClick={closeDetail} title="Close Listing Detail">
              <CancelledImg />
            </Button>
          </Right>
        </Header>
      </Section>
    </Wrapper>
  );
};

export default MultiListingsDetail;
