import ApiSnapshot, {
  SelldownGraphData,
  CurrentStateData,
  GraphPlot,
  SelldownGraphInfo,
} from '../../types/Snapshot';
import formatCurrentStateData from './formatCurrentStateData';
import calcGraphDomain from './calcGraphDomain';
import calcGraphDomainHistorical from './calcGraphDomainHistorical';

interface SnapshotData {
  actualUnsoldTickets?: GraphPlot;
  ticketsAdjusted: GraphPlot;
  ticketsNaive: GraphPlot;
  ticketsRemaining: GraphPlot;
  ticketsSelldown: GraphPlot;
  ticketsTarget: GraphPlot;
}
/* eslint-disable camelcase */

const formatSelldownSnapshot = (rawSnapshot: ApiSnapshot, linear: boolean): SnapshotData => {
  const {
    daysToEvent, logHoursToEvent, projected, actual, model,
  } = rawSnapshot;
  const {
    ticketsAdjusted,
    ticketsSelldown,
    ticketsNaive,
    ticketsTarget,
  } = projected;
  const actualUnsold = actual?.sales?.ticketsUnsoldCt;
  const ticketsRemaining = model?.ticketsRemaining || null;
  const x = (linear) ? daysToEvent : logHoursToEvent;

  const response: SnapshotData = {
    actualUnsoldTickets: { x: null, y: null },
    ticketsAdjusted: { x, y: ticketsAdjusted },
    ticketsNaive: { x, y: ticketsNaive },
    ticketsRemaining: { x, y: ticketsRemaining },
    ticketsSelldown: { x, y: ticketsSelldown },
    ticketsTarget: { x, y: ticketsTarget },
  };

  if (actualUnsold)
    response.actualUnsoldTickets = { x, y: actualUnsold };

  return response;
};

const formatSelldownSnapshots = (
  rawSnapshots: ApiSnapshot[],
): SelldownGraphInfo => {
  const graphData: SelldownGraphData = {
    actualUnsoldTickets: [],
    ticketsAdjusted: [],
    ticketsNaive: [],
    ticketsRemaining: [],
    ticketsSelldown: [],
    ticketsTarget: [],
  };
  const graphDataDaysX: SelldownGraphData = {
    actualUnsoldTickets: [],
    ticketsAdjusted: [],
    ticketsNaive: [],
    ticketsRemaining: [],
    ticketsSelldown: [],
    ticketsTarget: [],
  };

  let current = null;

  rawSnapshots.reduce(
    (
      accumulator: SelldownGraphData,
      rawSnapshot: ApiSnapshot,
    ): SelldownGraphData => {
      if (rawSnapshot?.model)
        current = rawSnapshot;

      const snapshot = formatSelldownSnapshot(rawSnapshot, false);
      const lineTypes = Object.keys(snapshot) as Array<keyof SnapshotData>;

      lineTypes.forEach((line) => {
        const dataArr: GraphPlot[] = accumulator[line];

        dataArr.push(snapshot[line]);
      });
      return accumulator;
    },
    graphData,
  );

  rawSnapshots.reduce(
    (
      accumulator: SelldownGraphData,
      rawSnapshot: ApiSnapshot,
    ): SelldownGraphData => {
      const snapshot = formatSelldownSnapshot(rawSnapshot, true);
      const lineTypes = Object.keys(snapshot) as Array<keyof SnapshotData>;

      lineTypes.forEach((line) => {
        const dataArr: GraphPlot[] = accumulator[line];

        dataArr.push(snapshot[line]);
      });
      return accumulator;
    },
    graphDataDaysX,
  );

  const currentStateData: CurrentStateData = formatCurrentStateData(current);

  // Standard selldown graph domain represents log-hours-to-event for x-axis
  const graphDomain = calcGraphDomain(graphData);

  // Domain for days x-axis
  const graphDomainDays = calcGraphDomain(graphDataDaysX);

  // Domain adjusted to zoom in on actual sales period
  const graphDomainHistorical = calcGraphDomainHistorical(
    graphDataDaysX,
    graphDomainDays,
  );

  return {
    currentStateData,
    graphData,
    graphDataDaysX,
    graphDomain,
    graphDomainHistorical,
  };
};

export default formatSelldownSnapshots;
