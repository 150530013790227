import {
  ListingsGraphData,
  PriceRatioGraphData,
  SelldownGraphData,
} from '#/types/Snapshot';
import palette from '#/theme/palettes/main';
import {
  PriceDistributionGraphData,
  PriceDistributionListings,
} from '#/types/Preview';

/* eslint-disable sort-keys */

const MILLE = 1000;

const ZERO = 0;

const SELLDOWN_GRAPH_KEYS: Array<keyof SelldownGraphData> = [
  'ticketsAdjusted',
  'ticketsNaive',
  'ticketsRemaining',
  'ticketsSelldown',
  'actualUnsoldTickets',
];

const TARGET_ACTUAL_KEYS: Array<keyof SelldownGraphData> = ['ticketsTarget'];

const TARGET_OVERLAY_KEYS: Array<keyof SelldownGraphData> = [
  'ticketsTargetOverlay',
];

const SELLDOWN_LEGEND_KEYS: Array<keyof SelldownGraphData> = [
  'actualUnsoldTickets',
  'ticketsSelldown',
  'ticketsAdjusted',
  'ticketsRemaining',
  'ticketsNaive',
  'ticketsTarget',
];

const PRICE_RATIO_GRAPH_KEYS: Array<keyof PriceRatioGraphData> = [
  'actualABLogPriceRatio',
  'actualABPercentilesMedium',
  'actualABPercentilesNarrow',
  'actualABPercentilesWide',
  'actualCompetitorLogPriceRatio',
  'actualCompetitorPercentilesMedium',
  'actualCompetitorPercentilesNarrow',
  'actualCompetitorPercentilesWide',
  'modelLogPriceRatio',
  'targetLogPriceRatio',
];

const PRICE_RATIO_LEGEND_KEYS: Array<keyof PriceRatioGraphData> = [
  'actualABLogPriceRatio',
  'actualCompetitorLogPriceRatio',
  'modelLogPriceRatio',
  'targetLogPriceRatio',
];

const LISTINGS_KEYS: Array<keyof ListingsGraphData> = [
  'actualCompetitorListingCount',
  'actualCompetitorNormalizedListingCount',
  'actualABListingCount',
  'actualABNormalizedListingCount',
];

const PRICE_DISTRIBUTION_AB_COMP_KEYS: Array<keyof PriceDistributionGraphData> = [
  'competitorMedium',
  'competitorNarrow',
  'competitorWide',
  'competitorTarget',
  'autobrokerMedium',
  'autobrokerNarrow',
  'autobrokerWide',
  'autobrokerTarget',
];

const PRICE_DISTRIBUTION_LISTING_KEYS: Array<keyof PriceDistributionListings> = [
  'autobrokerListings',
  'competitorListings',
];

const MODEL_ACTUAL_KEYS: Array<keyof PriceDistributionGraphData> = [
  'modelMedium',
  'modelNarrow',
  'modelWide',
  'modelTarget',
];

const MODEL_OVERLAY_KEYS: Array<keyof PriceDistributionGraphData> = [
  'modelMediumOverlay',
  'modelNarrowOverlay',
  'modelWideOverlay',
  'modelTargetOverlay',
];

const PRICE_DISTRIBUTION_LEGEND_KEYS: Array<string> = [
  'autobroker',
  'competitor',
  'model',
];

const DATA_PALETTE = {
  // Selldown graph
  actualUnsoldTickets: palette.gray.base,
  ticketsAdjusted: palette.salmon.base,
  ticketsNaive: palette.mint.base,
  ticketsRemaining: palette.blue.dark,
  ticketsSelldown: palette.cyan.base,
  ticketsTarget: palette.purple.base,
  ticketsTargetOverlay: palette.purple.base,
  // Price Ratio Graph
  actualABLogPriceRatio: palette.cyan.base,
  actualABPercentilesMedium: palette.cyan.base,
  actualABPercentilesNarrow: palette.cyan.base,
  actualABPercentilesWide: palette.cyan.base,
  actualCompetitorLogPriceRatio: palette.salmon.base,
  actualCompetitorPercentilesMedium: palette.salmon.base,
  actualCompetitorPercentilesNarrow: palette.salmon.base,
  actualCompetitorPercentilesWide: palette.salmon.base,
  targetLogPriceRatio: palette.gray.base,
  modelLogPriceRatio: palette.mint.dark,
  // Listings Graph
  actualABListingCount: palette.blue.base,
  actualABNormalizedListingCount: palette.cyan.base,
  actualCompetitorListingCount: palette.red.base,
  actualCompetitorNormalizedListingCount: palette.salmon.base,
  // Price Distribution Graph
  autobroker: palette.cyan.base,
  autobrokerListings: palette.cyan.dark,
  autobrokerMedium: palette.cyan.base,
  autobrokerNarrow: palette.cyan.base,
  autobrokerTarget: palette.cyan.base,
  autobrokerWide: palette.cyan.base,
  competitor: palette.salmon.base,
  competitorListings: palette.salmon.dark,
  competitorMedium: palette.salmon.base,
  competitorNarrow: palette.salmon.base,
  competitorTarget: palette.salmon.base,
  competitorWide: palette.salmon.base,
  model: palette.mint.dark,
  modelMedium: palette.mint.dark,
  modelNarrow: palette.mint.dark,
  modelTarget: palette.mint.dark,
  modelWide: palette.mint.dark,
  modelMediumOverlay: palette.mint.dark,
  modelNarrowOverlay: palette.mint.dark,
  modelTargetOverlay: palette.mint.dark,
  modelWideOverlay: palette.mint.dark,
  // Current State Data Line
  currentStateData: palette.red.base,
};

/* eslint-enable sort-keys */

enum ComponentType {
  area = 'area',
  line = 'line',
  scatter = 'scatter',
}

const priceRatioDataVisual = {
  actualABLogPriceRatio: ComponentType.line,
  actualABPercentilesMedium: ComponentType.area,
  actualABPercentilesNarrow: ComponentType.area,
  actualABPercentilesWide: ComponentType.area,
  actualCompetitorLogPriceRatio: ComponentType.line,
  actualCompetitorPercentilesMedium: ComponentType.area,
  actualCompetitorPercentilesNarrow: ComponentType.area,
  actualCompetitorPercentilesWide: ComponentType.area,
  targetLogPriceRatio: ComponentType.scatter,
  modelLogPriceRatio: ComponentType.scatter,
};

export {
  ComponentType,
  DATA_PALETTE,
  LISTINGS_KEYS,
  MILLE,
  MODEL_ACTUAL_KEYS,
  MODEL_OVERLAY_KEYS,
  PRICE_DISTRIBUTION_AB_COMP_KEYS,
  PRICE_DISTRIBUTION_LISTING_KEYS,
  PRICE_DISTRIBUTION_LEGEND_KEYS,
  PRICE_RATIO_GRAPH_KEYS,
  PRICE_RATIO_LEGEND_KEYS,
  SELLDOWN_GRAPH_KEYS,
  SELLDOWN_LEGEND_KEYS,
  TARGET_ACTUAL_KEYS,
  TARGET_OVERLAY_KEYS,
  ZERO,
};
export default priceRatioDataVisual;
