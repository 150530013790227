import client from './_client';

const getLogout = async (): Promise<boolean> => {
  try {
    await client.get('/auth/logout');
    return true;
  } catch (err) {
    return false;
  }
};

export default getLogout;
