import React from 'react';
import styled from 'styled-components';

const RETRY = 'Retry';

interface ErrorProps {
  copy: string;
  retry: () => void;
}

const Error: React.FC<ErrorProps> = ({ copy, retry }) => (
  <CenterContainer>
    <ErrorCopy>{copy}</ErrorCopy>
    <RetryButton onClick={retry} type='button'>
      {RETRY}
    </RetryButton>
  </CenterContainer>
);

const ErrorCopy = styled.div`
  white-space: pre;
  overflow-y: auto;
`;

const RetryButton = styled.button`
  cursor: pointer;
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.light};
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  ${({ theme }: { theme: Theme }): string => theme.text1};
  border: 1px solid
    ${({ theme }: { theme: Theme }): string => theme.color.border.light};
  background-color: ${({ theme }: { theme: Theme }): string => theme.color.action.primary};
  transition: all 0.2s ease;
  :hover {
    background-color: ${({ theme }: { theme: Theme }): string => theme.color.action.highlight};
  }
`;

const CenterContainer = styled.div`
  height: 100%;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }: { theme: Theme }): string => theme.text1};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
`;

export default Error;
