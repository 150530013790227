import React, { useContext, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import { User } from '#/types/User';
import Loader from '#/shared/Loader';
import palette from '#/theme/palettes/main';
import useFetchPriceLevels from './useFetchPriceLevels';
import useFetchPriceList from './useFetchPriceList';
import PriceLevelsTableAndDetail from './PriceLevelsTableAndDetail';
import { PriceGranularity } from '#/types/Event';
import { StakeholderLogos } from '#/pages/useFetchStakeholders';

const PRICE_LEVELS = 'Price Levels';

interface PriceLevelsProps {
  user: User;
  error: boolean;
  loading: boolean;
  stakeholderLogos: StakeholderLogos;
}

const PriceLevels: React.FC<PriceLevelsProps> = ({
  user, error, loading, stakeholderLogos,
}) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const autobrokerEventId = useMemo(() => activeEvent?.id, [activeEvent]);
  const showPrices = useMemo(() => activeEvent?.sourceEvents.filter(
    (s) => s.candidatePriceGranularities?.includes(PriceGranularity.PRICE_LEVEL),
  ).length > 0,
  [activeEvent]);

  // There are typically not very many price levels, so we can fetch them all at once
  // so set pagination to the max value that is allowed.
  const priceLevelsHook = useFetchPriceLevels({ autobrokerEventId, perPage: 500 });
  const priceListHook = useFetchPriceList(autobrokerEventId);

  const {
    filters, setFilters, showResults,
  } = priceLevelsHook;

  useEffect(() => {
    const updateAutobrokerEventId = (id: number): void => {
      setFilters({
        ...filters,
        autobrokerEventId: id,
      });
    };

    if (autobrokerEventId)
      updateAutobrokerEventId(autobrokerEventId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autobrokerEventId]);

  const showPriceLevelResults = useMemo(() => {
    return autobrokerEventId && !error && !loading && showResults;
  }, [autobrokerEventId, error, loading, showResults]);

  return (
    <div id="price-levels">
      <Wrapper>
        <Section>
          <Title>{PRICE_LEVELS}</Title>
          {showPriceLevelResults && (
            <PriceLevelsTableAndDetail
              priceLevelsHook={priceLevelsHook}
              priceListHook={priceListHook}
              showPrices={showPrices}
              stakeholderLogos={stakeholderLogos}
              user={user}
            />
          )}
        </Section>
        {!showPriceLevelResults && (
          <Section>
            <Loader hexColor={palette.brand.base} />
          </Section>
        )}
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
    width: 77.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 6rem;
  `;

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: left;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 8rem;
  width: 100%;
  margin-top: 3.75rem;
`;

export default PriceLevels;
