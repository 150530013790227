import React from 'react';
import Graph from './Graph';
import { ListingsGraphInfo } from '#/types/Snapshot';
import Loading from '../sharedGraphComponents/Loading';
import Error from '../sharedGraphComponents/Error';

interface ListingsGraphProps {
  error: string;
  loading: boolean;
  listingsGraphInfo: ListingsGraphInfo;
  showGraph: boolean;
}

const ListingsGraph: React.FC<ListingsGraphProps> = ({
  error,
  listingsGraphInfo,
  loading,
  showGraph,
}) => {
  const okToRender = showGraph && !loading && !error;

  return (
    <>
      {okToRender && (
        <Graph
          graphInfo={listingsGraphInfo}
        />
      )}
      <Loading loading={loading} showGraph={showGraph} />
      <Error error={error} showGraph={showGraph} />
    </>
  );
};

export default ListingsGraph;
