import { ToRelativeOptions } from 'luxon';
import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { EventErrorType } from '#/types/Errors';
import Event from '#/types/Event';
import { GreenEllipse, YellowEllipse, RedEllipse } from '#/shared/Ellipse';

interface CardProps {
  event: Event;
}

const SELLDOWN_MODEL_ERROR = 'Selldown Proj. Model';
const ONSALE_PRICING_MODEL = 'Onsale Pricing Model';
const dt = { style: 'short' } as ToRelativeOptions;

const PendingModelCard: React.FC<CardProps> = ({ event }) => {
  const onsale = event.models.onsalePricingModel;
  const onsaleError = (
    event.eventErrors
      .filter((e) => e.errorType === EventErrorType.ONSALE_MODEL_ERROR)[0]
  );
  const selldown = event.models.selldownProjectionModel;
  const selldownError = (
    event.eventErrors
      .filter((e) => e.errorType === EventErrorType.SELLDOWN_MODEL_ERROR)[0]
  );
  const pending = (
    (onsaleError || selldownError)
      ? 'incomplete'
      : 'pending'
  );

  let OnsaleStatus = YellowEllipse;
  let onsaleStatusText = pending;

  if (onsale) {
    OnsaleStatus = GreenEllipse;
    onsaleStatusText = `done: ${onsale.createdAt.toRelative(dt)}`;
  } else if (onsaleError) {
    OnsaleStatus = RedEllipse;
    onsaleStatusText = `error: ${onsaleError.createdAt.toRelative(dt)}`;
  }

  let SelldownStatus = YellowEllipse;
  let selldownStatusText = pending;

  if (selldown) {
    SelldownStatus = GreenEllipse;
    selldownStatusText = `done: ${selldown.createdAt.toRelative(dt)}`;
  } else if (selldownError) {
    SelldownStatus = RedEllipse;
    selldownStatusText = `error: ${selldownError.createdAt.toRelative(dt)}`;
  }

  return (
    <NarrowWrapper>
      <Element>
        <Name>
          <SelldownStatus />
          {SELLDOWN_MODEL_ERROR}
        </Name>
        <Value>{selldownStatusText}</Value>
      </Element>
      <Element>
        <Name>
          <OnsaleStatus />
          {ONSALE_PRICING_MODEL}
        </Name>
        <Value>{onsaleStatusText}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default PendingModelCard;
