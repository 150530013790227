import React, { useContext } from 'react';
import { Dropdown, Item } from '#/shared/clientReporting/Dropdown';
import { Label } from '#/shared/clientReporting/ListBox';
import { Label as InputLabel } from '#/shared/clientReporting/Inputs';
import { startCase } from 'lodash';
import {
  TAKE_KEY, METRIC_INPUT_LABEL, METRIC_OPTIONS, LIFT_KEY,
} from '../utils/menuOptions';
import { CollectionSalesDataContext, CollectionSelectionsContext } from '../contexts';
import { Tab } from '../constants';

interface MetricSelectionMenuProps {
  isLoading?: boolean;
}

const MetricSelectionMenu: React.FC<MetricSelectionMenuProps> = ({ isLoading }) => {
  const {
    activeTab,
    hasDealTerm,
    metricSelection,
    handleMetricSelection,
  } = useContext(CollectionSelectionsContext);
  const { dateFilters } = useContext(CollectionSelectionsContext);
  const hasTransactionDateFilter = dateFilters?.transactionAtMin || dateFilters?.transactionAtMax;
  const excludeTicketsUnsold = activeTab === Tab.TIME_BINS || hasTransactionDateFilter;

  const { trends } = useContext(CollectionSalesDataContext);
  const excludeLift = activeTab === Tab.TRENDS
    && trends?.salesTrendsByEvent
      .every((d) => Number.isNaN(d[LIFT_KEY]) || typeof d[LIFT_KEY] === 'undefined');

  let availableOptions = hasDealTerm
    ? METRIC_OPTIONS
    : METRIC_OPTIONS.filter((option) => option.key !== TAKE_KEY);

  availableOptions = excludeLift
    ? availableOptions.filter((option) => option.key !== LIFT_KEY)
    : availableOptions;

  availableOptions = excludeTicketsUnsold
    ? availableOptions.filter((option) => option.key !== 'cumulativeTicketsUnsoldPercent')
    : availableOptions;

  return (
    <>
      <InputLabel isLoading={isLoading}>{METRIC_INPUT_LABEL}</InputLabel>
      <Dropdown
        aria-label='event-collection-overlaid-metric-selection'
        id='event-collection-overlaid-metric-menu'
        isLoading={isLoading}
        items={availableOptions}
        onSelectionChange={handleMetricSelection}
        selectedKey={metricSelection.key}
      >
        {({ key, label }): JSX.Element => (
          <Item
            key={key}
            textValue={startCase(key)}
          >
            <Label maxWidth={180}>
              {label}
            </Label>
          </Item>
        )}
      </Dropdown>
    </>
  );
};

export default MetricSelectionMenu;
