import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { SinkSummary, SinkEvent } from '#/types/Event';
import QA from '#images/QA.svg';
import Peakpass from '#images/Peakpass.svg';
import Oneticket from '#images/Oneticket.svg';
import SroSeatLevelPricing from '#images/SroSeatLevelPricing.svg';
import SroPriceLevelPricing from '#images/SroPriceLevelPricing.svg';
import Ticketvault from '#images/Ticketvault.svg';
import Consumer from '#images/ConsumerUploader.svg';

interface CardProps {
  summary: SinkSummary;
  sinkEvents: SinkEvent[];
}

const LISTINGS = 'Visible Listings';
const TICKETS = 'Visible Listed Tickets';

const SinkCard: React.FC<CardProps> = ({ summary, sinkEvents }) => {
  const qaMode = sinkEvents.some((se) => se.qaMode);

  let icon = null;

  switch (summary.sinkName) {
    case '/listings': {
      icon = Peakpass;
      break;
    }
    case 'oneticket': {
      icon = Oneticket;
      break;
    }
    case 'sro-seat-level-pricing': {
      icon = SroSeatLevelPricing;
      break;
    }
    case 'sro-price-level-pricing': {
      icon = SroPriceLevelPricing;
      break;
    }
    case 'consumer': {
      icon = Consumer;
      break;
    }
    default: {
      icon = Ticketvault;
      break;
    }
  }

  return (
    <NarrowWrapper>
      <Element>
        <Name>{LISTINGS}</Name>
        <Value>{summary.listingCt}</Value>
      </Element>
      <Element>
        <Name>{TICKETS}</Name>
        <Value>{summary.listingTicketCt}</Value>
      </Element>
      <Name>
        {icon !== null && (
          <img
            alt={summary.sinkName}
            height="15"
            src={icon}
            title={summary.sinkName}
          />
        )}
        {qaMode && (
          <img
            alt="qa-mode"
            height="15"
            src={QA}
            title="qa mode"
          />
        )}
        {` ${summary.sinkName}`}
      </Name>
    </NarrowWrapper>
  );
};

export default SinkCard;
