import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import Ingestion from '#/types/Ingestion';

const SECTION_ROW = 'Section • Row';
const SEATS = 'Seats';
const SPLITS_RULE = 'Splits Rule';
const PRICE_LEVEL = 'Price Level';

interface CardProps {
  ingestion: Ingestion;
}

const SeatsCard: React.FC<CardProps> = ({ ingestion }) => {
  return (
    <NarrowWrapper>
      <Element>
        <Name>{SECTION_ROW}</Name>
        <Value>{`${ingestion.section} • ${ingestion.row}`}</Value>
      </Element>
      {ingestion.price_level_name && (
        <Element>
          <Name>{PRICE_LEVEL}</Name>
          <Value>{ingestion.price_level_name}</Value>
        </Element>
      )}
      <Element>
        <Name>{SEATS}</Name>
        <Value>{`${ingestion.start_seat}-${ingestion.end_seat}`}</Value>
      </Element>
      {ingestion.splits_rule && !ingestion.price_level_name && (
        <Element>
          <Name>{SPLITS_RULE}</Name>
          <Name>{ingestion.splits_rule.split('_').join(' ')}</Name>
        </Element>
      )}
    </NarrowWrapper>
  );
};

export default SeatsCard;
