/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useMemo, ChangeEvent, useCallback,
} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import Select from 'react-select';
import {
  SectionWrapper,
  InnerWrapper,
  HorizontalWrapper,
  Title,
  Label,
  Error,
  Input,
  ButtonsWrapper,
  Content,
  modalMenuProps,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import postListingOverride from '#/api/postListingOverride';
import { PostListingOverride } from '#/types/Override';
import { ListingPrice } from '#/types/Listing';
import { PRICE_CONSTRAINT_TYPES, PriceConstraintType } from '#/types/PriceConstraints';
import formatApiError from '#/api/utils/formatApiError';
import { InputListingOverride } from '#/types/Ingestion';
import { PricingBasis, PriceGranularity } from '#/types/Event';
import useSetupModal from '#/pages/useSetupModal';
import modalStyles from './modalStyles';
import type { GenericOption } from '#/types/GenericOption';

const TITLE = 'Submit Listing Override';
const DESCRIPTION = 'You may be able to fix this issue by submitting a listing override.';
const GRANULARITY = 'Granularity';

const SECTION = 'Section';
const ROW = 'Row';
const MAPKEY = 'MapKey';
const EXPECTED_VALUE = 'Expected Value';
const PRICE_CONSTRAINT = 'Price Constraint';
const IS_VISIBLE = 'Is Visible';
const ALL_IN_PRICE = 'All in Price';
const DISPLAY_PRICE = 'Display Price';
const BROADCAST_PRICE = 'Broadcast Price';
const NOTES = 'Notes';

const BOOLEAN_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const GRANULARITY_OPTIONS = [
  { value: PriceGranularity.INGESTION, label: 'Ingestion' },
  { value: PriceGranularity.LISTING, label: 'Listing' },
  { value: PriceGranularity.ROW, label: 'Row' },
  { value: PriceGranularity.SECTION, label: 'Section' },
  { value: PriceGranularity.PRICE_LEVEL, label: 'Price Level' },
  { value: PriceGranularity.EVENT, label: 'Event' },
  { value: null, label: 'Current Override' },
];

const SUBMIT = 'Submit';
const CANCEL = 'Cancel';
const DELETE = 'Delete';

interface InputListing {
  autobroker_event_id: number;
  source_name: string;
  source_external_event_id: string;
  source_inventory_id: string;
  source_price_level_id?: string;
  section?: string;
  row?: string;
  mapkey?: string;
  expected_value?: number;
  price?: ListingPrice;
  override?: InputListingOverride;
  ingestion_listing_id?: number;
}

interface ListingOverrideModalProps {
  listings: InputListing[];
  closeModal: (refresh: boolean) => void;
}

const ListingOverrideModal: React.FC<ListingOverrideModalProps> = ({
  listings,
  closeModal,
}) => {
  const granularityOptions = GRANULARITY_OPTIONS
    .filter((g) => (
      // Support setting a given granularity if all listings have a source_inventory_id for it.
      listings.every((l) => !!getSourceInventoryId(l, g.value))
      // Always support setting event level granularity.
      || g.value === PriceGranularity.EVENT
      // Support modifying existing overrides if all listings have one
      || (
        g.value === null
        && listings.every((l) => (
          !!l.override
          && !l.override.is_empty
          && !!getSourceInventoryId(l, l.override.granularity)
        ))
      )
    ));
  const defaultGranularity = granularityOptions[0]?.value;

  const [error, setError] = useState<string>(null);
  const [notes, setNotes] = useState<string>(null);
  const [granularity, setGranularity] = useState<PriceGranularity>(defaultGranularity);
  const [override, setOverride] = useState<PostListingOverride[]>(
    listings.map((i) => {
      const listingGranularity = granularity
        ?? (i.override?.granularity ?? defaultGranularity);

      return {
        source_name: i.source_name,
        source_external_event_id: i.source_external_event_id,
        source_inventory_id: getSourceInventoryId(i, listingGranularity),
        granularity: listingGranularity,
        is_cascading: listingGranularity !== PriceGranularity.LISTING,
        section: i.override?.section,
        row: i.override?.row,
        mapkey: i.override?.mapkey,
        expected_value: i.override?.expected_value,
        price_constraint: i.override?.price_constraint,
        is_visible: i.override?.is_visible,
        all_in_price: i.override?.all_in_price,
        display_price: i.override?.display_price,
        broadcast_price: i.override?.broadcast_price,
      };
    }),
  );
  const deletions = useMemo(() => listings
    .filter((l) => (
      !!l.override
      && !l.override.is_empty
      && !!getSourceInventoryId(l, l.override.granularity)
    ))
    .map((l) => ({
      source_name: l.source_name,
      source_external_event_id: l.source_external_event_id,
      source_inventory_id: getSourceInventoryId(l, l.override.granularity),
      granularity: l.override.granularity,
      is_cascading: l.override.granularity !== PriceGranularity.LISTING,
    })), [listings]);

  const { autobroker_event_id } = listings[0];
  const priceConstraintOptions = PRICE_CONSTRAINT_TYPES.map((p) => ({ label: p, value: p }));

  const onChangeGranularity = useCallback((e: GenericOption<string, PriceGranularity>) => {
    const newGranularity = e?.value;

    setGranularity(newGranularity);
    setOverride((o) => o.map((i, idx) => {
      const listing = listings[idx];
      const listingGranularity = (
        newGranularity
        ?? (listing.override?.granularity ?? defaultGranularity)
      );

      return {
        ...i,
        source_inventory_id: getSourceInventoryId(listing, listingGranularity),
        granularity: listingGranularity,
        is_cascading: listingGranularity !== PriceGranularity.LISTING,
      };
    }));
  }, [defaultGranularity, listings, setOverride]);

  const onChangeSection = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const section = e.target.value || null;

    setOverride((o) => o.map((i) => ({ ...i, section })));
  }, [setOverride]);

  const onChangeRow = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const row = e.target.value || null;

    setOverride((o) => o.map((i) => ({ ...i, row })));
  }, [setOverride]);

  const onChangeMapKey = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const mapkey = e.target.value || null;

    setOverride((o) => o.map((i) => ({ ...i, mapkey })));
  }, [setOverride]);

  const onChangeExpectedValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const expected_value = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({
      ...i,
      expected_value,
    })));
  }, [setOverride]);

  const onChangeAllInPrice = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const all_in_price = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({
      ...i,
      all_in_price,
    })));
  }, [setOverride]);

  const onChangeDisplayPrice = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const display_price = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({
      ...i,
      display_price,
    })));
  }, [setOverride]);

  const onChangeBroadcastPrice = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const broadcast_price = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({
      ...i,
      broadcast_price,
    })));
  }, [setOverride]);

  const onChangeIsVisible = useCallback((e: GenericOption<string, boolean>) => {
    setOverride((o) => o.map((i) => ({ ...i, is_visible: e?.value })));
  }, [setOverride]);

  const onChangePriceConstraintType = useCallback(
    (e: GenericOption<PriceConstraintType, PriceConstraintType>) => {
      const newType = e?.value;

      setOverride((o) => o.map((i) => ({
        ...i,
        price_constraint: newType && {
          price_floor: null,
          price_ceiling: null,
          ...i.price_constraint,
          price_constraint_type: newType,
        },
      })));
    }, [setOverride],
  );

  const onChangePriceFloor = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const price_floor = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({
      ...i,
      price_constraint: i.price_constraint && {
        ...i.price_constraint,
        price_floor,
      },
    })));
  }, [setOverride]);

  const onChangePriceCeiling = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const price_ceiling = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({
      ...i,
      price_constraint: i.price_constraint && {
        ...i.price_constraint,
        price_ceiling,
      },
    })));
  }, [setOverride]);

  const onChangeNotes = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newNotes = e?.target?.value;

    setNotes(newNotes);
  }, [setNotes]);

  const onSubmit = useCallback(async () => {
    try {
      await postListingOverride(
        autobroker_event_id,
        // When using courser granularity than PriceGranularity.LISTING, we need to deduplicate
        // the overrides to avoid api errors from submitting duplicate entries.
        deduplicateOverrides(override),
        notes,
      );
      setError(null);
      closeModal(true);
    } catch (err) {
      setError(formatApiError(err));
    }
  }, [autobroker_event_id, closeModal, override, notes]);

  const onDelete = useCallback(async () => {
    try {
      await postListingOverride(
        autobroker_event_id,
        // When using courser granularity than PriceGranularity.LISTING, we need to deduplicate
        // the overrides to avoid api errors from submitting duplicate entries.
        deduplicateOverrides(deletions),
        notes,
      );
      setError(null);
      closeModal(true);
    } catch (err) {
      setError(formatApiError(err));
    }
  }, [autobroker_event_id, closeModal, deletions, notes]);

  const onCancel = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  useSetupModal(onCancel);

  return (
    <Modal
      isOpen
      onRequestClose={onCancel}
      style={modalStyles}
    >
      <Title>{TITLE}</Title>
      <SectionWrapper>
        <InnerWrapper>
          <Description>{DESCRIPTION}</Description>
        </InnerWrapper>
        <InnerWrapper>
          <Label>{GRANULARITY}</Label>
          <Select
            defaultValue={granularityOptions.find((o) => o.value === granularity)}
            onChange={onChangeGranularity}
            options={granularityOptions}
            {...modalMenuProps}
          />
        </InnerWrapper>
        <Content>
          <InnerWrapper>
            <Label>{SECTION}</Label>
            <Input
              defaultValue={listings[0].override?.section}
              onChange={onChangeSection}
              placeholder={listings[0].section}
              type='text'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{ROW}</Label>
            <Input
              defaultValue={listings[0].override?.row}
              onChange={onChangeRow}
              placeholder={listings[0].row}
              type='text'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{MAPKEY}</Label>
            <Input
              defaultValue={listings[0].override?.mapkey}
              onChange={onChangeMapKey}
              placeholder={listings[0].mapkey}
              type='text'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{EXPECTED_VALUE}</Label>
            <Input
              defaultValue={listings[0].override?.expected_value}
              onChange={onChangeExpectedValue}
              placeholder={listings[0].expected_value?.toFixed(2)}
              type='number'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{PRICE_CONSTRAINT}</Label>
            <Select
              defaultValue={listings[0].override?.price_constraint && (
                priceConstraintOptions.find(
                  (option) => option.value
                    === listings[0].override.price_constraint.price_constraint_type,
                )
              )}
              isClearable
              onChange={onChangePriceConstraintType}
              options={priceConstraintOptions}
              {...modalMenuProps}
            />
            {override.some((o) => o.price_constraint) && (
              <HorizontalWrapper>
                <Input
                  defaultValue={listings[0].override?.price_constraint?.price_floor}
                  onChange={onChangePriceFloor}
                  placeholder='Price Floor'
                  type='number'
                />
                <Input
                  defaultValue={listings[0].override?.price_constraint?.price_ceiling}
                  onChange={onChangePriceCeiling}
                  placeholder='Price Ceiling'
                  type='number'
                />
              </HorizontalWrapper>
            )}
          </InnerWrapper>
          <InnerWrapper>
            <Label>{IS_VISIBLE}</Label>
            <Select
              defaultValue={listings[0].override && (
                BOOLEAN_OPTIONS.find((option) => option.value === listings[0].override.is_visible)
              )}
              isClearable
              onChange={onChangeIsVisible}
              options={BOOLEAN_OPTIONS}
              {...modalMenuProps}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{ALL_IN_PRICE}</Label>
            <Input
              defaultValue={listings[0].override?.all_in_price}
              onChange={onChangeAllInPrice}
              placeholder={
                (listings[0].price && listings[0].price.basis === PricingBasis.ALL_IN_PRICE)
                  ? listings[0].price.amount?.toFixed(2)
                  : ''
              }
              type='number'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{DISPLAY_PRICE}</Label>
            <Input
              defaultValue={listings[0].override?.display_price}
              onChange={onChangeDisplayPrice}
              placeholder={
                (listings[0].price && listings[0].price.basis === PricingBasis.DISPLAY_PRICE)
                  ? listings[0].price.amount?.toFixed(2)
                  : ''
              }
              type='number'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{BROADCAST_PRICE}</Label>
            <Input
              defaultValue={listings[0].override?.broadcast_price}
              onChange={onChangeBroadcastPrice}
              type='number'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{NOTES}</Label>
            <Input
              onChange={onChangeNotes}
              type='text'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Error>{error}</Error>
          </InnerWrapper>
        </Content>
        <ButtonsWrapper>
          <Button onClick={onSubmit}>{SUBMIT}</Button>
          <Button onClick={onCancel}>{CANCEL}</Button>
          <Button disabled={deletions.length === 0} onClick={onDelete}>{DELETE}</Button>
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

const getSourceInventoryId = (listing: InputListing, granularity: PriceGranularity): string => {
  const mapkeySection = listing.mapkey?.split('s:')[1].split(' ')[0];

  switch (granularity) {
    case PriceGranularity.LISTING:
      return listing.source_inventory_id;
    case PriceGranularity.INGESTION:
      return (
        listing.ingestion_listing_id
        && String(listing.ingestion_listing_id)
      );
    case PriceGranularity.ROW:
      return listing.mapkey;
    case PriceGranularity.SECTION:
      return mapkeySection ?? listing.section;
    case PriceGranularity.PRICE_LEVEL:
      return listing.source_price_level_id;
    case PriceGranularity.EVENT:
      return '';
    default:
      return undefined;
  }
};

const deduplicateOverrides = (overrides: PostListingOverride[]): PostListingOverride[] => {
  const seen: Record<string, boolean> = {};

  return overrides.filter((o) => {
    const key = `${o.source_inventory_id}-${o.granularity}`;

    if (seen[key])
      return false;

    seen[key] = true;
    return true;
  });
};

const Description = styled.p``;

export default ListingOverrideModal;
export { InputListing };
