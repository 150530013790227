import { AxiosResponse } from 'axios';
import client from './_client';
import { PostIngestion } from '#/types/Ingestion';

interface APIResponse {
  ingestion_listing_id: number;
}

type APICall = (ingestion: PostIngestion) => Promise<number>;

const postIngestionListing: APICall = async (ingestion: PostIngestion) => {
  const response: AxiosResponse<APIResponse> = await client.post('/proxy/ingestion_listing', ingestion);

  const id = response.data.ingestion_listing_id;

  return id;
};

export default postIngestionListing;
