import React from 'react';
import { YAxisLabel } from '../../sharedGraphComponents/styledComponents';
import { SelldownViewMode } from '#/types/SelldownByExpectedValue';

const X_AXIS_LABEL = 'Time to event';
const TICKETS_REMAINING = 'Tickets remaining';
const Y_AXIS_LABEL = <YAxisLabel>{TICKETS_REMAINING}</YAxisLabel>;
const TOOLTIP_POSITION = 'top';
const DASH_ARRAYS = ['0', '3,2'];
const SELLDOWN_VIEW_OPTIONS = Object.values(SelldownViewMode).map((v) => ({
  label: v,
}));
const IDENTITY_KEY = (): number => 0;

export {
  X_AXIS_LABEL,
  Y_AXIS_LABEL,
  TICKETS_REMAINING,
  TOOLTIP_POSITION,
  DASH_ARRAYS,
  SELLDOWN_VIEW_OPTIONS,
  IDENTITY_KEY,
};
