import React, { useCallback } from 'react';
import styled from 'styled-components';

import { SmallTag } from '#/shared/Card';
import palette from '#/theme/palettes/main';
import {
  Header, Title, Right, Button, CancelledImg, EditImg,
} from '#/shared/Detail';
import Listing from '#/types/Listing';
import { InputListing } from '#/shared/ListingOverrideModal';

const RESERVED = 'reserved';
const VISIBLE = 'visible';

interface ListingDetailHeaderProps {
  listing: Listing;
  setOverrideListingModal: (listings: InputListing[]) => void;
  closeDetail: () => void;
  closeable: boolean;
  canEdit: boolean;
}

const ListingDetailHeader: React.FC<ListingDetailHeaderProps> = ({
  listing,
  setOverrideListingModal,
  closeDetail,
  closeable,
  canEdit,
}) => {
  const {
    source_name, source_inventory_id, updated_at,
  } = listing;

  const openOverrideModal = useCallback(() => {
    setOverrideListingModal([listing]);
  }, [setOverrideListingModal, listing]);

  return (
    <Header>
      <Title>
        {source_name + ' listing ' + source_inventory_id}
      </Title>
      {listing.reserved_at && (
        <Right>
          <GoldTag title="reserved">{RESERVED}</GoldTag>
        </Right>
      )}
      {listing.sinks.length > 0 && (
        <Right>
          <GreenTag title="visible">{VISIBLE}</GreenTag>
        </Right>
      )}
      <Right>
        <BlueTag title={`updated ${updated_at.toRelative()}`}>
          {'Updated ' + updated_at.toRelative()}
        </BlueTag>
      </Right>
      {canEdit && (
        <Right>
          <Button onClick={openOverrideModal} title="edit listing">
            <EditImg />
          </Button>
        </Right>
      )}
      {closeable && (
        <Right>
          <Button onClick={closeDetail} title="close listing detail">
            <CancelledImg />
          </Button>
        </Right>
      )}
    </Header>
  );
};

const BlueTag = styled(SmallTag as any)`
  background-color: ${palette.blue.light24};
`;

const GreenTag = styled(SmallTag as any)`
  background-color: ${palette.mint.light24};
`;

const GoldTag = styled(SmallTag as any)`
  background-color: ${palette.gold.light56};
`;

export default ListingDetailHeader;
