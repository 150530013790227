import React from 'react';
import { User } from '#/types/User';
import MainGraph from './MainGraph';
import useFetchData from './useFetchData';

interface PricingProps {
  user: User;
}

const Pricing: React.FC<PricingProps> = ({ user }) => {
  const { initialSettings, parameters } = useFetchData();

  return (
    <MainGraph
      initialSettings={initialSettings}
      parameters={parameters}
      user={user}
    />
  );
};

export default Pricing;
