import { DateTime } from 'luxon';
import { ErrorStatusType, FormattedEventError } from '#/types/Errors';
import { APIEvent } from '#/types/Event';
import formatEventStartsAtString from './formatEventStartsAtString';

const mapEventErrors = (event: APIEvent, errorType: string): FormattedEventError[] => {
  const { config, summary } = event;

  const eventErrors: FormattedEventError[] = event.event_errors.filter((error) => (
    error.error_type === errorType
  ))
    .map((error) => {
      const { error_level, error_type } = error;
      const status = error.error_status.status === ErrorStatusType.NONE
        ? ErrorStatusType.TODO
        : error.error_status.status;
      const statusUpdated = error.error_status.created_at
        ? DateTime.fromISO(error.error_status.created_at, { zone: 'utc' })
        : null;
      const eventStartsAt = DateTime.fromISO(config.event_starts_at, { zone: 'utc' });
      const eventStartsAtLocal = config.event_starts_at_local
        ? DateTime.fromISO(config.event_starts_at_local, { zone: 'utc' })
        : eventStartsAt;
      const [
        eventStartDate,
        eventStartTime,
      ] = formatEventStartsAtString(eventStartsAt, eventStartsAtLocal);
      const eventStartsAtDisplay = `${eventStartDate}, ${eventStartTime}`;
      const portfolioValue = summary.portfolio_value;
      const ingestedUnsoldCost = summary.ingested_cost - summary.sale_cost;
      const stakeholders = summary.stakeholders;

      return {
        autobrokerEventId: config.autobroker_event_id,
        event: {
          title: config.title,
          venue: config.venue,
        },
        eventStartsAt,
        eventStartsAtDisplay,
        level: error_level,
        status,
        type: error_type,
        value: {
          portfolioValue,
          ingestedUnsoldCost,
        },
        stakeholders: stakeholders,
        statusUpdated,
      };
    });

  return eventErrors;
};

export default mapEventErrors;
