import {
  useCallback, useContext, useState, useEffect, useMemo,
} from 'react';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';
import postPreview from '#/api/postPreview';
import PriceDistributionGraphInfo from '#/types/Preview';
import { Settings, OverrideType } from '#/types/Override';
import { SelldownGraphInfo } from '#/types/Snapshot';
import formatApiError from '#/api/utils/formatApiError';

type Hook = (
  canonicalSettings: Settings
) => {
  clearOverlay: () => void;
  distributionOverlay: PriceDistributionGraphInfo;
  getPreviewOverlay: (
    settings: Settings,
    type: OverrideType,
    value: number
  ) => Promise<void>;
  overlayError: string;
  selldownOverlay: SelldownGraphInfo;
};

const usePostPreview: Hook = (canonicalSettings) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const eventId = useMemo(() => activeEvent?.id, [activeEvent]);
  const [distributionOverlay, setDistributionOverlay] = useState<
  PriceDistributionGraphInfo
  >(null);
  const [selldownOverlay, setSelldownOverlay] = useState<SelldownGraphInfo>(
    null,
  );
  const [overlayError, setOverlayError] = useState<string>('');

  const getPreviewOverlay = useCallback(
    async (settings: Settings, type: OverrideType, value: number) => {
      const updatedSettings = { ...settings, [type]: value };

      setOverlayError('');
      try {
        const overlayData = await postPreview(
          eventId,
          canonicalSettings,
          updatedSettings,
        );

        setDistributionOverlay(overlayData.priceDistribution);
        setSelldownOverlay(overlayData.selldown);
      } catch (err) {
        const errorString = formatApiError(err);

        setOverlayError(errorString);
      }
    },
    [eventId, canonicalSettings],
  );
  const clearOverlay = useCallback(() => {
    setDistributionOverlay(null);
    setSelldownOverlay(null);
  }, [setDistributionOverlay, setSelldownOverlay]);

  useEffect(() => {
    clearOverlay();
  }, [eventId, clearOverlay]);

  return {
    clearOverlay,
    distributionOverlay,
    getPreviewOverlay,
    overlayError,
    selldownOverlay,
  };
};

export default usePostPreview;
