/* eslint-disable import/max-dependencies */
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Ingestion from '#/types/Ingestion';
import Oneticket from '#images/Oneticket.svg';
import Ticketvault from '#images/Ticketvault.svg';
import Consumer from '#images/ConsumerUploader.svg';
import Peakpass from '#images/Peakpass.svg';
import UploadInProgress from '#images/UploadInProgress.svg';
import UploadFailed from '#images/UploadFailed.svg';
import UploadDone from '#images/UploadDone.svg';
import Notes from '#images/Notes.svg';
import Error from '#images/Error.svg';
import Listed from '#images/Listed.svg';
import Sale from '#images/Sale.svg';
import Sold from '#images/Sold.svg';
import WillCall from '#images/WillCall.svg';
import HardStock from '#images/HardStock.svg';
import MobileTransfer from '#images/MobileTransfer.svg';
import Pdf from '#images/Pdf.svg';
import Screenshot from '#images/Screenshot.svg';
import FlashSeats from '#images/FlashSeats.svg';
import Deleted from '#images/Deleted.svg';
import DeletionPending from '#images/DeletionPending.svg';
import Override from '#images/Override.svg';
import PendingOverride from '#images/PendingOverride.svg';
import BroadcastError from '#images/BroadcastError.svg';
import Pause from '#images/Pause.svg';
import Link from '#images/Link.svg';
import Vip from '#images/Vip.svg';
import Bundle from '#images/Bundle.svg';
import Aisle from '#images/Aisle.svg';
import Prime from '#images/Prime.svg';
import Unmanifested from '#images/Unmanifested.svg';
import Bulk from '#images/Bulk.svg';
import ReachIQ from '#images/ReachIQ.svg';
import Marketplace from '#images/Marketplace.svg';
import { PeakpassPackageType } from '#/types/Listing';

const BROADCAST_ERRORS = [
  'missing_valid_barcodes',
  'missing_from_sellerdirect',
];

interface IngestionIconsProps {
  ingestion: Ingestion;
  stakeholderLogo?: string;
}

const IngestionIcons: React.FC<IngestionIconsProps> = ({ ingestion, stakeholderLogo }) => {
  const {
    stakeholder,
    uploader,
    state,
    stock_type,
    error_status,
    input_listing_errors,
    output_listings,
    transactions,
    is_sold,
    sold_ct,
    gtv,
    is_reserved,
    peakpass_package_type,
    is_unmanifested,
    seatgeek_user_listing,
    ticket_account_platform,
  } = ingestion;

  let PackageTypeImage = null;

  switch (peakpass_package_type) {
    case PeakpassPackageType.VIP:
      PackageTypeImage = Vip;
      break;
    case PeakpassPackageType.BUNDLE:
      PackageTypeImage = Bundle;
      break;
    case PeakpassPackageType.AISLE:
      PackageTypeImage = Aisle;
      break;
    case PeakpassPackageType.PRIME:
      PackageTypeImage = Prime;
      break;
    default:
      break;
  }

  const uploaderImage = useMemo(() => {
    if (uploader === 'oneticket')
      return Oneticket;
    if (uploader === 'consumer')
      return Consumer;
    if (uploader === 'peakpass')
      return Peakpass;

    return Ticketvault;
  }, [uploader]);

  let stateImage = UploadInProgress;

  if (state === 'done')
    stateImage = UploadDone;
  else if (state === 'event_failed' || state === 'upload_failed' || state === 'deletion_failed' || state === 'failed')
    stateImage = UploadFailed;
  else if (state === 'deletion_needed' || state === 'deletion_pending')
    stateImage = DeletionPending;
  else if (state === 'deleted')
    stateImage = Deleted;

  let stockImage = WillCall;

  if (stock_type === 'hard_stock')
    stockImage = HardStock;
  else if (ticket_account_platform === 'reach iq')
    stockImage = ReachIQ;
  else if (stock_type === 'mobile_transfer')
    stockImage = MobileTransfer;
  else if (stock_type === 'pdf')
    stockImage = Pdf;
  else if (stock_type === 'screenshot')
    stockImage = Screenshot;
  else if (stock_type === 'axs_mobile_id')
    stockImage = FlashSeats;
  else if (stock_type === 'wallet_links')
    stockImage = Link;

  const inputListingErrorStatuses = input_listing_errors
    .map((e) => e.error_status)
    .filter((e) => e && e.status !== 'resolved');

  const overrides = output_listings.map((l) => l.override).filter((o) => o);

  const pricing_errors = input_listing_errors
    .filter((e) => !BROADCAST_ERRORS.includes(e.error_type));
  const broadcast_errors = input_listing_errors
    .filter((e) => BROADCAST_ERRORS.includes(e.error_type));

  const isBulk = !!(seatgeek_user_listing && seatgeek_user_listing.bulk_id);

  return (
    <>
      <OffsetImage
        alt="stakeholder"
        height="25"
        src={stakeholderLogo || Marketplace}
        title={`stakeholder: ${stakeholder}`}
      />
      <OffsetImage
        alt="uploader"
        height="25"
        src={uploaderImage}
        title={`uploader: ${uploader}`}
      />
      <OffsetImage
        alt="stock-type"
        height="25"
        src={stockImage}
        title={
          (ticket_account_platform ? `${ticket_account_platform} ` : '')
          + stock_type.split('_').join(' ')
        }
      />
      <OffsetImage
        alt="state"
        height="25"
        src={stateImage}
        title={`state: ${state}`}
      />
      {PackageTypeImage && (
        <OffsetImage
          alt="package-type"
          height="25"
          src={PackageTypeImage}
          title={`${peakpass_package_type} package type`}
        />
      )}
      {isBulk && (
        <OffsetImage
          alt="bulk-upload"
          height="25"
          src={Bulk}
          title="part of a bulk upload"
        />
      )}
      {is_reserved && (
        <OffsetImage
          alt="reserved"
          height="25"
          src={Pause}
          title="reserved"
        />
      )}
      {error_status && error_status.status !== 'resolved' && (
        <OffsetImage
          alt="error-status"
          height="24"
          src={Notes}
          title={`${error_status.status} (${error_status.userName}, ${error_status.createdAt.toRelative()}) - ${error_status.notes || ''}`}
        />
      )}
      {pricing_errors.length > 0 && (
        <OffsetImage
          alt="pricing-errors"
          height="25"
          src={Error}
          title={`errors: ${pricing_errors.map((e) => `${e.error_type}: ${e.error_reason}`).join(', ')}`}
        />
      )}
      {inputListingErrorStatuses.length > 0 && (
        <OffsetImage
          alt="input-listing-error-statuses"
          height="24"
          src={Notes}
          title={
            inputListingErrorStatuses
              .map((e) => `${e.status} (${e.userName}, ${e.createdAt.toRelative()}) - ${e.notes}`)
              .join(', ')
          }
        />
      )}
      {overrides.some((o) => o.is_active && !o.is_empty) && (
        <OffsetImage
          alt="overrides"
          height="25"
          src={Override}
          title="has applied overrides"
        />
      )}
      {overrides.some((o) => !o.is_active) && (
        <OffsetImage
          alt="pending-override"
          height="25"
          src={PendingOverride}
          title="Pending Override"
        />
      )}
      {output_listings.length > 0 && (
        <OffsetImage
          alt="listed"
          height="25"
          src={Listed}
          title={`listings: ${output_listings.map((l) => `${l.quantity}x $${l.price.amount.toFixed(2)}`).join(', ')}`}
        />
      )}
      {broadcast_errors.length > 0 && (
        <OffsetImage
          alt="broadcast-errors"
          height="25"
          src={BroadcastError}
          title={`errors: ${broadcast_errors.map((e) => `${e.error_type}: ${e.error_reason}`).join(', ')}`}
        />
      )}
      {transactions.length > 0 && (
        <OffsetImage
          alt="sales"
          height="25"
          src={Sale}
          title={`${transactions.length} sales, ${sold_ct} tickets sold, $${gtv.toFixed(2)} GTV`}
        />
      )}
      {is_sold && (
        <OffsetImage
          alt="sold"
          height="25"
          src={Sold}
          title="sold out!"
        />
      )}
      {is_unmanifested && (
        <OffsetImage
          alt="unmanifested-inventory"
          height="25"
          src={Unmanifested}
          title="unmanifested inventory"
        />
      )}
    </>
  );
};

const OffsetImage = styled.img`
  padding-left: 5px;
  padding-bottom: 3px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  object-fit: cover;
  object-position: center;
`;

export default IngestionIcons;
