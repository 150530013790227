import React, { useMemo } from 'react';
import Sparkline from '../Sparkline';
import Tooltip from './Tooltip';
import { type SparklineDatum } from '#/pages/clientReporting/DashboardPage/types';
import type { SalesSummaryMeasurement, CumulativeMmt } from '#/types/ReportingRollup';
import { createSparklineData } from '../utils';

interface SparklineTracesProps {
  data: SalesSummaryMeasurement[];
  loading?: boolean;
  onDatumFocus?: (d: SparklineDatum[], i: number[]) => void;
  onDatumUnfocus?: () => void;
  focusedDate?: Date;
  isExtraNarrow?: boolean;
  measure: CumulativeMmt;
  additionalTraces?: CumulativeMmt[];
}

const SparklineTraces: React.FC<SparklineTracesProps> = ({
  data,
  loading,
  onDatumFocus,
  onDatumUnfocus,
  focusedDate,
  isExtraNarrow,
  measure,
  additionalTraces = [],
}) => {
  const sparklineData: SparklineDatum[] = useMemo(() => (
    data
      ? createSparklineData(data, measure, additionalTraces)
      : undefined
  ), [data, measure, additionalTraces]);

  const focusedDatum = useMemo(() => {
    const thisDatesData = sparklineData
      .filter((d) => Number(d?.transactionDate) === Number(focusedDate));

    return thisDatesData?.length > 0 ? thisDatesData : undefined;
  }, [focusedDate, sparklineData]);

  return (
    <Sparkline
      data={sparklineData}
      focusedDatum={focusedDatum}
      loading={loading}
      measure={measure}
      onDatumFocus={onDatumFocus}
      onDatumUnfocus={onDatumUnfocus}
    >
      <Tooltip
        datum={focusedDatum}
        isExtraNarrow={isExtraNarrow}
        measure={measure}
      />
    </Sparkline>
  );
};

export { SparklineTracesProps };
export default SparklineTraces;
