import { useState, useCallback } from 'react';
import { SinkEvent } from '#/types/Event';

interface Hook {
  activeEventSink: SinkEvent;
  sinkModalIsOpen: boolean;
  updateSink: (sink: SinkEvent) => void;
  setSinkModalIsOpen: (input: boolean) => void;
}

const useSinkModal = (): Hook => {
  const [sinkModalIsOpen, setSinkModalIsOpen] = useState<boolean>(false);
  const [activeEventSink, setActiveEventSink] = useState<SinkEvent>(null);

  const updateSink = useCallback((sink: SinkEvent) => setActiveEventSink(sink), [
    setActiveEventSink,
  ]);

  return {
    activeEventSink,
    setSinkModalIsOpen,
    sinkModalIsOpen,
    updateSink,
  };
};

export default useSinkModal;
