import React, {
  useState, ChangeEvent, useCallback,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Select from 'react-select';
import {
  SectionWrapper,
  InnerWrapper,
  Title,
  Label,
  Error,
  TextArea,
  ButtonsWrapper,
  Content,
  modalMenuProps,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import {
  ErrorStatus, ErrorReference, ErrorStatusType, PostErrorStatus,
} from '#/types/Errors';
import postErrorStatuses from '#/api/postErrorStatuses';
import formatApiError from '#/api/utils/formatApiError';
import modalStyles from './modalStyles';
import useSetupModal from '#/pages/useSetupModal';
import type { GenericOption } from '#/types/GenericOption';

const STATUS = 'Status';
const NOTES = 'Notes';
const STATUSES = [
  { value: 'todo', label: 'To Do' },
  { value: 'inprogress', label: 'In Progress' },
  { value: 'resolved', label: 'Resolved' },
  { value: 'wontfix', label: 'Won\'t Fix' },
];
const SUBMIT = 'Submit';
const CANCEL = 'Cancel';

interface ErrorStatusProps {
  isOpen: boolean;
  title: string;
  description: string;
  existing: ErrorStatus | null;
  reference: ErrorReference | null;
}

interface ErrorStatusModalProps {
  props: ErrorStatusProps;
  closeModal: (refresh: boolean) => void;
}

const ErrorStatusModal: React.FC<ErrorStatusModalProps> = ({
  props,
  closeModal,
}) => {
  const {
    title, description, existing, reference,
  } = props;

  const [error, setError] = useState<string>(null);
  const [errorStatus, setErrorStatus] = useState<PostErrorStatus>(
    {
      status: existing?.status || ErrorStatusType.TODO,
      notes: existing?.notes || '',
      reference: reference,
    },
  );

  const onChangeStatus = useCallback((event: GenericOption<string, string>) => {
    setErrorStatus({
      ...errorStatus,
      status: event.value as ErrorStatusType,
    });
  }, [errorStatus]);

  const onChangeNotes = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setErrorStatus({
      ...errorStatus,
      notes: event.target.value,
    });
  }, [errorStatus]);

  const submitErrorStatus = useCallback(async () => {
    try {
      await postErrorStatuses({ errorStatuses: [errorStatus] });
      setError(null);
      closeModal(true);
    } catch (err) {
      setError(formatApiError(err));
    }
  }, [closeModal, errorStatus]);

  const cancel = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  useSetupModal(cancel);

  return (
    <Modal
      isOpen
      onRequestClose={cancel}
      style={modalStyles}
    >
      <Title>{title}</Title>
      <SectionWrapper>
        <InnerWrapper>
          <Description>{description}</Description>
        </InnerWrapper>
        <Content>
          <InnerWrapper>
            <Label>{STATUS}</Label>
            <Select
              defaultValue={
                existing === null
                  ? STATUSES[0]
                  : STATUSES.find((status) => status.value === existing.status)
              }
              onChange={onChangeStatus}
              options={STATUSES}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...modalMenuProps}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{NOTES}</Label>
            <TextArea
              defaultValue={existing?.notes}
              onChange={onChangeNotes}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Error>{error}</Error>
          </InnerWrapper>
        </Content>
        <ButtonsWrapper>
          <Button onClick={submitErrorStatus}>{SUBMIT}</Button>
          <Button onClick={cancel}>{CANCEL}</Button>
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

const Description = styled.p``;

export { ErrorStatusProps };
export default ErrorStatusModal;
