import React, { useContext } from 'react';
import {
  ErrorStatusType, EventError, PostErrorStatus,
} from '#/types/Errors';
import { MOPS_FORM_LINK } from '../utils';
import Checklist, { ChecklistConfigItem } from './checklist';
import { ActiveEventContext } from '#/pages/ViewPage/contexts/ActiveEvent';

interface OnsaleModelErrorChecklistProps {
  error: EventError;
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const TRAINING_SET_CONFIG: ChecklistConfigItem[] = [
  {
    id: 0,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Event has small set of Swaps Inventory',
  },
  {
    id: 1,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Check whether Fallback Prices are reasonable',
    parent_id: 0,
  },
  {
    id: 2,
    level: 3,
    status: ErrorStatusType.RESOLVED,
    notes: 'Prices are reasonable. Continue using Fallback Pricing',
    parent_id: 1,
  },
  {
    id: 3,
    level: 3,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Price are unreasonable',
    parent_id: 1,
  },
  {
    id: 4,
    level: 4,
    status: ErrorStatusType.RESOLVED,
    notes: 'Set Listing Overrides to adjust prices',
    parent_id: 3,
  },
  {
    id: 5,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Event has Client Inventory',
  },
  {
    id: 6,
    level: 2,
    status: ErrorStatusType.RESOLVED,
    notes: 'Manually generate an Onsale Model and upload it',
    parent_id: 5,
  },
  {
    id: 7,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Add data to autobroker_external_transactions in the DWH',
    parent_id: 5,
  },
  {
    id: 8,
    level: 3,
    status: ErrorStatusType.RESOLVED,
    notes: 'Retry generating an Onsale Model',
    parent_id: 7,
    showButton: true,
  },
  {
    id: 9,
    level: 2,
    status: ErrorStatusType.RESOLVED,
    notes: 'Change the SeatGeek Map to a more common map with sufficient training data',
    parent_id: 5,
  },
  {
    id: 10,
    level: 2,
    status: ErrorStatusType.RESOLVED,
    notes: 'Retry Generating an Onsale Model',
    parent_id: 5,
    showButton: true,
  },
];

const OnsaleModelErrorChecklist: React.FC<OnsaleModelErrorChecklistProps> = (
  { error, setErrorStatus },
) => {
  const { activeEvent } = useContext(ActiveEventContext);
  const mapLink = `https://seatgeekadmin.com/maps/ticket_data?event_id=${activeEvent?.config?.seatgeekEventId}`;

  const no_map_config: ChecklistConfigItem[] = [
    {
      id: 0,
      level: 1,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Check if Dummy Map is showing',
      link: mapLink,
    },
    {
      id: 1,
      level: 2,
      status: ErrorStatusType.RESOLVED,
      notes: 'Dummy Map Removed',
      parent_id: 0,
    },
  ];

  const no_support_config: ChecklistConfigItem[] = [
    {
      id: 0,
      level: 1,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Event corresponds to GA Inventory',
    },
    {
      id: 1,
      level: 2,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Configure the Event to use the GA Event Page - Navigate to the mapping admin page, assign a dummy GA map to the venue config, unlink the event, set the events venue config to GA, and remove the dummy map from both venue configs',
      link: mapLink,
      parent_id: 1,
    },
    {
      id: 2,
      level: 3,
      status: ErrorStatusType.RESOLVED,
      notes: 'Run the supervisor to ingest the change in venue config, which will kick off a retry to generate the onsale pricing model',
    },
    {
      id: 3,
      level: 1,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Event corresponds to Mapped Inventory',
    },
    {
      id: 4,
      level: 2,
      status: ErrorStatusType.INPROGRESS,
      notes: 'Submit MOps Form indicating that we are missing a map for this venue',
      link: MOPS_FORM_LINK,
      parent_id: 3,
    },
    {
      id: 5,
      level: 3,
      status: ErrorStatusType.RESOLVED,
      notes: 'If Fallback Pricing is being used, check prices are reasonable and if not, submit listing-level overrides',
      parent_id: 4,
    },
  ];

  let config = null;

  if (error.errorReason.toLowerCase().includes('no map found'))
    config = no_map_config;
  else if (error.errorReason.toLowerCase().includes("we don't support generating an onsale pricing model"))
    config = no_support_config;
  else
    config = TRAINING_SET_CONFIG;

  return (
    <Checklist config={config} error={error} setErrorStatus={setErrorStatus} />
  );
};

export default OnsaleModelErrorChecklist;
