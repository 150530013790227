import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PageWrapper from '#/shared/PageWrapper';
import Header from '#/shared/Header';
import { User } from '#/types/User';
import Filters from './Filters';
import useFetchIngestions from './useFetchIngestions';
import usePatchIngestions from './usePatchIngestions';
import IngestionsTableAndDetail from './IngestionsTableAndDetail';
import Ingestion, { IngestionFilters } from '#/types/Ingestion';
import { StakeholderLogos } from '../useFetchStakeholders';

interface IngestionsPageProps {
  user: User;
  stakeholderLogos: StakeholderLogos;
}

const IngestionsPage: React.FC<IngestionsPageProps> = ({
  user, stakeholderLogos,
}) => {
  const ingestionsHook = useFetchIngestions();
  const { filters, setFilters, setPinnedIngestions } = ingestionsHook;
  const patchIngestionsHook = usePatchIngestions(filters);
  const { setPatchFilters } = patchIngestionsHook;
  const [curPinnedIngestions, setCurPinnedIngestions] = useState<Ingestion[]>([]);

  useEffect(() => {
    const updateFilters = (newFilters: IngestionFilters): void => {
      setPatchFilters(newFilters);
    };

    if (filters)
      updateFilters(filters);
  }, [filters, setPatchFilters]);

  return (
    <PageWrapper>
      <Container>
        <Header
          toggleOpen={null}
          user={user}
        />
        <FilterWrapper>
          <Filters
            filters={filters}
            pinnedIngestions={curPinnedIngestions}
            setFilters={setFilters}
            setPinnedIngestions={setPinnedIngestions}
          />
        </FilterWrapper>
      </Container>
      <ScrollBody>
        <TableWrapper>
          <IngestionsTableAndDetail
            ingestionsHook={ingestionsHook}
            patchIngestionsHook={patchIngestionsHook}
            pinnedIngestions={curPinnedIngestions}
            setPinnedIngestions={setCurPinnedIngestions}
            stakeholderLogos={stakeholderLogos}
            user={user}
          />
        </TableWrapper>
      </ScrollBody>
    </PageWrapper>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 2;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.info};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: padding 0.75s ease;
`;

const ScrollBody = styled.div`
  position: absolute;
  width: 100%;
  top: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FilterWrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

const TableWrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
`;

export default IngestionsPage;
