import React, {
  useState, ChangeEvent, useCallback,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Select from 'react-select';
import {
  SectionWrapper,
  InnerWrapper,
  HorizontalWrapper,
  Title,
  Label,
  Error,
  Input,
  Checkbox,
  ButtonsWrapper,
  modalMenuProps,
  Content,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import postListingOverride from '#/api/postListingOverride';
import { PRICE_CONSTRAINT_TYPES, PriceConstraintType } from '#/types/PriceConstraints';
import formatApiError from '#/api/utils/formatApiError';
import { PriceGranularity, PricingBasis } from '#/types/Event';
import { PostListingOverride } from '#/types/Override';
import PriceLevel from '#/types/PriceLevel';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';
import type { GenericOption } from '#/types/GenericOption';

const TITLE = 'Submit Price Level Override';
const DESCRIPTION = 'Apply overrides to bypass automatic pricing for specific price levels.';

const SHOULD_APPLY_TO_LISTINGS = 'Apply to all listings in price level?';
const EXPECTED_VALUE = 'Expected Value';
const PRICE_CONSTRAINT = 'Price Constraint';
const ALL_IN_PRICE = 'All in Price';
const DISPLAY_PRICE = 'Display Price';
const BROADCAST_PRICE = 'Broadcast Price';
const NOTES = 'Notes';

const SUBMIT = 'Submit';
const CANCEL = 'Cancel';

const FACE_VALUE = 'face_value';
const PRICE_CONSTRAINT_OPTIONS = [
  { label: FACE_VALUE, value: FACE_VALUE },
  ...PRICE_CONSTRAINT_TYPES.map((p) => ({ label: p, value: p })),
];

interface PriceLevelOverrideModalProps {
  priceLevels: PriceLevel[];
  closeModal: (refresh: boolean) => void;
}

const PriceLevelOverrideModal: React.FC<PriceLevelOverrideModalProps> = ({
  priceLevels,
  closeModal,
}) => {
  const [error, setError] = useState<string>(null);
  const [override, setOverride] = useState<PostListingOverride[]>(
    priceLevels.map((i) => ({
      source_name: i.level.sourceName,
      source_external_event_id: i.level.sourceExternalEventId,
      source_inventory_id: i.level.sourcePriceLevelId,
      face_value: i.faceValue,
      granularity: PriceGranularity.PRICE_LEVEL,
      is_cascading: i.override?.isCascading ?? true,
      expected_value: i.override?.expectedValue,
      price_constraint: i.override?.priceConstraint && {
        price_floor: i.override?.priceConstraint.priceFloor,
        price_ceiling: i.override?.priceConstraint.priceCeiling,
        price_constraint_type: i.override?.priceConstraint.priceConstraintType,
      },
      all_in_price: i.override?.allInPrice,
      display_price: i.override?.displayPrice,
      broadcast_price: i.override?.broadcastPrice,
    })),
  );
  const [faceValueMode, setFaceValueMode] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>(null);

  const priceLevel = priceLevels[0];
  const priceLevelExpectedValue = (
    priceLevel.price.logExpectedValue
    && Math.exp(priceLevel.price.logExpectedValue)
  );
  const priceLevelIsCascading = priceLevel.override?.isCascading ?? true;
  const priceLevelPriceFloor = priceLevel.override?.priceConstraint?.priceFloor;
  const priceLevelPriceCeiling = priceLevel.override?.priceConstraint?.priceCeiling;
  const priceLevelFaceValue = priceLevel.faceValue?.amount;
  const { autobrokerEventId } = priceLevel;

  const onChangeIsCascading = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const is_cascading = e.target.checked;

    setOverride((o) => o.map((i) => ({ ...i, is_cascading })));
  }, [setOverride]);

  const onChangeExpectedValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const expected_value = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({ ...i, expected_value })));
  }, [setOverride]);

  const onChangeAllInPrice = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const all_in_price = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({ ...i, all_in_price })));
  }, [setOverride]);

  const onChangeDisplayPrice = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const display_price = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({ ...i, display_price })));
  }, [setOverride]);

  const onChangeBroadcastPrice = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const broadcast_price = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({ ...i, broadcast_price })));
  }, [setOverride]);

  const onChangePriceConstraintType = useCallback((e: GenericOption<string, string>) => {
    const priceConstraintType = e?.value;
    const newFaceValueMode = priceConstraintType === FACE_VALUE;

    setFaceValueMode(newFaceValueMode);
    setOverride((o) => o.map((i) => ({
      ...i,
      price_constraint: (
        priceConstraintType
        && (!newFaceValueMode || !!(i.face_value?.amount))
      ) ? {
          price_floor: null,
          price_ceiling: null,
          ...i.price_constraint,
          price_constraint_type: (
            newFaceValueMode
              ? i.face_value?.basis as unknown as PriceConstraintType
              : priceConstraintType as PriceConstraintType
          ),
        } : null,
    })));
  }, [setOverride, setFaceValueMode]);

  const onChangePriceFloor = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const priceFloor = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({
      ...i,
      price_constraint: i.price_constraint && {
        ...i.price_constraint,
        price_floor: priceFloor === null
          ? null
          : (faceValueMode ? i.face_value.amount : 1.0) * priceFloor,
      },
    })));
  }, [setOverride, faceValueMode]);

  const onChangePriceCeiling = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const priceCeiling = e.target.value ? Number(e.target.value) : null;

    setOverride((o) => o.map((i) => ({
      ...i,
      price_constraint: i.price_constraint && {
        ...i.price_constraint,
        price_ceiling: priceCeiling === null
          ? null
          : (faceValueMode ? i.face_value.amount : 1.0) * priceCeiling,
      },
    })));
  }, [setOverride, faceValueMode]);

  const onChangeNotes = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newNotes = e?.target?.value;

    setNotes(newNotes);
  }, [setNotes]);

  const onSubmit = useCallback(async () => {
    try {
      await postListingOverride(autobrokerEventId, override, notes);
      setError(null);
      closeModal(true);
    } catch (err) {
      setError(formatApiError(err));
    }
  }, [autobrokerEventId, closeModal, override, notes]);

  const onCancel = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  useSetupModal(onCancel);

  return (
    <Modal
      isOpen
      onRequestClose={onCancel}
      style={modalStyles}
    >
      <Title>{TITLE}</Title>
      <SectionWrapper>
        <InnerWrapper>
          <Description>{DESCRIPTION}</Description>
          <InlineWrapper>
            <Checkbox
              defaultChecked={priceLevelIsCascading}
              onChange={onChangeIsCascading}
              type='checkbox'
            />
            <Label>{SHOULD_APPLY_TO_LISTINGS}</Label>
          </InlineWrapper>
        </InnerWrapper>
        <Content>
          <InnerWrapper>
            <Label>{EXPECTED_VALUE}</Label>
            <Input
              defaultValue={priceLevel.override?.expectedValue}
              onChange={onChangeExpectedValue}
              placeholder={priceLevelExpectedValue && priceLevelExpectedValue.toFixed(2)}
              type='number'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{PRICE_CONSTRAINT}</Label>
            <Select
              defaultValue={priceLevel.override?.priceConstraint && (
                PRICE_CONSTRAINT_OPTIONS.find(
                  (option) => option.value
                    === priceLevel.override?.priceConstraint?.priceConstraintType,
                )
              )}
              isClearable
              onChange={onChangePriceConstraintType}
              options={PRICE_CONSTRAINT_OPTIONS}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...modalMenuProps}
            />
            {!faceValueMode && override.some((o) => o.price_constraint) && (
              <HorizontalWrapper>
                <Input
                  defaultValue={priceLevelPriceFloor}
                  onChange={onChangePriceFloor}
                  placeholder='Price Floor'
                  type='number'
                />
                <Input
                  defaultValue={priceLevelPriceCeiling}
                  onChange={onChangePriceCeiling}
                  placeholder='Price Ceiling'
                  type='number'
                />
              </HorizontalWrapper>
            )}
            {faceValueMode && override.some((o) => o.price_constraint) && (
              <HorizontalWrapper>
                <Input
                  defaultValue={
                    priceLevelPriceFloor && priceLevelPriceFloor / priceLevelFaceValue
                  }
                  onChange={onChangePriceFloor}
                  placeholder='Price Floor Scale'
                  type='number'
                />
                <Input
                  defaultValue={
                    priceLevelPriceCeiling && priceLevelPriceCeiling / priceLevelFaceValue
                  }
                  onChange={onChangePriceCeiling}
                  placeholder='Price Ceiling Scale'
                  type='number'
                />
              </HorizontalWrapper>
            )}
          </InnerWrapper>
          <InnerWrapper>
            <Label>{ALL_IN_PRICE}</Label>
            <Input
              defaultValue={priceLevel.override?.allInPrice}
              onChange={onChangeAllInPrice}
              placeholder={
                (priceLevel.price.basis === PricingBasis.ALL_IN_PRICE)
                  ? priceLevel.price.amount?.toFixed(2)
                  : ''
              }
              type='number'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{DISPLAY_PRICE}</Label>
            <Input
              defaultValue={priceLevel.override?.displayPrice}
              onChange={onChangeDisplayPrice}
              placeholder={
                (priceLevel.price.basis === PricingBasis.DISPLAY_PRICE)
                  ? priceLevel.price.amount.toFixed(2)
                  : ''
              }
              type='number'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{BROADCAST_PRICE}</Label>
            <Input
              defaultValue={priceLevel.override?.broadcastPrice}
              onChange={onChangeBroadcastPrice}
              type='number'
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{NOTES}</Label>
            <Input
              onChange={onChangeNotes}
              type='text'
            />
          </InnerWrapper>
        </Content>
        {error && (
          <InnerWrapper>
            <Error>{error}</Error>
          </InnerWrapper>
        )}
        <ButtonsWrapper>
          <Button onClick={onSubmit}>{SUBMIT}</Button>
          <Button onClick={onCancel}>{CANCEL}</Button>
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

const Description = styled.p``;
const InlineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default PriceLevelOverrideModal;
