interface ExpectedValueBin {
  id: number;
  lower: number;
  upper: number;
  label: string;
}

interface ExpectedValueBinSnapshot {
  expectedValueBinId: number;
  ticketsRemaining: number;
  selldownActual: number;
}

interface SnapshotByExpectedValueBin {
  id: number;
  snapshotAt: string;
  daysToEvent: number;
  logHoursToEvent: number;
  selldownProjected: number;
  selldownActual: number;
  bins: ExpectedValueBinSnapshot[];
}

interface ApiExpectedValueBinSnapshotResponse {
  autobrokerEventId: number;
  expectedValueBins: ExpectedValueBin[];
  snapshots: SnapshotByExpectedValueBin[];
}

enum ExpectedValueBinMeasure {
  ACTUAL = 'actual',
  TARGET = 'target',
}

enum SelldownViewMode {
  OVERALL = 'Overall',
  EXPECTED_VALUE = 'Expected Value',
}

enum RemainingTicketsView {
  PERCENT = 'Percent',
  TOTAL = 'Total',
}

interface ExpectedValueBinSnapshotFormatted {
  logHoursToEvent: number;
  ticketsRemaining: number;
  selldownActual: number;
  group: ExpectedValueBin;
  measure: ExpectedValueBinMeasure;
  isIntersection?: boolean;
}

interface ExpectedValueBinSnapshotResponse {
  formattedData: ExpectedValueBinSnapshotFormatted[];
  expectedValueBins: ExpectedValueBin[];
}

export {
  ApiExpectedValueBinSnapshotResponse,
  ExpectedValueBin,
  ExpectedValueBinSnapshotResponse,
  ExpectedValueBinSnapshotFormatted,
  ExpectedValueBinMeasure,
  SelldownViewMode,
  RemainingTicketsView,
};
