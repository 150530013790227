import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import Loader from '#/shared/Loader';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: React.ReactNode;
  active?: boolean;
  summary?: React.ReactNode;
  summaryKind?: 'primary' | 'secondary'
  Icon?: React.ReactNode;
  kind?: 'primary' | 'secondary';
  padding?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  forwardedRef?: React.MutableRefObject<any>;
  iconPosition?: 'before' | 'after';
};

const SmallButton = ({
  active,
  summary,
  Icon = null,
  label,
  kind = 'primary',
  summaryKind = 'primary',
  padding = '0.3rem 0.75rem',
  isDisabled = false,
  isLoading = false,
  forwardedRef,
  iconPosition = 'before',
  ...props
}: Props) => (
  // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
  <Btn
    {...props}
    active={active}
    ref={forwardedRef}
    kind={kind}
    padding={padding}
    disabled={isDisabled || isLoading}
    isLoading={isLoading}
  >
    {(Icon || isLoading) && iconPosition === 'before' && (
      <IconWrapper data-test="small-button-icon">
        {isLoading ? <Loader size={0.9} thickness={0.2} /> : Icon}
      </IconWrapper>
    )}
    {label}
    {(Icon || isLoading) && iconPosition === 'after' && (
      <IconWrapper hasLabel={!!label} iconPosition={iconPosition} data-test="small-button-icon">
        {isLoading ? <Loader size={0.9} thickness={0.2} /> : Icon}
      </IconWrapper>
    )}
    {summary && !isLoading && <Summary summaryKind={summaryKind}>{summary}</Summary>}
  </Btn>
);

export const MoreFiltersButton = ({
  active,
  summary,
  // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Node'.
  Icon = null,
  label,
  summaryKind = 'primary',
}: Props) => (
  // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
  <CenteredBtn onClick={onClick} active={active}>
    {Icon && <IconWrapper data-test="filter-button-icon">{Icon}</IconWrapper>}
    {label}
    {summary && <Summary summaryKind={summaryKind}>{summary}</Summary>}
  </CenteredBtn>
);

export default SmallButton;

interface SummaryProps {
  summaryKind?: 'primary' | 'secondary';
}

const Summary = styled.span<SummaryProps>`
  color: ${props => (
    props.summaryKind === 'primary'
      ? props.theme.color.background.primary
      : undefined
  )};
  background-color: ${props => (
    props.summaryKind === 'primary'
    ? props.theme.color.text.primary
    : undefined
  )};
  border-radius: ${6 / 16}rem;
  font-size: ${11 / 16}em;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 -0.25rem -0.25rem 0.45rem;
  position: relative;
  top: -0.125rem;
  box-sizing: border-box;
  min-width: 1rem;
  min-height: 1rem;
`;

const Btn = styled.button<Props>`
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  -webkit-appearance: none;
  border: none;
  // outline: 0;
  cursor: pointer;
  pointer-events: ${props => props.isLoading || props.isDisabled ? 'none' : undefined};
  background-color: ${props => {
  let bgColor;
  let activeColor;

  if (props.kind === 'primary') {
    bgColor = props.theme.color.text.primary;
    activeColor = props.theme.color.text.secondary;
  }
  
  if (props.kind === 'secondary') {
    bgColor = props.theme.color.background.primary;
    activeColor = '#ddd';
  }
  
  return props.active ? activeColor : bgColor;
}};
  margin: 2px 0;
  border: 1px solid
    ${props =>
      // @ts-ignore ts-migrate(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
      props.active
        ? '#ccc'
        : props.theme.color.border.primary};
  color: ${props =>
    // @ts-ignore ts-migrate(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.kind === 'primary' ? props.theme.color.background.primary : (props.active ? props.theme.color.text.primary : props.theme.color.text.secondary)};
  padding: ${props => props.padding};
  line-height: inherit;
  border-radius: 0.5rem;
  white-space: nowrap;
  transition: all 0.2s ease;
  &:enabled&:hover {
    // outline: none;
    color: ${props => props.kind === 'primary' ? props.theme.color.background.primary : props.theme.color.text.primary};
    box-shadow: 0 0.25rem 0.5rem 0 rgba(15, 15, 15, 0.1);
    border: 1px solid
      ${props => props.theme.color.border.primary};
    background-color: ${props => {
    if (props.active) return undefined;
    
    return (
      props.kind === 'primary'
        ? props.theme.color.text.secondary
        : props.theme.color.background.primary
    );
  }
  };
  }
  &:disabled {
    opacity: ${props => props.isLoading ? 0.7 : 0.3};
    cursor: ${props => props.isLoading ? 'progress' : 'not-allowed'};
  }
`;

const CenteredBtn = styled(Btn as any)`
  /*
    When we display '...' as the button text, we want this centered (accounting for character
    height from baseline). ideally this'd be a svg..
  */
  height: 34px;
  line-height: 0px;
  padding-top: 0;
  padding-bottom: 2px;
`;

interface IconWrapperProps {
  iconPosition?: 'before' | 'after';
  hasLabel?: boolean;
}

const IconWrapper = styled.span<IconWrapperProps>`
  min-width: 20px;
  margin: ${(props) => {
    if (props.hasLabel) {
      return props.iconPosition === 'after' ? '0 0 0 0.5rem' : '0 0.5rem 0 0';
    } else {
      return '4px';
    }
}};
  /* margin: ${(props) => props.iconPosition === 'after' ? '0 0 0 0.5rem' : '0 0.5rem 0 0'}; */
  display: inline-flex;
`;
