import React from 'react';

import { User } from '#/types/User';
import Ingestion from '#/types/Ingestion';
import { ErrorStatusProps } from '#/shared/ErrorStatusModal';
import { InputListing } from '#/shared/ListingOverrideModal';
import IngestionDetailHeader from './IngestionDetailHeader';
import IngestionDetailCards from './IngestionDetailCards';
import IngestionFunnelCards from './IngestionFunnelCards';
import { Wrapper, Section } from '#/shared/multiSelectDetail';

interface IngestionDetailProps {
  user: User;
  ingestion: Ingestion;
  setErrorStatusModal: (props: ErrorStatusProps) => void;
  setOverrideListingModal: (listings: InputListing[]) => void;
  setPdfUrl: (url: string) => void;
  openEditModal: () => void;
  openRelistModal: () => void;
  closeDetail: () => void;
  closeable: boolean;
}

const IngestionDetail: React.FC<IngestionDetailProps> = ({
  user,
  ingestion,
  setErrorStatusModal,
  setOverrideListingModal,
  setPdfUrl,
  openEditModal,
  openRelistModal,
  closeDetail,
  closeable,
}) => {
  return (
    <Wrapper>
      <Section>
        <IngestionDetailHeader
          closeDetail={closeDetail}
          closeable={closeable}
          ingestion={ingestion}
          openEditModal={openEditModal}
          openRelistModal={openRelistModal}
          setErrorStatusModal={setErrorStatusModal}
          user={user}
        />
      </Section>
      <Section>
        <IngestionDetailCards ingestion={ingestion} setPdfUrl={setPdfUrl} user={user} />
      </Section>
      <Section>
        <IngestionFunnelCards
          ingestion={ingestion}
          openEditModal={openEditModal}
          setErrorStatusModal={setErrorStatusModal}
          setOverrideListingModal={setOverrideListingModal}
          user={user}
        />
      </Section>
    </Wrapper>
  );
};

export default IngestionDetail;
