import { AxiosResponse } from 'axios';
import { PriceType, GetPriceTypesResponse } from '#/types/PriceTypes';
import client from './_clientFormatted';

const getPriceTypes = async (stakeholder?: string, isAutobroker = true): Promise<PriceType[]> => {
  const response: AxiosResponse<GetPriceTypesResponse> = await client.get(
    '/proxy/peakpass_price_types',
    {
      params: {
        stakeholder,
        isAutobroker,
      },
    },
  );

  return response?.data.peakpassPriceTypes;
};

export default getPriceTypes;
