import React, { FormEvent } from 'react';
import { ButtonTransparent } from '#/shared/Button';

interface ButtonProps {
  disabled: boolean;
  onClick: (event: FormEvent) => Promise<void>;
}

const SubmitButton: React.FC<ButtonProps> = ({ disabled, onClick }) => {
  const SUBMIT = 'Submit';

  return (
    <ButtonTransparent
      className='submit_pricing'
      disabled={disabled}
      onClick={onClick}
    >
      {SUBMIT}
    </ButtonTransparent>
  );
};

export default SubmitButton;
