import React from 'react';
import styled from 'styled-components';

interface ShowImageButtonProps {
  show: boolean;
  toggleShow: () => void;
  image: string;
  imageText: string;
}

const ShowImageButton: React.FC<ShowImageButtonProps> = ({
  show,
  toggleShow,
  image,
  imageText,
}) => {
  return (
    <Button onClick={toggleShow} show={show}>
      <img alt={imageText} src={image} />
    </Button>
  );
};

interface ButtonProps {
  show: boolean;
}

const Button = styled.button<ButtonProps>`
  background: none;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  img {
    transition: transform 0.2s ease;
    ${({ show }: { show: boolean }): string => {
    if (show) {
      return `
            transform: rotate(90deg);
            `;
    }
    return null;
  }};
  }
`;

export default ShowImageButton;
